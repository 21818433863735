module.exports = {
   getOpt: function (searchApi, eplist, create, addToQuery) {
      // Default to 'program'
      if (!searchApi) searchApi = 'program';

      if (myFunc.getQueryString().searchTest == 1)
         searchApi = 'programEpisodeNew'

      // Controllo se è abilitato a creare su Satellite
      // Permessi
      if (!myVars || !myVars.io) return;
      let adminPermission = ['Admin', 'Supervisor'].includes(myVars.io.ruolo);
      let satellitePermission = myVars.io.area.indexOf('Satellite') > -1;
      // let graficoPermission = ['Grafico'].includes(myVars.io.ruolo);

      if (create) {
         if (!adminPermission && !satellitePermission)
            create = false;
      }

      const baseOpt = {
         // debug: true,
         type: 'myTemplate', // /^program/.test(searchApi) ? 'program' : searchApi == 'person' ? 'myTemplate' : searchApi,
         cache: true,
         maxResults: 0,
         minCharacters: 2,
         searchDelay: 800,
         searchOnFocus: false,
         error: {
            serverError: 'Il server non risponde.',
            noResults: !create ? 'Nessun risultato trovato.' :
               `<div style="padding: 13px; border-top: 1px solid rgba(34,36,38,.1); margin: 13px -13px -13px -13px;">
            <div class="ui two mini fluid buttons">
              <div class="ui blue icon button" data-modalschedadbemptycreate="Programma,,">
                <i class="plus circle icon"></i>
                Crea Nuovo<br>Programma Singolo
              </div>
              <div class="ui orange icon button" data-modalschedadbemptycreate="Base,,">
                <i class="plus circle icon"></i>
                Crea Nuova<br>Base + Stagione
              </div>
            </div>
          </div>`,
         },
         onSelect: function (result, response) {
            $('.modalschedadbPop').remove();
            return false;
         },
         // onResultsClose: function () {
         //    $('.modalschedadbPop').remove();
         // },
         apiSettings: {
            action: searchApi ? searchApi : 'program',
            rawResponse: true,
            beforeSend: function (settings) {
               $(this).data('lastSearch', $(this).find('.prompt').val());
               if (addToQuery) settings.urlData.query = settings.urlData.query + addToQuery;

               settings.urlData.query = encodeURIComponent(settings.urlData.query);
               // console.log('settings', settings);
               return settings;
            },
            onResponse: function (response) {
               response.eplist = eplist;
               response.create = create;
               // console.log('onResponse', response);
               if (response.total && response.data) return response.data;
               return response;
            }
         },
      };

      // console.log('getOpt', searchApi, eplist, create, addToQuery, baseOpt);
      return baseOpt;
   },

   init: function ($els, searchApi, create, addToQuery) {
      // PROGRAM EPISODE SEARCH
      $.fn.api.settings.api = {
         'program': mySite + '/program-join?title={query}',
         'programEpisode': mySite + '/program-join?title={query}&epSearch=true',
         'programEpisodeNew': mySite + '/program-join?what=satelliteNew&title={query}&epSearch=true',
         'request': mySite + '/program-join?title={query}&what=request',
         'response': mySite + '/program-join?title={query}&what=response',
         'imdb': mySite + '/apis?site=imdb&title={query}',
         'tmdb': mySite + '/apis?site=tmdb&title={query}',

         // Persons (da fare)
         'person': mySite + '/program-join?name={query}&what=person',
         'tmdbPerson': mySite + '/apis?title={query}',
         'imdbPerson': mySite + '/apis?title={query}',
      };

      // MYTEMPLATE
      $.fn.search.settings.templates.myTemplate = (response) => {
         let html = '';

         if (response.create) {
            html += `
          <div style="padding: 13px; border-bottom: 1px solid rgba(34,36,38,.1);">
            <div class="ui two mini fluid buttons">
               <div class="ui blue icon button" data-modalschedadbemptycreate="Programma,,">
                  <i class="plus circle icon"></i>
                  Crea Nuovo<br>Programma Singolo
               </div>
               <div class="ui orange icon button" data-modalschedadbemptycreate="Base,,">
                  <i class="plus circle icon"></i>
                  Crea Nuova<br>Base + Stagione
               </div>
            </div>
          </div>`;
         }

         // if (response.queries.superloose || response.queries.ep_superloose) {
         //    html += `
         //    <div style="padding: 13px; border-bottom: 1px solid rgba(34,36,38,.1);">
         //       <div class="ui mini icon message">
         //          <i class="info circle icon"></i>
         //          <div class="content">
         //             <div class="header">
         //                Ricerca estesa
         //             </div>
         //             <p>Non sono state trovate corrispondenze per il testo cercato queste sono le corrispondeze parziali:</p>
         //          </div>
         //       </div>
         //    </div>
         //    `;
         // }

         response.results.forEach((res) => {
            const r = res.item ? res.item : res;
            if (r.restype == 'program' && r.idProgram == r.idBase) r.restype = 'base';

            if (['base', 'program', 'episode'].includes(r.restype)) {
               html += myProgramSearch.getHtmlForSat(r);

            } else if (r.restype == 'request') {
               html += myProgramSearch.getHtmlForRequest(r);

            } else if (r.restype == 'response') {
               html += myProgramSearch.getHtmlForResponse(r);

            } else if (r.restype == 'person') {
               html += myProgramSearch.getHtmlForPerson(r);

            } else if (r.restype == 'imdb') {
               html += myProgramSearch.getHtmlForImdb(r);

            } else if (r.restype == 'tmdb') {
               html += myProgramSearch.getHtmlForTmdb(r);

            } else if (r.restype == 'imdbperson') {
               html += myProgramSearch.getHtmlForImdbperson(r);

            } else if (r.restype == 'tmdbperson') {
               html += myProgramSearch.getHtmlForTmdbperson(r);

            }
         });

         return html;
      };

      // INIT
      // console.log('[myProgramSearch Init]...', $els, searchApi, create, addToQuery);
      if (!$els) $els = $('[data-program_search]');

      $els.each((i, el) => {
         let sApi = searchApi ? searchApi
            : $(el).attr('data-program_search') ? $(el).attr('data-program_search')
               : null;

         if (!sApi) return;
      });

      if ($('[data-program_search="program"]').length)
         $('[data-program_search="program"]').search(myProgramSearch.getOpt('program', true, true));

      if ($('[data-program_search="programEpisode"]').length)
         $('[data-program_search="programEpisode"]').search(myProgramSearch.getOpt('programEpisode', true, true));

      if ($('[data-program_search="request"]').length)
         $('[data-program_search="request"]').search(myProgramSearch.getOpt('request'));

      if ($('[data-program_search="imdb"]').length)
         $('[data-program_search="imdb"]').search(myProgramSearch.getOpt('imdb'));

      if ($('[data-program_search="tmdb"]').length)
         $('[data-program_search="tmdb"]').search(myProgramSearch.getOpt('tmdb'));

      // EVENTS
      // $('body').on('mouseover', '[data-program_search]', function (e) {
      //    $(this).find('input').eq(0).focus();
      // });

      // <a class="ui mini corner label" title="Esempi Ricerca Satellite"><i class="question icon"></i></a>

      $('body').on('mouseover', '[data-program_search] > .input > .icon', function (e) {
         $('.searchHelp').remove();
         const $search = $(this).closest('[data-program_search]');
         const $pop = $(`
         <div class="ui searchHelp tiny wide popup" style="width:350px;">
         <div class="ui tiny header">
            <div class="content">Esempi Filtri Ricerca</div>
         </div>

         <table class="ui compact table">
            <tbody>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>TG1 -- onair</b><br>
               Solo i risultati in onda
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>I simpson -- aired</b><br>
               Solo i risultati in onda negli ultimi 2 mesi
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>Rambo -- 1988</b><br>
               Solo i risultati del 1988
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>Lost -- S4</b><br>
               Solo i risultati con la stagione 4
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>Il Trono di Spade -- e4</b><br>
               Solo i risultati con l'episodio 12
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>Serie A -- calcio 2020</b><br>
               Solo i risultati Calcio del 2020
               </td></tr>
            </tbody>
         </table>

         <div class="ui tiny header" style="margin-top: 0;">
            <div class="content">Esempi Ricerca ID</div>
         </div>

         <table class="ui compact table">
            <tbody>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>idPr=83051</b><br>
               Cerca con l'Id Programma
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>idEp=128237</b><br>
               Cerca con l'Id Episodio
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>titleId=3491530</b><br>
               Cerca con il Title Id
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>idReq=216303</b><br>
               Cerca con l'Id Richiesta
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>idRes=14693</b><br>
               Cerca con l'Id Risposta
               </td></tr>
               <tr class="testSearch"><td style="line-height: 11px;">
               <b>idExt=1d9db54f-0cdc-4f67-a224-3469eda43d49</b><br>
               Cerca con l'External Id (UUID)
               </td></tr>
            </tbody>
         </table>

         </div>
      `).appendTo('body');

         $(this).popup({
            // on: 'manual',
            hoverable: true,
            popup: $pop,
         }).popup('show');

         $pop.find('.testSearch b').addClass('a_blue').css('cursor', 'pointer');

         $pop.on('click', '.testSearch', function (e) {
            const txt = $(this).find('b').text();
            $search.find('input').val(txt);
            $search.search('query');
         });
      });

      $('body').on('click', '[data-program_search], .personSearch', async function (e) {

         let $search = $(this).closest('[data-program_search], .personSearch');
         let $target = $(e.target);
         let $result = $target.closest('.myRes');
         let searched = $search.data('lastSearch');
         let resData = $result.data('result');
         let choosedItem = resData && resData.item ? resData.item : resData ? resData : false;

         // console.log(e, '$target', $target)
         if ($result.length) console.log('[program_search] Cercato:', searched, 'Risultato Scelto:', resData, 'Target:', $target);

         // Se è l'icona avvio la ricerca
         if ($target.hasClass('icon link')) {
            $search.search('query');
            return;
         }

         // Se è un link non faccio niente
         if ($target.closest('a').length) {
            $search.find('.prompt').val(searched);
            return;
         }

         // Blocco gli eventi
         e.preventDefault();
         e.stopPropagation();

         // CLICK
         if ($target.closest('[data-stlist]').length) { // Apro le stagioni della base
            let idProg = $target.closest('[data-stlist]').attr('data-stlist');
            let alreadyOpen = $result.find('.mTree').length;
            $search.find('.mTree').remove();

            if (!alreadyOpen) {
               let $loader = $('<div class="ui active inverted dimmer"><div class= "ui loader"></div></div>').appendTo($result);
               myProgramSearch.printProgramTree($result, idProg, false, 1);
               $loader.remove();
            }

         } else if ($target.closest('[data-eplist]').length) { // Apro gli episodi della stagione
            let idProg = $target.closest('[data-eplist]').attr('data-eplist');
            let alreadyOpen = $result.find('.mTree').length;
            $search.find('.mTree').remove();

            if (!alreadyOpen) {
               let $loader = $('<div class="ui active inverted dimmer"><div class= "ui loader"></div></div>').appendTo($result);
               myProgramSearch.printProgramTree($result, idProg, false, idProg);
               $loader.remove();
            }

         } else if ($target.closest('.mTree').length) { // Selezionato dentro Fulltree
            $search.find('.prompt').val(searched);
            const choosedEvent = new CustomEvent('choosed', { bubbles: false, detail: choosedItem });
            $search.search('hide results');
            $search[0].dispatchEvent(choosedEvent);

         } else if ($target.closest('[data-restype]').length) { // Selezionato dalla lista principale
            const choosedEvent = new CustomEvent('choosed', { bubbles: false, detail: choosedItem });
            $search.search('hide results');
            $search[0].dispatchEvent(choosedEvent);

         }
      });
   },

   // ---------------------------------------------------------------------------------------------------------- UTILITY
   // ------------------------------------------------------------------------------------------------------------------
   printProgramTree: async function ($dest, idProgram, activeEl, levelToPrint, createData) {
      console.log('[printProgramTreeNew] params:', $dest, idProgram, activeEl, levelToPrint, createData);
      if (!idProgram && !createData) return;
      if (!levelToPrint) levelToPrint = 0;

      // Carico programTreeData
      let programTreeData = $dest.data('programTreeData');

      if (createData) {

         let epItem = null;
         let prItem = null;
         let baseItem = null;

         // Episodio
         if (createData[2] && createData[2].ID != '[NEW]' && createData[2].ID != -1) {
            epItem = await client.service('program-join').find({ query: { idEp: createData[2].ID } })
               .then(r => r.results[0].item).catch(e => null);
         }

         // Programma
         if (createData[1] && createData[1].ID != '[NEW]' && createData[1].ID != -1) {
            let tree = await client.service('program-join').get(createData[1].ID, { query: { fullTree: true } })
               .then(r => r.fullTree).catch(e => null);
            if (tree.seasons) prItem = tree.seasons.find(p => p.idProgram == createData[1].ID);
            if (tree.idBase) baseItem = tree;
         }

         // Base
         if (!baseItem && createData[0] && createData[0].ID != '[NEW]' && createData[0].ID != -1) {
            let tree = await client.service('program-join').get(createData[0].ID, { query: { fullTree: true } })
               .then(r => r.fullTree).catch(e => null);
            if (tree.idBase) baseItem = tree;
         }

         if (!epItem && createData[2] && createData[2].ID == '[NEW]')
            epItem = await myProgramSearch.getItemFromCreateData(createData);

         if (!prItem && createData[1] && createData[1].ID == '[NEW]')
            prItem = await myProgramSearch.getItemFromCreateData([createData[0], createData[1], null]);

         if (!baseItem && createData[0] && createData[0].ID == '[NEW]')
            baseItem = await myProgramSearch.getItemFromCreateData([createData[0], null, null]);

         // Compongo il tree
         if (epItem && epItem.idEpisode == '[NEW]') {
            prItem.episodes.push(epItem);
            prItem.episodi = prItem.episodes.length;
         }

         if (prItem && prItem.idProgram == '[NEW]' && baseItem) {
            baseItem.seasons.push(prItem);
            baseItem.stagioni = baseItem.seasons.length;
         }

         if (baseItem) programTreeData = baseItem;
         else programTreeData = prItem;
         $dest.data('programTreeData', programTreeData);

      } else if (!programTreeData) {
         let $loader = $(`
            <div class="ui active inverted dimmer mTree">
               <div class="ui loader"></div>
            </div>
         `).appendTo($dest);
         // programTreeData = await client.service('program-join').get(idProgram, { query: { add: 'fullTree' } }).then(r => r.fullTree);
         programTreeData = await client.service('program-join').get(idProgram, { query: { fullTree: true } }).then(r => r.fullTree);
         console.log('///////', programTreeData)
         $dest.data('programTreeData', programTreeData);
         $loader.remove();
      }
      // console.log('[printProgramTreeNew] programTreeData:', programTreeData);

      // deep copy di tree
      let treeCopy = JSON.parse(JSON.stringify(programTreeData));

      // L'elemento Attivo
      if (activeEl) {
         if (activeEl.toCreate) {
            // Aggiungo il nuovo elemento a treeCopy
            let newEl = {};

            if (activeEl.toCreate == 'Base') {
               newEl = {
                  idProgram: '[NEW]',
                  tipo: activeEl.toCreate,
                  restype: 'base',
                  activeEl: true,
                  Title: activeEl.Title ? activeEl.Title : '???',
                  OriginalTitle: activeEl.OriginalTitle,
                  Year: activeEl.Year,
                  requested: true,
                  seasons: [{
                     idProgram: '[NEW]',
                     tipo: 'Stagione',
                     activeEl: true,
                     Title: activeEl.Title ? activeEl.Title : '???',
                     OriginalTitle: activeEl.OriginalTitle,
                     SeriesNumber: activeEl.SeriesNumber,
                     Year: activeEl.Year,
                  }],
               };

               treeCopy = newEl;

            } else if (activeEl.toCreate == 'Programma') {
               newEl = {
                  idProgram: '[NEW]',
                  tipo: activeEl.toCreate,
                  restype: 'program',
                  activeEl: true,
                  Title: activeEl.Title ? activeEl.Title : '???',
                  OriginalTitle: activeEl.OriginalTitle,
                  Year: activeEl.Year,
               };

               treeCopy = newEl;

            } else if (activeEl.toCreate == 'Stagione') {
               newEl = {
                  activeEl: true,
                  tipo: activeEl.toCreate,
                  restype: 'program',
                  idProgram: '[NEW]',
                  episodioNumerico: parseInt(activeEl.EpisodeNumber),
                  Title: activeEl.Title ? activeEl.Title : '???',
                  OriginalTitle: activeEl.OriginalTitle,
                  SeriesNumber: activeEl.SeriesNumber,
                  Turn: activeEl.Turn,
                  TxDate: activeEl.TxDate,
                  Location: activeEl.Location,
               };

               treeCopy.seasons.push(newEl);
               treeCopy.seasons = _.orderBy(treeCopy.seasons, [
                  (o) => o.SeriesNumber ? parseInt(o.SeriesNumber) : 0,
                  (o) => o.Year ? parseInt(o.Year) : 0,
                  'Title'
               ], ['asc', 'asc', 'asc']);


            } else if (activeEl.toCreate == 'Episodio') {
               let stwep = treeCopy.seasons.find(s => s.idProgram == idProgram);
               if (!stwep) stwep = treeCopy.seasons[0];

               newEl = {
                  activeEl: true,
                  tipo: 'Episodio',
                  restype: 'episode',
                  idEpisode: '[NEW]',
                  idProgram: stwep.idProgram,
                  episodioNumerico: parseInt(activeEl.EpisodeNumber),
                  Title: stwep.Title,
                  SeriesNumber: stwep.SeriesNumber,
                  Year: stwep.Year,
                  OriginalTitle: stwep.OriginalTitle,
                  epTitle: activeEl.Title ? activeEl.Title : '???',
                  epOriginalTitle: activeEl.OriginalTitle,
                  Turn: activeEl.Turn,
                  TxDate: activeEl.TxDate,
                  Location: activeEl.Location,
               };

               if (!stwep.episodes) stwep.episodes = [];
               stwep.episodes.push(newEl);
               stwep.episodes = _.orderBy(stwep.episodes, [
                  (o) => o.episodioNumerico ? parseInt(o.episodioNumerico) : 0,
                  (o) => o.Turn ? parseInt(o.Turn) : 0,
                  'Title'
               ], ['asc', 'asc', 'asc']);

            }

         } else {
            let daFareActive = null;
            if (activeEl.idEpisodeToUpdate) {
               // Evidenzio l'episodio
               daFareActive = myFunc.customFilter(treeCopy, 'idEpisode', activeEl.idEpisodeToUpdate);

            } else if (activeEl.idProgramToUpdate && activeEl.idProgramToUpdate == activeEl.idProgramBase) {
               // Evidenzio la Base
               daFareActive = treeCopy;

            } else if (activeEl.idProgramToUpdate) {
               // Evidenzio la Stagione
               // console.log('cerco', activeEl.idProgramToUpdate, 'dentro', treeCopy)
               daFareActive = myFunc.customFilter(treeCopy, 'idProgram', activeEl.idProgramToUpdate);

            }

            if (daFareActive) daFareActive.activeEl = true;
         }
      }

      // console.log('[printProgramTreeNew] treeCopy:', treeCopy);
      // console.log('[printProgramTreeNew] activeEl in treeCopy:', myFunc.customFilter(treeCopy, 'activeEl', true));

      // Print tree container
      $dest.find('.mTree').remove();
      let $tree = $(`<div class="mTree"></div>`).appendTo($dest);
      $tree.data('tree', treeCopy); // Tree Data

      // Stampa a partire dalla Base
      if (levelToPrint == 0) {
         if (treeCopy.idProgram) {
            printBase($tree);
         } else {
            if (treeCopy.seasons && treeCopy.seasons.length) {
               printSeasons($tree, treeCopy.seasons);
            }
         }
      }

      // Stampa a partire dalle Stagioni
      if (levelToPrint == 1) {
         if (treeCopy.seasons && treeCopy.seasons.length) {
            printSeasons($tree, treeCopy.seasons);
         }
      }

      // Sampa solo episodi dell'id == levelToPrint
      if (levelToPrint > 2) {
         if (treeCopy.seasons && treeCopy.seasons.length) {
            let season = treeCopy.seasons.find((s) => s.idProgram == levelToPrint);
            if (season) {
               printEpisodes($tree, season);
            }
         }
      }

      // Apri Selezionato
      if (treeCopy.seasons) {
         treeCopy.seasons.forEach(s => {
            let activeEp = s.episodes ? s.episodes.find(e => e.activeEl || e.idEpisode == '[NEW]') : null;

            // Se la Stagione è attiva o contiene un Episodio Attivo apro la Base
            let $base = $tree.find(`[data-treetype="base"][data-treeid="${treeCopy.idProgram}"]`);
            if ((s.activeEl || s.idProgram == '[NEW]') || activeEp) {
               openSub($base);
            }

            // Se la stagione contiene un Episodio Attivo lo apro
            let $stag = $tree.find(`[data-treetype="stagione"][data-treeid="${s.idProgram}"]`);
            if (activeEp) {
               openSub($stag);
            }

         });
      }

      // Scroll fino all'elemento attivo on document ready
      $(function () {
         let $active = $dest.find('.myRes.activeElement');
         if ($active.length) {
            $dest.animate({
               scrollTop: $active.offset().top - $dest.offset().top + $dest.scrollTop() - ($dest.height() / 3),
            }, 5);
         }
      });

      // Apri / Chiudi
      $tree.on('click', '[data-eplist], [data-stlist]', function (e) {
         e.preventDefault();
         e.stopPropagation();
         let $tItem = $(this).closest('.tItem');

         if ($tItem.find('.sub').length) {
            removeSub($tItem);

         } else {
            openSub($tItem);
         }
      });

      function openSub($tItem) {
         if (!$tItem.length) return;
         let tipo = $tItem.attr('data-treetype');
         let id = $tItem.attr('data-treeid');

         let $sub = $('<div class="sub"></div>').appendTo($tItem);

         if (tipo == 'base') {
            printSeasons($sub, treeCopy.seasons);

         } else if (tipo == 'stagione') {
            let season = treeCopy.seasons.find((s) => s.idProgram == id);
            if (season) {
               printEpisodes($sub, season);
            }
         }
      }

      function removeSub($tItem) {
         $tItem.find('.sub').remove();
      }

      function printBase($d) {
         let baseData = treeCopy;
         let html = '';
         html += `
            <div class="tItem" data-treetype="base" data-treeid="${treeCopy.idProgram}">
               ${myProgramSearch.getHtmlForSat(baseData)}
            </div>`;

         let $base = $(html).appendTo($d);
         $base.find('.myRes').data('result', baseData);
         return $base;
      }

      function printSeasons($d, seasonsData) {
         if (!seasonsData || !seasonsData.length) return;

         let html = '';
         let l = seasonsData.length;
         for (let i = 0; i < l; i++) {
            let s = seasonsData[i];

            html += `
            <div class="tItem" data-treetype="stagione" data-treeid="${s.idProgram}">
               ${myProgramSearch.getHtmlForSat(s)}
            </div>`;
         }

         let $seasons = $(html).appendTo($d);

         // assign season data for on "choosed" event
         seasonsData.forEach((s, i) => {
            let $season = $tree.find(`[data-treetype="stagione"][data-treeid="${s.idProgram}"]`);
            $season.find('.myRes').data('result', s);
         });

         return $seasons;
      }

      function printEpisodes($d, season) {
         let episodes = season.episodes;
         if (!episodes || !episodes.length) return;

         let episodesData = episodes;

         let html = '';
         let l = episodesData.length;
         for (let i = 0; i < l; i++) {
            let e = episodesData[i];

            html += `
               <div class="tItem" data-treetype="episodio" data-treeid="${e.idEpisode}">
                  ${myProgramSearch.getHtmlForSat(e)}
               </div>`;
         }

         let $episodes = $(html).appendTo($d);

         // assign episode data for on "choosed" event
         episodesData.forEach((e, i) => {
            let $episode = $tree.find(`[data-treetype="episodio"][data-treeid="${e.idEpisode}"]`);
            $episode.find('.myRes').data('result', e);
         });

         return $episodes;
      }
   },

   getItemFromCreateData: async function (createData) {
      if (!myVars.SatelliteCategoryGenre) {
         myVars.SatelliteCategoryGenre = await client.service('program-join').get('SatelliteCategoryGenre');
      }

      const [objBase, objProgram, objEpisode] = createData;
      let item = {
         restype: objEpisode ? 'episode'
            : objProgram ? 'program'
               : objBase ? 'base'
                  : null
      };

      if (!item.restype) return null;

      if (objBase) {
         for (const key in objBase) {
            let newKey = key;
            item.idBase = objBase.ID;
            item._tempID = objBase._tempID;
            if (key == 'ID') newKey = 'idProgram';
            else if (key == '_tempID') newKey = '_tempIdBase';

            if (key == 'IDCategory') {
               if (objBase[key])
                  item.Category_Name = myVars.SatelliteCategoryGenre.find(c => c.IDCategory == objBase[key])?.Category;

            } else if (key == 'IDGenre') {
               if (objBase[key]) {
                  item.genres = objBase[key].toString().split(', ')
                     .map(ig => myVars.SatelliteCategoryGenre.find(g => g.IDGenre == ig)?.genre)
                     .filter(gN => gN)
                     .join(',');
               }

            } else {
               item[newKey] = objBase[key];
            }
         }
      }

      if (objProgram) {
         for (const key in objProgram) {
            let newKey = key;
            item._tempID = objProgram._tempID;
            if (key == 'ID') newKey = 'idProgram';
            else if (key == '_tempID') newKey = '_tempIdProgram';

            if (key == 'IDCategory') {
               if (objProgram[key])
                  item.Category_Name = myVars.SatelliteCategoryGenre.find(c => c.IDCategory == objProgram[key])?.Category;

            } else if (key == 'IDGenre') {
               if (objProgram[key]) {
                  item.genres = objProgram[key].toString().split(', ')
                     .map(ig => myVars.SatelliteCategoryGenre.find(g => g.IDGenre == ig)?.genre)
                     .filter(gN => gN)
                     .join(',');
               }

            } else {
               item[newKey] = objProgram[key];
            }
         }
      }

      if (objEpisode) {
         for (const key in objEpisode) {
            let newKey = key;
            item._tempID = objEpisode._tempID;
            if (key == 'ID') newKey = 'idEpisode';
            else if (key == '_tempID') newKey = '_tempIdEpisode';
            else if (key == 'EpisodeNumber') newKey = 'episodioNumerico';
            else if (key in item) newKey = 'ep' + key;

            item[newKey] = objEpisode[key];
         }
      }

      if (item.restype == 'base') item.seasons = [];
      else if (item.restype == 'program') item.episodes = [];
      return item;
   },

   // ------------------------------------------------------------------------------------------------------------- HTML
   // ------------------------------------------------------------------------------------------------------------------
   getHtmlForHeader: function (data, dataEp, nEpisodi) {
      let html = ``;
      if (!data) return html;

      if (data.idProgram) data.ID = data.idProgram;
      if (data.idBase) data.idProgramBase = data.idBase;

      let tipo = dataEp ? 'Episodio'
         : data.idProgramBase == '[NEW]' ? 'Stagione'
            : data.idProgramBase && data.idProgramBase == data.ID ? 'Base'
               : data.idProgramBase && data.idProgramBase != data.ID ? 'Stagione'
                  : 'Programma';

      // IDS
      html += `
         ${data.idProgramBase ? `<div data-tocopy="${data.idProgramBase}" class="ui horizontal label orange" style="margin: 3px 0 0 0;">BA ${data.idProgramBase}</div>` : ''}
         ${tipo != 'Base' && data.ID ? `<div data-tocopy="${data.ID}" class="ui horizontal label blue" style="margin: 3px 0 0 0;">PR ${data.ID}</div>` : ''}
         ${dataEp ? `<div data-tocopy="${dataEp.ID}" class="ui horizontal label teal" style="margin: 3px 0 0 0;">EP ${dataEp.ID}</div>` : ''}
         <span class="ui grey text opacita4">/</span>
      `;

      // TITOLI
      html += `
         ${data.Title ? data.Title : ''}
         ${data.SeriesNumber ? `<span class="ui blue text">S${data.SeriesNumber}</span>` : ``}
         ${data.nota ? `<span class="ui grey text opacita4">(${data.nota})</span>` : ''}
         ${nEpisodi ? `<span class="ui grey text opacita4">(contiene ${nEpisodi} episodi)</span>` : ''}
         ${dataEp ? `
            ${data ? `<span class="ui grey text opacita4">/</span>` : ''}
            <span class="ui teal text">E${dataEp.episodioNumerico ? dataEp.episodioNumerico : data.EpisodeNumber ? data.EpisodeNumber : '--'}</span>
            ${dataEp.Title ? dataEp.Title : '???'}
         ` : ''}
      `;

      return html;
   },

   getHtmlForRequestHeader: function (data, resp) {
      let html = ``;
      if (!data) return html;

      let tipo = data.TipoRichiesta == 'TT' ? 'Programma'
         : data.TipoRichiesta == 'SE' ? 'Serie'
            : data.TipoRichiesta == 'SS' ? 'Stagione'
               : data.TipoRichiesta == 'EP' ? 'Episodio'
                  : null;

      // IDS
      html += `
         ${data.externalID ? `<div data-tocopy="${data.externalID}" class="ui horizontal label" style="margin: 3px 0 0 0; max-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">EX ${data.externalID}</div>` : ''}
         ${data.titleIDRichiesto ? `<div data-tocopy="${data.titleIDRichiesto}" class="ui horizontal label purple" style="margin: 3px 0 0 0;">EP ${data.titleIDRichiesto}</div>` : ''}
         <span class="ui grey text opacita4">/</span>
      `;

      // TITOLI
      let st = resp && resp.seasonNumber ? resp.seasonNumber : data.num_stagione;
      let ep = resp && resp.episodeNumber ? resp.episodeNumber : data.episodio;
      html += `
         ${data.titolo ? data.titolo : ''}
         ${data.num_stagione ? `<span class="ui blue text">S${st}</span>` : ``}
         ${tipo == 'Episodio' ? `
            ${data ? `<span class="ui grey text opacita4">/</span>` : ''}
            <span class="ui teal text">E${ep ? ep : '--'}</span>
         ` : ''}
      `;

      return html;
   },

   // Render oggetti
   getHtmlForSat: function (data) {
      let dateCol = ``;
      let idsCol = ``;
      let sepCol = ``;
      let titCol = ``;
      let footer = ``;
      let corCol = ``;

      // --------------------------------------------------------------------------------------------------------------- Data Programmazione
      if (data && data.dateTime) {
         let mome = moment(data.dateTime, 'YYYY-MM-DD HH:mm:ss');
         let info = mome.format('dddd');

         if (data.attributeMask) {
            let attributes = myFunc.getAttributesFromMask(data.attributeMask).map(a => a.Name);

            if (attributes.includes('Diretta'))
               info = `<div class="ui smallest red label">DIRETTA</div>`;
            else if (attributes.includes('Prima Visione Assoluta'))
               info = `<div class="ui smallest red label">PRIMA VISIONE ASSOLUTA</div>`;
            else if (attributes.includes('Prima Visione'))
               info = `<div class="ui smallest basic red label">PRIMA VISIONE</div>`;
            else if (attributes.includes('Differita'))
               info = `<div class="ui smallest label">DIFFERITA</div>`;
            else if (attributes.includes('Replica'))
               info = `<div class="ui smallest label">REPLICA</div>`;
            else if (attributes.includes('Sintesi'))
               info = `<div class="ui smallest label">SINTESI</div>`;
         }

         dateCol = `
            <div class="dateTime">
               <div class="infoLabel"><b>${mome.format('HH:mm')}</b></div>
               <div class="infoLabel">${mome.format('DD/MM/YY')}</div>
               <div class="infoLabel">${info}</div>
            </div>
         `;
      }

      // --------------------------------------------------------------------------------------------------------------- IDS
      idsCol += `<div class="ids">`;

      if (data.restype == 'base') {
         idsCol += `
            <div class="ui smallest label orange">BASE</div>
            <div class="ui smallest label" data-tocopy="${data.idProgram}">BA ${data.idProgram}</div>
         `;

      } else if (data.restype == 'program') {
         idsCol += `
            ${data.idBase ? `<div class="ui smallest label" data-tocopy="${data.idBase}">BA ${data.idBase}</div>` :
               '<div class="infoLabel">&nbsp;</div>'}
            <div class="ui smallest label blue" data-tocopy="${data.idProgram}">PR ${data.idProgram}</div>
         `;

      } else if (data.restype == 'episode') {
         idsCol += `
            <div class="ui smallest label" data-tocopy="${data.idProgram}">PR ${data.idProgram}</div>
            <div class="ui smallest label teal" data-tocopy="${data.idEpisode}">EP ${data.idEpisode}</div>
         `;

      }

      idsCol += `<div class="infoLabel">${data.Year ? data.Year : '&nbsp;'}${data.late2live ? `<i class="flag purple fitted icon" title="La7 Late2Live"></i>`: ''}</div></div>`;

      // --------------------------------------------------------------------------------------------------------------- Stagione Episodio Anno
      sepCol += `<div class="seasonEpisode">`;
      if (data.restype == 'base') {
         sepCol += `
            <div class="infoLabel a_orange">BA</div>
         `;

      } else if (data.restype == 'program') {
         sepCol += `
            <div class="infoLabel a_blue">${data.SeriesNumber ? 'S' + data.SeriesNumber : '&nbsp;'}</div>
         `;

      } else if (data.restype == 'episode') {
         sepCol += `
            <div class="infoLabel">S${data.SeriesNumber ? data.SeriesNumber : '--'}</div>
            <div class="infoLabel a_teal">E${data.episodioNumerico ? data.episodioNumerico : '--'}</div>
         `;

      }

      let prossTit = [];
      if (data.prossimoPassaggio) {
         let prossDate = moment(data.prossimoPassaggio).utc().format('dddd DD/MM/YY HH:mm');
         prossTit.push(`Prossimo Passaggio: ${prossDate}`);
      }

      if (data.passaggi)
         prossTit.push(`In Onda ${data.passaggi} volte negli ultimi 2 mesi`);

      if (data.canali)
         prossTit.push(`Trasmesso negli ultimi 12 mesi sui canali:\n${data.canali.map(c => c.name).join('\n')}`);

      sepCol += `<div class="infoLabel">`;
      if (!prossTit.length && !data.lastContent) sepCol += `&nbsp;`;
      else {
         sepCol += prossTit.length ? `<i class="tv ${data.prossimoPassaggio ? 'red' : ''} fitted icon" title="${prossTit.join('\n')}"></i>` : '';
         sepCol += data.lastContent ? `<i class="linkify fitted icon" title="Ultima Risposta Content: ${data.lastContent}"></i>` : '';
      }
      sepCol += `</div></div>`;

      // --------------------------------------------------------------------------------------------------------------- Titolo
      if (!data.TitleMarked) data.TitleMarked = data.Title;
      if (!data.epTitleMarked) data.epTitleMarked = data.epTitle;
      if (!data.OriginalTitleMarked) data.OriginalTitleMarked = data.OriginalTitle;
      if (!data.epOriginalTitleMarked) data.epOriginalTitleMarked = data.epOriginalTitle;

      let TitleTit = [data.Title, data.OriginalTitle, data.appTitle].filter(t => t).join('\n');
      let epTitleTit = [data.epTitle, data.epOriginalTitle].filter(t => t).join('\n');

      let descriptionsLength = data.DescriptionChars ? data.DescriptionChars : data.Description && typeof data.Description === 'string' ? data.Description.length : 0;
      if (data.restype == 'episode') descriptionsLength += `/${data.epDescriptionChars ? data.epDescriptionChars : data.epDescription ? data.epDescription.length : 0}`;

      let info = [descriptionsLength, data.nota, data.Turn, data.TxDate, data.Location, data.Category_Name, data.genres].filter(t => t || t === 0);

      if (data.restype == 'base') {
         titCol += `
            <div class="text" data-modalschedadb="Base,${data.idProgram}">
               <div class="title" title="${TitleTit}">${data.TitleMarked}</div>
               <div title="${info.join('\n')}">${/hardcore/gi.test(data.genres) || (data.parental && data.parental.find(p => p.id == 7)) ? '<span class="a_red">VM18</span> &bull; ' : ''}${info.join(' &bull; ')}</div>
               <div>${data.OriginalTitleMarked ? data.OriginalTitleMarked : '--'}</div>
            </div>`;

      } else if (data.restype == 'program') {
         titCol += `
            <div class="text" data-modalschedadb="Programma,${data.idProgram}">
               <div class="title" title="${TitleTit}">${data.TitleMarked}</div>
               <div title="${info.join('\n')}">${/hardcore/gi.test(data.genres) || (data.parental && data.parental.find(p => p.id == 7)) ? '<span class="a_red">VM18</span> &bull; ' : ''}${info.join(' &bull; ')}</div>
               <div>${data.OriginalTitleMarked ? data.OriginalTitleMarked : '--'}</div>
            </div>`;

      } else if (data.restype == 'episode') {
         titCol += `
            <div class="text" data-modalschedadb="Episodio,${data.idProgram},${data.idEpisode}">
               <div title="${TitleTit}">${data.TitleMarked}</div>
               <div class="title a_teal" title="${epTitleTit}">${data.epTitleMarked}</div>
               <div title="${/hardcore/gi.test(data.genres) || (data.parental && data.parental.find(p => p.id == 7)) ? '<span class="a_red">VM18</span> &bull; ' : ''}${info.join('\n')}">${info.join(' &bull; ')}</div>
               <div>${data.epOriginalTitleMarked ? data.epOriginalTitleMarked : '--'}</div>
            </div>`;
      }

      // --------------------------------------------------------------------------------------------------------------- Footer
      let creazione = data.dataCreazione ? moment(data.dataCreazione).utc().format('dddd DD/MM/YYYY HH:mm') : '';
      let modifica = data.dataUltimaModifica ? moment(data.dataUltimaModifica).utc().format('dddd DD/MM/YYYY HH:mm') : '';

      let titFooter = [];
      if (creazione && data.utenteCreazione)
         titFooter.push(`Creato ${creazione} da ${data.utenteCreazione ? data.utenteCreazione.replace(/_/gi, ' ') : '???'}`);
      if (modifica && data.utenteModifica)
         titFooter.push(`Modificato ${modifica} da ${data.utenteModifica ? data.utenteModifica.replace(/_/gi, ' ') : '???'}`);

      if (titFooter.length) {
         footer += `
            <div class="footer" title="${titFooter.join('\n')}"><i class="fitted info circle icon"></i></div>
         `;
      }

      // --------------------------------------------------------------------------------------------------------------- Corner
      if (data.restype == 'base' && data.stagioni && data.stagioni > 0)
         corCol += `<div class="ui mini left corner label orange" data-stlist="${data.idProgram}"><div>${data.stagioni}</div></div>`;
      else if (data.restype == 'program' && data.episodi && data.episodi > 0)
         corCol += `<div class="ui mini left corner label blue" data-eplist="${data.idProgram}"><div>${data.episodi}</div></div>`;

      // --------------------------------------------------------------------------------------------------------------- Return
      let fromHistory = data.matched && /history/gi.test(data.matched);
      let html = `
         <div class="myRes ${fromHistory ? 'striped' : ''} ${data.idProgram == '[NEW]' || data.idEpisode == '[NEW]' ? 'new' : ''} result${data.activeEl ? ' fadeInAndOut activeElement' : ''}"
            data-restype="${data.restype}"
            data-idprogram="${data.idProgram}"
            ${data.idEpisode ? `data-idepisode="${data.idEpisode}"` : ''}
            ${data._tempID ? `data-tempid="${data._tempID}"` : ''}>
            ${dateCol}${idsCol}${sepCol}${titCol}${footer}${corCol}
         </div>
      `;

      return html;
   },

   // ------------------------------------------------------------------------------------------------- DA RICONTROLLARE
   // ------------------------------------------------------------------------------------------------------------------
   getHtmlForRequest: function (data) {
      const infoLine1 = [];
      const infoLine2 = [];
      let rColor = '';

      if (data.TipoRichiesta == 'TT') {
         data.TipoRichiesta = 'Programma'
         rColor = 'blue';
      };
      if (data.TipoRichiesta == 'SE') {
         data.TipoRichiesta = 'Serie';
         rColor = 'orange';
      };
      if (data.TipoRichiesta == 'SS') {
         data.TipoRichiesta = 'Stagione';
         rColor = 'blue';
      };
      if (data.TipoRichiesta == 'EP') {
         data.TipoRichiesta = 'Episodio';
         rColor = 'teal';
      };

      if (data.tipologia) infoLine1.push(myFunc.camelize(data.tipologia));
      if (data.genres) infoLine1.push(data.genres);
      if (data.TipoRichiesta) infoLine2.push(data.TipoRichiesta);

      if (data.ethanMetadatasets) {
         myVars.metadataSets.forEach((m) => {
            let toCheck = m.value;
            if (m.value == 'nowtv') toCheck = 'now';
            if (m.value == 'stbfusion') toCheck = 'fusion';

            if (data.ethanMetadatasets.toLowerCase().indexOf(toCheck.toLowerCase()) > -1) {
               infoLine2.push(`<span class="a_${m.color}">${m.name}</span>`);
            }
         });
      }

      let html = `
         <div class="myRes result" data-restype="${data.restype}">
            <!--IDS-->
            <div class="ids">
               <div class="infoLabel">${data.ethanFlag == 0 ? 'TIM' : 'SKY'}</div>
               <div class="ui smallest label ${data.ethanFlag == 0 ? 'red' : 'blue'}">${data.externalID}</div>
               <div class="infoLabel">${data.Year ? data.Year : ''}</div>
            </div>
            <!--SEASON EPISODE-->
            <div class="seasonEpisode">
               <div class="infoLabel">
               <i class="question circle ${data.risposta ? '' : ''} icon fitted" title="
                  ${data.risposta ? `Risposta: ${data.risposta}` : 'Nessuna Risposta Presente'}${data.data_invio ? ` - Ultimo Invio ${moment(data.data_invio).format('DD-MM-YY, HH:mm')}` : ''}
                  "></i>
               </div>
               <div class="infoLabel">${data.SeriesNumber && data.SeriesNumber.length > 3 ? data.SeriesNumber : data.SeriesNumber ? 'S' + data.SeriesNumber : ''}</div>
               <div class="infoLabel">${data.episodioNumerico ? 'e' + data.episodioNumerico : ''}</div>
            </div>
            <!--TEXT-->
            <div class="text">
               <span>${infoLine2.join(' &bull; ')}</span><br>
               <span class="title">${data.TitleMarked ? data.TitleMarked : data.Title}</span><br>
               <span>${infoLine1.join(' &bull; ')}</span>
            </div>
            <!--CORNER-->
            <a target="_blank" href="https://content2.datatv.it/schedaRedir.asp?idRichiesta=${data.id}" class="ui mini right corner label ${data.ethanFlag == 0 ? 'red' : 'blue'}"><i class="external alternate icon"></i></a>
            <!--INFO-->
            <div class="bottomInfo">
               <i class="clock outline icon" title="Ultimo Aggiornamento"></i>${moment(data.data_aggiornamento ? data.data_aggiornamento : data.data_ricevimento ? data.data_ricevimento : '').format('DD-MM-YY, HH:mm')}
            </div>
         </div>`

      return html;
   },

   getHtmlForResponse: function (data) {
      if (!r.richieste || !r.richieste.length) return '';

      let html = '';
      const infoLine1 = [];
      const infoLine2 = [];

      if (data.detailedCategory) infoLine1.push(data.detailedCategory);
      if (data.genres) infoLine1.push(data.genres.split('#').join(', '));
      infoLine2.push(data.OriginalTitleMarked ? data.OriginalTitleMarked : data.OriginalTitle ? data.OriginalTitle : '');

      if (data.idSerie && data.idSerie != -1 && data.idProgram && data.idSerie == data.idProgram) {
         // RISPOSTA BASE
         html = `
            <div class="myRes result" data-restype="${data.restype}">
               <!--IDS-->
               <div class="ids">
               <div class="ui smallest label orange">BASE</div>
               <div class="ui smallest label">PR ${data.idProgram}</div>
               <div class="infoLabel">${data.Year ? data.Year : ''}</div>
               </div>
               <!--IMG-->
               <div class="img" ${data.image ? `style="background: url('${mySite}/content-imgs/${data.image}');"` : ''}></div>
               <!--SEASON EPISODE-->
               <div class="seasonEpisode">
               <div class="infoLabel">
                  ${data.richieste && data.richieste.length ? `<i class="${data.richieste[0].ethanFlag == 0 ? 'red' : 'blue'} question circle icon fitted"
                     title="Risposta: ${data.id} - Ultimo Invio ${data.richieste[0].ethanFlag == 0 ? 'TIM' : 'SKY'}: ${moment(data.data_invio).format('DD-MM-YY, HH:mm')}"></i>` : ''}
               </div>
               <div class="infoLabel a_orange">BA</div>
               &nbsp;
               </div>
               <!--TEXT-->
               <div class="text">
               <span class="title">${data.TitleMarked ? data.TitleMarked : data.Title}</span><br>
               <span>${infoLine1.join(' &bull; ')}</span><br>
               <span>${infoLine2.join(' &bull; ')}</span>
               </div>
               <!--CORNER-->
               ${data.richieste && data.richieste.length ? `<a target="_blank" href="https://content2.datatv.it/schedaRedir.asp?idRichiesta=${data.richieste[0].id}" class="ui mini right corner label orange"><i class="external alternate icon"></i></a>` : ''}
               <!--BTNS-->
               <div class="btns"></div>
               <!--INFO-->
               <div class="bottomInfo">
               ${data.richieste && data.richieste.length ? `<i class="question circle icon" title="Richieste Collegate"></i>${data.richieste.length}&nbsp;` : ''}
               ${data.immagini ? `<i class="images outline icon" title="Immagini presenti"></i>${data.immagini}&nbsp;` : ''}
               ${data.data_aggiornamento ? `<i class="clock outline icon" title="Ultimo Aggiornamento"></i>${moment(data.data_aggiornamento).format('DD-MM-YY, HH:mm')}` : ''}
               </div>
            </div>`;

      } else if (data.idEpisode && data.idEpisode != -1) {
         // RISPOSTA EPISODE
         html = `
            <div class="myRes result" data-restype="${data.restype}">
               <!--IDS-->
               <div class="ids">
               <div class="ui smallest label">PR ${data.idProgram}</div>
               <div class="ui smallest label teal">EP ${data.idEpisode}</div>
               <div class="infoLabel">${data.Year ? data.Year : ''}</div>
               </div>
               <!--IMG-->
               <div class="img" ${data.image ? `style="background: url('${mySite}/content-imgs/${data.image}');"` : ''}></div>
               <!--SEASON EPISODE-->
               <div class="seasonEpisode">
               <div class="infoLabel">
                  ${data.richieste && data.richieste.length ? `<i class="${data.richieste[0].ethanFlag == 0 ? 'red' : 'blue'} question circle icon fitted"
                     title="Risposta: ${data.id} - Ultimo Invio ${data.richieste[0].ethanFlag == 0 ? 'TIM' : 'SKY'}: ${moment(data.data_invio).format('DD-MM-YY, HH:mm')}"></i>` : ''}
               </div>
               <div class="infoLabel">${data.SeriesNumbedata.length > 3 ? data.SeriesNumber : 'S' + data.SeriesNumber}</div>
               <div class="infoLabel a_teal">e${data.episodioNumerico ? data.episodioNumerico : '--'}</div>
               &nbsp;
               </div>
               <!--TEXT-->
               <div class="text">
               <span>${data.TitleMarked ? data.TitleMarked : data.Title}</span><br>
               <span class="title a_teal">${data.epTitleMarked ? data.epTitleMarked : data.epTitle}</span><br>
               <span>${infoLine1.join(' &bull; ')}</span>
               </div>
               <!--CORNER-->
               ${data.richieste && data.richieste.length ? `<a target="_blank" href="https://content2.datatv.it/schedaRedir.asp?idRichiesta=${data.richieste[0].id}" class="ui mini right corner label orange"><i class="external alternate icon"></i></a>` : ''}
               <!--BTNS-->
               <div class="btns"></div>
               <!--INFO-->
               <div class="bottomInfo">
               ${data.richieste && data.richieste.length ? `<i class="question circle icon" title="Richieste Collegate"></i>${data.richieste.length}&nbsp;` : ''}
               ${data.immagini ? `<i class="images outline icon" title="Immagini presenti"></i>${data.immagini}&nbsp;` : ''}
               ${data.data_aggiornamento ? `<i class="clock outline icon" title="Ultimo Aggiornamento"></i>${moment(data.data_aggiornamento).format('DD-MM-YY, HH:mm')}` : ''}
               </div>
            </div>`;

      } else {
         // RISPOSTA PROGRAM
         html = `
            <div class="myRes result" data-restype="${data.restype}">
               <!--IDS-->
               <div class="ids">
               ${data.idSerie && data.idSerie != -1 ? `<div class="ui smallest label">BA ${data.idSerie}</div>` : '<div class="infoLabel"></div>'}
               <div class="ui smallest label blue">PR ${data.idProgram}</div>
               <div class="infoLabel">${data.Year ? data.Year : ''}</div>
               </div>
               <!--IMG-->
               <div class="img" ${data.image ? `style="background: url('${mySite}/content-imgs/${data.image}');"` : ''}></div>
               <!--SEASON EPISODE-->
               <div class="seasonEpisode">
               <div class="infoLabel">
                  ${data.richieste && data.richieste.length ? `<i class="${data.richieste[0].ethanFlag == 0 ? 'red' : 'blue'} question circle icon fitted"
                     title="Risposta: ${data.id} - Ultimo Invio ${data.richieste[0].ethanFlag == 0 ? 'TIM' : 'SKY'}: ${moment(data.data_invio).format('DD-MM-YY, HH:mm')}"></i>` : ''}
               </div>
               ${data.SeriesNumber ? `<div class="infoLabel a_blue">${data.SeriesNumber.length > 3 ? data.SeriesNumber : data.SeriesNumber ? 'S' + data.SeriesNumber : ''}</div>` : ''}
               &nbsp;
               </div>
               <!--TEXT-->
               <div class="text">
               <span class="title">${data.TitleMarked ? data.TitleMarked : data.Title}</span><br>
               <span>${infoLine1.join(' &bull; ')}</span><br>
               <span>${infoLine2.join(' &bull; ')}</span>
               </div>
               <!--CORNER-->
               ${data.richieste && data.richieste.length ? `<a target="_blank" href="https://content2.datatv.it/schedaRedir.asp?idRichiesta=${data.richieste[0].id}" class="ui mini right corner label orange"><i class="external alternate icon"></i></a>` : ''}
               <!--BTNS-->
               <div class="btns"></div>
               <!--INFO-->
               <div class="bottomInfo">
               ${data.richieste && data.richieste.length ? `<i class="question circle icon" title="Richieste Collegate"></i>${data.richieste.length}&nbsp;` : ''}
               ${data.immagini ? `<i class="images outline icon" title="Immagini presenti"></i>${data.immagini}&nbsp;` : ''}
               ${data.data_aggiornamento ? `<i class="clock outline icon" title="Ultimo Aggiornamento"></i>${moment(data.data_aggiornamento).format('DD-MM-YY, HH:mm')}` : ''}
               </div>

            </div>`;
      }

      return html;
   },

   getHtmlForPerson: function (data) {
      let html = `
         <div class="myRes result" data-restype="${data.restype}">
            <!--IDS-->
            <div class="ids">
               ${data.idDB ? `<div class="ui smallest blue label" title="ID DataTv">${data.idDB}</div>` : ''}
               ${data.IMDBID ? `<div class="ui smallest yellow label" title="ID IMDb">${data.IMDBID}</div>` : ''}
               <!--${data.tvDBID ? `<div class="ui smallest teal label" title="ID TVDb">${data.tvDBID}</div>` : ''}-->
            </div>
            <!--IMG-->
            <div class="img" ${data.image ? `style="background: url('${mySite}/personaggi/${data.image}');"` : ''}></div>
            <!--TEXT-->
            <div class="text">
               <span>${data.gender == 'M' ? 'Male' : data.gender == 'F' ? 'Female' : '&nbsp;'}</span><br>
               <span class="title">${data.Name} ${data.Surname}</span><br>
               <span>${data.birthDate ? `Nato il ${data.birthDate}` : ''}</span>&nbsp;
               <!--<span>${data.deathDate ? `Morto il ${data.deathDate}` : ''}</span>-->
            </div>
            <!--INFO-->
            ${data.data_aggiornamento ? `
               <div class="bottomInfo">
                  <i class="clock outline icon"></i>${moment(data.data_aggiornamento).format('DD-MM-YY, HH:mm')}
               </div>` : ''}
         </div>`;

      return html;
   },

   getHtmlForImdb: function (data) {
      let html = `
         <div class="myRes result" data-restype="${data.restype}">
            <!--IDS-->
            <div class="ids">
               <div class="infoLabel">IMDb</div>
               <div class="ui smallest label yellow">${data.id}</div>
               <div class="infoLabel">${data.year}</div>
            </div>
            <!--IMG-->
            <div class="img" style="background: url('${data.image}');"></div>
            <!--TEXT-->
            <div class="text">
               <br>
               <span class="title">${data.title}</span><br>
               <span>${data.type}</span>
            </div>
            <!--CORNER-->
            <a target="_blank" href="${data.link}" class="ui mini right corner label yellow"><i class="external alternate icon"></i></a>
         </div>`;

      return html;
   },

   getHtmlForTmdb: function (data) {
      let html = `
         <div class="myRes result" data-restype="${data.restype}">
         <!--IDS-->
         <div class="ids">
               <div class="infoLabel">TMDb</div>
               <div class="ui smallest label teal">${data.id}</div>
               <div class="infoLabel">${data.year}</div>
         </div>
         <!--IMG-->
         <div class="img" style="background: url('${data.image}');"></div>
         <!--TEXT-->
         <div class="text">
            <br>
            <span class="title">${data.title}</span><br>
            <span>${data.type}</span>
         </div>
         <!--CORNER-->
         <a target="_blank" href="${data.link}" class="ui mini right corner label teal"><i class="external alternate icon"></i></a>
         </div>`;

      return html;
   },

   getHtmlForImdbperson: function (data) {
      let html = `
         <div class="myRes result" data-restype="${data.restype}">
         <!--IDS-->
         <div class="ids">
               <div class="ui smallest label">????</div>
               <div class="ui smallest label">????</div>
               <div class="ui smallest label">????</div>
         </div>
         <!--IMG-->
         <div class="img"></div>
         <!--TEXT-->
         <div class="text">
            <span>Info Risultato molto lunghe, tanto da dover essere tagliate</span><br>
            <span class="title">Titolo risultato</span><br>
            <span>Info Risultato molto lunghe, tanto da dover essere tagliate</span>
         </div>
         <!--CORNER-->
         <a class="ui mini right corner label"><div>00</div></a>
         <!--BTNS-->
         <div class="btns">
            <a class="ui smallest label" title=""><i class="question icon"></i>??</a>
            <a class="ui smallest label" title=""><i class="question icon"></i>??</a>
            <a class="ui smallest label" title=""><i class="question icon"></i>??</a>
         </div>
         <!--INFO-->
         <div class="bottomInfo"><i class="question icon"></i>Small Info on top</div>
         </div>`;

      return html;
   },

   getHtmlForTmdbperson: function (data) {
      let html = `
         <div class="myRes result" data-restype="${data.restype}">
            <!--IDS-->
            <div class="ids">
               <div class="ui smallest label">????</div>
               <div class="ui smallest label">????</div>
               <div class="ui smallest label">????</div>
            </div>
            <!--IMG-->
            <div class="img"></div>
            <!--TEXT-->
            <div class="text">
               <span>Info Risultato molto lunghe, tanto da dover essere tagliate</span><br>
               <span class="title">Titolo risultato</span><br>
               <span>Info Risultato molto lunghe, tanto da dover essere tagliate</span>
            </div>
            <!--CORNER-->
            <a class="ui mini right corner label"><div>00</div></a>
            <!--BTNS-->
            <div class="btns">
               <a class="ui smallest label" title=""><i class="question icon"></i>??</a>
               <a class="ui smallest label" title=""><i class="question icon"></i>??</a>
               <a class="ui smallest label" title=""><i class="question icon"></i>??</a>
            </div>
            <!--INFO-->
            <div class="bottomInfo"><i class="question icon"></i>Small Info on top</div>
         </div>`;

      return html;
   }
};