// MODALTRAME
// modaltrame(90813, 151137), modaltrame(201077), modaltrame(170310, 714037)
// FRIENDS modaltrame(209471)
// SERIE A modaltrame(242141, 1268387)
module.exports = async function (idProgram, idEpisode) {
   const $loader = $(`
    <div class="ui active inverted dimmer modalLoader">
      <div class="ui indeterminate text loader">Loading</div>
    </div>
  `).appendTo('body');

   await myVars.channels.load();
   const data = await client.service('program-join').get(idProgram, { query: { add: 'program_synopsis, program_epg_synopsis, episodes, content, contentepisodes, vod, vodepisodes, nextair, onAirChannel, persons' } });
   const base = data.programserie && data.programserie.idProgramBase ? data.programserie.idProgramBase : false;
   const isBase = base == data.program.ID;

   // Ordino i programmi content per data
   data.content = _.sortBy(data.content, 'Dinizio').reverse();

   // Ordino i programmi VOD per data e tengo solo il primo
   if (data.vod.length) {
      data.vod = [_.sortBy(data.vod, 'dataCreazione').reverse()[0]];
   }

   // episodes
   data.episodes.forEach((ep) => {
      const content = data.contentepisodes.filter((ce) => ce.idEpisode == ep.ID);
      const vod = data.vodepisodes.reverse().filter((ve) => ve.idEpisode == ep.ID);
      ep.content = content && content.length ? _.sortBy(content, 'Dinizio').reverse() : [];
      ep.vod = vod && vod.length ? [_.sortBy(vod, 'dataCreazione').reverse()[0]] : [];
   });

   console.log('modaltrame:', data);

   // Modal
   const $modal = createModal(data);

   // Se richiesto Episodio
   if (idEpisode && idEpisode != -1) {
      $modal.find('[name="searchEpisodi"]').val(idEpisode).trigger('keyup');
      $modal.find('.menu [data-tab]').tab('change tab', 'trameep');

      const $segEp = $(`[data-idep="${idEpisode}"]`);

      $segEp.css('background', '#dff0ff');
      $segEp.find('.form').show();
      dataAndTextArea($segEp, data);
   }

   $loader.remove();

   $modal.modal({
      autofocus: false,
      allowMultiple: true,
      transition: 'scale',
   }).modal('show');

   function createModal(data) {
      // Rimuove tutte le modal
      $('#modalTrame').remove();

      // Creo una modal generica
      const $modal = $(`<div class="ui large modal" id="modalTrame"></div>`)
         .appendTo('body');

      // $header
      $(`<div class="header">
         <i class="align justify icon"></i>${myProgramSearch.getHtmlForHeader(data.program)}
      </div>`).appendTo($modal);

      // $menu
      $(`<div class="ui attached menu" style="border-top: 0; width: 100%; margin: 0;">
        <a class="active item" data-tab="tramepr"><i class="tv icon"></i>Trame Programma</a>
        ${data.episodes.length ? `
          <a class="item" data-tab="trameep"><i class="ordered list icon"></i>Trame Episodi</a>
          ` : ''}
        <div class="right menu">

          ${data.episodes.length ? `
            <div class="ui search vertically fitted item" style="width: 120px;">
              <div class="ui transparent icon input">
                <input class="prompt" type="text" placeholder="Episodi..." autocomplete="off" name="searchEpisodi">
                <i class="filter link icon"></i>
              </div>
            </div>
            ` : ''}

          <div class="ui search vertically fitted item" style="width: 200px;">
            <div class="ui transparent icon input">
              <input class="prompt" type="text" placeholder="Testi..." autocomplete="off" name="searchText">
              <i class="search link icon"></i>
            </div>
          </div>
        </div>
      </div>`).appendTo($modal);

      const $content = $(`<div class="scrolling content"></div>`).appendTo($modal);

      // $tramepr
      $(`<div class="ui tab" data-tab="tramepr">

        <div class="ui segment" style="background: #e8e8e8;">
          <form class="ui equal width form">
            <h2 class="ui header">Programma</h2>
            <div class="fields">
              <div class="field">
                <label>Descr. Generale</label>
                <textarea spellcheck="true" rows="6" data-max="200" name="program,Description"></textarea>
              </div>
              <div class="field">
                <label>Descr. Old</label>
                <textarea spellcheck="true" rows="6" data-max="0" class="secondaryInput" name="program,sinossi"></textarea>
              </div>
              <div class="field">
                <label>Commento</label>
                <textarea spellcheck="true" rows="6" data-max="0" class="secondaryInput" name="program,commento"></textarea>
              </div>
            </div>
          </form>
          <div class="ui top right attached mini label">Modifica: ${moment(data.program.dataUltimaModifica).format('DD/MM/YYYY, HH:mm')}, <b>${data.program.utenteModifica}</b></div>
        </div>

        ${data.program_synopsis.map((sy, i) => `
        <div class="ui segment" style="background: #e8e8e8;">
        <form class="ui equal width form">
            <h2 class="ui header">Sinossi</h2>
            <div class="fields">
              <div class="field">
                <label>Trama EPG 280</label>
                <textarea spellcheck="true" rows="5" data-max="180" name="program_synopsis,${sy.ID ? sy.ID : 0},tramaEPG"></textarea>
              </div>
              <div class="field">
                <label>Trama Primafila</label>
                <textarea spellcheck="true" rows="5" data-max="350" name="program_synopsis,${sy.ID ? sy.ID : 0},sinossiPrimafila"></textarea>
              </div>
              <div class="field">
                <label>Marketing Message</label>
                <textarea spellcheck="true" rows="5" data-max="134" name="program_synopsis,${sy.ID ? sy.ID : 0},marketingMessage"></textarea>
              </div>
            </div>

            <div class="fields">
              <div class="field">
                <label>Trama 90</label>
                <textarea spellcheck="true" rows="5" data-max="90" name="program_synopsis,${sy.ID ? sy.ID : 0},medium1"></textarea>
              </div>
              <div class="field">
                <label>Trama 120</label>
                <textarea spellcheck="true" rows="5" data-max="120" name="program_synopsis,${sy.ID ? sy.ID : 0},medium2"></textarea>
              </div>
              <div class="field">
                <label>Trama 180</label>
                <textarea spellcheck="true" rows="5" data-max="180" name="program_synopsis,${sy.ID ? sy.ID : 0},medium3"></textarea>
              </div>
            </div>

            <div class="fields">
              <div class="field">
                <label>Trama 240</label>
                <textarea spellcheck="true" rows="5" data-max="240" name="program_synopsis,${sy.ID ? sy.ID : 0},long"></textarea>
              </div>
              <div class="field">
                <label>Trama EPG 2</label>
                <textarea spellcheck="true" rows="5" data-max="180" name="program_synopsis,${sy.ID ? sy.ID : 0},tramaEPG2"></textarea>
              </div>
            </div>
          </form>
          ${data.program_synopsis ? `<div class="ui top right attached mini label">Modifica: ${moment(sy.dataUltimaModifica).format('DD/MM/YYYY, HH:mm')}, <b>${sy.utenteModifica}</b></div>` : ``}
        </div>
        `).join('')}

        ${data.program_epg_synopsis.map((es, i) => `
        <div class="ui segment" style="background: #e8e8e8;">
          <form class="ui equal width form">
            <h2 class="ui header">EPG dal ${moment(es.startDate).format('DD/MM/YYYY')} al ${moment(es.endDate).format('DD/MM/YYYY')}</h2>
            <div class="fields">
              <div class="field">
                <label>Sinossi ${es.channelList ? '<span class="a_blue">' + es.channelList.split('|').filter((c) => c).map((id) => myVars.channels.tutti.find((c) => c.ID == id)).filter((c) => c).map((c) => c.Name).join(', ') + '</span>' : ''}</label>
                <textarea spellcheck="true" rows="3" data-max="200" name="program_epg_synopsis,${es.id},synopsis"></textarea>
              </div>
            </div>
          </form>
        </div>`)}

        ${data.content.map((r) => `
        <div class="ui segment">
          <form class="ui equal width form">
            <h2 class="ui header">Risposta Content <div class="ui horizontal tiny ${r.ethanFlag ? 'blue' : 'red'} label">${r.ethanFlag ? 'SKY' : 'TIM'} ${r.idResponse}</div></h2>
            <div class="fields">
              <div class="field">
                <label>Trama Corta</label>
                <textarea spellcheck="true" rows="5" data-max="200" name="content,${r.idResponse},shortDescription"></textarea>
              </div>
              <div class="field">
                <label>Trama <span class="a_blue">Sky</span></label>
                <textarea spellcheck="true" rows="5" data-max="200" name="content,${r.idResponse},ethanSynopsis"></textarea>
              </div>
            </div>

            <div class="fields">
              <div class="field">
                <label>Trama Lunga</label>
                <textarea spellcheck="true" rows="9" data-max="450" name="content,${r.idResponse},longDescription"></textarea>
              </div>
              <div class="field">
                <label>Trama <span class="a_blue">Sky VOD</span></label>
                <textarea spellcheck="true" rows="9" data-max="1000" name="content,${r.idResponse},ethanMediumSynopsisVod"></textarea>
              </div>
            </div>

          </form>
          <div class="ui top right attached mini label">Inizio il: ${moment(r.Dinizio).format('DD/MM/YYYY, HH:mm')}</div>
        </div>`).join('')}

        ${data.vod.map((r) => `
        <div class="ui segment">
          <form class="ui equal width form">
            <h2 class="ui header">Ultima Risposta VOD <div class="ui horizontal tiny purple label">VOD ${r.id}</div></h2>
            <div class="fields">
              <div class="field">
                <label>Synopsis STB</label>
                <textarea spellcheck="true" rows="9" data-max="200" name="vod,${r.id},SynopsisSTB"></textarea>
              </div>
              <div class="field">
                <label>Synopsis Altri Dispositivi</label>
                <textarea spellcheck="true" rows="9" data-max="1000" name="vod,${r.id},SynopsisOtherDevices"></textarea>
              </div>
            </div>

          </form>
          <div class="ui top right attached mini label">Creata il: ${moment(r.dataCreazione).format('DD/MM/YYYY, HH:mm')}</div>
        </div>`).join('')}

      </div>`).appendTo($content);

      if (data.episodes.length) {
         // $trameep
         $(`<div class="ui tab" data-tab="trameep">
        ${data.episodes.map((e) => `
        <div class="ui segment segEp" data-idep="${e.ID}">

          <div class="ui small header showEp" style="cursor:pointer; margin-bottom: 0px;">
            <div class="ui tiny horizontal teal label" style="margin-left: 0;">EP ${e.ID}</div>
            <span class="ui grey text opacita4">/&nbsp;&nbsp;</span>
            ${e.Title} <span class="ui teal text">${e.episodioNumerico ? 'E' + e.episodioNumerico : ''}${e.Turn ? ' ' + e.Turn : ''}${e.Location ? ' (' + e.Location + ')' : ''}${e.EpisodeNumber ? ' <span class="a_grey">(' + e.EpisodeNumber + ')</span>' : ''}</span>

            <div class="ui labels" style="float: right;">
              ${e.cep && e.cep.find((r) => r.ethanFlag === true) ? '<div class="ui tiny horizontal blue label">SKY</div>' : ''}
              ${e.cep && e.cep.find((r) => r.ethanFlag === false) ? '<div class="ui tiny horizontal red label">TIM</div>' : ''}
              ${e.vep && e.vep.length ? '<div class="ui tiny horizontal purple label">VOD</div>' : ''}
            </div>
          </div>

          <form class="ui equal width form" style="display:none;">

            <div class="ui segment" style="background: #e8e8e8; margin-top: 14px;"">
              <h4 class="ui header">Episodio</h4>
              <div class="fields">
                <div class="field">
                  <label>Sinossi</label>
                  <textarea spellcheck="true" rows="5" data-max="200" name="episodes,${e.ID},Description"></textarea>
                </div>
                <div class="field">
                  <label>Sinossi Vecchia</label>
                  <textarea spellcheck="true" rows="5" data-max="0" class="secondaryInput" name="episodes,${e.ID},comment"></textarea>
                </div>
              </div>
              <div class="ui top right attached mini label">Modifica: ${moment(e.dataUltimaModifica).format('DD/MM/YYYY, HH:mm')}, <b>${e.utenteModifica}</b></div>
            </div>

            ${e.episode_epg_synopsis ? e.episode_epg_synopsis.map((epge, i) => `
            <div class="ui segment" style="background: #e8e8e8; margin-top: 14px;"">
              <h4 class="ui header">EPG dal ${moment(epge.startDate).format('DD/MM/YYYY')} al ${moment(epge.endDate).format('DD/MM/YYYY')}</h4>
              <div class="fields">
                <div class="field">
                  <label>Sinossi ${epge.channelList ? '<span class="a_blue">' + epge.channelList.split('|').filter((c) => c).map((id) => myVars.channels.tutti.find((c) => c.ID == id)).filter((c) => c).map((c) => c.Name).join(', ') + '</span>' : ''}</label>
                  <textarea spellcheck="true" rows="2" data-max="140" name="episodes,${e.ID},episode_epg_synopsis,${epge.id},synopsis"></textarea>
                </div>
              </div>
            </div>
            `).join('') : ''}

            ${e.content.map((c, i) => `
            <div class="ui segment">
            <h4 class="ui header">Risposta Content <div class="ui horizontal tiny ${c.ethanFlag ? 'blue' : 'red'} label">${c.ethanFlag ? 'SKY' : 'TIM'} ${c.idResponse}</div></h4>
            <div class="fields">
              <div class="field">
                <label>Trama Corta</label>
                <textarea spellcheck="true" rows="5" data-max="200" name="episodes,${e.ID},content,${c.idResponse},shortDescription"></textarea>
              </div>
              <div class="field">
                <label>Trama <span class="a_blue">Sky</span></label>
                <textarea spellcheck="true" rows="5" data-max="200" name="episodes,${e.ID},content,${c.idResponse},ethanSynopsis"></textarea>
              </div>
            </div>
            <div class="fields">
              <div class="field">
                <label>Trama Lunga</label>
                <textarea spellcheck="true" rows="5" data-max="450" name="episodes,${e.ID},content,${c.idResponse},longDescription"></textarea>
              </div>
              <div class="field">
                <label>Trama <span class="a_blue">Sky VOD</span></label>
                <textarea spellcheck="true" rows="5" data-max="1000" name="episodes,${e.ID},content,${c.idResponse},ethanMediumSynopsisVod"></textarea>
              </div>
            </div>
            <div class="ui top right attached mini label">Inizio il: ${moment(c.Dinizio).format('DD/MM/YYYY, HH:mm')}</div>
            </div>
            `).join('')}

            ${e.vod.map((v, i) => `
            <div class="ui segment">
            <h4 class="ui header">Ultima Risposta VOD <div class="ui horizontal tiny purple label">VOD ${v.id}</div></h4>
            <div class="fields">
              <div class="field">
                <label>Synopsis STB</label>
                <textarea spellcheck="true" rows="5" data-max="200" name="episodes,${e.ID},vod,${v.id},SynopsisSTB"></textarea>
              </div>
              <div class="field">
                <label>Synopsis Altri Dispositivi</label>
                <textarea spellcheck="true" rows="5" data-max="1000" name="episodes,${e.ID},vod,${v.id},SynopsisOtherDevices"></textarea>
              </div>
            </div>
            <div class="ui top right attached mini label">Creata il: ${moment(v.dataCreazione).format('DD/MM/YYYY, HH:mm')}</div>
            </div>
            `).join('')}
          </form>
        </div>
        `).join('')}

      </div>
      `).appendTo($content);
      }

      let satelliteRedattorePermission = myVars.io.area.indexOf('Satellite') > -1 && myVars.io.ruolo == 'Redattore';
      if (myVars.io.ruolo == 'Admin' || satelliteRedattorePermission) {
         // $actions
         $(`
        <div class="actions">
          <div class="ui red right labeled icon cancel button">Annulla<i class="ban icon"></i></div>
          <div class="ui green right labeled icon button salvaModal">Salva<i class="upload icon"></i>
        </div>
      `).appendTo($modal);
      }

      // Base Controls
      $modal.find('.item[data-tab]').tab();
      $modal.find('.ui.dropdown').dropdown();
      $modal.find('.ui.checkbox').checkbox();

      // FILL TextArea
      $modal.find('textarea[name]').each((i, ta) => {
         const path = $(ta).attr('name').split(',');
         let val = data;

         path.forEach((p) => {
            if (val.length && p.match(/^\d{1,}$/)) val = val.find((o) => o.id == p || o.ID == p || o.idResponse == p);
            else val = val[p] ? val[p] : '';
         });

         // console.log(path, val);
         if (val) $(ta).val(val);
      });

      // MAKE TextArea
      dataAndTextArea($modal.find('[data-tab="tramepr"]'), data);

      // SAVE TextArea
      $modal.find('.salvaModal').on('click', async function () {
         const saveModalObj = {
            title: 'Salvataggio Trame',
            els: [],
         };

         // Ciclo le textarea
         $modal.find('textarea[name]').each((i, ta) => {
            const $ta = $(ta);
            const value = $ta.val();
            const undo = $ta.data('undo');

            // Se non ho modifiche o non ho mai inizializzato la textarea (perchè non ha mai ricevuto lo show) non faccio nulla
            if (!$ta.data('ready') || (!value && !undo)) return;

            // Se diversa dalla versione salvata
            if (value != undo) {
               // Corrispondenza Servizi
               // const serviceArr = [
               //   ['SatelliteSupport.dbo.EPG_program_synopsis', 'program_epg_synopsis'],
               //   ['SatelliteSupport.dbo.EPG_Episode_synopsis', 'episode_epg_synopsis'],
               //   ['Satellite.dbo.ProgramSynopsis', 'program_synopsis'],
               //   ['Satellite.dbo.Program', 'program'],
               //   ['Satellite.dbo.Episode', 'episode'],
               //   ['TIMVision.dbo.Response', 'content'],
               //   ['SKYVOD.dbo.Response', 'vod'],
               // ];

               const name = $ta.attr('name').split(',');
               const nameIds = name.filter((p) => p.match(/^\d{1,}$/));
               const save = {
                  saveid: 's' + i,
                  service: 'program',
                  id: data.idProgram,
                  trama: value,
                  col: name[name.length - 1],
                  label: $ta.closest('.field').find('label').text(),
                  diff: !undo ? value : Diff.diffWordsWithSpace(undo, value),
               };

               if (nameIds.length) {
                  save.id = nameIds[nameIds.length - 1];
                  save.service = name[name.findIndex((e) => e == save.id) - 1].replace(/_/gi, '-');
                  if (save.service == 'episodes') save.service = 'episode';
                  if (save.service == 'content') save.service = 'content-response';
               }

               save[save.col] = value;
               console.log('Salva Trama:', save);
               return;
               const promise = myWrites.writeTrame(save.service, save.id, save.col, save.trama)
                  .then((r) => {
                     $ta.data('undo', save.trama);
                     return r;
                  });

               saveModalObj.els.push({
                  title: save.label,
                  details: [{
                     title: `${save.service} / ${save.id} / ${save.col}`,
                     promise: promise,
                  }],
               });
            }
         });

         if (!saveModalObj.els.length) {
            myFunc.affermativeModal('Nessuna trama da salvare', 'Non è stata fatta alcuna modifica alle trame di questo programma.');
         } else {
            await myFunc.saveModal(saveModalObj);
         }

         console.log('FINE SALVATAGGIO TRAME');
      });

      // Other
      $modal.find('.showEp').on('click', function () {
         const $form = $(this).next('form');
         if ($form.is(':visible')) $form.closest('.segment').css('background', '#ffffff');
         else $form.closest('.segment').css('background', '#dff0ff');

         dataAndTextArea($form, data);
         $form.transition('fade');
      });

      $modal.find('[name="searchText"]').on('keyup', function () {
         const val = $(this).val();
         searchText(val);
      });

      $modal.find('[name="searchEpisodi"]').on('focus', function () {
         $modal.find('.menu [data-tab]').tab('change tab', 'trameep');
      });

      $modal.find('[name="searchEpisodi"]').on('keyup', function () {
         const val = $(this).val();
         searchEpisodi(val);
      });

      $modal.find('textarea').on('keyup', function () {
         const toSearch = $('[name="searchText"]').val().trim();
         if (!toSearch) return;

         searchText(toSearch, $(this));
      });

      return $modal;
   }

   function dataAndTextArea($els, data) {
      $els.find('textarea[name]').addBack('textarea[name]').each(function (i, ta) {
         const $ta = $(ta);
         const name = $ta.attr('name').split(',');
         const idEp = $ta.closest('.segEp').attr('data-idep');
         const ep = data.episodes.find((e) => e.ID == (idEp ? idEp : 0));

         const epgcData = {
            // TUTTI
            canale: data.onAirChannel,
            campo: name[name.length - 1],
            categoria: data.program.Category_ID,
            genere: data.program.Genres && data.program.Genres.length ? data.program.Genres[0].id : null,
            // FILM
            persons: data.persons,
            country: data.program.Country,
            year: data.program.Year,
            // SERIE
            stagione: data.program.SeriesNumber,
            episodio: ep ? ep.episodioNumerico : '',
            titoloEpisodio: ep ? ep.Title : '',
         };

         if (name.includes('content') || name.includes('vod')) myFunc.makeTextArea($ta);
         else myFunc.makeTextArea($ta, epgcData);
      });
   }

   function searchText(text, $ta) {
      const $tas = $ta ? $ta : $modal.find('textarea');

      $tas.each((i, t) => {
         const $ta = $(t);
         const $fi = $ta.closest('.field');
         const $seg = $ta.closest('.segEp');
         const $form = $ta.closest('.form');
         const val = $ta.val();

         // Reset
         $fi.find('.myTextAreaOver').remove();
         if ($seg.length) $form.hide();

         if (!val || !text) return;
         if (text.length < 3) return;
         if (data.episodes.length > 50 && text.length < 4) return;

         const rex = new RegExp(text, 'g');

         if (rex.test(val)) {
            dataAndTextArea($ta, data);
            const highlighted = val.replace(rex, `<span class="bg_yellow">${text}</span>`);

            const $taOver = $(`<div class="myTextAreaOver">${highlighted}</div>`).appendTo($fi);
            $taOver.css({
               'font-size': $ta.css('font-size'),
               'line-height': $ta.css('line-height'),
               'padding': $ta.css('padding'),
            }).scrollTop($ta.scrollTop());

            // Mostra Episodio se Chiuso
            $form.show();
            $form.css('visibility', 'visible');
            $seg.show();
         }
      });
   }

   function searchEpisodi(text) {
      const $segEps = $modal.find('.segEp');

      if (!text) {
         $segEps.show();
         return;
      }

      $segEps.hide().each((i, s) => {
         const $seg = $(s);
         const $head = $seg.find('.showEp');
         const htext = $head.text();

         if (htext.toLowerCase().indexOf(text.toLowerCase()) > -1) $seg.show();
      });
   }
};
