module.exports = {
  init: function() {
    // Add Btn
    if ($('#logsTable').length || $('.item.myUserLog').length) return;

    // const $sidebarBtn = $('.fixed.menu .content.icon').closest('a.item');
    // const $myLogBtn = $('<a class="item icon myUserLog"><i class="user clock icon"></i></a>').insertAfter($sidebarBtn);

    const $myUserMenu = $('.myUserMenu');
    $('<a class="item myUserLog"><i class="user clock icon"></i>Log Operazioni</a>').prependTo($myUserMenu);

    $('body').on('click', '.myUserLog', function() {
      myLog.openModal();
    });
  },

  getLogs: async function(dStart, dEnd, visits, user, idProgram, idEpisode) {
    let query;

    if (idProgram) {
      console.log('idProgram', idProgram);
      client.service('userslog').timeout = 60000;
      // idProgram = 250653;
      query = {
        $or: [
          {data: {$like: `%idProgram":${idProgram}%`}},
          {data: {$like: `%pr${idProgram}%`}},
        ],
      };
    } else if (idEpisode) {
      client.service('userslog').timeout = 60000;
      query = {
        $or: [
          {data: {$like: `%idEpisode":${idEpisode}%`}},
          {data: {$like: `%ep${idEpisode}%`}},
        ],
      };
    } else {
      dEnd = moment(dEnd).add(1, 'day').format('YYYY-MM-DD');
      query = {created_at: {$gte: dStart, $lte: dEnd}};

      if (user == 'noAdmin') {
        const admins = await client.service('users').find( {query: {ruolo: 'admin'}} ).then((r) => r.data);
        const adminsIds = admins.map((a) => a.id);
        query.user = {$nin: adminsIds};
      } else if (user) query.user = user;

      if (!visits) query.path = {$ne: 'authentication'};
    }

    query.$limit = 32000;
    console.log('User Logs richiesti:', query);
    let logs = await client.service( 'userslog' ).find( {query: query});

    // Parse JSON
    console.log('Parsing User Logs...', logs);
    logs = myLog.parseMyLogs(logs.data);

    console.log('User Logs caricati:', logs);
    return logs;
  },

  parseMyLogs: function(logs) {
    const stats = {
      Programmi_Assegnati: [],
      Programmi_Salvati: [],
      Programmi_Confermati: [],
      Programmi_Inviati: [],

      Episodi_Assegnati: [],
      Episodi_Salvati: [],
      Episodi_Confermati: [],
      Episodi_Inviati: [],

      Immagini_Prog_Caricate: [],
      Immagini_Prog_Generate: [],
      Immagini_Ep_Caricate: [],
      Immagini_Ep_Generate: [],

      Immagini_Content: [],
      Immagini_Content_Personaggi: [],
      Immagini_DTT: [],
      Errori: [],
    };

    logs.forEach((log) => {
      // Parse JSON
      log.stringData = log.data;
      log.idm = log.idm ? JSON.parse(log.idm) : [];
      log.data = log.data ? JSON.parse(log.data) : [];
      if (!Array.isArray(log.data)) log.data = [log.data];

      // ////////////////////////////////////////////////////////////////////////////////////////////////// idProgram
      log._idProgram = [...new Set(log.data.map((o) => {
        if (o.idProgram) return o.idProgram;
        const match = o.name ? o.name.match(/_pr(\d{1,})/) : false;
        return match && match[1] ? match[1] : null;
      } ).filter((o) => o))];

      // ////////////////////////////////////////////////////////////////////////////////////////////////// idEpisode
      log._idEpisode = [...new Set(log.data.map((o) => {
        if (o.idEpisode && o.idEpisode != -1) return o.idEpisode;
        const match = o.name ? o.name.match(/_ep(\d{1,})/) : false;
        return match && match[1] ? parseInt(match[1]) : null;
      } ).filter((o) => o))];

      // ////////////////////////////////////////////////////////////////////////////////////////////////// idRequest
      log._idRequest = log.data.map((o) => {
        if (o.idRequest) return o.idRequest;
        if (o.idRichiesta) return o.idRichiesta;
        if (o.Request_id) return o.Request_id;
        const match = o.name ? o.name.match(/^ir(\d{1,})/) : false;
        return match && match[1] ? parseInt(match[1]) : null;
      } ).filter((o) => o);

      if (['content-request'].includes(log.path)) log._idRequest = log._idRequest.concat(log.idm);

      const idr = log.interface.match(/idRichiesta=(\d{1,})/i);
      if (idr && idr[1]) log._idRequest.push(parseInt(idr[1]));

      log._idRequest = [...new Set(log._idRequest)];

      // ////////////////////////////////////////////////////////////////////////////////////////////////// Titolo
      log._titoli = log.data.map((o) => {
        if (o.titolo) return o.titolo;
        if (o.programTitle) return o.programTitle;
        if (o.Title) return o.Title;
        if (o.title) return o.title;
        if (o.dbTitle) return o.dbTitle;
        if (o.requestData && o.requestData.response && o.requestData.response.title) return o.requestData.response.title;
      } ).filter((o) => o);

      if (!log._titoli.length) {
        const iTit = log.interface.match(/titolo=(.*?)(?:&|$)/i);
        if (iTit && iTit[1]) log._titoli.push(decodeURIComponent(iTit[1]));
      }

      if (!log._titoli.length) {
        if (log.path == 'uploads' && log.data[0].folder == 'images.dtt') {
          const match = /img_(\d{1,})_(-?\d{1,})_(.*?)\./gi.exec(log.data[0].name);
          if (match && match[3]) log._titoli.push(match[3]);
          if (match && match[1]) log._idProgram.push(match[1]);
        }
      }

      log._titoli = [...new Set(log._titoli)];

      // ////////////////////////////////////////////////////////////////////////////////////////////////// _interfaceHtml
      const text = log.interface.split('/').pop().split('?')[0];
      const isTools = /tools\.datatv\.it/gi.test(log.interface);
      log._interfaceHtml = `<a href="${log.interface}" target="_blank" class="${isTools ? 'a_grey' : ''}">${text}</a>`;

      // ////////////////////////////////////////////////////////////////////////////////////////////////// _Immagini
      log._immagini = [];


      // ////////////////////////////////////////////////////////////////////////////////////////////////// _html
      log._html = methodPathToHtml(log);

      // ////////////////////////////////////////////////////////////////////////////////////////////////// stats


      // Aggiungo le colonne per filtrarle poi
      Object.keys(stats).forEach((k) =>{
        log[k] = [];
      });

      if (log.method == 'create' && log.path == 'archivioassegnazioni') {
        if (log._idProgram[0]) {
          stats.Programmi_Assegnati.push(log._idProgram[0]);
          log.Programmi_Assegnati.push(log._idProgram[0]);
        }
        if (log._idEpisode[0]) {
          stats.Episodi_Assegnati.push(log._idEpisode[0]);
          log.Episodi_Assegnati.push(log._idEpisode[0]);
        }
      }

      if (log.method == 'create' && log.path == 'content-assegnazione') {
        stats.Programmi_Assegnati.push(log._idRequest[0]);
        log.Programmi_Assegnati.push(log._idRequest[0]);
      }

      if (log.method == 'salva' && log.path == 'content-programma') {
        stats.Programmi_Salvati.push(log._idRequest[0]);
        log.Programmi_Salvati.push(log._idRequest[0]);
      }

      if (log.method == 'salva' && log.path == 'content-episodio') {
        stats.Episodi_Salvati.push(log._idRequest[0]);
        log.Episodi_Salvati.push(log._idRequest[0]);
      }

      if (log.method == 'conferma' && log.path == 'content-programma') {
        stats.Programmi_Confermati.push(log._idRequest[0]);
        log.Programmi_Confermati.push(log._idRequest[0]);
      }

      if (log.method == 'conferma' && log.path == 'content-episodio') {
        stats.Episodi_Confermati.push(log._idRequest[0]);
        log.Episodi_Confermati.push(log._idRequest[0]);
      }

      if (log.method == 'invia' && log.path == 'content-programma') {
        stats.Programmi_Inviati.push(log._idRequest[0]);
        log.Programmi_Inviati.push(log._idRequest[0]);
      }

      if (log.method == 'invia' && log.path == 'content-episodio') {
        stats.Episodi_Inviati.push(log._idRequest[0]);
        log.Episodi_Inviati.push(log._idRequest[0]);
      }

      if (log.path == 'content-functions') {
        if (log.data[0].op == 'send' || log.data[0].op == 'reSend') {
          if (log._idEpisode.length) {
            stats.Episodi_Inviati.push(log._idRequest[0]);
            log.Episodi_Inviati.push(log._idRequest[0]);
          } else {
            stats.Programmi_Inviati.push(log._idRequest[0]);
            log.Programmi_Inviati.push(log._idRequest[0]);
          }
        }
      }

      if (log.method == 'errore') {
        stats.Errori.push(log._idRequest[0]);
        log.Errori.push(log._idRequest[0]);
      };

      if (log.path == 'uploads') {
        if (log.data[0].folder == 'images.dtt') {
          stats.Immagini_DTT.push(log.data.map((o) => o.name));
          log.Immagini_DTT.push(log.data.map((o) => o.name));
        }
        if (log.data[0].folder == 'images.content') {
          stats.Immagini_Content.push(log.data.map((o) => o.name));
          log.Immagini_Content.push(log.data.map((o) => o.name));
        }
        if (log.data[0].folder == 'images.content/Personaggi') {
          stats.Immagini_Content_Personaggi.push(log.data.map((o) => o.name));
          log.Immagini_Content_Personaggi.push(log.data.map((o) => o.name));
        }
      }

      if (log.path == 'archivio' && log.method == 'create') {
        log.data.forEach((i) => {
          if (i.idEpisode && i.idEpisode != -1) {
            if (i.origin == 'Auto') {
              stats.Immagini_Ep_Generate.push(log.data.map((o) => o.name));
              log.Immagini_Ep_Generate.push(log.data.map((o) => o.name));
            } else {
              stats.Immagini_Ep_Caricate.push(log.data.map((o) => o.name));
              log.Immagini_Ep_Caricate.push(log.data.map((o) => o.name));
            }
          } else {
            if (i.origin == 'Auto') {
              stats.Immagini_Ep_Generate.push(log.data.map((o) => o.name));
              log.Immagini_Ep_Generate.push(log.data.map((o) => o.name));
            } else {
              stats.Immagini_Prog_Caricate.push(log.data.map((o) => o.name));
              log.Immagini_Prog_Caricate.push(log.data.map((o) => o.name));
            }
          }
        });
      }
    });

    printStats(stats);
    console.log('Parsing End', logs);
    return logs;

    function methodPathToHtml(log) {
      const m = log.method;
      const p = log.path;
      const l = log.idm.length ? log.idm.length : log.data.length;
      let text = `${m} ${l} ${p}`;
      let color = 'grey';
      let info = null;

      if (['remove', 'errore'].includes(log.method)) color = 'red';
      else if (['update', 'patch'].includes(log.method)) color = 'yellow';
      else if (['salva', 'conferma', 'invia'].includes(log.method)) color = 'olive';
      else if (['create'].includes(log.method)) color = 'green';

      if (m == 'create') {
        if (p == 'authentication' && log.data[0].op == 'visit') text = `aperto la pagina`;
        if (p == 'authentication' && log.data[0].op == 'login') text = `effettuato il login`;
        if (p == 'archivio' && log.data[0].origin != 'Auto') text = `caricato ${l} immagini Archivio`;
        if (p == 'archivio' && log.data[0].origin == 'Auto') text = `generato ${l} immagini Archivio`;
        if (p == 'archivioassegnazioni') text = `assegnato ${l} ${log._idEpisode[0] ? 'Episodio' : 'Programma'}`;
        if (p == 'content-utenti-request') text = `assegnato ${l} un programma su content`;
        if (p == 'email') text = `inviato ${l} E-mail`;
        if (p == 'messages') text = `inviato ${l} Messaggi`;
        if (p == 'ibms-channels') text = `creato ${l} canali IBMS`;
        if (p == 'program') text = `creato ${l} Programmi in Database`;
        if (p == 'episode') text = `creato ${l} Episodi in Database`;
        if (p == 'program-series') text = `collegato ${l} Stagioni in Database`;

        if (p == 'uploads' && log.data[0].folder == 'tempUploads') text = `caricato ${l} immagini Temporanea`;
        if (p == 'uploads' && log.data[0].folder == 'images.dtt') text = `caricato ${l} immagini DTT`;
        if (p == 'uploads' && log.data[0].folder == 'images.content') text = `caricato ${l} immagini Content`;
        if (p == 'uploads' && log.data[0].folder == 'images.content/Personaggi') text = `caricato ${l} immagini Content Personaggi`;
      }
      
      if (m == 'remove') {
        if (p == 'archivio') text = `eliminato ${l} immagini Archivio`;
        if (p == 'archivioassegnazioni') text = `eliminato ${l} Assegnazioni`;
        if (p == 'content-utenti-request') text = `eliminato ${l} Assegnazioni Content`;
      }

      if (m == 'update' || m == 'patch') {
        if (p == 'users') text = `modificato ${l} Utenti`;
        if (p == 'archivio') text = `modificato ${l} immagini Archivio`;
        if (p == 'content-request') {
          if (log.data[0] && log.data[0].attributo) {
            text = `modificato le Note di Lavorazione di ${l} Richieste Content`;

          } else if (log.data[0] && log.data[0].canali) {
            text = `modificato i Canali di ${l} Richieste Content`;

          } else {
            text = `modificato ${l} Richieste Content`;

          }          
        }
        if (p == 'archivioassegnazioni') text = `modificato ${l} assegnazioni ${log._idEpisode[0] ? 'Episodio' : 'Programma'}`;
        if (p == 'riconciliazione-fox' && log.data[0].dbTitle) text = `riconciliato ${l} Programmi Fox`;
        if (p == 'riconciliazione-fox' && !log.data[0].dbTitle) text = `scollegato ${l} Programmi Fox`;
        if (p == 'content-functions' && log.data[0].op == 'sendAcquisition') text = `inviato ${l} Risposte Acquisition`;
        if (p == 'content-functions' && log.data[0].op == 'link') text = `collegato ${l} Risposte`;
        if (p == 'content-functions' && log.data[0].op == 'send') text = `inviato ${l} Risposte`;
        if (p == 'content-functions' && log.data[0].op == 'reSend') text = `inviato ${l} Risposte`;
        if (p == 'content-functions' && log.data[0].op == 'noSend') text = `annullato l'invio di ${l} Risposte`;
        if (p == 'content-functions' && log.data[0].hasOwnProperty('idSerie')) text = `collegato ${l} Richieste al Database`;
      }

      if (m == 'salva') {
        if (p == 'content-programma') text = `salvato ${l} Programma Content`;
        if (p == 'content-episodio') text = `salvato ${l} Episodio Content`;
      }

      if (m == 'conferma') {
        if (p == 'content-programma') text = `confermato ${l} Programma Content`;
        if (p == 'content-episodio') text = `confermato ${l} Episodio Content`;
      }

      if (m == 'invia') {
        if (p == 'content-programma') text = `inviato ${l} Programma Content`;
        if (p == 'content-episodio') text = `inviato ${l} Episodio Content`;
      }

      if (m == 'duplica') {
        if (p == 'content-programma') text = `duplicato ${l} Programma Content`;
        if (p == 'content-episodio') text = `duplicato ${l} Episodio Content`;
      }

      if (m == 'errore') {
        text = 'generato un Errore';
      }

      // Info
      if (p == 'archivio' && log.data && log.data.length) {
        info = log.data.map((i) => i.use).join(', ');
      }
      if (p == 'uploads' && log.data[0].folder == 'images.content') {
        info = log.data[0].name.match(/_(image[A-Z].*?)(_|\.)/)[1];
      }
      if (p == 'uploads' && log.data[0].folder == 'images.content/Personaggi') {
        info = log.data[0].name.split('_')[0];
      }
      if (log.data[0] && log.data[0].assigned_to) {
        const user = myVars.users.tutti.find((u) => u.id == log.data[0].assigned_to);
        info = user.nome + ' ' +user.cognome + ' (' + user.ruolo + ')';
      }


      return `
        <div class="ui tiny header">
          <i class="ui ${color} info circle icon dataInfo"></i>
          <div class="content">
            <div class="sub header">
              ${log._titoli.map((t) => `<div class='a_grey'>${t}</div>`).join('')}
            </div>
            #### ${text}
            <div class="sub header">
              ${info ? `<div class='a_grey'>${info}</div>` : ''}
            </div>
          </div>
        </div>`;
    }

    function printStats(stats) {
      console.log('Statistics:', stats);
      const kPr = Object.keys(stats).filter((k) => /^Programmi_/gi.test(k));
      const kEp = Object.keys(stats).filter((k) => /^Episodi_/gi.test(k));
      const kAr = Object.keys(stats).filter((k) => /(_Caricate|_Generate)/gi.test(k));
      const kOt = Object.keys(stats).filter((k) => /(Immagini_Content|Immagini_DTT|Errori)/gi.test(k));

      $('.statistiche').html(`
        <div class="ui blue segment" style="width: 25%;">
          ${kPr.map((k) => `
            <div class="ui tiny ${stats[k].length ? 'blue' : 'grey opacita6'} header" data-tablefilter="${k}" style="margin: 0 0 8px 0;">
              <i class="tv icon"></i>
              <div class="content">${stats[k].length} / ${[...new Set(stats[k])].length}
                <div class="sub header">${k.replace(/\_/gi, ' ')}</div>
              </div>
            </div>`,
  ).join('')} 
        </div>
        <div class="ui teal segment" style="width: 25%;">
          ${kEp.map((k) => `
            <div class="ui tiny ${stats[k].length ? 'teal' : 'grey opacita6'} header" data-tablefilter="${k}" style="margin: 0 0 8px 0;">
              <i class="ordered list icon"></i>
              <div class="content">${stats[k].length} / ${[...new Set(stats[k])].length}
                <div class="sub header">${k.replace(/\_/gi, ' ')}</div>
              </div>
            </div>`,
  ).join('')} 
        </div>
        <div class="ui yellow segment" style="width: 25%;">
          ${kAr.map((k) => `
            <div class="ui tiny ${stats[k].length ? 'yellow' : 'grey opacita6'} header" data-tablefilter="${k}" style="margin: 0 0 8px 0;">
              <i class="dropbox icon"></i>
              <div class="content">${stats[k].length} / ${[...new Set(stats[k])].length}
                <div class="sub header">${k.replace(/\_/gi, ' ')}</div>
              </div>
            </div>`,
  ).join('')} 
        </div>
        <div class="ui orange segment" style="width: 25%;">
          ${kOt.map((k) => `
            <div class="ui tiny ${stats[k].length ? 'orange' : 'grey opacita6'} header" data-tablefilter="${k}" style="margin: 0 0 8px 0;">
              <i class="${k == 'Errori' ? 'warning sign' : 'image'} icon"></i>
              <div class="content">${stats[k].length} / ${[...new Set(stats[k])].length}
                <div class="sub header">${k.replace(/\_/gi, ' ')}</div>
              </div>
            </div>`,
  ).join('')} 
        </div>`);

      $('.statistiche').on('click', '[data-tablefilter]', function() {
        const col = $(this).attr('data-tablefilter');
        const dt = $('#logsTable').DataTable();
        dt.columns().every(function() {
          const dataSrc = this.dataSrc();
          this.search('');
          if (dataSrc != col) return;
          this.search('.+', true, false);
        });
        dt.draw();
      });
    }
  },

  openModal: async function() {
    $('#myUserLogModal').remove();

    const $modal = $(`
      <div class="ui large modal" id="myUserLogModal">
        
        <div class="header">
          <div class="ui unstackable small form">
            <div class="ui fields" style="margin: 0;">
          
              <div class="ui four wide field small header">
                <img class="ui avatar image" src="${mySite}/${myVars.io.avatar ? 'avatars/'+myVars.io.avatar : 'avatars/user.png'}">
                <div class="content">
                  ${myVars.io.nome} ${myVars.io.cognome}
                  <div class="sub header">Log Operazioni</div>
                </div>
              </div>
          
              <div class="four wide field">
                <div class="ui icon input">
                  <input type="text" class="dateRange" placeholder="Data" style="width: 230px;">
                  <i class="calendar alternate outline icon"></i>
                </div>
              </div>
          
              <div class="three wide field">
                <div class="ui icon input">
                  <input type="text" name="datatableSearch" placeholder="Cerca...">
                  <i class="search link icon"></i>
                </div>
              </div>
          
              <div class="three wide field">
                <div class="ui toggle checkbox visits" style="margin-top:6px;">
                  <input type="checkbox" name="visits" class="hidden">
                  <label>Mostra visite</label>
                </div>
              </div>
          
              <div class="two wide field">
                  <div class="ui selection dropdown dtLength">
                    <input type="hidden" name="dtLength" value="50">
                    <i class="dropdown icon"></i>
                    <div class="text">10 righe</div>
                    <div class="menu">
                      <a class="item" data-value="10">10 righe</a>
                      <a class="item" data-value="25">25 righe</a>
                      <a class="item active selected" data-value="50">50 righe</a>
                      <a class="item" data-value="100">100 righe</a>
                      <a class="item" data-value="200">200 righe</a>
                      <a class="item" data-value="500">500 righe</a>
                      <a class="item" data-value="1000">1000 righe</a>
                    </div>
                  </div>
                </div>
          
            </div>
          </div>
        </div>

        <div class="scrolling content">
          <div class="ui horizontal segments statistiche" style="margin: 0 0 14px 0;"></div>
          <table class="ui small celled unstackable table" id="logsTable" style="width:100%; margin:0 !important"></table>
        </div>

      </div>
    `).appendTo('body');

    // First Load
    const $table = $modal.find( '#logsTable' );
    const dStart = moment().format( 'YYYY-MM-DD' );
    const dEnd = moment().format( 'YYYY-MM-DD' );
    await myVars.users.load();
    const logs = await myLog.getLogs(dStart, dEnd, false, myVars.io.id);
    myLog.printTable( logs, 'personal');

    // TABLE MAIN SEARCH
    $modal.on( 'keyup change', '[name="datatableSearch"]', function( e ) {
      const toSearch = $( this ).val();
      $table.DataTable().search( toSearch ).draw();
    });

    // TABLE VISITS FILTER
    $modal.find( '.visits' ).checkbox( {
      'onChange': function( value, text, $choice ) {
        const date = $('.dateRange').val().replace(/(dal:|al:)/gi, '').split(' ');
        const dStart = moment(date[0], 'DD/MM/YY').format( 'YYYY-MM-DD' );
        const dEnd = moment(date[1], 'DD/MM/YY').format( 'YYYY-MM-DD' );
        const visits = this.checked;

        myLog.getLogs(dStart, dEnd, visits, myVars.io.id).then((logs) => {
          myLog.printTable(logs, 'personal');
        });
      },
    });

    // TABLE LENGTH
    $modal.find( '.dtLength' ).dropdown( {
      'onChange': function( value, text, $choice ) {
        $table.DataTable().page.len( value ).draw();
      },
    });

    // Init Modal
    $modal.modal({
      autofocus: false,
      allowMultiple: true,
      transition: 'scale',
    }).modal('show');

    // TABLE DATE RANGE
    $modal.find('.dateRange').val('dal:' + moment().format('DD/MM/YY') + ' al:' + moment().format('DD/MM/YY'));
    myFunc.makeCalendar($modal.find('.dateRange'), 'range', (dStart, dEnd) => {
      const visits = $modal.find( '[name="visits"]' )[0].checked;
      myLog.getLogs(dStart, dEnd, visits, myVars.io.id).then((logs) => {
        myLog.printTable(logs, 'personal');
      });
    });
  },

  printTable: function(logs, tipo) {
    console.log('tipo', tipo);

    const $table = $('#logsTable');
    let dt;

    if ($table.hasClass('dataTable') && (tipo == 'personal' || $table.DataTable().columns()[0].length == 1)) {
      $table.DataTable().destroy();
      $table.empty();
    }

    if ($table.hasClass('dataTable')) { // Elimino i dati della tabella
      dt = $table.DataTable();
      dt.clear();
      dt.rows.add(logs);
      dt.draw();
    } else { // Creo la tabella
      const len = $( '[name="dtLength"]' ).val();
      const columns = logs[0] ? defineColumns(Object.keys(logs[0])) : {order: false, cols: [{created_at: ''}]};
      myDt.createTable($table, logs, columns, 'id', len);
      if (logs[0]) myDt.orderCols($table, true, [['created_at', 'desc']]);
      if (tipo == 'personal') $('#logsTable').closest('.dataTables_wrapper').css('margin', '0 -13px 0 -13px');

      $table.on('mouseenter', '.dataInfo', function(e) {
        if ($(this).closest('td').find('.popup').length) return;
        myLog.showDataInfo($(this));
      });
    }

    const toSearch = $('[name="datatableSearch"]').val();
    if (toSearch) $table.DataTable().search(toSearch);

    // On Draw NON SERVE
    myFunc.updateOnlineUser('all');
    $table.on('draw.dt', function(){
      myFunc.updateOnlineUser('all');
    });

    myFunc.elLoader(false);
    return $table;

    function defineColumns(colNames) {
      const cols = [];
      // const order = [];

      let myOrder = ['created_at', 'ip', 'user', 'path', 'method', '_html', '_idProgram', '_idRequest', '_interfaceHtml'];
      myOrder = [...new Set(myOrder.concat(colNames))];
      if (tipo == 'personal') myOrder.splice(2, 1);

      // Opzioni Colonne
      myOrder.forEach((n) => {
        let col = {visible: false/* , searchable: false*/};

        if (n == 'created_at') {
          col = {
            title: 'Data',
            searchable: true,
            className: 'collapsing right aligned logTrToConsole',
            render: function(data, type, row, meta) {
              const html = `<span style="display:none">${data}</span>${moment(data, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY<br>HH:mm')}`;
              return html;
            },
          };
        }

        if (n == 'user') {
          col = {
            title: 'User',
            searchable: true,
            className: 'collapsing',
            createdCell: function( td, cellData, rowData, row, col ) {
              const user = myVars.users.tutti.find((u) => u.id == rowData.user);
              if (!user) return;
              $( td ).addClass('onlineUserBg_'+user.id).css('padding-right', '60px');
              if (rowData.online) $( td ).addClass('green');
            },
            render: function(data, type, row, meta) {
              let html = '';
              const user = myVars.users.tutti.find((u) => u.id == data);
              html += user ? `<img data-filteruserid="${user.id}" class="ui mini avatar image" style="float:left;" src="./${user.avatar ? 'avatars/'+user.avatar : 'avatars/user.png'}"><b>${user.nome} ${user.cognome}</b></br>${user.ruolo}` : '';
              return html;
            },
          };
        }

        if (n == 'ip') {
          col = {
            title: 'From',
            searchable: true,
            className: 'collapsing',
            render: function( data, type, row, meta ) {
              let icon = '';
              let browser = 'Altro';

              if (row.agent.indexOf('Android') > -1) icon = 'green android';
              else if (row.agent.indexOf('iPhone') > -1) icon = 'grey mobile';
              else if (row.agent.indexOf('Macintosh') > -1) icon = 'grey apple';
              else if (row.agent.indexOf('Windows') > -1) icon = 'blue windows';

              if (row.agent.indexOf('Chrome') > -1 ) browser = 'Google Chrome';
              else if (row.agent.indexOf('Firefox') > -1 ) browser = 'Mozilla Firefox';
              else if (row.agent.indexOf('MSIE') > -1 ) browser = 'Internet Exploder';
              else if (row.agent.indexOf('Edge') > -1 ) browser = 'Internet Exploder';
              else if (row.agent.indexOf('Safari') > -1 ) browser = 'Safari';
              else if (row.agent.indexOf('Opera') > -1 ) browser = 'Opera';
              else browser = 'Altro';

              const html = `
                <div class="ui tiny header">
                  <i class="${icon} icon"></i>
                  <div class="content">
                    ${browser}
                    <div class="sub header">${data}</div>
                  </div>
                </div>`;

              return html;
            },
          };
        }

        if (n == '_html') {
          col = {
            title: 'Operazione',
            searchable: true,
            className: '',
            render: function( data, type, row, meta ) {
              return data.replace('####', (tipo == 'personal' ? 'Hai' : 'Ha'));
            },
          };
        }

        if (n == '_idProgram') {
          col = {
            title: 'Datatv',
            searchable: true,
            className: 'collapsing',
            render: function( data, type, row, meta ) {
              if (!data) return '';
              let html = '';
              if (data.length) {
                html += data.map((i) => `<a class="ui smallest blue label opacita8">PR ${i}</a>`).join('');
              }

              if (row._idEpisode.length) {
                html += row._idEpisode.map((i) => `<a class="ui smallest teal label">EP ${i}</a>`).join('');
              }

              return html;
            },
          };
        }

        if (n == '_idRequest') {
          col = {
            title: 'Content',
            searchable: true,
            className: 'collapsing',
            render: function( data, type, row, meta ) {
              if (!data) return '';
              let html = '';
              if (data.length) {
                html += data.map((i) => `<a class="ui smallest basic label">R. ${i}</a>`).join('');
              }

              return html;
            },
          };
        }

        if (n == '_interfaceHtml') {
          col = {
            title: 'Pagina',
            searchable: true,
            className: 'collapsing',
            render: function( data, type, row, meta ) {
              return data;
            },
          };
        }

        if (!col.data) col.data = n;
        if (!col.name) col.name = n;
        cols.push(col);
      });

      return {
        // order: order,
        cols: cols,
      };
    }
  },

  showDataInfo: function($el) {
    const $table = $el.closest('.dataTable');
    const $tr = $el.closest('tr');
    const row = $table.length && $tr.length ? $table.DataTable().row($tr) : false;
    const log = row.data();
    let popTxt = 'Nessun dato disponibile.';


    if (log.data && log.data[0]) {
      console.log('Log:\n', log);
      popTxt = log.data.map((o, i) => `        
        <table class="ui very basic small compact single line table">
        <thead><tr><th colspan="2"><i class="info circle icon"></i>Elemento ${i+1}</th></tr></thead>
        ${Object.keys(o).map((k) => {
    if (['interface', 'url'].includes(k)) return;
    return `
            <tr>
              <td>${k}</td>
              <td>${k == 'redattoreToolsId' ? myVars.users.tutti.find((u) => u.id ==o[k]).username :o[k] ?o[k].toString().replace(/\"/g, '') : '--'}</td>
            </tr>`;
  }).join('')}
        </table>
      `).join('');
    }

    $('.myPops').remove();
    $('.myPopsActivator').remove();
    const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
    const $pop = $(`    
      <div class="ui flowing popup myPops dataInfo" style="width:400px; font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif; line-height: 1.28571429em; max-height: 270px; overflow-y: auto;">        
        ${popTxt}
      </div>    
    `).appendTo($el);

    // popup
    $myPopsActivator.popup({
      popup: $pop,
      boundary: $('body'),
      exclusive: true,
      position: 'bottom left',
      lastResort: 'top left',
      distanceAway: -20,
      hoverable: true,
      on: 'manual',
      onHidden: function() {
        $myPopsActivator.remove();
        $pop.remove();
      },
    }).popup('show');

    $pop.find('input').focus();
  },
};