const modalInvioEpg = {
   open: async function (idChannel, startDate, endDate) {
      const isAdmin = myVars && myVars.io && myVars.io.ruolo === 'Admin';
      const isSatellite = myVars && myVars.io && myVars.io.ruolo && ['Redattore', 'DataEditor'].includes(myVars.io.ruolo) && /Satellite/gi.test(myVars.io.area);

      if (!isAdmin && !isSatellite)
         return;

      $.fn.transition.settings.silent = true;

      return new Promise(async (resolve, reject) => {

         console.log('open modalInvioEpg', idChannel, startDate, endDate);

         $('.ui.popup').remove();
         const $loader = $(`
            <div class="ui active inverted dimmer modalLoader">
               <div class="ui indeterminate text loader">Loading</div>
            </div>
         `).appendTo('body');

         // Crea Modal
         // const channelsToSend = await client.service('epg-scheduler').get('channelsToSend');
         const channelsToSend = await client.service('epg-scheduler').get('baseChannelsToSend');
         const $modal = modalInvioEpg.createModal(channelsToSend, idChannel, startDate, endDate);
         modalInvioEpg.$modal = $modal;

         $modal.modal({
            autofocus: false,
            allowMultiple: true,
            transition: 'scale',
            onApprove: function($element) {
               console.log('approve');
               myFunc.elLoader(true, $modal, 'Invio EPG...');
               modalInvioEpg.inviaEpg($modal.data('datiInvio'))
                  .then(res => {
                     console.log('res', res);
                     myFunc.elLoader(false, $modal);
                     $modal.modal('hide');
                     resolve('scheduled');
                  }).catch(err => {
                     myFunc.elLoader(false, $modal);
                     myError.show('Errore Invio', err);
                  });
               return false;
            },
            onHidden: function () {
               $('body').css('margin-right', 0);
               resolve('closed');
               modalSchedaDb.$modal = null;
               modalSchedaDb.origin = null;
            }
         }).modal('show');

         // First render
         $loader.remove();
      });
   },

   createModal: function (channelsToSend, idChannel, startDate, endDate) {
      const oggi = moment();
      const domani = oggi.clone().add('1', 'days');
      const mese = oggi.clone().startOf('month');
      const meseNext = mese.clone().add(1, 'month');

      const dates = [
         {
            label: mese.format('MMMM').toUpperCase(),
            startDate: mese.clone().startOf('month'),
            endDate: mese.clone().endOf('month'),
         },
         {
            label: `${mese.format('MMMM').toUpperCase()} ${mese.clone().startOf('month').format('DD')} - ${mese.clone().startOf('month').add(14, 'days').format('DD')}`,
            startDate: mese.clone().startOf('month'),
            endDate: mese.clone().startOf('month').add(14, 'days'),
         },
         {
            label: `${mese.format('MMMM').toUpperCase()} ${mese.clone().startOf('month').add(15, 'days').format('DD')} - ${mese.clone().endOf('month').format('DD')}`,
            startDate: mese.clone().startOf('month').add(15, 'days'),
            endDate: mese.clone().endOf('month'),
         },

         {
            label: meseNext.format('MMMM').toUpperCase(),
            startDate: meseNext.clone().startOf('month'),
            endDate: meseNext.clone().endOf('month'),
         },
         {
            label: `${meseNext.format('MMMM').toUpperCase()} ${meseNext.clone().startOf('month').format('DD')} - ${meseNext.clone().startOf('month').add(14, 'days').format('DD')}`,
            startDate: meseNext.clone().startOf('month'),
            endDate: meseNext.clone().startOf('month').add(14, 'days'),
         },
         {
            label: `${meseNext.format('MMMM').toUpperCase()} ${meseNext.clone().startOf('month').add(15, 'days').format('DD')} - ${meseNext.clone().endOf('month').format('DD')}`,
            startDate: meseNext.clone().startOf('month').add(15, 'days'),
            endDate: meseNext.clone().endOf('month'),
         },
         {
            label: `OGGI`,
            startDate: oggi,
            endDate: oggi,
         },
         {
            label: `OGGI + 1`,
            startDate: oggi,
            endDate: oggi.clone().add(1, 'days'),
         },
         {
            label: `DOMANI`,
            startDate: domani,
            endDate: domani,
         },
         {
            label: `DOMANI + 1`,
            startDate: domani,
            endDate: domani.clone().add(1, 'days'),
         },
         {
            label: `PROSSIMI 7 GIORNI`,
            startDate: domani,
            endDate: domani.clone().add(6, 'days'),
         },
      ];

      $('#modalInvioEpg').remove();
      let $modal = $(`
            <div class="ui tiny modal modalInvioEpg" id="modalInvioEpg">
               <div class="header"><i class="plane icon"></i>Invia Files EPG</div>

               <div class="scrolling content" style="min-height: 440px;">

                  <form class="ui form">

                     <div class="ui header">Scegli i canali da inviare</div>
                     <div class="field">
                        <label></label>
                        <div class="ui multiple fluid search clearable selection dropdown">
                           <input type="hidden" name="channels">
                           <i class="dropdown icon"></i>
                           <div class="default text">...</div>
                           <div class="menu">
                              ${channelsToSend.map(c => `<div class="item" data-value="${c.id}"><i class="${c.uploadS3 ? 'amazon' : 'envelope'} right floated grey icon"></i>${c.Name}</div>`).join('')}
                           </div>
                        </div>
                     </div>
                     <div class="ui small labels channelChilds"></div>

                     <div class="ui header">Scegli i giorni da inviare</div>
                     <div class="field">
                        <label></label>
                        <input type="text" name="startEndDate" placeholder="dal:--/--/-- al:--/--/--" value="dal:${domani.format('DD/MM/YY')} al:${domani.format('DD/MM/YY') }">
                     </div>
                     <div class="ui small labels">
                        ${dates.map(d => `<a class="ui label${d.label == 'DOMANI' ? ' blue' : ''}" data-startdate="${d.startDate.format('YYYY-MM-DD')}" data-endate="${d.endDate.format('YYYY-MM-DD') }">${d.label}</a>`).join('')}
                     </div>

                     <div class="ui header">Opzioni Invio</div>

                     <div class="field">
                        <div class="ui checkbox">
                           <input type="checkbox" tabindex="0" class="hidden" data-check="trascuraInviiPrecedenti">
                           <label><b class="a_orange">Trascura</b> Invii Precedenti</label>
                        </div>
                     </div>
                     <div class="field">
                        <div class="ui checkbox">
                           <input type="checkbox" tabindex="0" class="hidden" data-check="priorita">
                           <label><b class="a_red">Urgente</b> per Oggi</label>
                        </div>
                     </div>
                     <div class="field">
                        <div class="ui checkbox">
                           <input type="checkbox" tabindex="0" class="hidden" data-check="notturno">
                           <label>Invio <b class="a_blue">Notturno</b></label>
                        </div>
                     </div>
                     <div class="field">
                        <div class="ui checkbox">
                           <input type="checkbox" tabindex="0" class="hidden" data-check="testFlag">
                           <label>Invio per <b class="a_teal">Test</b></label>
                        </div>
                     </div>


                     <!--<div class="ui header">Series Link</div>
                     <div class="field disabled">
                        <div class="ui checkbox">
                           <input type="checkbox" tabindex="0" class="hidden" data-check="serieslink">
                           <label>Genera Series Link</label>
                        </div>
                     </div>-->

                  </form>

               </div>

               <div class="actions">
                  <div class="ui cancel red button">Annulla</div>
                  <div class="ui approve create green button">Invia EPG</div>
               </div>
            </div>`).appendTo('body');

      $(function () {
         $modal.data('datiInvio', {});

         $modal.find('[name="channels"]').closest('.dropdown').dropdown({ fullTextSearch: true, clearable: true, onChange: function (value, text) {
            const lastAddRem = myVars.channels.full.find(c => c.Name == text);
            console.log('lastAddRem', lastAddRem)
            updateForm('channels', lastAddRem ? lastAddRem.ID : '');

         } });
         myFunc.makeCalendar($modal.find('[name="startEndDate"]'), 'range', (dStart, dEnd) => {
            updateForm('startEndDate', [dStart, dEnd]);
         });
         $modal.find('.ui.checkbox').checkbox({
            onChange: function () {
               updateForm(this.dataset.check, this.checked);
            }
         });

         $modal.find(`[data-startdate]`).on('click', function (e) {
            const startDate = $(this).data('startdate');
            const endDate = $(this).data('endate');

            updateForm('startEndDate', [startDate, endDate]);
            $modal.find('[name="startEndDate"]').val(
               `dal:${moment(startDate, 'YYYY-MM-DD').format('DD/MM/YY')} al:${moment(endDate, 'YYYY-MM-DD').format('DD/MM/YY')}`
            );
         });

         // Se ho i parametri
         console.log(idChannel, startDate, endDate);
         if (idChannel) {
            $modal.find('[name="channels"]').closest('.dropdown')
               .dropdown('set selected', idChannel);

         }

         if (startDate) {
            if (!endDate) endDate = startDate;
            updateForm('startEndDate', [startDate, endDate]);
            $modal.find('[name="startEndDate"]').val(
               `dal:${moment(startDate, 'YYYY-MM-DD').format('DD/MM/YY')} al:${moment(endDate, 'YYYY-MM-DD').format('DD/MM/YY')}`
            );
         }

         updateForm();

         async function updateForm(el, last) {
            const $dropCh = $modal.find('[name="channels"]').closest('.dropdown');
            let allChannels = $dropCh.dropdown('get values') ? $dropCh.dropdown('get values').map(c => parseInt(c)) : [];

            // Aggiorna Pulsanti Dati
            if (el == 'startEndDate' && last) {

               $modal.find(`[data-startdate]`).removeClass('blue');
               if ($modal.find(`[data-startdate="${last[0]}"][data-endate="${last[1]}"]`).length) {
                  $modal.find(`[data-startdate="${last[0]}"][data-endate="${last[1]}"]`).addClass('blue');
               }
            }

            // Aggiorna Oggetto Dati
            const data = { channels: [], channelsChilds: [] };
            const idChannels = $dropCh.dropdown('get values');

            // Tolgo i figli
            for (const ic of idChannels) {
               const associatedChannels = await client.service('epg-scheduler').get('associatedChannels', { query: { idChannel: ic } })
                  .then(r => r.map(e => e.ID));

               if (!data.channelsChilds.includes(parseInt(ic)))
                  data.channels.push(ic);
               if (associatedChannels && associatedChannels.length)
                  data.channelsChilds.push(...associatedChannels);
            };

            $modal.find('.channelChilds').html(
               data.channelsChilds.map(c =>
                  `<div class="ui label">${myVars.channels.full.find(f => f.id == c).Name}</div>`
               ).join('')
            );

            data.startDate = /dal\:\d{2}\/\d{2}\/\d{2} al\:\d{2}\/\d{2}\/\d{2}/gi.test($modal.find('[name="startEndDate"]').val())
               ? moment($modal.find('[name="startEndDate"]').val().split(' al:')[0].replace('dal:', ''), 'DD/MM/YY').format('YYYY-MM-DD')
               : null;

            data.endDate = /dal\:\d{2}\/\d{2}\/\d{2} al\:\d{2}\/\d{2}\/\d{2}/gi.test($modal.find('[name="startEndDate"]').val())
               ? moment($modal.find('[name="startEndDate"]').val().split(' al:')[1], 'DD/MM/YY').format('YYYY-MM-DD')
               : null;

            $modal.find('[data-check]').each((i, ck) => {
               data[ck.dataset.check] = ck.checked ? 1 : 0;
            });

            $modal.data('datiInvio', data);
            console.log('datiInvio', last, data);
         }

      });

      return $modal;
   },

   inviaEpg: async function (data) {
      console.log('[inviaEpg] Inizio', data);

      const res = await client.service('epg-scheduler').get('inviaEpg', { query: data });

      if (res && res.length) {
         await myFunc.affermativeModal('Schedulazione completata', `
            <table class="ui small celled striped table">
               <thead>
                  <tr>
                     <th colspan="4">I canali seguenti sono stati messi in invio:</th>
                  </tr>
               </thead>
               <tbody>
                  ${res.map(r => `
                  <tr>
                     <td>${r.listaCanali}</td>
                     <td class="right aligned collapsing">${moment(r.giornoInizio, 'YYYY-MM-DD').format('DD/MM/YY')}</td>
                     <td class="right aligned collapsing">${moment(r.giornoFine, 'YYYY-MM-DD').subtract(1, 'day').format('DD/MM/YY')}</td>
                     <td class="collapsing">${r.NumeroGiorni}</td>
                  </tr>
                  `).join('')}
               </tbody>
            </table>`,
            null,
            'small'

         )
      } else {
         await myError.show('Errore Schedulazione', 'Impossibile Schedulare l\'invio, riprova più tardi.');
      }

      if ($('#epgSchedulerTable').length) {
         $('#epgSchedulerTable').DataTable().ajax.reload();
      }
      console.log('[inviaEpg] Fine', res);
      return res;
   }
}

if (typeof module != 'undefined') {
   module.exports = modalInvioEpg;
}