// es: modalImageChooser({title:'game of thrones tv series season 5 episode 4'})
// RITORNA LA PROMISE DI UNA IMMAGINE
module.exports = async function(req, title, filter) {
  // idProgram, idEpisode, idTmdb, type, season, episode, search

  return new Promise((resolve, reject) => {
    $('#modalimagechooser').remove();

    const $modal = $(`
      <div class="ui large modal" id="modalimagechooser">
        <div class="header">
        ${title ? title : 'Scegli una immagine'} ${req.title ? `per ${req.title}` : ``}
        </div>
        <div class="scrolling content"></div>
        <div class="actions">
          <div class="ui cancel button"><i class="remove icon"></i>Annulla</div>
        </div>
      </div>
      `).appendTo('body');

    // Loader
    const $loader = $(`
      <div class="ui active inverted dimmer modalLoader">
        <div class="ui indeterminate text loader">Loading</div>
      </div>`).appendTo($modal);

    // Content
    let myUrl = mySite + '/image-get?';
    Object.keys(req).forEach((k) =>{
      if (req[k]) myUrl += '&'+k+'=' + req[k];
    });
    console.log(myUrl);

    $.ajax({type: 'GET', url: myUrl}).then((res) =>{
      console.log(res);

      // ['service=content', 'height>=1080']
      const fimgs = renderSearchFilters(res.images, filter ? filter : ['imgs=all']);
      renderImages(fimgs);
      $loader.remove();
    });

    // Show Modal
    $modal.modal({
      autofocus: false,
      allowMultiple: true,
      transition: 'vertical flip',
    }).modal('show');

    function renderSearchFilters(imgs, filtersToApply) {
      console.log('BEFORE', filtersToApply);
      $modal.find('.searchFilters').remove();

      // avfilters
      const avfilters = [];
      imgs.forEach((img) => {
        if (img.service && !avfilters.includes('service=' + img.service)) avfilters.push('service=' + img.service);
        if (img.use && !avfilters.includes('use=' + img.use)) avfilters.push('use=' + img.use);
      });

      avfilters.sort((a, b) => {
        if (a.indexOf('service') > -1) return -1;
        else return 1;
      });
      // console.log(avfilters)

      // HTML
      let fhtml = '';
      avfilters.forEach((f, i) => {
        const k = f.split('=')[0];
        const v = f.split('=')[1];
        const icon = k == 'service' ? 'archive' : 'hand pointer outline';
        fhtml += `<a class="ui label imgsf" data-filtro="${k}=${v}"><i class="${icon} icon"></i>${v.toUpperCase()}</a>`;


        if (avfilters[i+1] && avfilters[i+1].split('=')[0] != k) {
          fhtml += '<i class="right angle grey icon divider" style="margin-right: 4px;"></i>';
        }
      });

      const $searchFilters = $(`
        <div class="searchFilters">
          <div class="ui small labels">
            <a class="ui label imgsf" data-filtro="imgs=all"><i class="inbox icon"></i>TUTTO</a>
            <i class="right angle grey icon divider" style="margin-right: 4px;"></i>
            ${fhtml}
          </div>
        </div>
        `).insertBefore($modal.find('.content'));

      // APPLY
      let fimgs = [];
      const uses = [];
      const services = [];

      filtersToApply.forEach((f) => {
        const match = f.match(/(.*[^>=])(>=|=)([^>=].*)/);
        const key = match[1];
        const opr = match[2];
        const val = match[3];

        if (key == 'use') uses.push(val);
        else if (key == 'service') services.push(val);

        $searchFilters.find('[data-filtro="imgs=all"]').removeClass('blue');
        $searchFilters.find('[data-filtro="'+f+'"]').addClass('blue');
      });

      if (uses.length > 0 && services.length > 0) fimgs = imgs.filter((i) => services.includes(i.service) && uses.includes(i.use));
      else if (uses.length == 0 && services.length > 0) fimgs = imgs.filter((i) => services.includes(i.service));
      else if (uses.length > 0 && services.length == 0) fimgs = imgs.filter((i) => uses.includes(i.use));
      else fimgs = imgs;

      // ADD REM
      $searchFilters.on('click', '.imgsf[data-filtro]', function(e) {
        e.preventDefault();
        e.stopPropagation();

        const filtro = $(this).data('filtro');
        const index = filtersToApply.findIndex((f) => f == filtro);
        const indexAll = filtersToApply.findIndex((f) => f == 'imgs=all');

        if (index > -1) { // Ho premuto un filtro già attivo
          filtersToApply.splice(index, 1);

        } else { // Ho premuto un filtro non attivo
          if (filtro == 'imgs=all') filtersToApply = ['imgs=all'];
          else {
            if (indexAll > -1) filtersToApply.splice(indexAll, 1);
            filtersToApply.push(filtro);
          }
        }

        const nimgs = renderSearchFilters(imgs, filtersToApply);
        renderImages(nimgs);

      });

      return fimgs;
    }

    function renderImages(imgs) {
      $modal.find('.content').html('');
      let imgsHtml = '';

      imgs.forEach((img) => {
        let color = '';
        if (img.service == 'Archivio') color = 'orange';
        else if (img.service == 'Content') color = 'blue';
        else if (img.service == 'VOD') color = 'purple';
        else if (img.service == 'Google') color = 'red';
        else if (img.service == 'Tmdb') color = 'teal';
        else if (img.service == 'Fanart') color = 'brown';

        if (img.lang == 'en') img.lang = 'uk';

        imgsHtml += `
          <table class="ui unstackable compact small single line table imageFile" data-url="${img.url}"><tbody>
          <tr><td>
            <img src="${(img.thumb ? img.thumb : img.url)}">
            <div class="ui tiny bottom left attached ${color} label" style="border-bottom-left-radius: 0;">${img.service.toUpperCase()}</div>            
          </td></tr>
          <tr><td>
            ${img.episodeNumber || (img.idEpisode && img.idEpisode != -1) ? `<div class="ui mini teal horizontal label" style="padding: 3px;">EP ${img.episodeNumber ? img.episodeNumber : '-'}</div>`: ''}
            ${img.lang ? `<i class="${img.lang} flag"></i>` : ``} 
            ${img.width} x ${img.height}</td></tr>
          <tr><td>
            <span class="ui small grey text">
              ${img.idProgram ? `PR ${img.idProgram}<br>` : ''}
              ${img.idEpisode && img.idEpisode != -1 ? `EP ${img.idEpisode}<br>` : ''}
              ${img.service == 'Google' ? `From: ${img.id}<br>` : ''}
              ${['Tmdb', 'Fanart'].includes(img.service) ? `ID ${img.id}<br>` : ''}
            </span>
          </td></tr>      
          </tbody></table>`;
      });

      $modal.find('.content').append(imgsHtml);

      $modal.find('.imageFile').css('cursor', 'pointer').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        const url = $(this).data('url');
        const imgObj = imgs.find((img) => img.url == url);

        console.log('Immagine Scelta:', imgObj);
        $modal.modal('hide');
        resolve(imgObj);
      });
    }
  });
}
