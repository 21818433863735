module.exports = {
  init: function() {
    if ($('.item.myImageReport').length) return;

    // Add Btn
    const $myUserMenu = $('.myUserMenu');
    $('<a class="item myImageReport"><i class="chart bar icon"></i>Immagini Archivio</a>').prependTo($myUserMenu);

    $('body').on('click', '.myImageReport', function() {
      myImageReport.openModal();
    });
   },


  openModal: async function() {
    $('#myImageReportModal').remove();

    const $modal = $(`
      <div class="ui large modal" id="myImageReportModal">

        <div class="header">
          <div class="ui unstackable small form">
            <div class="ui fields" style="margin: 0;">

              <div class="ui six wide field small header">
                <img class="ui avatar image" src="${mySite}/${myVars.io.avatar ? 'avatars/'+myVars.io.avatar : 'avatars/user.png'}">
                <div class="content">
                  ${myVars.io.nome} ${myVars.io.cognome}
                  <div class="sub header">Immagini Caricate in Archivio</div>
                </div>
              </div>

              <div class="four wide field">
                <div class="ui icon input">
                  <input type="text" class="dateRange" placeholder="Data" style="width: 230px;">
                  <i class="calendar alternate outline icon"></i>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="content report">

          <div class="ui form pricing">
            <div class="eight fields">
              <div class="field">
                <label style="white-space: nowrap;">Imm. Alt.</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="blue euro sign icon"></i></div>
                  <input readonly="" name="ia" placeholder="00" value="5">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Prog. 3 o meno</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="blue euro sign icon"></i></div>
                  <input readonly="" name="p3" placeholder="00" value="4">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Prog. 4</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="blue euro sign icon"></i></div>
                  <input readonly="" name="p4" placeholder="00" value="5">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Prog. 5</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="blue euro sign icon"></i></div>
                  <input readonly="" name="p5" placeholder="00" value="6">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Prog. 6</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="blue euro sign icon"></i></div>
                  <input readonly="" name="p6" placeholder="00" value="7">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Prog. 7</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="blue euro sign icon"></i></div>
                  <input readonly="" name="p7" placeholder="00" value="8">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Prog. 8</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="blue euro sign icon"></i></div>
                  <input readonly="" name="p8" placeholder="00" value="9">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Prog. 9 o più</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="blue euro sign icon"></i></div>
                  <input readonly="" name="p9" placeholder="00" value="10">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Episodi Hero</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="teal euro sign icon"></i></div>
                  <input readonly="" name="eh" placeholder="00" value="2">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Episodi 1</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="teal euro sign icon"></i></div>
                  <input readonly="" name="e1" placeholder="00" value="1">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Episodi 2</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="teal euro sign icon"></i></div>
                  <input readonly="" name="e2" placeholder="00" value="3">
                </div>
              </div>
              <div class="field">
                <label style="white-space: nowrap;">Episodi 3 o più</label>
                <div class="ui left labeled input">
                  <div class="ui basic icon label"><i class="teal euro sign icon"></i></div>
                  <input readonly="" name="e3" placeholder="00" value="4">
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    `).appendTo('body');

    // First Load
    const dStart = moment().startOf( 'month' ).format( 'YYYY-MM-DD' );
    const dEnd = moment().format( 'YYYY-MM-DD' );
    const date = 'dal:' + moment().startOf( 'month' ).format('DD/MM/YY') + ' al:' + moment().format('DD/MM/YY');
    const rData = await myImageReport.getReportData(myVars.io.id, dStart, dEnd);
    myImageReport.printSingleReport(rData, date, myVars.io, $modal.find('.content.report'));

    // Init Modal
    $modal.modal({
      autofocus: false,
      allowMultiple: true,
      transition: 'scale',
    }).modal('show');

    // TABLE DATE RANGE
    $modal.find('.dateRange').val(date);
    myFunc.makeCalendar($modal.find('.dateRange'), 'range', (dStart, dEnd) => {
      myImageReport.getReportData(myVars.io.id, dStart, dEnd).then((reportData) => {
        myImageReport.printSingleReport(reportData, $('.dateRange').val(), myVars.io, $modal.find('.content.report'));
      });
    });
  },

  getReportData: async function(userId, dStart, dEnd) {
    dEnd = moment(dEnd).add(1, 'day').format('YYYY-MM-DD');
    const query = {$limit: 50000, created_at: {$gte: dStart, $lte: dEnd}, created_by: userId};

    console.log('Report Immagini richiesti:', dStart, dEnd, query);
    const reportData = await client.service('archivio').find( {query: query}).then((r) => r.data);
    console.log('Report Immagini:', reportData);

    return reportData;
  },

  printSingleReport: function(rData, date, user, $dest) {
    const duplicati = {
      Programmi: {
        Poster_V: [],
        Poster_V_Logo: [],
        Poster_O: [],
        Poster_O_Logo: [],
        Hero: [],
        Scene: [],
        Loghi: [],
      },
      Episodi: {
        Poster_V: [],
        Poster_V_Logo: [],
        Poster_O: [],
        Poster_O_Logo: [],
        Hero: [],
        Scene: [],
        Loghi: [],
      },
    };

    const obj = {
      tot: rData,
      Immagini_Da_Lavorare: {tot: []},
      Immagini_Lavorate: {
        tot: [],
        Immagini_Vod: {
          tot: [],
          Singoli_Programmi: {tot: []},
          Singoli_Episodi: {tot: []},
          Fatte: {tot: []},
          Auto: {tot: []},
        },
        Altre_Immagini: {
          tot: [],
          Immagini_Programma: {
            tot: [],
            Singoli_Programmi: {
              tot: [],
              Programmi_3_o_meno: {tot: []},
              Programmi_4: {tot: []},
              Programmi_5: {tot: []},
              Programmi_6: {tot: []},
              Programmi_7: {tot: []},
              Programmi_8: {tot: []},
              Programmi_9_o_piu: {tot: []},
            },
          },
          Immagini_Episodio: {
            tot: [],
            Singoli_Episodi: {
              tot: [],
              Episodi_Hero: {tot: []},
              Episodi_1: {tot: []},
              Episodi_2: {tot: []},
              Episodi_3_o_piu: {tot: []},
            },
          },
        },
        Immagini_Alternative: {
          tot: [],
        },
      },
      Modifiche_Richieste: {
        tot: [],
        Immagini_Programma: {
          tot: [],
          Singoli_Programmi: {
            tot: [],
            Programmi_3_o_meno: {tot: []},
            Programmi_4: {tot: []},
            Programmi_5: {tot: []},
            Programmi_6: {tot: []},
            Programmi_7: {tot: []},
            Programmi_8: {tot: []},
            Programmi_9_o_piu: {tot: []},
          },
        },
        Immagini_Episodio: {
          tot: [],
          Singoli_Episodi: {
            tot: [],
            Episodi_Hero: {tot: []},
            Episodi_1: {tot: []},
            Episodi_2: {tot: []},
            Episodi_3_o_piu: {tot: []},
          },
        },
        Hero: [],
        Altre: [],
      },
      Correzioni: {tot: []},
      Immagini_KO: {tot: []},
    };

    // MACRO CATEGORIE
    obj.Immagini_Da_Lavorare.tot = rData.filter((i) => ['Da Lavorare'].includes(i.tags));
    obj.Immagini_Lavorate.tot = rData.filter((i) => !['Da Lavorare', 'Correzione', 'Cliente Cambio Giocatori', 'Cliente Cambio Grafica', 'Cliente Cambio Soggetti', 'Cliente Cambio Titolo'].includes(i.tags));
    obj.Modifiche_Richieste.tot = rData.filter((i) => ['Cliente Cambio Giocatori', 'Cliente Cambio Grafica', 'Cliente Cambio Soggetti', 'Cliente Cambio Titolo'].includes(i.tags));
    obj.Correzioni.tot = rData.filter((i) => ['Correzione'].includes(i.tags));
    obj.Immagini_KO.tot = rData.filter((i) => ['ko'].includes(i.approved));

    // VOD
    obj.Immagini_Lavorate.Immagini_Vod.tot = obj.Immagini_Lavorate.tot.filter((i) => i.approved == 'vod');
    obj.Immagini_Lavorate.Immagini_Vod.Singoli_Programmi.tot = [...new Set(obj.Immagini_Lavorate.Immagini_Vod.tot.filter((i) => i.idEpisode <= 0).map((i) => i.idProgram))];
    obj.Immagini_Lavorate.Immagini_Vod.Singoli_Episodi.tot = [...new Set(obj.Immagini_Lavorate.Immagini_Vod.tot.filter((i) => i.idEpisode > 0).map((i) => i.idEpisode))];
    obj.Immagini_Lavorate.Immagini_Vod.Fatte.tot = obj.Immagini_Lavorate.Immagini_Vod.tot.filter((i) => i.origin != 'Auto');
    obj.Immagini_Lavorate.Immagini_Vod.Auto.tot = obj.Immagini_Lavorate.Immagini_Vod.tot.filter((i) => i.origin == 'Auto');

    // Altre_Immagini // Le immagini ko vanno comunque considerate nei conteggi dei grafici
    obj.Immagini_Lavorate.Altre_Immagini.tot = obj.Immagini_Lavorate.tot.filter((i) => i.approved && i.approved != 'vod' /* && i.approved != 'ko'*/);
    obj.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.tot = obj.Immagini_Lavorate.Altre_Immagini.tot.filter((i) => i.idEpisode <= 0);
    obj.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio.tot = obj.Immagini_Lavorate.Altre_Immagini.tot.filter((i)=> i.idEpisode > 0);

    // Immagini Alternative
    console.log(obj)
    obj.Immagini_Lavorate.Immagini_Alternative.tot = obj.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.tot.filter((i) => ['Poster-V-Logo-AL1', 'Poster-V-Logo-AL2'].includes(i.use));

    // Rifacimenti
    obj.Modifiche_Richieste.Hero.tot = obj.Modifiche_Richieste.tot.filter((i) => i.use == 'Hero');
    obj.Modifiche_Richieste.Altre.tot = obj.Modifiche_Richieste.tot.filter((i) => i.use != 'Hero');
    // obj.Modifiche_Richieste.Immagini_Programma.tot = obj.Modifiche_Richieste.tot.filter(i => i.idEpisode <= 0);
    // obj.Modifiche_Richieste.Immagini_Episodio.tot = obj.Modifiche_Richieste.tot.filter(i=> i.idEpisode > 0);
    // obj.Modifiche_Richieste.Immagini_Programma.Singoli_Programmi.tot = [...new Set(obj.Modifiche_Richieste.Immagini_Programma.tot.map(i => i.idProgram))];
    // obj.Modifiche_Richieste.Immagini_Episodio.Singoli_Episodi.tot = [...new Set(obj.Modifiche_Richieste.Immagini_Episodio.tot.map(i => i.idEpisode))];

    // Singoli Programmi, Episodi
    obj.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.Singoli_Programmi.tot = [...new Set(obj.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.tot.map((i) => i.idProgram))];
    obj.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio.Singoli_Episodi.tot = [...new Set(obj.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio.tot.map((i) => i.idEpisode))];

    // Dettaglio Programmi
    obj.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.Singoli_Programmi.tot.forEach((id) => {
      const path = obj.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.Singoli_Programmi;
      const prImgs = obj.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.tot.filter((i) => id == i.idProgram);

      const V = prImgs.filter((i) => ['Poster-V'].includes(i.use));
      const Vl = prImgs.filter((i) => ['Poster-V-Logo'].includes(i.use));
      const O = prImgs.filter((i) => ['Poster-O'].includes(i.use));
      const Ol = prImgs.filter((i) => ['Poster-O-Logo'].includes(i.use));
      const H = prImgs.filter((i) => i.use == 'Hero');
      const S = prImgs.filter((i) => i.use == 'Scena');
      const L = prImgs.filter((i) => i.use == 'Logo');

      // const A = prImgs.filter((i) => ['Poster-V-Logo-AL1', 'Poster-V-Logo-AL2'].includes(i.use));

      //
      if (prImgs.length <= 3) path.Programmi_3_o_meno.tot.push(id);
      else if (prImgs.length == 4) path.Programmi_4.tot.push(id);
      else if (prImgs.length == 5) path.Programmi_5.tot.push(id);
      else if (prImgs.length == 6) path.Programmi_6.tot.push(id);
      else if (prImgs.length == 7) path.Programmi_7.tot.push(id);
      else if (prImgs.length == 8) path.Programmi_8.tot.push(id);
      else if (prImgs.length > 8) path.Programmi_9_o_piu.tot.push(id);

      if (user.id == 46) {
        console.log('Controllo:', prImgs, path);
      }

      // Duplicati
      if (V.filter((i) => i.approved != 'now').length > 1) duplicati.Programmi.Poster_V = duplicati.Programmi.Poster_V.concat([V]);
      if (Vl.filter((i) => i.approved != 'now').length > 1) duplicati.Programmi.Poster_V_Logo = duplicati.Programmi.Poster_V_Logo.concat([Vl]);
      if (O.filter((i) => i.approved != 'now').length > 1) duplicati.Programmi.Poster_O = duplicati.Programmi.Poster_O.concat([O]);
      if (Ol.filter((i) => i.approved != 'now').length > 1) duplicati.Programmi.Poster_O_Logo = duplicati.Programmi.Poster_O_Logo.concat([Ol]);
      if (H.filter((i) => i.approved != 'now').length > 1) duplicati.Programmi.Hero = duplicati.Programmi.Hero.concat([H]);
      if (S.filter((i) => i.approved != 'now').length > 1) duplicati.Programmi.Scene = duplicati.Programmi.Scene.concat([S]);
      if (L.filter((i) => i.approved != 'now').length > 1) duplicati.Programmi.Loghi = duplicati.Programmi.Loghi.concat([L]);
    });

    // Dettaglio Episodi
    obj.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio.Singoli_Episodi.tot.forEach((id) => {
      const path = obj.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio.Singoli_Episodi;
      const epImgs = obj.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio.tot.filter((i) => id == i.idEpisode);

      const V = epImgs.filter((i) => ['Poster-V'].includes(i.use));
      const Vl = epImgs.filter((i) => ['Poster-V-Logo'].includes(i.use));
      const O = epImgs.filter((i) => ['Poster-O'].includes(i.use));
      const Ol = epImgs.filter((i) => ['Poster-O-Logo'].includes(i.use));
      const H = epImgs.filter((i) => i.use == 'Hero');
      const S = epImgs.filter((i) => i.use == 'Scena');
      const L = epImgs.filter((i) => i.use == 'Logo');

      if (epImgs.length == 1 && H.length) path.Episodi_Hero.tot.push(id);
      else if (epImgs.length == 1) path.Episodi_1.tot.push(id);
      else if (epImgs.length == 2) path.Episodi_2.tot.push(id);
      else if (epImgs.length >= 3) path.Episodi_3_o_piu.tot.push(id);

      // Duplicati
      if (V.filter((i) => i.approved != 'now').length > 1) duplicati.Episodi.Poster_V = duplicati.Episodi.Poster_V.concat([V]);
      if (Vl.filter((i) => i.approved != 'now').length > 1) duplicati.Episodi.Poster_V_Logo = duplicati.Episodi.Poster_V_Logo.concat([Vl]);
      if (O.filter((i) => i.approved != 'now').length > 1) duplicati.Episodi.Poster_O = duplicati.Episodi.Poster_O.concat([O]);
      if (Ol.filter((i) => i.approved != 'now').length > 1) duplicati.Episodi.Poster_O_Logo = duplicati.Episodi.Poster_O_Logo.concat([Ol]);
      if (H.filter((i) => i.approved != 'now').length > 1) duplicati.Episodi.Hero = duplicati.Episodi.Hero.concat([H]);
      if (S.filter((i) => i.approved != 'now').length > 1) duplicati.Episodi.Scene = duplicati.Episodi.Scene.concat([S]);
      if (L.filter((i) => i.approved != 'now').length > 1) duplicati.Episodi.Loghi = duplicati.Episodi.Loghi.concat([L]);
    });

    // Dettaglio Modifiche
    // obj.Modifiche_Richieste.Immagini_Programma.Singoli_Programmi.tot.forEach(id => {
    //   const path = obj.Modifiche_Richieste.Immagini_Programma.Singoli_Programmi;
    //   const prImgs = obj.Modifiche_Richieste.Immagini_Programma.tot.filter(i => id == i.idProgram);

    //   const V = prImgs.filter(i => ['Poster-V'].includes(i.use));
    //   const Vl = prImgs.filter(i => ['Poster-V-Logo'].includes(i.use));
    //   const O = prImgs.filter(i => ['Poster-O'].includes(i.use));
    //   const Ol = prImgs.filter(i => ['Poster-O-Logo'].includes(i.use));
    //   const H = prImgs.filter(i => i.use == 'Hero');
    //   const S = prImgs.filter(i => i.use == 'Scena');
    //   const L = prImgs.filter(i => i.use == 'Logo');

    //   //
    //   if(prImgs.length <= 3) path.Programmi_3_o_meno.tot.push(prImgs);
    //   else if(prImgs.length == 4) path.Programmi_4.tot.push(prImgs);
    //   else if(prImgs.length == 5) path.Programmi_5.tot.push(prImgs);
    //   else if(prImgs.length == 6) path.Programmi_6.tot.push(prImgs);
    //   else if(prImgs.length == 7) path.Programmi_7.tot.push(prImgs);
    //   else if(prImgs.length == 8) path.Programmi_8.tot.push(prImgs);
    //   else if(prImgs.length > 8) path.Programmi_9_o_piu.tot.push(prImgs);
    // });

    // obj.Modifiche_Richieste.Immagini_Episodio.Singoli_Episodi.tot.forEach(id => {
    //   const path = obj.Modifiche_Richieste.Immagini_Episodio.Singoli_Episodi;
    //   const epImgs = obj.Modifiche_Richieste.Immagini_Episodio.tot.filter(i => id == i.idEpisode);

    //   const V = epImgs.filter(i => ['Poster-V'].includes(i.use));
    //   const Vl = epImgs.filter(i => ['Poster-V-Logo'].includes(i.use));
    //   const O = epImgs.filter(i => ['Poster-O'].includes(i.use));
    //   const Ol = epImgs.filter(i => ['Poster-O-Logo'].includes(i.use));
    //   const H = epImgs.filter(i => i.use == 'Hero');
    //   const S = epImgs.filter(i => i.use == 'Scena');
    //   const L = epImgs.filter(i => i.use == 'Logo');

    //   if(epImgs.length == 1 && H.length) path.Episodi_Hero.tot.push(H);
    //   else if(epImgs.length == 1) path.Episodi_1.tot.push(epImgs);
    //   else if(epImgs.length == 2) path.Episodi_2.tot.push(epImgs);
    //   else if(epImgs.length >= 3) path.Episodi_3_o_piu.tot.push(epImgs);
    // });


    let duplicatiPr = 0;
    Object.keys(duplicati.Programmi).forEach((k)=>{
      duplicatiPr += duplicati.Programmi[k].length;
    });
    let duplicatiEp = 0;
    Object.keys(duplicati.Episodi).forEach((k)=>{
      duplicatiEp += duplicati.Episodi[k].length;
    });

    const prObj = obj.Immagini_Lavorate.Altre_Immagini.Immagini_Programma;
    const epObj = obj.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio;
    const voObj = obj.Immagini_Lavorate.Immagini_Vod;

    const $pricing = $('.form.pricing');

    const totali = [
      // 0
      parseFloat( $pricing.find('[name="p3"]').val() ) * prObj.Singoli_Programmi.Programmi_3_o_meno.tot.length,
      // 1
      parseFloat( $pricing.find('[name="p4"]').val() ) * prObj.Singoli_Programmi.Programmi_4.tot.length,
      // 2
      parseFloat( $pricing.find('[name="p5"]').val() ) * prObj.Singoli_Programmi.Programmi_5.tot.length,
      // 3
      parseFloat( $pricing.find('[name="p6"]').val() ) * prObj.Singoli_Programmi.Programmi_6.tot.length,
      // 4
      parseFloat( $pricing.find('[name="p7"]').val() ) * prObj.Singoli_Programmi.Programmi_7.tot.length,
      // 5
      parseFloat( $pricing.find('[name="p8"]').val() ) * prObj.Singoli_Programmi.Programmi_8.tot.length,
      // 6
      parseFloat( $pricing.find('[name="p9"]').val() ) * prObj.Singoli_Programmi.Programmi_9_o_piu.tot.length,
      // 7
      parseFloat( $pricing.find('[name="eh"]').val() ) * epObj.Singoli_Episodi.Episodi_Hero.tot.length,
      // 8
      parseFloat( $pricing.find('[name="e1"]').val() ) * epObj.Singoli_Episodi.Episodi_1.tot.length,
      // 9
      parseFloat( $pricing.find('[name="e2"]').val() ) * epObj.Singoli_Episodi.Episodi_2.tot.length,
      // 10
      parseFloat( $pricing.find('[name="e3"]').val() ) * epObj.Singoli_Episodi.Episodi_3_o_piu.tot.length,
      // 11
      parseFloat( $pricing.find('[name="eh"]').val() ) * obj.Modifiche_Richieste.Hero.tot.length,
      // 12
      parseFloat( 1 ) * obj.Modifiche_Richieste.Altre.tot.length,
      // 12
      parseFloat( $pricing.find('[name="ia"]').val() ) * obj.Immagini_Lavorate.Immagini_Alternative.tot.length,
    ];
    const totale = totali.reduce((a, b) => a + b, 0);

    console.log('--- ' + user.nome + '---', '\nImmagini:', obj, '\nDuplicati:', duplicati, '\nTotale:', totali.join('+') + ' = ' + totale + '\n\n');

    $dest.find(`table.imageReport.ra_${user.id}`).remove();

    const $table = $(`
      <table class="ui table imageReport ra_${user.id}">
        <tbody>
          <tr>
            <td colspan="3">
              <h3 class="ui header">
                <img data-filteruserid="${user.id}" class="ui small avatar image" style="float:left;" src="./${user.avatar ? 'avatars/'+user.avatar : 'avatars/user.png'}">
                <div class="content">${user.nome} ${user.cognome}
                  <div class="sub header">${date} (<b class="a_red">${duplicatiPr}</b> Immagini Programma Duplicate, <b class="a_red">${duplicatiEp}</b> Immagini Episodio Duplicate)</div>
                </div>
              </h3>
            </td>
            <td>
              <div class="ui right floated mini red statistic">
                <div class="value"><i class="euro sign icon"></i> ${totale}</div>
                <div class="label">Totali</div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="top aligned mTree" style="width:25%;">
              <div class="item">
                <span class="ui small orange horizontal basic label">${obj.tot.length}</span> Immagini Caricate
                <div class="sub">

                ${Object.keys(obj).map((k) =>{
                  if (k == 'tot') return '';
                  return `
                  <div class="item ${!obj[k].tot.length ? 'off' : ''}"><span class="ui small orange horizontal basic label">${obj[k].tot.length}</span> ${k.replace(/\_/gi, ' ')}
                    <div class="sub">
                      ${Object.keys(obj[k]).map((j) =>{
                        if (j == 'tot') return '';

                        let eur = 0;
                        if (j == 'Hero') {
                          eur = totali[11];
                        } else if (j == 'Altre') {
                          eur = totali[12];
                        } else if (j == 'Immagini_Alternative') {
                          eur = totali[13];
                        }

                        return `
                        <div class="item ${!obj[k][j].tot.length ? 'off' : ''}">
                          <span class="ui small orange horizontal basic label">${obj[k][j].tot.length}</span> ${j.replace(/\_/gi, ' ')} ${eur ? '<span class="a_grey opacita6">('+eur+'€)</span>' : ''}
                        </div>`;
                  }).join('')}
                    </div>
                  </div>`;
                  }).join('')}

                </div>
              </div>
            </td>
            <td class="top aligned mTree" style="width:25%;">
              <div class="item"><span class="ui small orange horizontal basic label">${obj.Immagini_Lavorate.Altre_Immagini.tot.length}</span> Altre Immagini
                <div class="sub">
                  <div class="item ${!prObj.tot.length ? 'off' : ''}"><span class="ui small blue horizontal basic label">${prObj.tot.length}</span> Immagini Programma
                    <div class="sub">

                      ${Object.keys(prObj).map((k) =>{
                        if (k == 'tot') return '';
                        return `
                        <div class="item ${!prObj[k].tot.length ? 'off' : ''}"><span class="ui small blue horizontal basic label">${prObj[k].tot.length}</span> ${k.replace(/\_/gi, ' ')}
                          <div class="sub">

                            ${Object.keys(prObj[k]).map((j) =>{
                              if (j == 'tot') return '';

                              let eur = 0;
                              if (j == 'Programmi_3_o_meno') {
                                eur = totali[0];
                              } else if (j == 'Programmi_4') {
                                eur = totali[1];
                              } else if (j == 'Programmi_5') {
                                eur = totali[2];
                              } else if (j == 'Programmi_6') {
                                eur = totali[3];
                              } else if (j == 'Programmi_7') {
                                eur = totali[4];
                              } else if (j == 'Programmi_8') {
                                eur = totali[5];
                              } else if (j == 'Programmi_7_o_piu') {
                                eur = totali[6];
                              }

                              // console.log('', j, eur, totali);
                              return `
                              <div class="item ${!prObj[k][j].tot.length ? 'off' : ''}">
                                <span class="ui small blue horizontal basic label">${prObj[k][j].tot.length}</span> ${j.replace(/\_/gi, ' ')} ${eur ? '<span class="a_grey opacita6">('+eur+'€)</span>' : ''}
                              </div>`;
                            }).join('')}

                          </div>
                        </div>`;
                      }).join('')}

                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="top aligned mTree" style="width:25%;">
              <div class="item"><span class="ui small orange horizontal basic label">${obj.Immagini_Lavorate.Altre_Immagini.tot.length}</span> Altre Immagini
                <div class="sub">
                  <div class="item ${!epObj.tot.length ? 'off' : ''}"><span class="ui small teal horizontal basic label">${epObj.tot.length}</span> Immagini Episodio
                    <div class="sub">

                      ${Object.keys(epObj).map((k) =>{
                        if (k == 'tot') return '';
                        return `
                        <div class="item ${!epObj[k].tot.length ? 'off' : ''}"><span class="ui small teal horizontal basic label">${epObj[k].tot.length}</span> ${k.replace(/\_/gi, ' ')}
                          <div class="sub">

                            ${Object.keys(epObj[k]).map((j) =>{
                              if (j == 'tot') return '';

                              let eur = 0;
                              if (j == 'Episodi_Hero') {
                                eur = totali[7];
                              } else if (j == 'Episodi_1') {
                                eur = totali[8];
                              } else if (j == 'Episodi_2') {
                                eur = totali[9];
                              } else if (j == 'Episodi_3_o_piu') {
                                eur = totali[10];
                              }
                              return `
                                <div class="item ${!epObj[k][j].tot.length ? 'off' : ''}">
                                  <span class="ui small teal horizontal basic label">${epObj[k][j].tot.length}</span> ${j.replace(/\_/gi, ' ')} ${eur ? '<span class="a_grey opacita6">('+eur+'€)</span>' : ''}
                                </div>`;
                            }).join('')}

                          </div>
                        </div>`;
                      }).join('')}

                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="top aligned mTree" style="width:25%;">
              <div class="item"><span class="ui small orange horizontal basic label">${voObj.tot.length}</span> Immagini Vod
                <div class="sub">

                  ${Object.keys(voObj).map((k) =>{
                    if (k == 'tot') return '';
                    return `
                    <div class="item ${!voObj[k].tot.length ? 'off' : ''}"><span class="ui small purple horizontal basic label">${voObj[k].tot.length}</span> ${k.replace(/\_/gi, ' ')}
                      <div class="sub">

                        ${Object.keys(voObj[k]).map((j) =>{
                          if (j == 'tot') return '';
                          return `
                          <div class="item ${!voObj[k][j].tot.length ? 'off' : ''}">
                            <span class="ui small purple horizontal basic label">${voObj[k][j].tot.length}</span> ${j.replace(/\_/gi, ' ')}
                          </div>`;
                        }).join('')}

                      </div>
                    </div>`;
                  }).join('')}

                </div>
              </div>
              <div class="ui olive button downloadReport"><i class="download icon"></i>Scarica Report</div>
            </td>
          </tr>
        </tbody>
      </table>`).appendTo($dest);

    $table.find('.downloadReport').on('click', function() {
      myImageReport.downloadReport(user, obj);
    });
  },

  downloadReport: function(user, report) {
     console.log('downloadReport', user, report);

     const a_pr = [];
     const a_ep = [];
     const a_mo = [];
     const a_recapw = [];

     report.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.tot.forEach((r) => {
        let row = {
           'Data': r.created_at,
           'Utente': user.nome + ' ' + user.cognome,
           'App.': r.approved,
           'IDP': r.idProgram,
           'Programma': r.programTitle,
           'IDE': r.idEpisode && r.idEpisode != -1 ? r.idEpisode : r.aaa,
           'Episodio': r.episodeTitle,
           'Tipo': r.use,
           'Motivo': r.tags,
           'W': r.width,
           'H': r.height,
        };

        a_pr.push(row);
     });

     report.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio.tot.forEach((r) => {
        let row = {
           'Data': r.created_at,
           'Utente': user.nome + ' ' + user.cognome,
           'App.': r.approved,
           'IDP': r.idProgram,
           'Programma': r.programTitle,
           'IDE': r.idEpisode && r.idEpisode != -1 ? r.idEpisode : r.aaa,
           'Episodio': r.episodeTitle,
           'Tipo': r.use,
           'Motivo': r.tags,
           'W': r.width,
           'H': r.height,
        };

        a_ep.push(row);
     });

     report.Modifiche_Richieste.tot.forEach((r) => {
        let row = {
           'Data': r.created_at,
           'Utente': user.nome + ' ' + user.cognome,
           'App.': r.approved,
           'IDP': r.idProgram,
           'Programma': r.programTitle,
           'IDE': r.idEpisode && r.idEpisode != -1 ? r.idEpisode : r.aaa,
           'Episodio': r.episodeTitle,
           'Tipo': r.use,
           'Motivo': r.tags,
           'W': r.width,
           'H': r.height,
        };

        a_mo.push(row);
     });

     // imgClienti
     const imgClienti = {
        all: {
           imgs: report.Modifiche_Richieste.tot.filter((i) => i.approved == 'all'),
           idps: [],
           prog: {
              img1: [],
              img2: [],
              img3: [],
              img4: [],
              hero: [],

           },
           ides: [],
           eps: {
              img1: [],
              img2: [],
              img3: [],
              img4: [],
              hero: [],
           },
        },
        sky: {
           imgs: report.Modifiche_Richieste.tot.filter((i) => i.approved == 'sky' || i.approved == 'now'),
           idps: [],
           prog: {
              img1: [],
              img2: [],
              img3: [],
              img4: [],
              hero: [],

           },
           ides: [],
           eps: {
              img1: [],
              img2: [],
              img3: [],
              img4: [],
              hero: [],
           },
        },
        dazn: {
           imgs: report.Modifiche_Richieste.tot.filter((i) => i.approved == 'dazn'),
           idps: [],
           prog: {
              img1: [],
              img2: [],
              img3: [],
              img4: [],
              hero: [],

           },
           ides: [],
           eps: {
              img1: [],
              img2: [],
              img3: [],
              img4: [],
              hero: [],
           },
        },
        tim: {
           imgs: report.Modifiche_Richieste.tot.filter((i) => i.approved == 'tim'),
           idps: [],
           prog: {
              img1: [],
              img2: [],
              img3: [],
              img4: [],
              hero: [],

           },
           ides: [],
           eps: {
              img1: [],
              img2: [],
              img3: [],
              img4: [],
              hero: [],
           },
        },
     };

     Object.keys(imgClienti).forEach((k) => {
        const cliente = imgClienti[k];
        const imgs = cliente.imgs;
        cliente.idps = [...new Set(imgs.map((i) => i.idEpisode <= 0 ? i.idProgram : null).filter((e) => e))];
        cliente.ides = [...new Set(imgs.map((i) => i.idEpisode > 0 ? i.idEpisode : null).filter((e) => e))];

        cliente.idps.forEach((id) => {
           const prImgs = imgs.filter((i) => id == i.idProgram && i.idEpisode <= 0);
           const hero = prImgs.filter((i) => i.use == 'Hero');
           const altre = prImgs.filter((i) => i.use != 'Hero');

           if (hero && hero.length) cliente.prog.hero.push(hero);

           if (altre && altre.length == 1) cliente.prog.img1.push(altre);
           else if (altre && altre.length == 2) cliente.prog.img2.push(altre);
           else if (altre && altre.length == 3) cliente.prog.img3.push(altre);
           else if (altre && altre.length >= 4) cliente.prog.img4.push(altre);
        });

        cliente.ides.forEach((id) => {
           const epImgs = imgs.filter((i) => id == i.idEpisode);
           const hero = epImgs.filter((i) => i.use == 'Hero');
           const altre = epImgs.filter((i) => i.use != 'Hero');

           if (hero && hero.length) cliente.eps.hero.push(hero);

           if (altre && altre.length == 1) cliente.eps.img1.push(altre);
           else if (altre && altre.length == 2) cliente.eps.img2.push(altre);
           else if (altre && altre.length == 3) cliente.eps.img3.push(altre);
           else if (altre && altre.length >= 4) cliente.eps.img4.push(altre);
        });
     });

     Object.keys(imgClienti).forEach((k) => {
        const cliente = imgClienti[k];
        a_recapw.push({ a: 'Programmi Hero', b: cliente.prog.hero.map((p) => p.length).reduce((pv, cv) => pv + cv, 0) });
        a_recapw.push({ a: 'Programmi 1 Foto', b: cliente.prog.img1.length });
        a_recapw.push({ a: 'Programmi 2 Foto', b: cliente.prog.img2.length });
        a_recapw.push({ a: 'Programmi 3 Foto', b: cliente.prog.img3.length });
        a_recapw.push({ a: 'Programmi 4 Foto o piú', b: cliente.prog.img4.length });
        a_recapw.push({ a: 'Episodi Hero', b: cliente.eps.hero.map((p) => p.length).reduce((pv, cv) => pv + cv, 0) });
        a_recapw.push({ a: 'Episodi 1 Foto', b: cliente.eps.img1.length });
        a_recapw.push({ a: 'Episodi 2 Foto', b: cliente.eps.img2.length });
        a_recapw.push({ a: 'Episodi 3 Foto', b: cliente.eps.img3.length });
        a_recapw.push({ a: 'Episodi 4 Foto o piú', b: cliente.eps.img4.length });
        a_recapw.push({ a: '', b: '' });
        a_recapw.push({ a: '', b: '' });
     });

     const wBook = XLSX.utils.book_new();
     const style = {
        fgColor: { rgb: 0x2185d0 }, // blue solid background
        color: { rgb: 0xFFFFFF }, // white text
        top: { style: "thick", color: { rgb: 0xdededf } }, // thick yellow border
        bottom: { style: "thick", color: { rgb: 0xdededf } }, // red thick border
        left: { style: "thick", color: { rgb: 0xdededf } } // green dashed border
     }

     const ws_pr = XLSX.utils.json_to_sheet(a_pr);
     XLSX.utils.book_append_sheet(wBook, ws_pr, '>>> Immagini Programma');
     XLSX.utils.sheet_set_range_style(ws_pr, `A1:BA1`, style);

     const ws_ep = XLSX.utils.json_to_sheet(a_ep);
     XLSX.utils.book_append_sheet(wBook, ws_ep, '>>> Immagini Episodio');
     XLSX.utils.sheet_set_range_style(ws_ep, `A1:BA1`, style);

     const ws_mo = XLSX.utils.json_to_sheet(a_mo);
     XLSX.utils.book_append_sheet(wBook, ws_mo, '>>> Modifiche Richieste');
     XLSX.utils.sheet_set_range_style(ws_mo, `A1:BA1`, style);

     const ws_recapw = XLSX.utils.json_to_sheet(a_recapw);
     XLSX.utils.book_append_sheet(wBook, ws_recapw, '>>> Recap Modifiche Richieste');
     XLSX.utils.sheet_set_range_style(ws_recapw, `A1:BA1`, style);


     // Download
     let filename = `Report Immagini ${user.nome} ${user.cognome}.xlsx`;
     XLSX.writeFile(wBook, filename, { bookType: 'xlsx', cellStyles: true });


   //  // WORKBOOK
   //  const workbook = new ExcelJS.Workbook();

   //  // SHEETS
   //  const pr = workbook.addWorksheet('>>> Immagini Programma');
   //  const ep = workbook.addWorksheet('>>> Immagini Episodio');
   //  const mo = workbook.addWorksheet('>>> Modifiche Richieste');
   //  const recapw = workbook.addWorksheet('>>> Recap Modifiche Richieste');

   //  console.log(report.Modifiche_Richieste.tot);
   //  const baseCols = [
   //    {
   //      header: 'Data',
   //      key: 'created_at',
   //      width: 12,
   //    }, {
   //      header: 'Utente',
   //      key: 'utente',
   //      width: 24,
   //    }, {
   //      header: 'App.',
   //      key: 'approved',
   //      width: 12,
   //    }, {
   //      header: 'IDP',
   //      key: 'idProgram',
   //      width: 12,
   //    }, {
   //      header: 'Programma',
   //      key: 'programTitle',
   //      width: 36,
   //    }, {
   //      header: 'IDE',
   //      key: 'idEpisodio',
   //      width: 12,
   //    }, {
   //      header: 'Episodio',
   //      key: 'episodeTitle',
   //      width: 36,
   //    }, {
   //      header: 'Tipo',
   //      key: 'use',
   //      width: 16,
   //    }, {
   //      header: 'Motivo',
   //      key: 'tags',
   //      width: 24,
   //    }, {
   //      header: 'W',
   //      key: 'width',
   //      width: 6,
   //    }, {
   //      header: 'H',
   //      key: 'height',
   //      width: 6,
   //    },
   //  ];

   //  pr.columns = baseCols;
   //  ep.columns = baseCols;
   //  mo.columns = baseCols;

   //  report.Modifiche_Richieste.tot.forEach((r) => {
   //    r.utente = user.nome + ' ' + user.cognome;
   //    r.idEpisodio = r.idEpisode && r.idEpisode != -1 ? r.idEpisode : '';
   //    mo.addRow(r);
   //  });

   //  report.Immagini_Lavorate.Altre_Immagini.Immagini_Programma.tot.forEach((r) => {
   //    r.utente = user.nome + ' ' + user.cognome;
   //    r.idEpisodio = r.idEpisode && r.idEpisode != -1 ? r.idEpisode : '';
   //    pr.addRow(r);
   //  });

   //  report.Immagini_Lavorate.Altre_Immagini.Immagini_Episodio.tot.forEach((r) => {
   //    r.utente = user.nome + ' ' + user.cognome;
   //    r.idEpisodio = r.idEpisode && r.idEpisode != -1 ? r.idEpisode : '';
   //    ep.addRow(r);
   //  });

   //  // Recap Modifiche Richieste
   //  recapw.columns = [
   //    {
   //      key: 'a',
   //      width: 24,
   //    }, {
   //      key: 'b',
   //      width: 16,
   //    },
   //  ];

   //  const imgClienti = {
   //    all: {
   //      imgs: report.Modifiche_Richieste.tot.filter((i) => i.approved == 'all'),
   //      idps: [],
   //      prog: {
   //        img1: [],
   //        img2: [],
   //        img3: [],
   //        img4: [],
   //        hero: [],

   //      },
   //      ides: [],
   //      eps: {
   //        img1: [],
   //        img2: [],
   //        img3: [],
   //        img4: [],
   //        hero: [],
   //      },
   //    },
   //    sky: {
   //      imgs: report.Modifiche_Richieste.tot.filter((i) => i.approved == 'sky' || i.approved == 'now'),
   //      idps: [],
   //      prog: {
   //        img1: [],
   //        img2: [],
   //        img3: [],
   //        img4: [],
   //        hero: [],

   //      },
   //      ides: [],
   //      eps: {
   //        img1: [],
   //        img2: [],
   //        img3: [],
   //        img4: [],
   //        hero: [],
   //      },
   //    },
   //    dazn: {
   //      imgs: report.Modifiche_Richieste.tot.filter((i) => i.approved == 'dazn'),
   //      idps: [],
   //      prog: {
   //        img1: [],
   //        img2: [],
   //        img3: [],
   //        img4: [],
   //        hero: [],

   //      },
   //      ides: [],
   //      eps: {
   //        img1: [],
   //        img2: [],
   //        img3: [],
   //        img4: [],
   //        hero: [],
   //      },
   //    },
   //    tim: {
   //      imgs: report.Modifiche_Richieste.tot.filter((i) => i.approved == 'tim'),
   //      idps: [],
   //      prog: {
   //        img1: [],
   //        img2: [],
   //        img3: [],
   //        img4: [],
   //        hero: [],

   //      },
   //      ides: [],
   //      eps: {
   //        img1: [],
   //        img2: [],
   //        img3: [],
   //        img4: [],
   //        hero: [],
   //      },
   //    },
   //  };

   //  Object.keys(imgClienti).forEach((k) => {
   //    const cliente = imgClienti[k];
   //    const imgs = cliente.imgs;
   //    cliente.idps = [...new Set(imgs.map((i) => i.idEpisode <= 0 ? i.idProgram : null).filter((e) => e))];
   //    cliente.ides = [...new Set(imgs.map((i) => i.idEpisode > 0 ? i.idEpisode : null).filter((e) => e))];

   //    cliente.idps.forEach((id) => {
   //      const prImgs = imgs.filter((i) => id == i.idProgram && i.idEpisode <= 0);
   //      const hero = prImgs.filter((i) => i.use == 'Hero');
   //      const altre = prImgs.filter((i) => i.use != 'Hero');

   //      if (hero && hero.length) cliente.prog.hero.push(hero);

   //      if (altre && altre.length == 1) cliente.prog.img1.push(altre);
   //      else if (altre && altre.length == 2) cliente.prog.img2.push(altre);
   //      else if (altre && altre.length == 3) cliente.prog.img3.push(altre);
   //      else if (altre && altre.length >= 4) cliente.prog.img4.push(altre);
   //    });

   //    cliente.ides.forEach((id) => {
   //      const epImgs = imgs.filter((i) => id == i.idEpisode);
   //      const hero = epImgs.filter((i) => i.use == 'Hero');
   //      const altre = epImgs.filter((i) => i.use != 'Hero');

   //      if (hero && hero.length) cliente.eps.hero.push(hero);

   //      if (altre && altre.length == 1) cliente.eps.img1.push(altre);
   //      else if (altre && altre.length == 2) cliente.eps.img2.push(altre);
   //      else if (altre && altre.length == 3) cliente.eps.img3.push(altre);
   //      else if (altre && altre.length >= 4) cliente.eps.img4.push(altre);
   //    });
   //  });

   //  console.log(imgClienti);

   //  Object.keys(imgClienti).forEach((k) => {
   //    const cliente = imgClienti[k];
   //    recapw.addRow({a: k.toUpperCase(), b: ''}).fill = bgblue;
   //    recapw.addRow({a: 'Programmi Hero', b: cliente.prog.hero.map((p) => p.length).reduce((pv, cv) => pv + cv, 0)});
   //    recapw.addRow({a: 'Programmi 1 Foto', b: cliente.prog.img1.length});
   //    recapw.addRow({a: 'Programmi 2 Foto', b: cliente.prog.img2.length});
   //    recapw.addRow({a: 'Programmi 3 Foto', b: cliente.prog.img3.length});
   //    recapw.addRow({a: 'Programmi 4 Foto o piú', b: cliente.prog.img4.length});
   //    recapw.addRow({a: 'Episodi Hero', b: cliente.eps.hero.map((p) => p.length).reduce((pv, cv) => pv + cv, 0)});
   //    recapw.addRow({a: 'Episodi 1 Foto', b: cliente.eps.img1.length});
   //    recapw.addRow({a: 'Episodi 2 Foto', b: cliente.eps.img2.length});
   //    recapw.addRow({a: 'Episodi 3 Foto', b: cliente.eps.img3.length});
   //    recapw.addRow({a: 'Episodi 4 Foto o piú', b: cliente.eps.img4.length});
   //    recapw.addRow({a: '', b: ''});
   //    recapw.addRow({a: '', b: ''});
   //  });

   //  // DOWNLOAD
   //  workbook.xlsx.writeBuffer({
   //    base64: true,
   //  })
   //    .then(function(xls64) {
   //      // build anchor tag and attach file (works in chrome)
   //      const a = document.createElement('a');
   //      const data = new Blob([xls64], {
   //        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
   //      });

   //      const url = URL.createObjectURL(data);
   //      a.href = url;
   //      a.download = `Report Immagini ${user.nome} ${user.cognome}.xlsx`;
   //      document.body.appendChild(a);
   //      a.click();
   //      setTimeout(function() {
   //        document.body.removeChild(a);
   //        window.URL.revokeObjectURL(url);
   //      }, 0);
   //    })
   //    .catch(function(error) {
   //      console.log(error.message);
   //    });
  },
};