class ImgCanvas {

   ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// STATIC
   static imagesLoaded = [

   ];

   static formats = [{
      service: 'All',
      id: 'masterVL',
      name: 'Poster-V-Logo',
      uses: ['Poster-V-Logo'],
      width: 1280,
      height: 1920,
   }, {
      service: 'All',
      id: 'masterV',
      name: 'Poster-V',
      uses: ['Poster-V'],
      width: 1280,
      height: 1920,
   }, {
      service: 'All',
      id: 'masterOL',
      name: 'Poster-O-Logo',
      uses: ['Poster-O-Logo'],
      width: 1920,
      height: 1080,
   }, {
      service: 'All',
      id: 'masterO',
      name: 'Poster-O',
      uses: ['Poster-O'],
      width: 1920,
      height: 1080,
   }, {
      service: 'All',
      id: 'masterH',
      name: 'Background',
      uses: ['Hero'],
      width: 1920,
      height: 1080,
   }, {
      service: 'All',
      id: 'masterS',
      name: 'Scena',
      uses: ['Scena'],
      width: 1920,
      height: 1080,
   }, {
      service: 'All',
      id: 'masterL',
      name: 'Logo',
      uses: ['Logo'],
      width: 2880,
      height: 2880,
   }, {
      service: 'All',
      id: 'customA',
      name: '164x242',
      uses: ['Poster-V-Logo'],
      width: 164,
      height: 242,
   }, {
      service: 'All',
      id: 'Person',
      name: 'Person',
      uses: ['Character'],
      width: 600,
      height: 900,
   }, {
      service: 'All',
      id: 'imageDTT',
      name: 'Foto DTT',
      uses: ['Poster-O-Logo', 'Poster-O', 'Scena'],
      width: 1920,
      height: 1080,
      safeArea: 'safeArea-imageDTT.png',
   }, {
      service: 'All',
      id: 'PunchOut',
      name: 'Punch Out',
      uses: ['Character'],
      width: 400,
      height: 400,
   }, {
      service: 'Timvision',
      id: 'imageA',
      name: 'Locandina',
      uses: ['Poster-V-Logo', 'Poster-V'],
      width: 600,
      height: 900,
   }, {
      service: 'Timvision',
      id: 'imageC',
      name: 'Cover',
      uses: ['Poster-O-Logo', 'Poster-O'],
      width: 800,
      height: 600,
      programLogo: true,
   }, {
      service: 'Timvision',
      id: 'imageD',
      name: 'Background',
      episodic: true,
      uses: ['Poster-O', 'Scena'],
      width: 1920,
      height: 1080,
   }, {
      service: 'Timvision',
      id: 'imageB',
      name: 'Scena Tim',
      episodic: true,
      uses: ['Scena'],
      width: 800,
      height: 600,
   }, {
      service: 'Timvision',
      id: 'imageR',
      name: 'C+ Locandina',
      uses: ['Poster-V-Logo'],
      width: 600,
      height: 800,
   }, {
      service: 'Timvision',
      id: 'imageS',
      name: 'C+ Cover',
      uses: ['Poster-O-Logo'],
      width: 1920,
      height: 1080,
      programLogo: true,
   }, {
      service: 'Sky',
      id: 'imageE',
      name: 'Cover',
      uses: ['Poster-V-Logo', 'Poster-V'],
      width: 600,
      height: 800,
      channelLogo: [{
         coverage: 3.5,
         x: 'center',
         y: 24,
         xr: false,
         yr: false,
      }],
   }, {
      service: 'Sky',
      id: 'imageF',
      name: 'Background',
      uses: ['Hero'],
      width: 1920,
      height: 1080,
      safeArea: 'safeArea-imageF.png',
   }, {
      service: 'Sky',
      id: 'imageG',
      name: 'Scene',
      episodic: true,
      uses: ['Poster-O', 'Scena'],
      width: 1920,
      height: 1080,
      safeArea: 'safeArea-imageG.png',
   },
   // {
   // service: 'Sky',
   // id: 'imageH',
   // name: 'Now Land 16:9',
   // episodic: true,
   // flags: 'now,kids',
   // uses: ['Poster-O', 'Scena'],
   // width: 1920,
   // height: 1080,
   // safeArea: 'safeArea-imageH.png',
   // },
   {
      service: 'Sky',
      id: 'imageI',
      name: 'Now Land 4:3',
      episodic: true,
      flags: 'now',
      uses: ['Poster-O', 'Scena'],
      width: 1024,
      height: 730,
      safeArea: 'safeArea-imageI.png',
   }, {
      service: 'Sky',
      id: 'imageJ',
      name: 'Now Boxart',
      flags: 'now,fusion',
      uses: ['Poster-V-Logo', 'Poster-V'],
      width: 1080,
      height: 1600,
      channelLogo: [{
         coverage: 4,
         x: 'center',
         y: 48,
         xr: false,
         yr: false,
      }],
   }, {
      service: 'Sky',
      id: 'imageK',
      name: 'Vodafone Background',
      // 13/10/2022 tolta episodica
      episodic: false,
      flags: 'now',
      // uses: ['Scena', 'Poster-O', 'Poster-O-Logo'],
      uses: ['Hero'],
      width: 1280,
      height: 752,
      channelLogo: [{
         coverage: 1.45,
         x: 90,
         y: 90,
         xr: true,
         yr: true,
      }],
   }, {
      service: 'Sky',
      id: 'imageL',
      name: 'Vodafone Cover',
      flags: 'now',
      uses: ['Poster-V-Logo', 'Poster-V'],
      width: 450,
      height: 670,
      channelLogo: [{
         coverage: 1.4,
         x: 'center',
         y: 20,
         xr: false,
         yr: true,
      },
      {
         coverage: 1.4,
         x: 'center',
         y: 20,
         xr: false,
         yr: false,
      }],
   }, {
      service: 'Sky',
      id: 'imageM',
      name: 'TIM Hor. Cover',
      flags: 'TimCatchup',
      uses: ['Poster-O-Logo', 'Poster-O'],
      width: 800,
      height: 600,
   }, {
      service: 'Sky',
      id: 'imageN',
      name: 'Cover Clean',
      uses: ['Poster-V'],
      width: 600,
      height: 800,
   }, {
      service: 'Sky',
      id: 'imageO',
      name: 'Scene Key Art',
      uses: ['Poster-O-Logo'],
      width: 1920,
      height: 1080,
   }, {
      service: 'Sky',
      id: 'imageP',
      name: 'Logo',
      uses: ['Logo'],
      width: 2880,
      height: 1620,
   }, {
      service: 'Sky',
      id: 'imageQ',
      name: 'Boxset',
      uses: ['Poster-O-Logo-219', 'Poster-O-Logo'],
      width: 188,
      height: 71,
   }, {
      service: 'Sky Vod',
      id: 'imageVA',
      name: 'Vod A. Locandina',
      uses: ['Poster-V-Logo'],
      width: 360,
      height: 532,
      channelLogo: [{
         coverage: 1.45,
         x: 'center',
         y: 25,
         xr: false,
         yr: true,
      }],
   }, {
      service: 'Sky Vod',
      id: 'imageVB',
      name: 'Vod B. Landscape 3',
      episodic: true,
      uses: ['Scena', 'Poster-O', 'Poster-O-Logo'],
      width: 962,
      height: 542,
   }, {
      service: 'Sky Vod',
      id: 'imageVG',
      name: 'Vod Tombolino',
      uses: ['Poster-O-Logo'],
      width: 460,
      height: 260,
      episodeNumber: true,
      programLogo: true,
      channelLogo: [{
         coverage: 1.45,
         x: 20,
         y: 20,
         xr: true,
         yr: true,
      }],
   }, {
      service: 'Sky Vod',
      id: 'imageVL',
      name: 'Vod STB',
      episodic: true,
      uses: ['Scena', 'Poster-O', 'Poster-O-Logo'],
      width: 198,
      height: 109,
      episodeNumber: true,
   }, {
      service: 'Sky Primafila',
      id: 'imagePA',
      name: 'Primafila Big',
      uses: ['Poster-O-Logo'],
      width: 720,
      height: 407,
      programLogo: true,
   }, {
      service: 'Sky Primafila',
      id: 'imagePB',
      name: 'Primafila Small',
      uses: ['Poster-O-Logo'],
      width: 198,
      height: 109,
      programLogo: true,
   }, {
      service: 'Sky Primafila',
      id: 'imagePC',
      name: 'Primafila Scena',
      uses: ['Scena', 'Poster-O', 'Poster-O-Logo'],
      width: 1280,
      height: 704,
   }, {
      service: 'Caccia',
      id: 'imageCT',
      name: 'Poster O AMZ TV',
      uses: ['Poster-O-Logo'],
      width: 1600,
      height: 1200,
   }, {
      service: 'Caccia',
      id: 'imageCU',
      name: 'Poster V AMZ Movie',
      uses: ['Poster-V-Logo'],
      width: 1200,
      height: 1600,
   }, {
      service: 'Caccia',
      id: 'imageCV',
      name: 'Scena V AMZ TV',
      uses: ['Scena'],
      width: 1600,
      height: 1200,
   }];

   static getFormatsToPrint = function (progData, imgRichieste) {
      const allFormats = this.formats;
      if (progData == 'all') return allFormats;

      // formati
      let formati = allFormats.filter((f) => ['Sky', 'Timvision'].includes(f.service));
      const nuoviFormati = [];

      // req
      const req = progData && progData.request ? progData.request : progData ? progData : null;

      // Specifiche
      let tipo, channelGroupName, extraImageFlag,
         flagNow, flagFusion, flagTimCatchup, flagCaccia;

      if (req) {
         tipo = req._tr ? req._tr : req.TipoRichiesta;
         channelGroupName = req.channelGroupName;
         extraImageFlag = req.extraImageFlag;
         flagNow = req.ethanMetadatasets.match(/now/gi);
         flagFusion = req.ethanMetadatasets.match(/fusion/gi);
         flagTimCatchup = req.ethanMetadatasets.match(/TimCatchup/gi);
         flagCaccia = req.ethanMetadatasets.match(/Caccia/gi);
      }

      // Se non è now tolgo le immagini now
      if (!flagTimCatchup) formati = formati.filter((f) => !f.flags || !f.flags.match(/TimCatchup/gi));
      if (!flagNow) formati = formati.filter((f) => !f.flags || !f.flags.match(/now/gi));
      if (flagFusion) formati = formati.concat(allFormats.filter((f) => f.flags && f.flags.match(/fusion/gi)));

      // Formati da channelGroupName
      if (channelGroupName) {
         const cgNames = req.channelGroupName.split(',');
         cgNames.forEach((cgn) => {
            if (cgn.match(/dyn_img_/gi)) {
               nuoviFormati.push('imageJ_' + cgn);

            } else {
               nuoviFormati.push('imageE_' + cgn);
               nuoviFormati.push('imageF_' + cgn);
               nuoviFormati.push('imageG_' + cgn);
               if (flagFusion) nuoviFormati.push('imageJ_' + cgn);

            }
         });
      }

      // Creo i nuovi formati
      if (nuoviFormati.length > 0) {
         nuoviFormati.forEach((nf) => {
            const formatId = nf.split('_')[0];
            const channelGroup = nf.split('_').splice(1).join('_');
            const baseFormat = allFormats.find((f) => f.id == formatId);
            const newFormat = $.extend({}, baseFormat);

            newFormat.id = nf;
            newFormat.channelGroup = channelGroup;

            if (channelGroup.match(/dyn_img_/gi)) {
               newFormat.name = channelGroup.replace(/dyn_img_/g, 'Alt. ');
               newFormat.service = newFormat.service + ' Alternative Images';
               newFormat.flags = channelGroup.replace(/dyn_img_/g, 'ALT IMG ') + (newFormat.flags ? ',' + newFormat.flags : '');
               newFormat.uses = [channelGroup.replace(/dyn_img_/g, 'Poster-V-Logo-AL')];

            } else {
               newFormat.name = newFormat.name + ' ' + channelGroup.replace(/_/g, ' ').trim().toUpperCase();
               newFormat.service = newFormat.service + ' ' + channelGroup.replace(/[0-9]/g, ' ').toUpperCase();
               newFormat.flags = channelGroup + (newFormat.flags ? ',' + newFormat.flags : '');

            }

            // console.log('nuovo formato', newFormat)
            formati.push(newFormat);
         });
      }

      // Formati peacock
      if (extraImageFlag) {
         let formatsToAdd = allFormats.filter(f => ['imageK', 'imageL'].includes(f.id));
         formatsToAdd.forEach(f => {
            let fc = JSON.parse(JSON.stringify(f));
            fc.id = fc.id == 'imageK' ? 'imageT' : 'imageU';
            fc.name = fc.name.replace('Vodafone', 'Peacock');
            fc.service = 'Peacock';
            formati.push(fc);
         })
      }

      // Se episodio filtro solo i formati necessari
      if (tipo == 'EP') {
         if (imgRichieste) {
            if (imgRichieste == 'O') {
               formati = formati.filter((f) => f.episodic);
            }
            else if (imgRichieste == 'H-O') {
               formati = formati.filter((f) => f.episodic || /^imageF/gi.test(f.id));
            }
            else if (imgRichieste == 'V-O') {
               formati = formati.filter((f) => f.episodic || /^(imageE|imageJ)/gi.test(f.id));
            }
            else if (imgRichieste == 'V-H-O') {
               formati = formati.filter((f) => f.episodic || /^(imageF|imageE|imageJ)/gi.test(f.id));
            }
            else if (imgRichieste == 'No Episodiche') {
               formati = [];
            }
         }
         else {
            formati = formati.filter((f) => f.episodic);
         }
      }

      // Extra Formati caccia
      if (flagCaccia) {
         let formatsToAdd = [];

         if (tipo == 'EP') {
            formatsToAdd = allFormats.filter(f => ['imageCV'].includes(f.id));

         } else if (tipo == 'SS') {
            formatsToAdd = allFormats.filter(f => ['imageCT'].includes(f.id));

         } else {
            formatsToAdd = allFormats.filter(f => ['imageCU'].includes(f.id));

         }

         formatsToAdd.forEach(f => {
            let fc = JSON.parse(JSON.stringify(f));
            fc.id = fc.id == 'imageCT' ? 'imageT' : fc.id == 'imageCV' ? 'imageV' : 'imageU';
            formati.push(fc);
         })

      }

      // Ordino i formati per servizio
      // formati.sort((a, b) => a.service.localeCompare(b.service));

      return formati;
   }

   static printFormats = async function ($dest, formatsToPrint, tipo, progData) {

      const $container = $(`
         <div class="imgCanvasContainer ui segments" style="margin:20px"></div>
      `).insertAfter($dest);

      $dest.remove();


      for (const f of formatsToPrint) {
         const service = f.service.replace(/[^A-Z0-9]/gi, '_');
         let $serviceArea = $container.find(`.${service}_area`);

         if (!$serviceArea.length)
            $serviceArea = $(`
               <div class="${service}_area ui segment">
                  <div class="ui header">${f.service}</div>
               </div>`).appendTo($container);

         // Chiama se stesso per creare i canvas
         let can = await new this({
            dest: $serviceArea[0],
            format: f,
            class: 'ProvaImgCanvas',
            viewScaleHeight: 140,
            image: 'https://tools.datatv.it/content-imgs/ir721888_imageG_se-1_pr271558_ep-1_tt11138512.jpg',
            logo: 'https://tools.datatv.it/content-imgs/ir721888_imageP_se-1_pr271558_ep-1_tt11138512.jpg?nocache=1667905521604',
            progData: null,
         });


      };
   }



   ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// MAIN
   constructor(options) {
      const scope = this;

      // Opzioni
      this.options = options || {};
      this.history = [{ op: 'created', params: options }];
      this.state = 'empty';
      this.format = options.format;

      // Wrapper
      this.wrapper = document.createElement('div');
      this.wrapper.classList.add(...['wrapper', 'imgCanvas', this.format.id, `ts_${new Date().getTime()}`, this.options.class || 'noClass']);
      this.wrapper.draggable = true;
      if (this.options.dest) this.options.dest.appendChild(this.wrapper);
      else document.body.appendChild(this.wrapper);

      this.wrapper.innerHTML = `

         <div class="topLabels">
            <div class="source" title="Ultima Sorgente: ?">?</div>
            <div class="zoom">
               <i class="minus square fitted link icon"></i>
               <a href="#" class="perc" title="Doppio Click: Default Zoom">00.0%</a>
               <i class="plus square fitted link icon"></i>
            </div>
            <div class="hideMenu">
               <i class="eye outline fitted link icon"></i>
            </div>
         </div>

         <div class="state">?</div>
         <div class="header" title="Doppio Click: Editor Esteso">
            <div class="title">${this.format.name}</div>
            <div class="info">${this.format.id.replace('image', '')}. ${this.format.width}x${this.format.height}</div>
         </div>
         <div class="zoomArea">
            <div class="stage">
               <canvas class="inactive" width="${this.format.width}" height="${this.format.height}"></canvas>
            </div>
         </div>


         <input class="" type="file" accept="image/*" style="display: none;">
      `;

      // associo l'istanza creata all'oggetto html
      this.wrapper.imgCanvas = this;

      // Riferimenti
      this.stage = this.wrapper.querySelector('.stage');
      this.inactive = this.wrapper.querySelector('.inactive');
      this.header = this.wrapper.querySelector('.header');
      this.input = this.wrapper.getElementsByTagName('input')[0];

      // Init
      this.scaleUnit = Math.min(scope.format.width, scope.format.height) / 100;
      this.eventsAdd();
      this.viewScaleSet(this.options.viewScale, this.options.viewScaleWidth, this.options.viewScaleHeight || 200, 100);

      // crea la scena per il canvas e se serve il renderer generico
      this.renderer = this.rendererCreate();



      // Aggiungo i Layers necessari
      return addLayers();

      async function addLayers() {
         if (scope.options.image) await scope.layers.add('image', 'Background', scope.options.image);
         // if (scope.options.logo) await scope.layers.add('image', 'Program Logo', scope.options.logo);

         await scope.layers.add('text', 'Program Title', { text: 'Titolo Programma' });

         // if (scope.format.safeArea) await scope.layers.add('image', 'safeArea', `https://tools.datatv.it/datatv/myCanvas/${scope.format.safeArea}`);
      }


   }

   rendererCreate() {
      let renderer = this.constructor.renderer;

      if (!renderer) {
         // Creo un canvas comune
         const newCanvas = document.createElement('canvas');
         newCanvas.id = 'canvas3d';
         newCanvas.classList.add('canvas3d');
         newCanvas.style.width = '100%';
         newCanvas.style.height = '100%';
         document.body.appendChild(newCanvas);

         // Creo il renderer per tutti i canvas
         renderer = new THREE.WebGLRenderer({ canvas: newCanvas, alpha: true });
         this.constructor.renderer = renderer;
      }

      const scene = new THREE.Scene();
      const camera = new THREE.OrthographicCamera(this.format.width / - 2, this.format.width / 2, this.format.height / 2, this.format.height / - 2, .1, 10000);
      camera.position.z = 5000;


      // put the camera on a pole (parent it to an object)
      // so we can spin the pole to move the camera around the scene
      const cameraPole = new THREE.Object3D();
      scene.add(cameraPole);
      cameraPole.add(camera);
      cameraPole.name = 'camera';

      // cameraPole.rotation.x = this.degreeToRadiant(45);
      // cameraPole.position.z = this.format.height / 4;

      // const camera = new THREE.PerspectiveCamera(60, this.viewScale.w / this.viewScale.h, .1, 10000);
      // camera.position.z = 5000;

      // // put the camera on a pole (parent it to an object)
      // // so we can spin the pole to move the camera around the scene
      // const cameraPole = new THREE.Object3D();
      // scene.add(cameraPole);
      // cameraPole.add(camera);
      // cameraPole.rotation.x = this.degreeToRadiant(45);

      this.sceneInfo = { scene, camera, cameraPole };
      return renderer;
   }

   rendererUpdate() {

      const renderer = this.renderer;
      const scene = this.sceneInfo.scene;
      const camera = this.sceneInfo.camera;

      const canvas = renderer.domElement;
      const prev = document.getElementById('canvas3d').closest('.imgCanvas') ?
         document.getElementById('canvas3d').closest('.imgCanvas').imgCanvas : null;

      // console.log(`${this.format.id}: Update Render, Precedente: ${prev ? prev.imgCanvas.format.id : 'Nessuno'}`);

      if (!prev) {
         // La prima volta appendo il canvas all'immagine
         this.stage.appendChild(canvas);

      } else if (prev && prev.wrapper != this.wrapper) {
         // Aggiorno l'immagine precedente e
         // Svuoto l'immagine corrente e
         // Sposto il canvas 3d sull'immagine corrente

         // renderer.setSize(prev.format.width, prev.format.height, false);

         renderer.render(prev.sceneInfo.scene, prev.sceneInfo.camera);
         prev.inactive.getContext('2d').drawImage(canvas, 0, 0, prev.format.width, prev.format.height);
         this.inactive.getContext('2d').clearRect(0, 0, this.format.width, this.format.height);
         this.stage.appendChild(canvas);
      }

      // Resize if needed
      const pixelRatio = window.devicePixelRatio;
      const width = canvas.clientWidth * pixelRatio | 0;
      const height = canvas.clientHeight * pixelRatio | 0;
      const needResize = canvas.width !== width || canvas.height !== height;
      if (needResize) {
         renderer.setSize(width, height, false);
      }

      // UPDATE
      renderer.render(scene, camera);

      return;




      // // UPDATE
      // function render(time) {
      //    time *= 0.001;  // convert time to seconds

      //    scene.children[0].rotation.x = time;
      //    scene.children[0].rotation.y = time;


      //    renderer.render(scene, camera);

      //    // requestAnimationFrame(render);
      // }

      // requestAnimationFrame(render);
      return needResize;
   }

   renderGetVisibleWidth(depth) {
      if (!depth) depth = 0;
      const camera = this.sceneInfo.camera;

      const height = this.renderGetVisibleHeight(depth, camera);
      return height * camera.aspect;
   }

   renderGetVisibleHeight(depth) {
      if (!depth) depth = 0;
      const camera = this.sceneInfo.camera;

      // compensate for cameras not positioned at z=0
      const cameraOffset = camera.position.z;
      if (depth < cameraOffset) depth -= cameraOffset;
      else depth += cameraOffset;

      // vertical fov in radians
      const vFOV = camera.fov * Math.PI / 180;

      // Math.abs to ensure the result is always positive
      return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
   }

   degreeToRadiant(degree) {
      return degree * 0.01745329252;
   }

   eventsAdd() {

      this.header.addEventListener('mouseover', (ev) => {
         ev.preventDefault();
         ev.stopPropagation();
         this.topMenuVis(true);
         this.rendererUpdate();

      });

      this.wrapper.addEventListener('mouseleave', (ev) => {
         ev.preventDefault();
         ev.stopPropagation();
         this.topMenuVis(false);

      });

      this.header.addEventListener('dblclick', (ev) => {
         ev.preventDefault();
         ev.stopPropagation();
         this.fullscreen();

      });

      // ZOOM
      this.wrapper.querySelector('.topLabels .minus').addEventListener('click', (ev) => {
         let newScale = Math.round(this.viewScale.s / 5) * 5 - 5;
         this.viewScaleSet(newScale);

      });
      this.wrapper.querySelector('.topLabels .perc').addEventListener('dblclick', (ev) => {
         if (this.wrapper.classList.contains('fullscreen')) {
            this.viewScaleSet(false, false, false, 'parent');
         } else {
            this.viewScaleSet(this.options.viewScale, this.options.viewScaleWidth, this.options.viewScaleHeight || 200, 100);
         }
      });
      this.wrapper.querySelector('.topLabels .plus').addEventListener('click', (ev) => {
         let newScale = Math.round(this.viewScale.s / 5) * 5 + 5;
         this.viewScaleSet(newScale);

      });

      // HIDE MENU
      this.wrapper.querySelector('.topLabels .hideMenu').addEventListener('click', (ev) => {
         if (this.wrapper.classList.contains('withMenu')) {
            this.wrapper.classList.remove('withMenu');
         } else {
            this.wrapper.classList.add('withMenu');
         }


      });

   }

   viewScaleSet(scale, width, height, max) {

      let p = this.format.width / this.format.height;
      let s, w, h;

      // -4 per i bordi
      let cw = this.wrapper.offsetWidth - 4;
      let ch = this.wrapper.offsetHeight - this.header.offsetHeight - 4;

      if (max == 'parent') {

         let cs = Math.min(cw / this.format.width, ch / this.format.height) * 100;
         scale = cs <= 100 ? cs : 100;

         console.log(cw, ch, 'final scale', scale)
      }

      if (scale) {
         w = this.format.width / 100 * scale;
         h = this.format.height / 100 * scale;

      } else if (width) {
         w = width;
         h = width / p;

      } else if (height) {
         w = height * p;
         h = height;

      }

      if (w && h) {

         if (max == 100 && w > this.format.width) {
            w = this.format.width;
            h = this.format.height;

         } else if (w < 40 || h < 40) {
            return;

         }

         s = w / this.format.width * 100;
         this.viewScale = {
            s: s,
            w: w,
            h: h,
         }

         this.stage.style.height = Math.floor(h) + 'px';
         this.stage.style.width = Math.floor(w) + 'px';

         // Se fullscreen la centro
         if (this.wrapper.classList.contains('fullscreen'))
            this.stage.style.marginTop = Math.round((this.wrapper.offsetHeight - this.header.offsetHeight) / 2 - h / 2 - 2) + 'px';
         else this.stage.style.marginTop = 0 + 'px';

         this.wrapper.querySelector('.perc').textContent = `${Math.round(s * 10) / 10}%`;
         if (this.renderer) this.rendererUpdate()
      }
   }

   topMenuVis(show) {
      let topMenu = this.wrapper.querySelector('.topMenu');
      let bottomInfo = this.wrapper.querySelector('.bottomInfo');
      let isFullScreen = this.wrapper.classList.contains('fullscreen');

      if (show && topMenu)
         return;

      else if (!show) {
         if (!isFullScreen) {
            this.wrapper.classList.remove('withMenu');
            if (topMenu) topMenu.remove();
            if (bottomInfo) bottomInfo.remove();

         }
         return;
      }

      const fragment = document.createDocumentFragment();

      // Top Menu
      topMenu = document.createElement('div');
      topMenu.classList.add('topMenu');
      topMenu.innerHTML = `
            <div class="ui small four item icon menu">
               <a class="item del"><i class="trash alternate outline red icon"></i></a>
               <div class="ui floating dropdown item">
                  <i class="folder open icon"></i>
                  <div class="ui menu">
                     <div class="item" data-value="locale"><i class="hdd icon"></i>Apri Immagine Locale</div>
                     <div class="item" data-value="modalimagechooser"><i class="search icon"></i>Cerca Immagine</div>
                  </div>
               </div>
               <div class="ui floating dropdown item">
                  <i class="download icon"></i>
                  <div class="ui menu">
                     <div class="item" data-value="downJpg"><i class="download icon"></i>Scarica JPG</div>
                     <div class="item" data-value="downPng"><i class="download icon"></i>Scarica PNG (Lossless)</div>
                     <div class="item" data-value="downLayers"><i class="download icon"></i>Scarica Livelli PNG (Lossless)</div>
                  </div>
               </div>
               <a class="item"><i class="magic icon"></i></a>
            </div>`;

      // Bottom Info
      bottomInfo = document.createElement('div');
      bottomInfo.classList.add('bottomInfo');
      bottomInfo.innerHTML = `
         INFO
      `;

      // Appendo tutto insieme
      fragment.appendChild(topMenu);
      fragment.appendChild(bottomInfo);
      this.wrapper.appendChild(fragment);
      this.wrapper.classList.add('withMenu');
   }

   sideMenuVis(show) {
      const scope = this;
      let sideMenu = this.wrapper.querySelector('.sideMenu');
      let isFullScreen = this.wrapper.classList.contains('fullscreen');

      if (show && sideMenu)
         return;

      else if (!show) {
         if (!isFullScreen) {
            if (sideMenu) sideMenu.remove();

         }
         return;
      }

      const fragment = document.createDocumentFragment();

      sideMenu = document.createElement('div');
      sideMenu.classList.add('sideMenu');
      sideMenu.innerHTML = `
         <div class="ui small four item icon menu">
            <a class="item del"><i class="trash alternate outline red icon"></i></a>
            <div class="ui floating dropdown item">
               <i class="folder open icon"></i>
               <div class="ui menu">
                  <div class="item" data-value="locale"><i class="hdd icon"></i>Apri Immagine Locale</div>
                  <div class="item" data-value="modalimagechooser"><i class="search icon"></i>Cerca Immagine</div>
               </div>
            </div>
            <div class="ui floating dropdown item">
               <i class="download icon"></i>
               <div class="ui menu">
                  <div class="item" data-value="downJpg"><i class="download icon"></i>Scarica JPG</div>
                  <div class="item" data-value="downPng"><i class="download icon"></i>Scarica PNG (Lossless)</div>
                  <div class="item" data-value="downLayers"><i class="download icon"></i>Scarica Livelli PNG (Lossless)</div>
               </div>
            </div>
            <a class="item"><i class="magic icon"></i></a>
         </div>

         <div class="layerContainer"></div>`;

      const layerContainer = sideMenu.querySelector('.layerContainer');
      const layers = scope.sceneInfo.scene.children.sort((a, b) => a.position.z - b.position.z);

      layers.forEach(lay => {
         if (!lay._controlsO) return;

         // I controlli Html
         lay._controls = controlsHtml(lay);
         layerContainer.appendChild(lay._controls);

         // Aggiungo gli eventi
         controlsEvents(lay);

      });

      // Appendo tutto insieme
      fragment.appendChild(sideMenu);
      this.wrapper.appendChild(fragment);


      function controlsHtml(lay) {
         const cobj = lay._controlsO;
         if (!cobj) return;

         // Il Layer
         const lControl = document.createElement('div');
         lControl.classList.add('imgLayer', cobj.tipo);
         lControl.setAttribute('name', lay.name);

         // Il contenuto del layer
         lControl.innerHTML = `
            <div class="showicon"><i class="fitted ${cobj.icon} icon"></i></div>
            <div class="name" draggable="true">${lay.name}</div>
            <div class="editicon"><i class="fitted pencil icon"></i></div>

            ${cobj.buttons ? `
               <div class="buttonsMenu subLay">
                  <div class="ui fluid small icon buttons">
                     ${cobj.buttons.map((b, i) => `
                        <button ${b.title ? `title="${b.title}"` : ''} class="ui button lbutton" data-co="${i}"><i class="${b.icon} icon"></i></button>
                     `).join('')}
                  </div>
               </div>`: ''}

            ${cobj.fields ? `
               <form class="ui mini form subLay">
                  ${cobj.fields.map((f, i) => {
            let html = '';
            if (f.type == 'header') {
               html = `<div class="ui tiny header">${f.label}</div>`;

            } else if (f.type == 'anchor') {
               html = `
                           <div class="anchor">
                              <div class="tl ${!lay._anchor || lay._anchor == 'tl' ? 'selected' : ''}"></div>
                              <div class="tc ${lay._anchor == 'tc' ? 'selected' : ''}"></div>
                              <div class="tr ${lay._anchor == 'tr' ? 'selected' : ''}"></div>

                              <div class="cl ${lay._anchor == 'cl' ? 'selected' : ''}"></div>
                              <div class="cc ${lay._anchor == 'cc' ? 'selected' : ''}"></div>
                              <div class="cr ${lay._anchor == 'cr' ? 'selected' : ''}"></div>

                              <div class="bl ${lay._anchor == 'bl' ? 'selected' : ''}"></div>
                              <div class="bc ${lay._anchor == 'bc' ? 'selected' : ''}"></div>
                              <div class="br ${lay._anchor == 'br' ? 'selected' : ''}"></div>
                           </div>`;

            } else if (f.type == 'textarea') {
               html = `
                           <div class="field">
                              <div class="ui red small slider"></div>
                              <textarea data-co="${i}">${f.start(lay)}</textarea>
                           </div>`;

            } else if (f.type == 'dropdown') {
               html = ``;

            } else {
               html = `
                           <div class="field ${!f.long ? 'half' : ''}">
                              ${f.step ? '<div class="ui grey slider"></div>' : ''}
                              <div class="ui fluid labeled input">
                                 <div class="ui basic label">${f.icon ? `<i class="fitted ${f.icon} icon"></i>` : f.label}</div>
                                 <input type="${/color/gi.test(f.label) ? 'color' : 'number'}" name="${f.label}" value="${f.start(lay)}" data-co="${i}">
                              </div>
                              ${f.label == 'h' ? `<i class="grey linkify icon scaleLink"></i>` : ''}
                           </div>`;

            }

            return html;

         }).join('')}
               </form>
            `: ''}
         `;

         // return
         return lControl;
      }

      function controlsEvents(lay) {
         const cobj = lay._controlsO;
         const chtm = lay._controls;
         if (!cobj) return;

         // active (solo uno alla volta)
         chtm.addEventListener('click', (ev) => {
            document.querySelectorAll('.imgLayer')
               .forEach(ll => ll.classList.remove('active'));

            chtm.classList.add('active');
         });

         // show hide
         chtm.querySelector('.showicon').addEventListener('click', (ev) => {
            if (lay.visible) {
               lay.visible = false;
               scope.rendererUpdate();
               chtm.classList.add('invisible');

            } else {
               lay.visible = true;
               scope.rendererUpdate();
               chtm.classList.remove('invisible');

            }
         });

         // edit
         chtm.querySelector('.editicon').addEventListener('click', (ev) => {
            let wasOpen = chtm.querySelector('.subLay').offsetParent;

            document.querySelectorAll('.subLay')
               .forEach(el => el.style.display = 'none');

            if (!wasOpen)
               chtm.querySelectorAll('.subLay')
                  .forEach(el => el.style.display = 'block');

         });

         // Bottoni
         chtm.querySelectorAll('.lbutton').forEach(bu => {
            bu.addEventListener('click', (ev) => {
               chtm.querySelectorAll('.lbutton')
                  .forEach(el => el.classList.remove('active'));

               bu.classList.add('active');
               scope.layers.handleInterface(lay.name, ev);

            });
         });

         // Anchor
         chtm.querySelectorAll('.anchor > div').forEach(di => {
            di.addEventListener('click', (ev) => {
               chtm.querySelectorAll('.anchor > div')
                  .forEach(el => el.classList.remove('selected'));

               di.classList.add('selected');

               const anchor = Array.from(di.classList).find(c => c != 'selected');
               lay._anchor = anchor;
               scope.layers.handleInterface(lay.name, ev);

            });
         });

         // Gli input
         chtm.querySelectorAll('input, textarea').forEach(ip => {
            ip.addEventListener('change', (ev) => scope.layers.handleInterface(lay.name, ev));
            ip.addEventListener('keyup', (ev) => scope.layers.handleInterface(lay.name, ev));

            // Aggiungo gli slider
            const mO = cobj.fields[ip.dataset.co];
            if (mO.step) {

               $(function () {
                  const $slider = $(ip.closest('.field').querySelector('.slider'));
                  $slider.slider({
                     min: mO.min(lay),
                     max: mO.max(lay),
                     start: mO.start(lay),
                     step: mO.step(lay),
                     onMove: function (val) {
                        ip.value = val;
                        ip.dispatchEvent(new Event('change'));
                     }
                  });
               });
            }

         });

         // scaleLink
         const scaleLink = chtm.querySelector('.scaleLink');
         if(scaleLink)
            scaleLink.addEventListener('click', (ev) => {
               if (scaleLink.classList.contains('linkify')) {
                  lay._scaleLink = false;
                  scaleLink.classList.remove('linkify');
                  scaleLink.classList.add('unlink');

               } else {
                  lay._scaleLink = true;
                  scaleLink.classList.add('linkify');
                  scaleLink.classList.remove('unlink');
               }
            });
      }


   }

   fullscreen() {

      if (this.wrapper.classList.contains('fullscreen')) {

         this.wrapper.draggable = true;
         this.wrapper.classList.remove('fullscreen');
         this.topMenuVis(false);
         this.sideMenuVis(false);

         this.viewScaleSet(this.options.viewScale, this.options.viewScaleWidth, this.options.viewScaleHeight || 200, 100);
         document.body.style.overflow = 'auto';
         return;
      }

      this.wrapper.draggable = false;
      this.wrapper.classList.add('fullscreen');
      this.topMenuVis(true);
      this.sideMenuVis(true);

      document.body.style.overflow = 'hidden';
      this.viewScaleSet(false, false, false, 'parent');
   }

   copy() {

   }

   paste() {

   }

   clear() {

   }

   ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// IMAGES
   async imageLoad(url, serverUrl) {
      //https://tools.datatv.it/datatv/myCanvas/safeArea-imageF.png

      // Se ho già caricato l'immagine la restituisco
      let alreadyLoaded = this.constructor.imagesLoaded.find(i => i.url == url);
      if (alreadyLoaded) return alreadyLoaded;

      // Carico l'immagine
      var img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = serverUrl ? serverUrl : url;

      return new Promise(async (resolve, reject) => {
         img.onload = () => {

            // Salvo l'immagine caricata nella classe
            this.constructor.imagesLoaded.push({ url: url, serverUrl: serverUrl, img: img });
            return resolve(img);
         }

         img.onerror = () => {

            // se ho già provato a caricarla sul server do errore
            if (serverUrl)
               return reject(`Impossibile caricare: ${url}`);

            // Se ha dato errore provo a caricarla sul server
            return serverPreload(url)
               .then(r => {
                  if (!r || !r.length)
                     return reject(`Impossibile caricare: ${url} sul Server`);

                  serverUrl = `${mySite}/${r[0].folder}/${r[0].name}`;
                  return this.imageLoad(url, serverUrl);
               });

         }
      });

      async function serverPreload(eurl) {
         if (typeof myAuth == 'undefined' || !myAuth.getCookie('tools.datatv.it'))
            throw new Error('jjjjjjjjjjjjjjjjjjjjj')


         const formD = new FormData();
         formD.append('urls', JSON.stringify([eurl]));
         formD.append('folderName', 'tempUploads');


         // const formData = new FormData();

         // for (const name in data) {
         //    formData.append(name, data[name]);
         // }

         // const response = await fetch(url, {
         //    method: 'POST',
         //    body: formData
         // });

         return $.ajax({
            type: 'POST',
            url: `${mySite}/uploads`,
            beforeSend: function (xhr) {
               xhr.setRequestHeader('Authorization', 'Bearer ' + myAuth.getCookie('tools.datatv.it'));
            },
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formD,
         }).catch((e) => {
            console.log(e);
         });
      }
   }

   ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////// LAYERS
   async loadTexture(url, serverUrl) {
      const loader = new THREE.TextureLoader();
      const map = await loader.loadAsync(url);

      // console.log(map)
      // console.log(map.source.data)

      return map;
   }

   layers = {
      controlsO: {
         image: {
            tipo: 'image',
            icon: 'image',
            buttons: [
               {
                  icon: 'folder open',
                  action: (lay) => alert('Da Fare'),
               },
               {
                  icon: 'search center',
                  action: (lay) => alert('Da Fare'),
               },
               {
                  icon: 'expand',
                  action: (lay) => this.layers.resize(lay.name, 'ori'),
                  title: `Immagine alla dimensione originale`
               },
               {
                  icon: 'expand arrows alternate',
                  action: (lay) => this.layers.resize(lay.name, 'max'),
                  title: `Copri il Box con l'immagine`
               },
               {
                  icon: 'compress arrows alternate',
                  action: (lay) => this.layers.resize(lay.name, 'fit'),
                  title: `Adatta l'immagine al Box`
               },
            ],
            fields: [
               {
                  type: 'header',
                  label: 'Posizione & Dimensioni',
               },
               {
                  type: 'anchor',
                  start: 'tl',
               },
               {
                  label: 'x',
                  action: (lay, v) => this.layers.move(lay.name, v),
                  start: (lay) => this.layers.move(lay.name).displayx,
                  min: (lay) => - this.format.width * 2,
                  max: (lay) => this.format.width * 2,
                  step: (lay) => 1,
               },
               {
                  label: 'y',
                  action: (lay, v) => this.layers.move(lay.name, null, v),
                  start: (lay) => this.layers.move(lay.name).displayy,
                  min: (lay) => - this.format.height * 2,
                  max: (lay) => this.format.height * 2,
                  step: (lay) => 1,
               },
               {
                  label: 'w',
                  action: (lay, v) => this.layers.resize(lay.name, v), //lay.scale.x = (v / lay.geometry.parameters.width),
                  start: (lay) => lay.geometry.parameters.width * lay.scale.x,
                  min: (lay) => 0,
                  max: (lay) => lay.geometry.parameters.width * 2,
                  step: (lay) => 1,
               },
               {
                  label: 'h',
                  action: (lay, v) => this.layers.resize(lay.name, null, v), //lay.scale.y = (v / lay.geometry.parameters.height),
                  start: (lay) => lay.geometry.parameters.height * lay.scale.y,
                  min: (lay) => 0,
                  max: (lay) => lay.geometry.parameters.height * 2,
                  step: (lay) => 1,
               }
            ]
         },
         text: {
            tipo: 'text',
            icon: 'font',
            buttons: [
               {
                  icon: 'align left',
                  action: (lay) => lay.textAlign = 'left',
               },
               {
                  icon: 'align center',
                  action: (lay) => lay.textAlign = 'center',
               },
               {
                  icon: 'align right',
                  action: (lay) => lay.textAlign = 'right',
               },
               {
                  icon: 'align justify',
                  action: (lay) => lay.textAlign = 'justify',
               },
            ],
            fields: [
               {
                  type: 'header',
                  label: 'Posizione & Dimensioni',
               },
               {
                  type: 'anchor',
                  start: 'tl',
               },
               {
                  label: 'x',
                  action: (lay, v) => this.layers.move(lay.name, v),
                  start: (lay) => this.layers.move(lay.name).displayx,
                  min: (lay) => - this.format.width * 2,
                  max: (lay) => this.format.width * 2,
                  step: (lay) => 1,
               },
               {
                  label: 'y',
                  action: (lay, v) => this.layers.move(lay.name, null, v),
                  start: (lay) => this.layers.move(lay.name).displayy,
                  min: (lay) => - this.format.height * 2,
                  max: (lay) => this.format.height * 2,
                  step: (lay) => 1,
               },
               {
                  label: 'w',
                  action: (lay, v) => this.layers.resize(lay.name, v), //lay.scale.x = (v / lay.geometry.parameters.width),
                  start: (lay) => lay.geometry.parameters.width * lay.scale.x,
                  min: (lay) => 0,
                  max: (lay) => lay.geometry.parameters.width * 2,
                  step: (lay) => 1,
               },
               {
                  label: 'h',
                  action: (lay, v) => this.layers.resize(lay.name, null, v), //lay.scale.y = (v / lay.geometry.parameters.height),
                  start: (lay) => lay.geometry.parameters.height * lay.scale.y,
                  min: (lay) => 0,
                  max: (lay) => lay.geometry.parameters.height * 2,
                  step: (lay) => 1,
               },
               {
                  type: 'header',
                  label: 'Caratteristiche Testo',
               },
               {
                  type: 'textarea',
                  long: true,
                  action: (lay, v) => lay.text = v,
                  start: (lay) => lay._textRenderInfo.parameters.text,
               },
               {
                  label: 'Colore',
                  icon: `tint`,
                  // long: true,
                  action: (lay, v) => lay.color = v,
                  start: (lay) => lay.color,

               },
               {
                  label: 'opacità',
                  icon: `adjust`,
                  // long: true,
                  action: (lay, v) => lay.material.opacity = v,
                  start: (lay) => lay.material.opacity,
                  min: (lay) => 0,
                  max: (lay) => 1,
                  step: (lay) => .01,

               },
               {
                  label: 'Larghezza',
                  icon: 'arrows alternate horizontal',
                  // long: true,
                  action: (lay, v) => lay.maxWidth = v,
                  start: (lay) => lay._textRenderInfo.parameters.maxWidth,
                  min: (lay) => 0,
                  max: (lay) => this.format.width * 2,
                  step: (lay) => 1,
               },
               {
                  label: 'Dimensione',
                  icon: 'font',
                  // long: true,
                  action: (lay, v) => lay.fontSize = v,
                  start: (lay) => lay._textRenderInfo.parameters.fontSize,
                  min: (lay) => 0,
                  max: (lay) => 200,
                  step: (lay) => 1,
               },
               {
                  label: 'Interlinea',
                  icon: 'grip lines',
                  // long: true,
                  action: (lay, v) => lay.lineHeight = v,
                  start: (lay) => lay._textRenderInfo.parameters.lineHeight,
                  min: (lay) => 0,
                  max: (lay) => 10,
                  step: (lay) => .1,
               },
               {
                  label: 'Spaziatura',
                  icon: 'grip lines vertical',
                  // long: true,
                  action: (lay, v) => lay.letterSpacing = v,
                  start: (lay) => lay._textRenderInfo.parameters.letterSpacing,
                  min: (lay) => 0,
                  max: (lay) => 2,
                  step: (lay) => .01,
               },
               {
                  type: 'header',
                  label: 'Caratteristiche Ombra',
               },
            ]
         }
      },

      add: async (tipo, name, opt) => {
         const scope = this;
         let lay = null;

         // image, text, solid, gradient
         if (!tipo) throw new Error('Specificare un [tipo]');
         name = this.layers.getName(name);

         if (tipo == 'image') {
            lay = await addPlane(false, opt);

         } else if (tipo == 'text') {
            lay = await addText(opt);
         }

         // Init
         lay.name = name;
         lay._anchor = 'tl';
         lay._scaleLink = true;
         lay._controlsO = scope.layers.controlsO[tipo];
         lay.position.z = getNewDepth(name);

         if (['Background', 'safeArea'].includes(name))
            scope.layers.resize(name, 'max');
         else if(tipo != 'text')
            scope.layers.resize(name, 'fit');

         // Update Renderer
         this.rendererUpdate();
         // console.log(`Layer Creato: ${tipo}, ${name}`, lay);
         return lay;

         // Funzioni
         async function addPlane(color, texture) {
            const scene = scope.sceneInfo.scene;

            // Materiale
            let materialOpt = { side: THREE.DoubleSide };
            if (color) {
               materialOpt.color = color;

            } else if (texture) {
               materialOpt.map = await scope.loadTexture(texture);
               materialOpt.transparent = true;

            }

            // Se ha una texture creo il piano con le dimensioni delle immagini
            let w = materialOpt.map ? materialOpt.map.source.data.width : scope.format.width;
            let h = materialOpt.map ? materialOpt.map.source.data.height : scope.format.height;

            const geometry = new THREE.PlaneGeometry(w, h, 2, 2);
            const material = new THREE.MeshBasicMaterial(materialOpt);
            const plane = new THREE.Mesh(geometry, material);
            scene.add(plane);

            return plane;
         }

         function addText(opt) {
            const scene = scope.sceneInfo.scene;
            const myText = new Text();

            return new Promise((resolve, reject) => {
               // Set properties to configure:

               myText.text = `Alexander Skarsgård e Nicole Kidman protagonisti di un avvincente dramma in costume, ambientato nell'Islanda del X secolo. Dopo l'uccisione del padre per mano dello zio, il principe ereditario Amleth fugge in esilio. Determinato a vendicarsi, riesce a scoprire da una veggente che lo zio Fjolnir si trova in Islanda e decide così d'imbarcarsi in un viaggio epico tra maghi, stregoni e divinità norrene, per scovarlo e ucciderlo.`;
               myText.fontSize = scope.scaleUnit * 4;
               myText.lineHeight = 1;

               myText.color = '#FFFFFF';
               myText.anchorX = 'left';
               myText.anchorY = 'top';
               myText.maxWidth = scope.format.width;

               myText.position.x = - scope.format.width / 2;
               myText.position.y = scope.format.height / 2;

               myText.geometry.parameters = {
                  width: scope.format.width,
                  height: scope.format.height,
               }

               scene.add(myText);

               myText.addEventListener('synccomplete', () => {
                  scope.rendererUpdate();
               });

               myText.sync(() => {
                  return resolve(myText);
               });



            });
         }

         function getNewDepth(name) {
            let depth;

            if (name == 'safeArea')
               depth = 101;

            else if (name == 'Background')
               depth = 0;

            else {
               const depths = scope.sceneInfo.scene.children.map(c => c.position.z);
               for (let i = 1; i < 100; i++) {
                  if (depths.includes(i)) continue;
                  else {
                     depth = i;
                     break;
                  }
               }
            }

            if (depth != 0 && !depth)
               throw new Error('Non è possibile gestire più di 100 Livelli.');

            return depth;

         }
      },

      getName: (name) => {
         const names = this.sceneInfo.scene.children.map(c => c.name.toLowerCase());
         let newName = `Layer ${names.length + 1}`;

         if (name)
            name = name.replace(/[^A-Za-z0-9 -]+/gi, '').replace(/\s/gi, '-');

         if (names.includes(name.toLowerCase()))
            return `${name} (copy)`;

         return name
      },

      move: (name, x, y) => {
         const lay = this.sceneInfo.scene.getObjectByName(name);
         const anchor = lay._anchor ? lay._anchor : 'tl';

         const fw = this.format.width;
         const fh = this.format.height;
         const iw = lay.geometry.parameters.width * lay.scale.x;
         const ih = lay.geometry.parameters.height * lay.scale.y;

         const sx = iw / 2 - fw / 2;
         const dx = - (iw / 2 + fw / 2);
         const to = ih / 2 - fh / 2;
         const bo = - (ih / 2 + fh / 2);

         const ao = {
            tl: {
               x: () => sx,
               y: () => to,
            },
            tc: {
               x: () => - fw / 2,
               y: () => to,
            },
            tr: {
               x: () => dx,
               y: () => to,
            },

            cl: {
               x: () => sx,
               y: () => - fh / 2
            },
            cc: {
               x: () => - fw / 2,
               y: () => - fh / 2
            },
            cr: {
               x: () => dx,
               y: () => - fh / 2
            },

            bl: {
               x: () => sx,
               y: () => bo,
            },
            bc: {
               x: () => - fw / 2,
               y: () => bo,
            },
            br: {
               x: () => dx,
               y: () => bo,
            }
         }

         // Se ho passato dei valori li setto
         let newx, newy;
         if (x || x == 0) {
            newx = x + ao[anchor].x();
            lay.position.x = newx;
         }
         if (y || y == 0) {
            newy = -(y + ao[anchor].y()); // la y è invertita
            lay.position.y = newy;
         }


         // console.log(lay.position.x - ao[anchor].x() , lay.position.x, ao[anchor].x())
         const displayx = lay.position.x - ao[anchor].x();
         const displayy = - (lay.position.y + ao[anchor].y()); // la y è invertita
         const displayw = lay.geometry.parameters.width * lay.scale.x;
         const displayh = lay.geometry.parameters.height * lay.scale.y;

         // console.log({newx, newy, displayx, displayy, displayw, displayh });
         return { newx, newy, displayx, displayy, displayw, displayh };
      },

      resize: (name, mode, h) => {

         // fit, max, original
         const lay = this.sceneInfo.scene.getObjectByName(name);
         let anchor = lay._anchor ? lay._anchor : 'cc';

         const fw = this.format.width;
         const fh = this.format.height;
         const ow = lay.geometry.parameters.width;
         const oh = lay.geometry.parameters.height;
         let scalew, scaleh;

         if (mode == 'ori') {
            scalew = 1;
            anchor = 'cc';
            lay.position.x = 0;
            lay.position.y = 0;

         } else if (mode == 'max') {
            scalew = Math.max(fw / ow, fh / oh);
            anchor = 'cc';
            lay.position.x = 0;
            lay.position.y = 0;

         } else if (mode == 'fit') {
            scalew = Math.min(fw / ow, fh / oh);
            anchor = 'cc';
            lay.position.x = 0;
            lay.position.y = 0;

         } else {
            if ((mode == 0 || mode) && (h==0 || h)) {
               scalew = mode / lay.geometry.parameters.width;
               scaleh = h / lay.geometry.parameters.height;

            } else if ((mode == 0 || mode)) {
               scalew = mode / lay.geometry.parameters.width;
               scaleh = lay._scaleLink ? scalew : lay.scale.y;

            } else if (h == 0 || h) {
               scaleh = h / lay.geometry.parameters.height;
               scalew = lay._scaleLink ? scaleh : lay.scale.x;
            }
         }


         // Before
         let iw = lay.geometry.parameters.width * lay.scale.x;
         let ih = lay.geometry.parameters.height * lay.scale.y;

         lay.scale.set(scalew, scaleh ? scaleh : scalew);

         // After
         let dw = iw - lay.geometry.parameters.width * lay.scale.x;
         let dh = ih - lay.geometry.parameters.height * lay.scale.y;

         // Set position based on anchor
         const currx = lay.position.x;
         const curry = lay.position.y;

         const ao = {
            tl: {
               x: (x) => x - dw / 2,
               y: (y) => y + dh / 2,
            },
            tc: {
               x: (x) => x,
               y: (y) => y + dh / 2,
            },
            tr: {
               x: (x) => x + dw / 2,
               y: (y) => y + dh / 2,
            },

            cl: {
               x: (x) => x - dw / 2,
               y: (y) => y
            },
            cc: {
               x: (x) => x,
               y: (y) => y
            },
            cr: {
               x: (x) => x + dw / 2,
               y: (y) => y
            },

            bl: {
               x: (x) => x - dw / 2,
               y: (y) => y - dh / 2,
            },
            bc: {
               x: (x) => x,
               y: (y) => y - dh / 2,
            },
            br: {
               x: (x) => x + dw / 2,
               y: (y) => y - dh / 2,
            }
         }

         lay.position.x = ao[anchor].x(lay.position.x);
         lay.position.y = ao[anchor].y(lay.position.y);

      },

      handleInterface: (name, ev) => {
         const scope = this;
         const target = ev.target.closest('button') ? ev.target.closest('button') : ev.target;
         const lay = scope.sceneInfo.scene.getObjectByName(name);
         const cobj = lay._controlsO;

         const isButton = target.closest('.buttonsMenu');
         const isText = target.closest('.imgLayer.text');
         const hasSlider = target.closest('.field') && target.closest('.field').querySelector('.slider');

         let val, cO;
         if (target.dataset.co) {
            cO = isButton ? cobj.buttons[target.dataset.co] : cobj.fields[target.dataset.co];
            val = !isNaN(target.value) ? parseFloat(target.value) : target.value;
            // action
            if (cO.action) cO.action(lay, val);
         }

         console.log('update', lay, cO ? cO.label : 'noLabel', val);
         updateDisplayValues(lay, target);
         return scope.rendererUpdate();

         function updateDisplayValues(lay, target) {
            const chtm = lay._controls;

            let uPos = scope.layers.move(lay.name);
            let fx = chtm.querySelector('[name="x"]');
            let fy = chtm.querySelector('[name="y"]');
            let fw = chtm.querySelector('[name="w"]');
            let fh = chtm.querySelector('[name="h"]');

            fx.value = uPos.displayx;
            $(fx.closest('.field').querySelector('.slider'))
               .slider('set value', uPos.displayx, false);

            fy.value = uPos.displayy;
            $(fy.closest('.field').querySelector('.slider'))
               .slider('set value', uPos.displayy, false);

            fw.value = uPos.displayw;
            $(fw.closest('.field').querySelector('.slider'))
               .slider('set value', uPos.displayw, false);

            fh.value = uPos.displayh;
            $(fh.closest('.field').querySelector('.slider'))
               .slider('set value', uPos.displayh, false);
         }
      },

      show: () => {

      },

      hide: () => {

      },

      copy: () => {

      },

      paste: () => {

      },

      clear: () => {

      },

      remove: () => {

      },
   }
}

if (typeof module != 'undefined') {
   module.exports = ImgCanvas;
}