module.exports = async function(id) {
  if (!id) return;
  const create = id == 'create';
  const loadData = !create ? client.service('users').get(id) : Promise.resolve([]);
  const $modal = await createModal(create);

  // Loader
  const $loader = $(`
    <div class="ui active inverted dimmer modalLoader">
      <div class="ui indeterminate text loader">Loading</div>
    </div>
  `).appendTo($modal);

  $modal.modal({
    autofocus: false,
    allowMultiple: true,
    transition: 'scale',
  }).modal('show');

  // Load Data
  loadData.then((data) => {
    console.log(data);
    $loader.remove();

    if (data) {
      $modal.data('elData', data).addClass('.dataEl');
      myFunc.renderData($modal, data);
      if (data.avatar) $('.avatarCanvas')[0].myCanvas.insertImage('./avatars/'+data.avatar, 'max');
    }

    $modal.find('.salvaModal').on('click', function() {
      saveUser($modal).then((usr) =>{
        if (!usr) return;
        const $table = $('#usersTable.dataTable');
        const $tr = $('#' + usr.id);

        if ($table.length && $tr.length) $table.DataTable().row('#' + usr.id).data(usr);
        else if ($table.length) $table.DataTable().row.add(usr);

        $modal.modal('hide');
      });
    });
  });

  async function saveUser($modal) {
    const data = myFunc.readData($modal);

    // Se la password è vuota non la salvo
    if (data.newpassword) data.password = data.newpassword;
    delete data.newpassword;

    // Se c'è un nuovo avatar lo salvo
    if ($modal.find('.avatarName').val()) {
      const avatarOld = $modal.data('elData').avatar;
      const avatarName = data.avatar ? data.avatar.split('/')[1] : data.id ? data.id + '_avatar.png' : data.username ? data.username + '_avatar.png' : Date.now() + '_avatar.png';
      const avatarSaved = await $('.avatarCanvas')[0].myCanvas.saveImage(avatarName, 'avatars', 'png');

      if (avatarSaved[0].name) data.avatar = avatarSaved[0].name;

      // Refresh Avatars
      $('img[src*="'+avatarOld+'"]').each((i, img) =>{
        const newSrc = $(img).attr('src').replace(avatarOld, data.avatar);
        $(img).removeAttr('src').attr('src', newSrc+'?'+Date.now());
      });
    }

    // Patch User
    console.log('Patch User', create, data);
    const res = create ? await client.service('users').create(data) : await client.service('users').patch(id, data);
    console.log('Patched User', res);

    return res;
  }

  async function createModal(create) {
    // Rimuove tutte le modal
    $('.ui.modal').remove();

    // Creo una modal generica
    const $modal = $(`<div class="ui small modal" id="modaluser"></div>`)
      .appendTo('body');

    const $header = $(`<div class="header">` + (create ? `Crea` : `Modifica`) + ` Utente</div>`)
      .appendTo($modal);

    let chmenu = '';
    myVars.channels.tutti.forEach( function( ch ) {
      chmenu += '<div class="item" data-value="' + ch.ID + '">' + ch.Name + '</div>';
    } );

    const $content = $(`
      <div class="content">

        <form class="ui padded form" autocomplete="off">

          <h4 class="ui dividing header">Dati di Base</h4>
          <div class="two fields">
            <div class="field">
              <label>Username <i class="asterisk red icon"></i></label>
              <input placeholder="Username" type="text" data-renderdata="username">
            </div>
            <div class="field">
              <label>Nuova Password <i class="asterisk red icon"></i></label>
              <input placeholder="Nuova Password" type="text" data-renderdata="newpassword" autocomplete="new-password">
            </div>
          </div>

          <div class="fields">
            <div class="four wide field">
              <label>Ruolo</label>
              <div class="ui selection dropdown" tabindex="0" data-renderdata="ruolo">
                <div class="default text">Select</div>
                <i class="dropdown icon"></i>
                <input type="hidden" name="ruolo">
                <div class="menu" tabindex="-1">
                  <div class="item" data-value="Admin">Admin</div>
                  <div class="item" data-value="Supervisor">Supervisor</div>
                  <div class="item" data-value="Redattore">Redattore</div>
                  <div class="item" data-value="Grafico">Grafico</div>
                  <div class="item" data-value="DataEditor">Data Editor</div>
                  <div class="item" data-value="EPGScheduler">EPG Scheduler</div>
                  <div class="item" data-value="External">External</div>
                </div>
              </div>
            </div>
            <div class="eight wide field">
              <label>Area</label>
              <div class="ui multiple search selection dropdown" tabindex="0" data-renderdata="area">
                <div class="default text">Select</div>
                <i class="dropdown icon"></i>
                <input type="hidden" name="Area">
                <div class="menu" tabindex="-1">
                  <div class="item" data-value="*">Tutte</div>
                  <div class="item" data-value="Satellite">Satellite</div>
                  <div class="item" data-value="Content">Content</div>
                  <div class="item" data-value="Sky Merlin">Sky Merlin</div>
                  <div class="item" data-value="Discovery">Discovery</div>
                  <div class="item" data-value="Warner">Warner</div>
                  <div class="item" data-value="DeAgostini">DeAgostini</div>
                  <div class="item" data-value="La7">La7</div>
                  <div class="item" data-value="Presidi">Presidi</div>
                </div>
              </div>
            </div>
            <div class="two wide field">
              <label>Attivo</label>
              <div class="ui selection dropdown" tabindex="0" data-renderdata="attivo">
                <div class="default text">Select</div>
                <i class="dropdown icon"></i>
                <input type="hidden" name="Attivo">
                <div class="menu" tabindex="-1">
                  <div class="item" data-value="true">Si</div>
                  <div class="item" data-value="false">No</div>
                </div>
              </div>
            </div>
            <!--<div class="field">
              <label>Disponibile</label>
              <div class="ui selection dropdown" tabindex="0" data-renderdata="disponibile">
                <div class="default text">Select</div>
                <i class="dropdown icon"></i>
                <input type="hidden" name="disponibile">
                <div class="menu" tabindex="-1">
                  <div class="item" data-value="true">Si</div>
                  <div class="item" data-value="false">No</div>
                </div>
              </div>
            </div>-->
            <div class="two wide field">
              <label>Percentuale</label>
              <input placeholder="Percentuale" type="text" data-renderdata="percentuale">
            </div>
          </div>

          <div class="field">
            <label>Canali</label>
            <div class="ui multiple search selection dropdown" data-renderdata="canali">
              <i class="dropdown icon"></i>
              <div class="default text">Seleziona Canali</div>
              <div class="menu">` + chmenu + `</div>
            </div>
          </div>

          <div class="field">
            <label>Stations</label>
            <div class="ui multiple search selection dropdown" data-renderdata="station">
              <i class="dropdown icon"></i>
              <div class="default text">Seleziona Station</div>
              <div class="menu">
                  ${myVars.stations.map(s => `<a class="item" data-value="${s.name}">${s.name}</a>`).join('')}
              </div>
            </div>
          </div>

          <h4 class="ui dividing header">Altri Dati</h4>
          <div class="ui grid">

            <div class="ten wide column">
              <div class="field">
                <label>Nome</label>
                <input placeholder="Nome" type="text" data-renderdata="nome">
              </div>
              <div class="field">
                <label>Cognome</label>
                <input placeholder="Cognome" type="text" data-renderdata="cognome">
              </div>
              <div class="field">
                <label>Email <i class="asterisk red icon"></i></label>
                <input placeholder="Email" type="text" data-renderdata="email">
              </div>
              <div class="field">
                <label>Mobile</label>
                <input placeholder="Mobile" type="text" data-renderdata="mobile">
              </div>
              <div class="eight wide field">
              <label>Centro Di Costo</label>
               <div class="ui multiple search selection dropdown" tabindex="0" data-renderdata="centro_di_costo">
                  <div class="default text">Select</div>
                  <i class="dropdown icon"></i>
                  <input type="hidden" name="centro_di_costo">
                  <div class="menu" tabindex="-1">
                     <div class="item" data-value="*">Tutte</div>
                     <div class="item" data-value="Palinsesti">Palinsesti</div>
                     <div class="item" data-value="Redazione">Redazione</div>
                     <div class="item" data-value="Sport">Redazione Sport</div>
                     <div class="item" data-value="Presidi">Presidi</div>
                     <div class="item" data-value="Supervisori Content">Supervisori Content</div>
                     <div class="item" data-value="Redattori Content">Redattori Content</div>
                     <div class="item" data-value="Grafici">Grafici</div>
                     <div class="item" data-value="Compensi Soci">Compensi Soci</div>
                  </div>
               </div>
               </div>
            </div>

            <div class="six wide column">
              <div class="field avatar">
                <label>Avatar</label>
                <div class="ui fluid two item small menu" style="margin-top: 0;">
                  <div class="item icon avatarRandom"><i class="dice icon"></i></div>
                  <div class="item icon avatarLocal"><i class="folder open icon"></i></div>
                </div>
                <input type="text" class="avatarName" style="display:none;">
                <div class="avatarCanvas"></div>
              </div>
            </div>

          </div>

        </form>

      </div>`)
      .appendTo($modal);

    const $actions = $(`
      <div class="actions">

      <div class="ui left floated left aligned basic mini segment">
        <span class="ui grey text">
          Ultima Modifica il <span data-renderdata="updated_at">--/--/----</span><br>
          <span data-renderdata="created_by_username">--</span>
        </span>
      </div>
      <div class="ui right labeled icon cancel button">Annulla<i class="ban icon"></i></div>
      <div class="ui green right labeled icon button salvaModal">` + (create ? `Crea` : `Salva`) + `<i class="database icon"></i>


      </div>`)
      .appendTo($modal);

    $modal.find('.ui.dropdown').dropdown();
    $modal.find('.ui.checkbox').checkbox();

    // AVATAR CANVAS
    const canAvatar = await _myCanvas.init({
      selector: '.avatarCanvas',
      width: 512,
      height: 512,
      maxHeight: 200,
      nomenu: true,
    }).then((mc) => mc[0]);

    const lName = canAvatar.addLayer('avatar').name;
    canAvatar.activeLayer = lName;

    $modal.find('.avatarCanvas').css({'margin': '10px'});

    // AVATAR INPUT
    $modal.find('.avatarLocal').on('click', function() {
      canAvatar.getLocalFile()
        .then((file) => {
          canAvatar.insertImage(file);
          $modal.find('.avatarName').val(file.name);
        });
    });

    // AVATAR RANDOM
    $modal.find('.avatarRandom').on('click', function() {
      const random = myFunc.randomString(10);
      const randomUrl = 'https://avatars.dicebear.com/v2/avataaars/' + random + '.svg';
      canAvatar.insertImage(randomUrl, 'max');
      $modal.find('.avatarName').val(random + '.svg');
    });

    return $modal;
  }
};
