// INIT MODAL
// $('body').on('click', '[data-modalskydam]', function (e) {
//    e.preventDefault();
//    e.stopPropagation();

//    console.log('click');
//    if (!myVars || !myVars.io) return;
//    if (!['Admin', 'Supervisor', 'Grafico'].includes(myVars.io.ruolo)) return;

//    const id = $(this).data('modalskydam');
//    modalSkyDam(id);
// });

module.exports = async function (id) {
   $.fn.transition.settings.silent = true;

   const $loader = $(`
    <div class="ui active inverted dimmer modalLoader">
      <div class="ui indeterminate text loader">Loading</div>
    </div>
  `).appendTo('body');

   //  client.service('program-join').timeout = 60000;
   const wo = await client.service('sky-dam-wo').find({ query: { damId: id } });
   const assets = await client.service('sky-dam-assets').find({ query: { damId: id, $limit: 500 } });
   const pendingTasks = await client.service('sky-dam-tasks').find({ query: { damId: id, taskStatus: 'pending', $limit: 500 } });

   const data = wo.data[0];
   data.skyDamFiles = assets.data;
   data.skyDamFiles.forEach(a => {
      a.url = null;
      a.extension = null;
      a.name = a.assetTitle;

      if (a.downloadURL) {
         a.url = a.downloadURL.replace(/https\:\/\/d3qp0rh9g8u1hc\.cloudfront\.net\//gi, 'https://tools.datatv.it/sky-dam-files/');
         a.extension = myFunc.getExtension(a.downloadURL);
         a.name = a.assetTitle + '.' + a.extension;
      }
   });
   data.pendingTasks = pendingTasks.data;

   // get files details SYNC
   const imagesWithoutInfoLength = data.skyDamFiles.filter(fi => fi.url && !fi.size).length;

   if (imagesWithoutInfoLength) {
      const imagesWithoutInfo = data.skyDamFiles
         .map(fi => (fi.url && !fi.size ? { uri: fi.url } : { uri: null }));

      $loader.find('.loader').text(`Recupero informazioni per ${imagesWithoutInfoLength} files...`);
      console.log('Recupero informazioni per', imagesWithoutInfo);

      client.service('image-get').timeout = 30000;
      const imagesInfo = await client.service('image-get').get('processImage', {
         query: {
            multi: imagesWithoutInfo
         }
      }).catch(e => console.log('00000', e));

      if (imagesInfo) {
         imagesInfo.forEach((ii, i) => {
            if (!ii || !ii.details) return;

            if (ii.details.size) data.skyDamFiles[i].size = ii.details.size;
            if (ii.details.width) data.skyDamFiles[i].width = ii.details.width;
            if (ii.details.height) data.skyDamFiles[i].height = ii.details.height;

         });
      }
   }

   // for (let file of data.skyDamFiles) {
   //    if (file.size || !file.url) continue;


   //    $loader.find('.loader').text(`Recupero informazioni ${file.assetTitle}`);
   //    console.log('file', file)
   //    let fData = await client.service('image-get').get('processImage', {
   //       query: {
   //          uri: file.url
   //       }
   //    }).catch(e => console.log('00000', e));
   //    console.log('fData', fData)

   //    if (fData) {
   //       file.size = fData.details.size;
   //       file.width = fData.details.width;
   //       file.height = fData.details.height;
   //    }
   // }

   if (!data) return;

   // //////////////////////////////////////////////// CREATE MODAL
   const $modal = createModal(data).modal({
      autofocus: false,
      allowMultiple: true,
      transition: 'scale',
      onHidden: function () {
         // $('body').css('margin-right', 0);
      },
      onHide: function () {

      },
   }).modal('show');

   $modal.data('modalData', data).addClass('.dataEl');
   $loader.remove();

   // //////////////////////////////////////////////// SERVERFILES FIRST RENDER
   if (data.pendingTasks.length) renderPendingTasks(data.pendingTasks);
   if (data.skyDamFiles.length) renderFiles('skyDamFiles', 'listView');


   // //////////////////////////////////////////////// LIST OR THUMBS
   $modal.on('click', '.item.iconView, .item.listView', function (e) {
      e.preventDefault();
      e.stopPropagation();

      const source = $modal.find('.item.active[data-tab]').attr('data-tab');
      const type = $(this).hasClass('listView') ? 'listView' : 'iconView';
      renderFiles(source, type);
   });

   // //////////////////////////////////////////////// THUMBS SELECT / DESELECT
   $modal.on('click', '.imageFile', function () {
      $(this).toggleClass('selected');
   });

   $modal.on('click', function (e) {
      if ($(e.target).closest('.imageFile').length == 0) {
         $('.imageFile').removeClass('selected');
      }
   });

   // //////////////////////////////////////////////// DOWNLOAD SELECTED
   $modal.on('click', '.allDownload', function (e) {
      e.preventDefault();
      e.stopPropagation();

      const selection = $modal.find('.tab.active[data-tab] .selected').map(function () {
         return $(this).find('[data-down]').data('down');
      }).get();
      console.log('Download:', selection);
      selection.forEach((url, i) => {
         setTimeout(function () {
            const $link = $('<a href="' + url + '?downloadthis=1"  download class="myHidden imgDownload"></a>').appendTo($modal);
            console.log($link);
            $link[0].click();
            $link.remove();
         }, i * 500);
      });
   });

   // //////////////////////////////////////////////// SEARCH / SERVER
   $modal.on('keyup', '[name="searchServer"]', function (e) {
      const toSearch = $(this).val();
      const dt = $('.tab[data-tab="skyDamFiles"] .damFilesDt').DataTable();
      dt.search(toSearch).draw();
   });

   // ----------------------------------------------------------------------------------------------------------------------
   // ----------------------------------------------------------------------------------------------------------------------
   // ----------------------------------------------------------------------------------------------------------------------
   // ---------------------------------------------------------------------------------------------------------------------- CREATE MODAL
   function createModal(data) {
      $('#modalskydam').remove();

      const $modal = $(`
      <div class="ui fullscreen modal" id="modalskydam">
        <div class="header" style="border:0">
          <div class="ui horizontal label violet opacita8">DI ${data.damId}</div>
          ${data.titleId ? `<div class="ui horizontal label purple opacita8">TI ${data.titleId}</div>` : ''}
          ${data.UUID ? `<div class="ui horizontal label opacita8">${data.UUID}</div>` : ''}
          <span class="ui grey text opacita4">/&nbsp;&nbsp;</span>
          ${data.title}
         </div>
      </div>`).appendTo('body');

      const $menu = $(`
      <div class="ui attached menu archivioMenu DTE">
        <!--Con la classe DTE non dovrebbe fare il blur sulla selezione della datatable-->
        <div class="item" data-tab="skyDamFiles"><i class="violet box icon"></i>Sky Dam Files</div>
        <div class="item" data-tab="skyPendingTask"><i class="violet tasks icon"></i>Pending Tasks</div>

        <div class="right menu skyDamFiles">
          <div class="ui search vertically fitted item" style="width: 200px;">
            <div class="ui transparent icon input">
              <input class="prompt" type="text" placeholder="Cerca..." autocomplete="off" name="searchServer">
              <i class="search link icon"></i>
            </div>
          </div>
          <a class="item infoPop listView" style="display:none;" data-content="Visualilzza Lista"><i class="fitted bars icon"></i></a>
          <a class="item infoPop iconView" data-content="Visualizza Griglia"><i class="fitted th icon"></i></a>
          <a class="item infoPop allDownload" data-content="Download file selezionati"><i class="fitted download icon"></i></a>
        </div>

      </div>`).appendTo($modal);

      const $content = $(`
      <div class="scrolling content full" style="position:relative; height: 75vh !important;">

        <!--INPUT FILE-->
        <input class="input_file myHidden" type="file" ${!['Admin', 'Supervisor'].includes(myVars.io.ruolo) ? 'accept="image/*"' : ''} multiple>

        <!--TAB SKY DAM-->
        <div class="ui tab basic segment" data-tab="skyDamFiles" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:0; padding:auto;">
          <div class=" ui active inverted dimmer emptyArea">
            <h2 class="ui icon grey opacita4 header">
              <i class="box icon"></i>
              <div class="content">
                Nessun File Disponibile
                <div class="sub header">Non sono stati trovati files sul DAM Sky per questo programma.</div>
              </div>
            </h2>
          </div>
        </div>

        <!--TAB PENDING TASK-->
        <div class="ui tab basic segment" data-tab="skyPendingTask" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:0; padding:auto;">
          <div class=" ui active inverted dimmer emptyArea">
            <h2 class="ui icon grey opacita4 header">
              <i class="tasks icon"></i>
              <div class="content">
                Nessun Pending Task
                <div class="sub header">Non sono presenti Pending Tasks.</div>
              </div>
            </h2>
          </div>
        </div>

      </div>
      <div class="actions info" style="border-radius: 0 0 20px 20px;"></div>
    `).appendTo($modal);

      $(function () {
         $modal.find('.archivioMenu > .item').tab();
         $modal.find('.ui.dropdown').dropdown();
         $modal.find('.ui.checkbox').checkbox();
         $modal.find('.infoPop').popup();
         renderInfo();

         $modal.find('.archivioMenu > .item').not('.generateMenu').on('click', function () {
            const tab = $(this).attr('data-tab');

            // Hide all
            $modal.find('#generatedFiles').hide();
            $modal.find('.segment[data-tab]').hide();
            $modal.find('.archivioMenu .right.menu').hide();

            // Show Needed
            $modal.find('.segment[data-tab="' + tab + '"]').show();
            $modal.find('.archivioMenu .menu.' + tab).show();
         });

         $modal.on('click', function () {
            renderInfo();
         });
      });

      return $modal;
   }


   // ---------------------------------------------------------------------------------------------------------------------- RENDERS
   // renderPendingTasks
   function renderPendingTasks(tasks) {
      let $tab = $modal.find(`.tab[data-tab="skyPendingTask"]`);
      $tab.find('.emptyArea').hide();

      let TESTtasks = [
         // Nuovo Task Pending
         {
            id: 1,
            taskId: 'TEST',
            damId: 3216,
            taskType: null,
            taskStatus: 'Pending',
            created_at: '2021-10-24 16:26:43.0000000',
            updated_at: '2021-11-03 11:57:58.0000000',
            taskName: 'Synopsis Production',
            submitterComment: 'Test commento per il task',
            branchContents: [
               {
                  "assetId": 3217
               }
            ],
            nextBranches: [
               {
                  "exitStatus": 1,
                  "description": "Close Successfully"
               }
            ],
            requiredContents: [
               {
                  "type": "Document"
               }
            ],
            submitted_at: null,
            submitted_by: null,
            submittedMetadata: null,
            submittedUrls: null,
         },
         // Task Completato
         {
            id: 1,
            taskId: 'TEST',
            damId: 4321,
            taskType: null,
            taskStatus: 'Pending',
            created_at: '2021-10-24 16:26:43.0000000',
            updated_at: '2021-11-03 11:57:58.0000000',
            taskName: 'Title and Synopsis Production',
            submitterComment: null,
            branchContents: [
               {
                  "assetId": 3217
               }
            ],
            nextBranches: [
               {
                  "exitStatus": 1,
                  "description": "Close Successfully"
               }
            ],
            requiredContents: [
               {
                  "type": "Document"
               }
            ],
            submitted_at: '2021-11-04 11:57:58.0000000',
            submitted_by: 2,
            submittedMetadata: [{ "synopsis": "SSSSSSinossi di test" }],
            submittedUrls: [{ assetId: '12345', url: 'https://tools.datatv.it/sky-dam-uploads/documento di test.docx' }],
         },
      ];

      tasks = tasks.concat(TESTtasks);

      console.log('renderPendingTasks', tasks);

      // Qui controllo se ci sono task e nel caso li renderizzo
      for (let t of tasks) {
         renderTask(t);
      }

      function renderTask(t) {
         let submSynopsis = t.submittedMetadata ? t.submittedMetadata.find(e => e.synopsis) : null;
         let submTitle = t.submittedMetadata ? t.submittedMetadata.find(e => e.title) : null;
         let submUrls = t.submittedUrls ? t.submittedUrls.filter(e => e.assetId) : null;
         let alreadySent = t.submitted_at ? true : false;

         let $task = $(`

         <div class="ui message ${alreadySent ? 'opacita6' : ' error'}" id="pTask_${t.taskId}">
            ${alreadySent ? `
               <div class="ui top right attached basic label"><i class="green check circle icon"></i>Completato ${moment(t.submitted_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')}</div>
            ` : `
               <div class="ui top right attached basic label"><i class="red warning sign icon"></i>Pending</div>
            `}

            <h4 class="ui header taskHeader" style="margin: 0 !important; cursor: pointer;">
               <div class="ui horizontal label ${alreadySent ? 'grey' : 'pink'} opacita8" style="margin-left: 0;">tI ${t.taskId}</div>
               ${t.taskName}
               <span class="a_grey opacita8">/ ${t.requiredContents && t.requiredContents.length ? t.requiredContents.map(r => r.subtype ? r.subtype : r.type).join(', ') : ''}</span>
               <span class="a_grey opacita4">(${moment(t.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')})</span>
            </h4>

            ${t.submitterComment ? `
               <div class="ui info message">
                  <b>Note:</b> ${t.submitterComment}
               </div>
            ` : ''}

            <form class="ui form" style="margin-top: 14px;">
               ${['Title and Synopsis Production', 'Title Production'].includes(t.taskName) ? `
               <div class="field ${submTitle ? '' : ''}">
                  <label>Titolo</label>
                  <input type="text" name="title" placeholder="Titolo" value="${submTitle ? submTitle.title : ''}" ${submTitle ? 'readonly' : ''}>
               </div>
               ` : ''}

               ${['Title and Synopsis Production', 'Synopsis Production', 'Synopsis Approval'].includes(t.taskName) ? `
               <div class="field ${submSynopsis ? '' : ''}">
                  <label>Sinossi</label>
                  <textarea name="synopsis" rows="2" ${submSynopsis ? 'readonly' : ''}>${submSynopsis ? submSynopsis.synopsis : ''}</textarea>
               </div>
               ` : ''}

               <div class="field">
                  <label>File</label>
                  <input class="input_taskFile myHidden" type="file" multiple>
                  ${alreadySent ? `` : `
                     	<div class="ui compact small menu taskFileMenu">
                           <a class="item local"><i class="paperclip icon"></i>Allega File Locale</a>
                           <!--<a class="item archivio"><i class="blue archive icon"></i>Allega da Archivio</a>-->
                        </div>
                  `}

                  ${alreadySent ? '' : `
                  <div class="ui right floated green button closeTask"><i class="check circle icon"></i>Invia e Chiudi Task</div>
                  `}
               </div>

               ${submUrls ? `
                  <table class="ui small compact table assTable">
                     <thead>
                        <tr><th class="collapsing"></th><th>File Url</th></tr>
                     </thead>
                     <tbody>
                        ${submUrls.map(su => `
                           <tr>
                              <td class="collapsing">${su.assetId}</td>
                              <td>${su.url}</td>
                           </tr>`).join('')}
                     </tbody>
                  </table>
               ` : ''}

            </form>
         </div>

         `).appendTo($tab);

         $task.data('tData', t);

         // Show / Hide
         $task.find('form').hide();
         $task.find('.message').hide();
         $task.find('.taskHeader').on('click', function (e) {
            $task.find('form').toggle();
            $task.find('.message').toggle();
         });

         // Add Allegato
         $task.on('click', '.local', (e) => {
            $task.find('.input_taskFile')[0].click();
         });

         $task.find('.input_taskFile').change(function (e) {
            let files = e.target.files;
            let $menu = $task.find('.taskFileMenu');
            let $assTable = $task.find('.assTable');

            // Se non c'è la tabella la creo
            if (!$assTable.length) {
               $assTable = $(`
                  <table class="ui small compact table assTable">
                     <thead>
                        <tr><th class="collapsing"></th><th>File Url</th></tr>
                     </thead>
                     <tbody>
                     </tbody>
                  </table>
               `).appendTo($task.find('form'));
            }

            // Aggiungo i files
            Array.from(files).forEach(f => {
               let $tbody = $assTable.find('tbody');
               let $file = $(`
                  <tr class="fileToSend">
                     <td class="collapsing"><i class="times circle red link large icon removeAss"></i></td>
                     <td>${f.name}</td>
                  </tr>`).appendTo($tbody);
               $file.data('fileObj', { url: f.name, isFileImage: isFileImage(f) })
            });

            $(this).val('');
         });

         // Remove Allegato
         $task.on('click', '.removeAss', function () {
            let $tr = $(this).closest('tr');
            let $assTable = $(this).closest('table');
            $tr.remove();

            if (!$assTable.find('tbody tr').length) $assTable.remove();
         });

         // Bottone chiudi Task
         $task.on('click', '.closeTask', function () {
            sendDataAndCloseTask($task);
         });
      }

      async function sendDataAndCloseTask($task) {
         let tData = $task.data('tData');
         let title = $task.find('[name="title"]').val();
         let synopsis = $task.find('[name="synopsis"]').val();
         let filesToSend = $task.find('.fileToSend').toArray().map(e => $(e).data('fileObj'));

         let toSend = [];
         if (title) {
            toSend.push({
               "damId": tData.damId,
               "taskId": tData.taskId,
               "type": "Document",
               "subtype": "Title",
               "metadata": [{ "title": title }],
            })
         }

         if (synopsis) {
            toSend.push({
               "damId": tData.damId,
               "taskId": tData.taskId,
               "type": "Document",
               "subtype": "Synopsis",
               "metadata": [{ "synopsis": synopsis }],
            })
         }

         if (filesToSend && filesToSend.length) {
            filesToSend.forEach(fO => {
               toSend.push({
                  "damId": tData.damId,
                  "taskId": tData.taskId,
                  "type": fO.isFileImage ? "Image" : "Document",
                  "contentUrl": fO.url,
               })
            })
         }

         console.log('toSend', toSend);
         let promises = [];

         // toSend.forEach(ts => {
         //    promises.push(
         //       client.service('sky-dam').get('loadContentOnATask', {
         //          query: { body: ts }
         //       })
         //    )
         // });

         await Promise.all(promises);

         // CLOSE TASK
         // await client.service('sky-dam').get('closeTasks', {
         //    query: {
         //       body: [{
         //          damId: tData.damId,
         //          taskId: tData.taskId
         //       }]
         //    }
         // });

      }

      function isFileImage(file) {
         return file && file['type'].split('/')[0] === 'image';
      }
   }

   // renderFiles
   async function renderFiles(source, type) {
      // Incremento il timeout per i file molto grandi
      client.service('image-get').timeout = 60000;

      // Data
      let mData = $modal.data('modalData');
      let files = mData[source] ? mData[source] : [];

      console.log('render', source, type, files);

      // Selection
      let $tab = $modal.find(`.tab[data-tab="${source}"]`);
      let selection = $tab.find('.selected').map(function () {
         return $(this).attr('id');
      }).get();

      // Set menu
      $modal.find(`.right.menu.${source} .listView, .right.menu.${source} .iconView`).show();
      $modal.find(`.right.menu.${source} .${type}`).hide();

      // Reset table
      let dtOrder = [[0, 'desc'], [1, 'desc']];
      if ($tab.find('.dataTable').length > 0) {
         let dt = $tab.find('.dataTable').DataTable();
         dtOrder = dt.order().slice(); // SHALLOW COPY
         dt.destroy();
      }
      $tab.find('table').remove();

      // Se non ci sono files
      if (!files || !files.length) {
         $tab.find('.emptyArea').show();
         return;
      } else {
         $tab.find('.emptyArea').hide();

         let tabTitle = '';
         if (source == 'skyDamFiles') tabTitle = 'File Sky Dam';
         $tab.find('h3').remove();
         $tab.prepend(`<h3 class="ui header">${tabTitle}</h3>`);

         if (type == 'listView') {
            renderListView();
         } else if (type == 'iconView') {
            renderIconView();
         }
      };

      console.log('renderFiles: END');

      async function renderIconView() {
         // Genero le tabelle
         files.forEach(f => {
            let size = 250;
            let $fTable = $(`<table class="ui compact small single line table imageFile listaEl${source}" id="${f.id}"><tbody>
                  <tr>
                     <td style="position: relative;">
                        <div class="imageSelect" style="z-index: 1001;"></div>
                        <img src="https://tools.datatv.it/images/placeholderImage.png" height="${size}" ${source != 'localFiles' ? `data-down="${f.url}"` : ``}>
                     </td>
                  </tr>
                  <tr><td class="ellipsis"><b>${f.uploaded ? '<i class="ui blue archive icon"></i> ' : ''}${f.name}</b></td></tr>
                  <tr><td class="ellipsis">${f.extension ? `<b>${f.extension.toUpperCase()}</b> - ` : ''}${f.width ? `${f.width}x${f.height} - ` : ''}${f.size ? myFunc.readableBytes(f.size) : ''}</td></tr>
               </tbody></table>`).appendTo($tab);
         });

         // Carico le preview
         for (let f of files) {
            let size = 250;
            let $img = $modal.find(`#${f.id} img`);
            let $loader = $(`<div class="ui active inverted dimmer"><div class="ui text loader">Generazione Preview</div></div>`).appendTo($img.closest('td'));

            if (!f.url || !['jpg', 'png', 'webp', 'avif', 'tiff', 'gif', 'svg', 'psd'].includes(f.extension)) {
               $loader.html(`<div class="ui text"><i class="file alternate icon"></i>Preview non disponibile</div>`);

            } else if (f.url) {
               let ext = myFunc.getExtension(f.url);
               if (!['jpg', 'png', 'webp', 'avif', 'tiff', 'gif', 'svg', 'psd'].includes(ext)) continue;

               let fileName = f.url.substring(f.url.lastIndexOf('/') + 1, f.url.length);
               let destFileName = `${fileName}_autox${size}.jpg`;
               let destPath = `/mnt/tools.datatv.it/thumbnails/${destFileName}`;
               if (!$modal.resizing) $modal.resizing = [];

               if ($modal.resizing.includes(destPath)) {
                  $img.attr('src', `https://tools.datatv.it/thumbnails/${destFileName}`);
                  $loader.remove();

               } else if (!$modal.resizing.includes(destPath) && f.size < 536870912) {
                  $modal.resizing.push(destPath);

                  await client.service('image-get').get('processImage', {
                     query: {
                        uri: f.url,
                        destUri: destPath,
                        height: size
                     }
                  }).then(r => {
                     if (r && r.processed && r.processed.uri) {
                        $img.attr('src', r.processed.uri);
                        $loader.remove();
                     } else {
                        $loader.html(`<div class="ui text"><i class="ban icon"></i>Preview non riuscita</div>`);
                     }
                  }).catch(e => $loader.html(`<div class="ui text"><i class="ban icon"></i>Preview non riuscita</div>`));

               } else if (f.size >= 536870912) {
                  $loader.html(`<div class="ui text"><i class="ban icon"></i>Immagine troppo Grande</div>`);

               }
            }
         }

         // Seleziono i file
         $tab.find(selection.map(s => '#' + s).join(', ')).trigger('click');
      }

      function renderListView() {
         let $table = $('<table class="ui small basic selectable celled table damFilesDt" id="damFilesDt"></table>').appendTo($tab);
         let columns = defineFileCols(Object.keys(files[0]));
         let dt = myDt.createTable($table, files, columns, 'id', 1000, 'simple');

         myDt.initThumbnailPopUp('.imagePreview', '#damFilesDt', 'url', 300);

         if (dtOrder) dt.order(dtOrder).draw();

         // SELECT richiede una domready function
         $(function () {
            dt.rows(selection.map((i) => '#' + i)).select();
         });

         function defineFileCols(colNames) {
            let cols = [];
            let myOrder = [
               'isApproved',
               'type',
               'extension',
               'subType',
               'assetTitle',
               'metadataTitle',
               'metadataSynopsis',
               'description',
               'keywords',
               'notes',
               'producer',
               'width',
               'height',
               'size',
               'created_at',
               'lastUpdateDate',
               'updated_at',

               'metadata',
               'id',

               'damId',
               'assetId',
               'downloadURL',
            ];

            myOrder.forEach((n) => {
               if (!colNames.includes(n)) return;

               let col = { visible: false };

               if (n == 'isApproved') {
                  col = {
                     title: 'A',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (data) return '<div class="ui green mini horizontal label" style="margin:-4px 0;">OK</div>';
                        else return '<div class="ui red mini horizontal label" style="margin:-4px 0;">KO</div>';
                     },
                  };
               }

               if (n == 'type') {
                  col = {
                     title: 'Tipo',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        let html = data;
                        return html;
                     },
                  };
               }

               if (n == 'extension') {
                  col = {
                     title: 'Ext',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        let html = data.toUpperCase();
                        return html;
                     },
                  };
               }

               if (n == 'assetTitle') {
                  col = {
                     title: 'Nome',
                     className: 'imagePreview dtvselect',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        let html = data;
                        return html;
                     },
                  };
               }

               if (n == 'metadataTitle') {
                  col = {
                     title: 'Titolo',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        let html = `<textarea rows="3" style="
                           background: none;
                           border: none;
                           min-width: 200px;
                           padding: 10px;
                           margin: -10px -10px -15px -10px;
                           font-size: 12px;">${data}</textarea>`;
                        return html;
                     },
                  };
               }

               if (n == 'metadataSynopsis') {
                  col = {
                     title: 'Sinossi',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        let html = `<textarea rows="3" style="
                           background: none;
                           border: none;
                           min-width: 200px;
                           padding: 10px;
                           margin: -10px -10px -15px -10px;
                           font-size: 12px;">${data}</textarea>`;
                        return html;
                     },
                  };
               }

               if (n == 'description') {
                  col = {
                     title: 'Descrizione',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        let html = data;
                        return html;
                     },
                  };
               }

               if (n == 'notes') {
                  col = {
                     title: 'Note',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        let html = data;
                        return html;
                     },
                  };
               }

               if (n == 'producer') {
                  col = {
                     title: 'Produttore',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        let html = data;
                        return html;
                     },
                  };
               }

               if (n == 'width') {
                  col = {
                     title: 'W',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        const html = data ? data : '';
                        return html;
                     },
                  };
               }

               if (n == 'height') {
                  col = {
                     title: 'H',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        const html = data ? data : '';
                        return html;
                     },
                  };
               }

               if (n == 'size') {
                  col = {
                     title: 'Size',
                     className: 'collapsing',
                     type: 'numeric',
                     render: function (data, type, row, meta) {
                        if (type === 'sort' || type === 'type') {
                           return data;
                        } else {
                           return data ? myFunc.readableBytes(data) : '';
                        }
                     },
                  };
               }

               if (n == 'created_at') {
                  col = {
                     title: 'Creata',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        const html = '<span style="display:none">' + data + '</span>' + moment(data).format('DD/MM/YY HH:mm');
                        return html;
                     },
                  };
               }

               if (n == 'lastUpdateDate') {
                  col = {
                     title: 'Aggiornata',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        const html = '<span style="display:none">' + data + '</span>' + moment(data).format('DD/MM/YY HH:mm');
                        return html;
                     },
                  };
               }

               if (n == 'id') {
                  col = {
                     title: '',
                     orderable: false,
                     className: 'collapsing dtvselect-checkbox',
                     createdCell: function (td, cellData, rowData, row, col) {
                        if (rowData.url) $(td).attr('data-down', rowData.url);
                     },
                     render: function (data, type, row, meta) {
                        return '';
                     },
                  };
               }

               col.data = n;
               col.name = n;
               cols.push(col);
            });

            return { cols: cols };
         }
      }
   }

   // renderInfo
   function renderInfo() {
      const source = $modal.find('.item.active[data-tab]').attr('data-tab');
      const mData = $('#modalskydam').data('modalData');
      const files = mData[source] ? mData[source] : [];
      const selection = $('.tab[data-tab="' + source + '"] .selected').map(function () {
         return $(this).attr('id');
      }).get();
      const selectedFiles = files.filter((f) => selection.includes(f.id) || selection.includes(f.id.toString()));

      const $info = $('.actions.info');
      let html = `<div class="opacita4" style="float: left;"><i class="info circle icon"></i>Il singolo upload deve essere minore di 50MB e 50 File.</div>
    Selezionati <b class="a_blue">${selectedFiles.length}</b> File di ${files.length}`;

      if (source == 'localFiles') {
         const bytes = selectedFiles.map((f) => f.size).reduce((a, b) => a + b, 0);
         if (bytes > 0) html += ` per <b class="a_blue">${myFunc.readableBytes(bytes)}</b>`;
      } else if (source == 'internetFiles') {
         html += ``;
      } else if (source == 'contentVodFiles') {
         html += ``;
      } else if (source == 'skyDamFiles') {
         const bytes = selectedFiles.map((f) => f.size).reduce((a, b) => a + b, 0);
         if (bytes > 0) html += ` per <b class="a_blue">${myFunc.readableBytes(bytes)}</b>`;
      }

      $info.html(html);
   }



}
