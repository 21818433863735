class MyTabulator {
   static extend = function () {
      /* ------------------------------------------------------------------------------------------- EXTENDED FILTERS */
      Tabulator.extendModule("filter", "filters", {
         "=*": function (headerValue, rowValue, rowData, filterParams) {
            let cleanValue = headerValue.trim();
            let isRegExp = false;

            if (/^\".*\"$/gi.test(cleanValue)) {
               cleanValue = cleanValue.replace(/^\"(.*)\"$/gi, '$1');
            } else if (/\*/gi.test(cleanValue)) {
               cleanValue = cleanValue.replace(/\*/gi, '[*]');
               isRegExp = true;
            } else {
               cleanValue = `[*]${cleanValue}[*]`;
               isRegExp = true;
            }



            if (isRegExp) {
               cleanValue = '^' + cleanValue.split('[*]').map(s => myFunc.escapeRegex(s)).join('.*') + '$';
               return new RegExp(cleanValue, 'gi').test(rowValue);
            } else {
               return rowValue.toLowerCase() === cleanValue.toLowerCase();
            }
         },
         "=*Excel": function (headerValue, rowValue, rowData, filterParams) {
            let cleanValue = headerValue.trim();
            let isRegExp = false;

            if (/^\".*\"$/gi.test(cleanValue)) {
               cleanValue = cleanValue.replace(/^\"(.*)\"$/gi, '$1');
            } else if (/\*/gi.test(cleanValue)) {
               cleanValue = cleanValue.replace(/\*/gi, '[*]');
               isRegExp = true;
            } else {
               cleanValue = `[*]${cleanValue}[*]`;
               isRegExp = true;
            }

            if (isRegExp) {
               cleanValue = '^' + cleanValue.split('[*]').map(s => myFunc.escapeRegex(s)).join('.*') + '$';
               return new RegExp(cleanValue, 'gi').test(rowValue.txt);
            } else {
               return rowValue.txt.toLowerCase() === cleanValue.toLowerCase();
            }
         }
      });

      /* ---------------------------------------------------------------------------------------------- STATIC CREATE */
      Tabulator.__create = async function (opt) {
         const cutoms = Tabulator.__customs;

         const defaultOpt = {
            height: '500px',
            layout: "fitColumns", //fit columns to width of table (optional)
            selectable: true, //make rows selectable
            selectableRangeMode: "click",
            movableColumns: true, //allows columns to be moved
            resizableRows: true,
            clipboard: true,
            headerSortElement: '<div class="custom-sorter" />',
            locale: true,
            langs: {
               "it": {
                  "columns": {
                     "name": "Nome", //replace the title of column name with the value "Name"
                  },
                  "data": {
                     "loading": "Loading", //data loader text
                     "error": "Errore", //data error text
                  },
                  "groups": { //copy for the auto generated item count in group header
                     "item": "item", //the singular  for item
                     "items": "items", //the plural for items
                  },
                  "pagination": {
                     "page_size": "Righe Pag.", //label for the page size select element
                     "page_title": "Mostra Pag.",//tooltip text for the numeric page button, appears in front of the page number (eg. "Show Page" will result in a tool tip of "Show Page 1" on the page 1 button)
                     "first": "Prima", //text for the first page button
                     "first_title": "Prima Pag.", //tooltip text for the first page button
                     "last": "Ultima",
                     "last_title": "Ultima Pag.",
                     "prev": "Prec.",
                     "prev_title": "Pag. Prec.",
                     "next": "Pross.",
                     "next_title": "Pross. Pag.",
                     "all": "Tutte",
                     "counter": {
                        "showing": "Visualizzate",
                        "of": "di",
                        "rows": "righe",
                        "pages": "pagine",
                     }
                  },
                  "headerFilters": {
                     "default": "...", //default header filter placeholder text
                     "columns": {
                        "name": "...", //replace default header filter text for column name
                     }
                  }
               }
            },
         }

         // ADD OPTIONS
         opt = Object.assign(defaultOpt, opt);

         // COL OPTIONS
         if (opt.columns && opt.columns.length) {
            opt.columns.forEach(c => {
               if (c.formatter) {
                  const formatter = cutoms[c.formatter];
                  if (formatter) c.formatter = formatter;
               }

               if (c.titleFormatter) {
                  const titleFormatter = cutoms[c.titleFormatter];
                  if (titleFormatter) c.titleFormatter = titleFormatter;
               }

               if (c.headerClick) {
                  const headerClick = cutoms[c.headerClick];
                  if (headerClick) c.headerClick = headerClick;
               }
            });

         } else {
            opt.autoColumns = true;
         }

         if (opt.excelEditor) {
            opt.selectable = false;
            opt.autoColumnsDefinitions = function (definitions) {
               definitions.forEach((column, i) => {
                  column.headerFilter = 'input';
                  column.headerFilterFunc = "=*Excel",
                  column.headerFilterPlaceholder = '...';
                  column.title = opt.data[0][i].a.replace(/[^A-Z]/gi, '')
                  column.formatter = cutoms.excelFormatter;
                  column.editor = cutoms.excelEditor;
                  column.sorter = cutoms.excelSorter;
                  // column.filter = cutoms.excelFilter;
               });

               // Colonna Numeri
               definitions.unshift({
                  title: '',
                  frozen: true,
                  width: 40,
                  hozAlign: 'right',
                  formatter: cutoms.excelRowNumbersFormatter,
                  sorter: cutoms.excelRowNumbersSorter
               });

               return definitions;
            };
         }

         // REMOTE OPTIONS
         if (typeof opt.data === 'string') {
            const totalRows = await client.service(opt.data).find({ query: { $limit: 0 } }).then(r => r.total);
            // const onlyColumns = await client.service('timonegenerale-view').get('onlyColumns');

            const remoteOpt = {
               ajaxURL: `/${opt.data}`,
               ajaxParams: { select: opt._select },
               sortMode: 'remote',
               filterMode: 'remote',
               // ajaxURLGenerator: Tabulator.__feathersUrlGenerator,
               ajaxRequestFunc: Tabulator.__ajaxDebounce(Tabulator.__ajaxRequestFunc, 100),
               ajaxResponse: Tabulator.__ajaxResponse,
               dataLoaderLoading: `<div class="ui active text loader">Loading ${opt.data}...</div>`,
            }

            // Progressive load all
            if(totalRows <= 5000) {
               remoteOpt.progressiveLoad = 'load';
            }

            // Regular pagination
            else {
               // Nota: progressiveLoad: 'scroll' non carica sempre l'ultima richiesta
               // progressiveLoad: 'scroll',
               // progressiveLoadScrollMargin: 300,
               // progressiveLoadDelay: 0,
               remoteOpt.paginationSizeSelector = true;
               remoteOpt.paginationSize = 50;
               remoteOpt.pagination = true;
               remoteOpt.paginationCounter = "rows";
               remoteOpt.paginationMode = "remote";
            }

            opt = Object.assign(opt, remoteOpt);
         }

         // NEW TABLE
         const tabulator = new Tabulator(opt._tableSelector, opt);

         // REFERENCE
         this.tabulator = tabulator;
         tabulator.element.tabulator = tabulator;

         // ADD SEARCH
         tabulator.__addMainSearch();
         tabulator.__addEvents();

         console.log('[Tabulator OK]', tabulator);
         return tabulator;
      }

      /* ------------------------------------------------------------------------------------------------ STATIC AJAX */
      Tabulator.__feathersUrlGenerator = function (url, config, params) {
         console.log('[feathersUrlGenerator]', url, params);
         const { sort, page, size, filter, select } = params;

         // SELECT
         if (select) url += '?' + select.map(s => '$select[]=' + s).join('&');
         else url += '?';

         // PAGINATION
         url += `&$limit=${size}`;
         if (page > 1)
            url += `&$skip=${(page - 1) * size}`;

         // SORT
         if (!sort.length) sort.push({ field: 'id', dir: 'asc' });
         url += '&' + sort.map(s => `$sort[${s.field}]=${s.dir == 'asc' ? 1 : -1}`).join('&');

         // FILTERS
         const alreadyFiltered = [];
         filter.forEach(f => {

            if (f.field && !alreadyFiltered.includes(f.field)) {
               url += getFilterString(f);
               alreadyFiltered.push(f.field);

            } else if (Array.isArray(f)) {
               url += getFilterString(f);

            }
         });

         // console.log('[feathersUrl]', { url: `http://tools.datatv.it/${url}` });
         return url;

         function getFilterString(filter, index) {
            console.log('[filterString]', filter, index);

            // IS OR
            if (Array.isArray(filter))
               return filter.map((filter, i) => getFilterString(filter, i)).join('&');

            const { field, type, value } = filter;
            let filterString = '';
            let qfield = field;
            let qvalue = value;
            let qtype = type;

            // FIELD
            if (index || index === 0)
               qfield = `$or[${index}][${field}]`;

            // TYPE AND VALUE
            if (type === '<') {
               qtype = `[$lt]=`;

            } else if (type === '<=') {
               qtype = `[$lte]=`;

            } else if (type === '>') {
               qtype = `[$gt]=`;

            } else if (type === '>=') {
               qtype = `[$ge]=`;

            } else if (type === '!=') {
               qtype = `[$ne]=`;

            } else if (type === 'in') {
               qvalue = value.map(v => `${qfield}[$in][]=${v}`).join('&');
               qfield = '';
               qtype = '';

            } else if (type === '=*') {
               if (/^\".*\"$/gi.test(qvalue)) {
                  qvalue = qvalue.replace(/^\"(.*)\"$/gi, '$1');
                  qtype = '=';

               } else if (/\*/gi.test(qvalue)) {
                  qvalue = qvalue.replace(/\*/gi, '[*]');
                  qtype = '[$like]=';

               } else {
                  qvalue = `[*]${qvalue}[*]`;
                  qtype = '[$like]=';

               }
            }

            filterString += `&${qfield}${qtype}${qvalue}`;

            console.log('[filterString]\t', filterString);
            return filterString;
         }
      }

      Tabulator.__feathersQueryGenerator = function (url, config, params) {
         // console.log('[feathersQueryGenerator]', url, params);
         const { sort, page, size, filter, select } = params;
         const query = {};

         // SELECT
         if (select)
            query.$select = select;

         // PAGINATION
         query.$limit = size ? size : 25;
         if (page > 1)
            query.$skip = (page - 1) * size;

         // SORT
         if (!sort.length) sort.push({ field: 'id', dir: 'asc' });
         query.$sort = {};
         sort.forEach(s => query.$sort[s.field] = s.dir == 'asc' ? 1 : -1);

         // FILTERS
         const alreadyFiltered = [];
         filter.forEach(f => {
            if (!query.$and) query.$and = [];

            if (f.field && !alreadyFiltered.includes(f.field)) {
               query.$and.push(getFilter(f));
               alreadyFiltered.push(f.field);

            } else if (Array.isArray(f)) {
               query.$and.push(getFilter(f));

            }
         })

         function getFilter(filter) {
            const { field, type, value } = filter;
            let filO = {};

            if (Array.isArray(filter)) {
               filO.$or = filter.map((filter) => getFilter(filter));
            }

            // TYPE AND VALUE
            if (type === '=') {
               filO[field] = value;

            } else if (type === '=*') {
               if (/^\".*\"$/gi.test(value)) {
                  filO[field] = value.replace(/^\"|\"$/gi, '');

               } else if (/\*/gi.test(value)) {
                  filO[field] = { $like: value.replace(/\*/gi, '%') };

               } else {
                  filO[field] = { $like: `%${value}%` };

               }
            } else if (type === '!=') {
               filO[field] = { $ne: value };

            } else if (type === 'in') {
               filO[field] = { $in: value };

            } else if (type === '<') {
               filO[field] = { $lt: value };

            } else if (type === '<=') {
               filO[field] = { $lte: value };

            } else if (type === '>') {
               filO[field] = { $gt: value };

            } else if (type === '>=') {
               filO[field] = { $ge: value };

            }

            // console.log('[filter Object]\t', filO);
            return filO;
         }

         return query;
      }

      Tabulator.__ajaxDebounce = function (func, wait) {
         let timeout;
         return function (...args) {
            // console.log('---------------------------------------- Debouncing...', this.table.dataLoader.requestOrder)
            // this.table.dataLoader.requestOrder++;
            clearTimeout(timeout);
            return new Promise((resolve) => {
               timeout = setTimeout(() => {
                  // console.log('---------------------------------------- Go...', this.table.dataLoader.requestOrder)
                  // this.table.dataLoader.requestOrder--;
                  resolve(func.apply(this, args));
               }, wait);
            });
         };
      };

      Tabulator.__ajaxRequestFunc = function (url, config, params) {
         const query = Tabulator.__feathersQueryGenerator(url, config, params);
         console.time(`[${url}]`);
         console.log(`[${url}]:`, {query});

         return client.service(url).find({ query: query })
            .then(res => {
               console.timeEnd(`[${url}]`);
               console.log(`[${url}]:`, res);
               return res;
            });

      }

      Tabulator.__ajaxResponse = function (url, params, response) {
         this.options._last_row = response.total;

         const lastPage = Math.ceil(response.total / params.size);
         let formattedData = {
            last_page: lastPage,
            last_row: response.total,
            data: response.data
         };
         return formattedData;
      };

      /* --------------------------------------------------------------------------------------------- STATIC CUSTOMS */
      Tabulator.__customs = {
         selectHeaderToggle: function (event, column) {
            const table = column.getTable();
            const selectedRows = table.getSelectedRows();

            if (selectedRows.length) {
               table.deselectRow();
            } else {
               table.selectRow();
            }
         },

         selectIdHeader: function () {
            const html = `
            <div class="selectToIconHeader">
               <div class="selectToIconHeaderText"></div>
            </div>`;
            return html;
         },
         selectId: function (cell, formatterParams, onRendered) {
            const value = cell.getValue();
            const html = `
            <div class="selectToIcon">
               <div>${value}</div>
            </div>`;
            return html;
         },
         boolean: function (cell, formatterParams, onRendered) {
            const value = cell.getValue();
            let html = '';

            if (value) {
               html = '<i class="fitted large grey check circle icon"></i>';
            } else {
               html = '<i class="fitted large grey ban icon"></i>';
            }

            return html;
         },
         user: function (cell, formatterParams, onRendered) {
            const value = cell.getValue();
            const user = formatterParams.elsArray.find(u => u.id == value);

            let html = `User: ${value}`;

            if (user) {
               const { avatar, nome, cognome, ruolo } = user;
               const avatarSrc = avatar ? `./avatars/${avatar}` : './avatars/user.png';
               const firstNameInitial = nome ? `${nome.substring(0, 1)}.` : '';
               const userHeader = `${firstNameInitial} ${cognome}`;

               html = `
               <div class="ui relaxed divided link list userList">
                  <a class="item">
                     <img class="ui avatar image" src="./${avatarSrc}">
                     <div class="content">
                        <div class="header">${userHeader}</div>
                        <div class="description">${ruolo}</div>
                     </div>
                  </a>
               </div>`;
            }

            return html;
         },
         userDate: function (cell, formatterParams, onRendered) {
            const value = cell.getValue();
            const user = formatterParams.elsArray.find(u => u.id == value);
            const row = cell.getRow().getData();

            let html = `User: ${value}`;

            if (user) {
               const { avatar, nome, cognome } = user;
               const avatarSrc = avatar ? `./avatars/${avatar}` : './avatars/user.png';
               const firstNameInitial = nome ? `${nome.substring(0, 1)}.` : '';
               const userHeader = `${firstNameInitial} ${cognome}`;

               const date = row[formatterParams.dateField];
               const formattedDate = date ? moment.utc(date).format('DD/MM/YY') : '???';

               html = `
               <div class="ui relaxed divided link list userList">
                  <a class="item">
                     <img class="ui avatar image" src="./${avatarSrc}">
                     <div class="content">
                        <div class="header">${userHeader}</div>
                        <div class="description">${formattedDate}</div>
                     </div>
                  </a>
               </div>`;
            }

            return html;
         },
         elementsList: function (cell, formatterParams, onRendered) {
            const value = cell.getValue();
            const parsedArray = myFunc.tryJsonParse(value);
            let html = parsedArray;

            if (Array.isArray(parsedArray)) {
               html = `
               <div class="cell-scroll">
                  <div class="ui divided mini list">
                     ${parsedArray.map((el, i) =>
                  `<div class="item">${el == '*' ? 'Tutti' : el}</div>
                     `).join('')}
                  </div>
               </div>`;

               if (formatterParams.elsArray) {
                  const matchingEls = formatterParams.elsArray.filter(el =>
                     parsedArray.map(a => parseInt(a)).includes(el.id)
                  );

                  html = `
                  <div class="cell-scroll">
                     <div class="ui divided mini list">
                        ${matchingEls.map((el, i) => `
                           <div class="item">${el.Name ? el.Name : el.nome ? el.nome : '???'} (${el.id})</div>
                        `).join('')}
                     </div>
                  </div>`;
               }
            }

            return html;
         },
         rowTitle: function (cell, formatterParams, onRendered) {
            const row = cell.getRow().getData();
            if(!formatterParams)
               throw new Error('formatterParams mancanti per il formato colonna rowTitle');

            let line1 = row[formatterParams.line1] ? row[formatterParams.line1] : '-';
            let line2 = row[formatterParams.line2] ? row[formatterParams.line2] : '-';
            let line3 = row[formatterParams.line3] ? row[formatterParams.line3] : '-';

            let html = '';

            html += `<div><b>${line1}</b></div>`;
            if (line1 != line2 || line1 != line3 || line2 != line3) {
               html += `<div>${line2}</div>`;
               html += `<div class="opacita6">${line3}</div>`;
            }

            return html;
         },
         dataTvIds: function (cell, formatterParams, onRendered) {
            const value = cell.getValue();
            const row = cell.getRow().getData();
            let idSerie = row.idSerie;
            let idProgram = value;
            let idEpisode = row.idEpisode;

            if (formatterParams) {
               idSerie = row[formatterParams.idSerie];
               idProgram = row[formatterParams.idProgram];
               idEpisode = row[formatterParams.idEpisode];
            }

            let html = '';

            if (idSerie && idSerie != -1)
               html += `<div class="ui smallest orange label" title="ID Serie: ${idSerie}">SE ${idSerie}</div>`;
            if (idProgram && idProgram != -1)
               html += `<div class="ui smallest blue label" title="ID Programma: ${idProgram}">PR ${idProgram}</div>`;
            if (idEpisode && idEpisode != -1)
               html += `<div class="ui smallest teal label" title="ID Episodio: ${idEpisode}">EP ${idEpisode}</div>`;

            return html;
         },

         // Excel Editor
         excelFormatter: function (cell, formatterParams, onRendered) {
            const value = cell.getValue();
            const cellElement = cell.getElement();
            const cellColor = value.sty?.fgColor?.rgb;
            const fontColor = value.sty?.color?.rgb;
            const fontSize = value.sty?.sz;

            let html = '';

            if (cellColor) {
               cellElement.style.backgroundColor = '#' + cellColor;
            }

            if (fontColor) {
               cellElement.style.color = '#' + fontColor;
            }

            if (fontSize) {
               cellElement.style.fontSize = fontSize + 'px';
            }

            html = value.txt;
            return html;
         },
         excelEditor: function (cell, onRendered, success, cancel) {
            let valueObj = cell.getValue();
            const input = document.createElement("input");

            input.setAttribute("type", "text");
            input.style.padding = "4px";
            input.style.width = "100%";
            input.style.boxSizing = "border-box";
            input.value = valueObj.txt;

            onRendered(function () {
               input.focus();
               input.style.height = "100%";
            });

            function onChange() {
               if (input.value != valueObj.txt) {
                  if (!valueObj) valueObj = {};
                  valueObj.txt = input.value;
                  valueObj.par = null;
                  valueObj.fmt = null;
                  valueObj.tip = null;
                  valueObj.val = null;

                  success(valueObj);

               } else {
                  cancel();
               }
            }

            //submit new value on blur or change
            input.addEventListener("blur", onChange);
            //submit new value on enter
            input.addEventListener("keydown", function (e) {
               if (e.keyCode == 13) {
                  onChange();
               }

               if (e.keyCode == 27) {
                  cancel();
               }
            });

            return input;

         },
         excelSorter: function (a, b, aRow, bRow, column, dir, sorterParams) {
            return (a.txt ? a.txt : '').localeCompare(b.txt ? b.txt : '')
         },
         excelRowNumbersFormatter: function (cell, formatterParams, onRendered) {
            const cellData = cell.getRow().getData()[0];
            return `<b>${cellData.a.replace(/[^0-9]/gi, '')}</b>`;
         },
         excelRowNumbersSorter: function (a, b, aRow, bRow, column, dir, sorterParams) {
            const aN = aRow.getData()[0].a.replace(/[^0-9]/gi, '');
            const bN = bRow.getData()[0].a.replace(/[^0-9]/gi, '');
            return parseInt(aN) - parseInt(bN);
         },


         // Timone Generale
         tg_stato: function(cell, formatterParams, onRendered){

         }
      }

      /* ------------------------------------------------------------------------------------------ GENERIC FUNCTIONS */
      Tabulator.prototype.__debounce = function (callback, delay) {
         let timerId;
         return function (...args) {
            if (timerId) {
               clearTimeout(timerId);
            }
            timerId = setTimeout(() => {
               callback(...args);
               timerId = null;
            }, delay);
         };
      }

      Tabulator.prototype.__addEvents = function () {
         const tabulator = this;
         const { _tableSelector } = tabulator.options;

         // Document
         document.addEventListener('click', function (e) {
            if (!e.target.closest(_tableSelector)) tabulator.deselectRow();
         });

         // Tabulator
         tabulator.on("tableBuilt", function () {
            tabulator.__renderExtraControls();
            tabulator.__renderPresets();
         });

         tabulator.on("dataFiltering", function (filters) {
            // tabulator.__renderExtraControls();
            // tabulator.__renderPresets();
         });

         // tabulator.on("dataLoading", function (filters) {
         //    console.log('---------------------------------------- dataLoading...', tabulator.dataLoader.requestOrder)
         // });
         // tabulator.on("dataProcessing", function (filters) {
         //    console.log('---------------------------------------- dataProcessing...', tabulator.dataLoader.requestOrder)
         // });
         // tabulator.on("dataProcessed", function (filters) {
         //    console.log('---------------------------------------- dataProcessed...', tabulator.dataLoader.requestOrder)
         // });
         // tabulator.on("dataLoadError", function (filters) {
         //    console.log('---------------------------------------- dataLoadError...', tabulator.dataLoader.requestOrder)
         // });
         // tabulator.on("dataChanged", function (filters) {
         //    console.log('---------------------------------------- dataChanged...', tabulator.dataLoader.requestOrder)
         // });

         tabulator.on("rowSelectionChanged", function (row) {
            const selection = tabulator.getSelectedRows();
            const table = tabulator.element;
            table.querySelectorAll('.selectToIconHeaderText')[0].innerHTML = selection.length;

            if (selection.length) {
               table.querySelectorAll('.selectToIconHeader')[0].classList.add('selection');

            } else {
               table.querySelectorAll('.selectToIconHeader')[0].classList.remove('selection');
            }
         });
      }

      /* ------------------------------------------------------------------------------------------- FILTER FUNCTIONS */
      Tabulator.prototype.__getFilterIndex = function (arr, filter) {
         if (!Array.isArray(filter)) {
            return arr.findIndex(f =>
               f.field === filter.field &&
               f.type === filter.type &&
               f.value === filter.value
            );
         } else {
            // for (const af of arr) {
            for (let i = 0; i < arr.length; i++) {
               const af = arr[i];
               if (!Array.isArray(af)) continue;

               let found = 0;
               for (const subFilter of filter) {
                  if (af.find(aff =>
                     aff.field === subFilter.field &&
                     aff.type === subFilter.type &&
                     aff.value === subFilter.value
                  )) {
                     found++;
                  }
               }

               if (found === filter.length) return i;
            };

            return -1;
         };
      }

      Tabulator.prototype.__removeFilters = function (filterArr, apply) {
         const tabulator = this;
         const { initialFilter } = tabulator.options;
         const tableFilters = tabulator.getFilters();
         const headerFilters = tabulator.getHeaderFilters();
         const removedFilters = [];

         if (!Array.isArray(filterArr)) filterArr = [filterArr];
         // console.log('Remove filterArr', filterArr)

         // Custom del primo filtro
         const iFilterIndex = tabulator.__getFilterIndex(initialFilter, filterArr[0]);
         const iFilter = iFilterIndex > -1 ? initialFilter[iFilterIndex] : null;
         const custom =
            iFilter && iFilter.custom ? iFilter.custom
            : iFilter && iFilter[0] && iFilter[0].custom ? iFilter[0].custom
            : null;

         // Se è un preset tolgo tutti i filtri preset
         if (custom && /\(preset\)/gi.test(custom)) {
            filterArr = initialFilter.filter(f =>
               (Array.isArray(f) && /\(preset\)/gi.test(f[0].custom))
               || (!Array.isArray(f) && /\(preset\)/gi.test(f.custom))
            );
         }

         for (const fil of filterArr) {
            removedFilters.push(removeFilter(fil));
         }

         if (apply !== false) {
            if (tableFilters.length !== tabulator.getFilters().length) {
               tabulator.setFilter(tableFilters);
            }

            if (headerFilters.length !== tabulator.getHeaderFilters().length) {
               tabulator.clearHeaderFilter();
               headerFilters.forEach(f => tabulator.setHeaderFilterValue(f.field, f.value));
               //tabulator.setHeaderFilterValue(removedFilters[0].field, '');
            }
         }

         return { initialFilter, tableFilters, headerFilters };

         /**
          * Remove the given filter from initialFilter, tableFilters, or headerFilters.
          * @param {Object} filter - The filter to be removed.
          * @returns {Boolean} - Whether the filter was successfully removed or not.
          */
         function removeFilter(filter) {
            let removed = false;

            const initFilterIndex = tabulator.__getFilterIndex(initialFilter, filter);
            if (initFilterIndex > -1) {
               initialFilter.splice(initFilterIndex, 1);
            }

            const tableFilterIndex = tabulator.__getFilterIndex(tableFilters, filter);
            if (tableFilterIndex > -1) {
               removed = tableFilters.splice(tableFilterIndex, 1)[0];
               return removed;
            }

            const headerFilterIndex = tabulator.__getFilterIndex(headerFilters, filter);
            if (headerFilterIndex > -1) {
               removed = headerFilters.splice(headerFilterIndex, 1)[0];
               return removed;
            }
         }
      }

      Tabulator.prototype.__addFilters = function (filterArr, apply) {
         const tabulator = this;
         let { initialFilter } = tabulator.options;
         let tableFilters = tabulator.getFilters();
         let headerFilters = tabulator.getHeaderFilters();

         if (!Array.isArray(filterArr)) filterArr = [filterArr];

         // Rimuovo prima gli altri Search e Preset
         const filtersToRemove = [];
         for (const filter of filterArr) {
            const custom =
               filter && filter.custom ? filter.custom
                  : filter[0] && filter[0].custom ? filter[0].custom
                     : null;

            if (custom === 'search') {

               filtersToRemove.push(...initialFilter.filter(cf =>
                  (Array.isArray(cf) && cf[0].custom === 'search') ||
                  (!Array.isArray(cf) && cf.custom === 'search')
               ));

            } else if(custom && /\(preset\)/gi.test(custom)) {

               filtersToRemove.push(...initialFilter.filter(cf =>
                  (Array.isArray(cf) && /\(preset\)/gi.test(cf[0].custom)) ||
                  (!Array.isArray(cf) && /\(preset\)/gi.test(cf.custom))
               ));

            }
         }

         if(filtersToRemove.length){
            const res = tabulator.__removeFilters(filtersToRemove, false);
            initialFilter = res.initialFilter;
            tableFilters = res.tableFilters;
            headerFilters = res.headerFilters;
         }

         for (const filter of filterArr) {
            addFilter(filter);
         }

         if (apply !== false) {

            tabulator.setFilter(tableFilters);

            //    // Verifico quanti filtri sono gli stessi di quelli applicati
            //    const sameFilters = customFilters.reduce((count, nf) => {
            //       const exist = this.getFilterIndex(actualFilters, nf) > -1;
            //       return count + (exist ? 1 : 0);
            //    }, 0);


            if (headerFilters.length !== tabulator.getHeaderFilters().length) {
               tabulator.clearHeaderFilter();
               headerFilters.forEach(f => tabulator.setHeaderFilterValue(f.field, f.value));
               //tabulator.setHeaderFilterValue(removedFilters[0].field, '');
            }
         }

         return { initialFilter, tableFilters, headerFilters };

         function addFilter(filter){
            // Custom del primo filtro
            const custom =
               filter && filter.custom ? filter.custom
                  : filter[0] && filter[0].custom ? filter[0].custom
                     : null;

            if (custom === 'header') {
               initialFilter.unshift(filter);
               headerFilters.unshift(filter);

            } else {
               initialFilter.unshift(filter);
               tableFilters.unshift(filter);
            }
         }
      }

      /* -------------------------------------------------------------------------------------------------- INTERFACE */
      Tabulator.prototype.__addMainSearch = function () {
         const tabulator = this;
         const { _searchSelector } = tabulator.options;
         if(!_searchSelector) return;

         const searchInput = document.querySelector(_searchSelector);
         if (!searchInput) return;

         const fieldsToSearch = searchInput.dataset.colstosearch.split(',');
         if(!fieldsToSearch.length) return;

         searchInput.title = `Cerca nei campi: ${fieldsToSearch.join(', ')}
         Virgolette per cercare il testo esatto, es: "Tron"
         Asterischi come wildcards, es: Superm*n`;

         function search(query) {
            const value = query.trim();
            const newFilter = fieldsToSearch.map((field) => ({ field: field, type: '=*', value: value, custom: 'search' }));
            tabulator.__addFilters([newFilter]);
         }

         const debouncedSearch = tabulator.__debounce(search, 500);

         searchInput.addEventListener('keyup', (e) => {
            const query = event.target.value;
            debouncedSearch(query);
         });
      }

      Tabulator.prototype.__renderExtraControls = async function () {
         const tabulator = this;
         const { _addExtraControls, initialFilter, _last_row } = tabulator.options;
         if (!_addExtraControls) return;

         tabulator.element.style.marginTop = '0';

         const ajaxURL = tabulator.getAjaxUrl();
         const order = tabulator.getSorters();
         const filters = tabulator.getFilters(true);

         const tableTotal = ajaxURL
            ? await fetch(`./${ajaxURL}?$limit=0`)
               .then(res => res.json())
               .then(res => res.total)
            : tabulator.getDataCount();

         const last_row = _last_row
            ? _last_row
            : tabulator.getRows("active").length;

         // CONTAINER
         let element_extraControls = tabulator.element_extraControls;

         if (element_extraControls) {
            element_extraControls.innerHTML = '';

         } else {
            element_extraControls = document.createElement('div');
            element_extraControls.classList.add('tabulator-element_extraControls');
            tabulator.element.parentElement.insertBefore(element_extraControls, tabulator.element);
            tabulator.element_extraControls = element_extraControls;
         }

         // CONTAINERS
         const rightControls = document.createElement('div');
         rightControls.classList.add('rightControls', 'ui', 'basic', 'labels');
         element_extraControls.appendChild(rightControls);

         const leftControls = document.createElement('div');
         leftControls.classList.add('leftControls', 'ui', 'labels');
         element_extraControls.appendChild(leftControls);

         // RIGHT CONTROLS
         const copySelectedRowsLabel = document.createElement('a');
         copySelectedRowsLabel.classList.add('copySelectedRowsLabel', 'ui', 'label');
         copySelectedRowsLabel.innerHTML = '<i class="copy icon"></i>Copia';
         rightControls.appendChild(copySelectedRowsLabel);

         const exportExcelLabel = document.createElement('a');
         exportExcelLabel.classList.add('exportExcelLabel', 'ui', 'label');
         exportExcelLabel.innerHTML = '<i class="file excel icon"></i>Esporta';
         rightControls.appendChild(exportExcelLabel);



         // LEFT CONTROLS
         const resetFiltersLabel = document.createElement('a');
         resetFiltersLabel.classList.add('resetFiltersLabel', 'ui', 'red', 'label');
         resetFiltersLabel.innerHTML = '<i class="fitted alternate trash icon"></i>';
         leftControls.appendChild(resetFiltersLabel);

         printTotal(last_row, tableTotal);
         printOrder(order);
         printFilters(filters, initialFilter);

         function printTotal(last_row, tableTotal) {
            const totalFilteredLabel = document.createElement('div');
            totalFilteredLabel.classList.add('totalFilteredLabel', 'ui', 'label');
            totalFilteredLabel.innerHTML = `
            ${new Intl.NumberFormat('it-IT').format(last_row)} di
            ${new Intl.NumberFormat('it-IT').format(tableTotal)}
         `;
            leftControls.appendChild(totalFilteredLabel);
         }

         function printOrder(order) {
            order.forEach(oe => {
               const orderLabel = document.createElement('div');
               orderLabel.classList.add('orderLabel', 'ui', 'label');
               orderLabel.innerHTML = `<i class="fitted sort ${oe.dir == 'asc' ? 'up' : 'down'} icon"></i> ${oe.field}`;
               leftControls.appendChild(orderLabel);
            });
         }

         function printFilters(filters, initialFilter) {

            filters.forEach(fil => {
               const iFilterIndex = tabulator.__getFilterIndex(initialFilter, fil);
               const iFilter = iFilterIndex > -1 ? initialFilter[iFilterIndex] : null;

               const custom = iFilter && iFilter.custom ? iFilter.custom
                  : iFilter && iFilter[0] && iFilter[0].custom ? iFilter[0].custom
                     : null;

               if (custom) {
                  // Fixed non li stampo
                  if (custom === 'fixed') return;

                  // Main Search
                  if (custom === 'search') {
                     const searchFilter = document.createElement('a');
                     searchFilter.classList.add('searchFilter', 'ui', 'green', 'label');
                     searchFilter.innerHTML = `<i class="search icon"></i>Ricerca:<div class="detail">${iFilter[0].value}</div>`;
                     leftControls.appendChild(searchFilter);

                     searchFilter.addEventListener('mouseup', (e) => {
                        tabulator.__removeFilters([fil]);
                     });
                  }

                  // Presets
                  if (/\(preset\)/gi.test(custom) && !leftControls.querySelector('.presetFilter')) {
                     const presetFilter = document.createElement('a');
                     presetFilter.classList.add('presetFilter', 'ui', 'blue', 'label');
                     presetFilter.innerHTML = `<i class="search icon"></i>Preset:<div class="detail">${custom.replace(/\(preset\)/gi, '')}</div>`;
                     leftControls.appendChild(presetFilter);

                     presetFilter.addEventListener('mouseup', (e) => {
                        tabulator.__removeFilters([fil]);
                     });
                  }
               }

               // Table Filters
               if (!custom) {
                  const tableFilter = document.createElement('a');
                  let text;

                  if (Array.isArray(fil)) {
                     text = fil.map(fi => `${fi.field} ${fi.type.toUpperCase()} ${fi.value}`).join(' OR ');

                  } else {
                     const { field, type, value } = fil;
                     text = `${field} ${type.toUpperCase()} ${value}`;
                  }

                  tableFilter.classList.add('tableFilter', 'ui', 'basic', 'label');
                  tableFilter.innerHTML = `<i class="filter icon"></i>${text}</div>`;
                  leftControls.appendChild(tableFilter);

                  tableFilter.addEventListener('mouseup', (e) => {
                     tabulator.__removeFilters([fil]);
                  });
               }
            });


         }
      }

      Tabulator.prototype.__renderPresets = async function () {
         const tabulator = this;
         const { _presets, initialFilter } = tabulator.options;
         if(!_presets) return;

         const actualPreset = initialFilter.find(f =>
            (Array.isArray(f) && /\(preset\)/gi.test(f[0].custom))
            || (!Array.isArray(f) && /\(preset\)/gi.test(f.custom))
         )
         if (!_presets) return;

         // CONTAINER
         let element_presetsContainer = tabulator.element_presetsContainer;

         if (element_presetsContainer) {
            element_presetsContainer.innerHTML = '';

         } else {
            element_presetsContainer = document.createElement('div');
            element_presetsContainer.classList.add('tabulator-element_presetsContainer', 'ui', 'small', 'menu');
            tabulator.element.parentElement.insertBefore(element_presetsContainer, tabulator.element);
            tabulator.element_presetsContainer = element_presetsContainer;
         }

         addItemsToPresets(_presets, element_presetsContainer);
         $(element_presetsContainer).find('.ui.dropdown').dropdown();


         element_presetsContainer.querySelectorAll(`[data-presetname]`)
            .forEach(e => e.classList.remove('active'));

         if (actualPreset) {
            const presetElement = element_presetsContainer.querySelector(`[data-presetname="${actualPreset.custom}"]`);
            if (presetElement) presetElement.classList.add('active');
         }

         // generate A function that recursively render html items for each presets inside the container
         function addItemsToPresets(items, container) {
            items.forEach((item) => {
               const { label, icon, right, total, sub, filter } = item;

               if (right) {
                  if (!container.querySelector('.right.menu')) {
                     const rightMenu = document.createElement('div');
                     rightMenu.classList.add('right', 'menu');
                     container.appendChild(rightMenu);
                  }

                  container = container.querySelector('.right.menu');
               }

               let element = null;
               if (sub) {
                  element = document.createElement('div');
                  element.classList.add('ui', 'vertically', 'fitted', 'dropdown', 'item');
                  element.innerHTML = `
                     <i class="${icon} icon"></i>${label}&nbsp;
                     <span class="text">...</span>
                     <i class="dropdown icon"></i>
                     <div class="menu"></div>
                  `;
                  container.appendChild(element);
                  const subMenu = element.querySelector('.menu');

                  addItemsToPresets(sub, subMenu);


               } else if(filter) {
                  element = document.createElement('a');
                  element.classList.add('vertically', 'fitted', 'item');
                  element.dataset.presetname = `${label} (preset)`;
                  element.innerHTML = `
                     ${icon ? `<i class="${icon} icon"></i>` : ''}
                     ${label}
                     ${total ? `<div class="right aligned floating ui small label">0.000</div>` : ''}
                  `;
                  container.appendChild(element);

                  element.addEventListener('mouseup', (e) => {
                     tabulator.__addFilters(filter.map(f => ({ ...f, custom: `${label} (preset)` })));

                  })
               }

            });
         }
      }
   };
}