const myVars = require("./myVars");

module.exports = {
   _$modal: null,
   _origin: null,
   _createData: null,

   init: function () {

      $('body').on('contextmenu', '[data-modalschedadb]', function (ev) {
         if (isPermitted()) schedaDbStart(ev, 'data-modalschedadb');
      });

      $('body').on('click', '[data-modalschedadbemptycreate]', function (ev) {
         if (isPermitted()) schedaDbStart(ev, 'data-modalschedadbemptycreate');
      });

      async function schedaDbStart(ev, attrName) {
         // Solo Uno alla volta è possibile
         $('.modalschedadbPop').remove();

         ev.preventDefault();
         ev.stopPropagation();

         // Base Data
         const $target = $(ev.currentTarget);
         const $popActivator = $target.closest('.popup').data('activator');
         const $origin = $popActivator ? $popActivator : $target;
         let [tipo, idProgram, idEpisode, _tempID] = $target.attr(attrName).split(',');
         let $pop = null;

         // Se import Palinsesti
         if ($origin.closest('#sheet_preparazione').length) {
            modalSchedaDb._origin = $origin.closest('tr');
         }

         // Crea Nuova Scheda
         if (attrName == 'data-modalschedadbemptycreate') {
            modalSchedaDb.open(null, null, tipo ? tipo : 'Programma');
            return;
         }

         // MyRes
         const myResData = $target.closest('.myRes').data();
         if (myResData) {
            if (!idProgram) idProgram = myResData.idprogram;
            if (!idEpisode) idEpisode = myResData.idepisode;
            if (!tipo) tipo = myResData.restype;
            _tempID = myResData.tempid;
         }

         // Se è un elemento da creare
         if (idProgram == '[NEW]' || idEpisode == '[NEW]') {
            $pop = $(`
               <div class="ui wide popup modalschedadbPop" style="padding:0;">
                  <div class="ui small fluid vertical menu" style="box-shadow: none; border: none;">
                     ${!modalSchedaDb._$modal ? `<a class="item creaNuovoInDb"><i class="blue database icon"></i>Crea <b class="a_blue">Nuovi</b> Programmi/Episodi</a>` : ''}
                     <a class="item vediNuovoInDb"><i class="blue write icon"></i>Modifica <b class="a_blue">Nuovi</b> Programmi/Episodi</a>
                  </div>
               </div>
               `).appendTo($('body'));

         }
         // Se è un elemento esistente
         else {
            const timoneGeneraleFilter = idEpisode ? { _idEpisode: '==' + idEpisode } : { _idProgram: '==' + idProgram };
            const timoneGeneraleLink = 'https://tools.datatv.it/timone-generale?q=1' +
               `&order=${encodeURIComponent(JSON.stringify([[1, "asc"]]))}` +
               `&filters=${encodeURIComponent(JSON.stringify(timoneGeneraleFilter))}`;
            const storicoImportLink = 'https://tools.datatv.it/importPalinsestiStorico?ph=1' +
               `${idProgram ? `&c_idProgram=${idProgram}` : ''}` +
               `${idEpisode && idEpisode != -1 ? `&c_idEpisode=${idEpisode}` : ''}`;

            $pop = $(`
               <div class="ui wide popup modalschedadbPop" style="padding:0;">
                  <div class="ui small fluid vertical menu" style="box-shadow: none; border: none;">
                     <a class="item modifica"><i class="write icon"></i>Modifica ${tipo}</a>
                     <a class="item copyMyresResult"><i class="copy icon"></i>Copia ${tipo}</a>

                     ${tipo == 'Base' ? `
                        <a class="item creaStagione"><i class="plus circle icon"></i>Aggiungi Stagione</a>
                        <a class="item linkBaseToProgram"><i class="linkify icon"></i>Collega Programma</a>
                     ` : ''}
                     ${tipo == 'Programma' ? `
                        <a class="item creaEpisodio"><i class="plus circle icon"></i>Aggiungi Episodio</a>
                        <a class="item creaEpisodiMultipli"><i class="plus circle icon"></i>Aggiungi più Episodi</a>
                        <a class="item linkProgramToEpisode"><i class="linkify icon"></i>Collega Episodio</a>
                        <a class="item linkProgramToBase"><i class="linkify icon"></i>Collega a una Base</a>
                     ` : ''}
                     ${tipo == 'Episodio' ? `
                        <a class="item creaEpisodio"><i class="plus circle icon"></i>Aggiungi Episodio</a>
                        <a class="item creaEpisodiMultipli"><i class="plus circle icon"></i>Aggiungi più Episodi</a>
                        <a class="item linkEpisodeToProgram"><i class="linkify icon"></i>Collega a un Programma</a>
                     ` : ''}

                     <div class="item">
                        Collegamenti
                        <div class="menu">
                           <a href="${timoneGeneraleLink}" target="_blank" class="item"><i class="dharmachakra icon"></i></i>Cerca Timone Generale</a>
                           <a href="${storicoImportLink}" target="_blank" class="item"><i class="history icon"></i></i>Cerca Storico Import</a>
                        </div>
                     </div>

                     <a class="item" data-modaltrame="${idProgram},${idEpisode ? idEpisode : -1}"><i class="align justify icon"></i>Scheda Trame</a>
                     <a class="item" data-modalarchivio="${idProgram}"><i class="dropbox icon"></i></i>Archivio Immagini</a>
                  </div>
               </div>
               `).appendTo($('body'));
         }

         if (!$pop) return;

         // Choosed Data
         const choosedDataQuery = idEpisode && idEpisode != -1 && idEpisode != '[NEW]' ? { idEp: idEpisode }
            : idProgram && idProgram != '[NEW]' ? { idPr: idProgram }
               : null;
         const choosedData = await client.service('program-join').find({ query: choosedDataQuery })
            .then(r => r.results[0]).catch(e => null);


         // Items Functions
         $pop.on('click', '.modifica', function (e) {
            $target.popup('hide');
            modalSchedaDb.open(idProgram, idEpisode, null, null);
         })
         $pop.on('click', '.creaStagione', async function (e) {
            $target.popup('hide');
            let preFillData = await modalSchedaDb.getPreFillData($origin, 'Stagione');
            modalSchedaDb.open(idProgram, idEpisode, 'Stagione', preFillData);
         })
         $pop.on('click', '.creaEpisodio', async function (e) {
            $target.popup('hide');
            let preFillData = await modalSchedaDb.getPreFillData($origin, 'Episodio');
            modalSchedaDb.open(idProgram, idEpisode, 'Episodio', preFillData);
         })
         $pop.on('click', '.creaEpisodiMultipli', async function (e) {
            $target.popup('hide');
            let preFillData = await modalSchedaDb.getPreFillData($origin, 'Episodio');
            modalSchedaDb.creaEpisodiMultipli(idProgram, idEpisode, preFillData);
         })
         $pop.on('click', '.linkProgramToBase', function (e) {
            $target.popup('hide');
            modalSchedaDb.linkModal('linkProgramToBase', choosedData);
         })
         $pop.on('click', '.linkBaseToProgram', function (e) {
            $target.popup('hide');
            modalSchedaDb.linkModal('linkBaseToProgram', choosedData);
         })
         $pop.on('click', '.linkProgramToEpisode', function (e) {
            $target.popup('hide');
            modalSchedaDb.linkModal('linkProgramToEpisode', choosedData);
         })
         $pop.on('click', '.linkEpisodeToProgram', function (e) {
            $target.popup('hide');
            modalSchedaDb.linkModal('linkEpisodeToProgram', choosedData);
         })
         $pop.on('click', '.copyMyresResult', function (e) {
            let toCopy = $target.closest('.myRes.result').data();
            if (choosedData) toCopy = JSON.stringify(choosedData);
            else toCopy = 'Impossibile Copiare l\'elemento';
            myFunc.copyToClipboard(toCopy);
            $target.popup('hide');
         });
         $pop.on('click', '.creaNuovoInDb', async function (e) {
            let createData = await getCreateData($origin, _tempID);
            $target.popup('hide');
            // console.log({createData, $origin, _tempID})
            await modalSchedaDb.creaAllNewSatelliteDb(createData);
         })
         $pop.on('click', '.vediNuovoInDb', async function (e) {
            let createData = await getCreateData($origin, _tempID);
            $target.popup('hide');
            modalSchedaDb.open(idProgram, idEpisode, 'AllNew', null, createData);
         })

         // popup
         $target.popup({
            popup: $pop,
            silent: true,
            exclusive: false,
            // boundary: $container,
            // hideOnScroll: true,
            movePopup: false,
            position: 'bottom left',
            lastResort: 'top left',
            // distanceAway: -20,
            on: 'manual',
            onHidden: function () {
               $pop.remove();
               if (modalSchedaDb._origin) {
                  let $search = modalSchedaDb._origin.closest('.programSearch').find('input.prompt');
                  if ($search && $search.length) $search.focus();
               }
            },
            onHide: async function () {

            }
         }).popup('show');

         // hide on scroll
         let $scrollParent = getScrollParent($target);
         $scrollParent.on('scroll', function () {
            $target.popup('hide');
         });
      }

      function getScrollParent($node) {
         var overflowRegex = /(auto|scroll)/,
            position = $node.css('position'),
            excludeStaticParent = position === 'absolute',
            scrollParent = $node.parents().filter(function () {
               var parent = $(this);
               if (excludeStaticParent && parent.css('position') === 'static') {
                  return false;
               }
               var overflowState = parent.css(['overflow', 'overflowX', 'overflowY']);
               return (overflowRegex).test(overflowState.overflow + overflowState.overflowX + overflowState.overflowY);
            }).eq(0);

         return position === 'fixed' || !scrollParent.length ? $($node[0].ownerDocument || document) : scrollParent;
      }

      async function getCreateData($origin, _tempID) {
         let createData = modalSchedaDb._$modal ? modalSchedaDb._createData : null;

         console.log('[modalSchedaDb > getCreateData]', {$origin, _tempID});

         if (!createData && $origin.closest('#sheet_preparazione').length) {
            // console.log('[getCreateData] Not Found', $origin, _tempID);
            const dt = $('#sheet_preparazione').DataTable();
            const rowData = dt.row($origin.closest('tr')).data();
            createData = await rowData.newCreateData();
            modalSchedaDb._createData = createData;
            // console.log('[getCreateData] NEW!!!!!', createData);
         }

         if (!createData) {
            return null;
         }

         let activeDone = false;
         // Set active by cd._tempID == _tempID
         for (var i = createData.length - 1; i >= 0; i--) {
            if (!activeDone && createData[i] && createData[i]._tempID == _tempID) {
               createData[i].activeData = true;
               activeDone = true;
            } else if (createData[i]) {
               delete createData[i].activeData;
            }
         }

         // Set active by last element
         if (!activeDone) {
            for (var i = createData.length - 1; i >= 0; i--) {
               if (!activeDone && createData[i]) {
                  createData[i].activeData = true;
                  activeDone = true;
               } else if (createData[i]) {
                  delete createData[i].activeData;
               }
            }
         }

         console.log('[modalSchedaDb > getCreateData]', createData);
         return createData;
      }

      function isPermitted(){
         if (!myVars || !myVars.io)
            return false;

         let adminPermission = ['Admin', 'Supervisor'].includes(myVars.io.ruolo);
         let satellitePermission = myVars.io.area.indexOf('Satellite') > -1;
         let graficoPermission = myVars.io.ruolo.indexOf('Grafico') > -1;

         if (!adminPermission && !satellitePermission && !graficoPermission)
            return false;

         return true;
      }
   },

   // ------------------------------------------------------------------------------------------------------------ MODAL
   // ------------------------------------------------------------------------------------------------------------------
   open: async function (choosedIdPr, choosedIdEp, toCreate, preFill, createData) {
      $.fn.transition.settings.silent = true;

      return new Promise(async (resolve, reject) => {

         let mData = {
            choosedIdPr: choosedIdPr && choosedIdPr != -1 ? choosedIdPr : null,
            choosedIdEp: choosedIdEp && choosedIdEp != -1 ? choosedIdEp : null,
            toCreate: toCreate,
            preFill: preFill,
            createData: createData
         };

         // Se non ho scelto nessun idProgramma e non sto creando programmi singoli esco
         if (!mData.choosedIdPr && !['Programma', 'Base'].includes(mData.toCreate)) return;

         $('.ui.popup').remove();

         const $loader = $(`
            <div class="ui active inverted dimmer modalLoader">
               <div class="ui indeterminate text loader">Loading</div>
            </div>
         `).appendTo('body');

         // Crea Modal
         const $modal = modalSchedaDb.createModal(toCreate);
         modalSchedaDb._$modal = $modal;

         $modal.modal({
            autofocus: false,
            allowMultiple: true,
            transition: 'scale',
            onHidden: function () {
               $('body').css('margin-right', 0);
               modalSchedaDb._$modal = null;
               modalSchedaDb._origin = null;
               modalSchedaDb._createData = null;
               console.log('[modalSchedaDb] CLOSED');
               resolve('closed');
            },
            onHide: async function () {

            },
         }).modal('show');

         $modal.data('modalData', mData).addClass('.dataEl');

         // First render
         await modalSchedaDb.renderModal(mData.choosedIdPr, mData.choosedIdEp, mData.toCreate, mData.preFill, mData.createData);
         $loader.remove();

         // Show Prefill Data
         if (preFill && preFill.testoFullPrefill) {
            $modal.find('.formArea').prepend(`
               <div class="ui info message testoFullPrefill">
                  <div class="header">Origine creazione</div>
                  ${preFill.testoFullPrefill.replace(/\|/gi, '&bull;')}
               </div>
            `);
         }

         // Actions
         $modal.on('click', '.actions .modify', async function (e) {
            await modalSchedaDb.creaModificaSatelliteDb('modify');
         });

         $modal.on('click', '.actions .create', async function (e) {
            await modalSchedaDb.creaModificaSatelliteDb('create');
         });

         $modal.on('click', '.actions .createAllNew', async function (e) {
            await modalSchedaDb.creaAllNewSatelliteDb(createData);
         });

         // Se ho un origine
         if (modalSchedaDb._origin) {

            $modal.find('.actions').prepend(`
               <div class="ui grey button choose">Seleziona</div>
            `);

            $modal.on('click', '.actions .choose', async function (e) {
               let mData = $modal.data('modalData');

               if (mData.toCreate) {
                  myError.show(`Errore selezione ${mData.toCreate}`, 'Devi prima creare l\'elemento per poterlo selezionare.');
                  return;
               }

               // Choosed Item
               let idEpisode = mData.actEpis ? mData.actEpis.ID : null;
               let idProgram = mData.actProg ? mData.actProg.ID : null;
               let choosedItem = null;

               if (idEpisode)
                  choosedItem = await client.service('program-join').find({ query: { idEp: idEpisode } }).then(r => r.results[0]);
               else
                  choosedItem = await client.service('program-join').find({ query: { idPr: idProgram } }).then(r => r.results[0]);

               if (choosedItem) {
                  if (myPal !== 'undefined' && modalSchedaDb._origin.closest('#sheet_preparazione').length) {
                     let multiple = $('.multiRiga .checkbox').checkbox('is checked');
                     myPal.matchRows(modalSchedaDb._origin, choosedItem, multiple, 'scegli');
                  }
               }

               $modal.modal('hide');
            });

         }
      });
   },

   createModal: function (toCreate) {
      $('.modalSchedaDb').remove();

      let $modal = $(`
      <div class="ui ${toCreate == 'Base' || toCreate == 'Programma' ? '' : 'large'} modal modalSchedaDb" id="modalSchedaDb">
         <div class="header"></div>
         <div class="content"></div>

         <div class="actions">
            <div class="ui cancel red button">Chiudi</div>
         </div>
      </div>`).appendTo('body');

      $modal.find('.actions .cancel').on('click', function () {
         $modal.modal('hide');
      });
      return $modal;
   },

   renderModal: async function (idPr, idEp, toCreate, preFill, createData) {
      let $modal = modalSchedaDb._$modal;
      let mData = await modalSchedaDb.loadDbData(idPr, idEp, createData);
      let isStagione = toCreate == 'Stagione' || (mData.actData && mData.actData.program && mData.actData.program.idProgramBase && mData.actData.program.idProgramBase != mData.actData.program.idProgram) || (mData.actData && mData.actData.program && mData.actData.program.episodi);
      let isEpisodio = toCreate == 'Episodio' || idEp;

      console.log(`[modalSchedaDb] renderModal`);

      // Header
      let headerHtml = `<i class="database icon"></i> ${toCreate ? 'Crea' : ''} `;

      if (toCreate) {
         if (toCreate == 'Base') headerHtml += `<span class="a_orange">Base</span> e Prima <span class="a_blue">Stagione</span>`;
         else if (toCreate == 'Stagione') headerHtml += `<span class="a_blue">Stagione</span> nella Base:`;
         else if (toCreate == 'Episodio') headerHtml += `<span class="a_teal">Episodio</span> nella Stagione:`;
         else if (toCreate == 'Programma') headerHtml += `<span class="a_blue">Programma</span>`;
      }

      if (createData)
         headerHtml += myProgramSearch.getHtmlForHeader(createData[1], createData[2] ? createData[2] : null, false);

      else if (mData.actProg)
         headerHtml += myProgramSearch.getHtmlForHeader(mData.actProg, !toCreate && mData.actEpis ? mData.actEpis : false, false);
      $modal.find('.header').eq(0).html(headerHtml);

      // Content
      let $content = $modal.find('.content');
      $content.empty();

      // if (['Base', 'Programma'].includes(toCreate)) {
      //    $modal.removeClass('fullscreen large').addClass('large');
      //    $content.append(`
      //       <div class="ui grid">
      //          <div class="ui nine wide column formArea"></div>
      //          <div class="ui seven wide column tabsArea">
      //             <div class="ui small tabular menu tabsMenu"></div>
      //          </div>
      //       </div>`);

      //    await modalSchedaDb.myTabCast.init();

      // } else

      if (isEpisodio) {
         $modal.removeClass('fullscreen large').addClass('large');
         $content.append(`
            <div class="ui grid">
               <div class="ui seven wide column treeArea"></div>
               <div class="ui nine wide column formArea"></div>
            </div>`);

      } else {
         $modal.removeClass('fullscreen large').addClass('fullscreen');
         $content.append(`
            <div class="ui grid">
               <div class="ui five wide column treeArea"></div>
               <div class="ui six wide column formArea"></div>
               <div class="ui five wide  column tabsArea">
                  <div class="ui small tabular menu tabsMenu">
                     <div class="right menu">
                        ${mData.actData ? `
                        <a class="ui vertically fitted item" data-modaltrame="${mData.actData.program.idProgram},-1">
                           <i class="align justify icon"></i>Altre Trame
                        </a>
                        ` : ''}
                     </div>
                  </div>
               </div>
            </div>`);

         await modalSchedaDb.myTabCast.init();
      }

      // $modal.find('.column').css({
      //    'height': 'calc(100vh - 200px)',
      //    'overflow': 'hidden',
      //    'overflow-y': 'scroll'
      // });
      // $modal.find('.column:last-child').css({
      //    'overflow-y': 'auto'
      // });

      // Forms
      $modal.find('.form').remove();
      let $form = null;

      if (isEpisodio) {
         $form = $(`
      <div class="ui small form">
         <input type="hidden" name="toCreate" value="${toCreate ? toCreate : ''}">
         <input type="hidden" name="idProgram">
         <input type="hidden" name="idEpisodeToUpdate">

         <div class="required field">
            <label>Titolo</label>
            <input type="text" placeholder="Titolo" name="Title" class="titleInput">
         </div>

         <div class="field">
            <label>Titolo Originale</label>
            <input type="text" placeholder="Titolo Originale" name="OriginalTitle">
         </div>

         <div class="fields">
            <div class="eight wide field">
               <label>N° Episodio</label>
               <div class="ui action input">
                  <input type="text" placeholder="Episodio" name="EpisodeNumber" value="">
                  <div class="ui basic icon button formatEpisodeNumber" value="19 / / 18"><i class="code icon"></i></div>
               </div>
            </div>

            <div class="eight wide field">
               <label>TitleId Sky</label>
               <input type="text" placeholder="TitleId Sky" name="titleId" value="">
            </div>
         </div>

         <div class="fields">
            <div class="six wide field">
               <label>Turno</label>
               <input type="text" placeholder="Turno" name="Turn" value="">
            </div>

            <div class="five wide field">
               <label>Sede</label>
               <input type="text" placeholder="Sede" name="Location" value="">
            </div>

            <div class="five wide field">
               <label>TxDate</label>
               <input type="text" placeholder="TxDate" name="TxDate" value="">
            </div>
         </div>

         <div class="textArea field">
            <label>Descr. Generale</label>
            <textarea spellcheck="true" rows="4" data-max="200" name="Description"></textarea>
         </div>
         <div class="textArea field">
            <label>----</label>
            <textarea class="secondaryInput" spellcheck="true" rows="4" data-max="200" name="sinossi" style="background: #f9fafb;"></textarea>
         </div>

      </div>`);

      } else {
         $form = $(`
      <div class="ui small form">
         <input type="hidden" name="toCreate" value="${toCreate ? toCreate : ''}">
         <input type="hidden" name="idProgramBase">
         <input type="hidden" name="idProgramToUpdate">

         <div class="ui ticked labeled blue slider fullLevel" name="fullLevel"></div>

         <div class="required field">
            <label>Titolo</label>
            <input type="text" placeholder="Titolo" name="Title">
         </div>

         <div class="fields">
            <div class="ten wide field">
               <label>Titolo Originale</label>
               <input type="text" placeholder="Titolo Originale" name="OriginalTitle">
            </div>

            <div class="six wide field">
               <label>Nota</label>
               <input type="text" placeholder="Nota" name="nota" value="">
            </div>
         </div>

         <div class="fields">
            ${isStagione ? `
            <div class="four wide field required">
               <label>Stagione</label>
               <input type="text" placeholder="Stagione" name="SeriesNumber">
            </div>
            ` : ''}

            <div class="three wide field">
               <label>Anno I</label>
               <input type="text" placeholder="Anno" name="Year">
            </div>

            <div class="three wide field">
               <label>Anno F</label>
               <input type="text" placeholder="Anno" name="EndYear">
            </div>

            ${isStagione ? `
               <div class="three wide field">
                  <label>Tot. Episodi</label>
                  <input type="text" placeholder="Tot." name="totalEpisode">
               </div>
            ` : ''}

            <div class="three wide field">
               <label>Durata</label>
               <input type="number" placeholder="Duration" name="Duration" value="">
            </div>

         </div>

         <div class="fields">
            <div class="eight wide required field">
               <label>Categoria</label>
               <div class="ui fluid search clearable selection dropdown">
                  <input type="hidden" data-drop="category" name="IDCategory">
                  <i class="dropdown icon"></i>
                  <div class="default text">...</div>
                  <div class="menu"></div>
               </div>
            </div>

            <div class="four wide field">
               <label>TxDate</label>
               <input type="text" placeholder="TxDate" name="TxDate" value="">
            </div>

            <div class="four wide field">
               <label>TitleId Sky</label>
               <input type="text" placeholder="TitleId Sky" name="titleId" value="">
            </div>
         </div>

         <div class="fields">
            <div class="eight wide field">
               <label>Genere</label>
               <div class="ui fluid search clearable selection multiple dropdown">
                  <input type="hidden" data-drop="genre" name="IDGenre">
                  <i class="dropdown icon"></i>
                  <div class="default text">...</div>
                  <div class="menu"></div>
               </div>
            </div>

            <div class="eight wide field">
               <label>Contenuto</label>
               <div class="ui fluid search clearable selection multiple dropdown">
                  <input type="hidden" data-drop="content" name="IDContent">
                  <i class="dropdown icon"></i>
                  <div class="default text">...</div>
                  <div class="menu"></div>
               </div>
            </div>
         </div>

         <div class="fields">
            <div class="eight wide field">
               <label>Paesi di Produzione</label>
               <div class="ui fluid search clearable selection multiple dropdown">
                  <input type="hidden" data-drop="country" name="IDCountry">
                  <i class="dropdown icon"></i>
                  <div class="default text">...</div>
                  <div class="menu"></div>
               </div>
            </div>

            <div class="eight wide field">
               <label>Attributi</label>
               <div class="ui fluid search clearable selection multiple dropdown">
                  <input type="hidden" data-drop="attributeprogram" name="IDAttributeProgram">
                  <i class="dropdown icon"></i>
                  <div class="default text">...</div>
                  <div class="menu"></div>
               </div>
            </div>

         </div>

         <div class="textArea field">
            <label>Descr. Generale</label>
            <textarea spellcheck="true" rows="4" data-max="200" name="Description"></textarea>
         </div>
         <div class="textArea field">
            <label>Descr. Old</label>
            <textarea class="secondaryInput" spellcheck="true" rows="4" data-max="200" name="sinossi"></textarea>
         </div>

      </div>`);

      }

      $form.appendTo($modal.find('.formArea'));

      // Bottoni
      $modal.find('.actions .create, .actions .modify').remove();
      $(`
      ${toCreate == 'Base' ? `<div class="ui create green button">Crea Base e Prima Stagione In DB</div>`

            : toCreate == 'Programma' ? `<div class="ui create green button">Crea Programma In DB</div>`

               : toCreate == 'Stagione' ? `<div class="ui create green button">Crea Stagione In DB</div>`

               : toCreate == 'Episodio' ? `<div class="ui create green button">Crea Episodio In DB</div>`

                  : toCreate == 'AllNew' ? `<div class="ui createAllNew blue button">Crea Nuovi Programmi/Episodi</div>`

                     : !toCreate && mData.actEpis ? `<div class="ui modify blue button">Modifica Episodio</div>`

                        : !toCreate && mData.actProg ? `<div class="ui modify blue button">Modifica Programma</div>`

                           : ''}
   `).appendTo($modal.find('.actions'));

      // Dopo l'header la label di IMDb
      const $sourceLabels = $(`<div class="ui small labels" style="position:absolute; top: 20px; right: 20px;"></div>`).appendTo($modal);

      if (!isEpisodio) {
         const $copia = $(`<div class="ui label">Copia Dati</div>`).appendTo($sourceLabels);
         modalSchedaDb.copiaDati($copia);
      }

      if (!isEpisodio) {
         let $imdb = $(`<div class="ui label">IMDb<div class="detail imdbId">N.D.</div></div>`).appendTo($sourceLabels);
         modalSchedaDb.imdbLink($imdb);
      }

      // Form Elements
      $modal.find('.programSearch.imdb').search(myProgramSearch.getOpt('imdb'));
      await myFunc.initCommonDrops($modal.find('.dropdown'));
      myFunc.makeTextArea($modal.find('textarea'));

      // fulllevel slider
      $modal.find('[name="fullLevel"]').slider({
         min: 0,
         max: 10,
         start: 0,
         step: 1
      })

      // IDContent Dropdown
      $modal.find('[name="IDContent"]').closest('.dropdown').dropdown({
         onHide: function () {
            let aVal = $(this).dropdown('get value').split(',');
            let isAdult = aVal.find(v => v == 11 || v == 6 || v == 7);

            if (isAdult && !aVal.find(v => v == 4)) {
               aVal.unshift(4);
               $(this).dropdown('set exactly', aVal);
            }
         }
      });

      // EpisodeNumber Totale episodi
      $modal.find('.formatEpisodeNumber').on('click', function () {
         let $input = $(this).prev('input');
         let val = $input.val();
         if (val && !isNaN(val)) {
            if (mData.actProg && mData.actProg.totalEpisode) {
               $input.val(`${val.trim()} / / ${mData.actProg.totalEpisode}`);
            } else {
               $input.val(`${val.trim()} / / }`);
            }
         }
      });

      // Title Id test:41654569
      $modal.find('[name="titleId"]').on('change', async function () {
         let val = $(this).val();
         let sameTitleId = await client.service('program-join').find({ query: { titleId: val } })
            .then(r => r.results && r.results.length ? r.results[0] : null)

         if (sameTitleId) {
            $(this).closest('.field').addClass('error');
            // console.log(sameTitleId)
            let overwrite = await myFunc.confirmModal('Sky TitleId già presente', `
               <div class="ui error message">
                  <div class="header">Vuoi continuare con il TitleId che hai inserito?</div>
                  Il <b>TitleId</b> verrà RIMOSSO dall'elemento attualmente associato:
               </div>
               <table class="ui basic celled table"><tbody><tr>
                  <td class="collapsing"><div class="ui big purple label">TI ${sameTitleId.titleId}</div></td>
                  <td class="pad0">${sameTitleId.restype == 'base' ? myProgramSearch.getHtmlForBase(sameTitleId)
                  : sameTitleId.restype == 'program' ? myProgramSearch.getHtmlForProgram(sameTitleId)
                     : sameTitleId.restype == 'episode' ? myProgramSearch.getHtmlForEpisode(sameTitleId)
                        : ''}
                  </td>
               </tr></tbody></table>
            `);

            // Per sicurezza elimino l'errore solo alla risposta
            if (overwrite == 'ok') {
               $(this).closest('.field').removeClass('error');
            } else if (overwrite == 'ko') {
               $(this).closest('.field').removeClass('error');
               $(this).val('');
            }
         }

      });

      // Fill Form
      await modalSchedaDb.fillForm(idPr, idEp, toCreate, preFill, createData);

      myFunc.makeTitleInput($modal.find('.titleInput'))
      if (mData.actProg)
         await modalSchedaDb.renderProgramTree(mData.actProg.ID, createData);
   },

   myTabCast: {

      init: async function () {
         $('[data-tab="cast"]').remove();
         $('.personSearch').remove();
         $('.castTable').remove();

         let $modal = modalSchedaDb._$modal;
         let $area = $modal.find('.tabsArea');
         let $menu = $modal.find('.tabsMenu');
         let $tabItem = $(`<a class="ui tab item" data-tab="cast">Cast</a>`).prependTo($menu);
         let $tab = $(`<div class="ui tab active" data-tab="cast"></div>`).appendTo($area);

         $tabItem.tab();

         let $personSearch = $(`
            <div class="ui search personSearch">
               <div class="ui fluid action left icon input" style="margin-bottom: 12px;">
                  <input class="prompt" type="text" placeholder="Cerca Persone...">
                  <i class="search link icon"></i>
                  <button class="ui basic icon button removePerson" title="Rimuovi persone selezionate">
                     <i class="trash alternate red icon"></i>
                  </button>
               </div>
            </div>`).appendTo($tab);

         let $table = $(`<table class="ui small compact celled single line fixed table castTable"></table>`).appendTo($tab);
         let columns = defineColumns();
         //($table, data, columns, rowId, len, plain, preFilter, urlState, rowReorder)
         this.dt = await myDt.createTable($table, [], columns, 'ID', -1, true, false, false, { dataSrc: 'priority' });
         this.$table = $table;

         // this.dt.on('row-reordered', function (e, diff, edit) {
         //    console.log('order changed', e, diff, edit);
         //    // modalSchedaDb.myTabCast.dt.rows().invalidate().draw();
         // });

         // Funzioni
         $table.on('click', 'td.changeRole', function () {
            changeRole($(this));
         });

         $table.on('dblclick', 'tbody>tr', function (e) {
            modalSchedaDb.myTabCast.modify($(this));
         });

         $personSearch.search(myProgramSearch.getOpt('person'));
         $personSearch.on('choosed', function (e, choosed) {
            modalSchedaDb.myTabCast.add(e.detail);
         });
         $personSearch.find('.removePerson').on('click', function (e, choosed) {
            let $trs = $table.find('tr.selected');
            modalSchedaDb.myTabCast.remove($trs);
         });

         function defineColumns() {
            let cols = [];

            let myOrder = [
               'priority',
               'ID',
               'Name',
               'Surname',
               'Ruolo',
            ];

            // Opzioni Colonne
            myOrder.forEach(n => {
               let col = { visible: false };

               if (n == 'Name') {
                  col = {
                     title: `Nome`,
                     className: 'dtvselect',
                     orderable: true,
                     render: function (data, type, row, meta) {
                        let noMatch = row.matched_by == 'nothing';
                        let $tr = $($table.DataTable().row(meta.row).node());

                        if (noMatch) {
                           $tr.addClass('error');
                           $tr.attr('title', 'Personaggio non trovato in Database!.\nDoppio Click per confermare/modificare.');
                        } else {
                           $tr.removeClass('error');
                           $tr.attr('title', '');
                        }

                        let html = data ? data : '';
                        return html;
                     }
                  };
               }

               if (n == 'Surname') {
                  col = {
                     title: `Cognome`,
                     className: 'dtvselect',
                     orderable: true,
                     render: function (data, type, row, meta) {
                        let html = data ? data : '';
                        return html;
                     }
                  };
               }

               if (n == 'Ruolo') {
                  col = {
                     title: `Ruolo`,
                     className: 'changeRole',
                     width: '90px',
                     orderable: true,
                     render: function (data, type, row, meta) {
                        let $tr = $($table.DataTable().row(meta.row).node());
                        if (!data) $tr.addClass('warning');

                        let html = data ? data : '';
                        return html;
                     }
                  };
               }

               if (n == 'priority') {
                  col = {
                     title: ``,
                     className: 'center aligned clipCircularLabel reorder',
                     width: '15px',
                     orderable: true,
                     type: 'numeric',
                     render: function (data, type, row, meta) {
                        let html = ``;

                        if (type == 'sort') html = data;
                        else html = `<span class="ui circular label">${data}</span>`;

                        return html;
                     }
                  };
               }

               col.data = n;
               col.name = n;
               cols.push(col);

            });

            return { cols: cols };
         }

         function changeRole($td) {
            $td.html(`<span class="myVal">${$td.text()}</span>`);
            $td.find('.myVal').hide();

            $('.roleDrop').remove();

            let $drop = $(`
                  <div class="ui search fluid top right pointing dropdown roleDrop">
                     <input type="hidden" name="status">
                     <i class="dropdown icon"></i>
                     <div class="default text">Ruolo...</div>
                     <div class="menu">
                        <div class="item" data-value="1">Attore</div>
                        <div class="item" data-value="2">Regista</div>
                        <div class="item" data-value="3">Stuntman</div>
                        <div class="item" data-value="4">Conduttore</div>
                        <div class="item" data-value="5">Doppiatore</div>
                        <div class="item" data-value="6">Giornalista</div>
                        <div class="item" data-value="7">Autore</div>
                        <div class="item" data-value="8">Showgirl</div>
                        <div class="item" data-value="9">Coreografo</div>
                        <div class="item" data-value="10">Curatore</div>
                        <div class="item" data-value="11">Direttore</div>
                        <div class="item" data-value="12">Commentatore</div>
                        <div class="item" data-value="16">Creatore</div>
                        <div class="item" data-value="15">Produttore</div>
                     </div>
                  </div>`).appendTo($td);

            $td.css('overflow', 'visible');
            $td.find('.item').css('box-sizing', 'border-box');

            $drop.dropdown({
               fullTextSearch: true,
               onChange: async function (value, text, $choice) {
                  console.log('change', text)
                  let $toChange = $table.find('tr.selected').length ? $table.find('tr.selected') : $td.closest('tr');
                  let rows = modalSchedaDb.myTabCast.dt.rows($toChange);

                  rows.every(function (rowIdx, tableLoop, rowLoop) {
                     let rData = this.data();
                     rData.Ruolo = text;
                     rData.RuoloID = value;
                  });

                  rows.invalidate();
                  rows.draw('page');
                  $td.css('overflow', 'hidden');
               },
               onHide: function (param) {
                  $drop.hide();
                  $td.find('.myVal').show();
                  $td.css('overflow', 'hidden');
               }

            })
               .dropdown('show');
         }
      },

      modify: function ($tr) {
         let $modal = modalSchedaDb._$modal;
         let rowData = this.dt.row($tr).data();

         return new Promise(function (resolve, reject) {
            $modal.find('.modPerson').remove();
            let $modPerson = $(`
               <div class="ui tiny modal modPerson">

                  <div class="header">
                     <i class="user icon"></i>
                        <div class="ui horizontal label black" style="margin: 3px 0 0 0;">${rowData.ID ? 'PER ' + rowData.ID : '[NEW]'}</div>
                        <span class="ui grey text opacita4">/</span>
                        ${rowData.Name} ${rowData.Surname}
                  </div>

                  <div class="content">
                     ${!rowData.ID ? `
                        <div class="ui warning message">
                           <div class="header">
                              Nuovo Personaggio!
                           </div>
                           Questo personaggio non è stato trovato in Database.
                           <br>Conferma Nome e Cognome per salvarlo in seguito insieme alla scheda.
                        </div>` : ''}
                     <div class="ui form">
                        <div class="ui fields">
                           <div class="eight wide field">
                              <label>Nome</label>
                              <input type="text" name="name" value="${rowData.Name}">
                           </div>
                           <div class="eight wide field">
                              <label>Cognome</label>
                              <input type="text" name="surname" value="${rowData.Surname}">
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="actions">
                     <div class="ui cancel button">Annulla</div>
                     <div class="ui ok green button">Conferma</div>
                  </div>
               </div>`).appendTo($modal);

            $modPerson.modal({
               autofocus: false,
               allowMultiple: true,
               transition: 'scale',
               onApprove: function () {
                  if (!rowData.ID) rowData.matched_by = 'user';
                  rowData.Name = $modPerson.find('input[name="name"]').val();
                  rowData.Surname = $modPerson.find('input[name="surname"]').val();
                  modalSchedaDb.myTabCast.dt.row($tr).invalidate().draw();
               },
               onDeny: function () {
               },
               onHidden: function () {
                  $modPerson.remove();
               },
               onVisible: function () {
                  $modPerson.find('input[name="name"]').focus();
               },
            }).modal('show');
         });

         console.log('modify', rowData);

      },

      add: function (persona) {
         this.dt.row.add(persona);

         // Riassegno l'ordine
         this.dt.rows().rows().every(function (rowIdx, tableLoop, rowLoop) {
            this.data().priority = rowIdx + 1;
         });

         this.dt.rows().invalidate().draw();
      },

      remove: function ($trs) {
         if (!this.dt) return;
         this.dt.rows($trs).remove();

         // Riassegno l'ordine
         this.dt.rows().rows().every(function (rowIdx, tableLoop, rowLoop) {
            this.data().priority = rowIdx + 1;
         });

         this.dt.rows().invalidate().draw();
      }
   },

   // ------------------------------------------------------------------------------------------------------------- DATA
   // ------------------------------------------------------------------------------------------------------------------
   loadDbData: async function (idPr, idEp, createData) {
      let $modal = modalSchedaDb._$modal;
      let mData = $modal.data('modalData');
      mData.actData = null;

      if (!idPr && !idEp) {
         // se non ho id non carico niente
         return mData;

      } else if (idEp == '[NEW]' || idPr == '[NEW]') {
         // Se ho una tripletta da caricare
         mData.actData = {
            idProgram: createData[1].ID,
            program: createData[1],
            episode: createData[2]
         };

      } else if (idEp) {
         // Se non ho l'id programma lo cerco
         if (!idPr) idPr = await client.service('program-join').find({ query: { idEp: idEp } }).then(r => r.results[0].idProgram);
         mData.actData = await client.service('program-join').get(idPr, { query: { idEpisode: idEp } });

      } else if (idPr) {
         mData.actData = await client.service('program-join').get(idPr, { query: { add: ['persons'] } });

      }

      mData.actProg = mData.actData.program;
      mData.actEpis = idEp ? mData.actData.episode : null;

      if (idPr && idPr != '[NEW]')
         mData.extraIds = await client.service('extra-ids').find({ query: { idProgram: idPr } }).then(r => r && r.data.length ? r.data[0] : null);

      // console.log(`[modalSchedaDb] loadDbData mData:`, mData);
      // console.log(`[modalSchedaDb] loadDbData actProg:`, mData.actProg);
      // console.log(`[modalSchedaDb] loadDbData actEpis:`, mData.actEpis);
      return mData;
   },

   getPreFillData: function ($target, tipo) {
      let preFillData = {};
      let isDataTable = $target.closest('.dataTable').length;

      if (isDataTable) {
         let dt = $target.closest('.dataTable').DataTable();
         let $tr = $target.closest('tr');
         let row = dt.row($tr);
         let rowData = row.data();
         let match = false; //rowData._match;
         console.log('[getPreFillData] rowData:', rowData);

         if(match){
            preFillData = getPreFillFromImportPalinsesti(match);

         } else {
            preFillData = getPreFillFromGeneric(rowData, tipo);

         }

         if (rowData.testoFull) {
            preFillData.testoFullPrefill = rowData.testoFull;
         }
      }

      console.log('[getPreFillData] preFillData:', preFillData);
      return preFillData;

      function getPreFillFromImportPalinsesti(match){
         let data = {};
         return data;
      }

      function getPreFillFromGeneric(rowData, tipo){
         let data = {
            IDCategory: rowData.IDCategory || rowData.IDCategory == 0 ? rowData.IDCategory : '',
            IDGenre: rowData.IDGenre || rowData.IDGenre == 0 ? rowData.IDGenre : '',
            IDContent: rowData.idParental || rowData.idParental == 0 ? rowData.idParental : '',
            IDCountry: rowData.IDCountry ? rowData.IDCountry : '',
         };

         data.Title = rowData.testoProgramma ? rowData.testoProgramma
            : rowData.Title ? rowData.Title
               : rowData.Request_titolo ? rowData.Request_titolo
                  : '';

         data.OriginalTitle = rowData.OriginalTitle ? rowData.OriginalTitle
            : '';

         data.SeriesNumber = rowData.SeriesNumber ? rowData.SeriesNumber
            : rowData.Request_num_stagione && !isNaN(rowData.Request_num_stagione) ? rowData.Request_num_stagione
               : '';

         data.nota = typeof myPal !== 'undefined' && myPal.channel && myPal.channel.Name == 'Comcast' ? 'Comcast'
               : rowData.Request_Priorita == 4 ? 'Comcast'
                  : '';

         data.Year = rowData.Year ? rowData.Year
            : rowData.Request_anno_prod ? rowData.Request_anno_prod
               : '';

         data.Description = rowData.Description ? rowData.Description.replace(/\,/gi, ', ').replace(/\s+/gi, ' ')
            : rowData.Description && isNaN(rowData.Description) ? rowData.Description.replace(/\,/gi, ', ').replace(/\s+/gi, ' ')
               : '',

         data.IDAttributeProgram = rowData.IDAttributeProgram || rowData.IDAttributeProgram == 0 ? rowData.IDAttributeProgram
            : 1;

         if (tipo == 'Episodio') {

            data.EpisodeNumber = rowData.episodioNumerico ? rowData.episodioNumerico
               : rowData.Request_episodio && !isNaN(rowData.Request_episodio) ? rowData.Request_episodio
                  : '';

            data.Turn = rowData.Turn ? rowData.Turn : '';
            data.TxDate = rowData.TxDate ? rowData.TxDate : '';
            data.Location = rowData.Location ? rowData.Location : '';

            data.Title = rowData.testoEpisodio ? rowData.testoEpisodio
               : rowData.Request_titolo_puntata ? rowData.Request_titolo_puntata
                  : data.EpisodeNumber ? `Ep. ${data.EpisodeNumber}`
                     : '';
         }

         return data;
      }
   },

   fillForm: async function (idPr, idEp, toCreate, preFill, createData, color) {
      let $modal = modalSchedaDb._$modal;
      let mData = $modal.data('modalData');
      let toFill = {};

      console.log(`[modalSchedaDb] fillForm Start`, idPr, idEp, toCreate, preFill, createData, color);

      if (createData) { // Dati da createData
         const activeData = createData.find(d => d && d.activeData);
         if (activeData) toFill = activeData;
         color = true;

      } else if (preFill) { // Dati da preFill
         toFill = preFill;

         if (toCreate == 'Episodio') {
            // La stagione di appartenenza
            toFill.idProgram = mData.actProg.ID;

         } else if (toCreate) {
            // la Base di appartenenza
            if (mData.actData && mData.actData.programserie && mData.actData.programserie.ID) {
               toFill.idProgramBase = mData.actData.programserie.ID;
            }
         }
      } else if (mData.actData) { // Dati da Database
         if (toCreate == 'Episodio') {

            // prendo l'ultimo episodio
            let lastEpisode = await client.service('program-join').get(mData.actProg.ID, { query: { add: 'episodes' } })
               .then(r => r.episodes && r.episodes.length ? r.episodes[r.episodes.length - 1] : null);

            if (lastEpisode) {
               if (lastEpisode.episodioNumerico && !isNaN(lastEpisode.episodioNumerico)) {
                  let newn = lastEpisode.episodioNumerico + 1;
                  toFill.Title = `Ep. ${newn}`;
                  toFill.EpisodeNumber = newn;
               }

            } else {
               toFill.Title = 'Episodio 1';
               toFill.EpisodeNumber = 1;
            }

            // La stagione di appartenenza
            toFill.idProgram = mData.actProg.ID;

         } else if (toCreate == 'Stagione') {

            // prendo l'ultima stagione
            let lastSeason = await client.service('program-join').get(mData.actProg.ID, { query: { add: 'seasons' } })
               .then(r => r.seasons && r.seasons.length ? r.seasons[r.seasons.length - 1] : null);

            if (lastSeason) {
               toFill = lastSeason;
               if (lastSeason.SeriesNumber && !isNaN(lastSeason.SeriesNumber)) {
                  let newn = lastSeason.SeriesNumber + 1;
                  toFill.SeriesNumber = newn;
                  toFill.Title = `Stagione ${newn}`;
               } else {
                  toFill.Title = '';
               }

            } else {
               toFill = Object.assign({}, mData.actProg);
               toFill.SeriesNumber = 1;
               toFill.Title = 'Stagione 1';
            }

            // Riaggiungo i campi con nomi diversi
            toFill.IDGenre = toFill.Genres ? toFill.Genres.map(g => g.id) : [];
            toFill.IDCountry = toFill.Country ? toFill.Country : [];
            toFill.IDContent = toFill.parental ? toFill.parental.map(g => g.id) : [];
            toFill.IDAttributeProgram = toFill.attributeProgram ? toFill.attributeProgram.map(g => g.id) : [];

            // sposto Description dentro sinossi
            toFill.sinossi = toFill.Description;
            toFill.Description = '';

            // tolgo i campi
            toFill.OriginalTitle = '';
            toFill.titleId = '';

            // la Base di appartenenza
            if (mData.actData.programserie && mData.actData.programserie.ID) {
               toFill.idProgramBase = mData.actData.programserie.ID;
            }

         } else if (idEp && !toCreate) {

            // Riempio con i dati dell'episodio per l'update
            toFill = mData.actEpis;

            // Setto idEpisodeToUpdate
            toFill.idEpisodeToUpdate = idEp;

            // La stagione di appartenenza
            toFill.idProgram = mData.actProg.ID;

         } else if (idPr && !toCreate) {

            // Riempio con i dati del programma  per l'update
            toFill = mData.actProg;

            // Setto idProgramToUpdate
            toFill.idProgramToUpdate = idPr;

            // Se ha una base
            if (mData.actData.programserie && mData.actData.programserie.ID) {
               toFill.idProgramBase = mData.actData.programserie.ID;
            }

            // Riaggiungo i campi con nomi diversi
            toFill.IDGenre = mData.actProg.Genres ? mData.actProg.Genres.map(g => g.id) : [];
            toFill.IDCountry = mData.actProg.Country ? mData.actProg.Country : [];
            toFill.IDContent = mData.actProg.parental ? mData.actProg.parental.map(g => g.id) : [];
            toFill.IDAttributeProgram = mData.actProg.attributeProgram ? mData.actProg.attributeProgram.map(g => g.id) : [];
         }
      }

      // IMDb ID
      if (mData.extraIds && mData.extraIds.imDbId) {
         $modal.find('.detail.imdbId').text(mData.extraIds.imDbId);
         $modal.find('.detail.imdbId').closest('.label').addClass('yellow');
      } else {
         $modal.find('.detail.imdbId').text('N.D.');
         $modal.find('.detail.imdbId').closest('.label').removeClass('yellow');
      }

      // Fill Forms
      $modal.find(`.field`).removeClass('warning');
      for (let k of Object.keys(toFill)) {
         let val = toFill[k];
         let $input = $modal.find(`[name="${k}"]`);
         if (!val || !$input.length) continue;

         console.log('set', k, val, $input[0]);
         let $field = $input.closest('.field');
         if (!$input.val()) $input.data('undo', val);
         if (color) $field.addClass('warning');

         if ($input.hasClass('slider')) {
            $input.slider('set value', val);

         } else if ($input.parent('.dropdown').length) {
            let $drop = $input.parent('.dropdown');

            // Aggiungo Adulti se VM
            if (k == 'IDContent' && !Array.isArray(val)) val = [val];
            if (k == 'IDContent' && val.find(v => v == 11 || v == 6 || v == 7) && !val.find(v => v == 4)) {
               val.unshift(4);
            }

            if ($drop.data('dropOption').fatherDrop) {
               // Sa ha un father drop aspetto che siano caricati i valori
               $drop.one('dropValsLoaded', function () {
                  $drop.dropdown('set exactly', val);
               });
               // $drop.dropdown('set exactly', val);

            } else {
               $drop.dropdown('set exactly', val);
            }


         } else {
            $input.val(val);

         }
      }
      $modal.find('textarea').trigger('keyup');

      // Fill TabCast
      if (this.myTabCast && this.myTabCast.dt) {
         let persons = toFill.persons && toFill.persons.length ? toFill.persons : mData.actData && mData.actData.persons ? mData.actData.persons : [];
         console.log('set cast', persons);
         modalSchedaDb.myTabCast.remove();
         persons.forEach((persona, i) => {
            if (!persona.ID) persona.ID = _.uniqueId('person');
            modalSchedaDb.myTabCast.add({ ...persona, priority: i + 1 });
         });
      }
      return 'Fine Fill Form';

      console.log(`[modalSchedaDb] fillForm End`, toFill);

      async function dropValsLoaded($drop){
         return new Promise(resolve => {
            $drop.one('dropValsLoaded', () => {
               return resolve()
            });
         });
      }
   },

   // ---------------------------------------------------------------------------------------------------------- UTILITY
   // ------------------------------------------------------------------------------------------------------------------
   renderProgramTree: async function (idPr, createData) {
      let $modal = modalSchedaDb._$modal;
      let $dest = $modal.find('.treeArea');
      // L'elemento Attivo
      let activeEl = null;

      if (!idPr && !createData) return;

      if (!createData) {
         activeEl = {};
         $modal.find('[name]').toArray().map((i) => {
            const name = $(i).attr('name');
            const val = $(i).val();
            if (val && val != 'undefined') activeEl[name] = val;
         });
      }

      // printProgramTree: function ($dest, idProgram, activeEl, levelToPrint, createData)
      myProgramSearch.printProgramTree($dest, idPr, activeEl, 0, createData);

      // Aggiorno l'albero on change del form
      $modal.find('input, textArea').on('change keyup mouseup', function (ev) {
         console.log('[modalSchedaDb] updateProgramTree', ev);
         // non aggiorno per i dropdown
         // if ($(this).closest('.ui.dropdown').length) return;

         if (!createData) {
            // L'elemento Attivo
            activeEl = {};
            $modal.find('[name]').toArray().map((i) => {
               const name = $(i).attr('name');
               const val = $(i).val();
               if (val && val != 'undefined') activeEl[name] = val;
            });
         }

         if (createData) {
            let dataIndex = createData.findIndex(e => e && e.activeData);
            $modal.find('[name]').toArray().map((i) => {
               const name = $(i).attr('name');
               const val = $(i).val();
               if (val && val != 'undefined') createData[dataIndex][name] = val;
            });
         }

         myProgramSearch.printProgramTree($dest, idPr, activeEl, 0, createData);
      });
   },
   copiaDati: async function ($el) {
      let $modal = modalSchedaDb._$modal;
      let mData = $modal.data('modalData');
      let type = 'program';

      let $dropMenu = $(`
         <div class="menu" style="width: 475px;">
         <div class="header">Copia Dati Programma</div>

         <div class="ui search programSearch" style="margin: 0 14px 14px 14px;" data-program_search="${type}">
            <div class="ui fluid left icon input">
               <i class="search icon link"></i>
               <input class="prompt" type="text" name="programSearch" placeholder="Cerca Programma...">
            </div>
         </div>

         <div class="divider"></div>
         <div class="item cancellaDati"><i class="trash alternate icon"></i>Cancella Dati</div >

         </div>
      `).appendTo($el);

      $el.addClass('ui dropdown');

      // Init Drop
      $el.dropdown({
         onShow: function () {
            $('.ui.dropdown').dropdown('hide');

            // utilizzo una classe tempFocus perchè il dropdown inizia onHide prima del focus
            const $prompt = $(this).find('.prompt');
            setTimeout(function () {
               $prompt.addClass('tempFocus');
               $prompt[0].focus();
               $prompt.removeClass('tempFocus');
            }, 100);
         },
         onHide: function () {
            const $prompt = $(this).find('.prompt');
            if ($prompt.hasClass('tempFocus') || $prompt.is(':focus')) return false;
         }
      });

      // Search
      $el.find('.prompt').closest('.programSearch').search(myProgramSearch.getOpt(type));

      // Choosed
      $el.find('.programSearch').on('choosed', function (e) {
         const result = e.originalEvent.detail;
         if (result && result.idProgram) {
            copiaDati(result.idProgram);
         }
      });

      $el.find('.cancellaDati').on('click', function (e) {
         cancellaDati();
      });

      async function copiaDati(idProgram) {
         const data = await client.service('program-join').get(idProgram, { query: { add: ['persons'] } });
         const preFill = data.program;

         preFill.persons = data.persons;
         preFill.IDGenre = preFill.Genres ? preFill.Genres.map(g => g.id) : [];
         preFill.IDCountry = preFill.Country ? preFill.Country : [];
         preFill.IDContent = preFill.parental ? preFill.parental.map(g => g.id) : [];
         preFill.IDAttributeProgram = preFill.attributeProgram ? preFill.attributeProgram.map(g => g.id) : [];

         delete preFill.titleId;
         delete preFill.nota;

         await modalSchedaDb.fillForm(null, null, null, preFill, null, 'green');
      }

      async function cancellaDati() {
         let $modal = modalSchedaDb._$modal;

         modalSchedaDb._$modal.find('.field').each((i, el) => {
            let isDrop = $(el).find('.ui.dropdown').length;

            if (!isDrop) $(el).find('input, textarea').val('');
            else $(el).find('.ui.dropdown').dropdown('clear');

            modalSchedaDb.myTabCast.remove();
         })

      }
   },

   imdbLink: async function ($el) {
      let $modal = modalSchedaDb._$modal;
      let mData = $modal.data('modalData');
      let type = 'imdb';

      let $dropMenu = $(`
         <div class="menu" style="width: 475px;">
         <div class="header">Collega IMDb</div>

         <div class="ui search programSearch" style="margin: 0 14px 14px 14px;" data-program_search="${type}">
            <div class="ui fluid left icon input">
               <i class="search icon link"></i>
               <input class="prompt" type="text" name="cerca${type}" placeholder="Cerca IMDb...">
            </div>
         </div>

         <div class="hasId divider"></div>
         <div class="hasId item ricarica"><i class="recycle icon"></i>Ricarica Dati</div >
         <div class="hasId item scollega"><i class="unlink trash icon"></i>Scollega IMDb</div>

         </div>
      `).appendTo($el);

      $el.addClass('ui dropdown');

      // Init Drop
      $el.dropdown({
         onShow: function () {
            $('.ui.dropdown').dropdown('hide');

            let hasId = mData.extraIds && mData.extraIds.imDbId;
            if (hasId) $el.find('.hasId').show();
            else $el.find('.hasId').hide();

            // utilizzo una classe tempFocus perchè il dropdown inizia onHide prima del focus
            const $prompt = $(this).find('.prompt');
            setTimeout(function () {
               $prompt.addClass('tempFocus');
               $prompt[0].focus();
               $prompt.removeClass('tempFocus');
            }, 100);
         },
         onHide: function () {
            const $prompt = $(this).find('.prompt');
            if ($prompt.hasClass('tempFocus') || $prompt.is(':focus')) return false;
         }
      });

      // Search
      $el.find('.prompt').closest('.programSearch').search(myProgramSearch.getOpt(type));

      // Choosed
      $el.find('.programSearch').on('choosed', function (e) {
         const result = e.originalEvent.detail;
         if (result && result.id) {
            collegaImdbModal(result.id)
         }
      });

      // Scollega
      $el.find('.scollega').on('click', function () {
         scollegaImdb();
      });

      // ricarica
      $el.find('.ricarica').on('click', function () {
         fillImdbData(mData.extraIds.imDbId);
      });

      async function collegaImdbModal(id) {

         let userConfirm = await new Promise((resolve, reject) => {
            $('.modal.imdbMod').remove();
            let $imdbMod = $(`
               <div class="ui tiny modal imdbMod">
                  <div class="header"><i class="imdb icon"></i>Collega IMDb</div>
                  <div class="content">
                     <div class="ui form">
                        <div class="field">
                           <div class="ui toggle checkbox">
                              <input type="checkbox" name="iassocia">
                              <label>Collega questo Programma a <b>IMDb</b></label>
                           </div>
                        </div>
                        <!--<div class="field">
                           <div class="ui toggle checkbox">
                              <input type="checkbox" name="iriempi">
                              <label>Riempi solo i <b>Campi Vuoti</b> con i dati di <b>IMDb</b></label>
                           </div>
                        </div>-->
                        <div class="field">
                           <div class="ui toggle checkbox">
                              <input type="checkbox" name="isovrascrivi">
                              <label>Riempi <b>Tutti i Campi</b> con i dati di <b>IMDb</b></br>(Sovrascrive i campi esistenti)</label>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="actions">
                     <div class="ui cancel red small button"><i class="remove icon"></i>Annulla</div>
                     <div class="ui ok green small button"><i class="check icon"></i>Conferma</div>
                  </div>
               </div>
            `).prependTo(modalSchedaDb._$modal);

            $imdbMod.modal({
               allowMultiple: true,
               onApprove: async function () {
                  let collega = $imdbMod.find('[name="iassocia"]')[0].checked;
                  let sovrascrivi = $imdbMod.find('[name="isovrascrivi"]')[0].checked;

                  // Collega
                  if (collega) {
                     await collegaImdb(id);
                  }

                  // Sovrascrivi
                  if (sovrascrivi) {
                     await fillImdbData(id);
                  }

                  $imdbMod.modal('hide');
                  resolve(true);
               },
               onDeny: function () {
                  resolve(false);
               },
            }).modal('show');
         });
      }

      async function collegaImdb(id) {
         // Recupero Extra-ids
         mData.extraIds = await client.service('apis').get(id, { query: { request: 'ids' } });
         mData.extraIds.toSave = true;

         // Aggiungo i riferimenti nella modal
         if (mData.extraIds && mData.extraIds.imDbId) {
            $modal.find('.detail.imdbId').text(mData.extraIds.imDbId);
            $modal.find('.detail.imdbId').closest('.label').addClass('yellow');
         }
      }

      async function scollegaImdb() {
         mData.extraIds = { toDel: true };

         // Rimuovo i riferimenti nella modal
         $modal.find('.detail.imdbId').text('N.D.');
         $modal.find('.detail.imdbId').closest('.label').removeClass('yellow');

         if (!mData.actData.idProgram) return;
      }

      async function fillImdbData(id) {
         const $loader = $(`
            <div class="ui active inverted dimmer modalLoader">
               <div class="ui indeterminate text loader">Caricamento Dati IMDb in corso...</div>
            </div>
         `).appendTo('body');

         let apiData = await client.service('apis').get(id, { query: { imdbOnly: true } });
         console.log('apis apiData', apiData);

         let toFill = {};
         if (apiData.parsedImdbMain) {
            let ida = apiData.parsedImdbMain;

            toFill.Title = ida.Title ? ida.Title : '';
            toFill.OriginalTitle = ida.OriginalTitle ? ida.OriginalTitle : ida.Title ? ida.Title : '';
            toFill.SeriesNumber = '';
            toFill.Year = ida.Year ? ida.Year : '';
            toFill.EndYear = '';
            toFill.totalEpisode = '';
            toFill.Duration = ida.Duration ? ida.Duration : '';
            toFill.IDAttributeProgram = [(ida.color == 'Black and White' ? 5 : 1)];
            toFill.sinossi = ida.trama ? 'I: ' + ida.trama : '';

            let catGen = await parseImdbCatGen(ida.type, ida.genres);
            toFill.IDCategory = catGen.cat ? catGen.cat : '';
            toFill.IDGenre = catGen.gen ? catGen.gen : [];

            let countries = await myVars.country.load();
            toFill.IDCountry = ida.countryList ? countries.filter(c => ida.countryList.includes(c.ISO2CharCode)).map(c => c.ID) : [];

            let parental = await parseImdbParental(ida.parental);
            toFill.IDContent = parental ? [parental] : [];

            // Cast
            toFill.persons = [];
            let todo = [
               ['Directed by', 'Regista', 4],
               ['Series Cast|Cast in credits', 'Attore', 8],
               ['Produced by', 'Produttore', 0],
               ['Writing Credits', 'Autore', 0],
            ]

            // Se è telefilm
            if (toFill.IDCategory == 12) {
               todo = [
                  ['Series Cast|Cast in credits', 'Attore', 8],
                  ['Writing Credits', 'Autore', 2],
               ]
            }

            for (const person of apiData.parsedImdbCast) {
               if (new RegExp(todo.filter(r => r[2]).map(r => r[0]).join('|'), 'gi').test(person.role)) {
                  let dbPerson = await client.service('program-join').find({ query: { what: 'person', IMDBID: person.id } })
                     .then(r => r.results[0]);

                  if (dbPerson) {
                     dbPerson.matched_by = 'Id';

                  } else if (!dbPerson) {
                     dbPerson = await client.service('program-join').find({ query: { what: 'person', name: person.name } })
                        .then(r => r.results[0]);
                     if (dbPerson) dbPerson.matched_by = 'Name';

                  }

                  if (!dbPerson) {
                     dbPerson = {
                        matched_by: 'nothing',
                        restype: 'person',
                        ID: null,
                        IMDBID: person.id,
                        Name: person.name.split(' ')[0],
                        Surname: person.name.split(' ')[1],
                     }
                  }

                  let currTodo = todo.find(r => r[2] && new RegExp(r[0], 'gi').test(person.role));
                  if (currTodo[1] == 'Autore') {
                     if (/created by/gi.test(person.credit)) dbPerson.Ruolo = currTodo[1];
                  } else dbPerson.Ruolo = currTodo[1];

                  if (!dbPerson.IMDBID) dbPerson.IMDBID = person.id;
                  toFill.persons.push(dbPerson);
                  currTodo[2]--;

                  // Se non ho niente da fare esco
                  if (todo.filter(r => r[2]).length == 0) break;

               }
            }

            console.log(toFill)
         }

         await modalSchedaDb.fillForm(null, null, null, toFill, null, true);
         $loader.remove();
         return toFill;

         // Funzioni
         async function parseImdbParental(par) {
            // let content = await myVars.content.load();
            let tor = false;
            let imdbConv = [
               [9, 'T'],
               [10, '6+'],
               [6, 'VM14'],
               [6, 'VM16'],
               [7, 'VM18'],
            ]

            let conv = imdbConv.find(e => e[1] == par);
            if (conv) tor = conv[0];
            return tor;
         }
         async function parseImdbCatGen(type, genList) {
            // let categories = await myVars.categories.load();
             // let genres = await myVars.genre.load();

             console.log('parseImdbCatGen', type, genList)
            if (!genList) return { cat: false, gen: [] };


            let tor = {
               cat: false,
               gen: [],
            };

            let imdbConv = [
               [1, 'Movie'],
               [12, 'TV Series'],
               [12, 'TV Mini Series'],
               [15, 'TV Special'],
               [20, 'Music Video'],
               [12, 'TV Episode'],
               [23, 'TV Movie'],
            ]

            let conv = imdbConv.find(e => e[1] == type);
            if (conv) tor.cat = conv[0];

            let imdbGen = [
               [3, 'Action'],
               [58, 'Adult'],
               [2, 'Adventure'],
               [150, 'Animation'],
               [4, 'Biography'],
               [6, 'Comedy'],
               [18, 'Crime'],
               [8, 'Documentary'],
               [9, 'Drama'],
               [0, 'Family'],
               [310, 'Fantasy'],
               [17, 'Film Noir'],
               [0, 'Game Show'],
               [56, 'History'],
               [15, 'Horror'],
               [16, 'Musical'],
               [16, 'Music'],
               [21, 'Mystery'],
               [0, 'News'],
               [0, 'Reality-TV'],
               [19, 'Romance'],
               [11, 'Sci-Fi'],
               [0, 'Short'],
               [0, 'Sport'],
               [0, 'Talk-Show'],
               [21, 'Thriller'],
               [118, 'War'],
               [22, 'Western'],
            ]

            genList.forEach(g => {
               let gen = imdbGen.find(e => e[1] == g);
               if (gen) tor.gen.push(gen[0]);
            })

            return tor;
         }
      }
   },

   // ------------------------------------------------------------------------------------------------------------ OTHER
   // ------------------------------------------------------------------------------------------------------------------
   creaEpisodiMultipli: async function (idPr, idEp, preFillData) {
      console.log({ idPr, idEp, preFillData });

      // se il search è dentro un popup lo metto da parte per poi triggerare il choose
      $('.ui.popup').remove();
      const data = await client.service('program-join').get(idPr, { query: { add: ['episodes'] } });
      const lastEp = data.episodes.map(e => e.episodioNumerico).sort((a, b) => b - a)[0];
      const $modal = createModalEp(data, lastEp);

      // Apro la modal
      return new Promise(async (resolve, reject) => {
         $modal.modal({
            autofocus: false,
            allowMultiple: true,
            transition: 'scale',
            onHidden: function () {
               $('body').css('margin-right', 0);
               resolve('closed');
            },
            onHide: async function () {

            },
         }).modal('show');
      });

      function createModalEp(data, lastEp) {
         $('.modalEpMultipli').remove();

         let $modal = $(`
            <div class="ui small modal modalEpMultipli" id="modalEpMultipli">
               <div class="header">${myProgramSearch.getHtmlForHeader(data.program)}</div>

               <div class="scrolling content">

                  <form class="ui form">
                     <h4 class="ui block header">Crea Episodi Multipli</h4>
                     <div class="two fields">
                        <div class="field">
                           <label>Quanti episodi vuoi creare?</label>
                           <input type="number" name="quanti" placeholder="Numero Episodi" value="1">
                        </div>
                        <div class="field">
                           <label>Parti dal numero:</label>
                           <input type="Number" name="inizio" placeholder="Primo Episodio" value="${lastEp ? lastEp + 1 : 1}">
                        </div>
                     </div>

                     <div class="ui small message">
                        <div class="header">
                           Numeri e Titoli Episodio
                        </div>
                        <p>È possibile incollare nel campo sottostante una serie di numeri e titoli episodio delimitati da tab <i class="blue long arrow alternate right icon"></i> o da pipe <b class="a_blue">|</b>.
                        Esempi:

                        <ul>
                           <li>2 <b class="a_blue">|</b> titolo episosio <b class="a_blue">|</b> 3 <b class="a_blue">|</b> titolo episodio</li>
                           <li>2 <i class="blue long arrow alternate right icon"></i>titolo episosio <i class="blue long arrow alternate right icon"></i>3 <i class="blue long arrow alternate right icon"></i>titolo episodio</li>
                           <li>titolo episosio <b class="a_blue">|</b> titolo episodio</li>
                           <li>titolo episosio <i class="blue long arrow alternate right icon"></i> titolo episodio</li>
                        </ul>

                     </div>

                     <div class="field">
                        <label>Numeri e Titoli Episodio (opzionale)</label>
                        <textarea rows="4" name="titoli"></textarea>
                     </div>

                     <table class="ui small compact celled table anteprima">
                        <thead>
                           <tr>
                              <th colspan="2">Anteprima</th>
                           </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <td colspan="2">Inserire dei dati</td>
                           </tr>
                        </tbody>
                     </table>

                  </form>

               </div>

               <div class="actions">
                  <div class="ui cancel red button">Chiudi</div>
                  <div class="ui create green button">Crea Episodi in DB</div>
               </div>
            </div>`).appendTo('body');

         $modal.find('input, textarea').on('change keyup', function (ev) {
            const $tbody = $modal.find('.anteprima tbody');
            const titoli = $modal.find('[name="titoli"]').val();
            const titlesArr = getTitlesArr(titoli);
            const titlesArrHasNumbers = Array.isArray(titlesArr) && titlesArr.filter(e => Array.isArray(e)).length;

            let quanti = $modal.find('[name="quanti"]').val();
            let inizio = $modal.find('[name="inizio"]').val();
            quanti = !isNaN(quanti) ? parseInt(quanti) : null;
            inizio = !isNaN(inizio) ? parseInt(inizio) : null;

            if (titlesArrHasNumbers && !inizio) {
               const doubleArr = titlesArr.filter(e => Array.isArray(e));
               inizio = doubleArr[doubleArr.length - 1][0] + 1;
            }

            // epsToCreate
            let epsToCreate = [];
            if (quanti && inizio && titlesArr && Array.isArray(titlesArr)) {
               let cont = inizio;

               for (let i = 0; i < quanti; i++) {

                  let curr = '';
                  if (Array.isArray(titlesArr[i]))
                     curr = titlesArr[i][0];

                  else if (titlesArrHasNumbers) {
                     curr = cont;
                     cont++;

                  } else
                     curr = inizio + i;

                  epsToCreate.push({
                     toCreate: "Episodio",
                     idProgram: data.idProgram,
                     idEpisodeToUpdate: null,
                     Title: Array.isArray(titlesArr[i]) ? titlesArr[i][1] : titlesArr[i] ? titlesArr[i] : `Ep. ${curr}`,
                     OriginalTitle: null,
                     EpisodeNumber: curr,
                     titleId: null,
                     Turn: null,
                     Location: null,
                     TxDate: null,
                     Description: null,
                     sinossi: null
                  });
               }
            }

            console.log(epsToCreate);
            $modal.data('epsToCreate', epsToCreate);

            // HTML
            let html = '';
            if (!quanti) html = `<tr><td colspan="2">Specificare un numero di episodi</td></tr>`
            else if (!inizio) html = `<tr><td colspan="2">Specificare da quale episodio partire</td></tr>`
            else if (titlesArr && !Array.isArray(titlesArr)) html = `<tr><td colspan="2">${titlesArr}</td></tr>`
            else html = epsToCreate.map(e => `
               <tr>
                  <td class="collapsing">${e.EpisodeNumber}</td>
                  <td>${e.Title}</td>
               </tr>
            `).join('');

            $tbody.html(html);
         });

         $modal.find('.actions .cancel').on('click', function () {
            $modal.modal('hide');
         });

         $modal.find('input, textarea').trigger('change');

         // Crea Episodi
         $modal.find('.create').on('click', async function () {
            const epsToCreate = $modal.data('epsToCreate');
            const confirm = await myFunc.confirmModal('Vuoi Creare gli episodi?', `Vuoi creare ${epsToCreate.length} Episodi nel Database?`);

            if (confirm == 'ok') {
               myFunc.elLoader(true, $('.modal'), 'Creazione Episodi...');
               console.log('[creaEpisodiMultipli]', epsToCreate);
               const results = [];
               for (const ep of epsToCreate) {
                  const res = await client.service('episode').create(ep).then(r => r[0]).catch(e => e);
                  results.push(res);
               }
               console.log('[creaEpisodiMultipli] Results', results);
               myFunc.elLoader(false);
               $modal.modal('hide');
               myFunc.affermativeModal('Creazione Completata', `Sono stati creati ${results.length} Episodi`);
            }
         });

         function getTitlesArr(titoli) {
            if (!titoli) return [];

            let separator = /\|/gi.test(titoli) ? '\\||\\n|\\r' : /\t/gi.test(titoli) ? '\\t|\\n|\\r' : /\n|\r/gi.test(titoli) ? '\\n|\\r' : null;
            console.log({ separator })
            if (!separator) return 'Separatore non trovato';

            let titlesArr = titoli.split(new RegExp(separator, 'gi'))
               .filter(e => e)
               .map(e =>
                  e.trim().replace(/\s+/gi, ' ')
               );

            if (!titlesArr.length) return 'Titoli non trovati';

            // Doppio Array se ci sono i numeri episodio
            titlesArr = titlesArr.map((t, i) => {
               let tor = t;

               if (t && titlesArr[i + 1] && /^\d{1,}$/.test(t) && !/^\d{1,}$/.test(titlesArr[i + 1])) {
                  tor = [parseInt(t), titlesArr[1 + i]];
                  titlesArr[1 + i] = null;
               }

               return tor;
            }).filter(t => t);


            // let arrNumbers = titlesArr.filter(e => !isNaN(e));
            // if (arrNumbers.length == titlesArr.length / 2) {
            //    let doubleArr = arrNumbers.map((n, i) => [n, titlesArr[i * 2 + 1]]);
            //    titlesArr = doubleArr.filter(e => e[1]);
            // }

            return titlesArr;
         }




         return $modal;
      }
   },

   linkModal: function (op, data) {
      console.log('[linkModal]', op, data)
      $('#linkModal').remove();
      let header = '';
      let text = '';
      let to = '';

      if (op == 'linkProgramToBase') {
         header = `Collega un <span class="a_blue">Programma</span>
            a una <span class="a_orange">Base</span>`;

         text = `<div class="ui horizontal divider" style="margin-top: 0;">Collega il Programma</div>
            <div class="ui segment pad0">${myProgramSearch.getHtmlForSat(data)}</div>`;

         to = 'alla Base';

         filterSearch = 'base';

      } else if (op == 'linkBaseToProgram') {
         header = `Collega a una <span class="a_orange">Base</span>
            un <span class="a_blue">Programma</span>`;

         text = `<div class="ui horizontal divider" style="margin-top: 0;">Collega alla Base</div>
            <div class="ui segment pad0">${myProgramSearch.getHtmlForSat(data)}</div>`;

         to = 'il Programma';

         filterSearch = 'programma';

      } else if (op == 'linkProgramToEpisode') {
         header = `Collega a un <span class="a_blue">Programma</span>
            una <span class="a_teal">Episodio</span>`;

         text = `<div class="ui horizontal divider" style="margin-top: 0;">Collega al Programma</div>
            <div class="ui segment pad0">${myProgramSearch.getHtmlForSat(data)}</div>`;

         to = `l'Episodio`;

         filterSearch = 'episodio';

      } else if (op == 'linkEpisodeToProgram') {
         header = `Collega un <span class="a_teal">Episodio</span>
            a un <span class="a_blue">Programma</span>`;

         text = `<div class="ui horizontal divider" style="margin-top: 0;">Collega l'Episodio</div>
            <div class="ui segment pad0">${myProgramSearch.getHtmlForSat(data)}</div>`;

         to = 'al Programma';

         filterSearch = 'programma';

      }

      const $modal = $(`
         <div class="ui small modal" id="linkModal">
         <div class="header"><i class="linkify icon"></i>${header}</div>

         <div class="content">
            <div class="ui warning message">
               <div class="header">Attenzione!</div>
               Un <b>Programma</b> può essere collegato ad una sola <b>Base</b> e un <b>Episodio</b> ad un solo <b>Programma</b>.</br>
               Se ad esempio colleghi un Programma già collegato ad una Base verrà rimosso dalla vecchia Base per essere aggiunto alla nuova.
            </div>
            ${text}
            <div class="ui horizontal divider">${to}</div>
            <div class="ui small search programSearch focus" data-program_search="program" id="searchLink">
               <div class="ui fluid left icon input">
               <input type="text" name="myName" placeholder="Cerca in DB..." class="prompt" autocomplete="off">
               <i class="database icon"></i>
               </div>
            </div>
            <div class="choosed ui segment pad0">
               <div class="ui placeholder mar14">
                  <div class="image header">
                     <div class="line"></div>
                     <div class="line"></div>
                  </div>
               </div>
            </div>
         </div>

         <div class="actions">
            <div class="ui red button cancel">Annulla</div>
            <div class="ui disabled green button ok">Collega ${to}</i>
         </div>
         </div>
      `).appendTo('body');

      // Modal
      $modal.modal({
         autofocus: false,
         allowMultiple: true,
         transition: 'scale',
      }).modal('show');

      if (filterSearch == 'episodio')
         $modal.find('#searchLink').search(myProgramSearch.getOpt('programEpisode', false, false, '--' + filterSearch));
      else
         $modal.find('#searchLink').search(myProgramSearch.getOpt('program', false, false, '--' + filterSearch));

      $modal.find('#searchLink').on('choosed', async function (e) {
         let choosed = e.detail;
         let idProgramBase = null;
         let idProgramSeries = null;
         let idProgram = null;
         let idEpisode = null;

         if (op == 'linkProgramToBase') {
            idProgramSeries = data.idProgram;
            idProgramBase = choosed.idProgram;
            $modal.find('.choosed').html(myProgramSearch.getHtmlForSat(choosed));

         } else if (op == 'linkBaseToProgram') {
            idProgramBase = data.idProgram;
            idProgramSeries = choosed.idProgram;
            $modal.find('.choosed').html(myProgramSearch.getHtmlForSat(choosed));

         } else if (op == 'linkProgramToEpisode') {
            idProgram = data.ifProgram;
            idEpisode = choosed.idEpisode;
            $modal.find('.choosed').html(myProgramSearch.getHtmlForSat(choosed));

         } else if (op == 'linkEpisodeToProgram') {
            idEpisode = data.idEpisode;
            idProgram = choosed.idProgram;
            $modal.find('.choosed').html(myProgramSearch.getHtmlForSat(choosed));
         }

         console.log('[linkModal] Data:',`idProgramBase: ${idProgramBase}`, `idProgramSeries: ${idProgramSeries}`, `idProgram: ${idProgram}`, `idEpisode: ${idEpisode}`);
         $modal.data('idProgramBase', idProgramBase);
         $modal.data('idProgramSeries', idProgramSeries);
         $modal.data('idProgram', idProgram);
         $modal.data('idEpisode', idEpisode);

         $modal.find('.button.ok').removeClass('disabled');
      });

      $modal.find('.button.ok').on('click', async function (e) {

         let idProgramBase = $modal.data('idProgramBase');
         let idProgramSeries = $modal.data('idProgramSeries');
         let idProgram = $modal.data('idProgram');
         let idEpisode = $modal.data('idEpisode');

         let tor = '';

         if (idProgram || idEpisode) {
            tor = await client.service('satellite-functions')
               .create({ op: 'linkEpisode', idProgram: idProgram, idEpisode: idEpisode });

         } else if (idProgramBase || idProgramSeries) {
            tor = await client.service('satellite-functions')
               .create({ op: 'linkProgram', idProgramBase: idProgramBase, idProgramSeries: idProgramSeries });

         }

         console.log('[linkModal] Done!', tor);

         // Aggiorno le cose attive
         $('[data-program_search]').search('clear cache');
         $('.treeArea').data('programTreeData', null);
         $modal.modal('hide');

         // Se era aperta la finestra di edit di satellite la riapro
         if ($('.modalSchedaDb').length) {
            if (idEpisode) modalSchedaDb.renderModal(null, idEpisode);
            else if (idProgram) modalSchedaDb.renderModal(idProgram);
            else if (idProgramSeries) modalSchedaDb.renderModal(idProgramSeries);
         }
      });
   },

   // ------------------------------------------------------------------------------------------------------------- SAVE
   // ------------------------------------------------------------------------------------------------------------------
   checkScheda: function () {
      let $modal = modalSchedaDb._$modal;
      // Errore Se manca qualcosa
      $modal.find('.required.field').removeClass('error');
      $modal.find('.required.field').each((i, field) => {
         const $field = $(field);
         const val = $field.find('[name]').val();
         if (!val) $field.addClass('error');
      });
      if ($modal.find('.field.error:not(.textArea)').length) return 'ko';
      else return 'ok';
   },

   creaModificaSatelliteDb: async function (modifyOrCreate) {
      let $modal = modalSchedaDb._$modal;
      let mData = $modal.data('modalData');
      let castData = modalSchedaDb.myTabCast.dt ? modalSchedaDb.myTabCast.dt.data().toArray().filter(c => c.matched_by != 'nothing' && c.Ruolo) : null;

      // I Dati da salvare
      let tor = {};
      if (castData) tor.castData = castData;
      $modal.find('[name]').toArray().forEach((i) => {
         if ($(i).closest('.disabled').length) return;
         const k = $(i).attr('name');
         const v = $(i).hasClass('slider') ? $(i).slider('get value') : $(i).val();

         if (k == 'programSearch' || k == 'cercaimdb') return;
         tor[k] = v;
      });

      console.log(`[modalSchedaDb.creaModificaSatelliteDb] Parametri ${modifyOrCreate == 'modify' ? `Modifica` : `Creazione`}:`, tor);

      // Controlli
      if (!modifyOrCreate)
         throw new Error(`[modalSchedaDb.creaModificaSatelliteDb] Operazione Mancante!`, modifyOrCreate, tor);

      else if (modalSchedaDb.checkScheda() == 'ko')
         throw new Error(`[modalSchedaDb.creaModificaSatelliteDb] Dati non corretti!`, modifyOrCreate, tor);

      else if (modifyOrCreate == 'modify' && !(tor.idEpisodeToUpdate || tor.idProgramToUpdate))
         throw new Error(`[modalSchedaDb.creaModificaSatelliteDb] ID da modificare mancante!`, modifyOrCreate, tor);

      else if (modifyOrCreate == 'create' && !tor.toCreate)
         throw new Error(`[modalSchedaDb.creaModificaSatelliteDb] Specifica Creazione mancante!`, modifyOrCreate, tor);

      // Conferma Operazione
      let confirm = await myFunc.confirmModal(
         `Conferma ${modifyOrCreate == 'modify' ? `Modifica` : `Creazione`}`,
         `<div style="font-size: 15px;"> Vuoi
            ${modifyOrCreate == 'modify' ? `
               modificare
               ${tor.idEpisodeToUpdate ? `l'episodio con idEpisode: <b class="a_teal">${tor.idEpisodeToUpdate}</b>` : `il programma con idProgram: <b class="a_blue">${tor.idProgramToUpdate}</b>`}
            ` : `
               creare ${tor.toCreate == 'Base' ? `la <b class="a_orange">Base</b> e la Prima <b class="a_blue">Stagione</b>`
            : tor.toCreate == 'Programma' ? `il <b class="a_blue">Programma</b>`
               : tor.toCreate == 'Stagione' ? `la <b class="a_blue">Stagione</b>`
                  : tor.toCreate == 'Episodio' ? `l'<b class="a_teal">Episodio</b>`
                     : ''}
            `}
            ${tor.Title}?
         </div>`,
         'confirmCreate'
      );
      if (confirm != 'ok') return;

      // Salvataggio
      const $loader = $(`
            <div class="ui active inverted dimmer modalLoader">
               <div class="ui indeterminate text loader">Operazione in corso...</div>
            </div>
         `).prependTo($modal);

      // Eseguo l'operazione richiesta
      console.log(`[modalSchedaDb] Inizio ${modifyOrCreate}`, tor);
      let results = null;

      if (modifyOrCreate == 'create' && tor.toCreate == 'Episodio') {
         results = await client.service('episode').create(tor);

      } else if (modifyOrCreate == 'create') {
         results = await client.service('program').create(tor);

      } else if (modifyOrCreate == 'modify' && tor.idEpisodeToUpdate) {
         results = await client.service('episode').update(tor.idEpisodeToUpdate, tor);

      } else if (modifyOrCreate == 'modify' && tor.idProgramToUpdate) {
         results = await client.service('program').update(tor.idProgramToUpdate, tor);

      }
      console.log(`[modalSchedaDb] Fine ${modifyOrCreate}`, tor, results);

      // Salvo o rimuovo il collegamento a imdb
      if (tor.toCreate != 'Episodio' || tor.idProgramToUpdate) {
         if (mData.extraIds && mData.extraIds.imDbId && mData.extraIds.toSave) {
            mData.extraIds = await client.service('apis').create({}, { query: { what: 'extra-ids', idProgram: results[0].ID, imdbId: mData.extraIds.imDbId } })
               .then(r => r && r.data.length ? r.data[0] : null);

         } else if (mData.extraIds && mData.extraIds.toDel) {
            await client.service('extra-ids').remove(null, { query: { idProgram: results[0].ID } });

         }
      }

      // Report Operazione
      if (results && results.length) {
         $loader.remove();
         // 14/03/2021 Rimossa la conferma per velocizzare le operazioni
         // await myFunc.affermativeModal(
         //    `${modifyOrCreate == 'modify' ? `Modifica` : `Creazione`}`,
         //    `<div style="font-size: 15px;">
         //    Hai
         //    ${modifyOrCreate == 'modify' ? `
         //       modificato
         //       ${tor.idEpisodeToUpdate ? `l'episodio con idEpisode: <b class="a_teal">${results[0].ID}</b>` : `il programma con idProgram: <b class="a_blue">${results[0].ID}</b>`}
         //    ` : `
         //       creato ${tor.toCreate == 'Base' ? `la <b class="a_orange">Base</b> con idProgram: <b class="a_orange">${results[0].ID}</b> e la Prima <b class="a_blue">Stagione</b> con idProgram: <b class="a_blue">${results[1].ID}</b>`
         //       : tor.toCreate == 'Programma' ? `il <b class="a_blue">Programma</b> con idProgram: <b class="a_blue">${results[0].ID}</b>`
         //          : tor.toCreate == 'Stagione' ? `la <b class="a_blue">Stagione</b> con idProgram: <b class="a_blue">${results[0].ID}</b>`
         //             : tor.toCreate == 'Episodio' ? `l'<b class="a_teal">Episodio</b> con idEpisode: <b class="a_teal">${results[0].ID}</b>`
         //                : ''}
         //    `}
         //    <br> ${results[0].Title}
         // </div>`,
         //    'confirmCreate'
         // );

         // Cancello i dati del tree per fargli caricare quelli aggiornati
         $modal.data('modalData').toCreate = false;
         $modal.find('.treeArea').data('programTreeData', null);

         // Ricarico la scheda aggiornata
         if (tor.toCreate == 'Episodio' || tor.idEpisodeToUpdate) {
            modalSchedaDb.renderModal(null, results[0].ID);
         } else {
            modalSchedaDb.renderModal(results[0].ID);
         }
      }
   },

   creaAllNewSatelliteDb: async function (createData, rData) {
      console.log('[creaAllNewSatelliteDb]', { createData, rData });
      const $modal = modalSchedaDb._$modal;
      const manualOp = !rData && modalSchedaDb._origin.closest('#sheet_preparazione').length;

      if (!createData && rData && rData.newCreateData) {
         createData = await rData.newCreateData();
      }

      if ($modal) {
         $loader = $(`
            <div class="ui active inverted dimmer modalLoader">
               <div class="ui indeterminate text loader">Operazione in corso...</div>
            </div>
         `).prependTo($modal);
      }

      // Operazione
      const results = await client.service('import-palinsesti-functions').get('creaAllNewSatelliteDb', { query: { createData: createData } })
         .catch(e => { console.log(e); return null; });
      console.log('[creaAllNewSatelliteDb]', results);

      // Aggiorno import palinsesti (invalidate solo se manuale)
      if (results && myPal !== 'undefined') {

         // Prendo la rData dell'operazione manuale
         if (manualOp) {
            let rowId = modalSchedaDb._origin.closest('tr').attr('id');
            let row = myPal.dtPreparazione.row('#' + rowId);
            rData = row.data();
         }

         const newMatch = await myProgramSearch.getItemFromCreateData(results);
         rData._matchType = 'Provider Id';
         rData._match = newMatch;
         rData._hystorySaved = false;
         rData._dbResult = '';
         rData._ricontrolloMatch = '';
         rData._nonSalvareStorico = '';
         myPal.manageOtherData(rData);

         if (manualOp) {
            let rowId = modalSchedaDb._origin.closest('tr').attr('id');
            let row = myPal.dtPreparazione.row('#' + rowId);
            row.invalidate();
            row.deselect();
            myPal.updateInterface();
         }
      }

      if ($modal) {
         $loader.remove();
         $modal.modal('hide');
      }

      return results;
   }
}