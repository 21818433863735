module.exports = {
   error: function ($table, err) {
      // console.log( err );
      myFunc.elLoader(false);

      let tit = 'Errore caricamento dati';
      let msg = ' Si è verificato un errore durante il reperimento dei dati.';

      if (err.timeout) {
         tit = 'Timeout caricamento dati';
         msg = 'Il caricamento dei dati ha impiegato troppo tempo prova a restringere la ricerca con il <b>filtro DATA</b>.';

      } else {
         tit = err.name ? err.name : 'Errore';
         msg = err.message ? err.message : 'Non Definito';
      }

      $(`
      <div class="ui error message tableError">
        <i class="large warning sign red icon" style="margin-left: -10px;"></i>
        <b>` + tit + `</b> ` + msg + `</b>.
      </div>
    `).insertBefore($table);
   },

   createTable: function ($table, data, columns, rowId, len, plain, preFilter, urlState, rowReorder) {
      const serverSide = typeof data === 'string';

      // STANDARD TABLE SETTINGS
      const dtSettings = {
         rowId: rowId,
         pageLength: len ? parseInt(len) : (!serverSide && data.length < 101 ? 100 : 25),
         deferLoading: 1,
         deferRender: true,
         autoWidth: false,
         rowReorder: rowReorder && rowReorder.dataSrc ? rowReorder : rowReorder ? { dataSrc: rowId, update: false } : false,
         preDrawCallback: function (settings) { },
         drawCallback: function (settings) {
            $table.find('.infoPop').popup({ hoverable: true, lastResort: true });
            $('.ui.popup').popup('hide all');
            if (!plain || plain == 'noBorder') {
               myDt.printFilters($table);
            }

            if (urlState) {
               const state = myDt.getState($table);
               console.log('Salvo lo stato della tabella', state);
               history.pushState(false, false, state);
            }
         },

         select: {
            style: 'multi+shift',
            blurable: true,
            selector: '.dtvselect, .dtvselect-checkbox',
         },

         language: {
            'decimal': '',
            'emptyTable': 'Nessun risultato disponibile',
            'info': 'Risultati da _START_ a _END_ di _TOTAL_',
            'infoEmpty': 'Risultati da 0 a 0 di 0',
            'infoFiltered': '',
            'infoPostFix': '',
            'thousands': ',',
            'lengthMenu': 'Visualizza _MENU_ risultati',
            'loadingRecords': 'Caricamento...',
            'processing': 'Elaborazione...',
            'search': 'Cerca:',
            'zeroRecords': 'Nessun risultato corrispondente alla ricerca',
            'paginate': {
               'first': 'Prima',
               'last': 'Ultima',
               'next': 'Successiva',
               'previous': 'Precedente',
            },
         },
         dom: plain == true ? `<'dt-table' tr>`
            : plain == 'noBorder' ? `
               <'dt-table'
                  <'beforeDt' <'myPagination' p>>
                  <'dtContainer' tr>
               >`
               : `
               <'ui stackable padded grid tableGrid'
               <'beforeDt'>
               <'row dt-table'
                     <'sixteen wide column dtContainer' tr>
                  >
                  <'row'
                     <'seven wide column'i>
                     <'right aligned nine wide column'p>
                  >
               >`,
      };

      if (typeof columns[0] == 'string' || !columns) {
         const keys = columns ? [...columns] : Object.keys(data[0]);

         columns = { cols: [] };
         keys.forEach((k) => {
            columns.cols.push({
               title: k,
               data: k,
               className: 'collapsing',
               createdCell: function (td, cellData, rowData, row, col) {
                  if (cellData == 0 || cellData === false) $(td).addClass('red');
               },
            });
         });
      }


      dtSettings.columns = columns.cols;

      // COLREORDER
      if (columns && columns.order) {
         dtSettings.colReorder = {
            enable: false,
            order: columns.order,
         };
      }

      // SERVERSIDE
      if (serverSide) {
         dtSettings.serverSide = true;
         dtSettings.ajax = {
            'url': data,
            'type': 'POST',
            'headers': { 'Authorization': 'Bearer ' + client.settings.accessToken },
            'data': {},
            'error': function (xhr, error, thrown) {
               myDt.error($table, xhr.responseJSON);
            },
         };
      } else {
         dtSettings.data = data;
      }

      // GENERA LA TABELLA CON I SETTINGS DEFINITI
      const dt = $table.DataTable(dtSettings);
      if (preFilter) $table.data('preFilter', preFilter);

      if (serverSide) {
         $table.on('preXhr.dt', function (e, settings, data) {
            console.log(data)
            console.time('Query Time');
            if (!plain) myFunc.elLoader(true, $('body'), 'Loading Table...');
            const lengthVal = $('[name="dtLength"]').length > 0 && $('[name="dtLength"]').val() ? $('[name="dtLength"]').val() : false;
            const groupVal = $('[name="dtGroup"]').length > 0 && $('[name="dtGroup"]')[0].checked ? true : false;

            data.length = lengthVal ? parseInt(lengthVal) : 25;
            dt.page.len(data.length);
            data.grouped = groupVal;
            data.preFilter = $table.data('preFilter') ? $table.data('preFilter') : '';

         }).on('xhr.dt', function (e, settings, processing) {
            console.timeEnd('Query Time');
            $table.prevAll('.tableError').remove();
            myFunc.elLoader(false);
         });
      }

      // Popup alla creazione
      $table.find('.infoPop').popup({ hoverable: true, lastResort: true });

      // Listener per l'invio nei campi filter
      $('body').on('keypress', '.filterPop input', function (e) {
         if (e.which == 13) {
            myDt.applyFilters($table);
            $('.filterTh > .filter').popup('hide');
         }
      });

      return dt;
   },

   // FILTERS FUNCTIONS
   clearFilters: function ($table, reload, col) {
      const dt = $table.DataTable();
      const clearAll = col ? false : true;

      if (clearAll || col == 'search') {
         $('[name="datatableSearch"]').val('');
         dt.search('');
      }

      dt.columns().every(function () {
         this.search('');

         const tCol = this.dataSrc();
         const $input = $table.find(`input[data-filter="${tCol}"]`);
         const $dropdown = $input.closest('.ui.dropdown');
         const $mod = $input.prev('.dropdown.button.mod');

         if (!clearAll && tCol != col) return;

         $input.closest('.filterPop').prev('.filter').removeClass('active');
         if ($dropdown.length) $dropdown.dropdown('clear');
         if ($mod.length) $mod.dropdown('set selected', '==');
         $input.val('');
      });

      console.log('FILTER CLEAR:', (col ? col : 'ALL'));
      if (reload) myDt.applyFilters($table, true);
   },

   setFilterInput: function ($table, col, val, clear, apply) {
      // CLEAR
      if (clear) myDt.clearFilters($table, false);

      const $input = $table.find(`input[data-filter="${col}"]`);
      const $dropdown = $input.closest('.ui.dropdown');
      const $mod = $input.prev('.dropdown.button.mod');
      const modVal = /(==|\*=|!=|>>|<<)/gi.exec(val);

      // Se ha il bottone MOD
      if ($mod.length) {
         if (modVal && modVal[0]) {
            $mod.dropdown('set selected', modVal[0]);
            val = val.replace(modVal[0], '');
         }
      }

      // Se é un dropdown
      if ($dropdown.length) {
         val = val.split(',').map((v) => {
            const $val = $input.closest('.field').find(`[data-value="${v}"]`);
            const $valWithoutMod = modVal ? $input.closest('.field').find(`[data-value="${v.replace(modVal[0], '')}"]`) : [];

            if ($val.length) return v;
            else if ($valWithoutMod.length) return v.replace(modVal[0], '');
         });

         $dropdown.dropdown('set exactly', val);
      } else $input.val(val);

      console.log('\tFILTER SET:', col, val);
      // APPLY
      if (apply) myDt.applyFilters($table, true);
   },

   applyFilters: function ($table, draw) {
      $('.filterPop').prev('.filter').removeClass('active');
      $('[data-activePreset]').removeClass('active');
      $('.tableMessage').remove();

      // console.log('\t\tFILTER APPLY START:');

      // All Columns
      const dt = $table.DataTable();
      dt.columns().every(function () {
         // Reset
         this.search('');

         const col = this.dataSrc();
         const $input = $table.find(`input[data-filter="${col}"]`);
         const $dropdown = $input.closest('.ui.dropdown');
         const $mod = $input.prev('.dropdown.button.mod');
         let val = $input.val();

         // console.log('\t\tFILTER APPLY COL:', col);

         // Search Col
         if (val) {
            const dateRange = /dal:(\d{1,}\/\d{1,}\/\d{1,}) al\:(\d{1,}\/\d{1,}\/\d{1,})/gi.exec(val);

            if (dateRange && dateRange[1] && dateRange[2]) {
               val = `${moment(dateRange[1], 'DD/MM/YY').format('YYYY/MM/DD')}[D]${moment(dateRange[2], 'DD/MM/YY').format('YYYY/MM/DD')}`;
            } else if ($dropdown.length) {
               // Per ogni valore del dropdown (divisi da ,)
               val = val.split(',').map((v) => {
                  // GRUPPI DI CANALI
                  if (col == '_channel') {
                     const gruppi = [...new Set(myVars.channels.ibmsActive.filter((c) => c.gruppo).map((c) => c.gruppo))];
                     if (gruppi.includes(v)) {
                        return '*=' + myVars.channels.ibmsActive.filter((c) => c.gruppo == v).map((c) => `*${c.code}*#*${c.matchName ? c.matchName : c.clean}*`).join('#');
                     }
                  }

                  return v;
               }).join('#');

               val = val.replace(/\#/gi, '[#]');
            } else if ($mod.length) {
               val = $mod.dropdown('get value') + val;
            }

            console.log('\t\tFILTER APPLY:', col, val, this);
            $input.closest('.filterPop').prev('.filter').addClass('active');

            let isAjax = $table.DataTable().ajax.url();
            if (!isAjax) {
               if (val == '==Vuoto') {
                  this.search('^$', true, false);
               } else if (val == '!=Vuoto') {
                  this.search('.+', true, false);
               } else {
                  this.search(val);
               }
            } else {
               this.search(val);

            }
         }
      });

      if (draw) dt.draw();
   },

   filterPresets: function ($table, preset, draw) {
      myDt.clearFilters($table, false);
      let obj = false;

      // Se il preset è una stringa (dall'url) o un oggetto
      if (typeof preset == 'string') {
         obj = myFunc.customFilter(myVars.presets, 'name', preset);

      } else if (typeof preset == 'object') {
         obj = preset;
      }

      // le chiavi inutilizzate
      const cols = obj ? Object.keys(obj).filter((c) => !['mainSearch', 'destra', 'name', 'icon', 'myOrder', 'items'].includes(c)) : [];

      // Applico i preset o li cancello
      if (cols.find(c => c.clearAllFilters == true)) {
         myDt.clearFilters($table);

      } else {
         cols.forEach(function (col, i) {
            const val = obj[col];
            myDt.setFilterInput($table, col, val);
         });

      }

      // Applico l'ordinamento
      if (obj.myOrder && obj.myOrder.length) {
         myDt.orderCols($table, false, obj.myOrder);

      } else {
         myDt.orderCols($table, false, [[myDt.colIndexToName($table, 0), 'asc']]);

      }

      // esegue
      myDt.applyFilters($table, draw);
      $(`[data-activePreset="${preset}"]`).addClass('active');

      function flattenDeep(arr1) {
         return arr1.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
      }
   },

   getState: function ($table) {
      const dt = $table.DataTable();
      let filters = '';
      const search = dt.search();
      const order = dt.order();

      dt.columns().every(function () {
         if (!this.search()) return;
         if (!filters) filters = {};
         const col = this.dataSrc();
         const $input = $table.find(`input[data-filter="${col}"]`);
         // const $dropdown = $input.closest( '.ui.dropdown' );
         const $mod = $input.prev('.dropdown.button.mod');
         let val = $input.val();

         if ($mod.length) {
            val = $mod.dropdown('get value') + val;
         }

         filters[col] = val;
      });

      const preFilter = $table.data('preFilter');

      const state = location.pathname + '?q=1' +
         (preFilter ? `&preFilter=${encodeURIComponent(preFilter)}` : '') +
         (search ? `&search=${encodeURIComponent(search)}` : '') +
         (order ? `&order=${encodeURIComponent(JSON.stringify(order))}` : '') +
         (filters ? `&filters=${encodeURIComponent(JSON.stringify(filters))}` : '');


      return state;
   },

   printFilters: function ($table) {
      let $filters = $table.closest('.dataTables_wrapper').find('.orderSearch');
      if (!$filters.length) $filters = $('<div class="orderSearch"></div>').appendTo($table.closest('.dataTables_wrapper').find('.beforeDt'));
      else $filters.html('');

      const dt = $table.DataTable();
      let clearAll = false;

      // Print Search
      if (dt.search()) {
         clearAll = true;
         $(`<a class="ui basic label"><i class="search icon"></i>Ricerca:<div class="detail">${dt.search()}</div></a>`)
            .appendTo($filters)
            .on('click', function () {
               myDt.clearFilters($table, true, 'search');
            });
      }

      // Print Order
      dt.order().forEach((o, i) => {
         const col = dt.column(o[0]);
         let txt = $(col.header()).text();
         if (!txt) txt = col.dataSrc();

         if (txt) txt = txt.replace(/\_/gi, ' ');

         $filters.append(`<a class="ui label"><i class="sort content ${o[1] == 'asc' ? 'descending' : 'ascending'} icon"></i>${txt}</a>`);
      });

      // Print Col Searches
      dt.columns().every(function () {
         if (!this.search()) return;
         clearAll = true;

         const col = this.dataSrc();
         const $input = $table.find(`input[data-filter="${col}"]`);
         const val = $input.val();
         const $mod = $input.prev('.dropdown.button.mod');
         const $val = $input.closest('.field').find(`[data-value="${val}"]`).eq(0);
         const label = $input.closest('.field').find('label').text();

         // Searched
         const searched = `${$mod.length ? $mod.dropdown('get value') : ''} ${$val.length ? $val.text() : val ? val : this.search()}`;

         $(`<a class="ui basic label" data-coldatasrc="${col}"><i class="filter icon"></i>${label ? label : col}<div class="detail">${searched}</div></a>`)
            .appendTo($filters)
            .on('click', function () {
               myDt.clearFilters($table, true, $(this).data('coldatasrc'));
            });
      });

      $(`<a class="ui red label" data-tooltip="Risultati Filtro">${dt.page.info().recordsDisplay} / ${dt.page.info().recordsTotal}</a>`).prependTo($filters);

      if (clearAll) {
         $('<a class="ui red circular label removeAllFilters"><i class="fitted alternate trash icon"></i></a>')
            .prependTo($filters).on('click', function () {
               myDt.clearFilters($table, true);
            });
      }
   },

   orderCols: function ($table, draw, ordCols) {
      const dt = $table.DataTable();
      const dtOrder = [];

      ordCols.forEach(col => {
         dtOrder.push([
            myDt.colNameToIndex($table, col[0]),
            col[1]
         ]);
      });

      dt.order(dtOrder);
      if (draw) dt.draw();
   },

   colNameToIndex: function ($table, col) {
      const dt = $table.DataTable();
      const dtCol = dt.column(col + ':name');
      const colIn = dtCol.index();

      return colIn;
   },

   colIndexToName($table, index) {
      const dt = $table.DataTable();
      const dtCol = dt.column(index);
      const colNa = dtCol.dataSrc();

      return colNa;
   },

   initThumbnailPopUp: function (classe, idDataTable, key, width, height) {
      let $table = $(idDataTable);
      let dt = $table.DataTable();

      $table.on('mouseenter', classe, async function () {
         let $this = $(this);
         let $tr = $this.closest('tr');
         let data = dt.row($tr).data();
         if (!data) return;

         let uri = key ? data[key] : data.url;
         if (!uri) return;
         $('.thumbnailPopUp').remove();

         // Show
         const $pop = $(`
            <div class="ui flowing popup thumbnailPopUp" style="min-width:200px; min-height:100px;">
               <div class="ui active inverted dimmer">
                  <div class="ui text loader">Generazione Preview</div>
               </div>
            </div>
            ` ).appendTo($('body'));

         $this.popup({
            popup: $pop,
            on: 'manual',
            movePopup: false
         }).popup('show');

         // File locale
         if (uri.indexOf('data:image/') > -1) {
            $this.popup('change content', `<img src="${uri}" width="300">`);
            setTimeout(function () {
               if ($pop.is(":visible")) $this.popup('reposition');
            }, 500);
            return
         }

         // Data
         let ext = myFunc.getExtension(uri);
         let fileName = uri.substring(uri.lastIndexOf('/') + 1, uri.length);

         if (!height && !width) width = 400;
         if (!$this.data('thumbnail') && ['jpg', 'png', 'webp', 'avif', 'tiff', 'gif', 'svg', 'psd'].includes(ext)) {
            let destUri = `/mnt/tools.datatv.it/thumbnails/${fileName}_${width ? width : 'auto'}x${height ? height : 'auto'}.jpg`;
            $this.data('thumbnail',
               await client.service('image-get').get('processImage', {
                  query: {
                     uri: uri,
                     destUri: destUri,
                     width: width ? width : null,
                     height: height ? height : null
                  }
               }).then(r => {
                  if (r && r.processed && r.processed.uri) return r.processed.uri;
                  else return 'Non Riuscito'
               }).catch(e => 'Non Riuscito')
            );
         }

         // image to show
         let html = null;
         if ($this.data('thumbnail')) {
            if ($this.data('thumbnail') == 'Non Riuscito') {
               html = `<div class="ui active inverted dimmer"><div class="ui text"><i class="ban icon"></i>Preview non riuscita</div></div>`;
            } else if (typeof $this.data('thumbnail') != 'string') {
               html = `<div class="ui active inverted dimmer"><div class="ui text loader">Generazione Preview</div></div>`;
            } else {
               $pop.css('min-width', 'auto');
               $pop.css('min-height', 'auto');
               html = `<img src="${$this.data('thumbnail')}">`;
            }

         } else if (uri && ['bmp', 'jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext)) {
            $pop.css('min-width', 'auto');
            $pop.css('min-height', 'auto');
            html = `<img src="${$this.data('thumbnail')} width="300">`;

         } else {
            html = `<div class="ui active inverted dimmer"><div class="ui text"><i class="ban icon"></i>Preview non disponibile</div></div>`;
         }

         if ($pop.is(":visible")) $this.popup('change content', html);
         setTimeout(function () {
            if ($pop.is(":visible")) $this.popup('reposition');
         }, 500);
      });

      // Hide
      $table.on('mouseleave', classe, function () {
         $(this).popup('hide');
         $('.thumbnailPopUp img').attr('src', '');
         $('.thumbnailPopUp').remove();
      });
   },

   createDtFromFeatherService: async function (idTable, serviceName, dtSettings) {
      console.log(`Create Table for Service "${serviceName}" in Table Id "${idTable}"`);

      const $table = $(idTable);
      $table.data('serviceName', serviceName);

      // Datatable Default Options
      let defaultDtSettings = {
         rowId: 'id',
         pageLength: 50,
         deferLoading: 1,
         deferRender: true,
         autoWidth: false,
         rowReorder: false,
         preDrawCallback: function (settings) { },
         drawCallback: function (settings) {
            $table.find('.infoPop').popup({ hoverable: true, lastResort: true });
            $('.ui.popup').popup('hide all');

            myDt.printFilters($table);

            const state = myDt.getState($table);
            // console.log('Salvo lo stato della tabella', state);
            history.pushState(false, false, state);
         },

         select: {
            style: 'multi+shift',
            blurable: true,
            selector: '.dtvselect, .dtvselect-checkbox',
         },

         language: {
            'decimal': '',
            'emptyTable': 'Nessun risultato disponibile',
            'info': 'Risultati da _START_ a _END_ di _TOTAL_',
            'infoEmpty': 'Risultati da 0 a 0 di 0',
            'infoFiltered': '',
            'infoPostFix': '',
            'thousands': ',',
            'lengthMenu': 'Visualizza _MENU_ risultati',
            'loadingRecords': 'Caricamento...',
            'processing': 'Elaborazione...',
            'search': 'Cerca:',
            'zeroRecords': 'Nessun risultato corrispondente alla ricerca',
            'paginate': {
               'first': 'Prima',
               'last': 'Ultima',
               'next': 'Successiva',
               'previous': 'Precedente',
            },
         },
         dom: `<'dt-area'<'dt-div stickyHead stickyCol' t>>`
         // Paginazione disabilitata <'right aligned nine wide column'p>,
      }

      // Columns Default Options
      let dtColumns = [];
      const colNames = await client.service(serviceName).find({ query: { $limit: 1 } })
         .then(r => Object.keys(r.data[0]));

      // Porto la prima colonna alla fine
      // colNames.push(colNames.shift());

      colNames.forEach((cn) => {
         let colObj = getColumnDefaultObj(cn);
         dtColumns.push(colObj);
      });

      console.log({dtColumns});
      defaultDtSettings.columns = dtColumns;

      // Traspose service to Datatable
      defaultDtSettings.serverSide = true;
      defaultDtSettings.ajax = function (data, callback, settings) {
         getDataTableDataFromService(serviceName, data, $table)
            .then((dataTableData) => callback(dataTableData));
      }

      // Create Table
      const dt = $table.DataTable(defaultDtSettings);
      // Ordino di base per la prima colonna
      dt.order([0, 'desc']);

      addTableControls($table);
      addTableFilters($table);

      dt.draw();

      // Events
      $table.on('preXhr.dt', function (e, settings, data) {
         myFunc.elLoader(true, $table.closest('.dt-area'), 'Loading Data...');

         const pageLength = $table.closest('.dt-area').find('.dt-pagelenght').val();
         data.length = pageLength ? parseInt(pageLength) : 50;

         console.log('PRE', e, settings, data);
         // const lengthVal = $('[name="dtLength"]').length > 0 && $('[name="dtLength"]').val() ? $('[name="dtLength"]').val() : false;
         // data.length = lengthVal ? parseInt(lengthVal) : 25;
         // dt.page.len(data.length);

         // data.preFilter = $table.data('preFilter') ? $table.data('preFilter') : '';

      }).on('xhr.dt', function (e, settings, processing) {
         // console.log('END', e, settings, processing)
         $table.prevAll('.tableError').remove();
         myFunc.elLoader(false);

      }).on('click', '.filterBtn', function () {
         dt.draw();
      });

      function addTableControls($table) {
         const $dtArea = $table.closest('.dt-area');
         const $dtControl = $dtArea.prepend(`
            <div class="dt-control">
               <div class="ui labels dt-control-right" style="float: right">

                  <div class="ui basic label">
                     <div class="ui tiny dropdown dt-pagelenghtDrop">
                        <input type="hidden" class="noselection dt-pagelenght" value="">
                        <div class="text">---</div>
                        <i class="dropdown icon"></i>
                        <div class="menu transition hidden">
                           <div class="item" data-value="25">25 Righe</div>
                           <div class="item" data-value="50">50 Righe</div>
                           <div class="item" data-value="75">75 Righe</div>
                           <div class="item" data-value="100">100 Righe</div>
                           <div class="item" data-value="250">250 Righe</div>
                           <div class="item" data-value="500">500 Righe</div>
                        </div>
                     </div>
                  </div>

                  <div class="ui basic label"><i class="fitted excel file icon"></i></div>

               </div>
               <div class="ui labels dt-control-left"></div>
            </div>
         `);

         $dtControl.find('.dt-pagelenghtDrop').dropdown({
            onChange: function (val) {
               dt.draw();
            }
         }).dropdown('set selected', defaultDtSettings.pageLength);
      }

      // Add Filters
      function addTableFilters($table) {
         const dt = $table.DataTable();
         const $thead = $table.find('thead');
         const $tr = $(`<tr role="row"></tr>`).appendTo($thead);

         // Ciclo le colonne
         dt.columns().every(function (index) {
            if (!this.visible()) return;

            const dataSrc = this.dataSrc();
            const $th = $(this.header());
            const $thFilter = $(`
                <th class="filterTh">
                  <div class="fluid ui mini basic label filter"><i class="filter fitted icon"></i>&nbsp;</div>
               </th>
            `).appendTo($tr);

            $th.css('border-bottom', 'none');

            // custom filter pop
            let colObj = getColumnDefaultObj(dataSrc);
            const $activator = $thFilter.find('.label.filter');
            const $filter = colObj.customFilter ? colObj.customFilter($activator, this, dt)
               : getColumnDefaultObj('base').customFilter($activator, this, dt);


            $activator.popup({
               popup: $filter,
               inline: true,
               exclusive: true,
               position: 'bottom left',
               lastResort: 'bottom right',
               boundary: $table,
               on: 'click',
               jitter: 1
               // hoverable: true,
               // forcePosition: true,
            });

         });


         // $table.on('click', '.filterBtn', function () {
         //    // myDt.applyFilters($table, true);
         // });

      }

      // Default Columns
      function getColumnDefaultObj(colName, myType) {
         let obj = {};
         let defCols = {
            id: {
               orderable: true,
               className: 'center aligned collapsing dtvselect-checkbox',
               render: function (data, type, row, meta) {
                  if (!data) return '';

                  let html = `<div class="smallText opacita6">${data}</div>`;
                  return html;
               },
               customType: 'id',
               customFilter: function ($activator, col, dt) {
                  const $th = $(col.header());
                  const dataSrc = col.dataSrc();

                  let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui left action input">
                              <div class="ui basic dropdown button mod" tabindex="0">
                                 <div class="text bigMenuText">=</div>
                                 <div class="menu transition hidden" tabindex="-1">
                                    <div class="item bigMenuText active selected" data-value=""> = </div>
                                    <div class="item bigMenuText" data-value=">="> >= </div>
                                    <div class="item bigMenuText" data-value="<="> <= </div>
                                 </div>
                              </div>
                              <input type="number" data-dt-datasrc="${dataSrc}" placeholder="${$th.text()}" style="width:200px">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

                  $filter.find('.dropdown').dropdown({ fullTextSearch: true });

                  return $filter;
               }
            },
            base: {
               customType: 'base',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  if (!data) {
                     return '';

                  } else if (typeof data === "string" || typeof data === "number") {
                     return data;

                  } else if (typeof data === "object") {
                     return JSON.stringify(data);

                  }
               },
               customFilter: function ($activator, col, dt) {
                  const $th = $(col.header());
                  const dataSrc = col.dataSrc();

                  let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui input">
                                 <input type="text" data-dt-datasrc="${dataSrc}" placeholder="${$th.text()}" style="width:200px">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

                  $filter.find('.dropdown').dropdown({ fullTextSearch: true });
                  return $filter;
               }
            },
            string: {
               customType: 'string',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  return data ? data : '';
               }
            },
            number: {
               customType: 'number',
               orderable: true,
               className: 'right aligned collapsing',
               render: function (data, type, row, meta) {
                  if (!data) return '';

                  let html = `${data}`;
                  return html;
               },
               customFilter: function ($activator, col, dt) {
                  const $th = $(col.header());
                  const dataSrc = col.dataSrc();

                  let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui left action input">
                              <div class="ui basic dropdown button mod" tabindex="0">
                                 <div class="text bigMenuText">=</div>
                                 <div class="menu transition hidden" tabindex="-1">
                                    <div class="item bigMenuText active selected" data-value=""> = </div>
                                    <div class="item bigMenuText" data-value=">="> >= </div>
                                    <div class="item bigMenuText" data-value="<="> <= </div>
                                 </div>
                              </div>
                              <input type="number" data-dt-datasrc="${dataSrc}" placeholder="${$th.text()}" style="width:200px">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

                  $filter.find('.dropdown').dropdown({ fullTextSearch: true });

                  return $filter;
               }
            },
            array: {
               customType: 'array',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  if (!data) return '';

                  let html = '';
                  html = data.join(', ');
                  return html;
               }
            },
            datePlain: {
               customType: 'datePlain',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  if (!data) return '';

                  console.log('ciccio')

                  let html = '';
                  const mome = moment(data.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm');
                  html += `<span style="display: none">${mome.format('X')}</span>${mome.format('DD/MM/YY')}`;

                  return html;
               },
               customFilter: function ($activator, col, dt) {
                  const $th = $(col.header());
                  const dataSrc = col.dataSrc();
                  const chs = myVars.channels.tutti;

                  let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui input">
                                 <input type="text" data-dt-datasrc="${dataSrc}" placeholder="dal:--/--/-- al:--/--/--">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

                  myFunc.makeCalendar($filter.find(`[data-dt-datasrc="${dataSrc}"]`), 'range');
                  return $filter;
               }
            },
            date: {
               customType: 'date',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  if (!data) return '';

                  let html = '';
                  const mome = moment(data.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm');
                  html += `<span style="display: none">${mome.format('X')}</span>
                     ${mome.format('DD/MM/YY')}</br>${mome.format('HH:mm')}`;

                  return html;
               },
               customFilter: function ($activator, col, dt) {
                  const $th = $(col.header());
                  const dataSrc = col.dataSrc();
                  const chs = myVars.channels.tutti;

                  let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui input">
                                 <input type="text" data-dt-datasrc="${dataSrc}" placeholder="dal:--/--/-- al:--/--/--">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

                  myFunc.makeCalendar($filter.find(`[data-dt-datasrc="${dataSrc}"]`), 'range');
                  return $filter;
               }
            },
            hour: {
               customType: 'hour',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  return data ? data : '';
               }
            },
            hourdate: {
               customType: 'hourdate',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  return data ? data : '';
               }
            },
            channel: {
               customType: 'channel',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  if (!data) return '';

                  let html = '';
                  let ch = myVars.channels.tutti.find(ch => data == ch.ID);
                  //<div style="background: url(./returnFile?func=processImage&height=150&uri=channelsimages/${ch.ID}_logo.png&destUri=/mnt/tools.datatv.it/thumbnails/channelsimages/${ch.ID}_logo_150.png);background-color: #aaa;background-position: center;background-size: 80px;border-radius: 10px;height: 20px;background-repeat-x: no-repeat;"></div>
                  if (ch) html += `<span><b>${ch.ID}</b> &bull; ${ch.Name}</span>`;
                  return html;
               },
               customFilter: function ($activator, col, dt) {
                  const $th = $(col.header());
                  const dataSrc = col.dataSrc();
                  const chs = myVars.channels.tutti;

                  let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" class="noselection" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 ${chs.map(ch => `<div class="item" data-value="${ch.ID}">${ch.Name}</div>`).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

                  $filter.find('.dropdown').dropdown({ fullTextSearch: true });
                  return $filter;
               }
            },
            user: {
               customType: 'user',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  if (!data) return '';

                  let html = '';
                  let user = myVars.users.tutti.filter(u => data.includes(data));

                  html += `
                  <div class="ui relaxed divided link list" style="min-width: 110px;">
                     ${users.map(u => `
                        <a class="item">
                           <img class="ui avatar image" src="./${u.avatar ? 'avatars/' + u.avatar : 'avatars/user.png'}">
                           <div class="content">
                              <div class="header">${u.nome.substring(0, 1)}. ${u.cognome}</div>
                              <div class="description">${u.ruolo}</div>
                           </div>
                        </a>
                     `).join('')}
                  </div>`

                  return html;
               },
               customFilter: function ($activator, col, dt) {
                  const $th = $(col.header());
                  const dataSrc = col.dataSrc();
                  const usrs = myVars.channels.tutti;

                  let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" class="noselection" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 ${usrs.map(us => `<div class="item" data-value="${us.id}">${us.nome} ${us.cognome}</div>`).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

                  $filter.find('.dropdown').dropdown({ fullTextSearch: true });
                  return $filter;
               }
            },
            users: {
               customType: 'users',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  if (!data || !data.length) return '';

                  let html = '';
                  let users = myVars.users.tutti.filter(u => data.includes(u.id.toString()));

                  html += `
                  <div class="ui relaxed divided link list" style="min-width: 110px;">
                     ${users.map(u => `
                        <a class="item">
                           <img class="ui avatar image" src="./${u.avatar ? 'avatars/' + u.avatar : 'avatars/user.png'}">
                           <div class="content">
                              <div class="header">${u.nome.substring(0, 1)}. ${u.cognome}</div>
                              <div class="description">${u.ruolo}</div>
                           </div>
                        </a>
                     `).join('')}
                  </div>`

                  return html;
               },
               customFilter: function ($activator, col, dt) {
                  const $th = $(col.header());
                  const dataSrc = col.dataSrc();
                  const usrs = myVars.users.tutti;

                  let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" class="noselection" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 ${usrs.map(us => `
                                    <div class="vertical item" data-value="${us.id}">
                                       <span class="text">${us.nome} ${us.cognome}
                                          </br><span class="opacita4">${us.ruolo}</span>
                                       </span>
                                    </div>
                                 `).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

                  $filter.find('.dropdown').dropdown({ fullTextSearch: true });
                  return $filter;
               }
            },
            myres: {
               customType: 'myres',
               orderable: true,
               className: 'left aligned collapsing',
               render: function (data, type, row, meta) {
                  return data ? data : '';
               }
            },
         }

         // Default columns
         let title = null;
         if (colName == 'id') {
            myType = 'id';

         } else if (!myType) {
            let rex = /_([^_]+)$/g.exec(colName);
            if (rex && rex[1]) {

               if (['at', 'first', 'last', 'date'].includes(rex[1])) {
                  myType = 'date';

               } else if (['by', 'to', 'user'].includes(rex[1])) {
                  myType = 'user';

               } else {
                  myType = rex[1];

               }

               title = colName.replace(new RegExp(`_${rex[1]}\$`, 'gi'), '').replace(/_/gi, ' ');
            }
         }

         // console.log(`myType: ${myType}`, defCols, colName);

         // base type
         if (!myType || !defCols[myType]) myType = 'base';

         // Final Object
         obj = defCols[myType];
         obj.name = colName;
         obj.title = title ? title : colName;
         obj.data = colName;

         return obj;
      }

      // Get Data and print filters
      async function getDataTableDataFromService(serviceName, data, $table) {
         console.log(`Get Data for ${serviceName}`, data);
         const query = { $limit: data.length };
         const dt = $table.DataTable();
         const $filtersArea = $table.closest('.dt-area').find('.dt-control-left');
         // const $filtersInputs = $table.closest('.dt-area').find('[data-dt-datasrc]');

         if ($filtersArea.length) {
            $filtersArea.empty();

            // Stampo ordinamento
            dt.order().forEach((o, i) => {
               const col = dt.column(o[0]);
               const dataSrc = col.dataSrc();
               const txt = $(col.header()).text();

               $filtersArea.append(`<a class="ui label"><i class="sort content ${o[1] == 'asc' ? 'descending' : 'ascending'} icon"></i>
                  ${txt ? txt : dataSrc.replace(/\_/gi, ' ')}
               </a>`);
            });
         }

         // Applico filtri
         dt.columns().every(function (index) {
            const col = this;
            const dataSrc = col.dataSrc();
            const txt = $(col.header()).text();
            const colType = dt.settings()[0].aoColumns[index].customType;

            const $input = $table.find(`input[data-dt-datasrc="${dataSrc}"]`);
            const $dropdown = $input.closest('.ui.dropdown');
            const $mod = $input.prev('.dropdown.button.mod');

            const $thLabelFilter = $input.closest('th').find('.label.filter');

            if ($input.length) {
               let val = $input.val();
               $thLabelFilter.removeClass('active');

               // Modificatori
               if (val) {
                  if ($mod.length) {
                     val = $mod.dropdown('get value') + val;

                  } else if (colType == 'users') {
                     val = `*"${val}"*`;

                  }
               }


               // Stampa Filtro
               if ($filtersArea.length && val) {
                  $thLabelFilter.addClass('active');

                  const $filterLabel = $(`<a class="ui basic label"><i class="filter icon"></i>
                     ${txt ? txt : dataSrc.replace(/\_/gi, ' ')}
                     <div class="detail">${val}</div>
                  </a>`).appendTo($filtersArea);

                  $filterLabel.on('click', function () {
                     if ($dropdown.length) $dropdown.dropdown('clear');
                     if ($mod.length) $mod.dropdown('set selected', '==');
                     $input.val('');
                     dt.draw();
                  });
               }

               // Applica Filtro
               let dataCol = data.columns.find(c => c.data == dataSrc);
               if (dataCol) {
                  col.search(val);
                  dataCol.search.value = val;
                  dataCol.colType = colType;
               }
            }
         });

         // Converti Ricerca
         data.columns.forEach((col) => {
            if (!col.search.value) return;
            let val = col.search.value;
            let type = col.colType;

            if (['number', 'id'].includes(type)) {
               if (/\>\=/gi.test(val)) {
                  query[col.data] = { $gte: val.replace(/\>\=/gi, '').trim() };

               } else if (/\<\=/gi.test(val)) {
                  query[col.data] = { $lte: val.replace(/\<\=/gi, '').trim() };

               } else {
                  query[col.data] = val;

               }

            } else if (['date'].includes(type)) {
               const dateRange = /dal:(\d{1,}\/\d{1,}\/\d{1,}) al\:(\d{1,}\/\d{1,}\/\d{1,})/gi.exec(val);

               if (dateRange && dateRange[1] && dateRange[2]) {
                  query['$and'] = [
                     { [col.data]: { $gte: moment(dateRange[1], 'DD/MM/YY').format('YYYY/MM/DD') } },
                     { [col.data]: { $lte: moment(dateRange[2], 'DD/MM/YY').add('1', 'days').format('YYYY/MM/DD') } }

                  ];
               }

            } else if (/\*/gi.test(val)) {
               query[col.data] = { $like: val.replace(/\*/gi, '%').trim() };

            } else {
               query[col.data] = val;

            }
         });

         // Ordinamento
         if (data.order.length) {
            query.$sort = {};
            data.order.forEach((or) => {
               const col = data.columns[or.column].data;
               query.$sort[col] = or.dir == 'asc' ? 1 : -1;
            })
         } else {
            query.$sort = { id: -1 };
         }

         // Pagination non funziona da SQL Server 2012 in poi per ora la disabilito
         if (data.start) {
            query.$skip = data.start;
         }

         console.log(`Query Start ${serviceName}`, query);
         const serviceData = await client.service(serviceName).find({ plainService: true, query: query });
         const recordsTotal = await client.service(serviceName).find({ query: { $limit: 0 } }).then(r => r.total);
         console.log(`Query End ${serviceName}`, serviceData);

         const tableData = {
            draw: data.draw++,
            recordsFiltered: serviceData.data.length,
            recordsTotal: recordsTotal,
            data: serviceData.data
         }

         if ($filtersArea.length) {
            // Stampo Cancella filtri

            // Stampo Numero Risultati
            $(`<a class="ui red label" data-tooltip="Risultati Filtro">
               ${tableData.recordsFiltered} / ${recordsTotal}
            </a>`).prependTo($filtersArea);
         }

         return tableData;
      }
   }
};
