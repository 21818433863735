module.exports = async function(id) {
  console.log('open user', id);
  if (!id) return;

  const loadData = client.service('users').get(id);
  const $modal = await createModal();
  
  // Loader
  const $loader = $(`
    <div class="ui active inverted dimmer modalLoader">
      <div class="ui indeterminate text loader">Loading</div>
    </div>
  `).appendTo($modal);

  $modal.modal({
    autofocus: false,
    allowMultiple: true,
    transition: 'scale',
  }).modal('show');

  // Looad Data
  loadData.then((data) => {
    console.log(data);
    $loader.remove();

    if (data) {
      $modal.data('elData', data).addClass('dataEl');
      myFunc.renderData($modal, data);
      if (data.avatar) $('.avatarCanvas')[0].myCanvas.insertImage(mySite+'/avatars/'+data.avatar, 'max');
    }

    $modal.find('.salvaModal').on('click', function() {
      saveUser($modal).then((usr) =>{
        if ($('#userDataTable.dataTable').length > 0) $('#userDataTable').DataTable().row('#' + usr.id).data(usr);
        $modal.modal('hide');
      });
    });
  });

  async function saveUser($modal) {
    const data = myFunc.readData($modal);

    // Se la password è vuota non la salvo
    if (data.newpassword) data.password = data.newpassword;
    delete data.newpassword;

    // Se c'è un nuovo avatar lo salvo
    if ($modal.find('.avatarName').val()) {
      const avatarOld = $modal.data('elData').avatar;
      const avatarName = data.avatar ? data.avatar.split('/')[1] : data.id ? data.id + '_avatar.png' : data.username ? data.username + '_avatar.png' : Date.now() + '_avatar.png';
      const avatarSaved = await $('.avatarCanvas')[0].myCanvas.saveImage(avatarName, 'avatars', 'png');

      if (avatarSaved[0].name) data.avatar = avatarSaved[0].name;

      // Refresh Avatars
      $('img[src*="'+avatarOld+'"]').each((i, img) =>{
        const newSrc = $(img).attr('src').replace(avatarOld, data.avatar);
        $(img).removeAttr('src').attr('src', newSrc+'?'+Date.now());
      });
    }

    // Patch User
    console.log('Patch User', data);
    const res = await client.service('users').patch(id, data);
    console.log('Patched User', res);

    return res;
  }

  async function createModal(create) {
    // Rimuove tutte le modal
    $('.ui.modal').remove();

    // Creo una modal generica
    const $modal = $(`<div class="ui small modal" id="modaluser"/>`)
      .appendTo('body');

    const $header = $(`<div class="header">Impostazioni Utente</div>`)
      .appendTo($modal);

    const $content = $(`
      <div class="content">
        
        <form class="ui padded form" autocomplete="off">

          
          <div class="ui grid">
            
            <div class="ten wide column">

              <div class="disabled field">
                <label>Username</label>
                <input placeholder="Username" type="text" data-renderdata="username">
              </div>

              <div class="field">
                <label>Disponibile</label>
                <div class="ui selection dropdown" tabindex="0" data-renderdata="disponibile">
                  <div class="default text">Select</div>
                  <i class="dropdown icon"></i>
                  <input type="hidden" name="disponibile">
                  <div class="menu" tabindex="-1">
                    <div class="item" data-value="true">Si</div>
                    <div class="item" data-value="false">No</div>
                  </div>
                </div>
              </div>              

              <div class="field">
                <label>Email</label>
                <input placeholder="Email" type="text" data-renderdata="email">
              </div>

              <div class="field">
                <label>Mobile</label>
                <input placeholder="Mobile" type="text" data-renderdata="mobile">
              </div>
              
              <!--<div class="field">
                <label>Nuova Password</label>
                <input placeholder="Nuova Password" type="text" data-renderdata="newpassword" autocomplete="new-password">
              </div>-->

            </div>

            <div class="six wide column">
              <div class="field avatar">
                <label>Avatar</label>
                <div class="ui fluid two item small menu" style="margin-top: 0;">
                  <div class="item icon avatarRandom"><i class="user circle icon"></i></div>
                  <div class="item icon avatarLocal"><i class="folder open icon"></i></div>
                </div>
                <input type="text" class="avatarName" style="display:none;">
                <div class="avatarCanvas"></div>
              </div>
            </div>

          </div>

        </form>        

      </div>`)
      .appendTo($modal);

    const $actions = $(`
      <div class="actions">
      
      <div class="ui left floated left aligned basic mini segment">
        <span class="ui grey text">
          Ultima Modifica il <span data-renderdata="updated_at">--/--/----</span>
        </span>
      </div>
      <div class="ui right labeled icon cancel button">Annulla<i class="ban icon"></i></div>
      <div class="ui green right labeled icon button salvaModal">` + (create ? `Crea` : `Salva`) + `<i class="database icon"></i>
      

      </div>`)
      .appendTo($modal);

    $modal.find('.ui.dropdown').dropdown();
    $modal.find('.ui.checkbox').checkbox();

    // AVATAR CANVAS
    const canAvatar = await _myCanvas.init({
      selector: '.avatarCanvas',
      width: 512,
      height: 512,
      maxHeight: 200,
      nomenu: true,
    }).then((mc) => mc[0]);

    const lName = canAvatar.addLayer('avatar').name;
    canAvatar.activeLayer = lName;

    $modal.find('.avatarCanvas').css({'margin': '10px'});

    // AVATAR INPUT
    $modal.find('.avatarLocal').on('click', function() {
      canAvatar.getLocalFile()
        .then((file) => {
          canAvatar.insertImage(file);
          $modal.find('.avatarName').val(file.name);
        });
    });

    // AVATAR RANDOM
    $modal.find('.avatarRandom').on('click', function() {
      const random = myFunc.randomString(10);
      const randomUrl = 'https://avatars.dicebear.com/v2/avataaars/' + random + '.svg';
      canAvatar.insertImage(randomUrl, 'max');
      $modal.find('.avatarName').val(random + '.svg');
    });

    return $modal;
  }
};
