module.exports = {
  init: function() {
    if ($('.item.icon.onlineUsers').length) return;
    client.service('messages').on('created', (m) => myChat.showMessage(m));

    // Add onlineUsers
    const $sidebarBtn = $('.fixed.menu .content.icon').closest('a.item');
    $('<a class="item icon onlineUsers"><i class="paper plane icon"></i></a>').insertAfter($sidebarBtn);

    // showOnlineUsers
    $('body').on('click', '.onlineUsers', function() {
      myChat.showOnlineUsers($(this));
    });

    // showMessageForm
    $('body').on('click', '[data-messageToUserId]', function() {
      myChat.showMessageForm($(this));
    });

    // Copy Message
    $('body').on('click', '.copyMessage', function(e) {
      e.preventDefault();
      e.stopPropagation();

      const mess = $(this).closest('.description').text();
      myFunc.copyToClipboard(mess);
      $(this).transition('flash');
    });
  },

  showOnlineUsers: async function($el) {
    const allUsers = await myVars.users.load();
    $('#onlineUsers').remove();

    $(`
      <div class="ui popup" id="onlineUsers">
        <div class="ui small relaxed divided link list">

        ${!['Admin'].includes(myVars.io.ruolo) ? '' : `
          <a class="item" data-messageToUserId="">
          <img class="ui avatar image" src="${mySite}/avatars/user.png">
            <div class="content">
              <div class="header">Tutti</div>
              <div class="description">Messaggio a tutti</div>
            </div>
          </a>`}

          ${allUsers.map((u) => {
            if (!u.interface || u.interface == '[]') return '';

            const uInterface = JSON.parse(u.interface);
            let activePages = uInterface.filter((i) => i.active == 1);
            if (!activePages.length) return '';

            const nowTime = moment();
            activePages.forEach((ap) => {
              const diff = moment(ap.date, 'YYYY-MM-DD HH:mm:ss').diff(nowTime);
              ap.min = moment.duration(diff).minutes();
            });
            activePages = myFunc.orderObjByKey(activePages, 'min', 'desc');
            console.log(`${u.nome} ${u.cognome} Online con ${activePages.length} Pagine Attive.`);

            return `
              <a class="item" data-messageToUserId="${u.id}">
                <img class="ui avatar image" src="${mySite}/avatars/${u.avatar ? u.avatar : 'user.png'}">
                <div class="content">
                  <div class="header">${u.nome} ${u.cognome}</div>
                  <div class="description">${Math.abs(activePages[0].min)} minuti fa.</div>
                </div>
              </a>`;
          }).join('')}
        </div>
      </div>`).appendTo('body');

    $el.popup({
      on: 'manual',
      popup: '#onlineUsers',
    }).popup('show');
  },

  sendMessage: async function(to, text) {
    await client.service('messages').create({to: to, text: text});
  },

  showMessageForm: function($el) {
    const to = $el.attr('data-messageToUserId');

    $('#messageForm').remove();
    $messageForm = $(`
      <div class="ui popup" data-variation="wide" id="messageForm">
        <div class="ui small form" style="width:240px;">
          <div class="field">
            <label>Invia Messaggio...</label>
            <div class="ui icon input">
              <textarea rows="2"></textarea>
              <i class="paper plane link icon sendMessage"></i>
            </div>
          </div>
        </div>
      </div>`).appendTo('body');

    $el.popup({
      on: 'manual',
      popup: '#messageForm',
      onVisible: function() {
        $messageForm.find('textarea').focus();
      },
    }).popup('show');

    $messageForm.on('click', '.sendMessage', async function() {
      $(this).closest('.ui.input').addClass('loading');
      const text = $(this).prev().val();

      await myChat.sendMessage(to, text);
      $('#messageForm').remove();
    });

    $messageForm.on('keypress', 'textarea', async function(e) {
      if (e.which == 13) {
        $(this).closest('.ui.input').addClass('loading');
        const text = $(this).val();

        await myChat.sendMessage(to, text);
        $('#messageForm').remove();
      }
    });
  },

  showMessage: function(m) {
    const u = m.user;
    const mIsLink = /http(s?):\/\//gi.test(m.text);
    const $toast = $(`
      <div class="ui ${m.to ? 'blue' : 'black'} toast domtoast" id="toast_${$.now()}" data-time="${$.now()}">
        <div class="ui small relaxed divided link inverted list" style="margin:0;">
          <a class="item" data-messageToUserId="${u.id}">
            <img class="ui avatar image" src="${mySite}/avatars/${u.avatar ? u.avatar : 'user.png'}" style="position: absolute;">
            <div class="content" style="margin-left: 30px;">
              <div class="header">${u.nome} ${u.cognome} (${moment(m.created_at).format('HH:mm')})</div>
              <div class="description" style="overflow:hidden;"><i class="copy link icon copyMessage"></i>${mIsLink ? `<a href="${m.text}" target="_blank" style="color:#feebb4;">`: ''}${m.text}${mIsLink ? '</a>': ''}</div>
            </div>
          </a>
        </div>
        <i class="close icon"></i>
      </div>`).appendTo('body');

    $toast.toast({
      displayTime: 0,
      closeIcon: true,
      onVisible: function() {
        $toast.remove(); // Rimuovo il domtoast
      },
    });

    // Elimino i vecchi toast
    const $toasts = $('.ui.blue.toast');
    let $toastsToHide = $();
    const nToast = 5;
    if ($toasts.length <= nToast) return;

    $toasts.each((i, t) =>{
      if (i < $toasts.length - nToast) $toastsToHide = $toastsToHide.add($(t));
    });

    $toastsToHide.each((i, t) =>{
      $(t).toast('close');
    });
  },
};