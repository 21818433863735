module.exports = {
   getQueryString: function () {
      const queryString = new URLSearchParams(window.location.search);
      const obj = {};

      queryString.forEach(function (value, key) {
         obj[key] = value;
      });
      return obj;
   },

   delay: (ms) => new Promise(resolve => setTimeout(resolve, ms)),

   tryJsonParse: function (jsonString) {
      if (!jsonString || jsonString === '""') {
         return '';
      }
      try {
         return JSON.parse(jsonString);
      } catch (error) {
         return jsonString;
      }
   },

   cleanTxt: function (txt, placeholder, looseString) {
      if (!txt) return '';
      console.log(txt, placeholder, looseString)

      if(looseString) {
         return txt
            .trim()
            .replace(/[^A-Z0-9 ]/gi, placeholder === false ? '' : placeholder ? placeholder : '#')
            .replaceAll(/.(?!\S)/gi, placeholder === false ? '' : placeholder ? placeholder : '#')
            .replace(/  /gi, ' ') // elimina doppi spazi
            .trim()
            .toLowerCase();
      }

      return txt
         .normalize('NFD')
         .replace(/[\u0300-\u036f]/g, '') // rimuove gli accenti
         .replace(/[^A-Z0-9 ]/gi, placeholder === false ? '' : placeholder ? placeholder : '#') // sostituisce quello che non sono lettere numeri spazi con #
         .replace(/  /gi, ' ') // elimina doppi spazi
         .trim()
         .toLowerCase();
   },

   addScripts: function (array) {
      return new Promise((resolve, reject) => {
         const scripts = Array
            .from(document.querySelectorAll('script'))
            .map((scr) => scr.src);

         const loader = function (src, handler) {
            const script = document.createElement('script');
            script.src = src;
            script.onload = script.onreadystatechange = function () {
               script.onreadystatechange = script.onload = null;
               handler();
            };
            const head = document.getElementsByTagName('head')[0];
            (head || document.body).appendChild(script);
         };
         (function run() {
            if (array.length != 0) {
               const src = array.shift();
               if (!scripts.includes(rs)) loader(src, run);
            } else {
               resolve('scripts loaded');
            }
         })();
      });
   },

   affermativeModal: function (title, text, classe, size) {
      $('.ui.popup').popup('hide all');

      return new Promise((resolve, reject) => {
         $('#affermativeModal').remove();

         const $cmodal = $(`
        <div class="ui ${size ? size : 'mini'} modal ${classe}" id="affermativeModal">
          <div class="header"><i class="exclamation circle icon"></i>${title}</div>
          <div class="content">${text}</div>
          <div class="actions">
            <div class="ui ok green small button"><i class="check icon"></i>CHIUDI</div>
          </div>
        </div>
        `).appendTo('body');

         // Show Modal
         $cmodal.modal({
            autofocus: false,
            allowMultiple: true,
            transition: 'zoom',
            closable: false,
            duration: 250,
            onDeny: function () {
               setTimeout(function () {
                  resolve('ko');
               }, 500);
            },
            onApprove: function () {
               setTimeout(function () {
                  resolve('ok');
               }, 500);
            },
         }).modal('show');
      });
   },

   confirmModal: function (title, question, classe) {
      $('.ui.popup').popup('hide all');

      return new Promise((resolve, reject) => {
         $('#confirmModal').remove();

         const $cmodal = $(`
        <div class="ui small modal ${classe}" id="confirmModal">
          <div class="header"><i class="question circle icon"></i>${title}</div>
          <div class="scrolling content">${question}</div>
          <div class="actions">
            <div class="ui cancel red small button"><i class="remove icon"></i>Annulla</div>
            <div class="ui ok green small button"><i class="check icon"></i>Conferma</div>
          </div>
        </div>
        `).appendTo('body');

         // Show Modal
         $cmodal.modal({
            autofocus: false,
            allowMultiple: true,
            transition: 'zoom',
            closable: false,
            duration: 250,
            onDeny: function () {
               setTimeout(function () {
                  resolve('ko');
               }, 500);
            },
            onApprove: function () {
               setTimeout(function () {
                  resolve('ok');
               }, 500);
            },
         }).modal('show');
      });
   },

   saveModal: function (saveModalObj) {
      $('.ui.popup').popup('hide all');
      $('#saveModal').remove();
      console.log(saveModalObj)
      const allpromises = saveModalObj.els.map((el) => el.details.map((d) => d.promise)).flat();

      return new Promise((resolve, reject) => {
         const $smodal = $(`
        <div class="ui small modal saveModal" id="saveModal">
          <div class="header"><i class="download icon"></i>${saveModalObj.title} (<span class="ended">0</span>/<span class="total">${saveModalObj.els.length}</span>)</div>
          <div class="content">
            <table class="ui compact unstackable celled table">
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      `).appendTo('body');

         let ended = parseInt($smodal.find('.ended').text());
         // Per ogni elemento
         saveModalObj.els.forEach((el, i) => {
            const $el = $(`
          <tr id="save_${i}">
            <td class="collapsing top aligned">
              <i class="large notched circle loading fitted icon"></i>
            </td>
            <td>
              <div class="ui header" style="margin:0px;">${el.title}</div>
              <div class="ui small horizontal divided list" style="margin:0;">
              </div>
            </td>
          </tr>`).appendTo($smodal.find('tbody'));

            // Fine Elemento
            Promise.all(el.details.map((d) => d.promise)).then((r) => {
               // Tutto a Posto
               $el.find('td > .icon').removeClass('notched circle loading').addClass('green circle check');
               ended++;
               $smodal.find('.ended').text(ended);
            }).catch((e) => {
               // Ci sono errori
               $el.find('td > .icon').removeClass('notched circle loading').addClass('red warning sign');
            });

            // Per ogni PROMISE
            el.details.forEach((d, y) => {
               const $list = $el.find('.list');
               const $p = $(`
            <div class="item sdetail" style="border-right:0;" id="save_${i}_${y}">
              <i class="ellipsis horizontal icon"></i>${d.title}
            </div>`).appendTo($list);

               d.promise.then((res) => {
                  $p.find(`.icon`).removeClass('ellipsis horizontal loading').addClass('green circle check');
                  const $pres = $(`<div class="ui mini positive message pRes" style="word-break: break-word;">${JSON.stringify(res)}</div>`).hide().insertAfter($list);
                  $p.on('click', () => $pres.toggle());
               }).catch((err) => {
                  $p.find(`.icon`).removeClass('ellipsis horizontal loading').addClass('red warning sign');
                  const $pres = $(`<div class="ui mini error message pRes" style="word-break: break-word;">${JSON.stringify(err)}</div>`).hide().insertAfter($list);
                  $p.on('click', () => $pres.toggle());
               });
            });
         });

         // Show Modal
         $smodal.modal({
            autofocus: false,
            allowMultiple: true,
            transition: 'zoom',
            closable: false,
            duration: 250,
            onDeny: function () {
               setTimeout(function () {
                  resolve('ko');
               }, 500);
            },
         }).modal('show');

         Promise.all(allpromises).then((r) => {
            // Tutto a Posto
            $(`<div class="ui positive icon message">
            <i class="green circle check icon"></i>
            <div class="content">
              <div class="header">Salvataggio OK</div>
              <p>Tutte le operazioni sono state effettuate correttamente.</p>
            </div>
          </div>`).prependTo($smodal.find('.content')).transition('flash');

            $(`<div class="actions">
            <div class="ui right labeled icon button closeModal">Chiudi<i class="close icon"></i></div>
          </div>`).appendTo($smodal).on('click', () => $smodal.modal('hide'));
         }).catch((e) => {
            // Ci sono errori
            $(`<div class="ui negative icon message">
            <i class="red warning sign icon"></i>
            <div class="content">
              <div class="header">Sono presenti errori durante il salvataggio.</div>
              <p>Il dettaglio degli errori è indicato nelle aree rosse sottostanti.</p>
            </div>
          </div>`).prependTo($smodal.find('.content')).transition('flash');

            $(`<div class="actions">
          <div class="ui right labeled icon button closeModal">Chiudi<i class="close icon"></i></div>
        </div>`).appendTo($smodal).on('click', () => $smodal.modal('hide'));
         });
      });
   },

   copyToClipboard: function (str) {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      console.log('copiato:', str);
   },

   copyClipboardInit: function () {
      $('body').on('click', '.copy.clipboard.icon, [data-tocopy]', function (e) {
         e.preventDefault();
         e.stopPropagation();
         let toCopy = $(this).attr('data-tocopy');

         if (toCopy) {
            myFunc.copyToClipboard(toCopy);

         } else {
            let $label = $(this).closest('.label');
            if ($label.length) {
               const $detail = $label.find('.detail');
               if ($detail.length) myFunc.copyToClipboard($detail.text());
               else myFunc.copyToClipboard($label.text());

            }
         }

         $(this).transition('flash');

      });
   },

   elLoader: function (show, $el, testo, size) {
      return new Promise((resolve, reject) => {

         // Sposto il dimmer dal body a .pusher altrimenti interferisce con le modal
         if (!$el || $el.is('body')) $el = $('.pushable>.pusher');
         if (!testo && testo !== false) testo = 'Loading...';
         if (!size) size = ' normal';

         const $dimmer = $el.children('.elLoader');
         // console.log(`[elLoader]`, { show }, { $el }, { testo }, { size }, { $dimmer });

         // Prima Creazione
         if (!$dimmer.length && show) {
            $el.dimmer({
               dimmerName: 'elLoader',
               closable: false,
               variation: 'inverted',
               displayLoader: true,
               loaderVariation: 'indeterminate ' + size,
               loaderText: testo,
            }).dimmer('show');
         }

         if ($dimmer.length && show) {
            $dimmer.find('.text').text(testo);
            if (!$dimmer.hasClass('visible')) $dimmer.dimmer('show');

         }

         // Hide All
         if (!show && $el.is('.pushable>.pusher')) {
            $('.elLoader').each((i, el) => {
               $(el).dimmer('hide', dimmerDone);
            });
         }

         // Hide Specific
         else if (!show && $dimmer.length) {
            $dimmer.dimmer('hide', dimmerDone);
         }

         function dimmerDone() {
            setTimeout(function () {
               // console.log('[elLoader] Hidden', $el);
               resolve($el);
            }, 1000);
         }
      });
   },

   randomString: function (length) {
      return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
   },

   renderData: function ($dest, data) {
      const $els = $dest.find('[data-renderdata]');

      $els.each(function (i, el) {
         const $el = $(el);
         const cols = $el.data('renderdata').split('.');
         let val = data;

         // Nested properties
         cols.forEach((k, i) => {
            val = val ? val[k] : null;
            // console.log('\t'.repeat(i), k, val);
         });

         if (!val && val !== 0 && val !== false) {
            if ($el.hasClass('hideEmpty')) $el.hide();
            return;
         }

         val = val.toString();

         if (/^\d{4}\-\d{1,2}\-\d{1,2}[A-Z]\d{1,2}:\d{1,2}/.test(val)) {
            val = moment(val).utc().format('DD/MM/YYYY - HH:mm');
         }

         // console.log("Render data:", col, val, $el);

         // SET
         if ($el.is('.ui.multiple.dropdown')) $el.dropdown('set selected', JSON.parse(val));
         else if ($el.is('.ui.dropdown')) $el.dropdown('set selected', val);
         else if ($el.attr('type') == 'checkbox') $el[0].checked = val === true ? true : false;
         else if (['INPUT', 'TEXTAREA'].includes($el.prop('tagName'))) $el.val(val);
         else $el.text((val ? val : '--'));
      });

      return $dest;
   },

   readData: function ($source, data) {
      const $els = $source.find('[data-renderdata]');
      const pageData = {};

      $els.each(function (i, el) {
         const $el = $(el);
         const tag = $el.prop('tagName');
         const col = $el.data('renderdata');

         // SET
         if ($el.is('.ui.multiple.dropdown')) pageData[col] = JSON.stringify($el.dropdown('get values'));
         else if ($el.is('.ui.dropdown')) pageData[col] = $el.dropdown('get value');
         else if ($el.attr('type') == 'checkbox') pageData[col] = $el[0].checked;
         else if (['INPUT', 'TEXTAREA'].includes(tag)) pageData[col] = $el.val();
      });

      return pageData;
   },
   makeTitleInput: async function ($els) {
      const epgTeams = await client.service('epg-team-abbreviations').find({ query: { $limit: 10000 } });

      $els.each(function (i, el) {
         const $el = $(el);
         const $search = $(`
            <div class="ui search">
               <div class="ui icon input">
                  <i class="search icon"></i>
               </div>
               <div class="results"></div>
            </div>
         `).insertAfter($el);

         $el.prependTo($search.find('.ui.input'));
         $el.addClass('prompt');

         $search.search({
            type: 'category',
            showNoResults: false,
            searchDelay: 500,
            apiSettings: {
               responseAsync: function (settings, callback) {
                  const query = settings.urlData.query;

                  // do any asynchronous task here
                  getPossibleMatches(query, epgTeams.data).then(function (matches) {
                     console.log(matches);
                     callback(matches);

                  });
               }
            }
         });
      });

      async function getPossibleMatches(query, epgTeams) {
         const results = { success: true, results: {} };

         // Solo se è una partita
         if (!/(-|\sVS\s)/.test(query)) return results;

         // Se ho 2 parti di testo
         const rex = /(.*)(?:-|\sVS\s)(.*)/.exec(query);
         if (!rex[1] || !rex[2] || rex[2].length < 3) return results;

         // Possibili Squadre
         const teamsHome = await searchPossibleTeamLocal(rex[1], epgTeams);
         const teamsAway = await searchPossibleTeamLocal(rex[2], epgTeams);
         let possibleMatches = [];

         teamsHome.forEach(t => {
            // Solo se è lo stesso genere
            const arr = teamsAway.filter(ta => ta.Genere == t.Genere);
            arr.forEach(a => {
               possibleMatches.push([t, a]);
            });
         });

         // Ordino per somma di score
         possibleMatches = possibleMatches.sort((a, b) => (b[0].score.score + b[1].score.score) - (a[0].score.score + a[1].score.score));
         console.log('possibleMatches', possibleMatches);

         const generi = [... new Set(possibleMatches.map(m => m[0].Genere))];
         generi.forEach(g => {

            results.results[g] = {
               name: g,
               results: [... new Set(
                  possibleMatches
                     .filter(m => m[0].Genere == g)
                     .map(m => m[0].Esatto + ' - ' + m[1].Esatto)
               )]
                  .map(mt => ({ title: mt }))
            }
         });

         console.log(results)
         return results;

         async function searchPossibleTeamLocal(txt, teams) {
            teams = JSON.parse(JSON.stringify(teams));
            teams.forEach(t => {
               const scores = [
                  { field: 'Nome', ...myFunc.getStringSimilarityScore(txt, t.Nome) },
                  { field: 'Ottimizzato', ...myFunc.getStringSimilarityScore(txt, t.Ottimizzato) },
                  { field: 'Esatto', ...myFunc.getStringSimilarityScore(txt, t.Esatto) },
               ];

               t.score = scores.sort((a, b) => b.score - a.score)[0];
            });

            return teams.sort((a, b) => b.score.score - a.score.score).slice(0, 10);

         }

         async function searchPossibleTeam(txt) {
            const clean = myFunc.cleanTxt(txt, '%', true);
            const teams = await client.service('epg-team-abbreviations').find({
               query: {
                  $limit: 1000,
                  $or: [
                     { Nome: { $like: '%' + clean + '%' } },
                     { Ottimizzato: { $like: '%' + clean + '%' } },
                     { Esatto: { $like: '%' + clean + '%' } }
                  ]
               }
            });

            teams.data.forEach(t => {
               const scores = [
                  { field: 'Nome', ...myFunc.getStringSimilarityScore(txt, t.Nome) },
                  { field: 'Ottimizzato', ...myFunc.getStringSimilarityScore(txt, t.Ottimizzato) },
                  { field: 'Esatto', ...myFunc.getStringSimilarityScore(txt, t.Esatto) },
               ];

               t.score = scores.sort((a, b) => b.score - a.score)[0];
            });

            return teams.data.sort((a, b) => b.score.score - a.score.score);
         }
      }

   },
   makeCalendar: function ($els, type, action) {
      const baseOpt = {
         on: 'click',
         ampm: false,
         multiMonth: 1,
         text: {
            days: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
            months: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
            monthsShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
            today: 'Oggi',
            now: 'Ora',
            am: 'AM',
            pm: 'PM',
         },
         popupOptions: {
            forcePosition: true,
            position: 'bottom left',
            hideOnScroll: false,
         },
         // formatter: {
         //   date: function( date, settings ) {
         //     if ( !date ) return '';
         //     const day = date.getDate();
         //     const month = date.getMonth() + 1;
         //     const year = date.getFullYear();
         //     return day + '/' + month + '/' + year;
         //   },
         // },
      };

      $els.each(function (i, el) {
         const $el = $(el);
         $el.closest('.field, .item').addClass('myCalendar');
         $el.closest('.field, .item').css('position', 'relative');

         // Start Calendar
         const myCal = $('<div class="ui calendar start"><input type="text" name="start" style="display:none;"></div>').insertAfter($el);



         // Type Range
         if (type == 'range') {
            const endCal = $('<div class="ui calendar end"><input type="text" name="end" style="display:none;"></div>').insertAfter(myCal);

            myCal.calendar(Object.assign({}, baseOpt, {
               type: 'date',
               endCalendar: endCal,
               onChange: function (date, val, type) {
                  const sd = moment(date);
                  $el.val('dal:' + sd.format('DD/MM/YY') + ' al...');
                  endCal.calendar('set date', null, true, false);
               },
            }));

            endCal.calendar(Object.assign({}, baseOpt, {
               type: 'date',
               startCalendar: myCal,
               onShow: function (el) {
                  const sd = endCal.calendar('get startDate');
                  endCal.calendar('set focusDate', sd);
               },
               onChange: function (date, val, type) {
                  const sd = moment($(this).calendar('get startDate'));
                  const ed = moment(date);

                  if (ed._isValid == false) return;
                  // console.log('Calendar Change End', sd.format('DD/MM/YY'), ed.format('DD/MM/YY'), ed);

                  $el[0].dataset.startdate = sd.format('YYYY-MM-DD');
                  $el[0].dataset.enddate = ed.format('YYYY-MM-DD');
                  $el.val('dal:' + sd.format('DD/MM/YY') + ' al:' + ed.format('DD/MM/YY'));
                  if (action) action(sd.format('YYYY-MM-DD'), ed.format('YYYY-MM-DD'));
               }
            }));

            $el.on('click', function (e) {
               myCal.calendar('popup', 'show');
            });

            // Hide on click
            $('body').on('click', function (e) {
               const $target = $(e.target);
               if (!$target.closest('.myCalendar').length) {
                  myCal.calendar('popup', 'hide');
                  endCal.calendar('popup', 'hide');
               }
            });
         }

         // Type Month
         if (type == 'month') {
            myCal.calendar({
               type: 'month',
               onChange: function (date, val, type) {
                  const sd = moment(date).startOf('month');
                  const ed = moment(date).endOf('month');

                  if (ed._isValid == false) return;
                  // console.log('Calendar Change End', sd.format('DD/MM/YY'), ed.format('DD/MM/YY'), ed);

                  $el[0].dataset.startdate = sd.format('YYYY-MM-DD');
                  $el[0].dataset.enddate = ed.format('YYYY-MM-DD');

                  $el.val(sd.format('MMMM YYYY'));
                  if (action) action(sd.format('YYYY-MM-DD'), ed.format('YYYY-MM-DD'));
               },
            });

            $el.on('click', function (e) {
               console.log('Calendar Show Call');
               myCal.calendar('popup', 'show');

            });
         }

         // Type date
         if (!type || type == 'date') {
            myCal.calendar({
               type: 'date',
               onChange: function (date, val, type) {
                  const sd = moment(date).startOf('day');

                  if (ed._isValid == false) return;
                  // console.log('Calendar Change End', sd.format('DD/MM/YY'), ed.format('DD/MM/YY'), ed);

                  $el[0].dataset.startdate = sd.format('YYYY-MM-DD');

                  $el.val(sd.format('DD/MM/YY'));
                  if (action) action(sd.format('YYYY-MM-DD'));
               },
            });

            $el.on('click', function (e) {
               console.log('Calendar Show Call');
               myCal.calendar('popup', 'show');

            });
         }

      });
   },

   makeTextArea: function ($els, epgcData) {
      // console.log(epgcData);
      $els.each(function (i, ta) {
         const $ta = $(ta);
         const $fi = $ta.closest('.field');
         const undo = $ta.val();
         const max = $ta.data('max') ? parseInt($ta.data('max')) : false;

         if ($fi.find('.ui.menu').length > 0) return;

         $ta.data('undo', undo);
         $ta.css({ 'padding-top': '16px' });
         $fi.css({ 'position': 'relative' });

         const toAdd = `
        <div class="ui mini text compact menu" style="position:absolute; top:-8px; right:12px;">
          <div class="item icon undo" title="Ripristina"><i class="undo alternate icon"></i></div>
          <div class="item icon copy" title="Copia"><i class="copy icon"></i></div>
          <div class="item icon paste" title="Incolla"><i class="paste icon"></i></div>
          <div class="item icon plus" title="Zoom In"><i class="search plus icon"></i></div>
          <div class="item icon minus" title="Zoom Out"><i class="search minus icon"></i></div>
        </div>

        <div class="ui mini horizontal divided list" style="position:absolute; top:14px; right:18px; z-index:1;">
          ${epgcData ? `<div class="item charsepg a_blue" title="Caratteri EPG attuali/massimi">${0}${max ? ' / ' + max : ''}</div>` : ''}
          <div class="item chars"  title="Caratteri attuali/massimi">${0}${max ? ' / ' + max : ''}</div>
        </div>`;

         $fi.append(toAdd);

         const $menu = $fi.find('.ui.menu');
         const $count = $fi.find('.ui.list');

         $menu.find('.copy').on('click', function () {
            $ta[0].select();
            document.execCommand('copy');
         });

         $menu.find('.paste').on('click', async function () {
            try {
               const text = await navigator.clipboard.readText();
               if (text) $ta.val(text);
            } catch (err) {
               console.error('Failed to copy!', err);
            }
         });

         $count.find('.charsepg')
            .on('mouseenter', async function () {
               $(this).addClass('a_blue');
               const epgc = $ta.data('epgc');
               const text = `<span class="a_blue">${epgc.pre}</span>${epgc.txt}<span class="a_blue">${epgc.post}</span>`;

               const $taOver = $(`<div class="myTextAreaOver epg">${text}</div>`).appendTo($fi);
               $taOver.css({
                  'font-size': $ta.css('font-size'),
                  'line-height': $ta.css('line-height'),
                  'padding': $ta.css('padding'),
                  'top': $ta.position().top,
                  'left': $ta.position().left,
                  'width': $ta.outerWidth(),
               }).scrollTop($ta.scrollTop());

            }).on('mouseleave', async function () {
               $fi.find('.myTextAreaOver.epg').remove();
               $fi.find('.charsepg').removeClass('a_blue');

            });

         $menu.find('.undo')
            .on('click', async function () {
               $ta.val($ta.data('undo'));
               $fi.find('.myTextAreaOver').remove();
               $fi.find('.undo').removeClass('a_blue');
            }).on('mouseenter', async function () {
               $(this).addClass('a_blue');

               let text = '';
               const diff = Diff.diffWordsWithSpace($ta.val(), $ta.data('undo'));
               diff.forEach((part) => {
                  const color = part.added ? 'a_green' :
                     part.removed ? 'a_red a_deleted' : '';
                  text += `<span class="${color}">${part.value}</span>`;
               });

               const $taOver = $(`<div class="myTextAreaOver diff">${text}</div>`).appendTo($fi);
               $taOver.css({
                  'font-size': $ta.css('font-size'),
                  'line-height': $ta.css('line-height'),
                  'padding': $ta.css('padding'),
                  'top': $ta.position().top,
                  'left': $ta.position().left,
                  'width': $ta.outerWidth(),
               }).scrollTop($ta.scrollTop());
            }).on('mouseleave', async function () {
               $fi.find('.myTextAreaOver.diff').remove();
               $fi.find('.undo').removeClass('a_blue');
            });

         $menu.find('.plus').on('click', async function () {
            const size = $ta.css('font-size');
            const newSize = parseInt(size.replace('px', '')) + 2;
            $ta.css('font-size', newSize + 'px');

            $fi.find('.myTextAreaOver').css({
               'font-size': $ta.css('font-size'),
               'line-height': $ta.css('line-height'),
               'padding': $ta.css('padding'),
               'top': $ta.position().top,
               'left': $ta.position().left,
               'width': $ta.outerWidth(),
            }).scrollTop($ta.scrollTop());
         });

         $menu.find('.minus').on('click', async function () {
            const size = $ta.css('font-size');
            const newSize = parseInt(size.replace('px', '')) - 2;
            $ta.css('font-size', newSize + 'px');

            $fi.find('.myTextAreaOver').css({
               'font-size': $ta.css('font-size'),
               'line-height': $ta.css('line-height'),
               'padding': $ta.css('padding'),
               'top': $ta.position().top,
               'left': $ta.position().left,
               'width': $ta.outerWidth(),
            }).scrollTop($ta.scrollTop());
         });

         //
         $ta.on('change keyup', function () {
            const val = cleanVal($ta.val());
            $count.find('.chars').html(`${val.length}${max ? ' / ' + max : ''}`);

            if (max) {
               const len = val.length;
               const per = Math.ceil(len / max * 100);

               if (per > 100) {
                  $fi.addClass('error').removeClass('warning success');
               } else if (per > 75 && per <= 100) {
                  $fi.addClass('success').removeClass('warning error');
               } else if (per > 50 && per <= 75) {
                  $fi.addClass('warning').removeClass('error success');
               } else {
                  $fi.removeClass('error warning success');
               }
            }

            if (epgcData) {
               myFunc.epgCharCount(val, epgcData).then((epgc) => {
                  $count.find('.charsepg').html(`${epgc.epgtxt.length} / ${epgc.realmax}`);
                  $ta.data('epgc', epgc);
               });
            }
         });

         $ta.on('focusout', function () {
            const val = cleanVal($ta.val());
            $count.find('.chars').html(`${val.length}${max ? ' / ' + max : ''}`);
            if (epgcData) {
               myFunc.epgCharCount(val, epgcData).then((epgc) => {
                  $count.find('.charsepg').html(`${epgc.epgtxt.length} / ${epgc.realmax}`);
                  $ta.data('epgc', epgc);
               });
            }

            $ta.val(val);
            $ta.removeClass('highlight');
         });

         $ta.on('focusin', function () {
            $ta.addClass('highlight');
         });

         $fi.on('mouseenter', '.myTextAreaOver', function () {
            $(this).hide();
         });

         $fi.on('mouseleave', function () {
            $(this).find('.myTextAreaOver').show();
         });

         // Clean VAL
         function cleanVal(txt) {
            clean = txt.replace(/ +(?= )/g, '').trim();

            return clean;
         };

         $ta.trigger('focus');
         $ta.trigger('focusout');
         $ta.data('ready', true);


         // $(this).on('keyup', function () {
         //   var val = $(this).val();

         //   //percentuale
         //   var char = val.length,
         //     perc = Math.ceil(char / max * 100);
         //   bc.html($(this).val().length + ' / ' + max);
         //   if (perc > 100) {
         //     //console.log('Percentuale caratteri:'+perc + ' (più di 100)')
         //     bc.addClass('red').removeClass('green yellow');
         //     el.addClass('error').removeClass('warning success');
         //     //bar.addClass('red').removeClass('green yellow');
         //     perc = 100;
         //   } else if (perc > 75 && perc <= 100) {
         //     //console.log('Percentuale caratteri:'+perc + ' (da 76 a 100)')
         //     bc.addClass('green').removeClass('red yellow');
         //     el.addClass('success').removeClass('warning error');
         //     //bar.addClass('green').removeClass('red yellow');
         //   } else if (perc > 50 && perc <= 75) {
         //     //console.log('Percentuale caratteri:'+perc + ' (da 51 a 75)')
         //     bc.addClass('yellow').removeClass('green red');
         //     el.addClass('warning').removeClass('error success');
         //     //bar.addClass('yellow').removeClass('green red');
         //   } else {
         //     bc.removeClass('green red yellow');
         //     el.removeClass('error warning success');
         //   }
         //   //bar.progress('set percent', perc);
         // });
      });
   },

   epgCharCount: async function (text, epgcData, campo, idProgram, idEpisode) {
      if (!myVars.channels.tutti) await myVars.channels.load();

      // Get Data
      if (campo) {
         let pJoin;
         if (epgcData && epgcData.hasOwnProperty('onAirChannel')) pJoin = epgcData;
         else if (idProgram) pJoin = await client.service('program-join').get(idProgram, { query: { add: 'episodes, onAirChannel, persons' } });
         const ep = pJoin.episodes.find((e) => e.ID == (idEpisode ? idEpisode : 0));

         epgcData = {
            // TUTTI
            canale: pJoin.onAirChannel,
            campo: campo,
            categoria: pJoin.program.Category_ID,
            genere: pJoin.program.Genres && pJoin.program.Genres.length ? pJoin.program.Genres[0].id : null,
            // FILM
            persons: pJoin.persons,
            country: pJoin.program.Country,
            year: pJoin.program.Year,
            // SERIE
            stagione: pJoin.program.SeriesNumber,
            episodio: ep ? ep.episodioNumerico : '',
            titoloEpisodio: ep ? ep.Title : '',
         };

         console.log(pJoin, epgcData);
      }

      const epgc = {
         max: 450,
         pre: '',
         post: '',
         txt: text,
         epgtxt: '',
      };

      // MAX CHARS
      if (myVars.channels.skyCinema.find((c) => c.ID == epgcData.canale)) {
         // è un canale Cinema
         epgc.max = 185;

      } else if (myVars.channels.skySport.find((c) => c.ID == epgcData.canale)) {
         // è un canale Sport
         epgc.max = 180;

      } else {
         // tutti gli altri
         epgc.max = 200;

      }

      // -7 caratteri n di tot puntate, episodi

      // PRE POST SINOSSI
      if ([1, 23].includes(epgcData.categoria)) {
         // Se è un Film o un TV Movie
         if (epgcData.campo == 'tramaEPG' && myVars.channels.skyCinema.find((c) => c.ID == epgcData.canale)) epgc.post = getPostSinossiFilm(epgcData);
         else epgc.pre = getPreSinossiFilm(epgcData);

      } else if ([2, 12].includes(epgcData.categoria) || ([13].includes(epgcData.categoria))) {
         // Se è un Documentario, un Telefilm o un Reality Show
         epgc.pre = getPreSinossiTelefim(epgcData);

      } else {
         epgc.pre = getPreSinossi(epgcData);
      }

      // RETURN
      epgc.pre = cleanTxt(epgc.pre);
      epgc.post = cleanTxt(epgc.post);
      epgc.txt = cleanTxt(epgc.txt);
      epgc.epgtxt = epgc.pre + epgc.txt + epgc.post;
      epgc.realmax = epgc.max - epgc.pre.length - epgc.post.length;

      return epgc;

      // FUNZIONI
      function getPreSinossiFilm(data) {
         // Regia di [], con [], []; USA 1999.
         if (!data.persons) data.persons = [];
         const reg = [];// data.persons.filter((p) => p.Ruolo == 'Regista');
         const att = [];// data.persons.filter((p) => p.Ruolo == 'Attore');
         const naz = data.country;
         const anno = data.year;

         let pre = '';

         if (reg && reg.length > 0) {
            pre += 'Regia di ';
            pre += reg.map((r, i) =>
               i < 2 ?
                  (r.Surname ? r.Name.replace(/(^.)(.*)/gi, '$1') + '. ' + r.Surname.trim() : r.Name.trim()) :
                  ' ')
               .join(', ').replace(/[\s,]*$/gi, '');
         }

         if (att && att.length > 0) {
            if (pre) pre += ', ';

            pre += 'con ';
            pre += att.map((r, i) =>
               i < 2 ?
                  (r.Surname ? r.Name.replace(/(^.)(.*)/gi, '$1') + '. ' + r.Surname.trim() : r.Name.trim()) :
                  ' ')
               .join(', ').replace(/[\s,]*$/gi, '');
         }

         if (naz && naz.length > 0) {
            if (pre) pre += '; ';
            if (naz.length > 3) naz.length = 3;
            pre += naz.join('/').toUpperCase();
         }

         if (anno) {
            if (pre) pre += ' ';
            pre += anno;
         }

         if (pre) pre += '. ';

         return pre;
      }

      function getPostSinossiFilm(data) {
         const naz = data.country;
         const anno = data.year;

         let post = '';

         if (naz && naz.length > 0) {
            if (naz.length > 3) naz.length = 3;
            post += naz.join('/').toUpperCase();
         }

         if (anno) {
            if (post) post += ' ';
            post += anno;
         }

         if (post) post = ' (' + post + ')';

         return post;
      }

      function getPreSinossiTelefim(data) {
         // S[]
         let titoloP = data.titoloEpisodio;
         const ep = data.episodio;
         const st = data.stagione;
         const ch = data.canale;

         let pre = '';

         if (st) {
            // canale 326 BBC Prime
            if (ch == 326) {
               const lastN = st.toString().match(/(\d{1})(?!.*\d{1})/gi)[0];
               if (lastN == 1) pre += st + 'st';
               else if (lastN == 2) pre += st + 'nd';
               else if (lastN == 3) pre += st + 'rd';
               else pre += st + 'th';
               pre += ' season';
            } else {
               pre += 'S' + st;
            }
         }

         if (ep) {
            // Toglie Ep.n in testa al titolo puntata
            if (titoloP) titoloP = titoloP.replace(/^ep\.\s?\d*\b/gi, '').trim();

            pre += (pre ? ' ' : '') + 'Ep' + ep;
         }

         if (titoloP) {
            // Toglie . e - in testa e alla fine del titolo puntata...
            titoloP = titoloP.replace(/(?:^([\.\-])[^.]|[^..]([\.\-])$)/gi, '').trim();
            pre += (pre ? ' ' : '') + titoloP;
         }

         pre = pre.trim();

         return (pre ? pre + ' - ' : '');
      }

      function getPreSinossi(turno, sede) {
         return '';
      }

      function cleanTxt(txt) {
         txt = txt.replace(/\b1°[\/|-]2°/gi, 'I-II')
            .replace(/\b3°[\/|-]4°/gi, 'III-IV')
            .replace(/\b5°[\/|-]6°/gi, 'V-VI')
            .replace(/\b7°[\/|-]8°/gi, 'VII-VIII')

            .replace(/360°/gi, '360 gradi')
            .replace(/270°/gi, '270 gradi')
            .replace(/180°/gi, '180 gradi')
            .replace(/90°/gi, '90 gradi')
            .replace(/45°/gi, '45 gradi')

            .replace(/\b1°/gi, '1mo')
            .replace(/\b2°/gi, '2ndo')
            .replace(/\b3°/gi, '3rzo')
            .replace(/\b4°/gi, '4rto')
            .replace(/\b5°/gi, '5nto')
            .replace(/\b6°/gi, '6sto')
            .replace(/\b7°/gi, '7mo')
            .replace(/\b8°/gi, '8vo')
            .replace(/\b9°/gi, '9no')
            .replace(/\b10°/gi, '10mo')
            .replace(/(\d{2,})°/gi, '$1esimo')
            .replace(/°/gi, 'o')

            .replace(/[àá](\s)/g, 'a\'$1')
            .replace(/[ÀÁ](\s)/g, 'A\'$1')
            .replace(/[èé](\s)/g, 'e\'$1')
            .replace(/[ÈÉ](\s)/g, 'E\'$1')
            .replace(/[ìí](\s)/g, 'i\'$1')
            .replace(/[ÌÍ](\s)/g, 'I\'$1')
            .replace(/[òó](\s)/g, 'o\'$1')
            .replace(/[ÒÓ](\s)/g, 'O\'$1')
            .replace(/[ùú](\s)/g, 'u\'$1')
            .replace(/[ÙÚ](\s)/g, 'U\'$1')

            .replace(/[àáâãäå]/g, 'a')
            .replace(/[ÀÁÂÃÄÅ]/g, 'A')
            .replace(/[èéêë]/g, 'e')
            .replace(/[ÈÉÊË]/g, 'E')
            .replace(/[ìíîï]/g, 'i')
            .replace(/[ÌÍÎÏ]/g, 'I')
            .replace(/[òóôõö]/g, 'o')
            .replace(/[ÒÓÔÕÖ]/g, 'O')
            .replace(/[ùúûü]/g, 'u')
            .replace(/[ÙÚÛÜ]/g, 'U')

            .replace(/[\"\“\”]/gi, '\'\'')
            .replace(/[\’\‘]/gi, '\'')
            .replace(/«/gi, '<<')
            .replace(/»/gi, '>>')
            .replace(/…/gi, '...')
            .replace(/&/gi, 'and')

            .replace(/\u2013|\u2014/g, '-') // normalizza trattini
            .replace(/\r?\n|\r/gi, ' ') // Toglie tutti gli a capo
            .replace(/\s\s+/gi, ' '); // Toglie spazi in eccesso

         return txt;
      }
   },

   getAttributesFromMask: function (mask) {
      if (!myVars || !myVars.attributes || !myVars.attributes.tutti || !myVars.attributes.tutti.length) {
         console.error('!!! Caricare prima gli attributi');
         return [];
      }

      mask = parseInt(mask);
      const attrArr = [];

      myVars.attributes.tutti.forEach((attr) => {
         const bitPosition = 1 << attr.bitPosition;
         if ((bitPosition & mask) != 0) attrArr.push(attr);
      });

      // console.log('getAttributesFromMask', attrArr);
      return attrArr;
   },

   getMaskFromAttributes: function (attr) {
      if (!attr) return 0;
      // 536870912 Sottotitoli per non udenti(29)
      // 805306368 16:9(28) e Sottotitoli per non udenti(29) 1<<29
      if (!myVars || !myVars.attributes || !myVars.attributes.tutti || !myVars.attributes.tutti.length) {
         console.error('!!! Caricare prima gli attributi');
         return [];
      }

      if (!Array.isArray(attr)) attr = [attr];
      let mask = 0;

      attr.forEach(a => {
         let bitPosition = null;
         if (typeof a == 'string') {
            let ao = myVars.attributes.tutti.find(at => a.toLowerCase() == at.Name.toLowerCase());
            bitPosition = ao ? ao.bitPosition : null;
         } else if (!isNaN(a)) {
            bitPosition = a;
         }

         if (bitPosition || bitPosition == 0) {
            mask += 1 << bitPosition;
         }

      });

      // console.log('[getMaskFromAttributes]', mask);
      return mask;
   },

   sendXHRequest: function (formData, uri, $el) {
      return new Promise(function (resolve, reject) {
         const token = client ? myAuth.getCookie('tools.datatv.it') : false;
         if (!token) reject(new Error('Upload Non autorizzato.'));

         const $upProgress = $el && $el.length > 0 ?
            $(`<div class="ui active inverted dimmer uploadProgress">
            <div class="ui blue progress" style="width:300px;">
              <div class="bar"></div>
              <div class="label">...</div>
            </div>
          </div>
        `).appendTo($el) : false;

         $('.uploadProgress .progress').progress({
            percent: 2,
         });

         // Get an XMLHttpRequest instance
         const xhr = new XMLHttpRequest();

         // Set up events
         xhr.upload.addEventListener('loadstart', handleLoadStart, false);
         xhr.upload.addEventListener('progress', handleProgress, false);
         xhr.addEventListener('loadend', handleOnloadEnd, false);

         // Set up request
         xhr.open('POST', uri, true);
         // Auth
         xhr.setRequestHeader('Authorization', 'Bearer ' + token);
         // Fire!
         xhr.send(formData);

         // Handle the start of the transmission
         function handleLoadStart(evt) {
            $('.uploadProgress .progress').progress('set percent', 1);
            $('.uploadProgress .progress').progress('set label', `Inizio Upload di ${myFunc.readableBytes(evt.total)}...`);
            // console.log('Upload iniziato.');
         }

         // Handle the progress
         function handleProgress(evt) {
            const percent = Math.round(evt.loaded / evt.total * 100);
            $('.uploadProgress .progress').progress('set percent', percent);
            $('.uploadProgress .progress').progress('set label', `Caricati ${percent}% di ${myFunc.readableBytes(evt.total)}`);
            // console.log('Progress: ' + percent + '%');
         }

         // Handle the END
         function handleOnloadEnd(evt) {
            const res = JSON.parse(xhr.response);
            console.log('Fine Upload!', xhr.status, xhr.statusText, res);
            $upProgress.remove();

            if (xhr.status == 201 || xhr.statusText == 'Created') resolve(res);
            else reject(xhr.statusText);
         }
      });
   },

   updateOnlineUser(user) {
      const loadUsers = user == 'all' ? myVars.users.load() : Promise.resolve([user]);

      loadUsers.then((users) => {
         users.forEach((user) => {
            // if(user.online && user.id != myVars.io.id) console.log('ONLINE:', user.nome + ' ' + user.cognome);

            const $bg = $('.onlineUserBg_' + user.id);
            const $dt = $('.onlineUserDt_' + user.id);
            const $color = $('.onlineUserColor_' + user.id);

            if ($bg.length > 0) {
               if (user.online) $bg.addClass('green');
               else $bg.removeClass('green');
            }

            if ($color.length > 0) {
               if (user.online) $color.addClass('green');
               else $color.removeClass('green');
            }

            if ($dt.length > 0 && users.length == 1) {
               const $table = $dt.closest('.dataTable');
               const $tr = $dt.closest('tr');

               if ($table.length > 0) {
                  if (!$('.ui.modal').is(':visible')) {
                     // Questo non fa chidere le modal
                     $table.DataTable().row($tr).data(user).invalidate().draw();
                  }
               }
            }
         });
      });
   },

   isJSON: function (str) {
      try {
         const json = JSON.parse(str);
         const type = Object.prototype.toString.call(json).slice(8, -1);
         if (type !== 'Object' && type !== 'Array') return false;
      } catch (e) {
         return false;
      }

      return true;
   },

   getExtension: function (filename) {
      if(!filename) return '';
      const i = filename.lastIndexOf('.');
      let ext = (i < 0) ? '' : filename.substr(i + 1);

      if (ext) {
         if (ext.match(/(jpeg|jpg)/gi)) ext = 'jpg';
         else if (ext.match(/png/gi)) ext = 'png';
         else if (ext.match(/svg/gi)) ext = 'svg';
         else if (ext.match(/tif/gi)) ext = 'tiff';
      }

      return ext.toLowerCase();
   },

   readableBytes: function (bytes) {
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
   },

   orderObjByKey: function (obj, k, ad) {
      const sorted = obj.sort((a, b) => compare(a, b));
      return sorted;

      function compare(a, b) {
         if (!ad || ad == 'asc') {
            if (a[k] > b[k]) return 1;
            else return -1;
         } else if (ad == 'desc') {
            if (a[k] < b[k]) return 1;
            else return -1;
         }

         return 0;

         // if (a[k] < b[k]) {
         //   if(ad == 'asc') return 1;
         //   else return -1;

         // }

         // if (a[k] > b[k]) {
         //   if(ad == 'desc') return -1;
         //   else return 1;

         // }

         // return 0;
      }
   },

   chunkArray: function (arr, len) {
      const chunks = [];
      let i = 0;
      const n = arr.length;

      while (i < n) {
         chunks.push(arr.slice(i, i += len));
      }

      return chunks;
   },

   camelize: function (str) {
      if(!str) return '';
      const splitStr = str.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i++) {
         // You do not need to check if i is larger than splitStr length, as your for does that for you
         // Assign it back to the array
         splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
   },

   getLinkSchedaContent: function (data) {
      const link = 'https://content2.datatv.it/scheda.asp?nrr=1' +
         '&idRichiesta=' + data.Request_id +
         '&tipologia=' + data.Request_tipologia +
         '&titolo=' + encodeURIComponent(data.Request_titolo) +
         '&num_stagione=' + data.Request_num_stagione +
         '&episodio=' + data.Request_episodio +
         '&externalID=' + data.Request_externalID +
         '&tr=' + data.Request_TipoRichiesta +
         '&ethanFlag=' + data.Request_ethanFlag +
         '&anno=' + data.Request_anno_prod +
         '&ethanMetadatasets=' + data.Request_ethanMetadatasets +
         '&cliente=' + data.Request_ethanFlag +
         '&adm=' + (['Admin', 'Supervisor'].includes(myVars.io.ruolo) ? 'ok' : '');

      return link;
   },

   setZoom: function (zoom, el) {
      transformOrigin = [0, 0];
      el = el || instance.getContainer();
      const p = ['webkit', 'moz', 'ms', 'o'];
      const s = 'scale(' + zoom + ')';
      const oString = (transformOrigin[0] * 100) + '% ' + (transformOrigin[1] * 100) + '%';

      for (let i = 0; i < p.length; i++) {
         el.style[p[i] + 'Transform'] = s;
         el.style[p[i] + 'TransformOrigin'] = oString;
      }

      el.style['transform'] = s;
      el.style['transformOrigin'] = oString;
   },

   levenshtein: function (stringA, stringB) {
      // la più lunga stringa è la stringa A
      let a = stringA.length >= stringB.length ? stringB : stringA;
      let b = a == stringA ? stringB : stringA;

      a = a.replace(/(\r\n)+|\r+|\n+|\t+/gi, '↵').replace(/  /gi, ' ').toLowerCase();
      b = b.replace(/(\r\n)+|\r+|\n+|\t+/gi, '↵').replace(/  /gi, ' ').toLowerCase();

      if (a.length === 0) return '+'.repeat(b.length);
      if (b.length === 0) return '+'.repeat(a.length);

      const matrix = [];
      let diff = '';

      // increment along the first column of each row
      let i;
      for (i = 0; i <= b.length; i++) {
         matrix[i] = [i];
      }

      // increment each column in the first row
      let j;
      for (j = 0; j <= a.length; j++) {
         matrix[0][j] = j;
      }

      let r = ''; let min = 0;;

      // Fill in the rest of the matrix
      for (i = 1; i <= b.length; i++) {
         const currMin = min;
         min = a.length + 1;

         for (j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) == a.charAt(j - 1)) {
               matrix[i][j] = matrix[i - 1][j - 1];
            } else {
               matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                  Math.min(matrix[i][j - 1] + 1, // insertion
                     matrix[i - 1][j] + 1)); // deletion
            }

            if (matrix[i][j] < min) {
               min = matrix[i][j];
            }
         }

         if (min > currMin) {
            r += '#';
            diff += b[i - 1];
         } else {
            r += b[i - 1];
         }
      }
      return {
         diff: diff,
         long: b,
         shor: a,
         edit: r,
         perc: 100 - 100 * diff.length / (stringA.length + stringB.length)
      };
   },

   // Find Object by key/value
   customFilter: function (object, key, value) {
      if (Array.isArray(object)) {
         for (const obj of object) {
            const result = myFunc.customFilter(obj, key, value);
            if (result) {
               return result;
            }
         }
      } else {
         if (object.hasOwnProperty(key) && object[key] == value) {
            return object;
         }

         for (const k of Object.keys(object)) {
            if (object[k] && typeof object[k] === 'object') {
               const o = myFunc.customFilter(object[k], key, value);
               if (o !== null && typeof o !== 'undefined')
                  return o;
            }
         }

         return null;
      }
   },

   getArrayMostFrequent: function (arr) {
      const hashmap = arr.reduce((acc, val) => {
         acc[val] = (acc[val] || 0) + 1
         return acc
      }, {})
      return Object.keys(hashmap).reduce((a, b) => hashmap[a] > hashmap[b] ? a : b, 0)
   },

   getArrayDifference: function (arr1, arr2) {
      let difference = arr1
         .filter(x => !arr2.includes(x))
         .concat(arr2.filter(x => !arr1.includes(x)));

      return difference;
   },

   initCommonDrops: async function ($drops, dropOptions) {
      if (!dropOptions) {
         dropOptions = [
            {
               col: 'category',
               description: false,
               multiple: false,
               allowAdditions: false,
               url: `${mySite}/category?`,
               val: 'ID',
               txt: 'Name',
            }, {
               col: 'genre',
               description: false,
               multiple: true,
               allowAdditions: false,
               url: `${mySite}/genre?`,
               val: 'ID',
               txt: 'Name',
               fatherDrop: 'category',
            }, {
               col: 'country',
               description: false,
               multiple: true,
               allowAdditions: false,
               url: `${mySite}/country?`,
               val: 'ID',
               txt: 'Name',
            }, {
               col: 'content',
               description: false,
               multiple: true,
               allowAdditions: false,
               url: `${mySite}/content?`,
               val: 'ID',
               txt: 'Name',
            }, {
               col: 'attributeprogram',
               description: false,
               multiple: true,
               allowAdditions: false,
               url: `${mySite}/attributeprogram?`,
               val: 'ID',
               txt: 'Name',
            }
         ]
      }

      const promises = [];

      $drops.each((i, drop) => {
         const $drop = $(drop);
         const col = $drop.find('[data-drop]').attr('data-drop');
         const o = dropOptions.find((od) => od.col == col);

         if (!o) return;

         promises.push(new Promise(resolve => {

            let childOption = dropOptions.find(co => co.fatherDrop == col);
            $drop.data('dropOption', o);

            const initO = {
               filterRemoteData: !o.url ? true : false,
               allowAdditions: o.allowAdditions,
               forceSelection: false,
               apiSettings: !o.url || o.fatherDrop ? false : {
                  url: `${o.url}&$sort[${o.txt}]=1&${o.txt}[$like]={query}%`,
                  beforeSend: function (settings) {
                     settings.urlData.query = settings.urlData.query.replace(/ /gi, '_');
                     return settings;
                  },
                  onComplete: function (response, element, xhr) {
                     $drop.trigger('dropValsLoaded');
                     resolve(o.col);
                  },
                  onResponse: function (response) {
                     const newVals = {
                        'success': true,
                        'results': !response.data.length ? [] : response.data.map((r) => {
                           return { 'value': r[o.val], 'name': r[o.txt] };
                        }),
                     };
                     // console.log('loaded ---------', newVals);
                     return newVals;
                  },
               },
            };

            // On change del father cambio i valori del child
            if (childOption) {
               initO.onChange = async function (value, text, $choice) {
                  const $childDrop = $drops.find(`[data-drop="${childOption.col}"]`).closest('.ui.dropdown');
                  $childDrop.addClass('loading');
                  let newVals = [];

                  if (value) {
                     if (col == 'category') {
                        const genreCategory = await $.ajax(`${mySite}/genrecategory?IDCategory=${value}`);
                        newVals = await $.ajax(`${mySite}/genre?${genreCategory.data.map((g) => `ID[$in]=${g.IDGenre}`).join('&')}`);

                     } else {
                        newVals = await $.ajax(`${childOption.url}&$sort[${childOption.txt}]=1&${o.txt}=${value}`);

                     }

                     newVals = newVals.data.map((v) => {
                        return { 'value': v[childOption.val], 'name': v[childOption.txt] };
                     });
                  }

                  // console.log('fatherDrop Loaded', childOption.col, 'newVals', newVals);
                  $childDrop.dropdown('change values', newVals);
                  $childDrop.removeClass('loading').trigger('dropValsLoaded');
               };
            }

            // INIT
            $drop.dropdown(initO);
            if (!o.fatherDrop) {
               $drop.dropdown('queryRemote', '', function () { });
            } else {
               resolve(o.col + '(child)');
            }
         }));
      });

      let result = await Promise.all(promises);
      // console.log('/////////////////////// FINE initCommonDrops', $drops.length, result);
      return result;
   },

   escapeRegex: function (txt) {
      if (!txt) return '';
      else return txt.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
   },

   getStringSimilarityScore: function(searched, searchIn) {
      searched = searched.toLowerCase().trim();
      searchIn = searchIn.toLowerCase().trim();

      const sco = {
         score: 0,
         description: [],
         searched: searched,
         searchedClean: cleanTxt(searched, '#'),
         searchedWords: [],
         searchIn: searchIn,
         searchInClean: cleanTxt(searchIn, '#'),
         searchInWords: [],
         wordsFound: [],
         wordsFoundStr: [],
         lengthDiff: searched.length - searchIn.length,
      };

      // Normalized Edit Distance
      sco.editDistance = editDistance(searched, searchIn),
         sco.editDistanceNormalized = sco.editDistance / Math.max(searched.length, searchIn.length);

      // Posizioni Parole Trovate
      sco.searchInWords = sco.searchInClean.split(/\s|#/gi).filter((w) => w);//.filter((w) => w.length >= 2);
      sco.searchedWords = sco.searchedClean.split(/\s|#/gi).filter((w) => w);//.filter((w) => w.length >= 2);
      sco.searchedWords.forEach((sw, j) => {
         let i = sco.searchInWords.findIndex(iw => {
            let found = false;

            if (iw == sw)
               found = true;

            if (!found) {
               const ed = editDistance(sw, iw) / Math.max(sw.length, iw.length);
               if (ed < 0.175) found = true;
            }

            if (found)
               sco.wordsFoundStr.push(iw);

            return found;
         });

         if (i > -1 && !sco.wordsFound.includes(i)) {
            sco.wordsFound.push(i);
         }
      });

      // Flags Modificatori
      sco._matchFull = sco.searchedClean == sco.searchInClean;
      sco._matchInFront = new RegExp(`^\\b${myFunc.escapeRegex(sco.searchedClean)}.?\\b`, 'gi').test(sco.searchInClean);
      sco._matchInside = new RegExp(`\\b${myFunc.escapeRegex(sco.searchedClean)}.?\\b`, 'gi').test(sco.searchInClean);
      sco._searchInFront = new RegExp(`^\\b${myFunc.escapeRegex(sco.searchInClean)}.?\\b`, 'gi').test(sco.searchedClean);
      sco._searchInside = new RegExp(`\\b${myFunc.escapeRegex(sco.searchInClean)}.?\\b`, 'gi').test(sco.searchedClean);
      sco._wordsSorted = sco.wordsFound.length <= 1 ? 0 : sco.wordsFound.filter((e, i) => (i == 0 && sco.wordsFound[i + 1] > e) || e > sco.wordsFound[i - 1]).length;
      sco._wordsConsecutive = sco.wordsFound.length <= 1 ? 0 : sco.wordsFound.filter((e, i) => e + 1 == sco.wordsFound[i + 1]).length;
      sco._wordsAllSearched = sco.wordsFound.length == sco.searchedWords.length;
      sco._wordsAllSearchIn = sco.wordsFound.length == sco.searchInWords.length;
      sco._wordsAll = sco.wordsAllSearched && sco.wordsAllSearchIn ? true : false;

      if (sco._matchFull) {
         sco.description.push(`Match Completo`);
         sco.score += 7;

      } else if (sco._wordsAll && sco._wordsSorted == sco.wordsFound.length) {
         sco.description.push(`Tutte le parole del Titolo Presenti in ordine`);
         sco.score += 6;

      } else if (sco._wordsAll) {
         sco.description.push(`Tutte le parole del Titolo Presenti`);
         sco.score += 5;

      } else if (sco._matchInFront) {
         sco.description.push(`Ricerca all'inizio del Titolo`);
         sco.score += 4;

      } else if (sco._matchInside) {
         sco.description.push(`Ricerca contenuta nel Titolo`);
         sco.score += 3;

      } else if (sco._wordsAllSearched && sco._wordsSorted == sco.wordsFound.length) {
         sco.description.push(`Tutte le parole della Ricerca presenti in ordine`);
         sco.score += 2;

      } else if (sco._wordsAllSearched) {
         sco.description.push(`Tutte le parole della Ricerca presenti`);
         sco.score += 1;

      } else if (sco.wordsFound.length && sco._wordsConsecutive) {
         sco.description.push(`Alcune Parole Presenti e consecutive`);
         sco.score += sco.wordsFound.length / 10 + 0.02;

      } else if (sco.wordsFound.length && sco._wordsSorted) {
         sco.description.push(`Alcune Parole Presenti in ordine`);
         sco.score += sco.wordsFound.length / 10 + 0.01;

      } else if (sco.wordsFound.length) {
         sco.description.push(`Alcune Parole Presenti`);
         sco.score += sco.wordsFound.length / 10;

      }

      // Malus per la differenza di lunghezza
      sco.score -= sco.editDistanceNormalized / 10;
      return sco;

      function cleanTxt(txt, placeholder) {
         if (!txt) return '';
         return txt
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // rimuove gli accenti
            .replace(/[^A-Z0-9 ]/gi, placeholder === false ? '' : '#') // sostituisce quello che non sono lettere numeri spazi con #
            .replace(/  /gi, ' ') // elimina doppi spazi
            .trim()
            .toLowerCase();
      }

      function editDistance(first, second) {
         const array = [];
         const characterCodeCache = [];

         if (first === second) {
            return 0;
         }

         const swap = first;

         // Swapping the strings if `a` is longer than `b` so we know which one is the
         // shortest & which one is the longest
         if (first.length > second.length) {
            first = second;
            second = swap;
         }

         let firstLength = first.length;
         let secondLength = second.length;

         // Performing suffix trimming:
         // We can linearly drop suffix common to both strings since they
         // don't increase distance at all
         // Note: `~-` is the bitwise way to perform a `- 1` operation
         while (firstLength > 0 && (first.charCodeAt(~-firstLength) === second.charCodeAt(~-secondLength))) {
            firstLength--;
            secondLength--;
         }

         // Performing prefix trimming
         // We can linearly drop prefix common to both strings since they
         // don't increase distance at all
         let start = 0;

         while (start < firstLength && (first.charCodeAt(start) === second.charCodeAt(start))) {
            start++;
         }

         firstLength -= start;
         secondLength -= start;

         if (firstLength === 0) {
            return secondLength;
         }

         let bCharacterCode;
         let result;
         let temporary;
         let temporary2;
         let index = 0;
         let index2 = 0;

         while (index < firstLength) {
            characterCodeCache[index] = first.charCodeAt(start + index);
            array[index] = ++index;
         }

         while (index2 < secondLength) {
            bCharacterCode = second.charCodeAt(start + index2);
            temporary = index2++;
            result = index2;

            for (index = 0; index < firstLength; index++) {
               temporary2 = bCharacterCode === characterCodeCache[index] ? temporary : temporary + 1;
               temporary = array[index];
               // eslint-disable-next-line no-multi-assign
               result = array[index] = temporary > result ? (temporary2 > result ? result + 1 : temporary2) : (temporary2 > temporary ? temporary + 1 : temporary2);
            }
         }

         return result;
      }
   },

   // /////////////////////////////////////////////////////////////////////////// FILE EXPLORER
   fileExplorer: async function ($dest, startPath, fileAction, extensionsArray, gmailMaxresults, height) {
      let type = null;

      if (startPath && startPath.indexOf('@datatv.it') > -1) {
         type = {
            source: 'gmail',
            color: 'red',
            icon: 'google'
         };
      } else if (startPath && startPath.indexOf('import-palinsesti-files') > -1) {
         type = {
            source: 'uploads',
            color: 'grey',
            icon: 'upload'
         };
      } else {
         type = {
            source: 'mnt',
            color: 'brown',
            icon: 'server'
         };
      }

      // HTML di base
      let $exp = $(`
         <div class="fileExplorer">
            <div class="ui top attached secondary segment ${type.color}">
              <div class="ui small breadcrumb">Loading...</div>
              <div class="ui small icon input search" style="width: 160px;">
                  <input type="text" placeholder="Cerca...">
                  <i class="search link icon"></i>
              </div>
            </div>
            <div class="ui attached segment tableContainer" style="height: ${height}px;">
               <table class="ui small compact selectable unstackable fixed single line attached table filesTable"></table>
            </div>
            <div class="ui bottom attached secondary mini right aligned segment totalFiles" style="margin-bottom:0;">
               Loading...
            </div>
         </div>
      `);

      $dest.replaceWith($exp);

      let $bread = $exp.find('.breadcrumb');
      let $search = $exp.find('.search input');
      let $tableContainer = $exp.find('.tableContainer');
      let $totalFiles = $exp.find('.totalFiles');
      let $table = $exp.find('.filesTable');

      $tableContainer.addClass('loading');

      if (type.source == 'gmail') {
         // Refresh on new gmail messages
         console.log('Registering Gmail Refresh event!');
         client.service('gmail').removeListener('newDumpedMessages');
         client.service('gmail').on('newDumpedMessages', (messages) => {
            console.log('GMAIL REFRESH !!!!!!!', messages);
            myFunc.fileExplorer($exp, startPath, fileAction, extensionsArray, false, height);
         })
      }

      // GET STARTFILES
      let startFiles = [];
      let currPath = null;
      if (type.source == 'mnt') {
         currPath = startPath ? startPath : '/mnt';

         // prendo l'ultima cartella (per palinsesti pls_2022)
         let pal = await client.service('file-explorer')
            .get('list', { query: { path: currPath } });

         if (pal && pal.length && currPath != '/mnt/palinsesti/01 - Discovery Lis') {
            let lastFolder = pal.sort((a, b) => b.stat.mtimeMs - a.stat.mtimeMs).find(e => e.type == 'folder');

            if (lastFolder) {
               currPath = lastFolder.path;
            }
         }

         startFiles = await client.service('file-explorer')
            .get('list', { query: { path: currPath } });

      } else if (type.source == 'uploads') {
         let sessions = await client.service('import-palinsesti-functions').find({ query: { getSessions: 1, user: myVars.io.ruolo == 'Admin' ? null : myVars.io.id }, num: 200 })

         if (sessions && sessions.length) {
            for (const ses of sessions) {
               let name = `${ses.canale} (${ses.periodo})`;
               let downName = ses.name;

               startFiles.push({
                  id: ses.sessionId,
                  type: 'session',
                  name: name,
                  mimeType: ses.extension,
                  size: ses.size,
                  fileName: ses.fileName,
                  mtime: ses.updated_at,
                  username: ses.username,
                  down: ses.folder + ses.name,
                  downName: downName
               });
            }
         }

      } else if (type.source == 'gmail') {

         if (!gmailMaxresults) gmailMaxresults = 200;
         let mQuery = {
            $limit: gmailMaxresults,
            $sort: {
               received_at: -1
            },
            $and: [
               { emailOwner: startPath }
            ]
         };

         if (startPath == 'palinsesti@datatv.it') {
            mQuery.$and.push(
               { labels: { $notlike: `%"label_2"%` } },
               { from: { $notlike: '%ViacomEPGs@vimn.com%' } },
               { from: { $notlike: '%programmazione@gruppotv.it%' } },
               { from: { $notlike: '%capparotto.linda@paramount.com%' } }
            )
         }

         let messages = await client.service('gmail-table').find({ query: mQuery });

         if (messages && messages.data && Array.isArray(messages.data)) {
            for (const mess of messages.data) {
               if (!mess.attachments || !mess.attachments.length) continue;

               let multiTxtsAtt = [];
               for (const att of mess.attachments) {
                  if (!att.name) continue;
                  let ext = myFunc.getExtension(att.name);

                  if (['xml', 'asc'].includes(ext)) {
                     multiTxtsAtt.push({ ...att, emailOwner: startPath, messageId: mess.id, ext: ext });
                  }
               }

               // Allegati RAI da unire
               if (multiTxtsAtt.length) {
                  startFiles.push({
                     type: 'email',
                     mtime: mess.received_at ? mess.received_at : '',
                     id: null,
                     multiTxtsAtt: multiTxtsAtt,
                     name: `(${multiTxtsAtt[0].ext.toUpperCase()} Files) ${myFunc.camelize(mess.subject)}`,
                     mimeType: 'application/xml',
                     size: 1,
                     messageId: mess.messId,
                     from: myFunc.camelize(mess.from).replace(/[\<]/gi, '(').replace(/[\>]/gi, ')'),
                     subject: myFunc.camelize(mess.subject),
                     snippet: mess.snippet,
                     labels: mess.labels,
                     down: '',
                     importInfo: '',
                     emailOwner: startPath,
                  });

               }

               // Allegati normali
               else {
                  for (const att of mess.attachments) {
                     if (!att.name) continue;
                     let ext = myFunc.getExtension(att.name);

                     if (['xls', 'xlsx', 'txt', 'csv'].includes(ext)) {
                        startFiles.push({
                           type: 'email',
                           mtime: mess.received_at ? mess.received_at.replace(/t/gi, ' ').replace(/z/gi, '') : '',
                           id: att.id,
                           name: att.name,
                           mimeType: att.mimeType,
                           size: att.size,
                           messageId: mess.messId,
                           from: myFunc.camelize(mess.from).replace(/[\<]/gi, '(').replace(/[\>]/gi, ')'),
                           subject: myFunc.camelize(mess.subject),
                           snippet: mess.snippet,
                           labels: mess.labels,
                           down: '',
                           importInfo: '',
                           emailOwner: startPath,
                        });
                     };
                  };
               }
            };
         }
      }

      // IMPORTINFO
      const importInfos = await client.service('import-palinsesti-storico').find({
         query: {
            $limit: 1000,
            $sort: { created_at: -1 },
            fileName: { $in: startFiles.map(sf => sf.name) },
            op: { $ne: null },
         }
      });

      startFiles.forEach(sf => {
         sf.importInfo = importInfos.data.filter(imf => imf.fileName == sf.name);
      });

      console.log(`[fileExplorer] source ${type.source}, startFiles`, startFiles);
      $exp.attr('data-currentpath', startPath);

      // TABLE
      $totalFiles.html(startFiles.length + ' Elementi');
      let dt = myDt.createTable($table, startFiles, defineColumns(type.color), 'name', 100, true);

      if (type.source == 'mnt') {
         myDt.orderCols($table, true, [['type', 'desc'], ['name', 'asc']]);
      } else {
         myDt.orderCols($table, true, [['mtime', 'desc']]);
      }

      updateBreadCrumb();
      $tableContainer.removeClass('loading');

      // Action
      $exp.on('click', '.fileAction', async function (e) {
         console.log('File Explorer Action Call!')
         e.preventDefault();
         e.stopPropagation();
         $tableContainer.addClass('loading');

         let $tr = $(this).closest('tr');
         let row = dt.row($tr);
         let rowData = row.data();

         await fileAction(rowData);
         $tableContainer.removeClass('loading');
      });

      // Cambio Cartella
      $exp.on('click', '[data-getpath]', async function (e) {
         e.preventDefault();
         e.stopPropagation();
         let path = $(this).attr('data-getpath');
         $exp.attr('data-currentpath', path);
         $search.val('');
         updateBreadCrumb();

         $tableContainer.addClass('loading');
         let files = await client.service('file-explorer')
            .get('list', { query: { path: path } });

         const importInfos = await client.service('import-palinsesti-storico').find({
            query: {
               $limit: 1000,
               $sort: { created_at: -1 },
               fileName: { $in: files.map(f => f.name) },
               op: { $ne: null },
            }
         });

         files.forEach(f => {
            f.importInfo = importInfos.data.filter(imf => imf.fileName == f.name);
         });

         dt.clear();
         dt.rows.add(files);
         dt.search('');
         dt.draw('page');
         $tableContainer.removeClass('loading');
         $totalFiles.html(files.length + ' Elementi');
      });

      // Gmail Attachment
      $exp.on('click', '[data-gmailattachment]', async function () {
         let id = $(this).attr('data-gmailattachment');
         let emailOwner = $(this).attr('data-emailowner');
         let row = dt.row($(this).closest('tr')).data();

         $tableContainer.addClass('loading');
         if (row.multiTxtsAtt) await myFunc.convertKnownTxtsFilesToWbookObj(row.multiTxtsAtt, row.name);
         else await myFunc.getGmailAttachmentBase64(emailOwner, row.id, row.messageId, row.name, row.mimeType, true);
         $tableContainer.removeClass('loading');
         $tableContainer.removeClass('loading');
      });

      // Search files in folder
      $search.on('keyup change', function (e) {
         let toSearch = $(this).val();
         dt.search(`${toSearch}`, true, false).draw();
      })

      // updateBreadCrumb
      function updateBreadCrumb() {
         let path = $exp.attr('data-currentpath');

         if (type.source == 'mnt') {
            let pathArr = path.split('/');
            let pathArrLast = pathArr.slice(Math.max(pathArr.length - 3, 0)); // gli ultimi elementi dell'array

            $bread.html(pathArrLast.map((p, i) => {
               if (!p) return;
               let pathIndex = pathArr.findIndex(e => e == p);
               let currp = pathArr.slice(0, pathIndex + 1).join('/');

               if (i == pathArrLast.length - 1) {
                  return `<div class="active section">${p}</div>`;

               } else {
                  return `
                  <a href="#" class="section" data-getpath="${currp}"><b>${p}</b></a>
                  <div class="divider"> / </div>
               `;

               }
            }).join(''))

         } else if (type.source == 'uploads') {
            $bread.html(`
               <i class="${type.color} download icon"></i>
               Ultimi <b>${startFiles.length}</b> Stati di Lavorazione salvati

            `);

         } else if (type.source == 'gmail') {
            $bread.html(`
               <i class="${type.color} google icon"></i>
               Allegati delle ultime <b>${gmailMaxresults}</b> email di
               <div class="ui dropdown">
                  <div class="text a_${type.color}">${path}</div>
                  <i class="fitted dropdown icon"></i>
                  <div class="menu">
                     <div class="item">palinsesti@datatv.it</div>
                     <div class="item">sport@datatv.it</div>
                  </div>
               </div>
            `);

            $bread.find('.dropdown').dropdown({
               onChange: function (value, text, $selectedItem) {
                  myFunc.fileExplorer($exp, text, fileAction, extensionsArray, false, height);
               }
            });
            return;
         }
      }

      // defineColumns
      function defineColumns(color) {
         let cols = [];

         let myOrder = [
            'type',
            'name',
            'mtime',
            'size',
            'down',
         ];

         // Opzioni Colonne
         myOrder.forEach(n => {
            let col = { visible: false };

            if (n == 'type') {
               col = {
                  title: '&nbsp;',
                  orderable: true,
                  class: 'center aligned',
                  width: '20px',
                  createdCell: function (td, cellData, rowData, row, col) {
                     if (!rowData.importInfo || !rowData.importInfo.length) return;

                     let mailDate = moment(rowData.mtime);
                     let importTitle = [];
                     let lavorato, aperto;

                     rowData.importInfo.forEach((ii) => {
                        let opDate = moment(ii.created_at);
                        let op = ii.op == 'UPDATE' ? 'Aggiornato' : ii.op == 'IMPORT' ? 'Importato' : 'Aperto';
                        let user = myVars.users.tutti.find(u => u.id == ii.created_by);

                        importTitle.push(`${opDate.utc().format('DD/MM/YY HH:mm')} - ${op} da ${user.nome} ${user.cognome}`)

                        if (opDate.isSameOrAfter(mailDate)) {
                           if (['UPDATE', 'IMPORT'].includes(ii.op)) lavorato = true;
                           else aperto = true;
                        }
                     });

                     const html = `
                        <div class="ui mini left corner ${lavorato ? 'green' : aperto ? 'yellow' : ''} label" style="z-index:0" title="${importTitle.join('\n')}">
                           <div>${rowData.importInfo.length}</div>
                        </div>`;

                     $(td).append(html);
                     $(td).closest('tr').addClass(lavorato ? 'positive' : aperto ? 'warning' : '');

                  },
                  render: function (data, type, row, meta) {
                     let html = `<span style="display:none;">${data}</span>`;

                     if (data == 'folder') html += `<i class="large folder fitted icon" data-getpath="${row.path}"></i>`;
                     else if (data == 'email') html += `<i class="large paperclip fitted icon"></i>`;
                     else if (data == 'session') html += `<i class="large file fitted icon"></i>`;
                     else html += `<i class="large file fitted icon"></i>`;

                     return html;
                  }
               };
            }
            if (n == 'name') {
               col = {
                  title: 'Nome File',
                  orderable: true,
                  class: 'tdCompact',
                  createdCell: function (td, cellData, rowData, row, col) {


                  },
                  render: function (data, type, row, meta) {
                     let html = ``;

                     // Change folder or Download
                     if (row.type == 'folder') {
                        html += `<a href="#" data-getpath="${row.path}">`;

                     } else if (row.type == 'email') {
                        html += `<a href="#" data-emailowner="${row.emailOwner}" data-gmailattachment="${row.id}" class="a_grey" title="${row.snippet ? row.snippet : '...'}">`;

                     } else if (row.down) {
                        html += `<a href="./${row.down}" download="${row.downName ? row.downName : row.name}" class="a_grey">`;

                     }

                     // Title
                     html += `<b title="${data}">${data}</b><br>`;

                     // Riga 2
                     html += `
                        <span class="opacita8 a_grey" title="${row.subject ? `Oggetto: ${row.subject}` : row.down}">
                           ${row.subject ? row.subject : row.down}
                        </span><br>`;

                     // Riga 3
                     html += `
                        <span class="opacita8 a_grey" title="${row.username ? `Ultima Modifica di: ${row.username}` : row.from ? `Da: ${row.from}` : ''}">
                           ${row.labels && row.labels.includes('STARRED') ? '<span style="display:none">STARRED</span><i class="star icon"></i>' : ''}
                           ${row.username ? `Ultima Modifica di: ${row.username}` : row.from ? `Da: ${row.from}` : ''}
                        </span>`;

                     html += `</a>`;
                     return html;
                  }
               };
            }
            if (n == 'mtime') {
               col = {
                  title: 'Data',
                  orderable: true,
                  width: '95px',
                  createdCell: function (td, cellData, rowData, row, col) {

                  },
                  render: function (data, type, row, meta) {
                     let mome = moment(data);
                     return `<span style="display:none;">${mome.format('X')}</span>${mome.format('DD/MM/YY HH:mm')}`;
                  }
               };
            }
            if (n == 'size') {
               col = {
                  title: 'Size',
                  orderable: true,
                  width: '70px',
                  createdCell: function (td, cellData, rowData, row, col) {

                  },
                  render: function (data, type, row, meta) {
                     if (type === "sort" || type === 'type') {
                        return row.type == 'folder' ? 0 : data;
                     }
                     else {
                        return row.type == 'folder' ? '--' : myFunc.readableBytes(data);
                     }
                  }
               };
            }
            if (n == 'down') {
               col = {
                  title: '&nbsp;',
                  orderable: true,
                  class: 'center aligned',
                  width: '20px',
                  createdCell: function (td, cellData, rowData, row, col) {

                  },
                  render: function (data, type, row, meta) {
                     let html = ``;

                     if (row.type == 'session') {
                        html += `<a href="./importPalinsesti?sessionId=${row.id}" class="fileAction">
                              <i class="large fitted hand pointer outline icon"></i>
                           </a>`;

                     } else if (['file', 'email'].includes(row.type)) {
                        let ext = row.name.split('.').pop();
                        if (row.multiTxtsAtt || (extensionsArray && extensionsArray.includes(ext.toLowerCase()))) {
                           html += `<a href="#" class="fileAction">
                              <i class="large fitted hand pointer outline icon"></i>
                           </a>`;
                        }
                     }

                     return html;
                  }
               };
            }

            col.data = n;
            col.name = n;
            cols.push(col);

         });

         return { cols: cols };
      }
   },

   getFileFromUrl: async function (url, name, defaultType = 'image/jpeg') {
      const response = await fetch(url);
      const data = await response.blob();
      return new File([data], name, {
         type: data.type || defaultType,
      });
   },

   getGmailAttachmentBase64: async function (emailOwner, id, messageId, name, mimeType, download) {
      if (!emailOwner) emailOwner = 'palinsesti@datatv.it';


      const att = await client.service('gmail')
         .get('getGmailAttachment', { query: { id: id, messageId: messageId, user: emailOwner  } });

      console.log('[getGmailAttachment]', att);
      const base64 = att.data.data.replace(/_/g, '/').replace(/-/g, '+');

      if (download && name && mimeType) {
         // $('body').append(`<a href="${base64}" download="${name}"></a>`)[0].click;
         const linkSource = `data:${mimeType};base64,${base64}`;
         const downloadLink = document.createElement("a");
         downloadLink.href = linkSource;
         downloadLink.download = name;
         downloadLink.click();

      } else {
         return base64;

      }
   },

   // /////////////////////////////////////////////////////////////////////////// SHEETJS HELPERS
   xmlToJson: function (xml) {
      // Create the return object
      var obj = {};

      if (xml.nodeType == 1) { // element
         // do attributes
         if (xml.attributes.length > 0) {
            obj["@attributes"] = {};
            for (var j = 0; j < xml.attributes.length; j++) {
               var attribute = xml.attributes.item(j);
               obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
            }
         }
      } else if (xml.nodeType == 3) { // text
         obj = xml.nodeValue;
      }

      // do children
      if (xml.hasChildNodes()) {
         for (var i = 0; i < xml.childNodes.length; i++) {
            var item = xml.childNodes.item(i);
            var nodeName = item.nodeName;
            if (typeof (obj[nodeName]) == "undefined") {
               obj[nodeName] = myFunc.xmlToJson(item);
            } else {
               if (typeof (obj[nodeName].push) == "undefined") {
                  var old = obj[nodeName];
                  obj[nodeName] = [];
                  obj[nodeName].push(old);
               }
               obj[nodeName].push(myFunc.xmlToJson(item));
            }
         }
      }

      return obj;
   },

   b64DecodeUnicode: function (str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      // console.log({ str })

      let clean = '';

      try {
         clean = decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
         }).join(''));
      } catch {
         clean = atob(str);
      }

      return clean
   },

   // getBase64FromUrl
   getBase64FromUrl: async function (url) {
      const response = await fetch(url);
      const blob = await response.blob();
      const reader = new FileReader();
      await new Promise((resolve, reject) => {
         reader.onload = resolve;
         reader.onerror = reject;
         reader.readAsDataURL(blob);
      });
      return reader.result.replace(/^data:.+;base64,/, '');
   },

   // getBase64FromFile
   getBase64FromFile: async function (file) {
      const reader = new FileReader();
      await new Promise((resolve, reject) => {
         reader.onload = resolve;
         reader.onerror = reject;
         reader.readAsDataURL(file);
      });
      return reader.result.replace(/^data:.+;base64,/, '');
   },

   // getTextFromFile
   getTextFromFile: async function (file, encoding) {
      const reader = new FileReader();
      await new Promise((resolve, reject) => {
         reader.onload = resolve;
         reader.onerror = reject;
         reader.readAsText(file, encoding ? encoding : "UTF-8");
      });
      return reader.result;
   },

   downloadXLSX: function (wBook, filename) {

      Object.keys(wBook.Sheets).forEach(sn => {
         let sheet = wBook.Sheets[sn];
         let range = XLSX.utils.decode_range(sheet['!ref']);
         range.s.r = 0; range.e.r = 0; // restrict to the first row

         // Freeze Header
         sheet['!freeze'] = 'A2';

         // Autosize Cols
         if (!sheet['!cols']) sheet['!cols'] = [];
         for (let i = range.s.c; i <= range.e.c; ++i) {
            sheet['!cols'][i] = { auto: 1 };
         }

         // // Set Style and Filter (file corrotto)
         // sheet["!tables"] = [
         //    {
         //       'name': 'Datatv Table',
         //       'filter': true,
         //       'header': true,
         //       'style': {
         //          'name': 'light1',
         //          'rowstripe': false,
         //          'colstripe': false
         //       }
         //    }
         // ];

         XLSX.utils.sheet_set_range_style(sheet, range, {
            fgColor: { rgb: 0x2185d0 }, // blue solid background
            color: { rgb: 0xFFFFFF }, // white text
            top: { style: "thick", color: { rgb: 0xdededf } }, // gray border
            bottom: { style: "thick", color: { rgb: 0xdededf } }, // gray border
            left: { style: "thick", color: { rgb: 0xdededf } } // gray border
         });
      });

      XLSX.writeFile(wBook, filename + ` (${moment().format('DD-MM-YYYY HH.mm')}).xlsx`, { bookType: 'xlsx', cellStyles: true });
   },

   convertKnownTxtsFilesToWbookObj: async function(arr, download) {
      const regexPath = /(\/+\w{0,}){0,}\.\w{1,}$/;
      const promises = [];
      let txts = [];

      // Recupero i testi
      arr.forEach((el, i) => {
         txts[i] = { name: el.name, txt: null }

         if (el instanceof File) {
            promises.push(
               myFunc.getTextFromFile(el, 'UTF-8')
                  .then(res => txts[i].txt = res)
            );

         } else if (el.id && el.messageId) {
            promises.push(
               myFunc.getGmailAttachmentBase64(el.emailOwner, el.id, el.messageId)
                  .then(res => txts[i].txt = myFunc.b64DecodeUnicode(res))
            );

         } else if (el.down && regexPath.test(el.down)) { // Se è una URL
            // DA PROVARE
         }

      });

      await Promise.all(promises);


      if (txts[0].name) {
         txts = txts.sort((a, b) => a.name.localeCompare(b.name));
      }

      console.log({ txts });

      // File Excel dai txtx
      let filename;
      if (typeof download === 'string') filename = download;

      let wBook = XLSX.utils.book_new();

      // Chiedo il canale all'utente
      let singleChannel = null;
      if (myFunc.getExtension(txts[0].name) == 'asc') {
         let chs = txts[0].txt.split(/\s+Proteo: Schema Orario di.*\s\d{1,2}\/\d{1,2}\/\d{1,4} di\s+(.*)/gi).filter(t => t && !/\r\n\r\n\r\n/gi.test(t));
         singleChannel = await askChannelModal(chs);
      }

      if (myFunc.getExtension(txts[0].name) == 'xml' && /\brai\b/gi.test(txts[0].name)) {
         let chs = [... new Set(txts.map(t => 'RAI' + t.name.toLowerCase().replace(/\.xml/gi, '').split(/\brai\b/gi)[1]))];
         singleChannel = await askChannelModal(chs);
      }

      txts.forEach((txto, i) => {
         let palArray = [];
         let ext = myFunc.getExtension(txto.name);

         if (['xml'].includes(ext)) {
            if (singleChannel && !new RegExp('\\b' + myFunc.escapeRegex(singleChannel) + '\\b', 'gi').test(txto.name)) return;
            palArray = getPalFromXmlText(txto.txt);

         } else if (['asc'].includes(ext)) {
            palArray = getPalFromAscText(txto.txt, singleChannel);

         }

         if (!filename && palArray[0][8] && palArray[1][0]) filename = `${palArray[0][8]} ${txts.length}g. dal ${palArray[1][0]} (File di testo uniti)`;

         // Nuovo foglio per file
         let ws = XLSX.utils.aoa_to_sheet(palArray);
         XLSX.utils.book_append_sheet(wBook, ws, txto.name.substring(0, Math.min(txto.name.length, 30)));
      });


      if (!filename) filename = 'File di testo uniti';

      if (download) {
         myFunc.downloadXLSX(wBook, filename);

      } else {
         return { wBook: wBook, name: filename }

      }

      function getPalFromXmlText(xmlString){
         let parser = new DOMParser();
         let xml = parser.parseFromString(xmlString, `application/xml`);
         console.log('[getPalFromXmlText]\n', xml)
         let obj = myFunc.xmlToJson(xml);
         let palArray = [[
            'Giorno',
            'Ora',
            'Titolo Programma',
            'Titolo Episodio',
            'Stagione',
            'Episodio',
            'Sinossi',
            'Varie TXT',
            obj.Section.SingleEventInfo[0].ChannelName['#text']
         ]];

          // Convert RAI XML
         obj.Section.SingleEventInfo.forEach(ev => {
            palArray.push([
               obj.Section.Intestazione.Data['#text'],
               ev.OraInizioRadioCorriereLT['#text'],
               ev.ProgramTitle['#text'] == '.' && ev.EpisodeTitle['#text'] ? ev.EpisodeTitle['#text'] : ev.ProgramTitle['#text'],
               ev.EpisodeTitle['#text'],
               '',
               '',
               ev.DesSinossi['#text'],
               '',
               '',
            ]);
         });

         return palArray;
      }

      function getPalFromAscText(ascString, channel){
         let objs = [];

         // obj from asc
         ascString.split(/\s+Proteo: Schema Orario di\s+/gi).filter(e => e).forEach(txt => {
            let obj = { text: txt, progs: [] };
            let rex = /.*\s(\d{1,2})\/(\d{1,2})\/(\d{1,4}) di (.*)\s/gi.exec(txt);

            if (rex && rex[4]) {
               obj.day = `${rex[1]}/${rex[2]}/${rex[3]}`;
               obj.channel = rex[4];

               let progs = obj.text.split(/\s{6}(?=\d{1,2}:\d{1,2})/gi);
               progs.forEach(prog => {
                  let rex = /^(\d{1,2}):(\d{1,2})/gi.exec(prog);

                  if (rex && rex[1] && rex[2]) {
                     let pp = {
                        ora: `${rex[1]}:${rex[2]}`,
                        arr: prog
                           .replace(rex[0], '')
                           .replace(/^\s+\d{1,2}\:\d{1,2}/gi, '')
                           .trim()
                           .split(/[\r\n]+/gi)
                           .map(t => t.replace(/\s+/gi, ' ').trim())
                     }

                     obj.progs.push(pp);
                  }
               })

               objs.push(obj);
            }

         })
         console.log(objs)
         if (!channel) channel = objs[0].channel;

         let palArray = [[
            'Giorno',
            'Ora',
            'Titolo Programma',
            'Titolo Episodio',
            'Stagione',
            'Episodio',
            'Sinossi',
            'Varie TXT',
            channel
         ]];

         objs.forEach(obj => {
            if (obj.channel != channel) return;
            obj.progs.forEach(prog => {
               palArray.push([
                  obj.day,
                  prog.ora,
                  prog.arr[0],
                  '',
                  '',
                  '',
                  '',
                  prog.arr.filter((p, i) => i > 0).join('\n'),
                  '',
               ]);
            });
         });

         console.log({palArray});
         return palArray;
      }

      async function askChannelModal(chs) {
         $('.ui.popup').popup('hide all');
         $('#askChannelModal').remove();

         return new Promise((resolve, reject) => {
            const $cmodal = $(`
            <div class="ui mini modal" id="askChannelModal">
               <div class="header"><i class="question circle icon"></i>Scegli un canale</div>
               <div class="content">

                  <form class="ui form">

                     <div class="field">
                        <label>Canale Da Importare</label>
                        <div class="ui selection clearable search dropdown singleChannel">
                           <input type="hidden" name="singleChannel">
                           <i class="dropdown icon"></i>
                           <div class="default text">Canale</div>
                           <div class="menu">
                              ${chs.map(c => `
                                 <div class="vertical item" data-value="${c}">${c}</div>
                              `).join('')}
                           </div>
                        </div>
                     </div>

                  </form>
               </div>
               <div class="actions">
                  <div class="ui ok green small button"><i class="check icon"></i>Conferma</div>
               </div>
            </div>
            `).appendTo('body');

            $cmodal.find('.ui.dropdown').dropdown({ silent: true, forceSelection: true, fullTextSearch: true });

            // Show Modal
            $cmodal.modal({
               autofocus: false,
               allowMultiple: false,
               transition: 'zoom',
               closable: false,
               duration: 250,
               onApprove: function () {
                  setTimeout(function () {
                     resolve($cmodal.find('.ui.dropdown.singleChannel').dropdown('get value'));
                  }, 500);
               },
            }).modal('show');
         });
      }
   }
};
