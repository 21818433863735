module.exports = {
   inviaAcquisition: async function (Request_id, choosed) {
      if (!Request_id || !choosed) return;
      console.log('inviaAcquisition', Request_id, choosed);

      const promise = client.service('content-functions').update(null, { op: 'sendAcquisition', Request_id: Request_id, choosed: choosed });

      return promise.catch(function (err) {
         myError.show(`Errore Invio Acquisition`, err);
      });
   },

   annullaInviaAcquisition: async function (Request_id){
      if (!Request_id) return;
      console.log('annullaInviaAcquisition', Request_id);

      const promise = client.service('content-functions').update(null, { op: 'annullaInviaAcquisition', Request_id: Request_id });

      return promise.catch(function (err) {
         myError.show(`Errore Invio Acquisition`, err);
      });
   },

   creaRichiesta: async function (data) {
      if (!data) return;
      console.log('creaRichiesta', data);
      const promise = client.service('content-functions').update(null, { op: 'create', requestData: data });

      return promise.catch(function (err) {
         myError.show(`Errore Creazione Richiesta`, err);
      });
   },

   creaRichiestaModal: function (tipo, row) {
      return new Promise((resolve, reject) => {
         $('.ui.modal').remove();
         let $modal = false;

         if (tipo == 'SS') {
            $modal = $(`
          <div class="ui mini modal" id="creaRichiestaModal">
            <div class="header"><i class="code branch icon"></i>Crea Stagione</div>

            <div class="scrolling content">
              <div class="ui small form">

              <div class="field">
                <label>Titolo</label>
                <input type="text" placeholder="Titolo" name="Request_titolo" value="">
              </div>

              <div class="fields">
                <div class="eight wide field">
                  <label>Stagione</label>
                  <input type="text" placeholder="Stagione" name="Request_num_stagione" value="">
                </div>
              </div>

            </div>
          </div>

          <div class="actions">
            <div class="ui red button cancel">Annulla</div>
            <div class="ui green button ok">Crea Stagione</i>
          </div>

        </div>`).appendTo('body');
         } else if (tipo == 'SE') {
            $modal = $(`
          <div class="ui mini modal" id="creaRichiestaModal">
            <div class="header"><i class="code branch icon"></i>Crea Serie</div>

            <div class="scrolling content">
              <div class="ui small form">

              <div class="field">
                <label>Titolo</label>
                <input type="text" placeholder="Titolo" name="Request_titolo" value="">
              </div>

            </div>
          </div>

          <div class="actions">
            <div class="ui red button cancel">Annulla</div>
            <div class="ui green button ok">Crea Serie</i>
          </div>

        </div>`).appendTo('body');
         } else {
            $modal = $(`
          <div class="ui mini modal" id="creaRichiestaModal">
            <div class="header"><i class="write icon"></i>Crea Richiesta</div>

            <div class="content">
              <div class="ui small form">

              <div class="required field">
                <label>Titolo</label>
                <input type="text" placeholder="Titolo" name="Request_titolo" value="">
              </div>

              <div class="fields">
                <div class="four wide required field">
                  <label>Priorità</label>
                  <input type="text" placeholder="00" name="Request_Priorita" value="">
                </div>
                <div class="twelve wide required field">
                  <label>Tipo</label>
                  <div class="ui selection dropdown">
                    <input type="hidden" class=""  name="Request_TipoRichiesta" value="">
                    <i class="dropdown icon"></i>
                    <div class="text">--</div>
                    <div class="menu">
                      <div class="item" data-value="TT">Programma</div>
                      <div class="item" data-value="SE">Serie</div>
                      <div class="item" data-value="SS">Stagione</div>
                      <div class="item" data-value="EP">Episodio</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="fields">
                <div class="eight wide field">
                  <label>Stagione</label>
                  <input type="text" placeholder="Stagione" name="Request_num_stagione" value="">
                </div>
                <div class="eight wide field">
                  <label>Episodio</label>
                  <input type="text" placeholder="Episodio" name="Request_episodio" value="">
                </div>
              </div>

              <div class="fields">
                <div class="four wide required field">
                  <label>Cliente</label>
                  <div class="ui selection dropdown">
                    <input type="hidden" name="Request_ethanFlag" value="">
                    <i class="dropdown icon"></i>
                    <div class="text">--</div>
                    <div class="menu">
                      <div class="item" data-value=""><b class="a_red">TIM</b></div>
                      <div class="item" data-value="true"><b class="a_blue">SKY</b></div>
                    </div>
                  </div>
                </div>
                <div class="twelve wide field">
                  <label>Flags - Meta Data Sets</label>
                  <div class="ui multiple selection dropdown">
                    <input type="hidden" class=""  name="Request_ethanMetadatasets" value="">
                    <i class="dropdown icon"></i>
                    <div class="text">--</div>
                    <div class="menu">
                      <div class="item" data-value="nowtv">NOW</div>
                      <div class="item" data-value="skykids">KIDS</div>
                      <div class="item" data-value="dazn">DAZN</div>
                      <div class="item" data-value="stbfusion">FUSION</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="fields">

                <div class="ten wide required field">
                  <label>Tipologia</label>
                  <div class="ui selection dropdown">
                    <input type="hidden" name="Request_tipologia" value="">
                    <i class="dropdown icon"></i>
                    <div class="text">--</div>
                    <div class="menu">
                      <div class="item" data-value="Altri Programmi">Altri Programmi</div>
                      <div class="item active selected" data-value="Film">Film</div>
                      <div class="item" data-value="Informazione">Informazione</div>
                      <div class="item" data-value="Intrattenimento">Intrattenimento</div>
                      <div class="item" data-value="Mondo e Tendenze">Mondo e Tendenze</div>
                      <div class="item" data-value="Ragazzi e Musica">Ragazzi e Musica</div>
                      <div class="item" data-value="Sport">Sport</div>
                    </div>
                  </div>
                </div>
                <div class="six wide field">
                  <label>Data Inizio</label>
                  <input type="text" placeholder="--/--/----" name="Request_Dinizio" value="">
                </div>
              </div>

            </div>
          </div>

          <div class="actions">
            <div class="ui red button cancel">Annulla</div>
            <div class="ui green button ok">Crea Richiesta</i>
          </div>

        </div>`).appendTo('body');
         }

         $modal.on('change', '[name="Request_ethanFlag"]', function (e) {
            const $flagsField = $modal.find('[name="Request_ethanMetadatasets"]').closest('.field');
            if ($(this).val()) $flagsField.removeClass('disabled');
            else $flagsField.addClass('disabled');
         });


         $modal.on('change', '[name="Request_TipoRichiesta"]', function (e) {
            const tipo = $(this).val();
            const $st = $modal.find('[name="Request_num_stagione"]').closest('.field');
            const $ep = $modal.find('[name="Request_episodio"]').closest('.field');
            const $creaSE = $modal.find('.creaSE').closest('.field');
            const $creaSS = $modal.find('.creaSS').closest('.field');

            if (tipo == 'TT') {
               $st.addClass('disabled');
               $ep.addClass('disabled');
               $creaSE.addClass('disabled');
               $creaSS.addClass('disabled');
            } else if (tipo == 'SE') {
               $st.addClass('disabled');
               $ep.addClass('disabled');
               $creaSE.addClass('disabled');
               $creaSS.addClass('disabled');
            } else if (tipo == 'SS') {
               $st.removeClass('disabled');
               $ep.addClass('disabled');
               $creaSE.removeClass('disabled');
               $creaSS.addClass('disabled');
            } else if (tipo == 'EP') {
               $st.removeClass('disabled');
               $ep.removeClass('disabled');
               $creaSE.addClass('disabled');
               $creaSS.removeClass('disabled');
            }
         });

         // RESOLVE
         $modal.on('click', '.button.ok', (e) => {
            const tor = {};

            if (tipo == 'SS') {
               tor.TipoRichiesta = 'SS';
               tor.titolo = $modal.find('[name="Request_titolo"]').val();
               tor.num_stagione = $modal.find('[name="Request_num_stagione"]').val();
               tor.externalID = row.Request_externalID + '_SS_' + tor.num_stagione;
               tor.pushFlag = 1;
               tor.linkedRequest = row.Request_id;
               // tor.id = '';
               // tor.uuid = '';
               // tor.providerID = '';
               // tor.episodio = '';
               tor.tipologia = row.Request_tipologia;
               tor.generi = row.Request_generi;
               tor.anno_prod = row.Request_anno_prod;
               tor.Dinizio = row.Request_Dinizio;
               tor.Priorita = row.Request_Priorita;
               tor.anno_prod = row.Request_anno_prod;
               tor.ethanFlag = row.Request_ethanFlag;
               tor.ethanMetadatasets = row.Request_ethanMetadatasets;
               tor.ethanChannelCode = row.Request_ethanChannelCode;
               tor.idSerie = row._idSerie;
               tor.idProgram = row._idProgram;
               tor.brand = row.Request_brand;
               tor.station = row.Request_station;


            } else if (tipo == 'SE') {
               tor.TipoRichiesta = 'SE';
               tor.titolo = $modal.find('[name="Request_titolo"]').val();
               tor.externalID = row.Request_externalID.replace(/\_SS\_.*/i, '') + '_SE';
               tor.pushFlag = 1;
               tor.linkedRequest = row.Request_id;
               // tor.id = '';
               // tor.uuid = '';
               // tor.providerID = '';
               // tor.num_stagione = '';
               // tor.episodio = '';
               tor.tipologia = row.Request_tipologia;
               tor.generi = row.Request_generi;
               tor.anno_prod = row.Request_anno_prod;
               tor.Dinizio = row.Request_Dinizio;
               tor.Priorita = row.Request_Priorita;
               tor.anno_prod = row.Request_anno_prod;
               tor.ethanFlag = row.Request_ethanFlag;
               tor.ethanMetadatasets = row.Request_ethanMetadatasets;
               tor.ethanChannelCode = row.Request_ethanChannelCode;
               tor.idSerie = row._idSerie;
               tor.idProgram = row._idSerie;
               tor.brand = row.Request_brand;
               tor.station = row.Request_station;

            } else {
               $modal.find('[name]').toArray().map((i) => {
                  const disabled = $(i).closest('.field').hasClass('disabled');
                  const k = $(i).attr('name');
                  const v = $(i).val();

                  if (v != row[k]) tor[k.replace('Request_', '')] = disabled ? null : v;
               });

               tor.pushFlag = 1;
            }

            resolve(tor);
         });

         // Riempio i campi
         if (['SS', 'SE'].includes(tipo)) {
            Object.keys(row).forEach((k) => {
               $modal.find('[name="' + k + '"]').val(row[k]);
            });
         }

         // Init
         $modal.find('[name="Request_TipoRichiesta"]').trigger('change');
         $modal.find('[name="Request_ethanFlag"]').trigger('change');
         $modal.find('.ui.dropdown').dropdown();

         $modal.modal({
            autofocus: false,
            allowMultiple: true,
            transition: 'scale',
         }).modal('show');
      });
   },

   inviaRisposta: async function (Request_id, op, idProgram, idEpisode, title) {
      if (!Request_id || !op) return;
      console.log('inviaRisposta', Request_id, op, title);
      // const logData = {};
      const promise = client.service('content-functions').update(Request_id, { op: op, Request_id: Request_id, idProgram: idProgram, idEpisode: idEpisode, title: title });

      return promise.catch(function (err) {
         myError.show(`Errore ${op} Risposta`, err);
      });
   },

   collegaRisposta: async function (Response_id, Request_id, idProgram, idEpisode, title) {
      const promise = client.service('content-functions').update(Request_id, { op: 'link', Response_id: Response_id, Request_id: Request_id, idProgram: idProgram, idEpisode: idEpisode, title: title });

      return promise.catch(function (err) {
         myError.show('Errore Collega Risposta', err);
      });
   },

   modificaRichiesta: async function (Request_id, data, idProgram, idEpisode, title) {
      if (!Request_id || !data) return;
      console.log('modificaRichiesta', Request_id, data);
      const promise = client.service('content-functions').update(Request_id, { op: 'modify', Request_id: Request_id, requestData: data, idProgram: idProgram, idEpisode: idEpisode, title: title });

      return promise.catch(function (err) {
         myError.show(`Errore Modifica Richiesta`, err);
      });
   },

   eliminaRichiesta: async function (Request_id, idProgram, idEpisode, title) {
      if (!Request_id) return;
      console.log('eliminaRichiesta', Request_id);
      const promise = client.service('content-functions').update(Request_id, { op: 'delete', Request_id: Request_id, idProgram: idProgram, idEpisode: idEpisode, title: title });

      return promise.catch(function (err) {
         myError.show(`Errore Elimina Richiesta`, err);
      });
   },

   nascondiRichiesta: function (data) {
      if (!data.id || !data.pending) return;

      if (data.pending == 'mostra') data.pending = null;
      return client.service('content-request').patch(data.id, data).catch(function (err) {
         myError.show('Errore Nascondi Richiesta', err);
      });
   },

   writeDbId: async function (idSerie, idProgram, idEpisode, idRequest, idResponse) {
      console.log(idSerie, idProgram, idEpisode, idRequest, idResponse);
      const data = {
         idSerie: idSerie ? idSerie : null,
         idProgram: idProgram ? idProgram : null,
         idEpisode: idEpisode ? idEpisode : null,
      };

      const promises = [];
      if (idResponse) promises.push(client.service('content-response').patch(idResponse, data));
      if (idRequest) promises.push(client.service('content-request').patch(idRequest, data));

      return Promise.all(promises).catch(function (err) {
         myError.show('Errore Assegnazione', err);
      });
   },

   writeCanale: function (data) {
      if (!data.id || !data.canali) return;

      if (data.canali == 'remove') data.canali = null;
      return client.service('content-request').patch(data.id, data).catch(function (err) {
         myError.show('Errore Assegnazione', err);
      });
   },

   writeGrafico: async function (data) {
       if (!data.idProgram || data.idProgram == -1) {
           myError.show('Errore Assegnazione', `Associare la scheda a un id Programma prima di assegnare un Grafico`);
       }

       if (!data.assigned_to)
           return;

      const preSearch = await client.service('archivioassegnazioni').find({ query: { idProgram: data.idProgram, idEpisode: data.idEpisode, tipo: 'Grafico' } });
      const idAss = preSearch.data[0] && preSearch.data[0].id ? preSearch.data[0].id : false;
      let promise = false;

      if (data.assigned_to == 'remove' && idAss) {
         console.log('REMOVE');
         promise = client.service('archivioassegnazioni').remove(idAss, data);
      } else if (idAss) {
         promise = client.service('archivioassegnazioni').update(idAss, data);
      } else {
         promise = client.service('archivioassegnazioni').create(data);
      }

      return promise.catch(function (err) {
         myError.show('Errore Assegnazione', err);
      });
   },

    writeRedattore: async function (data, dataOld) {
        if (!data.idProgram || data.idProgram == -1) {
            myError.show('Errore Assegnazione', `Associare la scheda a un id Programma prima di assegnare un Redattore`);
        }

        if (!data.assigned_to)
            return;

        const preSearch = await client.service('archivioassegnazioni').find({ query: { idProgram: data.idProgram, tipo: 'Redattore' } });
        const idAss = preSearch.data[0] && preSearch.data[0].id ? preSearch.data[0].id : false;
        let promise = false;

        if (data.assigned_to == 'remove' && idAss) {
            console.log('REMOVE');
            promise = client.service('archivioassegnazioni').remove(idAss, data);
        } else if (idAss) {
            promise = client.service('archivioassegnazioni').update(idAss, data);
        } else {
            promise = client.service('archivioassegnazioni').create(data);
        }

        return promise.catch(function (err) {
            myError.show('Errore Assegnazione', err);
        });




    //   if (!data.codiceF || !data.assigned_to) return;

    //   const preSearch = await client.service('archivioassegnazioni').find({ query: { idProgram: data.idProgram, tipo: 'Redattore' } });
    //   const idAss = preSearch.data[0] && preSearch.data[0].id ? preSearch.data[0].id : false;
    //   let promise = false;

    //   if (data.assigned_to == 'remove' && idAss) {
    //      promise = client.service('archivioassegnazioni').remove(idAss, data);
    //   } else if (data.assigned_to == 'remove' && !idAss) {
    //      promise = Promise.resolve('Success');
    //   } else if (idAss) {
    //      promise = client.service('archivioassegnazioni').update(idAss, data);
    //   } else {
    //      promise = client.service('archivioassegnazioni').create(data);
    //   }

    //   return promise.catch(function (err) {
    //      myError.show('Errore Assegnazione', err);
    //   });



       // Per la vecchia tabella di assegnazioni
       //    .then(() => {
       //        // Per la vecchia tabella di assegnazioni
       //        return oldWrite(dataOld);
       //    })
    //   async function oldWrite(data) {
    //      const preSearch = await client.service('content-utenti-request').find({ query: { codiceF: data.codiceF } });
    //      const idAss = preSearch.data[0] && preSearch.data[0].id ? preSearch.data[0].id : false;
    //      let promise = false;

    //      if (data.idUser == 'remove' && idAss) {
    //         promise = client.service('content-utenti-request').remove(idAss, data);
    //      } else if (data.assigned_to == 'remove' && !idAss) {
    //         promise = Promise.resolve('Success');
    //      } else if (idAss) {
    //         promise = client.service('content-utenti-request').update(idAss, data);
    //      } else {
    //         promise = client.service('content-utenti-request').create(data);
    //      }

    //      return promise.catch(function (err) {
    //         myError.show('Errore Assegnazione', err);
    //      });
    //   }
   },

   writeAttributi: async function (data) {
      if (!data.noteL && !data.imgRichieste && data.imgCadenza) return;

      const request = await client.service('content-request').get(data.id);
      const attributo = request && request.attributo ? JSON.parse(request.attributo) : {};

      attributo.imgRichieste = data.imgRichieste == 'remove' ? '' : data.imgRichieste;
      attributo.imgCadenza = data.imgCadenza == 'remove' ? '' : data.imgCadenza;
      attributo.noteL = data.noteL == 'remove' ? '' : attributo.noteL && attributo.noteL.value == data.noteL ? attributo.noteL : {
         data: moment().format('YYYY-MM-DD HH:mm:ss'),
         value: data.noteL,
         username: myVars.io.username,
      };

      const toWrite = { attributo: JSON.stringify(attributo) };
      if (data.controllare != request.controllare)
         toWrite.controllare = data.controllare;

      // console.log(attributo);
      return client.service('content-request').patch(data.id, toWrite).catch(function (err) {
         myError.show('Errore Scrittura Attributi', err);
      });
   },

   writeTrame: async function (service, id, col, trama) {
      // Corrispondenza Servizi
      // const serviceArr = [
      //   ['SatelliteSupport.dbo.EPG_program_synopsis', 'program_epg_synopsis'],
      //   ['SatelliteSupport.dbo.EPG_Episode_synopsis', 'episode_epg_synopsis'],
      //   ['Satellite.dbo.ProgramSynopsis', 'program_synopsis'],
      //   ['Satellite.dbo.Program', 'program'],
      //   ['Satellite.dbo.Episode', 'episode'],
      //   ['TIMVision.dbo.Response', 'content'],
      //   ['SKYVOD.dbo.Response', 'vod'],
      // ];

      // service, id, trama
      if (!service || !id || !col) return;
      const data = {};
      data[col] = trama;

      if (['program', 'episode', 'program-synopsis'].includes(service)) {
         data.utenteModifica = `${myVars.io.nome}_${myVars.io.cognome}`;
      }

      return client.service(service).patch(id, data).catch(function (err) {
         myError.show(`Errore Scrittura Trama ${service} ${col}`, err);
         throw (err);
      });
   },
};
