var myAuth = {
    expiration: '24h',
    loginRedirect: /tools.datatv/gi.test(window.location.href) ? 'https://tools.datatv.it/home' : 'https://content2.datatv.it/requestlistadmindata.asp',
    logoutRedirect: /tools.datatv/gi.test(window.location.href) ? 'https://tools.datatv.it/logout' : 'https://content2.datatv.it/logout.asp',

    login: async function (usr, pwd, autoLogout) {
        const params = new URLSearchParams(window.location.search);
        let result = false;

        // Se non ho il client esco
        if (!client) return;

        // Se non ho dati per autenticare esco
        let jwt = localStorage.getItem('feathers-jwt');
        let prevCookie = myAuth.getCookie('tools.datatv.it') || myAuth.getCookie('content2.datatv.it');

        // Se ho un cookie lo rimetto nel localstorage
        if (!jwt && prevCookie) {
            localStorage.setItem('feathers-jwt', prevCookie);
            jwt = prevCookie;
        }

        // Se non ho niente per autenticare esco
        if (!jwt && !usr && !pwd) {
            console.log('not logged yet', { jwt }, { usr }, { pwd }, { prevCookie });
            return;
        }

        // Se ho usr e pwd uso quelle per entrare
        if (usr && pwd) {
            try {
                // If that errors, log in with email/password
                console.log('Authenticate...');
                result = await client.authenticate({
                    strategy: 'local',
                    username: usr,
                    password: pwd
                });

                $('.loginError').remove();
                myAuth.setCookie('tools.datatv.it', result.accessToken, myAuth.expiration);
                myAuth.setCookie('content2.datatv.it', result.accessToken, myAuth.expiration);


            } catch (error) {
                console.log('Autenticazione non riuscita...', usr, pwd, prevCookie);
                myAuth.deleteCookie('tools.datatv.it');
                myAuth.deleteCookie('content2.datatv.it');

                const urlErr = params.get('err');

                let mess = 'Errore Login Generico';
                if (error.message == 'Invalid login') mess = 'Ricontrolla le credenziali inserite.';
                else if (urlErr && urlErr == 'notlogged') mess = 'Effettua nuovamente il login.';
                else if (urlErr && urlErr == 'reauth') mess = 'Token non valido.';
                else if (urlErr && urlErr == 'expired') mess = 'Accesso Scaduto.';
                else if (e.message) mess = e.message;
                else if (e) mess = JSON.stringify(e);

                $('.loginError').remove();
                $('#login, #submit').before(`
            <div class="ui icon negative message loginError" style="text-align: left;">
            <i class="warning sign icon"></i>
            <div class="content">
               <div class="header">Errore Login</div>
               <p>Errore Accesso: ${mess}</p>
            </div>
            </div>`);

                throw error;
            }
        }

        // Altrimenti provo ad entrare con il jwt
        else {
            try {
                // First try to log in with an existing JWT
                console.log('Reauthenticate...');
                result = await client.reAuthenticate(true);
                await client.service('users').on('patched', (user) => myFunc.updateOnlineUser(user));

            } catch (error) {
                myAuth.deleteCookie('tools.datatv.it');
                myAuth.deleteCookie('content2.datatv.it');
                // window.location.href = myAuth.logoutRedirect;
            }
        }

        if (result && autoLogout !== false)
            myAuth.logoutOnExpire();

        if (/\/login/gi.test(window.location.href))
            window.location.href = myAuth.loginRedirect;

        // Fine
        myFunc.elLoader(false);
        return result;

        // if (!client) return;
        // else if (usr && pwd) return authCred(usr, pwd);
        // else return reAuth();

        // // authenticate with credentials
        // function authCred(usr, pwd) {
        //    myFunc.elLoader(true, false, 'Autenticazione...');
        //    return client.authenticate({ strategy: 'local', username: usr, password: pwd }).then((r) => {
        //       myAuth.setCookie('tools.datatv.it', r.accessToken, myAuth.expiration);
        //       $('.loginError').remove();
        //       window.location.href = myAuth.loginRedirect;

        //    }).catch((e) => {
        //       console.log('Lgin error', e);
        //       myAuth.deleteCookie('tools.datatv.it');

        //       $('.loginError').remove();

        //       const urlErr = params.get('err');

        //       let mess = 'Errore Login Generico';
        //       if (e.message == 'Invalid login') mess = 'Ricontrolla le credenziali inserite.';
        //       else if (urlErr && urlErr == 'notlogged') mess = 'Effettua nuovamente il login.';
        //       else if (urlErr && urlErr == 'reauth') mess = 'Token non valido.';
        //       else if (urlErr && urlErr == 'expired') mess = 'Accesso Scaduto.';
        //       else if (e.message) mess = e.message;
        //       else if (e) mess = JSON.stringify(e);

        //       $('#login, #submit').before(`
        //       <div class="ui icon negative message loginError" style="text-align: left;">
        //         <i class="warning sign icon"></i>
        //         <div class="content">
        //           <div class="header">Errore Login</div>
        //           <p>Errore Accesso: ${mess}</p>
        //         </div>
        //       </div>`);

        //       myFunc.elLoader(false);

        //       throw e;
        //    });
        // }

        // // reAuthenticate
        // function reAuth() {
        //    console.log('Reauthenticate...');
        //    // true forza il refresh del token
        //    return client.reAuthenticate(true).then((r) => {
        //       // if (myVars) myVars.io = r.user; spostato in datatv.js
        //       if (autoLogout !== false) myAuth.logoutOnExpire();

        //       // Listen for channel user
        //       client.service('users').on('patched', (user) => myFunc.updateOnlineUser(user));
        //       return `Logged Successful as: ${myVars.io.ruolo}`;

        //    }).catch((e) => {
        //       if (e.name == 'NotAuthenticated') {
        //          client.logout().then((r) => {
        //             window.location.href = myAuth.logoutRedirect + '?err=reauth';
        //          });
        //       }

        //       throw new Error('Reauthenticate Fallito...');
        //    });
        // }
    },

    logout: function () {
        myAuth.deleteCookie('tools.datatv.it');
        myAuth.deleteCookie('content2.datatv.it');

        if (client) {
            return client.logout().then((r) => {
                window.location.href = myAuth.logoutRedirect;
            }).catch((e) => {
                console.log('errore logout', e);
            });

        } else {
            window.location.href = myAuth.logoutRedirect;

        }
    },

    logoutOnExpire: function () {
        // Logout on expire
        if (client && client.authentication.authenticated) {
            let withoutToken = 0;
            let interval = 3000;
            let max = interval * 10

            setInterval(function () {
                const cookie = myAuth.getCookie('tools.datatv.it') || myAuth.getCookie('content2.datatv.it');
                if (cookie) withoutToken = 0;
                else withoutToken += interval;

                if(!cookie)
                    console.log(`[logoutOnExpire] fra ${(max - withoutToken) / 1000} secondi`);

                if (withoutToken >= max) {
                    console.log(`[logoutOnExpire] expired`);
                    myAuth.deleteCookie('tools.datatv.it');
                    myAuth.deleteCookie('content2.datatv.it');
                    window.location.href = myAuth.logoutRedirect + '?err=expired'

                };
            }, interval);
        }
    },

    deleteCookie: function (name) {
        myAuth.setCookie(name, '', -1);
    },

    setCookie: function (name, value, expiration) {
        let ex;
        if (expiration == -1) ex = -1;
        else {
            const en = expiration.match(/\d{1,}/gi)[0];
            const et = expiration.match(/[a-z]/gi)[0];

            if (et == 'h') ex = en * 60 * 60 * 1000;
            if (et == 'm') ex = en * 60 * 1000;
            if (et == 's') ex = en * 1000;
        }

        // Set Cookie
        const d = new Date;
        d.setTime(d.getTime() + ex);

        document.cookie = name + '=' + value + '; domain=datatv.it; path=/;expires=' + d.toUTCString();
    },

    getCookie: function (name) {
        const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    },
}

module.exports = myAuth;
