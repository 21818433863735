const myAuth = require("./myAuth");

module.exports = {
   init: function () {
      window.addEventListener('error', (e) => {
         myError.handle('window', e);
      });

      window.onunhandledrejection = (e) => {
         myError.handle('promise', e);
      };

      // Reauthenticate se disconnesso prima delle chiamate
      client.hooks({
         async before(hook) {
            if (hook.path != 'authentication' && myVars.disconnected) {
               let auth = await client.get('authentication');
               if (auth && auth.accessToken) {
                  try {
                     await myAuth.login('forceJwt');
                     myVars.disconnected = 0;

                  } catch (e) {
                     myAuth.logout();

                  }
               } else {
                  myAuth.logout();
               }
            }
         }
      })

      // SOCKET
      socket.on('reconnect', (e) => {
         $('.ui.toast').toast('close');
         $('body').toast({
            message: `Connessione Ristabilita!`,
            class: 'green center aligned',
            displayTime: 2000,
         });
      });

      socket.on('error', (e) => {
         myError.handle('socket error', e);
      });

      socket.on('connect_error', (e) => {
         myError.handle('socket connect_error', e);
      });

      socket.on('reconnect_error', (e) => {
         myError.handle('socket reconnect_error', e);
      });

      socket.on('disconnect', (e) => {
         myError.handle('socket disconnect', e);
      });

      // CLIENT
      client.hooks({
         error(context) {
            myError.handle('client', context.error);
         },
      });
   },

   handle: async function (mySource, e, message, source, lineno, colno) {
      if (e == 'transport close') console.log('io disconnect, probabile reboot del server');
      console.log(`:::::::::: Errore ${mySource ? mySource : 'Generico'} ::::::::::\n`, e);

      if (e.reason && e.reason.message && e.reason.message == `Cannot read properties of undefined (reading 'top')`) {
         return;
      }

      // CHIUDO GLI ERRORI PRECEDENTI
      $('.ui.toast').toast('close');

      if (mySource == 'promise' && e.reason && e.reason.message) {
         $('body').toast({
            message: e.reason.message,
            class: 'red center aligned',
            displayTime: 0,
         });

         $('.dimmer.modalLoader').remove();
      }

      if (mySource.indexOf('socket') != -1) {
         $('body').toast({
            message: `Disconnesso! (${mySource})`,
            class: 'red center aligned',
            displayTime: 0,
         });

         myVars.disconnected = 1;
      }

      if (mySource == 'client') {
         if (e.message == 'Not authenticated') {
            $('body').toast({
               message: `Autenticazione Scaduta, ricarica la pagina`,
               class: 'red center aligned',
               displayTime: 0,
            });
         }
      }

      if (e.type == 'FeathersError' && e.name == 'NotAuthenticated') {
         // Non funziona al reboot cambia l'access token
         //   let authentication = await client.get('authentication');

         //   if (authentication.accessToken) {
         //      return myAuth.login();
         //   }
      }
   },

   show: function (title, error, classe) {
      return new Promise((resolve, reject) => {
         $('.ui.popup').popup('hide all');
         $('#errorModal').remove();

         console.error(error);

         const $emodal = $(`
        <div class="ui mini modal ${classe ? classe : 'myError'}" id="errorModal">
          <div class="header"><i class="red warning sign icon"></i>${title ? title : 'Errore'}</div>
          <div class="content">${error.message ? error.message : error}</div>
          <div class="actions">
            <div class="ui cancel red small button"><i class="remove icon"></i>Chiudi</div>
          </div>
        </div>
        `).appendTo('body');

         // Show Modal
         $emodal.modal({
            autofocus: false,
            allowMultiple: true,
            transition: 'zoom',
            closable: true,
            duration: 250,
            onDeny: function () {
               setTimeout(function () {
                  resolve('ko');
               }, 500);
            },
         }).modal('show');
      });
   },
};