class Listing {

   static raiNames = [
      // DB, Rayplay, RaiWeb
      ['Rai 1', 'rai-1', 'RaiUno'],
      ['Rai 2', 'rai-2', 'RaiDue'],
      ['Rai 3', 'rai-3', 'RaiTre'],
      ['Rai 4', 'rai-4', 'Rai4'],
      ['Rai 5', 'rai-5', 'Extra'],
      ['Rai Movie', 'rai-movie', 'RaiMovie'],
      ['Rai Premium', 'rai-premium', 'Premium'],
      ['Rai Gulp', 'rai-gulp', 'RaiGulp'],
      ['Rai YoYo', 'rai-yoyo', 'Yoyo'],
      ['Rai Storia', 'rai-storia', 'RaiEDU2'],
      ['Rai Scuola', 'rai-scuola'],
      ['Rai News 24', 'rai-news-24', 'RaiNews'],
      ['Rai Sport 1', 'rai-sport', 'RaiSport1'],
      ['Rai Sport 2', 'rai-sport-piu-hd', 'RaiSport2'],
      // Canali sconosciuti RaiWeb
      // EuroNews
      // RaiEducational
      // RaiMed
      // RaiWorld
   ];

   static programmiEpisodici = [
      [`Rai 1`, 1, `A Sua immagine`, `ContentItem-fb891fc2-8de8-4b46-9f1b-75022006dbe6`],
      [`Rai 1`, 1, `Buongiorno Benessere`, `ContentItem-5c5513db-04bb-48e5-8176-e4c9ad652e67`],
      [`Rai 1`, 1, `Ciao Maschio`, `ContentItem-b3982916-4085-4703-925c-f9eb0a3a37cd`],
      [`Rai 1`, 0, `Cinematografo`, `ContentItem-3e3f2675-1f46-4c1f-8c90-9cc3be27606c`],
      [`Rai 1`, 1, `Il Provinciale`, `ContentItem-2e40451a-a74d-48f4-92fb-5b4e46cb988a`],
      [`Rai 1`, 0, `L'Eredità`, `ContentItem-199044ef-970c-45f0-95a8-d4f796263485`],
      [`Rai 1`, 0, `L'Eredità Weekend`, `ContentItem-199044ef-970c-45f0-95a8-d4f796263485`],
      [`Rai 1`, 0, `Oggi è un altro giorno`, `ContentItem-32f32a3e-4191-4fb0-9bc1-dea59f923258`],
      [`Rai 1`, 0, `Storie di sera`, `ContentItem-593ea48a-fd7a-47c4-b361-917bddd7a038`],
      [`Rai 1`, 1, `The Voice Senior`, `ContentItem-c4ad104e-4762-4f9f-b763-0828e808e723`],
      [`Rai 2`, 0, `Bellama'`, `ContentItem-1347424c-6bf9-4847-b7db-3a7654fb3561`],
      [`Rai 2`, 1, `Belve`, `ContentItem-84bf6baa-0e7c-494d-9baf-c593d8d10285`],
      [`Rai 2`, 0, `Check Up`, `ContentItem-a2d0a673-b33f-4ba8-8f43-f54d29584920`],
      [`Rai 2`, 1, `Citofonare Rai2`, `ContentItem-0115d22c-fdec-44ca-9e4e-4b9e773457d0`],
      [`Rai 2`, 1, `Cook 40`, `ContentItem-43699c82-0a0c-4ba0-a4db-70a15a41b2ce`],
      [`Rai 2`, 0, `E viva il Videobox`, `ContentItem-69a3cfa9-5643-46d1-9bc9-fcb6fabc9f4f`],
      [`Rai 2`, 0, `Generazione Z`, `ContentItem-b6d5368c-c35d-4335-aabc-a7f6841db71f`],
      [`Rai 2`, 0, `I Fatti Vostri`, `ContentItem-30fac42a-0bd3-4b1b-a6e6-6f54fc5785a2`],
      [`Rai 2`, 0, `I Lunatici`, `ContentItem-af882a79-6b8d-40f6-a463-bb940a2e0eee`],
      [`Rai 2`, 0, `Il meglio di Radio2 Social Club`, `ContentItem-4553831f-3090-485c-be76-6b5b9a09e3a7`],
      [`Rai 2`, 0, `Nei tuoi panni`, `ContentItem-d982cf07-2119-4b73-9d2b-a5bdf3807419`],
      [`Rai 2`, 0, `Ore 14`, `ContentItem-16355c3d-0260-4304-abfd-5ef2b468247f`],
      [`Rai 2`, 0, `Radio2 Happy Family`, `ContentItem-ba895d20-afdd-4d5a-823b-b0df9ff7904b`],
      [`Rai 2`, 0, `Radio2 Social Club`, `ContentItem-4553831f-3090-485c-be76-6b5b9a09e3a7`],
      [`Rai 2`, 1, `Re Start`, `ContentItem-04155942-81af-4da3-a311-092d027cf9f4`],
      [`Rai 2`, 1, `Stasera c'è Cattelan su Rai2`, `ContentItem-35d8e357-e1e3-4e27-a160-0a62d2432049`],
      [`Rai 2`, 1, `Stasera tutto è possibile`, `ContentItem-d36163c7-ed6a-44ef-abb9-25a7492b354f`],
      [`Rai 2`, 1, `Ti sembra normale?`, `ContentItem-ae553085-4890-483f-990e-15f3a6e6c6a7`],
      [`Rai 2`, 1, `Top - Tutto quanto fa tendenza`, `ContentItem-ecb69282-31af-4aa7-95fb-2220436a6f33`],
      [`Rai 2`, 0, `Viva Rai2`, `ContentItem-afa8b47d-b0bf-49ff-bbc7-1d1410ec7dc1`],
      [`Rai 2`, 0, `Vorrei dirti che`, `ContentItem-008d188c-31d6-42b5-a1bf-08fd4e1d2907`],
      [`Rai 3`, 0, `#cartabianca`, `ContentItem-c6b2406f-f643-4979-b867-0c8fc81c0e4b`],
      [`Rai 3`, 0, `Agorà`, `ContentItem-946e3fb9-b749-4f57-be26-331fd722c73d`],
      [`Rai 3`, 0, `Agorà Extra`, `ContentItem-946e3fb9-b749-4f57-be26-331fd722c73d`],
      [`Rai 3`, 0, `Agorà weekend`, `ContentItem-a00f27d6-6baf-4623-926f-839a71891111`],
      [`Rai 3`, 0, `Alla scoperta del ramo d'oro`, `ContentItem-f0a75a47-1537-41e6-938f-c4ecb6963a2d`],
      [`Rai 3`, 0, `Blob`, `ContentItem-7da4ed57-74a3-4eae-a1c4-aa2ea691784b`],
      [`Rai 3`, 1, `Caro Marziano`, `ContentItem-26bcd3f9-c1ef-4f95-8593-e69c316107cb`],
      [`Rai 3`, 1, `Che tempo che fa`, `ContentItem-271cf921-e532-4dd0-a3d1-de70eb1a574d`],
      [`Rai 3`, 1, `Chi l'ha visto?`, `ContentItem-d26a553d-1d3e-4107-b97e-17bb57aa9a37`],
      [`Rai 3`, 1, `Costruttori di pace - Presa Diretta`, `ContentItem-798924f4-b094-4d0f-a9aa-c7277a748e38`],
      [`Rai 3`, 0, `Elisir`, `ContentItem-8a4b2c3f-d140-4ac0-8543-6dbed16001bd`],
      [`Rai 3`, 1, `Frontiere`, `ContentItem-4f01f380-e4f5-4595-b2bd-fd0ce52df326`],
      [`Rai 3`, 0, `Geo`, `ContentItem-5ab15733-ef59-4f5c-a24a-b15c8311281e`],
      [`Rai 3`, 0, `Il cavallo e la torre`, `ContentItem-d2d471f2-6c91-4af6-afaf-f14a03b86d69`],
      [`Rai 3`, 0, `Il posto giusto`, `ContentItem-a19b1fa1-bd66-47a0-85c8-d7e559b5f085`],
      [`Rai 3`, 0, `Kilimangiaro - Di nuovo in viaggio`, `ContentItem-e8d0aec1-aa7d-4dbe-a849-71c50fbc00a2`],
      [`Rai 3`, 1, `La scelta del presidente Allende`, `ContentItem-9cc09475-7b96-4c9e-aa61-c72a84f41243`],
      [`Rai 3`, 0, `Le parole`, `ContentItem-d3a6ba1e-2145-4e47-b899-7932174ce5a1`],
      [`Rai 3`, 0, `Mezz'ora in più - Il Mondo che verrà`, `ContentItem-778d855f-2db1-4680-b64b-4b1299a15363`],
      [`Rai 3`, 0, `Mi manda Raitre`, `ContentItem-0ab521c8-5404-4313-935f-ec7834cc1dc9`],
      [`Rai 3`, 1, `Mixer venti anni di Televisione`, `ContentItem-d7ee4eb7-adca-457f-9887-d8143b09a7fb`],
      [`Rai 3`, 0, `O anche no`, `ContentItem-37f9c355-2bb3-4544-8a2d-8eea4436fb27`],
      [`Rai 3`, 1, `Presa Diretta`, `ContentItem-798924f4-b094-4d0f-a9aa-c7277a748e38`],
      [`Rai 3`, 0, `Quante storie`, `ContentItem-657c9909-b180-42e7-83f1-9f4a3512697e`],
      [`Rai 3`, 1, `Rebus`, `ContentItem-01a001a6-a90f-4d8d-91cf-1b27177caa28`],
      [`Rai 3`, 1, `Sapiens, un solo pianeta`, `ContentItem-9f2772f3-876b-42b3-9165-cdd1affddc1d`],
      [`Rai 3`, 0, `Sorgente di vita`, `ContentItem-c2ad33d2-92cd-4b98-bdea-76a8d2c3c075`],
      [`Rai 3`, 1, `Splendida Cornice`, `ContentItem-92b2267e-80e9-4b83-9cbb-cca6e39dbe63`],
      [`Rai 3`, 1, `Sulla via di Damasco`, `ContentItem-250351fb-1997-45c6-b10d-986ceae27cd3`],
      [`Rai 3`, 1, `Timeline`, `ContentItem-8fce2b4d-b5c6-4ccc-9ec1-e117020cbe8c`],
      [`Rai 3`, 1, `TV Talk`, `ContentItem-0b7e91ec-07c2-47fe-9e1f-de739e813cd3`],
      [`Rai 3`, 1, `Un posto al sole`, `ContentItem-c5282ddf-8340-44cb-ab8f-b7e49e2665d5`],
      [`Rai 2`, 0, `90° Minuto`, `ContentItem-aa44f781-1b05-4a70-a8e9-35148a00ac15`],
      [`Rai 2`, 0, `La Domenica Sportiva`, `ContentItem-e0d7a2c5-bdd8-419e-a494-768b8175fb83`],
      [`Rai 1`, 2, `Domenica In`, `ContentItem-19ee0e11-f793-4480-8e61-6e49f339124b`],
      [`Rai 1`, 2, `Passaggio a Nord-Ovest`, `ContentItem-3760aefd-84d1-4c55-b6ba-649e3a4e177e`],
   ];

   static roundMinutes = function (m) {
      let mins = m.minute();
      const resto = mins % 5;

      if (resto && resto != 0 && resto < 3) {
         mins = Math.floor(mins / 5) * 5;

      } else if (resto) {
         mins = Math.ceil(mins / 5) * 5;

      }

      m.minute(mins).second(0);
      return m;
   }

   static loadDatatv = async function (channelName, startDate, endDate){
      const channel = myVars.channels.tutti.find(c => isNaN(channelName) ? c.Name == channelName : c.ID == channelName);
      const startMome = moment(startDate, 'YYYY/MM/DD');
      const endMome = moment(endDate, 'YYYY/MM/DD').add(1, 'day');
      const query = `${channel.ID} -- ${startMome.format('YYYY-MM-DD')} al ${endMome.format('YYYY-MM-DD')}`;

      client.service('program-join').timeout = 30000;
      let data = await client.service('program-join').find({ query: { title: query } })
         .then(r => r.results);

      data.map(p => {
         let mome = moment(p.dateTime, 'YYYY-MM-DD HH:mm:ss');
         p.mills = mome.format('x');
         p.myId = 'dtv_' + p.idProgramChannel;
      });

      console.log('[loadDatatv]', query, data);
      return data;
   }

    static loadRaiPlay = async function (channelName, startDate, endDate) {

      const channel = myVars.channels.tutti.find(c => isNaN(channelName) ? c.Name == channelName : c.ID == channelName);
      const raiName = this.raiNames.find(n => n[0] == channel.Name);

      const startMome = moment(startDate, 'YYYY/MM/DD');
      const endMome = moment(endDate, 'YYYY/MM/DD').add(1, 'day');
      const giorni = endMome.diff(startMome, 'days');

      const promises = [];
      let data = [];
      let urls = [];

      if (!raiName[1]) return data;

      for (let g = 0; g <= giorni; g++) {
         const giorno = startMome.clone().add(g, 'days');
          const url = `https://www.raiplay.it/palinsesto/app/${raiName[1]}/${giorno.format('DD-MM-YYYY')}.json`;
          console.log('[loadRaiPlay]', url);
         urls.push(url);

         promises.push(
            fetch(url)
               .then(r => r.json())
               .then(json => data = data.concat(json.events))
               .catch(e => e)
         );
      }

      const startMills = startMome.clone().add(channel.ExportStartTime, 'minutes').format('x');
      const endMills = endMome.clone().add(channel.ExportStartTime, 'minutes').format('x');
      const idNonEpisodici = this.programmiEpisodici.filter(pe => pe[1] === 0).map(pe => pe[3]);
      const idEpisodiciForce = this.programmiEpisodici.filter(pe => pe[1] === 2).map(pe => pe[3]);
      const rexPuntata = /(Puntata)? ?del \d{1,2}\/\d{1,2}\/\d{1,4}/gi;
      await Promise.all(promises);

      let epsToAdd = [];
      data = data.map((r, i) => {
         let mome = this.roundMinutes(moment(r.date + ' ' + r.hour, 'DD/MM/YYYY HH:mm'));

         if (data[i + 1]) {
            let next = this.roundMinutes(moment(data[i + 1].date + ' ' + data[i + 1].hour, 'DD/MM/YYYY HH:mm'));

            if (next.format('x') == mome.format('x'))
               mome.subtract(5, 'minutes');
         }

         const mills = mome.format('x');

         let p = {
            original: JSON.parse(JSON.stringify(r)),
            dateTime: mome.format('YYYY-MM-DD HH:mm:ss'),
            Title: r.name,
            epTitle: r.episode_title,
            SeriesNumber: r.season.split('/')[0],
            episodioNumerico: r.episode,
            description: r.description,
            mills: mills,
            providerId: r.program.id,
            idEpisode: null,
            myId: 'rpl_' + mills
         }

         let epsInName = r.name.match(/s\d{1,2}e\d{1,2}/gi);
         let epsTitles = r.name.split(/s\d{1,2}e\d{1,2}/gi);
         if (epsInName && epsInName.length) {
            epsInName.forEach((ep, j) => {
               let epRex = /s(\d{1,2})e(\d{1,2})/gi.exec(ep);
               let stn = epRex && epRex[1] ? epRex[1] : null;
               let epn = epRex && epRex[2] ? epRex[2] : null;

               if (!stn || !epn) return;

               if (j == 0) {
                  p.Title = r.program.name;
                  p.epTitle = epsTitles[j + 1];
                  if (!p.SeriesNumber) p.SeriesNumber = stn;
                  if (!p.episodioNumerico) p.episodioNumerico = epn;

               } else if (r.duration_in_minutes) {
                  p.Title = r.program.name;
                  let duration = parseInt(r.duration_in_minutes.replace(' min', '') / 2 / 5) * 5;
                  let newMome = mome.clone().add(duration, 'minutes');

                  // Aggiungo un passaggio
                  let newP = {
                     original: p.original,
                     dateTime: newMome.format('YYYY-MM-DD HH:mm:ss'),
                     Title: r.program.name,
                     epTitle: epsTitles[j + 1],
                     SeriesNumber: stn,
                     episodioNumerico: epn,
                     description: r.description,
                     mills: newMome.format('x'),
                     providerId: r.program.id,
                     idEpisode: null,
                     myId: 'rpl_' + newMome.format('x')
                  }

                  console.log('add', newP);
                  epsToAdd.push(newP);
               }

            });
         }

          console.log('qui....')
          if (/(Episodio|episode|ep|e)\.? ?\d{1,}/gi.test(p.Title)) {
              let rex = /(Episodio|episode|ep|e)\.? ?(\d{1,})/gi.exec(p.Title);
              console.log(rex)
          }

         if (p.epTitle && p.epTitle != p.Title) {
            p.Title = p.Title
               .replace(new RegExp(myFunc.escapeRegex(p.epTitle), 'gi'), '')
               .trim();
         }




         if (p.SeriesNumber && p.episodioNumerico)
            p.Title = p.Title.replace(new RegExp(`s${p.SeriesNumber}e${p.episodioNumerico}`, 'gi'), '');

         if (idEpisodiciForce.includes(r.program.id) && !rexPuntata.test(p.Title) && !rexPuntata.test(p.epTitle)) {
            p.epTitle = p.epTitle + `(Specifica mancante ${mome.format('DD/MM HH:mm')})`;
         }

         if (idNonEpisodici.includes(r.program.id)) {
            p.Title = p.Title.replace(rexPuntata, '');
            p.epTitle = p.epTitle.replace(rexPuntata, '');
         }

         p.Title = p.Title
            .replace(/\- ?\d{1,2}\/\d{1,2}\/\d{1,4}/gi, '')
            .replace(/(\,|\:|\-)\s*$/gi, '')
            .replace(/^\s*(\,|\:|\-)/gi, '')
            .replace(/\s+/gi, ' ')
            .trim();

         p.epTitle = p.epTitle
            .replace(/\- ?\d{1,2}\/\d{1,2}\/\d{1,4}/gi, '')
            .replace(/(\,|\:|\-)\s*$/gi, '')
            .replace(/^\s*(\,|\:|\-)/gi, '')
            .replace(/\s+/gi, ' ')
            .trim();

         return p;
      })
         //Filtro gli orari fuori range
         .concat(epsToAdd)
         .filter(p => p.mills >= startMills && p.mills < endMills)
         .sort((a, b) => a.mills - b.mills);



      console.log('[loadRayPlay]', data.length, urls, data);
      return data;
   };

   constructor(options) {
      const scope = this;
      this.minuteH = 16;

      // Opzioni
      this.options = options || {};

      if(!options.channel)
         throw new Error('Specificare almeno il parametro [channel]');

      this.channel = myVars.channels.tutti.find(c => isNaN(options.channel) ? c.Name == options.channel : c.ID == options.channel);

      this.startDate = options.startDate ? options.startDate
         : moment().format('YYYY/MM/DD');
      this.endDate = options.endDate ? options.endDate
         : moment(this.startDate, 'YYYY/MM/DD').add(1, 'days').format('YYYY/MM/DD');

      // Wrapper
      this.wrapper = document.createElement('div');
      this.wrapper.classList.add(...['wrapper', 'pGridScroll', `listing_${new Date().getTime()}`, this.options.class || 'noClass']);

      if (this.options.dest) this.options.dest.appendChild(this.wrapper);
      else document.body.appendChild(this.wrapper);

      this.wrapper.innerHTML = `
         <div class="pGridScroll">
            <div class="pGridContainer"></div>
            <div class="pGridRuler"></div>
         </div>
      `;

      this.pGridScroll = this.wrapper.querySelector('.pGridScroll');
      this.pGridContainer = this.wrapper.querySelector('.pGridContainer');
      this.pGridRuler = this.wrapper.querySelector('.pGridRuler');

      // associo l'istanza creata all'oggetto html
      this.wrapper.listing = this;

      if (this.channel) {
         this.renderListing();
      }

   }

   async downloadXLSX(type) {
      const data = type == 'datatv' ? this.dtv : type == 'rayplay' ? this.rpl : [];
      const palArray = [
         [
            'Giorno',
            'Ora',
            'Titolo Programma',
            'Titolo Episodio',
            'Stagione',
            'Episodio',
            'providerId',
            'idProgramma',
            'idEpisodio',
            'Sinossi',
            'Varie TXT',
            this.channel.Name
         ]
      ];

      data.forEach(p => {
         let mome = moment(p.mills, 'x');
         let row = [
            mome.format('DD/MM/YYYY'),
            mome.format('HH:mm'),
            p.Title,
            p.epTitle ? p.epTitle : '',
            p.SeriesNumber ? p.SeriesNumber : '',
            p.episodioNumerico ? p.episodioNumerico : '',
            p.providerId ? p.providerId : '',
            p.idProgram ? p.idProgram : '',
            p.idEpisode ? p.idEpisode : '',
            p.description ? p.description : '',
            '',
            ''
         ];

         palArray.push(row);
      });

      const wBook = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(palArray);
      XLSX.utils.book_append_sheet(wBook, ws, `Export ${this.channel.Name}`);
      myFunc.downloadXLSX(wBook, `${type.toUpperCase()} ${this.channel.Name} dal ${this.startDate} al ${this.endDate}`);

      console.log('[downloadXLSX]', type, data);

   }

   async renderListing(type) {
      const _this = this;
      myFunc.elLoader(true, false, 'Loading Palinsesti');

      if (!type) {
         this.dtv = await this.constructor.loadDatatv(this.channel.Name, this.startDate, this.endDate);
         this.rpl = await this.constructor.loadRaiPlay(this.channel.Name, this.startDate, this.endDate);

         this.compareListings(this.dtv, this.rpl);
         renderGrid('datatv', this);
         renderGrid('rayplay', this);

      } else if (type) {

         if (type == 'datatv') {

            if (!this.dtv) this.dtv = await this.constructor.loadDatatv(this.channel.Name, this.startDate, this.endDate);

            this.compareListings(this.dtv, this.rpl);
            renderGrid('datatv', this);

         } else if (type == 'rayplay') {

            if (!this.rpl) this.rpl = await this.constructor.loadRaiPlay(this.channel.Name, this.startDate, this.endDate);

            this.compareListings(this.dtv, this.rpl);
            renderGrid('rayplay', this);

         }
      }

      myFunc.elLoader(false);

      // RENDER GRID
      function renderGrid(type, _this) {

         const startDate = _this.startDate;
         const endDate = _this.endDate;
         const ExportStartTime = _this.channel.ExportStartTime;

         const startMome = moment(startDate, 'YYYY/MM/DD');
         const endMome = moment(endDate, 'YYYY/MM/DD').add(1, 'day');
         const days = [];
         const mins = [];

         // Giorni
         for (var i = 0; i < endMome.diff(startMome, 'days'); i++) {
            const day = startMome.clone().add(i, 'days');
            days.push(day);
         }

         // Ore
         for (var y = ExportStartTime; y < ExportStartTime + 288; y++) {
            let m = startMome.clone().add(y * 5, 'minutes');
            let mO = {
               m: m,
               startTime: m.minutes() + m.hours() * 60,
               text: '',
               top: m.minutes() + m.hours() * 60,
               topPx: 0,
            };

            if (m.minutes() == 0) mO.text = m.format('H:mm');
            else if ([15, 30, 45].includes(m.minutes())) mO.text = m.format('m');

            if (mO.startTime >= ExportStartTime)
               mO.top -= ExportStartTime;
            else
               mO.top += 24 * 60 - ExportStartTime;

            mO.topPx = mO.top / 5 * _this.minuteH;
            mins.push(mO);
         }

         let table = _this[type];
         if (table) table.remove();

         const data = type == 'datatv' ? _this.dtv : type == 'rayplay' ? _this.rpl : [];
         if (data && data.length) {
            _this[type] = document.createElement('table');
            _this[type].classList.add(...[type, 'pGridTable', `pGridTable_${new Date().getTime()}`]);
            _this.pGridContainer.appendChild(_this[type]);

            _this[type].innerHTML = `
               <thead>
                  <tr>
                     <th></th>
                     <th colspan="${days.length}">
                        Palinsesto ${_this.channel.Name} <b class="a_blue">${type.toUpperCase()}</b>
                        <a class="ui horizontal label downXls"><i class="download icon"></i>Scarica</a>
                     </th>
                  </tr>
                  <tr>
                     <th class="pGridDayTxt">&nbsp;</th>
                     ${days.map(d => `
                        <th class="pGridDayTxt">
                           <div>${d.format('dddd')}</div>
                           <div>${d.format('DD/MM/YY')}</div>
                        </th>
                     `).join('')}
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td style="height: ${mins[mins.length - 1].topPx + 16}px">
                     ${mins.map(m => `
                        <div style="top: ${m.topPx}px"><span class="${m.startTime % 60 ? '' : 'hour'}">${m.text}</span></div>
                     `).join('')}
                     </td>

                     ${days.map(d => `
                           <td class="pGridCell" data-startdate="${d.format('YYYY-MM-DD')}"></td>
                     `).join('')}
                  </tr>
               </tbody>
            `;

            data.forEach((p, i) => _this.renderPassaggio(_this[type], p, data[i + 1]));
         }

         // Aggiusto il contenitore
         document.querySelector('.myPage').style.paddingBottom = 0;
         const tableNodes = _this.pGridContainer.querySelectorAll('.pGridTable');
         _this.pGridContainer.style.minWidth = (days.length * 153 + 50) * tableNodes.length + 'px';
         for (let i = 0; i < tableNodes.length; i++) {
            tableNodes[i].style.width = 100 / tableNodes.length + '%';
         }

         if (_this[type]) {
            _this[type].querySelector('.downXls').addEventListener('click', function () {
               _this.downloadXLSX(type);
            })
         }

         return _this[type];

      }
   }

   compareListings(listA, listB) {
      if (!listA || !listA.length || !listB || !listB.length)
         return;

      // 60000ms = 1 minuto
      listB.forEach((prog, i) => {
         let notMatching = '';
         let hourMatch = listA.find(e => e.mills == prog.mills);
         let similarity = -1;
         let bestSim = null;

         if (!hourMatch) {
            notMatching = 'hour';

         } else if (hourMatch) {
            const epTitleclean = prog.epTitle.replace(/del \d{1,2}\/\{1,2}\/\d{1,4}/gi, '');
            const similarityEp = myFunc.getStringSimilarityScore(hourMatch.Title, epTitleclean);
            const similarityPr = myFunc.getStringSimilarityScore(hourMatch.Title, prog.Title);

            bestSim = similarityEp.score > similarityPr.score ? similarityEp : similarityPr;
            if (bestSim.score < 0.15 && bestSim.editDistanceNormalized > 0.3 && !bestSim._searchInFront) notMatching = 'program';

         }

         prog.similarity = bestSim;
         prog.notMatching = notMatching;
      });
   }

   renderPassaggio(dest, p, nextp){
      const ExportStartTime = this.channel.ExportStartTime;
      const currm = moment(p.dateTime, 'YYYY-MM-DD HH:mm:ss');
      const nextm = nextp ? moment(nextp.dateTime, 'YYYY-MM-DD HH:mm:ss') : null;
      const currStartTime = currm.hours() * 60 + currm.minutes();

      // Colonna
      let dC = currm.clone().startOf('day').format('YYYY-MM-DD'); // Colonna Giorno
      if (currStartTime < ExportStartTime) dC = currm.clone().subtract(1, 'day').format('YYYY-MM-DD');

      const tdDest = dest.querySelector(`[data-startdate="${dC}"]`);
      if (!tdDest) return;

      // Top e Height
      let mH = nextm ? nextm.diff(currm, 'minutes') : 10; // Durata = Altezza
      let mY = currStartTime - ExportStartTime; // Ora = y

      // Porto indietro di 1 giorno e incremento la y di 24 ore
      if (currStartTime < ExportStartTime) {
         mY += 24 * 60;
      }

      // Se più alto della tabella lo accorcio
      // let remainingH = $area.find('table td').eq(0).height() - ((mY + mH) / 5 * minuteH - 2);
      // if (remainingH < 0) {
      //    mH += remainingH * 5 / minuteH;
      // }

      let info = '';
      if (p.attributeMask) {
         let attributes = myFunc.getAttributesFromMask(p.attributeMask).map(a => a.Name);
         if (attributes.includes('Diretta'))
            info = `<div class="ui mini red label attr" title="Diretta">D</div>`;
         else if (attributes.includes('Prima Visione Assoluta'))
            info = `<div class="ui mini red label attr" title="Prima Visione Assoluta">P</div>`;
         else if (attributes.includes('Prima Visione'))
            info = `<div class="ui mini basic red label attr" title="Prima Visione">P</div>`;
      }

      const pdiv = document.createElement('div');
      pdiv.classList.add(...['myResMini', (p.restype == 'episode' ? 'teal' : p.restype == 'base' ? 'orange' : p.restype == 'program' ? 'blue' : 'grey')]);
      pdiv.dataset.idpc = p.idProgramChannel;
      pdiv.style.top = `${mY / 5 * this.minuteH - 1}px`;
      pdiv.style.height = `${(mH / 5 * this.minuteH) - 2}px`;
      pdiv.innerHTML = `
         ${info ? info : ''}
         <div id="${p.myId}" class="text ${mH <= 5 ? 'oneLine' : ''}" title="${currm.format('HH:mm')} ${p.Title} ${p.epTitle ? ` / ${p.epTitle}` : ''}">
            <span class="opacita6 hour">${currm.format('HH:mm')}</span>
            <span class="seasonEpisode">
               ${p.SeriesNumber ? `<b class="a_blue">S${p.SeriesNumber}</b>` : ''}
               ${p.episodioNumerico ? `<b class="a_teal">E${p.episodioNumerico}</b>` : ''}
            </span>
            <b class="title">${p.Title}</b> ${p.epTitle && mH >= 10 ? `<br><span class="opacita6 eptitle">${p.epTitle}</span>` : ''}
         </div>
      `;

      p.div = pdiv;
      tdDest.appendChild(pdiv);

      if (p.notMatching == 'hour') pdiv.classList.add('red');
      else if (p.notMatching == 'program') pdiv.classList.add('orange');


      pdiv.addEventListener('click', (ev) => {
         console.log(p);
      });

      // SCROLL RULER
      const _this = this;
      this.debounceOver = _.debounce(function (pdiv) {
         $(_this.pGridRuler).animate({
            opacity: 1,
            top: 38 * 2 + parseInt(pdiv.style.top) + 'px',
            height: pdiv.style.height
         }, 50);

      }, 100, {
         'leading': false,
         'trailing': true
      });


      pdiv.addEventListener('mouseover', (ev) => {
         this.debounceOver(pdiv);
         // Column Hihglight
         // let data = tdDest.dataset.startdate;
         // let tds = this.pGridContainer.querySelectorAll('td');

         // [...tds].forEach(td => {
         //    if (td.dataset.startdate == data)
         //       td.classList.add('highlight');
         //    else
         //       td.classList.remove('highlight');
         // });
      });

   }
}

if (typeof module != 'undefined') {
   module.exports = Listing;
}