// INIT MODAL
module.exports = {
   init: function () {
      if (!['Admin', 'Supervisor'].includes(myVars.io.ruolo)) return;

      const $header = $('.fixed.menu .item.header').not('.logo');
      const $assegnazioniBtn = $('<a class="item" data-modalassegnazioni=""><i class="envelope open icon"></i><div class="assegnazioniCounter a_blue">0</div>&nbsp;Assegnazioni Oggi</a>').insertAfter($header);

      client.service('archivioassegnazioni').on('created', (a) => myAssegnazioni.counter(a));
      client.service('archivioassegnazioni').on('removed', (a) => myAssegnazioni.counter(a));
      myAssegnazioni.counter();

      $('body').on('click', '[data-modalassegnazioni]', function () {
         myAssegnazioni.show();
      });
   },

   counter: function (a) {
      if (a) console.log('Nuova Assegnazione', a);
      const dStart = moment().format('YYYY-MM-DD');
      const dEnd = moment().add(1, 'days').format('YYYY-MM-DD');
      client.service('get-assegnazioni').find({ query: { startDate: dStart, endDate: dEnd } }).then((r) => {
         $('.assegnazioniCounter').text(r.length);
      });
   },

   show: async function (forceStart, forceEnd) {

      // Modal
      const $modal = myAssegnazioni.createModal(forceStart, forceEnd);
      const $table = await myAssegnazioni.createTable($modal.find('.content'), forceStart, forceEnd);

      $modal.modal({
         autofocus: false,
         allowMultiple: true,
         transition: 'scale',
      }).modal('show');

      $modal.find('.ui.dropdown').dropdown('set selected', 'content@datatv.it');

      $modal.on('click', '.chiudi', async function (e) {
         if (!$table) {
            $modal.modal('hide');
            return;
         }

         const promises = [];
         $table.DataTable().rows().every(function (rowIdx, tableLoop, rowLoop) {
            const data = this.data();
            data.note = $(this.node()).find('textarea').val();
            promises.push(client.service('archivioassegnazioni').patch(data.archivioassegnazioni_id, { note: data.note ? data.note : null }));
         });

         await Promise.all(promises);
         $modal.modal('hide');
      });

      $modal.on('click', '.sendEmail', async function (e) {
         const oggetto = $modal.find('.oggetto').val();
         const messaggio = $modal.find('.messaggio').val().replace(/\n/gi, '<br>');
         const destinatariVal = $modal.find('.destinatari').dropdown('get value');
         const assigned_tos = [];
         const mailData = [];

         // patch archivio assegnazioni
         const promises = [];
         $('#tableAssegnazioni').DataTable().rows({ selected: true }).every(function (rowIdx, tableLoop, rowLoop) {
            const data = this.data();
            data.note = $(this.node()).find('textarea').val();
            promises.push(client.service('archivioassegnazioni').patch(data.archivioassegnazioni_id, { note: data.note ? data.note : null }));
            assigned_tos.push(data.assigned_to)
            mailData.push(data);
         });
         await Promise.all(promises);

         // Destinatari
         let destinatari = [];
         if (destinatariVal == 'tutti') {
            destinatari = myVars.users.tutti.filter(u => u.attivo == true && /Content/.test(u.area)).map((u) => u.email);

         } else if (destinatariVal == 'assegnati') {
            destinatari = myVars.users.tutti.filter(u => assigned_tos.includes(u.id)).map((u) => u.email);

         } else {
            destinatari.push(destinatariVal);
         }

         // Aggiungo la mail del mittente
         destinatari.push(myVars.io.email);

         if (['tutti', 'assegnati'].includes(destinatariVal)) {
            // Aggiungo Babbi
            destinatari.push(myVars.users.tutti.find((u) => u.id == 24).email);

            // Aggiungo i Supervisor
            let supervisors = myVars.users.tutti.filter((u) => u.attivo == true && u.ruolo == 'Supervisor').map((u) => u.email);
            destinatari = destinatari.concat(supervisors);
         }

         // Rimuovo i destinatari duplicati
         destinatari = [...new Set(destinatari)];

         // INVIO MAIL
         let sendMail = await myAssegnazioni.sendMail(mailData, oggetto, messaggio, destinatari);

         await myFunc.affermativeModal('Email Inviata', `Email Inviata Correttamente con <b>${mailData.length} Assegnazioni</b> a:<br>${destinatari.join(', ')}`);
         myAssegnazioni.createTable($modal.find('.content'), forceStart, forceEnd);
      });
   },

   createModal: function (forceStart, forceEnd) {
      // Rimuove tutte le modal
      $('.ui.modal').remove();

      const $modal = $(`
      <div class="ui fullscreen modal" id="modalAssegnazioni">
        ${forceStart ? `<div class="header"><i class="envelope icon"></i> Assegnazioni dal: ${moment(forceStart, 'YYYY/MM/DD').format('dddd DD MMMM YYYY')} al ${moment(forceEnd, 'YYYY/MM/DD').format('dddd DD MMMM YYYY')}</div>` :
            `<div class="header"><i class="envelope icon"></i> Assegnazioni di Oggi, ${moment().format('dddd DD MMMM YYYY (HH:mm)')}</div>`}

        <div class="scrolling content">

          <div class="ui form" style="margin-bottom: 20px;">
            <div class="fields">
              <div class="twelve wide field">
                <label>Oggetto</label>
                <input type="text" placeholder="Oggetto" class="oggetto" value="${forceStart ? `[tools.datatv.it] Assegnazioni Schede - dal: ${moment(forceStart, 'YYYY/MM/DD').format('DD MMMM YYYY')} al ${moment(forceEnd, 'YYYY/MM/DD').format('DD MMMM YYYY')}` :
            `[tools.datatv.it] Assegnazioni Schede - ${moment().format('DD MMMM YYYY (HH:mm)')}`}">
              </div>
              <div class="four wide field"> <label>Destinatari</label>
                <div class="ui selection dropdown destinatari" tabindex="0"> <input type="hidden" class="" value="assegnati"> <i
                    class="dropdown icon"></i>
                  <div class="text">--</div>
                  <div class="menu" tabindex="-1">
                    <div class="item active selected" data-value="assegnati">Assegnati</div>
                    <div class="item" data-value="tutti">Tutti</div>
                    <div class="item" data-value="${myVars.io.email}">${myVars.io.email}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <label>Messaggio</label>
              <textarea rows="3" class="messaggio">Buongiorno a tutti,
queste le assegnazioni:</textarea>
            </div>
          </div>
      </div>

      <div class="actions">
        <div class="ui red right labeled icon button chiudi">Chiudi (Salva le note)<i class="ban icon"></i></div>
        <div class="ui green right labeled icon button sendEmail">Invia E-mail<i class="envelope icon"></i></div>
      </div>


    </div>`).appendTo('body');

      return $modal;
   },

   createTable: async function ($dest, forceStart, forceEnd) {
      const dStart = forceStart ? moment(forceStart, 'YYYY/MM/DD').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      const dEnd = forceEnd ? moment(forceEnd, 'YYYY/MM/DD').add(1, 'days').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD');
      const assegnazioni = await client.service('get-assegnazioni').find({ query: { startDate: dStart, endDate: dEnd } });
      console.log('Assegnazioni di Oggi:', assegnazioni);

      $dest.find('#tableAssegnazioni').remove();
      $dest.find('#tableAssegnazioniMess').remove();

      if (!assegnazioni.length) {
         $dest.append(`
        <div class="ui icon message" id="tableAssegnazioniMess">
          <i class="inbox icon"></i>
          <div class="content">
            <div class="header">
              Non sono presenti assegnazioni
            </div>
            <p>Oggi non sono state ancora fatte assegnazioni.</p>
          </div>
        </div>
      `);
         return;
      }

      const $table = $(`<table class="ui smallest compact celled table" id="tableAssegnazioni"></table>`).appendTo($dest);
      const columns = assegnazioni[0] ? defineColumns(Object.keys(assegnazioni[0])) : { order: false, cols: [{ title: '', orderable: false }] };
      myDt.createTable($table, assegnazioni, columns, 'idUser', 200, true);
      myDt.orderCols($table, true, [['Request_Dinizio', 'desc']]);

      return $table;

      function defineColumns(colNames) {
         const cols = [];
         const order = [];
         const editIcon = ['Admin', 'Supervisor'].includes(myVars.io.ruolo) ? '<i class="large hand pointer outline icon"></i>' : '';

         const myOrder = [
            'Request_Dinizio',
            'Request_ethanChannelCode',
            'Request_canali',

            'Request_ethanFlag',
            'Request_TipoRichiesta',
            'Request_num_stagione',
            'Request_episodio',
            'Request_titolo',
            '_idProgram',
            '_idEpisode',

            'tipo',
            'assigned_to',
            'created_by',
            'created_at',

            'codiceF',
            'Satellite_Program_Title',
            'confirm_by',
            'confirmed_at',
            'note',
            'sent',
            'archivioassegnazioni_id',
            'Request_id',
            'Request_tipologia',
            'Request_externalID',
            'Request_anno_prod',
            'Request_ethanMetadatasets',
         ];

         // Aggiungo prima l'ordine richiesto
         myOrder.forEach((m) => {
            order.push(colNames.findIndex((n) => {
               return n == m;
            }));
         });

         // Poi le restanti colonne
         colNames.forEach((c, i) => {
            if (!order.includes(i)) {
               order.push(i);
               myOrder.push(c);
            }
         });

         // Opzioni Colonne
         myOrder.forEach((n) => {
            let col = { visible: false };

            if (n == 'Request_Dinizio') {
               col = {
                  title: 'Inizio',
                  className: 'collapsing',
                  createdCell: function (td, cellData, rowData, row, col) {
                     if (!cellData) $(td).addClass('empty');
                  },
                  render: function (data, type, row, meta) {
                     if (!data) return '';
                     const m = moment(data, 'YYYY-MM-DD HH:mm:ss');
                     const d = m.format('ddd DD/MM/YY');
                     const h = m.format('HH:mm');

                     return `${d}<br>${h ? '<span class="a_grey opacita8"><i class="clock outline icon"></i>' + h + '</span>' : ''}`;
                  },
               };
            }

            if (n == 'Request_ethanChannelCode') {
               col = {
                  title: 'Canali',
                  className: 'collapsing',
                  createdCell: function (td, cellData, rowData, row, col) {
                     if (!cellData && !rowData.Request_canali) $(td).addClass('empty');
                     // if(rowData.Request_id) $(td).attr('data-popassegna_canale', '');
                  },
                  render: function (data, type, row, meta) {
                     const ch = data ? myVars.channels.ibms.find((c) => c.code == data) : '';
                     const chDb = row.Request_canali ? row.Request_canali.split(',')[0] : '';
                     const isAcquisition = /purchase/gi.test(row.Request_nota);

                     return `
                ${isAcquisition ? `<span class="a_orange">Aquisition</span><br>` : ''}
                ${ch ? `<span data-chcode='${ch.code}'>${ch.clean.replace(/Channel/gi, '')}</span><br>` : ''}
                ${chDb ? `<span class="a_grey opacita8">${chDb.replace(/Channel/gi, '')}</span>` : ''}
                ${!data && !row.Request_canali && !isAcquisition && row.Request_id ? editIcon : ''}`;
                  },
               };
            }


            if (n == 'Request_ethanFlag') {
               col = {
                  title: 'Richiesta',
                  className: 'collapsing',
                  createdCell: function (td, cellData, rowData, row, col) {
                     if (!rowData.Request_id) $(td).addClass('empty');
                  },
                  render: function (data, type, row, meta) {
                     if (!row.Request_id) return `Database`;

                     return `
                ${row._collegate ? `<a class="ui mini left corner label" data-filterlink="Response_id==${row._collegate}"><i class="fitted linkify icon"></i></a>` : ''}
                <div class="ui smallest ${data ? 'blue' : 'red'} label">${data ? 'SKY' : 'TIM'}</div>
                ${/now/i.test(row.Request_ethanMetadatasets) ? '<div class="ui smallest teal label">NOW</div>' : ''}
                ${/kids/i.test(row.Request_ethanMetadatasets) ? '<div class="ui smallest orange label">KIDS</div>' : ''}
                ${/mediaset/i.test(row.Request_ethanMetadatasets) ? '<div class="ui smallest grey label">MS</div>' : ''}
                ${/dazn/i.test(row.Request_ethanMetadatasets) ? '<div class="ui smallest black label">DAZN</div>' : ''}
              `;
                  },
               };
            }

            if (n == 'Request_TipoRichiesta') {
               col = {
                  title: 'Tipo',
                  className: 'collapsing',
                  render: function (data, type, row, meta) {
                     let tipo = '';
                     if (data == 'TT') tipo = 'PROGRAMMA';
                     if (data == 'SE') tipo = 'SERIE';
                     if (data == 'SS') tipo = 'STAGIONE';
                     if (data == 'EP') tipo = 'EPISODIO';
                     return `
                <span class="${tipo == 'EPISODIO' ? 'a_teal' : 'a_blue'}">${tipo}</span><br>
                ${row.Request_tipologia ? `<span class="a_grey opacita8">${myFunc.camelize(row.Request_tipologia)}</span><br>` : ''}
                ${row.Response_genres ? `<span class="a_grey opacita8">${row.Response_genres.split('#')[0]}</span>` : ''}
                ${row.Request_anno_prod ? `<span class="a_grey opacita8">(${row.Request_anno_prod})</span>` : ''}

              `;
                  },
               };
            }

            if (n == 'Request_num_stagione') {
               col = {
                  title: '&nbsp;&nbsp;S&nbsp;&nbsp;',
                  className: 'collapsing center aligned',
                  createdCell: function (td, cellData, rowData, row, col) {
                     if (!cellData) $(td).addClass('empty');
                  },
                  render: function (data, type, row, meta) {
                     const s = data && data != 'null' ? data : '';
                     return `<span class="ui small header">${s}</span>`;
                  },
               };
            }

            if (n == 'Request_episodio') {
               col = {
                  title: '&nbsp;&nbsp;E&nbsp;&nbsp;',
                  className: 'collapsing center aligned',
                  createdCell: function (td, cellData, rowData, row, col) {
                     if (!cellData) $(td).addClass('empty');
                  },
                  render: function (data, type, row, meta) {
                     const e = data && data != 'null' ? data : '';
                     return `<span class="ui small header">${e}</span>`;
                  },
               };
            }

            if (n == 'Request_titolo') {
               col = {
                  title: 'Titolo Richiesta / DB',
                  className: '',
                  render: function (data, type, row, meta) {
                     const link = myFunc.getLinkSchedaContent(row);
                     const toSearch = data ? 'Request_titolo' : 'Satellite_Program_Title';

                     return `
                ${data ? `<a href="${link}" target="_blank"><b>${data}</b></a><br>` : ''}
                ${row.Satellite_Program_Title ? `<span class="a_grey opacita8">${row.Satellite_Program_Title}</span>` : ''}
              `;
                  },
               };
            }

            if (n == '_idProgram') {
               col = {
                  title: 'ID',
                  className: 'collapsing',
                  render: function (data, type, row, meta) {
                     const idSerie = row._idSerie && row._idSerie != -1 ? row._idSerie : '';
                     const idProgram = row._idProgram && row._idProgram != -1 ? row._idProgram : '';
                     const idEpisode = row._idEpisode && row._idEpisode != -1 ? row._idEpisode : '';

                     return `
                <div class="ui labels">
                  ${idSerie ? `<div class="ui smallest blues label">SE ${idSerie}</div>` : ``}
                  ${idProgram ? `<div class="ui smallest blue label opacita8">PR ${idProgram}</div>` : ``}
                  ${idEpisode ? `<div class="ui smallest teal label">EP ${idEpisode}</div>` : ``}
                </div>
              `;
                  },
               };
            }

            if (n == 'tipo') {
               col = {
                  title: 'T',
                  visible: true,
                  className: 'collapsing center aligned',
                  render: function (data, type, row, meta) {
                     return data == 'Grafico' ? '<span style="display:none">G</span><i class="fitted image large grey icon"></i>' : data == 'Redattore' ? '<span style="display:none">R</span><i class="fitted font large grey icon"></i>' : '';
                  },
               };
            }

            if (n == 'assigned_to') {
               col = {
                  title: 'Assegnato a:',
                  visible: ['Admin', 'Supervisor'].includes(myVars.io.ruolo),
                  className: 'collapsing',
                  createdCell: function (td, cellData, rowData, row, col) {
                     const user = cellData ? myVars.users.tutti.find((u) => u.id == cellData) : myVars.users.tutti.find((u) => u.old_id == cellData);
                     if (user) $(td).addClass('onlineUserBg_' + user.id).css('padding-right', '60px');

                     // if(rowData.tipo == 'Grafico') $( td ).attr('data-popassegna_grafico', '');
                     // else if(rowData.tipo == 'Redattore') $( td ).attr('data-popassegna_redattore', '');

                  },
                  render: function (data, type, row, meta) {
                     const user = data ? myVars.users.tutti.find((u) => u.id == data) : myVars.users.tutti.find((u) => u.old_id == data);
                     return user ? `<img data-filteruserid="${user.id}" class="ui mini avatar image" style="float:left;" src="./${user.avatar ? 'avatars/' + user.avatar : 'avatars/user.png'}"><b>${user.nome.substring(0, 1)}. ${user.cognome}</b><br>
              <span class="a_grey opacita8">${user.ruolo}</span>` : '';
                  },
               };
            }

            if (n == 'created_by') {
               col = {
                  title: 'Assegnato da:',
                  visible: ['Admin', 'Supervisor'].includes(myVars.io.ruolo),
                  className: 'collapsing',
                  createdCell: function (td, cellData, rowData, row, col) {
                     const user = cellData ? myVars.users.tutti.find((u) => u.id == cellData) : myVars.users.tutti.find((u) => u.old_id == cellData);
                     if (user) $(td).addClass('onlineUserBg_' + user.id);
                  },
                  render: function (data, type, row, meta) {
                     const user = data ? myVars.users.tutti.find((u) => u.id == data) : myVars.users.tutti.find((u) => u.old_id == data);
                     return user ? `<b>${user.nome.substring(0, 2)}. ${user.cognome}</b><br>
              <span class="a_grey opacita8">${moment(row.created_at, 'YYYY-MM-DD HH:mm:ss').format('ddd DD/MM/YY<br>HH:mm')}</span>` : '';
                  },
               };
            }

            if (n == 'note') {
               col = {
                  title: 'Note',
                  orderable: false,
                  className: '',
                  render: function (data, type, row, meta) {
                     return `
              <div class="ui form">
                <div class="field" style="box-sizing: border-box;">
                    <textarea rows="2">${data ? data : ''}</textarea>
                </div>
              </div>`;
                  },
               };
            }

            // if ( n == 'sent' ) {
            //   col = {
            //     title: 'I',
            //     orderable: false,
            //     className: 'collapsing',
            //     render: function ( data, type, row, meta ) {
            //       return data ? '<i class="fitted envelope large green icon"></i>' : '';
            //     }
            //   };
            // }

            if (n == 'sent') {
               col = {
                  title: 'I',
                  orderable: false,
                  className: 'dtvselect-checkbox',
                  render: function (data, type, row, meta) {
                     return data ? '<i class="fitted envelope large green icon"></i>' : '';
                  },
               };
            }

            col.data = n;
            col.name = n;
            cols.push(col);

         });

         return {
            // order: order,
            cols: cols,
         };

      }
   },

   sendMail: function (mailData, oggetto, messaggio, destinatari, conoscenza) {
      if (!Array.isArray(destinatari)) destinatari = [destinatari];
      if (destinatari[0] != myVars.io.email) destinatari.push(myVars.io.email);

      const today = moment().startOf('day');
      const users = myVars.users.tutti;
      const data = {
         from: 'support@datatv.it',
         to: destinatari,
         subject: oggetto ? oggetto : `[tools.datatv.it] Assegnazioni Schede - ${moment().format('DD MMMM YYYY (HH:mm)')}`,
         text: '',
      };

      mailData.map((md) => {
         const m = md.Request_Dinizio ? moment(md.Request_Dinizio, 'YYYY-MM-DD HH:mm:ss') : '';
         md.Request_Dinizio = m ? m.format('DD/MM/YYYY ddd') : '';
         md.daysFromNow = Math.round(moment.duration(m - today).asDays());

         if (md.Request_id) {
            md.link = 'https://content2.datatv.it/scheda.asp?nrr=1' +
               '&idRichiesta=' + md.Request_id +
               '&tipologia=' + md.Request_tipologia +
               '&titolo=' + encodeURIComponent(md.Request_titolo) +
               '&num_stagione=' + md.Request_num_stagione +
               '&episodio=' + md.Request_episodio +
               '&externalID=' + md.Request_externalID +
               '&tr=' + md.Request_TipoRichiesta +
               '&ethanFlag=' + md.Request_ethanFlag +
               '&anno=' + md.Request_anno_prod +
               '&ethanMetadatasets=' + md.Request_ethanMetadatasets +
               '&cliente=' + md.Request_ethanFlag +
               '&adm=';
         }

         if (md.daysFromNow >= 0) {
            if (md.daysFromNow <= 3) md.urgente = 'URGENTISSIMA!!!';
            else if (md.daysFromNow <= 7) md.urgente = 'URGENTE!!!';
            else md.urgente = '';
         }
      });

      const grafici = mailData.filter((md) => md.tipo == 'Grafico');
      const redattori = mailData.filter((md) => md.tipo == 'Redattore');
      const uniqueGrafici = [...new Set(grafici.map((md) => md.assigned_to))];
      const uniqueRedattori = [...new Set(redattori.map((md) => md.assigned_to))];

      console.log('Assegnazioni mailData:', mailData);
      console.log('Assegnazioni grafici:', grafici);
      console.log('Assegnazioni redattori:', redattori);

      data.text = `${messaggio ? messaggio : 'Buongiorno a tutti<br>queste le assegnazioni:'}<br>

    ${redattori.length ? '<h2>Assegnazioni Redattori</h2>' : ''}
      ${uniqueRedattori.map((ur) => {
         const user = users.find((u) => u.id == ur);
         const assegnazioni = redattori.filter((a) => a.assigned_to == ur).sort((a, b) => a.daysFromNow - b.daysFromNow);

         return `
          <table cellspacing="0" cellpadding="5"  style="border-collapse:collapse; border:2px solid #dededf; width:700px; margin:20px 0">
            <tr><td colspan="5" style="background:#f9fafb; border-bottom: 1px solid #dededf; font-weight:bold; color:#999999;">${user.nome} ${user.cognome}</td></tr>
            ${assegnazioni.map((a) => `
              <tr>
                <td style="width: 120px; border-bottom: 1px solid #dededf;"><b>${a.Request_Dinizio ? a.Request_Dinizio : a.created_at}</b><br>${a.Request_canali ? a.Request_canali.split(',')[0] : ''}</td>
                <td style="width: 30px; border-bottom: 1px solid #dededf;">${a.Request_num_stagione ? 'S' + a.Request_num_stagione : ''}</td>
                <td style="width: 30px; border-bottom: 1px solid #dededf;">${a.Request_episodio ? 'E' + a.Request_episodio : ''}</td>
                <td style="border-bottom: 1px solid #dededf;"><a href="${a.link}" target="_blank" style="color:#2284d1; font-weight:bold;">${a.Request_titolo ? a.Request_titolo : a.Satellite_Program_Title}</a><br>
                  ${a.Request_ethanMetadatasets ? a.Request_ethanMetadatasets.split(',').join(', ').toUpperCase() + ' - ' : ''}${a.Request_tipologia ? a.Request_tipologia : ''}</td>
                <td style="width: 200px; border-bottom: 1px solid #dededf;"><div style="color:#dc2828; font-weight:bold;">${a.urgente ? a.urgente : ''}</div>${a.note}</td>
              </tr>
            `).join('')}
          </table>`;
      }).join('')}


      ${grafici.length ? '<h2>Assegnazioni Grafici<br></h2>' : ''}
      ${uniqueGrafici.map((ur) => {
         const user = users.find((u) => u.id == ur);
         const assegnazioni = grafici.filter((a) => a.assigned_to == ur).sort((a, b) => a.daysFromNow - b.daysFromNow);

         return `
          <table cellspacing="0" cellpadding="5"  style="border-collapse:collapse; border:2px solid #dededf; width:700px; margin:20px 0">
            <tr><td colspan="5" style="background:#dff0ff; border-bottom: 1px solid #dededf; font-weight:bold; color:#2185d0;">${user.nome} ${user.cognome}</td></tr>
            ${assegnazioni.map((a) => `
              <tr>
                <td style="width: 120px; border-bottom: 1px solid #dededf;"><b>${a.Request_Dinizio ? a.Request_Dinizio : a.created_at}</b><br>${a.Request_canali ? a.Request_canali.split(',')[0] : ''}</td>
                <td style="width: 30px; border-bottom: 1px solid #dededf;">${a.Request_num_stagione ? 'S' + a.Request_num_stagione : ''}</td>
                <td style="width: 30px; border-bottom: 1px solid #dededf;">${a.Request_episodio ? 'E' + a.Request_episodio : ''}</td>
                <td style="border-bottom: 1px solid #dededf;"><a href="${a.link}" target="_blank" style="color:#2284d1; font-weight:bold;">${a.Request_titolo ? a.Request_titolo : a.Satellite_Program_Title}</a><br>
                  ${a.Request_ethanMetadatasets ? a.Request_ethanMetadatasets.split(',').join(', ').toUpperCase() + ' - ' : ''}${a.Request_tipologia ? a.Request_tipologia : ''}</td>
                <td style="width: 200px; border-bottom: 1px solid #dededf;"><div style="color:#dc2828; font-weight:bold;">${a.urgente ? a.urgente : ''}</div>${a.note}</td>
              </tr>
            `).join('')}
          </table>`;
      }).join('')}

      <br><br>
      Cordiali Saluti<br>
      ${myVars && myVars.io ? `
        <b>${myVars.io.nome} ${myVars.io.cognome}</b><br>${myVars.io.email}` :
            `<b>Datatv</b><br>${data.from}`
         }`;

      // SEND
      // console.log('---------', data, data.text);
      return client.service('email').create(data).then((r) => {
         const idInviati = mailData.map((md) => md.archivioassegnazioni_id);
         return client.service('archivioassegnazioni').patch(null, { sent: 1 }, { query: { id: { $in: idInviati } } });
      });
   },
};
