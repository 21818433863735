module.exports = {

   createMyDataTable: async function (idTable, dataSource, customSettings) {
      console.log(`[createMyDataTable] in Table Id "${idTable}" for Service/Data`);
      const $table = $(idTable);

      ///////////////////////////////////////////////////////////////////////////////////////// Datatable DEFAULT OPTIONS
      let defaultOptions = {
         // deferLoading: 1,
         masterSearchId: idTable + 'Search',
         rowId: 'id',
         pageLength: 50,
         lengthMenu: [25, 50, 75, 100, 250, 500, 750, 1000],
         deferRender: true,
         autoWidth: false,
         rowReorder: false,
         order: [[0, 'desc']],
         select: {
            style: 'os',
            blurable: true,
            selector: '.dtvselect, .dtvselect-checkbox',
         },
         dom: `
            <'dt-control' p>
            <'dt-div stickyHead ${customSettings.stickyCol ? 'stickyCol' : ''}' t>`,
         language: {
            'decimal': '',
            'emptyTable': 'Nessun risultato disponibile',
            'info': 'Risultati da _START_ a _END_ di _TOTAL_',
            'infoEmpty': 'Risultati da 0 a 0 di 0',
            'infoFiltered': '',
            'infoPostFix': '',
            'thousands': ',',
            'lengthMenu': 'Visualizza _MENU_ risultati',
            'loadingRecords': 'Caricamento...',
            'processing': 'Elaborazione...',
            'search': 'Cerca:',
            'zeroRecords': 'Nessun risultato corrispondente alla ricerca',
            'paginate': {
               'first': 'Prima',
               'last': 'Ultima',
               'next': 'Succ.',
               'previous': 'Prec.',
            },
         },
      }

      // Custom Pagination Classes
      $.fn.dataTable.ext.classes.sPageButton = 'ui basic label paginate_button';

      ///////////////////////////////////////////////////////////////////////////////////////// Datatable SOURCE
      let _dtObj = { _colNames: [], columns: [] };

      // data from Array
      $table.data('dataSource', dataSource);
      if (Array.isArray(dataSource)) {
         _dtObj.data = dataSource;
         _dtObj._colNames = customSettings.dtColumnsFilter ? customSettings.dtColumnsFilter : Object.keys(dataSource[0]).filter(k => !/^_nocol_/gi.test(k));

      }
      // data from Feather Service
      else if (typeof dataSource === 'string') {
         _dtObj.serverSide = true;
         _dtObj.ajax = function (data, callback, settings) {
            myDataTable.getDataTableDataFromService(dataSource, data, $table)
               .then((dataTableData) => callback(dataTableData));
         }

         if (customSettings.dtColumnsFilter) _dtObj._colNames = customSettings.dtColumnsFilter;
         else {
            _dtObj._colNames = await client.service(dataSource).get('onlyColumns')
               .then(r => r.map(c => c.COLUMN_NAME));


            // _dtObj._colNames = await client.service(dataSource).find({
            //    query: {
            //       $limit: 1,
            //       id: { $ne: null } // id < 1000 velocizza il reperimento del record per le colonne
            //    }
            // })
            //    .then(r => Object.keys(r.data[0]));
         }
      }

      ///////////////////////////////////////////////////////////////////////////////////////// Datatable COLUMNS
      _dtObj._colNames.forEach((cn, i) => {
         let customCol = customSettings && customSettings.dtColumns ? customSettings.dtColumns[cn] : null;
         let colObj = myDataTable.getColumnDefaultObj(cn, customCol && customCol.customType ? customCol.customType : null);

         // Custom Column
         if (customCol) {
            colObj = Object.assign(colObj, customSettings.dtColumns[cn]);
         }

         _dtObj.columns.push(colObj);
      });

      // Custom Order, Ordine Colonne
      if (customSettings && customSettings.dtColumnsOrder) {
         _dtObj.columns.forEach((col, i) => {
            let co = customSettings.dtColumnsOrder.find(c => c == col.data);
            if (!co) col.visible = false;
         });


         // Porto in fondo le colonne invisibili
         // Ordino le colonne
         _dtObj.columns
            .sort((a, b) => customSettings.dtColumnsOrder.indexOf(b.data) - customSettings.dtColumnsOrder.indexOf(a.data))
            .sort((a, b) => customSettings.dtColumnsOrder.indexOf(a.data) == -1 ? 1 : -1);
      }

      ///////////////////////////////////////////////////////////////////////////////////////// Datatable URL STATE

      // URL State
      let _dtUrlState = myDataTable.urlToState({}, _dtObj.columns.map(c => c.data));

      ///////////////////////////////////////////////////////////////////////////////////////// Datatable FINAL SETTINGS
      const finalSettings = Object.assign(defaultOptions, customSettings, _dtObj, _dtUrlState);
      if (!customSettings.order && !_dtUrlState.order) {
         let idCol = finalSettings.columns.findIndex(c => c.data.toLowerCase() == 'id');
         finalSettings.order = [[idCol, 'desc']];
      }

      ///////////////////////////////////////////////////////////////////////////////////////// Datatable EVENTS
      return new Promise(resolve => {

         $table
            .on('preInit.dt', function (e, settings) {
               // console.log('[0] preInit', settings);
               myFunc.elLoader(true, $table.closest('.dataTables_wrapper'), 'Creating Table...');
               myDataTable.addTableControls($table);
               myDataTable.addColumnsSearch($table);
               myDataTable.addLocalRangeFilters($table);

               if (finalSettings.select) myDataTable.enableAreaSelect($table);
               if (finalSettings.filtersPresets) myDataTable.addPresets($table);

               if (Object.keys(_dtUrlState).length) myDataTable.setInputsFromDt($table);
               myDataTable.printStateLabels($table);
               myDataTable.updatePresets($table);
               myDataTable.updateSelectionLabel($table);

            })
            .on('preDraw.dt', function (e, settings) {
               // console.log('[1] preDraw', settings);

               // Con l' autoScroll della colonna palinsesto di import
               // il preDraw viene chiamato continuamente
               if (!finalSettings.autoScroll) {
                  myDataTable.setDtFromInputs($table);
                  if (finalSettings.serverSide) myFunc.elLoader(true, $table.closest('.dataTables_wrapper'), 'Loading Data...');

               }
               resolve($table);

            })
            .on('draw.dt', function (e, settings) {
               // console.log('[2] draw', settings);

               $('.ui.popup').popup('hide all');
               $('.ui.pagination').removeClass('menu').addClass('labels dt-pagination');
               $table.prevAll('.tableError').remove();
               $table.find('.infoPop').popup({ hoverable: true, lastResort: true });

               myDataTable.printStateLabels($table);
               myDataTable.updatePresets($table);
               myDataTable.updateSelectionLabel($table);
               if (finalSettings.urlState !== false) myDataTable.stateToUrl($table);

               if ($table.closest('.stickyHead').length) {
                  let stickyY = $table.closest('.stickyHead').offset().top + 24;
                  $table.closest('.stickyHead').css('height', `calc(100vh - ${stickyY}px)`);
               }

               myFunc.elLoader(false, $table.closest('.dataTables_wrapper'));
            });

         $table
            .on('select.dt', function (e, dt, type, indexes) {
               myDataTable.updateSelectionLabel($table);
               $('.superSelect').removeClass('superSelect');

            })
            .on('deselect.dt', function (e, dt, type, indexes) {
               myDataTable.updateSelectionLabel($table);
               $('.superSelect').removeClass('superSelect');

            })
            .on('keypress', '[data-dt-datasrc]', function (e) {
               if (e.which == 13) {
                  e.preventDefault();
                  e.stopPropagation();
                  dt.draw();
               }
            })

         ///////////////////////////////////////////////////////////////////////////////////////// Datatable INIT

         // Create Table
         const dt = $table.DataTable(finalSettings);
         const $masterSearch = $(finalSettings.masterSearchId);

         if ($masterSearch.length) {

            const masterSearchTitle = finalSettings.masterSearch ? `Cerca ${finalSettings.masterSearch.join(', ')}...` : `Cerca...`;
            $masterSearch
               .attr('placeholder', masterSearchTitle)
               .on('keyup change', function (e) {
                  e.preventDefault();
                  e.stopPropagation();
                  const toSearch = $(this).val();

                  if (e.type != 'keyup') dt.search(toSearch).draw();
                  else if (e.which == 13) {
                     dt.search(toSearch).draw();
                  }
               });

            if (!$masterSearch.attr('title'))
               $masterSearch.attr('title', masterSearchTitle)

            $table.on('click', '[data-toSearch]', function (e) {
               e.preventDefault();
               e.stopPropagation();
               const toSearch = $(this).attr('data-toSearch');
               $masterSearch.val(toSearch);
               console.log($masterSearch, toSearch);
               dt.search(toSearch).draw();
            });
            $table.on('click', '[data-searchCol]', function (e) {
               e.preventDefault();
               e.stopPropagation();
               const searchArr = $(this).attr('data-searchCol').split('||');

               dt.search('');
               dt.columns().search('');
               dt.column(`${searchArr[0]}:name`).search(searchArr[1]);

               myDataTable.setInputsFromDt($table);
               dt.draw();
            });
         }
      });
   },

   // Add Presets
   addPresets: function ($table) {
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;
      const $menu = $('<div class="ui small pointing menu dt-presets-menu">').prependTo($table.closest('.dataTables_wrapper'));
      let tot = 0;

      dtSettings.filtersPresets.forEach(pre => {
         addPreset(pre, $menu);
      });

      function addPreset(pre, $dest) {
         if (pre.right) {
            let $right = $menu.find('.right.menu');
            if (!$right.length) {
               $menu.append('<div class="right menu"></div>');
               $right = $menu.find('.right.menu')
            }

            $dest = $right;
         }

         pre.class = `presets-menu-item_${tot}`;
         const $item = $(pre.label == 'divider' ? '<div class="divider"></div>' : `
            <${pre.search ? 'a' : 'div'} class="${pre.sub ? 'ui select dropdown ' : ''}vertically fitted item ${pre.class}">
               ${pre.icon ? ` <i class="${pre.icon} icon"></i>` : ''}
               ${pre.label == 'spacer' ? '<div style="padding: 0 10px;"></div>' : pre.label}
               ${pre.sub ? `&nbsp;
                  <span class="text">...</span>
                  <i class="dropdown icon"></i>
                  <div class="menu"></div>
               ` : ``}
            </${pre.search ? 'a' : 'div'}>
         `)

         // if ($dest.children('.item').length)
         //    $item.insertAfter($dest.children('.item').last())
         // else $item.appendTo($dest);

         $item.appendTo($dest);

         pre.tot = tot;
         tot++;

         if (pre.sub) {
            pre.sub.forEach(ps => {
               let $dest = $item.children('.menu');
               addPreset(ps, $dest);
            });

            $item.dropdown();
         }

         if (pre.search || pre.order) {
            $item.on('click', async function (e) {
               if ($item.closest('.dropdown').length)
                  $item.closest('.dropdown').dropdown('hide');

               e.stopPropagation();
               dt.search('');
               dt.columns().search('');

               if (pre.order && pre.order.length) {
                  let preOrder = [];
                  pre.order.forEach(o => {
                     let idCol = dtSettings.columns.findIndex(c => c.data == o[0]);
                     if (idCol > -1) preOrder.push([idCol, o[1]]);
                  });
                  dt.order(preOrder);
               }

               if (pre.masterSearch) {
                  console.log(pre.masterSearch)
                  dt.search(pre.masterSearch);
               }

               if (pre.search) {
                  for (const k of Object.keys(pre.search)) {
                     let val = pre.search[k];

                     if (pre.search[k] == '[LAST]') {
                        let last = '';

                        if (dtSettings.serverSide) {
                           last = await client.service(dtSettings.dataSource).find({ query: { $limit: 1, $sort: { [k]: -1 } } })
                              .then(r => r.data[0][k]);

                        } else {
                           last = _.orderBy(dt.data().toArray(), ['created_at', 'desc'])[0][k];

                        }

                        if (last) {
                           last = moment(last.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm');
                           val = `dal:${last.format('DD/MM/YY HH:mm')} al:${last.format('DD/MM/YY HH:mm')}`;

                        } else {
                           val = '';

                        }


                     } else if (pre.search[k] == '[TODAY]') {
                        let oggi = moment();
                        val = `dal:${oggi.format('DD/MM/YY')} al:${oggi.format('DD/MM/YY')}`;

                     }

                     console.log('SET', k, val);
                     dt.column(`${k}:name`).search(val);
                  }
               }

               myDataTable.setInputsFromDt($table);
               dt.draw();

               // Visibilità colonne
               dt.columns().every(function (index) {
                  const col = this;
                  const dataSrc = this.dataSrc();

                  if (!pre.colvis || !pre.colvis.length || pre.colvis.includes(dataSrc) || dataSrc == 'id') {

                     // // La faccio vedere se era inizialmente visibile
                     // if (dtSettings.dtColumnsOrder.includes(dataSrc)) {
                     //    col.visible(true);
                     //    $table.find(`[data-maindata="${dataSrc}"]`).show();
                     // }

                  } else {
                     col.visible(false);
                     $table.find(`[data-maindata="${dataSrc}"]`).hide();
                  }
               });
            });
         }
      }
   },

   updatePresets: function ($table) {
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;

      if (!dtSettings.filtersPresets) return;
      const $menu = $table.closest('.dataTables_wrapper').find('.dt-presets-menu');
      const flatPresets = [].concat(...dtSettings.filtersPresets.map(p => p.sub ? p.sub.map(s => ({ ...s, parent: p.label })) : p));

      // Reset
      $menu.find('.myActivePresets').removeClass('myActivePresets');
      $menu.find('.dropdown').find('.text').html('...');

      // per ogni preset
      flatPresets.forEach(fp => {
         fp.found = 0;
         fp.cols = [];
         if (!fp.search) return;

         // per ogni colonna
         dt.columns().every(function (index) {
            const dataSrc = this.dataSrc();
            const val = this.search();

            // Se la colonna è nel preset
            if (Object.keys(fp.search).includes(dataSrc)) {
               if (!fp.cols.includes(dataSrc)) fp.cols.push(dataSrc);

               if (val && fp.search[dataSrc] == val) {
                  // console.log(dataSrc, fp.search[dataSrc], val, fp.search[dataSrc] == val)
                  fp.found++;
               }
            }
         });
      });

      // Set Preset
      let best = flatPresets.sort((a, b) => b.found - a.found)[0];
      // console.log('Best Matching Preset', best);
      if (best && best.found >= best.cols.length) {

         const $item = $menu.find(`.item.${best.class}`);
         $item.addClass('myActivePresets');

         if ($item.closest('.dropdown').length) {
            $item.parent().closest('.item').addClass('myActivePresets');
            $item.closest('.dropdown').find('.text').html(best.label);
         }
      }
   },

   // Add Table Controls
   addTableControls: function ($table) {
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;
      const $dtControl = $table.closest('.dataTables_wrapper').find('.dt-control');

      $dtControl.append(`
            <div class="ui labels dt-control-right" style="float: right">

               <div class="ui basic label dt-exportXLSX" title="Scarica File XLSX"><i class="fitted excel file icon"></i></div>
               <div class="ui basic label dt-colVisPop"><i class="fitted columns icon"></i></div>
               <div class="ui popup vispop">
                  <div class="ui small form">
                     ${dtSettings.columns.filter(c => c.visible !== false).map((c, i) => i == 0 ? '' : `
                        <div class="ui field">
                           <div class="ui checkbox">
                              <input type="checkbox" name="${c.data}">
                              <label>${c.title}</label>
                           </div>
                        </div>
                     `).join('')}
                  </div>
               </div>

               <div class="ui basic label">
                  <div class="ui tiny dropdown dt-pagelenghtDrop">
                     <input type="hidden" class="dt-pagelenght" value="">
                     <div class="text">---</div>
                     <i class="dropdown icon"></i>
                     <div class="menu">
                        ${dtSettings.lengthMenu.map(l => `<div class="item" data-value="${l}">${l} Righe</div>`).join('')}
                     </div>
                  </div>
               </div>
            </div>
            <div class="ui labels dt-control-filters"></div>
      `);

      $dtControl.find('.dt-colVisPop').popup({
         inline: true,
         hoverable: true,
         on: 'click',
         onShow: function () {
            const dt = $table.DataTable();
            dt.columns().every(function () {
               let $check = $dtControl.find(`.vispop [name="${this.dataSrc()}"]`);

               if ($check.length) {
                  console.log('show!!!!!!!!!', this.visible())
                  $check[0].checked = this.visible();
               }
            })

         },
         position: 'bottom center',
      });

      $dtControl.find('.ui.checkbox').checkbox('check');
      $dtControl.find('.ui.checkbox').checkbox({
         onChange: function () {
            const dataSrc = $(this).attr('name');
            const checked = $(this).closest('.checkbox').checkbox('is checked');
            const index = dtSettings.columns.findIndex(c => c.data == dataSrc);

            if (checked) {
               dt.columns(index).visible(checked);
               $table.find(`[data-maindata="${dataSrc}"]`).show();
            } else {
               dt.columns(index).visible(checked);
               $table.find(`[data-maindata="${dataSrc}"]`).hide();
            }
         }
      });

      $dtControl.find('.dt-pagelenghtDrop').dropdown('set selected', dtSettings.pageLength);
      $dtControl.find('.dt-pagelenghtDrop').dropdown({
         onChange: function (val) {
            dt.page.len(val).draw();
         }
      });

      $dtControl.find('.dt-exportXLSX').on('click', function (e) {
         e.preventDefault();
         e.stopPropagation();
         myDataTable.exportXLSX($table);
      });
   },

   // Add Filters
   addColumnsSearch: function ($table) {
      const dt = $table.DataTable();
      const $thead = $table.find('thead');
      const $tr = $(`<tr role="row"></tr>`).appendTo($thead);

      // Le otherCols da non stampare nei filtri
      const allOtherCols = [].concat(...dt.settings()[0].aoColumns.filter(c => c.otherCol).map(c => c.otherCol))

      // Ciclo le colonne
      dt.columns().every(function (index) {
         const dataSrc = this.dataSrc();
         const colObj = dt.settings()[0].aoColumns[index];

         // Se la colonna non è visibile
         if (!this.visible()) {
            // Solo se non è nel filtro di un'altra colonna lo stampo nascosto
            if (!allOtherCols.includes(dataSrc))
               $(`<input type="hidden" data-dt-datasrc="${dataSrc}" value="">`).prependTo($thead.find('th').eq(0));
            // Comunque esco
            return;
         }

         const $th = $(this.header());
         const $thFilter = $(`
                <th class="filterTh" data-maindata="${dataSrc}">
                  <div class="fluid ui mini basic label filter"><i class="filter fitted icon"></i>&nbsp;</div>
               </th>
            `).appendTo($tr);

         $th.css('border-bottom', 'none');

         // custom filter pop
         let filterRenderer = colObj.filterRenderer;
         if (!filterRenderer) filterRenderer = myDataTable.defaultColumns['base'].filterRenderer;

         const $activator = $thFilter.find('.label.filter');
         const $filter = filterRenderer($activator, this, dt);

         $activator.popup({
            popup: $filter,
            inline: true,
            exclusive: true,
            position: 'bottom left',
            lastResort: 'bottom right',
            boundary: $table.closest('.dt-div'),
            on: 'click',
            jitter: 1
         });

      });

      $table.on('click', '.filterBtn', function () {
         dt.draw();
      });

   },

   //Enable Select
   enableAreaSelect: function ($table) {
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;
      const $area = $table.closest('.dataTables_wrapper');
      const areaId = '#' + $area.attr('id');

      if (!dtSettings.select) return;
      else if (dtSettings.select.items == 'cell') {
         const selection = new SelectionArea({
            selectionAreaClass: 'selection-area',
            selectionContainerClass: 'selection-area-container',
            container: `${areaId}`,
            selectables: [`${areaId} td:not(:first-child)`],
            startareas: [`${areaId} td`],
            boundaries: ['.dt-div'],
            behaviour: {
               overlap: 'keep',
               intersect: 'touch',
               startThreshold: 100,
               scrolling: {
                  speedDivider: 10,
                  manualSpeed: 750,
                  startScrollMargins: { x: 50, y: 50 }
               }
            },
            features: {
               touch: true,
               range: true,
               singleTap: {
                  allow: false,
                  intersect: 'native'
               }
            }
         });

         selection.on('beforestart', evt => {
            const isSelectable = $(evt.event.target).closest(areaId).length && !$(evt.event.target).closest('textarea').length ? true : false;
            const allowedButtons = [
               1, // left click
               // 2, // right click
               // 4, // mouse wheel / middle button
            ];

            if (isSelectable && allowedButtons.includes(evt.event.buttons)) $area.css('user-select', 'none');
            else return false;

         }).on('start', evt => {
            selection.clearSelection();
            $table.find('td.preSelect').removeClass('preSelect')

         }).on('move', evt => {
            const added = evt.store.changed.added;
            const removed = evt.store.changed.removed;

            // console.log({added}, {removed})
            for (const td of removed) {
               // dt.cell($(td)).deselect();
               $(td).removeClass('preSelect');
            }
            for (const td of added) {
               // dt.cell($(td)).select();
               $(td).addClass('preSelect');
            }

         }).on('stop', evt => {
            // console.log('stop ' + areaId, evt);
            $area.css('user-select', 'auto');

            // Seleziono nella dataTable
            dt.cells($area.find('.preSelect')).select();
            $area.find('.preSelect').removeClass('preSelect');
         });
      } else {
         const selection = new SelectionArea({
            selectionAreaClass: 'selection-area',
            selectionContainerClass: 'selection-area-container',
            container: `${areaId}`,
            selectables: [`${areaId} tr`],
            startareas: [`${areaId} .dtvselect, ${areaId} .dtvselect-checkbox`],
            boundaries: ['.dt-div'],
            behaviour: {
               overlap: 'keep',
               intersect: 'touch',
               startThreshold: 50,
               scrolling: {
                  speedDivider: 10,
                  manualSpeed: 750,
                  startScrollMargins: { x: 50, y: 50 }
               }
            },
            features: {
               touch: true,
               range: true,
               singleTap: {
                  allow: false,
                  intersect: 'native'
               }
            }
         });

         selection.on('beforestart', evt => {
            const isSelectable = $(evt.event.target).closest(`${areaId} .dtvselect-checkbox`).length ? true : false;
            const allowedButtons = [
               1, // left click
               // 2, // right click
               // 4, // mouse wheel / middle button
            ];

            if (isSelectable && allowedButtons.includes(evt.event.buttons)) $area.css('user-select', 'none');
            else return false;

         }).on('start', evt => {
            selection.clearSelection();
            $table.find('td.preSelect').removeClass('preSelect')

         }).on('move', evt => {
            const added = evt.store.changed.added;
            const removed = evt.store.changed.removed;

            // console.log({added}, {removed})
            for (const tr of removed) {
               // dt.cell($(td)).deselect();
               $(tr).removeClass('preSelect');
            }
            for (const tr of added) {
               // dt.cell($(td)).select();
               $(tr).addClass('preSelect');
            }

         }).on('stop', evt => {
            console.log('stop ' + areaId, evt);
            $area.css('user-select', 'auto');

            // Seleziono nella dataTable
            dt.rows($area.find('.preSelect')).select();
            $area.find('.preSelect').removeClass('preSelect');
         });
      };
   },

   addLocalRangeFilters($table) {
      const dt = $table.DataTable();
      const serverSide = dt.settings()[0].oInit.serverSide;
      if (serverSide) return;

      dt.columns().every(function (index) {
         const col = this;
         const dataSrc = col.dataSrc();
         const $inputs = $table.find(`input[data-dt-datasrc="${dataSrc}"]`);
         const colType = dt.settings()[0].aoColumns[index].customType;

         $inputs.each((i, el) => {
            const $input = $(el);

            if ($input.hasClass('localRangeNumber')) {
               $.fn.dataTable.ext.search.push(
                  function (settings, searchData, index, rowData, counter) {
                     let val = $input.val();
                     let tor = true;
                     if (!val) return tor;

                     const row = rowData[dataSrc];
                     const rex = /(?:>=(\d{1,}))?(?:<=(\d{1,}))?/.exec(val);
                     if (!rex || !rex[0]) return tor;

                     if (rex[1]) {
                        if (row < rex[1]) tor = false;
                     }

                     if (rex[2]) {
                        if (row > rex[2]) tor = false;
                     }

                     return tor;
                  }
               );

            } else if ($input.hasClass('localRangeDate')) {
               $.fn.dataTable.ext.search.push(
                  function (settings, searchData, index, rowData, counter) {
                     let val = $input.val();
                     let tor = true;
                     if (!val) return tor;

                     const row = rowData[dataSrc];
                     const rex = /dal:(\d{1,}\/\d{1,}\/\d{1,}) al\:(\d{1,}\/\d{1,}\/\d{1,})/gi.exec(val);
                     if (!rex || !rex[0] || !rex[1] || !rex[2]) return tor;

                     if (!row) {
                        tor = false;

                     } else {
                        const startDate = moment(rex[1], 'DD/MM/YY').format('X');
                        const endDate = moment(rex[2], 'DD/MM/YY').add(1, 'day').format('X');
                        const rowDate = moment(row.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm').format('X');

                        if (rowDate < startDate) {
                           tor = false;
                        }

                        if (rowDate > endDate) {
                           tor = false;
                        }
                     }

                     return tor;
                  }
               );
            }
         });
      });
   },

   setInputsFromDt: function ($table) {
      // console.log('\tsetInputsFromDt');
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;
      const serverSide = dtSettings.serverSide;
      const searchedMain = dt.search();

      // Reset
      $table.find(`input[data-dt-datasrc]`).each((i, el) => {
         const $input = $(el);
         const $dropdown = $input.closest('.ui.dropdown');
         const $maggiore = $input.closest('.ui.form').find('.maggiore');
         const $minore = $input.closest('.ui.form').find('.minore');

         if ($dropdown.length) $dropdown.dropdown('clear');
         $input.val('');
         $maggiore.val('');
         $minore.val('');
      });

      if (searchedMain) {
         $(dtSettings.masterSearchId).val(searchedMain);
      }

      dt.columns().every(function (index) {
         const col = this;
         const dataSrc = col.dataSrc();
         const $input = $table.find(`input[data-dt-datasrc="${dataSrc}"]`).eq(0);
         const colType = dt.settings()[0].aoColumns[index].customType;
         let searched = col.search();

         if ((searched || searched === 0) && $input.length) {
            const isRange = $input.hasClass('localRangeNumber') || $input.hasClass('localRangeDate')
            const $dropdown = $input.closest('.ui.dropdown');

            if (serverSide) {

               if (isRange) { //>=3<=10
                  const $maggiore = $input.closest('.ui.form').find('.maggiore');
                  const $minore = $input.closest('.ui.form').find('.minore');
                  const rex = /(?:>=(\d{1,}))?(?:<=(\d{1,}))?/.exec(searched);
                  // const rexDate = /dal:(\d{1,}\/\d{1,}\/\d{1,}) al\:(\d{1,}\/\d{1,}\/\d{1,})/gi.exec(searched);

                  if (rex && rex[1]) $maggiore.val(rex[1]);
                  if (rex && rex[2]) $minore.val(rex[2]);
                  $input.val(searched);

               } else if ($dropdown.length) {
                  const valori = $dropdown.find('[data-value]').toArray().map(e => $(e).attr('data-value'))
                     .sort((a, b) => b.length - a.length);

                  const dVal = valori.find(v => v == searched);
                  // console.log('find valori', valori, dVal);

                  if (dVal) {
                     searched = dVal;

                  } else if (/\[OR\]/gi.test(searched)) {
                     const orv = searched.split(/\[OR\]/gi).map(v => v.trim());
                     searched = orv;
                  }

                  $dropdown.dropdown('set exactly', searched);

               } else {
                  $input.val(searched);
               }

            } else {

               if (isRange) {

               } else if ($dropdown.length) {
                  $dropdown.dropdown('set exactly', searched.replace(/\\b/gi, ''));
               } else {
                  $input.val(searched.replace(/\\b/gi, ''));
               }
            }
         }
      });
   },

   setDtFromInputs: function ($table) {
      // console.log('\tsetDtFromInputs');
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;
      const serverSide = dtSettings.serverSide;

      dt.search('');
      if ($(dtSettings.masterSearchId).length) {
         dt.search($(dtSettings.masterSearchId).val());
      }

      dt.columns().every(function (index) {
         const col = this;
         const dataSrc = col.dataSrc();
         const $inputs = $table.find(`input[data-dt-datasrc="${dataSrc}"]`);
         const colType = dt.settings()[0].aoColumns[index].customType;

         // Reset
         col.search('');

         // Set
         $inputs.each((i, el) => {
            const $input = $(el);
            const $multiple = $(el).closest('.multiple.dropdown');
            const isRange = $input.hasClass('localRangeNumber') || $input.hasClass('localRangeDate');
            let val = $input.val();

            if (val || val === 0) {
               if (serverSide) {
                  if ($multiple.length) val = val.split(',').join(' [OR] ');

                  col.search(val, false, false);

               } else {

                  if (isRange) {

                  } else {
                     let rexVal = null;

                     if (['^$', '.*'].includes(val)) {
                        rexVal = val;

                     } else {
                        val = $multiple.length ? val.split(',') : [val];
                        rexVal = val.map(v => `\\b${v}\\b`.replace(/(\\b\*|\*)/gi, '.*')).join('|');
                     }

                     // console.log('new rex', rexVal);
                     col.search(rexVal, true, false);
                  }
               }
            }
         });
      });
   },

   printStateLabels: function ($table) {
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;
      const $filtersArea = $table.closest('.dataTables_wrapper').find('.dt-control-filters');

      // Disattivo gli headers
      const $thLabelFilters = $table.find('th .label.filter');
      $thLabelFilters.removeClass('active');

      // Attivo gli headers filtrati
      $thLabelFilters.each((i, el) => {
         let $filterPop = $(el).next('.filterPop');
         if ($filterPop.length) {
            // Gli input con valori
            let $input = $filterPop.find('input[data-dt-datasrc]').filter((i, e) => $(e).val());

            if ($input.length) {
               $(el).addClass('active');
            }
         }
      });

      if (!$filtersArea.length) return;
      $filtersArea.empty();

      // Stampo i totali
      const selected = dtSettings.select.items == 'cell' ? dt.cells({ selected: true }).count() : dt.rows({ selected: true }).count();
      const pageInfo = dt.page.info();
      $(`<a class="ui label selectedLabel" title="${dtSettings.select.items == 'cells' ? 'Celle' : 'Righe'} Selezionate">${selected}</span>
         <a class="ui red label">
            <span title="Righe Visibili">${pageInfo.recordsDisplay}</span> /
            <span title="Righe Totali">${pageInfo.recordsTotal}</span>
         </a>`).prependTo($filtersArea);

      // Stampo ordinamento
      dt.order().forEach((o, i) => {
         const col = dt.column(o[0]);
         const dataSrc = col.dataSrc();
         let txt = $(col.header()).text();

         let $inputLabel = $table.find(`[data-dt-datasrc="${dataSrc}"]`).closest('.field').find('label');
         if ($inputLabel.length) txt = $inputLabel.text();

         $filtersArea.append(`<a class="ui label"><i class="sort content ${o[1] == 'asc' ? 'descending' : 'ascending'} icon"></i>
               ${txt ? txt : dataSrc.replace(/\_/gi, ' ')}
            </a>`);
      });

      // Print Search
      if (dt.search) {
         const $masterSearch = $(dtSettings.masterSearchId);
         const txt = $masterSearch.length ? $masterSearch.val() : dt.search();

         if (txt) {
            let $masterLabel = $(`<a class="ui green label aFilterLabel"><i class="search icon"></i>Ricerca:<div class="detail">${txt}</div></a>`)
               .appendTo($filtersArea);

            $masterLabel.on('click', function () {
               $masterSearch.val('');
               dt.search('');
               dt.draw();
            });
         }
      }

      // Stampo Filtri
      dt.columns().every(function (index) {
         const col = this;
         const dataSrc = col.dataSrc();
         const colType = dt.settings()[0].aoColumns[index].customType;

         const $input = $table.find(`input[data-dt-datasrc="${dataSrc}"]`);
         const $inputLabel = $table.find(`[data-dt-datasrc="${dataSrc}"]`).closest('.field').find('label');
         const $dropdown = $input.closest('.ui.dropdown');

         // if (!$input.length && col.search()) {
         //    const $filterLabel = $(`<a class="ui basic label opacita4 aFilterLabel"><i class="filter icon"></i>
         //          ${dataSrc.replace(/\_/gi, ' ')}
         //          <div class="detail">${col.search()}</div>
         //       </a>`).appendTo($filtersArea);

         //    $filterLabel.on('click', function () {
         //       $input.val('');
         //       col.search('');
         //       dt.draw();
         //    });
         // }

         let $filterLabel = null;
         let searchedTxt = $input.val();

         if (searchedTxt) {

            // if (/\[OR\]/gi.test(searchedTxt))
            //   searchedTxt = searchedTxt.split('[OR]').join(',');

            // Se è un drop prendo il testo dalle label
            let dropOpt = false;
            if ($dropdown.length) {
               dropOpt = searchedTxt.split(',').map(v =>
                  $dropdown.find(`[data-value="${v}"]`).eq(0).text()
               ).join(', ');
            }

            if (dropOpt)
               searchedTxt = dropOpt;
            else if (/\[OR\]/gi.test(searchedTxt))
               searchedTxt = searchedTxt.split('[OR]').join(',');

            // Stampo i Filtri
            let txt = $inputLabel.length ? $inputLabel.text()
               : $(col.header()).length ? $(col.header()).text()
                  : dataSrc.replace(/\_/gi, ' ').trim();

            $filterLabel = $(`<a class="ui basic label aFilterLabel"><i class="filter icon"></i>
               ${txt}
               <div class="detail">${searchedTxt}</div>
            </a>`).appendTo($filtersArea);

            // Rimuovi on Click
            if ($filterLabel) {
               $filterLabel.on('click', function () {
                  const $dropdown = $input.closest('.ui.dropdown');
                  if ($dropdown.length) $dropdown.dropdown('clear');
                  $input.val('');
                  col.search('');
                  dt.draw();
               });
            }

         }

      });

      if ($filtersArea.find('.aFilterLabel').length) {
         $clearSearch = $(`<a class="ui red circular label removeAllFilters"><i class="fitted alternate trash icon"></i></a>`).prependTo($filtersArea);
         $clearSearch.on('click', function () {

            dt.search('');
            $(dtSettings.masterSearchId).val('');

            dt.columns().search('');
            $table.find(`input[data-dt-datasrc]`).each((i, el) => {
               const $dropdown = $(el).closest('.ui.dropdown');
               if ($dropdown.length) $dropdown.dropdown('clear');
               $(el).val('')
            });

            // Ordino per id desc
            let idCol = dtSettings.columns.findIndex(c => c.data.toLowerCase() == 'id');
            dt.order([idCol, 'desc']);

            dt.draw();
         });
      }
   },

   updateSelectionLabel: function ($table) {
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;
      const $selLabel = $table.closest('.dataTables_wrapper').find('.selectedLabel');
      const selected = dtSettings.select.items == 'cell' ? dt.cells({ selected: true }).count() : dt.rows({ selected: true }).count();

      if (selected) {
         $selLabel.show().addClass('blue').text(selected);
      } else {
         $selLabel.hide().removeClass('blue').text(selected);
      }

      // $selLabel.removeClass('blue').addClass(selected ? 'blue' : '').text(selected);
   },

   stateToUrl: function ($table) {
      const dt = $table.DataTable();
      const customState = {
         _pl: dt.page.info().length,
         _pg: dt.page.info().page,
      };

      // Main Search
      if (dt.search()) customState._sr = dt.search();

      // Search
      dt.columns().every(function (i) {
         if (this.search()) {
            if (!customState._cl) customState._cl = [];
            customState._cl.push([this.dataSrc(), this.search()]);
         }
      });

      // Order
      dt.order().forEach(o => {
         if (!customState._or) customState._or = [];
         customState._or.push([dt.column(o[0]).dataSrc(), o[1]]);
      });

      // console.log('[stateToUrl] customState', customState)


      // const state = dt.state();
      // // Solo le colonne con il search
      // state.columns = state.columns.map((c, i) => {
      //    let tor = null;
      //    if (c && c.search.search) {
      //       tor = {};
      //       tor.search = c.search.search;
      //       if (c.search.regex) tor.regex = c.search.regex;
      //       if (c.search.smart) tor.smart = c.search.smart;
      //    }

      //    return tor;
      // });

      // state.order = state.order.map(o => {
      //    return [o[0], o[1]];
      // });

      // // Accorcio la stringa
      // delete state.childRows;
      // delete state.select;

      // console.log('\tstateToUrl', state);
      const searchParams = new URLSearchParams(window.location.search);
      const encodedState = encodeURIComponent(JSON.stringify(customState));
      searchParams.set('dtstate', encodedState);
      history.pushState(false, false, `${window.location.pathname}?${searchParams.toString()}`);

   },

   urlToState: function (dtSettings, colNames) {
      const queryString = myFunc.getQueryString();
      let state = {};

      if (queryString.dtstate) {
         state = JSON.parse(decodeURIComponent(queryString.dtstate));
      }

      if (state) {
         if (state._sr)
            dtSettings.search = { search: state._sr };

         if (state._cl && state._cl.length) {

            const searchCols = [];
            colNames.forEach(cn => {
               const co = { sSearch: '' };
               searchCols.push(co);
            })

            state._cl.forEach(col => {
               let colIndex = colNames.findIndex(n => n == col[0]);
               searchCols[colIndex].sSearch = col[1];
            });

            dtSettings.searchCols = searchCols;
         }

         if (state._or && state._or.length) {
            let order = [];
            state._or.forEach(ord => {
               let colIndex = colNames.findIndex(n => n == ord[0]);
               order.push([colIndex, ord[1]]);
            });
            dtSettings.order = order;
         }

         if (state._pg && state._pl) {
            dtSettings.displayStart = state._pg * state._pl;
            dtSettings.pageLength = state._pl;

         }
      }



      // // Stato tabella
      // if (state) {




      //    if (state.search && state.search.search) {
      //       dtSettings.search = state.search;
      //    }

      //    if (state.columns) {
      //       dtSettings.searchCols = state.columns;

      //    }

      //    if (state.order) {
      //       dtSettings.order = state.order;
      //    }
      // }

      // Custom Search
      Object.keys(queryString).forEach(key => {
         if (key == 's_') {
            dtSettings.search = { search: queryString[key] };

         } else if (/^c_/gi.test(key)) {
            let qcol = key.replace(/^c_/gi, '');
            let index = colNames.indexOf(qcol);

            if (index > -1) {
               if (!dtSettings.searchCols) dtSettings.searchCols = [];
               dtSettings.searchCols[index] = { search: queryString[key] };
            }
         }
      });

      // https://tools.datatv.it/importPalinsestiStorico?c_sessionId=51468
      // https://tools.datatv.it/importPalinsestiStorico?c_idProgram=204956
      // console.log('urlToState', JSON.parse(JSON.stringify(state)));#
      // console.log(dtSettings)
      return dtSettings;

   },

   exportXLSX: async function ($table) {
      const pageName = location.href.split("/").slice(-1)[0].split('?')[0];
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0].oInit;
      const downloadTable = dtSettings.downloadTable ? dtSettings.downloadTable : pageName;

      // Filename di default
      let filename = `Export ${pageName}`;
      let wBook = null;

      // se è una funzione la eseguo
      if (typeof downloadTable == 'function') {
         let fdata = await downloadTable();
         wBook = fdata[0];
         if (fdata[1]) filename = fdata[1];
      }

      // Se ho uno oggetto lo scarico
      else if (typeof downloadTable == 'object') {
         wBook = downloadTable[0];
         if (downloadTable[1]) filename = downloadTable[1];
      }

      // Se ho dataSource e lastQuery scarico tutto il risultato della query senza paginazione
      else if (dtSettings.dataSource && dtSettings.lastQuery) {
         const dtInfo = dt.page.info();
         let query = JSON.parse(JSON.stringify(dtSettings.lastQuery));
         query.$limit = 5000;

         if (dtInfo.recordsDisplay > 5000) {
            await myFunc.affermativeModal('Download Parziale', `È possibile scaricare massimo 5000 Righe per volta. Il filtro corrente è composto da ${dtInfo.recordsDisplay} Righe. Verranno scaricate solamente le prime 5000 righe.`)
         }

         // Load Data
         myFunc.elLoader(true, false, `Loading XLSX data...`);
         let timeout = client.service(dtSettings.dataSource).timeout;
         client.service(dtSettings.dataSource).timeout = 180000;  // Massimo 3 minuti
         let data = await client.service(dtSettings.dataSource).find({ query: query }).then(r => r.data);
         client.service(dtSettings.dataSource).timeout = timeout;
         myFunc.elLoader(false);

         // Create wBook
         wBook = XLSX.utils.book_new();
         const ws = XLSX.utils.json_to_sheet(data.map(p => {
            let obj = {};

            Object.keys(p).forEach(k => {
               let val = p[k];
               let col = k;

               if (typeof val == 'object') return;

               obj[col] = val;
            });

            return obj;
         }));

         XLSX.utils.book_append_sheet(wBook, ws, downloadTable);
      }

      // Altrimenti lo creo
      else {
         let data = dt.rows().data().toArray();
         let filename = `${downloadTable} Export ${data.length} elementi`;

         // Create wBook
         wBook = XLSX.utils.book_new();
         const ws = XLSX.utils.json_to_sheet(data.map(p => {
            let obj = {};

            Object.keys(p).forEach(k => {
               let val = p[k];
               let col = k;

               if (typeof val == 'object') return;

               obj[col] = val;
            });

            return obj;
         }));

         XLSX.utils.book_append_sheet(wBook, ws, downloadTable);
      }

      myFunc.downloadXLSX(wBook, filename);
   },

   getDataTableDataFromService: async function (serviceName, data, $table) {
      // console.log(`Get Data for ${serviceName}`, data);
      const dt = $table.DataTable();
      const dtSettings = dt.settings()[0];
      const customSettings = dt.settings()[0].oInit;

      let draw = dtSettings.iDraw;
      let query = { $limit: dt.page.len(), $and: [] };

      // Search Colonne
      dt.columns().every(function (index) {
         const dataSrc = this.dataSrc();
         const val = this.search();

         let qPart = myDataTable.getQueryPart(dataSrc, val);
         if (qPart) query.$and.push(qPart);
      });

      // Master Search
      let sVal = dt.search();
      if (sVal) {
         let colArr = dt.settings()[0].oInit.masterSearch ? dt.settings()[0].oInit.masterSearch
            : dt.settings()[0].aoColumns.map(c => c.data);

         if (!/\*/gi.test(sVal)) sVal = `*${sVal}*`;

         if (colArr.length) {
            let $or = [];
            colArr.forEach(c => {
               let qPart = myDataTable.getQueryPart(c, sVal);
               if (qPart) $or.push(qPart);
            });

            query.$and.push({ $or: $or });
         }
      }

      // Ordinamento
      let dtOrder = dt.order();
      if (dtOrder && dtOrder.length) {
         query.$sort = {};
         dtOrder.forEach((or) => {
            let dataSrc = dt.column(or[0]).dataSrc();
            if (dataSrc == 'Request_episodio') dataSrc = 'Request_episodio_N';
            if (dataSrc == 'Request_num_stagione') dataSrc = 'Request_num_stagione_N';

            query.$sort[dataSrc] = or[1] == 'asc' ? 1 : -1;
         })
      } else {
         query.$sort = { id: -1 };
      }

      // PreQuery (controllare se sovrascritti i campi funziona)
      if (customSettings.preQuery) {
         Object.keys(customSettings.preQuery).forEach(k => {
            let qPart = myDataTable.getQueryPart(k, customSettings.preQuery[k]);
            if (qPart) query.$and.push(qPart);
         });
      }


      // Pagination
      if (data.start) query.$skip = data.start;

      // Salvo lastquery
      customSettings.lastQuery = query;

      const t0 = performance.now();
      console.log(`[Query] ${serviceName}`, query);

      const promises = [];
      promises.push(
         client.service(serviceName).find({ query: query })
      );

      if (customSettings.preQuery) {
         const totQuery = { $limit: 0, $and: [] };
         Object.keys(customSettings.preQuery).forEach(k => {
            let qPart = myDataTable.getQueryPart(k, customSettings.preQuery[k]);
            if (qPart) totQuery.$and.push(qPart);
         });

         promises.push(
            client.service(serviceName).find({ query: totQuery })
         );
      } else{
         promises.push(client.service(serviceName).find({query: {$limit:0}}))
      }

      const [serviceData, recordsTotal] = await Promise.all(promises);

      const t1 = performance.now();
      const queryTime = parseInt((t1 - t0)) / 1000;
      console.log('[Query]', `${serviceName}`, 'Result', serviceData, `${queryTime} Secondi`);

      const tableData = {
         draw: draw++,
         recordsFiltered: serviceData.total,
         recordsTotal: recordsTotal.total,
         data: serviceData.data
      }

      $table.closest('#timoneGeneraleTable_wrapper').find('.queryPerf').remove();
      $table.closest('#timoneGeneraleTable_wrapper')
         .append(`<div class="queryPerf">${queryTime} Secondi</div>`);

      return tableData;

      // // Pagination Hack
      // if (data.start) {
      //    // $skip funziona da SQL Server 2012 in poi per ora la disabilito
      //    // query.$skip = data.start;
      //    // Non mostra i risultati se sono oltre il limite del servizio
      //    query.$limit = query.$limit + data.start;
      // }

      // // Abilita Hook che Modifica il servizio per la dataTable
      // query.$dataTable = 1;
      // customSettings.lastQuery = query;

      // const t0 = performance.now();
      // console.log(`[Query] ${serviceName}`, query);

      // const promises = [];
      // promises.push(
      //    client.service(serviceName).find({ query: query })
      // );

      // if (customSettings.preQuery) {
      //    const totQuery = { $limit: 0, $and: [] };
      //    Object.keys(customSettings.preQuery).forEach(k => {
      //       let qPart = myDataTable.getQueryPart(k, customSettings.preQuery[k]);
      //       if (qPart) totQuery.$and.push(qPart);
      //    });

      //    promises.push(
      //       client.service(serviceName).find({ query: totQuery })
      //    );
      // }

      // const res = await Promise.all(promises);
      // const serviceData = res[0];
      // const recordsTotal = res[1] ? res[1].total : serviceData.totalTable;

      // // Pagination Hack
      // if (data.start) {
      //    serviceData.data = serviceData.data.slice(data.start);
      // }

      // const t1 = performance.now();
      // const queryTime = parseInt((t1 - t0)) / 1000;
      // console.log('[Query]', `${serviceName}`, 'Result', serviceData, `${queryTime} Secondi`);

      // const tableData = {
      //    draw: draw++,
      //    recordsFiltered: serviceData.total,
      //    recordsTotal: recordsTotal,
      //    data: serviceData.data
      // }

      // $table.closest('#timoneGeneraleTable_wrapper').find('.queryPerf').remove();
      // $table.closest('#timoneGeneraleTable_wrapper')
      //    .append(`<div class="queryPerf">${queryTime} Secondi</div>`);

      // return tableData;
   },

   getQueryPart: function (dataSrc, val) {
      if (!val) return false;
      // console.log(dataSrc, val);

      // decode val
      val = decodeEntity(val);

      let isVuoto = /\[VUOTO\]/gi.test(val);
      let isPieno = /\[PIENO\]/gi.test(val);
      let isOr = /\[OR\]/gi.test(val);
      let isNot = /\[NOT\]/gi.test(val);
      let isRange = /(?:>=\-?(\d{1,}))|(?:<=\-?(\d{1,}))/.test(val);
      let isDateRange = /dal:(\d{1,}\/\d{1,}\/\d{1,}) al\:(\d{1,}\/\d{1,}\/\d{1,})/gi.test(val);
      let isDateHourRange = /dal:(\d{1,}\/\d{1,}\/\d{1,} \d{2}\:\d{2}) al\:(\d{1,}\/\d{1,}\/\d{1,} \d{2}\:\d{2})/gi.test(val);
      let isOggi = /\[OGGI\]/gi.test(val);
      let isDomani = /\[DOMANI\]/gi.test(val);
      let isIeri = /\[IERI\]/gi.test(val);

      let qPart = null;

      if (isRange) {
         // console.log('isRange', val);
         const rex = /(?:>=(\-?\d{1,}))?(?:<=(\-?\d{1,}))?/.exec(val);

         if (rex[1] && rex[2]) {
            const $and = [];
            $and.push({ [dataSrc]: { $gte: rex[1] } });
            $and.push({ [dataSrc]: { $lte: rex[2] } });
            qPart = { $and: $and };

         } else if (rex[1]) {
            qPart = { [dataSrc]: { $gte: rex[1] } };

         } else if (rex[2]) {
            qPart = { [dataSrc]: { $lte: rex[2] } };

         }

      } else if (isOggi) {
         let oggi = moment().format('YYYY/MM/DD');
         let domani = moment().add(1, 'day').format('YYYY/MM/DD');

         const $and = [];
         $and.push({ [dataSrc]: { $gte: oggi } });
         $and.push({ [dataSrc]: { $lt: domani } });
         qPart = { $and: $and };

      } else if (isDomani) {
         let domani = moment().add(1, 'day').format('YYYY/MM/DD');
         let dopoDomani = moment().add(2, 'day').format('YYYY/MM/DD');

         const $and = [];
         $and.push({ [dataSrc]: { $gte: domani } });
         $and.push({ [dataSrc]: { $lt: dopoDomani } });
         qPart = { $and: $and };

      } else if (isIeri) {
         let ieri = moment().subtract(1, 'day').format('YYYY/MM/DD');
         let oggi = moment().format('YYYY/MM/DD');

         const $and = [];
         $and.push({ [dataSrc]: { $gte: ieri } });
         $and.push({ [dataSrc]: { $lt: oggi } });
         qPart = { $and: $and };

      } else if (isDateRange) {
         // console.log('isDateRange', val);
         const dateRange = /dal:(\d{1,}\/\d{1,}\/\d{1,}) al\:(\d{1,}\/\d{1,}\/\d{1,})/gi.exec(val);

         if (dateRange && dateRange[1] && dateRange[2]) {
            const $and = [];
            $and.push({ [dataSrc]: { $gte: moment(dateRange[1], 'DD/MM/YY').format('YYYY/MM/DD') } });
            $and.push({ [dataSrc]: { $lt: moment(dateRange[2], 'DD/MM/YY').add('1', 'days').format('YYYY/MM/DD') } });
            qPart = { $and: $and };
         }

      } else if (isDateHourRange) {
         // console.log('isDateRange', val);
         const dateRange = /dal:(\d{1,}\/\d{1,}\/\d{1,} \d{2}\:\d{2}) al\:(\d{1,}\/\d{1,}\/\d{1,} \d{2}\:\d{2})/gi.exec(val);

         if (dateRange && dateRange[1] && dateRange[2]) {
            const $and = [];
            $and.push({ [dataSrc]: { $gte: moment(dateRange[1], 'DD/MM/YY HH:mm').format('YYYY/MM/DD HH:mm') } });
            $and.push({ [dataSrc]: { $lt: moment(dateRange[2], 'DD/MM/YY HH:mm').add('1', 'days').format('YYYY/MM/DD HH:mm') } });
            qPart = { $and: $and };
         }

      } else if (isOr) {
         // console.log('isOr', val);
         const orv = val.split(/\[OR\]/gi).map(v => v.trim());

         const $or = [];
         orv.forEach(v => {
            if (/\*/.test(val))
               $or.push({ [dataSrc]: { $like: v.replace(/\*/gi, '%').trim() } });
            else
               $or.push({ [dataSrc]: v.trim() });
         });
         qPart = { $or: $or };

      } else if (isVuoto) {
         // console.log('isVuoto', val);

         const $or = [];
         // query.$or.push({ [dataSrc]: false });
         $or.push({ [dataSrc]: null });
         $or.push({ [dataSrc]: '' });
         qPart = { $or: $or };

      } else if (isPieno) {
         // console.log('isPieno', val);

         const $and = [];
         // qPart = { [dataSrc]: true };
         $and.push({ [dataSrc]: { $ne: null } });
         $and.push({ [dataSrc]: { $ne: '' } });
         qPart = { $and: $and };

      } else if (isNot) {
         // console.log('isNot', val);
         val = val.replace(/\[NOT\]/gi, '');
         if (/\*/.test(val)) qPart = { [dataSrc]: { $notlike: val.replace(/\*/gi, '%').trim() } };
         else qPart = { [dataSrc]: { $ne: val } };

      } else {
         // console.log('is', val);
         if (/\*/.test(val)) qPart = { [dataSrc]: { $like: val.replace(/\*/gi, '%').trim() } };
         else qPart = { [dataSrc]: val };
      }

      // console.log(qPart)
      return qPart;

      function decodeEntity(inputStr) {
         var textarea = document.createElement("textarea");
         textarea.innerHTML = inputStr;
         return textarea.value;
      }
   },

   // Default Columns
   getColumnDefaultObj: function (colName, customType) {
      // console.log(colName, customType)
      let defCols = myDataTable.defaultColumns;
      let tor = { data: colName, name: colName };

      // Se ho passato il tipo
      if (customType) {
         if (defCols[customType]) return Object.assign(tor, defCols[customType]);
         else return Object.assign(tor, defCols.base);
      }

      // Cerco il tipo
      let foundType = null;

      // id principale
      if (colName == 'id') {
         foundType = 'mainId';

      }

      // Nome colonna con underscore
      if (!foundType) {
         let rex = /_([^_]+)$/g.exec(colName);
         if (rex && rex[1]) {

            if (/^(Request|Response|Satellite)\_/gi.test(colName)) {
               tor.title = myFunc.camelize(colName.replace(/(Request|Response|Satellite)\_/gi, '').replace(/_/g, ' '));

            } else {
               tor.title = myFunc.camelize(colName.replace(new RegExp('_' + rex[1]), '').replace(/_/g, ' '));

            }

            if (['at', 'first', 'last', 'date'].includes(rex[1])) {
               foundType = 'date';

            } else if (['by', 'to', 'user'].includes(rex[1])) {
               foundType = 'user';

            } else if (['sheetjs'].includes(rex[1])) {
               foundType = 'sheetjs';

            } else {
               foundType = rex[1];

            }
         }
      }

      // Nome colonna
      if (!foundType && defCols[colName]) {
         foundType = colName;
      }

      // base type
      if (!foundType || !defCols[foundType]) foundType = 'base';

      // Final Object
      tor = Object.assign(tor, defCols[foundType]);

      if (!tor.title) tor.title = colName;
      return tor;
   },


   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   // COLONNE PREIMPOSTATE
   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

   defaultColumns: {
      mainId: {
         className: 'center aligned collapsing dtvselect-checkbox',
         render: function (data, type, row, meta) {
            if (!data) return '';
            // let $table = $(meta.settings.nTable);
            // let dt = $table.DataTable();
            // if (dt.rows({ selected: true })[0].includes(meta.row)) {} else {}

            let html = `<div class="smallText opacita6">${data}</div>`;
            return html;
         },
         customType: 'mainId',
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();

            let $filter = $(`
               <div class="ui flowing popup filterPop">
                  <form class="ui small form">
                     <div class="field">
                        <label>${$th.text()}</label>
                        <div class="ui input">
                           <input type="text" data-dt-datasrc="${dataSrc}" placeholder="${$th.text()}" style="width:200px">
                        </div>
                     </div>
                     <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                  </form>
               </div>
            `).insertAfter($activator);

            return $filter;
         }
      },
      base: {
         customType: 'base',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) {
               return '';

            } else if (typeof data === "string" || typeof data === "number") {
               return data;

            } else if (typeof data === "object") {
               return JSON.stringify(data);

            } else if (data === true) {
               return '1';

            } else if (data === false) {
               return '0';
            }
         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui input">
                                 <input type="text" data-dt-datasrc="${dataSrc}" placeholder="${$th.text()}" style="width:200px">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            return $filter;
         }
      },
      boolean: {
         customType: 'boolean',
         className: 'center aligned collapsing',
         render: function (data, type, row, meta) {
            let html = '';
            if (!data) html = `<span style="display:none">0</span><i class="fitted large red minus circle icon"></i>`;
            else html = `<span style="display:none">1</span><i class="fitted large green check circle icon"></i>`;

            return html;
         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 <div class="item" data-value="0"><i class="minus circle icon"></i>No</div>
                                 <div class="item" data-value="1"><i class="check circle icon"></i>Si</div>
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            $filter.find('.dropdown').dropdown({ fullTextSearch: true });

            return $filter;
         }
      },
      string: {
         customType: 'string',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            return data ? data : '';
         }
      },
      number: {
         customType: 'number',
         className: 'right aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';

            let html = `${data}`;
            return html;
         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();

            let $filter = $(`
               <div class="ui flowing popup filterPop">
                  <form class="ui small form">
                     <input class="localRangeNumber" type="text" data-dt-datasrc="${dataSrc}" style="display: none;">
                     <div class="ui fields">
                        <div class="field">
                           <label>${$th.text()} magg. di:</label>
                           <div class="ui left icon input">
                              <i class="greater than equal icon"></i>
                              <input class="maggiore" type="number" placeholder="${$th.text()}" style="width:120px;">
                           </div>
                        </div>
                        <div class="field">
                           <label>${$th.text()} min. di:</label>
                           <div class="ui left icon input">
                              <i class="less than equal icon"></i>
                              <input class="minore" type="number" placeholder="${$th.text()}" style="width:120px;">
                           </div>
                        </div>
                     </div>
                     <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                  </form>
               </div>
               `).insertAfter($activator);


            $filter.on('change', '.maggiore, .minore', function () {
               let $dtInput = $filter.find('input[data-dt-datasrc="' + dataSrc + '"]');
               let $maggiore = $filter.find('.maggiore');
               let $minore = $filter.find('.minore');

               // if (!$maggiore.val()) {
               //    $maggiore.val($minore.val());
               // }

               // if (!$minore.val()) {
               //    $minore.val($maggiore.val());
               // }


               $dtInput.val(($maggiore.val() ? '>=' + $maggiore.val() : '') + ($minore.val() ? '<=' + $minore.val() : ''));

            });

            return $filter;
         }
      },
      array: {
         customType: 'array',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';

            let html = '';
            html = data.join(', ');
            return html;
         }
      },
      ///////////////////////////////////////////////////////////////////////////// DATE / HOUR
      datePlain: {
         customType: 'datePlain',
         // title: 'Data',
         className: 'left aligned collapsing tdCompactText',
         render: function (data, type, row, meta) {
            if (!data) return '';

            let html = '';
            const mome = moment(data.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm');
            html += `<span style="display: none">${mome.format('X')}</span>
                     ${mome.format('DD/MM/YY')}`;

            return html;
         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();
            const chs = myVars.channels.tutti;

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui input">
                                 <input type="text" data-dt-datasrc="${dataSrc}" placeholder="dal:--/--/-- al:--/--/--">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            myFunc.makeCalendar($filter.find(`[data-dt-datasrc="${dataSrc}"]`), 'range');
            return $filter;
         }
      },
      date: {
         customType: 'date',
         // title: 'Data',
         className: 'left aligned collapsing tdCompactText',
         render: function (data, type, row, meta) {
            if (!data) return '';

            let html = '';
            const mome = moment(data.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm');
            html += `<span style="display: none">${mome.format('X')}</span>
                     ${mome.format('DD/MM/YY')}</br><span class="opacita8">${mome.format('HH:mm')}</span>`;

            return html;
         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();
            const chs = myVars.channels.tutti;

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui input">
                                 <input type="text" data-dt-datasrc="${dataSrc}" placeholder="dal:--/--/-- al:--/--/--">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            myFunc.makeCalendar($filter.find(`[data-dt-datasrc="${dataSrc}"]`), 'range');
            return $filter;
         }
      },
      hour: {
         customType: 'hour',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            return data ? data : '';
         }
      },
      hourdate: {
         customType: 'hourdate',
         className: 'left aligned collapsing tdCompactText',
         render: function (data, type, row, meta) {
            if (!data) return '';

            let html = `
               <span style="display: none;">${row._ftime}</span>
               <div class="infoLabel"><b>${row._fhour}</b></div>
               <div class="infoLabel" title="Giorno Programmazione: ${row._fday6}">${row._fday}</div>
               <div class="infoLabel opacita6">${row._fdayt}</div>

               ${row.hour ? `<!--<a data-addrToSheet="${row.sheetIndex},${row.hour.a}" title="Vai al foglio corrispondente" class="ui top right mini corner label">
                  <i class="crosshairs icon"></i>
               </a>-->` : ''}
            `;

            return html;
         }
      },
      ///////////////////////////////////////////////////////////////////////////// CHANNELS
      channel: {
         customType: 'channel',
         title: 'Canale',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type == 'display') {
               let html = '';
               let ch = myVars.channels.tutti.find(ch => data == ch.ID);
               //<div style="background: url(./returnFile?func=processImage&height=150&uri=channelsimages/${ch.ID}_logo.png&destUri=/mnt/tools.datatv.it/thumbnails/channelsimages/${ch.ID}_logo_150.png);background-color: #aaa;background-position: center;background-size: 80px;border-radius: 10px;height: 20px;background-repeat-x: no-repeat;"></div>
               if (ch) html += `<span><b>${ch.ID}</b> &bull; ${ch.Name}</span>`;
               return html;

            } else return data;

         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();
            const chs = myVars.channels.tutti;

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 ${chs.map(ch => `<div class="item" data-value="${ch.ID}">${ch.Name}</div>`).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            $filter.find('.dropdown').dropdown({ fullTextSearch: true });
            return $filter;
         }
      },
      channels: {
         customType: 'channels',
         title: 'Canali',
         className: 'left aligned collapsing tdCompactText',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type == 'display') {
               if (myFunc.isJSON(data)) data = JSON.parse(data);

               let html = '';
               let channels = myVars.channels.tutti.filter(u => data.includes(u.ID.toString()));

               html = channels.map(ch => `<span><b>${ch.ID}</b> &bull; ${ch.Name}</span>`).join('</br>');
               return html;

            } else return data;

         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();
            const chs = myVars.channels.tutti;

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 ${chs.map(ch => `<div class="item" data-value="${ch.ID}">${ch.Name}</div>`).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            $filter.find('.dropdown').dropdown({ fullTextSearch: true });
            return $filter;
         }
      },
      ///////////////////////////////////////////////////////////////////////////// USERS
      user: {
         customType: 'user',
         title: 'Utente',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type == 'display') {
               let html = '';
               let user = '';

               if (!isNaN(data)) user = myVars.users.tutti.find(u => u.id == data);
               else if (row.avatar && row.nome && row.cognome && row.ruolo) user = row;

               if (user) {
                  let dt = $(`#${meta.settings.sTableId}`).DataTable();
                  let $td = dt.cells({ "row": meta.row, "column": meta.col }).nodes(0).to$();
                  $td.addClass('onlineUserBg_' + user.id);

                  html += `
                     <div class="ui relaxed divided link list userList">
                           <a class="item">
                              <img class="ui avatar image" src="./${user.avatar ? 'avatars/' + user.avatar : 'avatars/user.png'}">
                              <div class="content">
                                 <div class="header">${user.nome ? user.nome.substring(0, 1) + '.' : ''} ${user.cognome}</div>
                                 <div class="description">${user.ruolo}</div>
                              </div>
                           </a>
                     </div>`;
               }

               return html;

            } else return data;

         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();
            const usrs = myVars.users.tutti;

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 ${usrs.map(us => `
                                    <div class="vertical item" data-value="${us.id}">
                                       <span class="text">${us.cognome} ${us.nome}
                                          </br><span class="opacita4">${us.ruolo}</span>
                                       </span>
                                    </div>
                                 `).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            $filter.find('.dropdown').dropdown({ fullTextSearch: true });
            return $filter;
         }
      },
      users: {
         customType: 'users',
         title: 'Utenti',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data || !data.length) return '';

            if (type == 'display') {
               let html = '';
               let users = myVars.users.tutti.filter(u => data.includes(u.id.toString()));

               html += `
                  <div class="ui relaxed divided link list userList">
                     ${users.map(u => `
                        <a class="item">
                           <img class="ui avatar image" src="./${u.avatar ? 'avatars/' + u.avatar : 'avatars/user.png'}">
                           <div class="content">
                              <div class="header">${u.nome.substring(0, 1)}. ${u.cognome}</div>
                              <div class="description">${u.ruolo}</div>
                           </div>
                        </a>
                     `).join('')}
                  </div>`

               return html;

            } else return data;

         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();
            const usrs = myVars.users.tutti;

            console.log(dt);

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 ${usrs.map(us => `
                                    <div class="vertical item" data-value='*"${us.id}"*'>
                                       <span class="text">${us.cognome} ${us.nome}
                                          </br><span class="opacita4">${us.ruolo}</span>
                                       </span>
                                    </div>
                                 `).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            $filter.find('.dropdown').dropdown({ fullTextSearch: true });
            return $filter;
         }
      },
      userDate: {
         customType: 'userDate',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type == 'display') {
               let colObj = meta.settings.aoColumns[meta.col];

               const otherCol = colObj.otherCol[0];
               let html = '';
               let user = '';
               // if (!isNaN(data)) {
               //    if (colObj.title == 'Redattore') user = myVars.users.tutti.find(u => u.old_id == data);
               //    else user = myVars.users.tutti.find(u => u.id == data);
               // }
               if (!isNaN(data)) user = myVars.users.tutti.find(u => u.id == data);
               else if (row.avatar && row.nome && row.cognome && row.ruolo) user = row;

               if (user) {
                  let dt = $(`#${meta.settings.sTableId}`).DataTable();
                  let $td = dt.cells({ "row": meta.row, "column": meta.col }).nodes(0).to$();
                  $td.addClass('onlineUserBg_' + user.id);

                  let mome = row[otherCol];
                  if (mome) mome = moment(mome.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm');
                  html = `
                     <div class="ui relaxed divided link list userList">
                           <a class="item">
                              <img class="ui avatar image" src="./${user.avatar ? 'avatars/' + user.avatar : 'avatars/user.png'}">
                              <div class="content">
                                 <div class="header">${user.nome ? user.nome.substring(0, 1) + '.' : ''} ${user.cognome}</div>
                                 <div class="description">${mome ? mome.format('DD/MM/YY') : '???'}</div>
                              </div>
                           </a>
                     </div>`;
               }

               return html;

            } else return data;
         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();
            const usrs = myVars.users.tutti;
            const colSettings = col.settings()[0].aoColumns[col[0][0]];

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 <div class="item" data-value="[PIENO]">Pieno</div>
                                 <div class="item" data-value="[VUOTO]">Vuoto</div>
                                 ${usrs.map(us => `
                                    <div class="vertical item" data-value="${us.id}">
                                       <span class="text">${us.cognome} ${us.nome}
                                          </br><span class="opacita4">${us.ruolo}</span>
                                       </span>
                                    </div>
                                 `).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="field">
                              <label>Il Giorno</label>
                              <div class="ui input">
                                 <input class="localRangeDate" type="text" data-dt-datasrc="${colSettings.otherCol[0]}" placeholder="dal:--/--/-- al:--/--/--">
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            $filter.find('.dropdown').dropdown({ fullTextSearch: true });
            myFunc.makeCalendar($filter.find(`[data-dt-datasrc="${colSettings.otherCol[0]}"]`), 'range');
            return $filter;
         }
      },
      ///////////////////////////////////////////////////////////////////////////// OTHERS
      testoProgramma: {
         customType: 'testoProgramma',
         title: 'Programma / Episodio / Originale',
         className: 'left aligned collapsing tdCompactText',
         render: function (data, type, row, meta) {
            let testoFull = row.testoOriginale ? row.testoOriginale : row.testoFull ? row.testoFull : '';
            let html = `
                  <a class="ui mini right corner label" data-toSearch="${data}" title="Cerca questo titolo"><i class="fitted search icon"></i></a>
                  <div class="testoProgramma" title="(${row.sheetName}, ${row.hour ? row.hour.a : ''}) ${data}">${data ? myFunc.camelize(data) : ''}</div>
                  ${row.testoEpisodio ? `<div class="testoEpisodio">${myFunc.camelize(row.testoEpisodio)}</div>` : ''}
                  <div class="testoFull" class="testoFull" title="${testoFull}">${testoFull}</div>
               `;

            return html;
         }
      },
      SeriesNumber: {
         customType: 'SeriesNumber',
         title: 'St',
         className: 'right aligned collapsing bold',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display') {
               let html = `${data}`;

               return html;
            }
            return data;
         }
      },
      episodioNumerico: {
         customType: 'episodioNumerico',
         title: 'Ep',
         className: 'right aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display') {
               let html = `${data}`;

               return html;
            }
            return data;
         }
      },
      dataTvIds: {
         customType: 'dataTvIds',
         className: 'collapsing left aligned',
         render: function (data, type, row, meta) {
            if (!data) return '';
            if (type === 'display') {
               let html = ``;

               if (data) {
                  html += `<div class="ui smallest blue label">PR ${data}</div>`;
               }
               if (row.idEpisode && row.idEpisode != -1) {
                  html += `<div class="ui smallest teal label">EP ${row.idEpisode}</div>`;
               }

               return html;

            } else return data;
         }
      },
      myRes: {
         customType: 'myRes',
         title: 'idProgram',
         className: 'left aligned collapsing tdMyRes',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display' || !meta.settings.serverSide) {
               let html = '';
               let satObj = typeof data === 'object' && data.restype ? data : row._match ? row._match : null;

               let idProgram = row.idProgram ? row.idProgram : null;
               let idEpisode = row.idEpisode && row.idEpisode != -1 ? row.idEpisode : null;

               if (satObj) {
                  html = myProgramSearch.getHtmlForSat(satObj);

               } else if (idProgram) {
                  html = `
                     <span style="display:none;">${idProgram}${idEpisode ? ', ' + idEpisode : ''}</span>
                     <div>Loading...</div>
                  `;

                  let dt = $(`#${meta.settings.sTableId}`).DataTable();
                  let $td = dt.cells({ "row": meta.row, "column": meta.col }).nodes(0).to$();
                  let query = idEpisode ? { title: `idEp=${idEpisode}` } : { title: `idPr=${idProgram}` };

                  client.service('program-join').find({ query: query })
                     .then(r => {
                        $td.find('div').remove();

                        if (r && r.results && r.results.length) {
                           let $resHtml = $(myProgramSearch.getHtmlForSat(r.results[0]));
                           $td.append($resHtml);
                        } else {
                           let $resHtml = $(`<span><i class="warning sign icon"></i>${idEpisode ? `Episodio ${idEpisode}` : `Programma ${idProgram}`} Non Trovato.</span>`);
                           $td.append($resHtml);
                        }
                     });
               }

               if (row._hystorySaved) {
                  html += `<div class="ui mini right corner label" title="Salvato nello storico"><i class="history icon"></i></div>`;
               }

               return html;
            }
            return data;
         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();
            const dataSrcs = dt.columns().dataSrc().toArray();

            let $filter = null;
            if (['_match'].includes(dataSrc)) {
               $filter = $(`
                  <div class="ui flowing popup filterPop">
                     <form class="ui small form">

                           <div class="field">
                              <label>Programma/Episodio</label>
                              <input type="text" data-dt-datasrc="${dataSrc}" placeholder="Programma/Episodio...">
                           </div>

                        <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                     </form>
                  </div>`).insertAfter($activator);

            } else {
               $filter = $(`
                  <div class="ui flowing popup filterPop">
                     <form class="ui small form">
                        <div class="field">
                           <label>Ricerca</label>
                           <div class="ui small search programSearch" data-program_search="programEpisode">
                              <div class="ui left icon input">
                                 <input type="text" name="myName" placeholder="Cerca Programma o Episodio in DB..." class="prompt"
                                    autocomplete="off" style="width: 300px;">
                                 <i class="database icon link"></i>
                              </div>
                           </div>
                        </div>

                        ${dataSrcs.includes('idProgram') ? `
                           <div class="field">
                              <label>ID Programma</label>
                              <input type="text" data-dt-datasrc="idProgram" placeholder="ID Programma...">
                           </div>
                        ` : ''}
                        ${dataSrcs.includes('idEpisode') ? `
                           <div class="field">
                              <label>ID Episodio</label>
                              <input type="text" data-dt-datasrc="idEpisode" placeholder="ID Episodio...">
                           </div>
                        ` : ''}

                        <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                     </form>
                  </div>`).insertAfter($activator);

               $filter.find('.dropdown').dropdown({ fullTextSearch: true });
               $filter.find('.programSearch').search(myProgramSearch.getOpt('programEpisode', true, true));
               $filter.find('.programSearch').on('choosed', function (e) {
                  let res = e.originalEvent.detail;
                  if (res && res.idProgram) {
                     $filter.find(`input[data-dt-datasrc="idProgram"]`).val(res.idProgram);
                  } else $filter.find(`input[data-dt-datasrc="idProgram"]`).val('');

                  if (res && res.idEpisode) {
                     $filter.find('input[data-dt-datasrc="idEpisode"]').val(res.idEpisode);
                  } else $filter.find('input[data-dt-datasrc="idEpisode"]').val('');
               })
            }

            return $filter;
         }
      },
      myResProgrammazione: {
         customType: 'myResProgrammazione',
         title: 'Programmazione',
         className: 'left aligned collapsing tdMyRes dtvselect',
         orderable: false,
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display' || !meta.settings.serverSide) {
               let html = '';

               if (row.dateTime) {
                  html += `<span style="display: none;">${moment(row.dateTime, 'YYYY-MM-DD HH:mm:ss').format('X')}</span>`;
               }

               html += myProgramSearch.getHtmlForSat(row);
               return html;

            } else return data;
         }

      },
      interface: {
         title: 'pag.',
         className: 'collapsing center aligned',
         render: function (data, type, row, meta) {
            const pages = myFunc.isJSON(data) ? JSON.parse(data) : [];
            if (!pages || pages.length == 0) return '';

            let html = `
                                    <span class="ui ${row.online ? 'green' : ''} label infoPop" data-html="
                                    <table class=\'ui very basic small compact celled single line table\'>
                                       <tbody>
                                          ${pages.map(p => {
               if (!p.page) return '?'; // Connesso in VPN?
               const pageName = p.page.replace(/(https|http):\/\//, '').split('?')[0];
               const matchIdr = /idRichiesta=(\d{1,})/i.exec(p.page);
               const duration = moment.duration(moment().diff(moment(p.date, 'YYYY-MM-DD HH:mm:ss')));
               const minutes = Math.ceil(duration.asMinutes());

               return `
                                                <tr class=\'${p.active ? '' : 'disabled'}\'>
                                                   <td>${p.ip}</td>
                                                   <td><a href=\'${p.page}\' target=\'_blank\'>${pageName} ${matchIdr && matchIdr[1] ? 'idr: <b>' + matchIdr[1] + '</b>' : ''}</a></td>
                                                   <td>da: ${minutes} min.</td>
                                                </tr>`;
            }).join('')}
                                       </tbody>
                                    </table>">${pages.length}
                                    </span>`;

            return html;
         }
      },
      station: {
         customType: 'station',
         title: 'Station',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type == 'display') {
               if (myFunc.isJSON(data)) data = JSON.parse(data);
               if (data == '""') return '';

               let html = '';
               if (Array.isArray(data)) html += data.map(e => `<span>${e}</span>`).join('</br>');
               else html += `<span>${data}</span>`;
               return html;

            } else return data;

         },
         filterRenderer: function ($activator, col, dt) {
            const $th = $(col.header());
            const dataSrc = col.dataSrc();

            let $filter = $(`
                     <div class="ui flowing popup filterPop">
                        <form class="ui small form">
                           <div class="field">
                              <label>${$th.text()}</label>
                              <div class="ui multiple search selection dropdown toApply" style="width:250px;">
                              <input type="hidden" data-dt-datasrc="${dataSrc}" value="">
                              <i class="dropdown icon"></i>
                              <i class="remove icon"></i><input class="search" autocomplete="fomantic-search" tabindex="0"><div class="default text">${$th.text()}</div>
                              <div class="menu" tabindex="-1">
                                 ${myVars.stations.map(s => `<div class="item" data-value="${s.name}">${s.name}</div>`).join('')}
                              </div>
                              </div>
                           </div>
                           <div class="ui right labeled icon tiny button filterBtn">Filtra<i class="filter icon"></i></div>
                        </form>
                     </div>
                  `).insertAfter($activator);

            $filter.find('.dropdown').dropdown({ fullTextSearch: true });
            return $filter;
         }
      },
      area: {
         customType: 'area',
         className: 'left aligned collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';
            if (myFunc.isJSON(data)) data = JSON.parse(data);

            let html = '';
            if (Array.isArray(data)) html += data.map(e => `<span>${e == '*' ? 'Tutte' : e}</span>`).join('</br>');
            return html;
         },
      },
      avatar: {
         customType: 'avatar',
         title: 'A',
         className: 'collapsing',
         render: function (data, type, row, meta) {
            var html = '';
            html += `
               <span style="display:none;">${row.online}</span>
               <img class="ui mini circular image" src="./${data ? 'avatars/' + data : 'avatars/user.png'}">`;
            return html;
         }
      },
      sessionFile: {
         customType: 'sessionFile',
         className: 'collapsing',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display') {
               let sessionId = row.sessionId ? row.sessionId : null;

               let html = `
                  <div title="${data}"><b>${data}</b></div>
                  ${sessionId ? `
                     <div class="ui mini basic labels">
                        <a class="ui label" href="https://tools.datatv.it/importPalinsesti?sessionId=${sessionId}" target="_blank"><i class="external alternate icon"></i> Sessione</a>
                        <a class="ui label" href="https://tools.datatv.it/importPalinsestiStorico?c_sessionId=${sessionId}" target="_blank"><i class="external alternate icon"></i> Storico</a>
                        ${['Admin', 'Supervisor'].includes(myVars.io.ruolo) ? `<a class="ui label" href="https://tools.datatv.it/reportImportPalinsesti?c_sessionId=${sessionId}" target="_blank"><i class="external alternate icon"></i> Import</a>` : ''}
                     </div>
                  ` : ''}
               `;

               return html;

               return html;
            }
            return data;
         }
      },
      /////////////////////////////////////////
      damWoPriority: {
         customType: 'damWoPriority',
         className: 'collapsing center aligned',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display') {
               data = data.toUpperCase();
               let html = data == 'URGENT' ? `<span class="ui smallest label">${data}</span>` :
                  data == 'HIGH' ? `<span class="ui smallest label opacita8">${data}</span>` :
                     data == 'MEDIUM' ? `<span class="ui smallest label opacita6">${data}</span>` :
                        data == 'LOW' ? `<span class="ui smallest label opacita4">${data}</span>` :
                           `<span class="ui smallest label">${data}</span>`;



               return html;

            } else return data;
         }

      },
      damWoIds: {
         customType: 'damWoIds',
         className: 'collapsing',
         render: function (data, type, row, meta) {

            if (type === 'display') {
               let html = ``;

               if (data) {
                  html += `<div class="ui smallest violet label">DI ${data}</div>`;
               }
               if (row.titleId) {
                  html += `<div class="ui smallest purple label">TI ${row.titleId}</div>`;
               }
               if (row.UUID) {
                  html += `<div class="ui smallest label" data-tocopy="${row.UUID}">${row.UUID}</div>`;
               }
               return html;

            } else return data;
         }
      },
      damWoTitle: {
         customType: 'damWoTitle',
         className: 'left aligned tdCompactText',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display') {
               let html = `
                  ${row.damUrl ? `<a href="${row.damUrl}" target="_blank" class="ui mini right corner label"><i class="external link alternate icon"></i></a>` : ''}
                  <div class="a_blue" title="${data}"><b>${data}</b></div>
                  ${row.IBMSTitle ? `<div class="opacita8">${row.IBMSTitle}</div>` : ''}
               `;

               return html;

            } else return data;
         }
      },
      damWoTasks: {
         customType: 'damWoTasks',
         className: 'left aligned collapsing pad4_2',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type == 'display') {
               if (myFunc.isJSON(data)) data = JSON.parse(data);
               let html = '';
               if (Array.isArray(data))
                  html += data.map(e => `
                     <div class="ui ${e.assigneeComment ? 'tiny warning' : 'mini'} message">
                        <div class="header">${e.taskType}</div>
                        <span>${e.assigneeComment ? e.assigneeComment : ''}</span>
                     </div>
                  `).join('');
               else html += `<span>${data}</span>`;
               return html;

            } else return data;

         },
      },
      programType: {
         customType: 'programType',
         title: 'Tipo',
         className: 'left aligned collapsing tdCompactText',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display') {
               const otherCol = meta.settings.aoColumns[meta.col].otherCol;
               let html = ``;

               html += /episode|episodio|ep/gi.test(data) ? `<div class="a_teal">${data}</div>` :
                  /serie/gi.test(data) ? `<div class="a_orange">${data}</div>` :
                     `<div class="a_blue">${data}</div>`;

               if (otherCol) {
                  otherCol.forEach(c => {
                     if (row[c]) html += `<div class="opacita6">${row[c]}</div>`;
                  });
               }

               return html;

            } else return data;
         }
      },
      sheetjs: {
         customType: 'sheetjs',
         render: function (data, type, row, meta) {
            if (!data) return '';

            if (type === 'display') {
               let cell = data;
               let html = `${cell.txt ? cell.txt : ''}`;

               // Classi
               let dt = new $.fn.dataTable.Api(meta.settings);
               let $td = $(dt.cell(meta.row, meta.col).node());

               let style = '';
               let classi = 'sheetjs ';
               let altTitle = '';

               if (cell.sty) {

                  if (cell.sty.top && cell.sty.top.style && cell.sty.top.color && cell.sty.top.color) {
                     if (cell.sty.top.color.rgb) style += `border-top: 1px solid #CCC;`;
                     if (cell.sty.top.color.auto) style += `border-top: 1px solid #CCC;`;
                  }

                  if (cell.sty.left && cell.sty.left.style && cell.sty.left.color && cell.sty.left.color) {
                     if (cell.sty.left.color.rgb) style += `border-left: 1px solid #CCC;`;
                     if (cell.sty.left.color.auto) style += `border-left: 1px solid #CCC;`;
                  }

                  if (cell.sty.bottom && cell.sty.bottom.style && cell.sty.bottom.color && cell.sty.bottom.color) {
                     if (cell.sty.bottom.color.rgb) style += `border-bottom: 1px solid #CCC;`;
                     if (cell.sty.bottom.color.auto) style += `border-bottom: 1px solid #CCC;`;
                  }

                  if (cell.sty.right && cell.sty.right.style && cell.sty.right.color && cell.sty.right.color) {
                     if (cell.sty.right.color.rgb) style += `border-right: 1px solid #CCC;`;
                     if (cell.sty.right.color.auto) style += `border-right: 1px solid #CCC;`;
                  }

                  // Colore sfondo
                  if (cell.sty.fgColor && cell.sty.fgColor.rgb) {
                     // Solo se diverso dal testo
                     if (!cell.sty.color || !cell.sty.color.rgb || (cell.sty.color.rgb != cell.sty.fgColor.rgb))
                        style += `background: #${cell.sty.fgColor.rgb};`;
                  }

                  // Colore testo
                  if (cell.sty.color && cell.sty.color.rgb) {
                     style += `color: #${cell.sty.color.rgb};`;
                  }

                  if (cell.sty.strike && cell.sty.strike > 0) {
                     style += `text-decoration: line-through;`;
                  }
               }

               // Giorni e Ora
               if (cell.tip == 'ora') {
                  classi += 'ora ';
                  altTitle = `${cell.par.getHours()}:${cell.par.getMinutes()}`;

               } else if (cell.tip == 'giorno') {
                  classi += 'giorno ';
                  altTitle = `${cell.par.getDate()}/${cell.par.getMonth() + 1}/${cell.par.getFullYear()}`;
               }

               // Errori
               if (cell.errorCell && !cell.hiddenRow && !cell.toSkip) {
                  classi += 'errorCell ';
                  altTitle += `\n${cell.errorCell}`;
               }

               // Fake or Error cells
               if (cell.hiddenRow) {
                  classi += 'hiddenRow ';
               }

               if (cell.fake) {
                  classi += 'fake ';
               }

               if (cell.toSkip) {
                  classi += 'toSkip ';
               }

               $td.attr('style', style);
               $td.attr('class', classi);
               $td.attr('title', altTitle);

               if (cell.a) $td.attr('data-celladdress', cell.a);

               return html;

            } else return (data.txt ? data.txt : '') + (data.hiddenRow || data.toSkip ? ' HiddenRow' : ' VisibleRow');
         }
      }
   },

}