// // INIT MODAL
// $('body').on('click', '[data-modalarchivio]', function (e) {
//    e.preventDefault();
//    e.stopPropagation();

//    if (!myVars || !myVars.io) return;
//    if (!['Admin', 'Supervisor', 'Grafico'].includes(myVars.io.ruolo)) return;

//    const id = $(this).data('modalarchivio');
//    const modalpage = $(this).data('modalpage');
//    console.log('modalpage', modalpage)
//    modalArchivio(id, page);
// });

module.exports = async function (id, modalpage) {
   $.fn.transition.settings.silent = true;

   await myVars.users.load();

   const permittedMime = ['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'];
   const permittedExt = ['jpg', 'gif', 'png', 'svg', 'psd', 'tiff'];

   const useArr = ['Hero', 'Character', 'Logo', 'Poster-V', 'Poster-V-Logo', 'Poster-V-Logo-AL1', 'Poster-V-Logo-AL2', 'Poster-O', 'Poster-O-Logo', 'Poster-O-Logo-219', 'Scena', 'Unica'];
   const tagsArr = ['Cliente Cambio Giocatori', 'Cliente Cambio Grafica', 'Cliente Cambio Soggetti', 'Cliente Cambio Titolo', 'Correzione', 'Da Lavorare', 'Master'];
   const originArr = ['Canale', 'Datatv', 'Auto', 'Internet', 'Produttore', 'Richiesta', 'Service'];
   const supplierArr = [
      'A&E (Canale)',
      'Academy Two',
      'Adler',
      'Blue Swan',
      'CBS',
      'DeAgostini (Canale)',
      'Discovery (Canale)',
      'Disney',
      'Eagle Pictures',
      'FOX',
      'Fanart',
      'Getty Images',
      'Good Films',
      'HBO',
      'Kock Media',
      'La7',
      'Leone Film Group',
      'Lucky Red',
      'M2 Pictures',
      'MGM',
      'Mari Film',
      'Medusa',
      'Minerva',
      'Miramax',
      'NBCUniversal',
      'Nexo',
      'No.Mad Entertainment',
      'Notorius',
       'Paramount',
      'Plaion',
      'Produttori',
      'Rai Cinema',
       'Rai Play',
      'RCS Mediagroup',
      'SKY',
      'Sony',
      'Tmdb',
      'Tucker',
      'Turner (Canale)',
      'Viacom (Canale)',
      'Videa',
      'Vision Distribution',
      'Warner',
      'Webphoto'
   ];

   const approvedArr = [
      {
         name: '<div class="ui red mini horizontal label" style="margin:-4px 0;">KO</div>',
         value: 'ko',
      }, {
         name: '<div class="ui basic mini horizontal label" style="margin:-4px 0;">VOD</div>',
         value: 'vod',
      }, {
         name: '<div class="ui basic mini horizontal label" style="margin:-4px 0;">SKY</div>',
         value: 'sky',
      }, {
         name: '<div class="ui basic mini horizontal label" style="margin:-4px 0;">DAZN</div>',
         value: 'dazn',
      }, {
         name: '<div class="ui basic mini horizontal label" style="margin:-4px 0;">NOW</div>',
         value: 'now',
      }, {
         name: '<div class="ui basic mini horizontal label" style="margin:-4px 0;">TIM</div>',
         value: 'tim',
      }, {
         name: '<div class="ui green mini horizontal label" style="margin:-4px 0;">ALL</div>',
         value: 'all',
      }];

   const $loader = $(`
    <div class="ui active inverted dimmer modalLoader">
      <div class="ui indeterminate text loader">Loading</div>
    </div>
  `).appendTo('body');

   //  client.service('program-join').timeout = 60000;
   const data = await client.service('program-join').get(id, { query: { add: 'synopsis,episodes,content,archivio,assegnazioneGrafico' } });
   data.skyDamIds = [];
   if (data.program && data.program.damId) data.skyDamIds.push(data.program.damId);
   if (data.episodes.length) data.episodes.map(e => e.damId ? data.skyDamIds.push(e.damId) : null);
   console.log(data.skyDamIds)

   console.log(`[modalArchivio] idProgram: ${id}, modalpage:${modalpage}`, data);
   if (!data) return;

   // //////////////////////////////////////////////// CREATE MODAL
   const $modal = createModal(data).modal({
      autofocus: false,
      allowMultiple: true,
      transition: 'scale',
      onHidden: function () {
         $('body').css('margin-right', 0);
      },
      onHide: function () {
         if (!data.localFiles) return;
         const filesNotUploaded = data.localFiles.filter((f) => f.uploaded == false);

         if (filesNotUploaded.length) {
            myFunc.confirmModal('Vuoi Chiudere?', 'Ci sono <b>' + filesNotUploaded.length + '</b> Immagini in <i class="upload icon"></i><b>Da Caricare</b> che non sono state caricate. Vuoi chiudere comunque?')
               .then((r) => {
                  if (r == 'ok') {
                     filesNotUploaded.map((f) => f.uploaded = true);
                     $('#modalarchivio').modal('hide');
                  }
               });
            return false;
         }
      },
   }).modal('show');

   $modal.data('modalData', data).addClass('.dataEl');
   $loader.remove();

   data.serverFiles = data.archivio;
   data.serverFiles.forEach((sf) => {
      sf.url = mySite + '/' + sf.folder + '/' + sf.name;
   });

   // //////////////////////////////////////////////// CHANGE TAB
   $modal.on('click', '.item[data-tab]', function (e) {
      const source = $(this).attr('data-tab');
      renderFiles(source, 'listView');
   });

   if (modalpage) {
      $(function () {
         $modal.find(`.item[data-tab="${modalpage}"]`).trigger('click');
      })
   }

   // //////////////////////////////////////////////// GUESS FILES
   $modal.on('click', '.item.guessFiles', function (e) {
      e.preventDefault();
      e.stopPropagation();

      const selection = $('.tab[data-tab="localFiles"] .selected').map(function () {
         return $(this).attr('id');
      }).get();

      guessFiles(selection);
   });

   // //////////////////////////////////////////////// LIST OR THUMBS
   $modal.on('click', '.item.iconView, .item.listView', function (e) {
      e.preventDefault();
      e.stopPropagation();

      const source = $modal.find('.item.active[data-tab]').attr('data-tab');
      const type = $(this).hasClass('listView') ? 'listView' : 'iconView';
      renderFiles(source, type);
   });

   // //////////////////////////////////////////////// THUMBS SELECT / DESELECT
   $modal.on('click', '.imageFile', function () {
      $(this).toggleClass('selected');
   });

   $modal.on('click', function (e) {
      if ($(e.target).closest('.imageFile').length == 0) {
         $('.imageFile').removeClass('selected');
      }
   });

   // //////////////////////////////////////////////// ADD FILES
   $modal.find('.input_file').change(function (e) {
      addLocalFiles(e.target.files);
      $(this).val('');
   });

   $modal.on('click', '.fileChooser', (e) => {
      $modal.find('.input_file')[0].click();
   });

   $('.fileChooser, .dropzone').on({
      'dragover dragenter': function (e) {
         e.preventDefault();
         e.stopPropagation();
      },
      'drop': function (e) {
         e.preventDefault();
         e.stopPropagation();
         const dataTransfer = e.originalEvent.dataTransfer;

         if (dataTransfer && dataTransfer.files.length) {
            addLocalFiles(dataTransfer.files);
         }
      },
   });

   // //////////////////////////////////////////////// DOWNLOAD SELECTED
   $modal.on('click', '.allDownload', function (e) {
      e.preventDefault();
      e.stopPropagation();

      const selection = $modal.find('.tab.active[data-tab] .selected').map(function () {
         return $(this).find('[data-down]').data('down');
      }).get();
      console.log('Download:', selection);
      selection.forEach((url, i) => {
         setTimeout(function () {
            const $link = $('<a href="' + url + '?downloadthis=1"  download class="myHidden imgDownload"></a>').appendTo($modal);
            console.log($link);
            $link[0].click();
            $link.remove();
         }, i * 500);
      });
   });

   // //////////////////////////////////////////////// DELETE LOCAL / SERVER
   $modal.on('click', '.menu.localFiles .del', (e) => {
      const selection = $('.tab[data-tab="localFiles"] .selected').map(function () {
         return $(this).attr('id');
      }).get();
      deleteLocalFiles(selection);
   });

   $modal.on('click', '.menu.serverFiles .del', (e) => {
      const selection = $('.tab[data-tab="serverFiles"] .selected').map(function () {
         return $(this).attr('id');
      }).get();
      deleteServerFiles(selection);
   });

   // //////////////////////////////////////////////// SEARCH / SERVER
   $modal.on('keyup', '[name^="search_"]', function (e) {
      let source = $(this).attr('name').replace('search_', '');
      let toSearch = $(this).val();
      let dt = $(`.tab[data-tab="${source}"] .archivioDt`).DataTable();
      dt.search(toSearch).draw();
   });

   // //////////////////////////////////////////////// UPLOAD FILES
   $modal.on('click', '.item.upload', (e) => {
      const source = $modal.find('.item.active[data-tab]').attr('data-tab');
      const selection = $('.tab[data-tab="' + source + '"] .selected').map(function () {
         return $(this).attr('id');
      }).get();
      uploadFiles(source, selection);
   });

   // //////////////////////////////////////////////// change-idEpisode, change-origin, change-use, change-approved
   $modal.on('click', 'td.change-idEpisode, td.change-origin, td.change-use, td.change-approved, td.change-supplier, td.change-tags', function (e) {
      e.preventDefault();
      e.stopPropagation();
      $('.changeTdDrop').remove();

      const $td = $(this);
      const testo = $td.text();
      const classe = $td.attr('class');
      const mData = $('#modalarchivio').data('modalData');
      const serFil = mData.serverFiles;

      let menu = `<div class="menu">
      <div class="ui search icon input">
        <i class="search icon"></i>
        <input type="text" name="search" placeholder="Cerca...">
      </div>`;
      let epsObj = mData.episodes;

      if (/change-idEpisode/.test(classe) && epsObj && epsObj.length > 0) {
         if (epsObj.find((e) => e.Turn)) {
            epsObj = epsObj.sort((a, b) => {
               const an = a.Turn ? parseInt(a.Turn.split('a')[0]) : -1;
               const bn = b.Turn ? parseInt(b.Turn.split('b')[0]) : -1;
               return an - bn;
            });
         }

         const sel = epsObj.find((e) => e.ID == testo);
         if (sel) {
            menu += `
          <div class="divider"></div>
          <div class="item" data-value="${sel.ID}">
            ${sel.episodioNumerico ? `<span class="a_blue">E${sel.episodioNumerico}</span>` : ``}
            ${sel.Turn ? `<span class="a_blue">${sel.Turn}</span>` : ``}
            <span class="opacita6"> / ${sel.ID} / Immagini ${serFil.filter((i) => i.idEpisode == sel.ID).length}</span></br>
            <b>${sel.Title}</b>
          </div>
          <div class="divider"></div>`;
         }

         menu += `<div class="item" data-value=""><span class="ui grey text opacita4">---</span></div>` + epsObj.map((ep) =>
            `<div class="item" data-value="${ep.ID}" data-tocopy="${ep.Title}">
            ${ep.episodioNumerico ? `<span class="a_blue">E${ep.episodioNumerico}</span>` : ``}
            ${ep.Turn ? `<span class="a_blue">${ep.Turn}</span>` : ``}
            <span class="opacita6"> / ${ep.ID} / Immagini ${serFil.filter((i) => i.idEpisode == ep.ID).length}</span></br>
            <b>${ep.Title}</b>
          </div> `).join('');
      } else if (/change-origin/.test(classe)) {
         menu += `<div class="item" data-value=""><span class="ui grey text opacita4">---</span></div>` + originArr.map((i) => `<a class="item" data-value="${i}">${i}</a>`).join('');
      } else if (/change-tags/.test(classe)) {
         menu += `<div class="item" data-value=""><span class="ui grey text opacita4">---</span></div>` + tagsArr.map((i) => `<a class="item" data-value="${i}">${i}</a>`).join('');
      } else if (/change-supplier/.test(classe)) {
         menu += `<div class="item" data-value=""><span class="ui grey text opacita4">---</span></div>` + supplierArr.map((i) => `<a class="item" data-value="${i}">${i}</a>`).join('');
      } else if (/change-use/.test(classe)) {
         menu += `<div class="item" data-value=""><span class="ui grey text opacita4">---</span></div>` + useArr.map((i) => `<a class="item" data-value="${i}">${i}</a>`).join('');
      } else if (/change-approved/.test(classe)) {
         menu += `<div class="item" data-value=""><span class="ui grey text opacita4">---</span></div>` + approvedArr.map((i) => `<a class="item" data-value="${i.value}">${i.name}</a>`).join('');
      }

      menu += `</div>`;

      const $drop = $(`<div class="ui scrolling dropdown changeTdDrop" style="box-sizing: border-box;">` + menu + `</div>`).prependTo($td);

      // Azione
      $drop.dropdown({
         fullTextSearch: 'exact',
         match: 'both',
         onChange: function (value, text, $choice) {
            changeTdValue($td, value);
            $drop.dropdown('destroy');
         },
      }).dropdown('show');

      $drop.on('contextmenu', '.item', function (e) {
         e.preventDefault();
         e.stopPropagation();

         const tit = $(this).attr('data-tocopy');
         myFunc.copyToClipboard(tit);
         $(this).transition('flash');
      });
   });

   // //////////////////////////////////////////////// change-episodeNumber
   $modal.on('click', '.change-episodeNumber input, td.change-notes input, td.change-copyright input', function (e) {
      e.stopPropagation();
   });

   $modal.on('change', '.change-episodeNumber input, td.change-notes input, td.change-copyright input', function (e) {
      const $td = $(this).closest('td');
      const value = $(this).val();
      changeTdValue($td, value);
   });

   // // //////////////////////////////////////////////// Content Vod Images
   // $modal.on('click', '.item[data-tab="contentFiles"]', function (e) {
   //    rendercontentFiles();
   // });

   // $modal.on('click', '.item[data-tab="internetFiles"]', function (e) {
   //    renderInternetFiles();
   // });

   // //////////////////////////////////////////////// Generate buttons
   $modal.on('click', '.generatedDownload', function (e) {
      const mcs = $modal.find('#generatedFiles ._myCanvas');
      mcs.each((i, mc) => {
         const myCan = mc.myCanvas;
         if (!['No'].includes(myCan.state)) {
            myCan.download();
         }
      });
   });

   $modal.on('click', '.generatedCheck', function (e) {
      const mcs = $modal.find('#generatedFiles ._myCanvas');
      mcs.each((i, mc) => {
         const myCan = mc.myCanvas;
         if (!['No', 'Db', 'Ok'].includes(myCan.state)) {
            myCan.setState('Ok');
         }
      });
   });

   $modal.on('click', '.generatedUnCheck', function (e) {
      const mcs = $modal.find('#generatedFiles ._myCanvas');
      mcs.each((i, mc) => {
         const myCan = mc.myCanvas;
         if (!['No', 'Db', 'Ko'].includes(myCan.state)) {
            myCan.setState('kO');
         }
      });
   });

   $modal.on('click', '.generatedUpload', function (e) {
      const selection = $modal.find('#generatedFiles ._myCanvas').toArray().map((mc) => mc.myCanvas).filter((mc) => mc.state == 'Ok');
      uploadMyCanvases(selection);
   });

   // ----------------------------------------------------------------------------------------------------------------------
   // ----------------------------------------------------------------------------------------------------------------------
   // ----------------------------------------------------------------------------------------------------------------------
   // ---------------------------------------------------------------------------------------------------------------------- CREATE MODAL
   function createModal(data) {
      console.log('Modal Archivio', data);
      $('#modalarchivio').remove();
      const requiredImg = getRequiredImgs(data);

      // <div class="ui tiny labels" style="position: absolute;top: 0px;right: 0;">
      // <div class="ui label">Content Complete</div>
      // <div class="ui label">VOD</div></div>

      const base = data.programserie && data.programserie.idProgramBase ? data.programserie.idProgramBase : false;
      const isBase = base == data.program.ID;
      const reverseEpisodes = false; //data.episodes.length ? data.episodes.reverse() : false;
      const userAss = data.assegnazioneGrafico ? myVars.users.tutti.find(u => u.id == data.assegnazioneGrafico.assigned_to) : null;

      const $modal = $(`
         <div class="ui fullscreen modal" id="modalarchivio">
         <div class="header" style="border:0">
            <i class="archive icon"></i>
            ${myProgramSearch.getHtmlForHeader(data.program, false, data.episodes.length)}
            ${requiredImg.length > 0 ? '<div class="ui top right attached small label">' + requiredImg.join('<span class="ui grey text opacita4">&nbsp;&nbsp;/&nbsp;&nbsp;</span>') + '</div>' : ''}
         </div>
         </div>`).appendTo('body');

      const $menu = $(`
      <div class="ui attached menu archivioMenu DTE">
        <!--Con la classe DTE non dovrebbe fare il blur sulla selezione della datatable-->
        <div class="item" data-tab="serverFiles"><i class="blue archive icon"></i>Archivio</div>
        <div class="disabled text item">Sorgenti:</div>
        <div class="active item" data-tab="localFiles" title="Carica File Locali"><i class="fitted brown upload icon"></i></div>
        <div class="item" data-tab="contentFiles" title="Carica File di Content"><i class="fitted green database icon"></i></div>
        ${data.skyDamIds.length ? '<div class="item" data-tab="skyDamFiles" title="Carica File dal DAM di Sky"><i class="fitted violet box icon"></i></div>' : ''}
        ${data.idTmdb ? '<div class="item" data-tab="internetFiles" title="Carica File da Internet"><i class="fitted teal cloud icon"></i></div>' : ''}

        <div class="ui dropdown item generateMenu">
          <i class="orange paint brush icon"></i> Genera Immagini
          <i class="dropdown icon"></i>
          <div class="ui menu" style="width:260px;">
            <div class="item" data-generate="generateShowTab"><i class="eye icon"></i>Visualizza i file Generati</div>
            <div class="item" data-generate="generateBaseFormats"><i class="dropbox icon"></i>Genera formati base</div>
            <!--<div class="item" data-generate="generateVod"><i class="tv icon"></i>VOD <span class="a_blue">Programma</span></div>-->

            ${reverseEpisodes.length ? `
              <div class="dropdown item"><i class="ordered list icon"></i>VOD <span class="a_blue">Episodi</span>
                <i class="dropdown icon"></i>
                <div class="ui menu" style="max-height: 300px; overflow-x: hidden; overflow-y: scroll;">
                  <div class="item" data-generate="generateVodEps"><i class="archive icon"></i>Episodi Mancanti</div>
                  ${reverseEpisodes.map((ep) => {
         return `<div class="item" data-generate="generateVodEps" data-generateSingleEp="${ep.ID}"><span class="a_blue">${ep.episodioNumerico ? ep.episodioNumerico : ep.Turn}</span> ${ep.Title}</div>`;
      }).join('')}
                </div>
              </div>

              <div class="dropdown item"><i class="ordered list icon"></i>VOD <span class="a_blue">Episodi</span> con N°Ep.
                <i class="dropdown icon"></i>
                <div class="ui menu" style="max-height: 300px; overflow-x: hidden; overflow-y: scroll;">
                  <div class="item" data-generate="generateVodEpsNep"><i class="archive icon"></i>Episodi Mancanti</div>
                  ${reverseEpisodes.map((ep) => {
         return `<div class="item" data-generate="generateVodEpsNep" data-generateSingleEp="${ep.ID}"><span class="a_blue">${ep.episodioNumerico ? ep.episodioNumerico : ep.Turn}</span> ${ep.Title}</div>`;
      }).join('')}
                </div>
              </div>

              <div class="dropdown item"><i class="ordered list icon"></i>VOD <span class="a_blue">Episodi</span> con N°Ep. Kids
                <i class="dropdown icon"></i>
                <div class="ui menu" style="max-height: 300px; overflow-x: hidden; overflow-y: scroll;">
                  <div class="item" data-generate="generateVodEpsNepKids"><i class="archive icon"></i>Episodi Mancanti</div>
                  ${reverseEpisodes.map((ep) => {
         return `<div class="item" data-generate="generateVodEpsNepKids" data-generateSingleEp="${ep.ID}"><span class="a_blue">${ep.episodioNumerico ? ep.episodioNumerico : ep.Turn}</span> ${ep.Title}</div>`;
      }).join('')}
                </div>
              </div>

              <!--<div class="item" data-generate="generateEp"><i class="ordered list icon"></i>VOD <span class="a_blue">Episodi</span> con N°Ep. da <b>selezione</b></div>
              <div class="item" data-generate="generateEpKids"><i class="ordered list icon"></i>VOD <span class="a_blue">Episodi</span> con N°Ep. Kids da <b>selezione</b></div>-->
              <div class="item" data-generate="generateEpOptions"><i class="ordered list icon"></i>VOD <span class="a_blue">Episodi</span> da Selezione</b></div>
            ` : ''}

          </div>
        </div>

         <a class="item vertically fitted infoPop assegnaGrafico" data-content="Assegnazione Grafico" data-popassegna_grafico="${data.program.ID},-1,,${data.program.Title.replace(/[^A-Z0-9- ]/gi, '_')}">
            <img class="ui avatar image" src="./${userAss && userAss.avatar ? 'avatars/' + userAss.avatar : 'avatars/user.png'}">
            <div class="content">
               <div class="header">${userAss && userAss.nome ? userAss.nome.substring(0, 1) + '.' : ''} ${userAss ? userAss.cognome : '--'}</div>
               <div class="description opacita6">${data.assegnazioneGrafico ? moment(data.assegnazioneGrafico.created_at.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm').format('DD/MM/YY') : '???' }</div>
            </div>
         </a>

        <div class="right menu localFiles">
         <a class="item infoPop fileChooser" data-content="Aggiungi file locali"><i class="fitted plus circle icon"></i></a>
         <a class="item infoPop del" data-content="Rimuovi file locali selezionati"><i class="fitted minus circle icon"></i></a>
          <a class="item infoPop guessFiles" data-content="Interpreta i nomi dei file selezionati"><i class="fitted magic icon"></i></a>
          <a class="item infoPop listView" style="display:none;" data-content="Visualilzza Lista"><i class="fitted bars icon"></i></a>
          <a class="item infoPop iconView" data-content="Visualizza Griglia"><i class="fitted th icon"></i></a>
          <div class="item vertically fitted">
            <div class="ui toggle checkbox multiMod">
              <input type="checkbox" tabindex="0" class="hidden">
              <label>Multi</label>
            </div>
          </div>
          <a class="item infoPop upload" data-content="Upload file selezionati nell'archivio"><i class="blue upload icon"></i>Carica</a>
        </div>

        <div class="right menu serverFiles">
          <div class="ui search vertically fitted item" style="width: 200px;">
            <div class="ui transparent icon input">
              <input class="prompt" type="text" placeholder="Cerca..." autocomplete="off" name="search_serverFiles">
              <i class="search link icon"></i>
            </div>
          </div>
          <a class="item infoPop listView" style="display:none;" data-content="Visualilzza Lista"><i class="fitted bars icon"></i></a>
          <a class="item infoPop iconView" data-content="Visualizza Griglia"><i class="fitted th icon"></i></a>
          <a class="item infoPop allDownload" data-content="Download file selezionati"><i class="fitted download icon"></i></a>
          <div class="item vertically fitted">
            <div class="ui toggle checkbox multiMod">
              <input type="checkbox" tabindex="0" class="hidden">
              <label>Multi</label>
            </div>
          </div>
          <a class="item infoPop del" data-content="Elimina file selezionati dall'Archivio!"><i class="red trash alternate outline icon"></i>Elimina</a>
        </div>

        <div class="right menu contentFiles">
          <div class="ui search vertically fitted item" style="width: 200px;">
            <div class="ui transparent icon input">
              <input class="prompt" type="text" placeholder="Cerca..." autocomplete="off" name="search_contentFiles">
              <i class="search link icon"></i>
            </div>
          </div>
          <a class="item infoPop listView" style="display:none;" data-content="Visualilzza Lista"><i class="fitted bars icon"></i></a>
          <a class="item infoPop iconView" data-content="Visualizza Griglia"><i class="fitted th icon"></i></a>
          <a class="item infoPop allDownload" data-content="Download file selezionati"><i class="fitted download icon"></i></a>
          <div class="item vertically fitted">
            <div class="ui toggle checkbox multiMod">
              <input type="checkbox" tabindex="0" class="hidden">
              <label>Multi</label>
            </div>
          </div>
          <a class="item infoPop upload" data-content="Upload file selezionati nell'archivio"><i class="blue upload icon"></i>Carica</a>
        </div>

        <div class="right menu internetFiles">
          <div class="ui search vertically fitted item" style="width: 200px;">
            <div class="ui transparent icon input">
              <input class="prompt" type="text" placeholder="Cerca..." autocomplete="off" name="search_internetFiles">
              <i class="search link icon"></i>
            </div>
          </div>
          <a class="item infoPop listView" style="display:none;" data-content="Visualilzza Lista"><i class="fitted bars icon"></i></a>
          <a class="item infoPop iconView" data-content="Visualizza Griglia"><i class="fitted th icon"></i></a>
          <a class="item infoPop allDownload" data-content="Download file selezionati"><i class="fitted download icon"></i></a>
          <div class="item vertically fitted">
            <div class="ui toggle checkbox multiMod">
              <input type="checkbox" tabindex="0" class="hidden">
              <label>Multi</label>
            </div>
          </div>
          <a class="item infoPop upload" data-content="Upload file selezionati nell'archivio"><i class="blue upload icon"></i>Carica</a>
        </div>

        <div class="right menu skyDamFiles">
          <div class="ui search vertically fitted item" style="width: 200px;">
            <div class="ui transparent icon input">
              <input class="prompt" type="text" placeholder="Cerca..." autocomplete="off" name="search_skyDamFiles">
              <i class="search link icon"></i>
            </div>
          </div>
          <a class="item infoPop listView" style="display:none;" data-content="Visualilzza Lista"><i class="fitted bars icon"></i></a>
          <a class="item infoPop iconView" data-content="Visualizza Griglia"><i class="fitted th icon"></i></a>
          <a class="item infoPop allDownload" data-content="Download file selezionati"><i class="fitted download icon"></i></a>
          <div class="item vertically fitted">
            <div class="ui toggle checkbox multiMod">
              <input type="checkbox" tabindex="0" class="hidden">
              <label>Multi</label>
            </div>
          </div>
          <a class="item infoPop upload" data-content="Upload file selezionati nell'archivio"><i class="blue upload icon"></i>Carica</a>
        </div>

        <div class="right menu generatedFiles">
          <a class="item infoPop generatedDownload" data-content="Download file generati"><i class="fitted download icon"></i></a>
          <a class="item infoPop generatedCheck" data-content="Conferma tutte le immagini"><i class="check fitted icon"></i></a>
          <a class="item infoPop generatedUnCheck" data-content="Rimuovi Conferma a tutte le immagini"><i class="ban fitted icon"></i></a>
          <a class="item infoPop generatedUpload" data-content="Upload Immagini Generate Confermate"><i class="blue upload icon"></i>Carica Confermate</a>
        </div>

      </div>`).appendTo($modal);

      const $content = $(`
      <div class="scrolling content full" style="position:relative; height: 75vh !important;">

        <!--INPUT FILE-->
        <input class="input_file myHidden" type="file" ${!['Admin', 'Supervisor'].includes(myVars.io.ruolo) ? 'accept="image/*"' : ''} multiple>

        <!--TAB LOCAL-->
        <div class="ui tab basic segment active dropzone" data-tab="localFiles" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:0; padding:auto;">
          <div class=" ui active inverted dimmer fileChooser emptyArea">
            <h2 class="ui icon brown header">
              <i class="folder open icon"></i>
              <div class="content">
                Trascina qui i file da Caricare!
                <div class="sub header">Puoi caricare immagini JPG(JPEG), PNG, SVG, GIF.</div>
              </div>
            </h2>
          </div>
        </div>

        <!--TAB SERVER-->
        <div class="ui tab basic segment" data-tab="serverFiles" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:0; padding:auto;">
          <div class=" ui active inverted dimmer emptyArea">
            <h2 class="ui icon grey opacita4 header">
              <i class="archive icon"></i>
              <div class="content">
                Nessun File Disponibile
                <div class="sub header">Sul server non sono presenti files per questo programma.</div>
              </div>
            </h2>
          </div>
        </div>

        <!--TAB CONTENT VOD-->
        <div class="ui tab basic segment" data-tab="contentFiles" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:0; padding:auto;">
          <div class=" ui active inverted dimmer emptyArea">
            <h2 class="ui icon grey opacita4 header">
              <i class="database icon"></i>
              <div class="content">
                Nessun File Disponibile
                <div class="sub header">Non sono presenti files Content o VOD per questo programma.</div>
              </div>
            </h2>
          </div>
        </div>

        <!--TAB INTERNET-->
        <div class="ui tab basic segment" data-tab="internetFiles" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:0; padding:auto;">
          <div class=" ui active inverted dimmer emptyArea">
            <h2 class="ui icon grey opacita4 header">
              <i class="cloud icon"></i>
              <div class="content">
                Nessun File Disponibile
                <div class="sub header">Non sono stati trovati files su internet per questo programma.</div>
              </div>
            </h2>
          </div>
        </div>

        <!--TAB SKY DAM-->
        <div class="ui tab basic segment" data-tab="skyDamFiles" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:0; padding:auto;">
          <div class=" ui active inverted dimmer emptyArea">
            <h2 class="ui icon grey opacita4 header">
              <i class="box icon"></i>
              <div class="content">
                Nessun File Disponibile
                <div class="sub header">Non sono stati trovati files sul DAM Sky per questo programma.</div>
              </div>
            </h2>
          </div>
        </div>

        <!--FALSE TAB GENERATE-->
        <div id="generatedFiles" style="position:absolute; top:0; left:0; right:0; bottom:0; margin:0; padding:auto; display:none;">
          <div class=" ui active inverted dimmer emptyArea">
              <h2 class="ui icon grey opacita4 header">
                <i class="paint brush icon icon"></i>
                <div class="content">
                  Nessun File Generato
                  <div class="sub header">Per generare dei file clicca sulla freccia accanto al Tab "Genera".</div>
                </div>
              </h2>
            </div>
          </div>
      </div>
      <div class="actions info" style="border-radius: 0 0 20px 20px;"></div>
    `).appendTo($modal);

      $modal.find('.archivioMenu .menu.serverFiles').hide();
      $modal.find('.archivioMenu .menu.contentFiles').hide();
      $modal.find('.archivioMenu .menu.internetFiles').hide();
      $modal.find('.archivioMenu .menu.generatedFiles').hide();
      $modal.find('.archivioMenu .menu.skyDamFiles').hide();

      $(function () {
         $modal.find('.archivioMenu > .item').tab();
         $modal.find('.ui.dropdown').dropdown();
         $modal.find('.ui.checkbox').checkbox();
         $modal.find('.infoPop').popup();
         renderInfo();

         $modal.find('.archivioMenu > .item').not('.generateMenu, .assegnaGrafico').on('click', function () {
            const tab = $(this).attr('data-tab');

            // Hide all
            $modal.find('#generatedFiles').hide();
            $modal.find('.segment[data-tab]').hide();
            $modal.find('.archivioMenu .right.menu').hide();

            // Show Needed
            $modal.find('.segment[data-tab="' + tab + '"]').show();
            $modal.find('.archivioMenu .menu.' + tab).show();
         });

         $modal.find('.multiMod').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
         });

         $modal.on('click', function () {
            renderInfo();
         });

         $modal.on('click', '[data-generate]', function (e) {
            const action = $(this).attr('data-generate');
            const singleEp = $(this).attr('data-generateSingleEp');
            generateImages(action, singleEp);
         });
      });

      return $modal;
   }

   // ---------------------------------------------------------------------------------------------------------------------- MANAGE FILES
   // addLocalFiles
   function addLocalFiles(files) {
      const myFiles = [];
      const promises = [];

      Object.keys(files).forEach((k, i) => {
         const mime = files[k].type;

         if (permittedMime.includes(mime) || ['Admin', 'Supervisor'].includes(myVars.io.ruolo)) {
            promises.push(readFile(files[k], i));
         }
      });

      Promise.all(promises).then((r) => {
         const mData = $('#modalarchivio').data('modalData');
         const view = $('.menu.localFiles .iconView').is(':visible') ? 'listView' : 'iconView';

         if (!mData.localFiles) mData.localFiles = [];
         mData.localFiles = mData.localFiles.concat(r);
         renderFiles('localFiles', view);
      });

      function readFile(file, i) {
         return new Promise((resolve, reject) => {
            const myFile = {
               id: i + 'l' + Date.now(),
               name: file.name,
               size: file.size,
               extension: myFunc.getExtension(file.name),
               origin: '',
               use: '',
               approved: '',
               notes: '',
               tags: '',
               supplier: '',
               idEpisode: '',
               episodeNumber: '',
               episodeTitle: '',
               updated_at: file.lastModifiedDate,
               localFile: file,
               uploaded: false,
            };

            if (!permittedMime.includes(file.type)) {
               myFile.url = '';
               myFile.width = '0';
               myFile.height = '0';
               resolve(myFile);
            } else {
               const reader = new FileReader();
               reader.onload = (theFile) => {
                  const image = new Image();
                  image.src = theFile.target.result;

                  image.onload = () => {
                     myFile.url = theFile.target.result;
                     myFile.width = image.width;
                     myFile.height = image.height;
                     resolve(myFile);
                  };
               };
               // Read the image
               reader.readAsDataURL(file);
            }
         });
      }
   }

   // uploadFiles
   async function uploadFiles(source, selection) {
      if (!selection || selection.length == 0) {
         console.log('Nessun File Selezionato.', source, selection);
         return;
      }

      let mData = $('#modalarchivio').data('modalData');
      let files = mData[source];
      let selectedFiles = files.filter((f) => (source == 'localFiles' || permittedExt.includes(f.extension)) && (selection.includes(f.id) || selection.includes(f.id.toString())));

      // Converto i file non supportati
      const $loader = $(`
         <div class="ui active inverted dimmer modalLoader">
            <div class="ui indeterminate text loader">Conversione Files non supportati</div>
         </div>
      `).appendTo($modal);

      for (let sf of selectedFiles) {
         sf.error = null;
         sf.processed = null;

         if (sf.size > 536870912) {
            sf.error = 'File Troppo Grande';

         } else if (['psd' || 'tiff'].includes(sf.extension)) {
            $loader.find('.loader').text(`Conversione ${sf.name}`);
            await client.service('image-get').get('processImage', {
               query: {
                  uri: sf.url,
                  destUri: `/mnt/tools.datatv.it/tempResize/${sf.name}.png`
               }
            })
            .then(r => {
               console.log(r);
               if (r && r.processed) {
                  sf.processed = r.processed;
               } else {
                  sf.error = 'non processata';
               }
            })
            .catch(e => {
               console.log(e);
               sf.error = 'Errore Conversione';
            });
         }
      }

      // Elimino i file non convertiti
      selectedFiles = selectedFiles.filter(sf => !sf.error);

      $loader.remove();

      // Suddivido l'upload in gruppi di 10
      const chunks = myFunc.chunkArray(selectedFiles, 10);
      console.log('[uploadFiles] chunks:', chunks.length, chunks);

      for (let chunk of chunks) {
         await uploadChunk(chunk);
      }

      renderFiles('serverFiles', 'listView');
      selectedFiles.forEach((sf) => {
         if (sf.uploaded) $('tr#' + sf.id + ' td.imagePreview').prepend('<i class="ui blue archive icon"></i>');
         else $('tr#' + sf.id + ' td.imagePreview').prepend('<i class="ui red warning sign icon"></i>');
      });
      console.log('[uploadFiles] Fine', chunks);

      async function uploadChunk(chunkFiles) {
         // formDATA
         const formData = new FormData();
         const toUpload = [];
         const filesData = [];

         chunkFiles.forEach((cf) => {
            filesData.push({
               idProgram: mData.program.ID,
               programTitle: mData.program.Title,

               idEpisode: cf.idEpisode ? cf.idEpisode : -1,
               episodeTitle: cf.episodeTitle,
               episodeNumber: cf.episodeNumber,

               // name:'',
               extension: cf.extension,
               size: cf.processed ? cf.processed.details.size : cf.size,
               width: cf.processed ? cf.processed.details.width : cf.width,
               height: cf.processed ? cf.processed.details.height : cf.height,

               notes: cf.notes,
               approved: cf.approved,
               origin: cf.origin,
               supplier: cf.supplier,
               tags: cf.tags,
               use: cf.use,
            });

            if (source == 'localFiles') formData.append('myFile', cf.localFile);
            else toUpload.push(cf.processed ? cf.processed.uri : cf.url);
         });

         if (source != 'localFiles') formData.append('urls', JSON.stringify(toUpload));
         formData.append('folderName', 'archivio');
         formData.append('filesData', JSON.stringify(filesData));

         // UPLOAD
         console.log('Inizio Upload Chunk:', source, filesData);
         const upload = await myFunc.sendXHRequest(formData, mySite + '/archivio', $('#modalarchivio')).then((resp) => {
            resp.forEach((rf) => {
               rf.url = mySite + '/' + rf.folder + '/' + rf.name;
            });

            if (!mData.serverFiles) mData.serverFiles = [];
            mData.serverFiles = mData.serverFiles.concat(resp);
            chunkFiles.forEach((cf) => {
               cf.uploaded = true;
            });
         }).catch((e) => {
            console.log('[sendXHRequest] Errore Upload', e)
            chunkFiles.forEach((cf) => {
               cf.uploaded = false;
            });
         });
      }
   }

   // uploadMyCanvases
   async function uploadMyCanvases(mcs) {
      const mData = $('#modalarchivio').data('modalData');

      // Suddivido l'upload in gruppi di 10
      const chunks = myFunc.chunkArray(mcs, 10);
      console.log('+++++++++++++chunks', chunks.length, chunks);

      await (async () => {
         for (let i = 0; i < chunks.length; i++) {
            const chunk = chunks[i];
            await uploadChunk(chunk, i);
         }
      })();

      renderFiles('serverFiles', 'listView');
      mcs.forEach((mc) => {
         if (mc.uploaded) mc.setState('Db');
         else mc.setState('Ko');
      });
      console.log('+++++++++++++chunks FINE', chunks);

      async function uploadChunk(chunkFiles, i) {
         // formDATA
         const formData = new FormData();
         const toUpload = [];
         const filesData = [];

         chunkFiles.forEach((mc) => {
            const progInfo = mc.settings.progInfo;
            const TDUext = progInfo.extension == 'jpg' ? 'jpeg' : progInfo.extension;
            const canvas = mc.mergeLayers();
            const base64 = canvas.toDataURL('image/' + TDUext, 0.9);
            toUpload.push(base64);

            filesData.push({
               idProgram: mData.program.ID,
               programTitle: mData.program.Title,

               idEpisode: progInfo.idEpisode ? progInfo.idEpisode : -1,
               episodeTitle: progInfo.episodeTitle,
               episodeNumber: progInfo.episode,

               extension: progInfo.extension,
               size: window.atob(base64.split(',')[1]).length,
               width: mc.settings.width,
               height: mc.settings.height,

               notes: progInfo.notes,
               approved: progInfo.approved,
               origin: progInfo.origin,
               supplier: progInfo.supplier,
               tags: progInfo.tags,
               use: progInfo.use,
            });
         });

         formData.append('base64', JSON.stringify(toUpload));
         formData.append('folderName', 'archivio');
         formData.append('filesData', JSON.stringify(filesData));

         // UPLOAD
         console.log('Inizio Upload Chunk:', i, filesData);
         const upload = await myFunc.sendXHRequest(formData, mySite + '/archivio', $('#modalarchivio')).then((resp) => {
            resp.forEach((rf) => {
               rf.url = mySite + '/' + rf.folder + '/' + rf.name;
            });

            if (!mData.serverFiles) mData.serverFiles = [];
            mData.serverFiles = mData.serverFiles.concat(resp);
            chunkFiles.forEach((cf) => {
               cf.uploaded = true;
            });
         }).catch((e) => {
            generatedFilesMessage('warning sign', 'error', 'Errore Caricamento Immagini Generate', 'Si è verificato un errore durante il caricamento immagini generate, controlla il log.');
            chunkFiles.forEach((cf) => {
               cf.uploaded = false;
            });
         });
      }
   }

   // deleteLocalFiles
   function deleteLocalFiles(selection) {
      const mData = $('#modalarchivio').data('modalData');
      const files = mData.localFiles;
      mData.localFiles = files.filter((f) => !selection.includes(f.id) || !selection.includes(f.id.toString()));

      const view = $('.menu.localFiles .iconView').is(':visible') ? 'listView' : 'iconView';
      renderFiles('localFiles', view);
   }

   // deleteServerFiles
   function deleteServerFiles(selection) {
      const mData = $('#modalarchivio').data('modalData');
      const files = mData.serverFiles;

      client.service('archivio').remove(null, {
         query: {
            id: {
               $in: selection,
            },
         },
      }).then((res) => {
         mData.serverFiles = files.filter((f) => !res.map((r) => r.id).includes(f.id));
         const view = $('.menu.serverFiles .iconView').is(':visible') ? 'listView' : 'iconView';
         renderFiles('serverFiles', view);
      });
   }

   // updateServerFile
   function updateServerFile(imgId, $tr) {
      const mData = $('#modalarchivio').data('modalData');
      const imgIndex = mData.serverFiles.findIndex((k) => k.id == imgId);
      const imgO = mData.serverFiles[imgIndex];

      console.log('updateServerFile', imgO.name);

      $tr.addClass('warning');
      client.service('archivio').update(imgId, imgO).then((res) => {
         $tr.removeClass('warning');
         res.url = mySite + '/' + res.folder + '/' + res.name;
         mData.serverFiles[imgIndex] = res;

         const view = $('.menu.serverFiles .iconView').is(':visible') ? 'listView' : 'iconView';
         renderFiles('serverFiles', view);
      });
   }

   // ---------------------------------------------------------------------------------------------------------------------- RENDERS
   async function getExternalFiles(source) {
      let mData = $modal.data('modalData');
      let images;
      mData[source] = [];

      if (source == 'internetFiles') {
         images = await client.service('/image-get').find({ query: { idTmdb: mData.idTmdb, type: mData.type } })
            .then(r => r.images);

         images.forEach((img, i) => {
            const myFile = {
               id: 'if' + i,
               name: img.name,
               url: img.url,
               size: '',
               extension: myFunc.getExtension(img.name),
               origin: 'Internet',
               use: img.use,
               notes: img.lang ? img.lang : '',
               approved: '',
               tags: '',
               supplier: img.service,
               idEpisode: '',
               episodeNumber: img.episodeNumber ? img.episodeNumber : '',
               episodeTitle: '',
               updated_at: '',
               width: img.width,
               height: img.height,
               uploaded: false,
            };

            mData[source].push(myFile);
         });

      } else if (source == 'contentFiles') {
         images = await client.service('/image-get').find({ query: { idProgram: data.idProgram } })
            .then(r => r.images);

         images.forEach((img, i) => {
            // Se ho già aggiunto la foto o non è content la tolgo
            if (mData.contentFiles.find((f) => f.name == img.name) || !['Content'].includes(img.service)) return;

            const myFile = {
               id: 'cv' + i,
               name: img.name,
               url: img.url,
               size: '',
               extension: myFunc.getExtension(img.name),
               origin: img.service,
               use: img.use,
               notes: '',
               approved: '',
               tags: '',
               supplier: '',
               idEpisode: '',
               episodeNumber: img.episodeNumber ? img.episodeNumber : '',
               episodeTitle: '',
               updated_at: '',
               width: img.width,
               height: img.height,
            };

            mData[source].push(myFile);
         });

      } else if (source == 'skyDamFiles') {
         images = await client.service('sky-dam-assets').find({
            query: {
               damId:{ $in: mData.skyDamIds },
               type: 'Image',
               $limit: 1000,
            }
         })
            .then(r => r.data);

         for (let img of images) {
            let url = img.downloadURL.replace(/https\:\/\/d3qp0rh9g8u1hc\.cloudfront\.net\//gi, 'https://tools.datatv.it/sky-dam-files/');
            let extension = myFunc.getExtension(img.downloadURL);
            let episode = mData.episodes.find(e => e.damId == img.damId);

            const myFile = {
               id: 'sd' + img.assetId,
               name: img.assetTitle + '.' + extension,
               url: url,
               extension: extension,
               origin: 'Canale',
               use: img.use,
               notes: img.notes,
               approved: img.isApproved ? 'sky' : 'ko',
               tags: '',
               supplier: 'SKY',
               idEpisode: episode ? episode.ID : '',
               episodeNumber: episode ? episode.episodioNumerico : '',
               episodeTitle: '',
               updated_at: img.lastUpdateDate,
               width: img.width ? img.width : '',
               height: img.height ? img.height : '',
               size: img.size ? img.size : '',
               uploaded: false,
               file_source: `skyDam_damId${img.damId}_AssetId${img.assetId}`
            };

            if (url && !myFile.size) {
               let fData = await client.service('image-get').get('processImage', {
                  query: {
                     uri: url
                  }
               });

               if (fData) {
                  myFile.size = fData.details.size;
                  myFile.width = fData.details.width;
                  myFile.height = fData.details.height;
               }
            }

            mData[source].push(myFile);
         }
      }

      console.log('getExternalFiles', source, mData[source]);
      return mData[source];
   }

   // renderFiles
   async function renderFiles(source, type) {

      const $loader = $(`
         <div class="ui active inverted dimmer modalLoader">
            <div class="ui indeterminate text loader">Reperimento Files</div>
         </div>
      `).appendTo($modal);

      // Incremento il timeout per i file molto grandi
      client.service('image-get').timeout = 60000;

      // Data
      let mData = $modal.data('modalData');
      let episodes = mData.episodes ? mData.episodes : [];
      let files = mData[source] ? mData[source] : [];

      // Carico i files esterni
      if (!files.length && ['internetFiles', 'contentFiles', 'skyDamFiles'].includes(source)) {
         files = await getExternalFiles(source);
      }

      console.log('render', source, type, files);

      // Selection
      let $tab = $modal.find(`.tab[data-tab="${source}"]`);
      let selection = $tab.find('.selected').map(function () {
         return $(this).attr('id');
      }).get();

      // Set menu
      $modal.find(`.right.menu.${source} .listView, .right.menu.${source} .iconView`).show();
      $modal.find(`.right.menu.${source} .${type}`).hide();

      // Reset table
      let dtOrder = [[1, 'asc']];
      if ($tab.find('.dataTable').length > 0) {
         let dt = $tab.find('.dataTable').DataTable();
         dtOrder = dt.order().slice(); // SHALLOW COPY
         dt.destroy();
      }
      $tab.find('table').remove();

      // Se non ci sono files
      if (!files || !files.length) {
         $tab.find('.emptyArea').show();
         $loader.remove();
         return;
      } else {
         $tab.find('.emptyArea').hide();

         let tabTitle = '';
         if (source == 'localFiles') tabTitle = 'File Locali';
         else if (source == 'serverFiles') tabTitle = 'File in Archivio';
         else if (source == 'internetFiles') tabTitle = 'File Internet';
         else if (source == 'contentFiles') tabTitle = 'File Schede Content';
         else if (source == 'skyDamFiles') tabTitle = 'File Sky Dam';
         $tab.find('h3').remove();
         $tab.prepend(`<h3 class="ui header">${tabTitle}</h3>`);

         if (type == 'listView') {
            renderListView();
         } else if (type == 'iconView') {
            renderIconView();
         }

         $loader.remove();

      };

      console.log('renderFiles: END');

      async function renderIconView() {
         // Genero le tabelle
         files.forEach(f => {
            let size = 250;
            let $fTable = $(`<table class="ui compact small single line table imageFile listaEl${source}" id="${f.id}"><tbody>
                  <tr>
                     <td style="position: relative;">
                        <div class="imageSelect" style="z-index: 1001;"></div>
                        <img src="https://tools.datatv.it/images/placeholderImage.png" height="${size}" ${source != 'localFiles' ? `data-down="${f.url}"` : ``}>
                     </td>
                  </tr>
                  <tr><td class="ellipsis"><b>${f.uploaded ? '<i class="ui blue archive icon"></i> ' : ''}${f.name}</b></td></tr>
                  <tr><td class="ellipsis">${f.extension ? `<b>${f.extension.toUpperCase()}</b> - ` : ''}${f.width ? `${f.width}x${f.height} - ` : ''}${f.size ? myFunc.readableBytes(f.size) : ''}</td></tr>
               </tbody></table>`).appendTo($tab);
         });

         // Carico le preview
         for (let f of files) {
            let size = 250;
            let $img = $modal.find(`#${f.id} img`);
            let $loader = $(`<div class="ui active inverted dimmer"><div class="ui text loader">Generazione Preview</div></div>`).appendTo($img.closest('td'));

            if (!f.url || !['jpg', 'png', 'webp', 'avif', 'tiff', 'gif', 'svg', 'psd'].includes(f.extension)) {
               $loader.html(`<div class="ui text"><i class="file alternate icon"></i>Preview non disponibile</div>`);

            } else if (f.url) {
               let ext = myFunc.getExtension(f.url);
               if (!['jpg', 'png', 'webp', 'avif', 'tiff', 'gif', 'svg', 'psd'].includes(ext)) continue;

               let fileName = f.url.substring(f.url.lastIndexOf('/') + 1, f.url.length);
               let destFileName = `${fileName}_autox${size}.jpg`;
               let destPath = `/mnt/tools.datatv.it/thumbnails/${destFileName}`;
               if (!$modal.resizing) $modal.resizing = [];

               if ($modal.resizing.includes(destPath)) {
                  $img.attr('src', `https://tools.datatv.it/thumbnails/${destFileName}`);
                  $loader.remove();

               } else if (!$modal.resizing.includes(destPath) && f.size < 536870912) {
                  $modal.resizing.push(destPath);

                  await client.service('image-get').get('processImage', {
                     query: {
                        uri: f.url,
                        destUri: destPath,
                        height: size
                     }
                  }).then(r => {
                     if (r && r.processed && r.processed.uri) {
                        $img.attr('src', r.processed.uri);
                        $loader.remove();
                     } else {
                        $loader.html(`<div class="ui text"><i class="ban icon"></i>Preview non riuscita</div>`);
                     }
                  }).catch(e => $loader.html(`<div class="ui text"><i class="ban icon"></i>Preview non riuscita</div>`));

               } else if (f.size >= 536870912) {
                  $loader.html(`<div class="ui text"><i class="ban icon"></i>Immagine troppo Grande</div>`);

               }
            }
         }

         // Seleziono i file
         $tab.find(selection.map(s => '#' + s).join(', ')).trigger('click');
      }

      function renderListView() {
         let $table = $(`<table class="ui small selectable celled table archivioDt" id="${source}Dt"></table>`).appendTo($tab);
         let columns = defineFileCols(Object.keys(files[0]));
         let dt = myDt.createTable($table, files, columns, 'id', 1000, true);

         myDt.initThumbnailPopUp('.imagePreview', `#${source}Dt`, 'url', 300);

         if (dtOrder) dt.order(dtOrder).draw();

         // SELECT richiede una domready function
         $(function () {
            dt.rows(selection.map((i) => '#' + i)).select();


            $('.tableSelAll').on('click', function (param) {
               if (dt.rows('.selected').toArray()[0].length) {
                  dt.rows().deselect();
               } else {
                  dt.rows().select();
               }
            })
         });

         function defineFileCols(colNames) {
            let cols = [];
            let myOrder = [
               'approved',
               'name',
               'idEpisode',
               'episodeNumber',
               'use',
               'tags',
               'origin',
               'supplier',
               'notes',
               'copyright',
               'width',
               'height',
               'size',
               'extension',
               'created_at',
               'updated_at',
               'created_by',
               'id',
            ];

            myOrder.forEach((n) => {
               if (!colNames.includes(n)) return;

               let col = { visible: false };

               if (n == 'name') {
                  col = {
                     title: 'Nome',
                     className: 'imagePreview dtvselect',
                     render: function (data, type, row, meta) {
                        let html = (row.uploaded ? '<i class="ui blue archive icon"></i>' : '') + data;

                        if (source == 'serverFiles') {
                           if (row.idEpisode && row.idEpisode != -1) {
                              const rep = episodes.find((e) => e.ID == row.idEpisode);
                              if (rep) html = `${rep.Turn ? `<span class="a_blue">${rep.Turn}</span> / ` : ''}${rep.Title}`;
                              else html = '<span class="a_red">Episodio non trovato!</span>';

                           } else html = 'Programma';
                        }

                        return html;
                     },
                  };
               }

               if (n == 'notes') {
                  col = {
                     title: 'Note',
                     className: 'change-notes',
                     render: function (data, type, row, meta) {
                        const html = '<div class="ui transparent input" style="width:120px;"><input type="text" placeholder="---" ' + (data ? 'value="' + data + '"' : '') + '></div>';
                        return html;
                     },
                  };
               }

               if (n == 'copyright') {
                  col = {
                     title: 'Copyright',
                     className: 'change-copyright',
                     render: function (data, type, row, meta) {
                        const html = '<div class="ui transparent input" style="width:80px;"><input type="text" placeholder="---" ' + (data ? 'value="' + data + '"' : '') + '></div>';
                        return html;
                     },
                  };
               }

               if (n == 'tags') {
                  col = {
                     title: 'Tags',
                     className: 'change-tags',
                     render: function (data, type, row, meta) {
                        const html = data ? data : '<span class="ui grey text opacita4">---</span>';
                        return html;
                     },
                  };
               }

               if (n == 'supplier') {
                  col = {
                     title: 'Fornitore',
                     className: 'change-supplier',
                     render: function (data, type, row, meta) {
                        const html = data ? data : '<span class="ui grey text opacita4">---</span>';
                        return html;
                     },
                  };
               }

               if (n == 'idEpisode' && episodes.length) {
                  col = {
                     title: 'Id Episode',
                     className: 'collapsing change-idEpisode',
                     render: function (data, type, row, meta) {
                        const html = data && data != '-1' ? data : '<span class="ui grey text opacita4">---</span>';
                        return html;
                     },
                  };
               }

               if (n == 'episodeNumber') {
                  col = {
                     title: 'n.Ep',
                     className: 'collapsing noPadding center aligned change-episodeNumber',
                     render: function (data, type, row, meta) {
                        let html = '<span class="myHidden">' + (data ? data : -1) + '</span>';
                        html += '<div class="ui transparent input" style="width:24px;"><input type="text" placeholder="---" ' + (data ? 'value="' + data + '"' : '') + '></div>';
                        return html;
                     },
                  };
               }

               if (n == 'origin') {
                  col = {
                     title: 'Origine',
                     className: 'collapsing change-origin',
                     render: function (data, type, row, meta) {
                        const html = data ? data : '<span class="ui grey text opacita4">---</span>';
                        return html;
                     },
                  };
               }

               if (n == 'use') {
                  col = {
                     title: 'Utilizzo',
                     className: 'collapsing change-use',
                     render: function (data, type, row, meta) {
                        const html = data ? data : '<span class="ui grey text opacita4">---</span>';
                        return html;
                     },
                  };
               }

               if (n == 'approved') {
                  col = {
                     title: 'A',
                     className: 'collapsing center aligned change-approved',
                     render: function (data, type, row, meta) {
                        let html = '<span class="myHidden">' + data + '</span>';
                        html += data ? approvedArr.find((a) => a.value == data).name : '<span class="ui grey text opacita4">---</span>';

                        return html;
                     },
                  };
               }

               if (n == 'width') {
                  col = {
                     title: 'W',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        const html = data ? data : '';
                        return html;
                     },
                  };
               }

               if (n == 'height') {
                  col = {
                     title: 'H',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        const html = data ? data : '';
                        return html;
                     },
                  };
               }

               if (n == 'size') {
                  col = {
                     title: 'Size',
                     className: 'collapsing',
                     type: 'numeric',
                     render: function (data, type, row, meta) {
                        if (!data) {
                           return '';
                        } else if (type === 'sort' || type === 'type') {
                           return data;
                        } else {
                           return myFunc.readableBytes(data);
                        }
                     },
                  };
               }

               if (n == 'extension') {
                  col = {
                     title: 'Ext',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        return data? data.toUpperCase() : '';
                     },
                  };
               }

               if (n == 'created_at') {
                  col = {
                     title: 'Creata',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        const html = '<span style="display:none">' + data + '</span>' + moment(data).format('DD/MM/YY HH:mm');
                        return html;
                     },
                  };
               }

               if (n == 'updated_at') {
                  col = {
                     title: 'Aggiornata',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        if (!data) return '';
                        const html = '<span style="display:none">' + data + '</span>' + moment(data).format('DD/MM/YY HH:mm');
                        return html;
                     },
                  };
               }

               if (n == 'created_by') {
                  col = {
                     title: 'U',
                     className: 'collapsing',
                     render: function (data, type, row, meta) {
                        const user = myVars.users.tutti.find((u) => u.id == data);
                        const html = `<img title="${user.nome + ' ' + user.cognome}" class="ui mini circular image" style="width:24px;" src="${mySite}/${user && user.avatar ? 'avatars/' + user.avatar : 'avatars/user.png'}">`;
                        return html;
                     },
                  };
               }

               if (n == 'id') {
                  col = {
                     title: '<i class="large grey fitted circle outline link icon tableSelAll"></i>',
                     orderable: false,
                     className: 'collapsing dtvselect-checkbox',
                     createdCell: function (td, cellData, rowData, row, col) {
                        if (source != 'localFiles' && rowData.url) $(td).attr('data-down', rowData.url);
                     },
                     render: function (data, type, row, meta) {
                        return '';
                     },
                  };
               }

               col.data = n;
               col.name = n;
               cols.push(col);
            });

            return { cols: cols };
         }
      }


   }

   function renderInfo() {
      const source = $modal.find('.item.active[data-tab]').attr('data-tab');
      const mData = $('#modalarchivio').data('modalData');
      const files = mData[source] ? mData[source] : [];
      const selection = $('.tab[data-tab="' + source + '"] .selected').map(function () {
         return $(this).attr('id');
      }).get();
      const selectedFiles = files.filter((f) => selection.includes(f.id) || selection.includes(f.id.toString()));

      const $info = $('.actions.info');
      let html = `<div class="opacita4" style="float: left;"><i class="info circle icon"></i>Il singolo upload deve essere minore di 50MB e 50 File.</div>
    Selezionati <b class="a_blue">${selectedFiles.length}</b> File di ${files.length}`;

      if (source == 'localFiles') {
         const bytes = selectedFiles.map((f) => f.size).reduce((a, b) => a + b, 0);
         if (bytes > 0) html += ` per <b class="a_blue">${myFunc.readableBytes(bytes)}</b>`;
      } else if (source == 'internetFiles') {
         html += ``;
      } else if (source == 'contentFiles') {
         html += ``;
      } else if (source == 'serverFiles') {
         const bytes = selectedFiles.map((f) => f.size).reduce((a, b) => a + b, 0);
         if (bytes > 0) html += ` per <b class="a_blue">${myFunc.readableBytes(bytes)}</b>`;
      }

      $info.html(html);
   }

   // ---------------------------------------------------------------------------------------------------------------------- UTILS
   // changeTdValue
   function changeTdValue($btd, newValue) {
      const mData = $('#modalarchivio').data('modalData');
      const source = $btd.closest('[data-tab]').attr('data-tab');
      const multi = $('.' + source + ' .multiMod input')[0].checked;
      const classe = $btd.attr('class');

      changeValue($btd);

      if (multi) {
         const $el = $('.tab[data-tab="' + source + '"]');
         const selection = $el.find('.selected').map(function () {
            return $(this).attr('id');
         }).get();

         selection.forEach((sel) => {
            const $std = $('#' + sel).find('[class="' + classe + '"]');
            if ($std.length == 1) changeValue($std);
         });
      }

      // changeValue
      function changeValue($td) {
         const imgI = $td.closest('[id]').attr('id');
         const imgO = mData[source].find((k) => k.id == imgI);
         const $tr = $td.closest('tr');
         const $dt = $td.closest('.dataTable');
         const dt = $dt.length > 0 ? $dt.DataTable() : false;

         const rowData = dt ? dt.row($tr).data() : false;

         console.log('changeTdValue', newValue, imgO);

         if (/change-idEpisode/.test(classe)) {
            if (newValue) {
               const epO = mData.episodes.find((e) => e.ID == newValue);
               imgO.idEpisode = newValue;
               imgO.episodeTitle = epO.Title;
               imgO.episodeNumber = epO.episodioNumerico;
            } else {
               imgO.idEpisode = '';
               imgO.episodeTitle = '';
               imgO.episodeNumber = '';
            }

            if (rowData) {
               rowData.idEpisode = imgO.idEpisode;
               rowData.episodeTitle = imgO.episodeTitle;
               rowData.episodeNumber = imgO.episodeNumber;
            } else {
               $td.html(imgO.idEpisode ? imgO.idEpisode : '<span class="ui grey text opacita4">---</span>');
               $td.closest('.imageFile').find('.change-episodeNumber input').val(imgO.episodeNumber ? imgO.episodeNumber : '');
            }
         } else if (/change-episodeNumber/.test(classe)) {
            imgO.idEpisode = '';
            imgO.episodeTitle = '';
            imgO.episodeNumber = newValue;

            if (rowData) {
               rowData.idEpisode = '';
               rowData.episodeTitle = '';
               rowData.episodeNumber = imgO.episodeNumber;
            } else {
               $td.html(imgO.idEpisode ? imgO.idEpisode : '<span class="ui grey text opacita4">---</span>');
            }
         } else if (/change-notes/.test(classe)) {
            imgO.notes = newValue;
            if (rowData) {
               rowData.notes = imgO.notes;
            } else {
               $td.html(imgO.notes ? imgO.notes : '<span class="ui grey text opacita4">---</span>');
            }
         } else if (/change-copyright/.test(classe)) {
            imgO.copyright = newValue;
            if (rowData) {
               rowData.copyright = imgO.copyright;
            } else {
               $td.html(imgO.copyright ? imgO.copyright : '<span class="ui grey text opacita4">---</span>');
            }
         } else if (/change-approved/.test(classe)) {
            imgO.approved = newValue;
            if (rowData) rowData.approved = newValue;
            else $td.html(newValue ? approvedArr.find((a) => a.value == newValue).name : '<span class="ui grey text opacita4">---</span>');
         } else {
            const rex = /\bchange\-([a-z]+)\b/.exec(classe);
            const toChange = rex && rex[1] ? rex[1] : false;

            if (toChange) {
               imgO[toChange] = newValue;
               if (rowData) rowData[toChange] = newValue;
               else $td.html(newValue ? newValue : '<span class="ui grey text opacita4">---</span>');
            }
         }

         if (rowData) dt.row($tr).invalidate();
         if (source == 'serverFiles') updateServerFile(imgO.id, $tr);
      }
   }

   // guessFiles
   function guessFiles(selection) {
      const mData = $('#modalarchivio').data('modalData');
      const files = mData.localFiles;
      const selectedFiles = files.filter((f) => selection.includes(f.id) || selection.includes(f.id.toString()));

      // Per il Match
      const eps = data.episodes; // ep.Title: "Lazio - Roma"
      const rexEp = /(?:\_|\-|\b)(?:episodio|episode|eps|ep|e)\.?\-?\s?(\d{1,})(?:\_|\-|\b)/i;
      const rexClean = /[^A-Z0-9]/gi;

      const useArr = [

         ['Poster-V-Logo', '_VL', 'LOC'],
         ['Poster-V', '_V', 'LOC'],
         ['Poster-O-Logo-219', '_OL2', 'ORIZ'],
         ['Poster-O-Logo', '_OL', 'ORIZ'],
          ['Poster-O', '_O', 'BACK'],
          ['Hero', '_H', 'HERO'],
         ['Logo', '_L', 'LOGO'],
         ['Scena', '_S', 'SCENA'],
      ];

      selectedFiles.forEach((sf) => {
         let episodeNumber = false;
         let idEpisode = false;
         let use = false;

         // Match EP Title
         const cleanName = sf.name.replace(rexClean, '');
         let matchLen = 0; // Prendo il match più lungo
         eps.forEach((ep) => {
            // if (idEpisode || !ep.Title || ep.Title.length < 3) return;
            if (!ep.Title || ep.Title.length < 3) return;
            const cleanTitle = ep.Title.replace(rexClean, '');
            const rexMatch = RegExp(cleanTitle, 'i');
            // console.log(eps, cleanTitle, '==', cleanName);
            if (rexMatch.test(cleanName) && cleanTitle.length > matchLen) {
               matchLen = cleanTitle.length;
               idEpisode = ep.ID;
               episodeNumber = ep.episodioNumerico ? ep.episodioNumerico : false;
            }
         });

         // Match Ep Number
         if (!episodeNumber) {
            const matchEp = rexEp.exec(sf.name);
            if (matchEp && matchEp[1]) {
               episodeNumber = matchEp[1];
               const foundEp = eps.find((e) => e.episodioNumerico == episodeNumber);
               if (foundEp) idEpisode = foundEp.ID;
            }
         }

         // Match Use
         useArr.forEach((ua) => {
            ua.forEach((ui) => {
               const rexUse = RegExp(ui + '(?:\\_|\\b)', 'i');
               // console.log(rexUse, sf.name)
                if (!use && rexUse.test(sf.name)) use = ua[0];
            });
         });

         if (episodeNumber) sf.episodeNumber = episodeNumber;
         if (idEpisode) sf.idEpisode = idEpisode;
         if (use) sf.use = use;

         console.log(episodeNumber, idEpisode, use);
      });

      renderFiles('localFiles', 'listView');
   }

   // getRequiredImgs
   function getRequiredImgs(data) {
      const requiredImg = [];

      data.content.forEach((r) => {
         let ric = r.ethanFlag ? 'SKY' : 'TIM';
         if (/nowtv/i.test(r.ethanMetadatasets)) ric += ', NOW';
         if (/kids/i.test(r.ethanMetadatasets)) ric += ', KIDS';
         if (/dazn/i.test(r.ethanMetadatasets)) ric += ', DAZN';

         if (r.attributo && r.attributo.imgRichieste) {
            ric += `(${r.attributo.imgRichieste})`;
         }

         if (!requiredImg.includes(ric)) requiredImg.push(ric);
      });

      // if (data.vod.length > 0) requiredImg.push('VOD');
      return requiredImg;
   }

   // ---------------------------------------------------------------------------------------------------------------------- GENERATE

   function generateImages(action, singleEp) {
      const mData = $modal.data('modalData');
      const filesObj = mData.serverFiles;
      const idSelected = $modal.find('.tab.active[data-tab] tr.selected').eq(0).attr('id');
      const fileSelected = filesObj ? filesObj.find((fo) => fo.id == idSelected) : false;
      console.log('***\t generateImages:', action, mData, filesObj, idSelected, fileSelected);

      // Show Menu
      $modal.find('.archivioMenu .right.menu').hide();
      $modal.find('.archivioMenu .menu.generatedFiles').show();
      $modal.find('.archivioMenu > .item').removeClass('active');

      // Change Tab
      $modal.find('.segment[data-tab]').hide();
      const $tab = $modal.find('#generatedFiles');
      $tab.show();

      // Clear Old Messages
      generatedFilesMessage('clear');

      // Action
      if (action !== 'generateShowTab') $tab.find('.emptyArea').hide();

      if (action == 'generateBaseFormats') generateBaseFormats();
      // else if (action == 'generateVod') generateVod();
      // else if (['generateVodEps', 'generateVodEpsNep', 'generateVodEpsNepKids'].includes(action)) generateVodEps(action, singleEp);
      // else if(['generateEp', 'generateEpKids'].includes(action)) generateEp(action);
      else if (action == 'generateEpOptions') generateEpOptions();

      // Formati Base
      function generateBaseFormats() {
         $tab.find('.generatedFormats').remove();
         const $group = $(`<div class="ui basic segment generatedFormats area-immagini"></div>`).appendTo($tab);
         const prF = _myCanvas.defaultFormats.filter((f) => ['imageE', 'imageF', 'imageG', 'imageH', 'imageJ', 'imageK', 'imageL', 'imageC', 'imageQ'].includes(f.id));
         let doneImg = 0;

         (async () => {
            for (let i = 0; i < prF.length; i++) {
               const format = prF[i];
               $group.append(`<div class="baseFormat bfo_${format.id}"></div>`);

               const f = Object.assign({}, format);
               f.selector = `.bfo_${format.id}`;
               f.maxHeight = 200;
               f.progInfo = {};

               console.log(mData);
               const myCanvas = await _myCanvas.init(f).then((mc) => mc[0]);
               myCanvas.addProgramInfo(mData, false, 'program-join');

               myCanvas.settings.progInfo.extension = 'jpg';
               myCanvas.settings.progInfo.notes = '';
               myCanvas.settings.progInfo.approved = 'ko';
               myCanvas.settings.progInfo.origin = '';
               myCanvas.settings.progInfo.supplier = '';
               myCanvas.settings.progInfo.tags = '';
               myCanvas.settings.progInfo.use = format.uses[0];
               myCanvas.activeLayer = 'bg';
               doneImg++;
            }

            await $('.baseFormat')[0].myCanvas.imageWizard($('.baseFormat').toArray());
            addGroupControl($group, `${doneImg} Immagini Programma Generate`);
         })();
      }

      // VOD Programma
      function generateVod() {
         $tab.find('.generatedFormats').remove();
         const $group = $(`<div class="ui basic segment generatedFormats area-immagini"></div>`).appendTo($tab);
         const prF = _myCanvas.defaultFormats.filter((f) => f.service == 'Sky Vod' || f.service == 'Sky Primafila');
         let doneImg = 0;

         (async () => {
            for (let i = 0; i < prF.length; i++) {
               const format = Object.assign({}, prF[i]);
               let imageO;

               // 6/12/2019 Eccezzioni VB / VL
               // Se è un programma 1-23 VB=Scena,Poster-O / VL=Poster-O,Scena
               // Se è un programma senza episodi VB/VL=Scena,Poster-O,Poster-O-Logo
               // Se è un programma con episodi VB/VL=Poster-O,Scena,Poster-O-Logo

               if ([1, 23].includes(mData.program.IDCategory)) {
                  if (format.id == 'imageVB') format.uses = ['Scena', 'Poster-O'];
                  if (format.id == 'imageVL') format.uses = ['Poster-O', 'Scena'];
               } else if (!mData.episodes || !mData.episodes.length) {
                  if (['imageVB', 'imageVL'].includes(format.id)) format.uses = ['Scena', 'Poster-O', 'Poster-O-Logo'];
               } else if (mData.episodes && mData.episodes.length) {
                  if (['imageVB', 'imageVL'].includes(format.id)) format.uses = ['Poster-O', 'Scena', 'Poster-O-Logo'];
               }

               format.uses.forEach((u) => {
                  // Prima vedo se c'è una foto con le stesse specifiche e le stesse proporzioni. (Pirchio 13/01/2021)
                  if (!imageO) imageO = filesObj.find((img) => img.use == u && img.approved && !['ko', 'now'].includes(img.approved) && (img.width / img.height == format.width / format.height) && img.width > format.width * 0.75 && img.tags != 'Da Lavorare' && (!img.idEpisode || img.idEpisode == -1));
                  if (!imageO) imageO = filesObj.find((img) => img.use == u && img.approved && !['ko', 'now'].includes(img.approved) && img.width > format.width * 0.75 && img.tags != 'Da Lavorare' && (!img.idEpisode || img.idEpisode == -1));
               });

               console.log('Image Found:', format.id, format.uses, imageO);
               $group.append(`<div class="gfo_${format.id}"></div>`);

               const f = Object.assign({}, format);
               f.selector = `.gfo_${format.id}`;
               f.maxHeight = 200;
               f.progInfo = {};

               const myCanvas = await _myCanvas.init(f).then((mc) => mc[0]);
               myCanvas.addProgramInfo(mData, false, 'program-join');

               if (imageO) {
                  await myCanvas.insertImage(imageO.url, 'max');
                  if (imageO.copyright) await myCanvas.macroCopyRight(imageO.copyright);

                  // National Geographic Channel 16, 551
                  // Nat Geo Temp 366
                  // Nat Geo Wild 460, 872
                  // Nat Geo People 264
                  // Discovery Channel 84
                  // Discovery Science 269
                  // History 263, 365, 550
                  // C+I 727
                  // Blaze 804
                  // National Geographic Plus OD4
                  // Love Nature OD3

                  if (format.id == 'imageVA') {
                     const getprog = await client.service('program-join').get(mData.idProgram, { query: { add: 'onAirChannel' } });
                     const chId = getprog.onAirChannel;

                     console.log(mData, chId);

                     if (/Love Nature/gi.test(myCanvas.settings.progInfo.canali)) {
                        await myCanvas.macroChannelLogo(false, 'OD3');
                     } else if (/National Geographic Plus/gi.test(myCanvas.settings.progInfo.canali)) {
                        await myCanvas.macroChannelLogo(false, 'OD4');
                     } else if ([16, 551, 366, 460, 872, 264, 84, 269, 263, 365, 550, 727, 804].includes(chId)) {
                        await myCanvas.macroChannelLogo(false, chId);
                     }
                  }
               }

               myCanvas.settings.progInfo.extension = 'jpg';
               myCanvas.settings.progInfo.notes = '';
               myCanvas.settings.progInfo.approved = 'vod';
               myCanvas.settings.progInfo.origin = 'Auto';
               myCanvas.settings.progInfo.supplier = imageO ? imageO.supplier : '';
               myCanvas.settings.progInfo.tags = '';
               myCanvas.settings.progInfo.use = format.uses[0];

               doneImg++;
            }

            addGroupControl($group, `${doneImg} Immagini Programma Generate`);
         })();
      }

      // VOD Episodi WIZARD
      function generateVodEps(action, singleEp) {
         if (!singleEp) $tab.find('.generatedFormatsEps').remove();
         const $group = $(`<div class="ui basic segment generatedFormatsEps area-immaginiEps"></div>`).appendTo($tab);
         const epF = _myCanvas.defaultFormats.filter((f) => f.episodic && (f.service == 'Sky Vod' || f.service == 'Sky Primafila'));
         let doneImg = 0;

         let eps = mData.episodes;
         if (singleEp) eps = mData.episodes.filter((ep) => ep.ID == singleEp);

         // Aggiungo locandina ai formati
         // if(locandina) epF.unshift(_myCanvas.defaultFormats.find(f => f.id == 'imageVA'));

         (async () => {
            for (let e = 0; e < eps.length; e++) {
               const ep = eps[e];
               let doneEp = 0;

               for (let i = 0; i < epF.length; i++) {
                  const format = epF[i];
                  const uClass = 'gfep_' + format.id + ep.ID;
                  // let imageO = filesObj.find(img => img.idEpisode == ep.ID && img.approved && img.approved != 'ko' && img.use == 'Scena' && img.width > format.width * 0.75);
                  // if(!imageO) imageO = filesObj.find(img => img.idEpisode == ep.ID && img.approved && img.approved != 'ko' && img.use == 'Poster-O' && img.width > format.width * 0.75);
                  // if(!imageO) imageO = filesObj.find(img => img.idEpisode == ep.ID && img.approved && img.approved != 'ko' && img.use == 'Poster-O-Logo' && img.width > format.width * 0.75);

                  // if(!imageO) continue;

                  // Se on c'è neanche una immagine per questo episodio la salto
                  // if(!singleEp && !filesObj.find(i => i.idEpisode == ep.ID)) continue;

                  // Se c'è già una immagine per l'episodio la salto
                  if (!singleEp && filesObj.find((i) => i.idEpisode == ep.ID && i.approved == 'vod' && i.width == format.width)) continue;

                  $group.append(`<div class="${uClass}"></div>`);

                  const f = Object.assign({}, format);
                  f.name = (ep.episodioNumerico ? ep.episodioNumerico + ' ' : ep.Turn ? ep.Turn + ' ' : '') + ep.Title;
                  f.selector = '.' + uClass;
                  f.maxHeight = 109;
                  f.progInfo = {};

                  const myCanvas = await _myCanvas.init(f).then((mc) => mc[0]);
                  myCanvas.addProgramInfo(mData, ep, 'program-join');

                  // myCanvas.insertImage(imageO.url, 'max');
                  await $('.' + uClass)[0].myCanvas.imageWizard($('.' + uClass).toArray(), ['Archivio', 'SportImages']);

                  myCanvas.settings.progInfo.extension = 'jpg';
                  myCanvas.settings.progInfo.notes = '';
                  myCanvas.settings.progInfo.approved = 'vod';
                  myCanvas.settings.progInfo.origin = 'Auto';
                  myCanvas.settings.progInfo.supplier = '';// imageO.supplier;
                  myCanvas.settings.progInfo.tags = '';
                  myCanvas.settings.progInfo.use = format.uses[0];// imageO.use;

                  if (action == 'generateVodEpsNep') myCanvas.macroEpisodeNumber(false, 'norm');
                  if (action == 'generateVodEpsNepKids') myCanvas.macroEpisodeNumber(false, 'kids');

                  myCanvas.activeLayer = 'bg';

                  doneImg++;
                  doneEp++;
               }

               if (doneEp > 0) $group.append(`<br>`);
            }

            addGroupControl($group, `${doneImg} Immagini Episodio Generate`, true);
         })();
      }

      // VOD Episodi da selezione con Opzioni
      async function generateEpOptions() {
         if (!fileSelected) {
            generatedFilesMessage('info', 'info', 'Seleziona prima una immagine', 'Devi selezionare una immagine da usare come base per generare le immagini con numero episodio.');
            return;
         }

         const opt = await getOptions();
         if (!opt) return;
         console.log('generateEpOptions', opt);

         $tab.find('.generatedEps').remove();
         const $group = $(`<div class="ui basic segment generatedEps"></div>`).appendTo($tab);
         const format = _myCanvas.defaultFormats.find((f) => f.id == 'imageVL');
         let eps = mData.episodes;
         let startEp = 0;
         let endEp = 0;
         let doneImg = 0;

         if (opt.eps == 'free') {
            if (!opt.num) opt.num = 1;

            if (opt.num.indexOf('-') > -1) {
               startEp = opt.num.split('-')[0];
               endEp = opt.num.split('-')[1];
            }


            eps = new Array(parseInt(opt.num)).fill({});
            eps = eps.map((v, i) => {
               return {
                  ID: 'free_' + (i + 1),
                  episodioNumerico: (i + 1),
               };
            });
         }

         (async () => {
            for (let i = 0; i < eps.length; i++) {
               const ep = eps[i];
               if (!ep.episodioNumerico) continue;

               // Se solo i mancanti e c'è già una immagine per l'episodio la salto
               if (opt.mis && filesObj.find((i) => i.episodeNumber == ep.episodioNumerico && i.approved == 'vod' && i.width == format.width)) continue;

               $group.append(`<div class="gep_${ep.ID}"></div>`);

               const f = Object.assign({}, format);
               f.name = ep.episodioNumerico + ' ' + (ep.Title ? ep.Title : '');
               f.selector = `.gep_${ep.ID}`;
               f.maxHeight = 109;
               f.progInfo = {};

               const myCanvas = await _myCanvas.init(f).then((mc) => mc[0]);
               const img = fileSelected.localFile ? await myCanvas.readFiles(fileSelected.localFile).then((f) => f[0]) : fileSelected.url;

               if (!img) return;

               await myCanvas.insertImage(img, 'max');
               if (fileSelected.copyright) await myCanvas.macroCopyRight(fileSelected.copyright);

               if (opt.eps != 'free') myCanvas.addProgramInfo(mData, ep, 'program-join');

               myCanvas.settings.progInfo.episode = ep.episodioNumerico;

               myCanvas.settings.progInfo.extension = 'jpg';
               myCanvas.settings.progInfo.notes = '';
               myCanvas.settings.progInfo.approved = 'vod';
               myCanvas.settings.progInfo.origin = 'Auto';
               myCanvas.settings.progInfo.supplier = fileSelected.supplier;
               myCanvas.settings.progInfo.tags = '';
               myCanvas.settings.progInfo.use = fileSelected.use;

               myCanvas.macroEpisodeNumber(false, opt.tipo == 'kids' ? 'kids' : 'norm');
               myCanvas.activeLayer = 'bg';
               doneImg++;
            }

            addGroupControl($group, `${doneImg} Immagini Episodio Generate`);
         })();

         // Modal Options
         function getOptions() {
            return optPromise = new Promise((resolve, reject) => {
               const opt = {};

               $('#epOptionsModal').remove();
               const $optModal = $(`
            <div class="ui mini modal" id="epOptionsModal">
              <div class="header">
                Come vuoi generare gli episodi?
              </div>
              <div class="content">

                <div class="ui form">
                  <div class="field">
                    <label>Tipo Etichetta</label>
                    <div class="ui selection dropdown">
                      <input type="hidden" name="tipo">
                      <i class="dropdown icon"></i>
                      <div class="default text">Normale</div>
                      <div class="menu">
                        <div class="item" data-value="norm">Etichetta Normale</div>
                        <div class="item" data-value="kids">Etichetta Kids</div>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>Cosa vuoi generare?</label>
                    <div class="ui selection dropdown epsDrop">
                      <input type="hidden" name="eps">
                      <i class="dropdown icon"></i>
                      <div class="default text">Normale</div>
                      <div class="menu">
                        <div class="item" data-value="all">Tutti Gli Episodi</div>
                        <div class="item" data-value="mis">Solo gli Episodi Mancanti</div>
                        <div class="item" data-value="free">Un numero arbitrario di episodi</div>
                      </div>
                    </div>
                  </div>
                  <div class="field freeNfield">
                    <label>Quanti Episodi?</label>
                    <input type="number" name="num" placeholder="Numero Episodi">
                  </div>
              </div>

              </div>
              <div class="actions">
                <div class="ui red button ko">Annulla</div>
                <div class="ui green button ok">Genera</div>
              </div>
            </div>`).appendTo('body');

               $optModal.find('.freeNfield').hide();
               $optModal.find('.ui.dropdown').dropdown();
               $optModal.find('.epsDrop').dropdown({
                  onChange: function (value, text, $choice) {
                     if (value == 'free') $optModal.find('.freeNfield').show();
                     else $optModal.find('.freeNfield').hide();
                  },
               });

               $optModal.modal({ allowMultiple: true });
               $optModal.modal('show');

               $optModal.on('click', '.button.ko', (e) => {
                  $optModal.modal('hide');
                  resolve(false);
               });

               $optModal.on('click', '.button.ok', (e) => {
                  $optModal.modal('hide');
                  opt.tipo = $optModal.find('[name="tipo"]').val();
                  opt.eps = $optModal.find('[name="eps"]').val();
                  opt.num = $optModal.find('[name="num"]').val();

                  resolve(opt);
               });
            });
         }
      }

      // VOD Episodi da selezione
      // function generateEp(action){
      //   $tab.find('.generatedEps').remove();
      //   const $group = $(`<div class="ui basic segment generatedEps"></div>`).appendTo($tab);
      //   const format = _myCanvas.defaultFormats.find(f => f.id == 'imageVL')
      //   const eps = mData.episodes;
      //   let doneImg = 0;

      //   const epsN = eps.find(ep => ep.episodioNumerico);

      //   if(!fileSelected) {
      //     generatedFilesMessage('info', 'info', 'Seleziona prima una immagine', 'Devi selezionare una immagine da usare come base per generare le immagini con numero episodio.');
      //     return;
      //   }
      //   if(!epsN){
      //     generatedFilesMessage('info', 'warning', 'Nessun Numero Episodio', 'In database non è presente nessun episodio con il numero per questo programma.');
      //     return;
      //   }

      //   (async () => {
      //     for (let i = 0; i < eps.length; i++) {
      //       const ep = eps[i];
      //       if(!ep.episodioNumerico) continue;

      //       // Se c'è già una immagine per l'episodio la salto
      //       if(filesObj.find(i => i.episodeNumber == ep.episodioNumerico && i.approved == 'vod' && i.width == format.width)) continue;

      //       $group.append(`<div class="gep_${ep.ID}"></div>`);

      //       const f = Object.assign({}, format);
      //       f.name = ep.episodioNumerico + ' ' + ep.Title;
      //       f.selector = `.gep_${ep.ID}`;
      //       f.maxHeight = 109;
      //       f.progInfo = {};

      //       var myCanvas = await _myCanvas.init(f).then(mc => mc[0]);
      //       const img = fileSelected.localFile ? await myCanvas.readFiles(fileSelected.localFile).then(f=>f[0]) : fileSelected.url;

      //       if(!img) return;

      //       myCanvas.insertImage(img, 'max');
      //       myCanvas.addProgramInfo(mData, ep, 'program-join');

      //       myCanvas.settings.progInfo.extension = 'jpg';
      //       myCanvas.settings.progInfo.notes = '';
      //       myCanvas.settings.progInfo.approved = 'vod';
      //       myCanvas.settings.progInfo.origin = 'Auto';
      //       myCanvas.settings.progInfo.supplier = fileSelected.supplier;
      //       myCanvas.settings.progInfo.tags = '';
      //       myCanvas.settings.progInfo.use = fileSelected.use;

      //       myCanvas.macroEpisodeNumber(false, action == 'generateEpKids' ? 'kids' : 'norm');
      //       myCanvas.activeLayer = 'bg';
      //       doneImg++;
      //     }

      //     addGroupControl($group, `${doneImg} Immagini Episodio Generate`);
      //   })();
      // }

      // Controlli
      function addGroupControl($group, groupTitle, addLocBtn) {
         const $gMenu = $(`
        <div class="ui menu">
          <span class="header item"><i class="layer group icon"></i>${groupTitle}</span>
          <a class="item genBgCopy"><i class="clone icon"></i>Copia Posizione</a>
          ${addLocBtn ? '<a class="item genAddLoc"><i class="layers group icon"></i>Aggiungi Locandina</a>' : ''}

          <div class="right menu">
            <a class="item genGroupCheck"><i class="check fitted icon"></i></a>
            <a class="item genGroupUnCheck"><i class="ban fitted icon"></i></a>
            <a class="item genGroupDownload"><i class="fitted download icon"></i></a>
            <a class="item genGroupDel"><i class="fitted trash alternate outline icon"></i></a>
          </div>
        </div>`).prependTo($group);

         $group.on('click', '.genAddLoc', function (e) {
            const mcs = $group.find('._myCanvas');

            mcs.each(async (i, mc) => {
               const myCan = mc.myCanvas;
               const uClass = 'gfep_imageVA' + myCan.settings.progInfo.idEpisode;
               if ($('.' + uClass).length) return;

               const format = _myCanvas.defaultFormats.find((f) => f.id == 'imageVA');
               $(mc).before(`<div class="${uClass}"></div>`);

               const f = Object.assign({}, format);
               f.name = myCan.settings.name;
               f.selector = '.' + uClass;
               f.maxHeight = myCan.settings.maxHeight;
               f.progInfo = myCan.settings.progInfo;

               const myCanvas = await _myCanvas.init(f).then((mc) => mc[0]);
               await myCanvas.imageWizard($('.' + uClass).toArray(), ['Archivio', 'SportImages']);
               const layerToDel = myCanvas.layers.find((l) => l.type == 'channel');
               myCanvas.deleteLayer(layerToDel.name);
               // Teen Titans Go! vs. Teen Titans
            });
         });

         $group.on('click', '.genGroupDownload', function (e) {
            const mcs = $group.find('._myCanvas');
            mcs.each((i, mc) => {
               const myCan = mc.myCanvas;
               if (!['No'].includes(myCan.state)) {
                  myCan.download();
               }
            });
         });

         $group.on('click', '.genGroupCheck', function (e) {
            const mcs = $group.find('._myCanvas');
            mcs.each((i, mc) => {
               const myCan = mc.myCanvas;
               if (!['No', 'Db', 'Ok'].includes(myCan.state) && !myCan.areLayersBlank()) {
                  myCan.setState('Ok');
               }
            });
         });

         $group.on('click', '.genGroupUnCheck', function (e) {
            const mcs = $group.find('._myCanvas');
            mcs.each((i, mc) => {
               const myCan = mc.myCanvas;
               if (!['No', 'Db', 'Ko'].includes(myCan.state)) {
                  myCan.setState('kO');
               }
            });
         });

         $group.on('click', '.genGroupUpload', function (e) {
            const selection = $group.find('._myCanvas').toArray().map((mc) => mc.myCanvas).filter((mc) => mc.state == 'Ok' && !mc.areLayersBlank());
            uploadMyCanvases(selection);
         });

         $group.on('click', '.genBgCopy', function (e) {
            const mcs = $group.find('._myCanvas');
            const firstCanBg = mcs[0].myCanvas.copyLayer('bg');
            mcs.each((i, mc) => {
               const myCan = mc.myCanvas;
               myCan.pasteLayer('bg', firstCanBg);
            });
         });

         $group.on('click', '.genGroupDel', function (e) {
            $group.remove();
         });
      }
   }

   // generatedFilesMessage
   function generatedFilesMessage(icon, color, title, text) {
      const $tab = $modal.find('#generatedFiles');

      if (icon == 'clear') {
         $tab.find('.generatedFilesMessages').remove();
         return;
      }

      const $messArea = $tab.find('.generatedFilesMessages').length ?
         $tab.find('.generatedFilesMessages') :
         $(`<div class="ui basic segment generatedFilesMessages"></div>`).prependTo($tab);

      const $mess = $(`
        <div class="ui icon ${color} small message generatedFilesMess" style="margin: 0;">
          <i class="${icon} icon"></i>
          <div class="content">
            <div class="header">${title}</div>
            <p>${text}</p>
          </div>
        </div>`);

      $mess.prependTo($messArea);
   }
}
