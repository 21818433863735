module.exports = {
    smallScreen: window.matchMedia('screen and (max-width:1679px)').matches,
    settings: {
        searchLang: 'IT'
    },
    users: {
        load: function () {
            return client.service('users').find()
                .then((r) => r.data)
                .then(function (data) {
                    myVars.users.tutti = data;

                    // Ordino per cognome
                    myVars.users.tutti.sort(function (a, b) {
                        const textA = a.cognome.toUpperCase();
                        const textB = b.cognome.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });

                    return (myVars.users.tutti);
                });
        },
        tutti: {},
    },
    channels: {
        load: async function () {

            let data, ibms;

            client.service('ibms-channels').timeout = 30000;
            client.service('channels').timeout = 30000;

            const promises = [
                client.service('ibms-channels').find({
                    query: { $sort: { 'clean': 1 } }
                }).then((r) => { ibms = r.data }),

                client.service('channels').find({
                    query: {
                        $limit: 1000,
                        $sort: { Name: 1 },
                        $select: [
                            'DefaultAttributeMask',
                            'Description',
                            'ExportMask',
                            'ExportStartTime',
                            'ID',
                            'IDCategory',
                            'IDGroup',
                            'Name',
                            'OrderNumber',
                            'ProssimoPassaggioFlag',
                            'ShowDefaultCategory',
                            'ShowOnTheWeb',
                            'baseChannel',
                            'dataFinePP',
                            'dataInizioPP',
                            'displacement',
                            'listingsFlag',
                            'nomePP',
                            'ppvFlag',
                            'programmiMediXgiorno',
                            'remoteControlNumber',
                            'showOnMagazineSatellite',
                            'showOnSatellite',
                            'sinossiSport',
                            'telecomandoDT',
                            'tipo',
                            'broadcaster',
                        ]
                    }
                }).then((r) => { data = r.data })
            ]

            await Promise.all(promises).catch(err => console.log(err));

            // SET
            myVars.channels.full = data;
            myVars.channels.tutti = data.filter(c => c.showOnSatellite == 1);
            myVars.channels.ibms = ibms;
            myVars.channels.ibmsActive = ibms.filter((ch) => ch.active);
            myVars.channels.skyCinema = myVars.channels.tutti.filter((c) => /Sky Cinema/gi.test(c.Name));
            myVars.channels.skySport = myVars.channels.tutti.filter((c) => /Sky Sport/gi.test(c.Name));
            myVars.channels.fox = myVars.channels.tutti.filter((c) => /^fox/gi.test(c.Name));
            myVars.channels.national = myVars.channels.tutti.filter((c) => /^nat\s/gi.test(c.Name));
            myVars.channels.discovery = myVars.channels.tutti.filter((c) => /^Discovery\s/gi.test(c.Name));

            return data;
        },

        addUsersChannels: function () {
            myVars.users.tutti.forEach((u) => {
                if (!u.canali) return;
                const uCanali = JSON.parse(u.canali);
                const canali = myVars.channels.tutti.filter((c) => uCanali.includes(c.ID.toString()));
                myVars.channels['user_' + u.nome + ' ' + u.cognome] = canali;
            });
        },

        logos: async function () {
            const channels = myVars.channels.tutti ? myVars.channels.tutti : await myVars.channels.load().then(() => myVars.channels.tutti);
            const logos = await client.service('channelsimages').find().then((res) => res.data);

            console.log(channels, logos);
        },
    },
    attributes: {
        load: function () {
            return client.service('attributes').find({ query: { $sort: { Name: 1 }, $select: ['ID', 'Name', 'bitPosition'] } })
                .then((r) => r.data)
                .then(function (data) {
                    myVars.attributes.tutti = data;
                    return (data);
                });
        },
        tutti: {},
    },
    categories: {
        load: function () {
            return client.service('category').find({ query: { $select: ['ID', 'Name', 'WebColor'] } })
                .then((r) => r.data)
                .then(function (data) {
                    myVars.categories.tutti = data;
                    return (data);
                });
        },
        tutti: {},
    },
    content: {
        load: function () {
            return client.service('content').find({ query: { $sort: { Name: 1 }, $select: ['ID', 'Name'] } })
                .then((r) => r.data)
                .then(function (data) {
                    myVars.content.tutti = data;
                    return (data);
                });
        },
        tutti: {},
    },
    country: {
        load: function () {
            return client.service('country').find({ query: { $sort: { Name: 1 }, $select: ['ID', 'Name', 'ISO2CharCode'] } })
                .then((r) => r.data)
                .then(function (data) {
                    myVars.country.tutti = data;
                    return (data);
                });
        },
        tutti: {},
    },
    genre: {
        load: function () {
            return client.service('genre').find({ query: { $select: ['ID', 'Name'] } })
                .then((r) => r.data)
                .then(function (data) {
                    myVars.genre.tutti = data;
                    return (data);
                });
        },
        tutti: {},
    },
    noteLavorazione: [
        { name: 'No Foto', type: 'pre', icon: 'write' },
        { name: 'Attesa Dati', type: 'pre', icon: 'write' },
        { name: 'Attesa Foto', type: 'pre', icon: 'write' },
        { name: 'Verifica Dati', type: 'pre', icon: 'write' },
        { name: 'Verifica Foto', type: 'pre', icon: 'write' },
        { name: 'Stand-By', type: 'pre', icon: 'write' },
        { name: 'Corr. Dati', type: 'post', icon: 'flag' },
        { name: 'Corr. Foto', type: 'post', icon: 'flag' },
        { name: 'Corr. Struttura', type: 'post', icon: 'flag' },
        { name: 'Richiesta Test', type: 'mix', icon: 'wrench' },
        { name: 'Op. Batch', type: 'mix', icon: 'laptop code' },
    ],
    immaginiRichieste: [
        { name: 'No Episodiche', type: 'img', icon: 'image' },
        { name: 'O', type: 'img', icon: 'image' },
        { name: 'H-O', type: 'img', icon: 'image' },
        { name: 'V-O', type: 'img', icon: 'image' },
        { name: 'V-H-O', type: 'img', icon: 'image' },
    ],
    metadataSets: [
        { name: 'NOW', value: 'nowtv', color: 'teal' },
        { name: 'KIDS', value: 'skykids', color: 'orange' },
        { name: 'UHD', value: 'uhd', color: 'grey', channelGroupID: 21 },
        { name: 'TIMCATCHUP', value: 'TimCatchup', color: 'violet' },
        { name: 'FUSION', value: 'fusion', color: 'grey' },
        { name: 'DAZN', value: 'dazn', color: 'black', channelGroupID: 90 },
        { name: 'ALT IMG 1', value: 'dyn_img_1', color: 'yellow' },
        { name: 'ALT IMG 2', value: 'dyn_img_2', color: 'yellow' },
        { name: 'TVOD', value: 'tvod', color: 'brown' },
        { name: 'Caccia e Pesca', value: 'Caccia e Pesca', color: 'olive' },
        { name: 'ADULT', value: 'Adult', color: 'pink' },
        { name: 'SKY-RAI', value: 'Sky-RAI', color: 'grey' },
        { name: 'LEI-DOVE', value: 'LeiDove', color: 'grey' },
        { name: 'SKY-COMCAST', value: 'Sky-Comcast', color: 'grey' },
        { name: 'TURNER', value: 'turner', color: 'orange' },
    ],
    stations: [
        { name: 'Cielo' },
        { name: 'Cinema and PPV' },
        { name: 'Sky Arte' },
        { name: 'Sky Atlantic' },
        { name: 'Sky Documentaries' },
        { name: 'Sky Investigation' },
        { name: 'Sky Nature' },
        { name: 'Sky News' },
        { name: 'Sky Serie' },
        { name: 'Sky Sports' },
        { name: 'Sky Uno' },
        { name: 'VOD Contents' },
    ]
};
