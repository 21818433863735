// DTV namespace
global._dtv = {
   imgCanvas: require('./dtv/imgCanvas/imgCanvas'),
   myTabulator: require('./dtv/myTabulator/myTabulator'),
   listing: require('./dtv/listing'),
};



// Globals Scripts
global.myError = require('./datatv/myError');
global.myVars = require('./datatv/myVars');
global.myAuth = require('./datatv/myAuth');
global.myFunc = require('./datatv/myFunc');
global.myChat = require('./datatv/myChat');
global.myDt = require('./datatv/myDt');
global.myDataTable = require('./datatv/myDataTable');
global.myPops = require('./datatv/myPops');
global.myWrites = require('./datatv/myWrites');
global.myListings = require('./datatv/myListings');
global.myLog = require('./datatv/myLog');
global.myImageReport = require('./datatv/myImageReport');
global.myAssegnazioni = require('./datatv/myAssegnazioni');
global.myProgramSearch = require('./datatv/myProgramSearch');
global.myRemoteCommands = require('./datatv/myRemoteCommands');

// Classes
// global.myImportPalinsesti = require('./datatv/myImportPalinsesti').myImportPalinsesti;

// MYCANVAS
global._myCanvas = require('./datatv/myCanvas/myCanvas');

require('./datatv/myCanvas/myCanvas.css');

// MODALS
global.modalSkyDam = require('./datatv/modalSkyDam');
global.modalArchivio = require('./datatv/modalArchivio');
global.modalImageChooser = require('./datatv/modalImageChooser');
global.modalTrame = require('./datatv/modalTrame');
global.modalUsers = require('./datatv/modalUsers');
global.modalSelf = require('./datatv/modalSelf');
global.modalSchedaDb = require('./datatv/modalSchedaDb');
global.modalInvioEpg = require('./datatv/modalInvioEpg');

// MODALS CLICK
document.addEventListener('click', function (e) {

   const mSkyDam = e.target.closest('[data-modalskydam]');
   const mArchivio = e.target.closest('[data-modalarchivio]');
   const mImageChooser = e.target.closest('[data-modalimagechooser]');
   const mTrame = e.target.closest('[data-modaltrame]');
   const mUser = e.target.closest('[data-modaluser]');
   const mSelf = e.target.closest('[data-modalself]');
   const mInvioEpg = e.target.closest('[data-modalinvioepg]');

   // Permessi
   if (!myVars || !myVars.io) return;
   let adminPermission = ['Admin', 'Supervisor'].includes(myVars.io.ruolo);
   let redattorePermission = ['Redattore'].includes(myVars.io.ruolo) && myVars.io.area.indexOf('Satellite') > -1;
   let graficoPermission = ['Grafico'].includes(myVars.io.ruolo);

   if (mSkyDam) {
      if (!adminPermission && !redattorePermission && !graficoPermission) return;
      e.preventDefault();
      e.stopPropagation();
      const id = mSkyDam.getAttribute('data-modalskydam');
      const modalpage = mSkyDam.getAttribute('data-modalpage');
      global.modalSkyDam(id, modalpage);
   }

   if (mArchivio) {
      if (!adminPermission && !graficoPermission) return;
      e.preventDefault();
      e.stopPropagation();
      const id = mArchivio.getAttribute('data-modalarchivio').split(',')[0];
      let modalpage = mArchivio.getAttribute('data-modalpage');
      if (!modalpage) modalpage = mArchivio.getAttribute('data-modalarchivio').split(',')[1];
      global.modalArchivio(id, modalpage);
   }

   if (mImageChooser) {
      e.preventDefault();
      e.stopPropagation();
      const ids = mImageChooser.getAttribute('data-modalimagechooser').split(',');
      const idProgram = ids[0];
      const idEpisode = ids[1] ? ids[1] : -1;
      global.modalImageChooser(idProgram, idEpisode);
   }

   if (mTrame) {
      if (!adminPermission && !redattorePermission) return;
      e.preventDefault();
      e.stopPropagation();
      const ids = mTrame.getAttribute('data-modalTrame').split(',');
      const idProgram = ids[0];
      const idEpisode = ids[1] ? ids[1] : -1;
      global.modalTrame(idProgram, idEpisode);
   }

   if (mUser) {
      e.preventDefault();
      e.stopPropagation();
      if (!['Admin', 'Supervisor', 'Grafico'].includes(myVars.io.ruolo)) return;
      const id = mUser.getAttribute('data-modaluser');
      global.modalUsers(id);
   }

   if (mSelf) {
      const id = mSelf.getAttribute('data-modalself');
      global.modalSelf(id);
   }

   if (mInvioEpg) {
      e.preventDefault();
      e.stopPropagation();
      const vars = mInvioEpg.getAttribute('data-modalinvioepg').split(',');

      if (vars && vars.length) global.modalInvioEpg.open(...vars);
      else global.modalInvioEpg.open();
   }
});

myError.init();
// AUTHENTICATED uso authenticated al posto di document ready perchè arriva dopo
client.on('authenticated', (e) => {
   myVars.io = e.user;
   console.log(`Authenticated! as ${myVars.io.ruolo}`);
   if (typeof myFunc != 'undefined') myFunc.copyClipboardInit();
   if (typeof myLog != 'undefined') myLog.init();
   if (typeof myChat != 'undefined' && !['External'].includes(e.user.ruolo)) myChat.init();
   if (typeof myImageReport != 'undefined' && ['Admin', 'Grafico'].includes(e.user.ruolo)) myImageReport.init();
   if (typeof myProgramSearch != 'undefined') myProgramSearch.init();
   if (typeof myRemoteCommands != 'undefined') myRemoteCommands.init();
   if (typeof modalSchedaDb != 'undefined') modalSchedaDb.init();

   // RESIZE
   $(window).on('resize', function () {
      var win = $(this); //this = window
      var matched = false;

      if (win.width() <= 1679) {
         matched = true;
      }

      var smallScreenEvent = new CustomEvent('smallScreen', { bubbles: false, detail: matched });
      myVars.smallScreen = matched;
      window.dispatchEvent(smallScreenEvent);
   });

   // CONTROL
   var ctrlPressed = false;
   $(window).keydown(function (evt) {
      if (evt.which == 17) { // ctrl
         myVars.ctrl = true;
      }
   }).keyup(function (evt) {
      if (evt.which == 17) { // ctrl
         myVars.ctrl = false;
      }
   });

   // SIDE MENU
   $('#sideMenu').sidebar({
      transition: 'overlay',
      dimPage: false
   }).sidebar('attach events', '.sidebar_toggle');

   // LOGOUT
   $('body').on('click', '.userLogout', function () {
      myFunc.elLoader(true);
      myAuth.logout();
   });

   // MIX
   $('.ui.dropdown.userMenu').dropdown();
   // $('.ui.checkbox').checkbox();

});

// CSS
import './stylesheets/datatv.css';