(function (root, factory) {
   if (typeof define === 'function' && define.amd) {
      define([], factory(root));
   } else if (typeof exports === 'object') {
      module.exports = factory(root);
   } else {
      root._myCanvas = factory(root);
   }
})(typeof global !== 'undefined' ? global : this.window || this.global, function (root) {
   'use strict';

   // Variables
   const window = root; // Map window to root to avoid confusion
   const document = window.document;
   const _myCanvas = { // Placeholder for public methods
      windowResizeEvent: null,
      accessToken: null,
      fonts: null,
      mySite: 'https://tools.datatv.it',
      loadedUrls: {},
      loadedImages: {},
      shadowTemplates: {
         // IMPOSTARE I VALORI PER IL FORMATO imageE 600x800
         // Poi verrà scalato dallo script per gli altri formati
         'none': {
            shadowColor: '#000000',
            shadowAlpha: 0,
            shadowX: 0,
            shadowY: 0,
            shadowBlur: 0,
         },
         'halo': {
            shadowColor: '#000000',
            shadowAlpha: 30,
            shadowX: 0,
            shadowY: 0,
            shadowBlur: 3,
         },
         'mini': {
            shadowColor: '#000000',
            shadowAlpha: 30,
            shadowX: 1,
            shadowY: 1,
            shadowBlur: 0,
         },
         'tiny': {
            shadowColor: '#000000',
            shadowAlpha: 30,
            shadowX: 2,
            shadowY: 2,
            shadowBlur: 2,
         },
         'small': {
            shadowColor: '#000000',
            shadowAlpha: 40,
            shadowX: 3,
            shadowY: 3,
            shadowBlur: 3,
         },
         'medium': {
            shadowColor: '#000000',
            shadowAlpha: 100,
            shadowX: 0,
            shadowY: 1,
            shadowBlur: 5,
         },
         'large': {
            shadowColor: '#000000',
            shadowAlpha: 30,
            shadowX: 8,
            shadowY: 8,
            shadowBlur: 4,
         },
         'huge': {
            shadowColor: '#000000',
            shadowAlpha: 100,
            shadowX: 0,
            shadowY: 0,
            shadowBlur: 150,
         },
      },
      // IMPOSTARE I VALORI PER IL FORMATO imageE 600x800
      // Poi verrà scalato dallo script per gli altri formati
      titleTemplates: {
         'Sky Q': {
            idCh: [],
            font: 'skytext 800',
            dy_size: 80,
            dy_lineHeight: 66,
            allCaps: 1,
            fontSub: 'skytext 400',
            dy_sizeSub: 54,
            dy_lineHeightSub: 66,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'left',
            yAlign: 'bottom',
            gradAlpha: 75,
            dy_marginX: 25,
            dy_marginY: 44,
            shadow: 'medium',
         },
         'Sky Scene Key Art': {
            idCh: [],
            font: 'skytext 800',
            dy_size: 80,
            dy_lineHeight: 66,
            allCaps: 1,
            fontSub: 'skytext 400',
            dy_sizeSub: 54,
            dy_lineHeightSub: 66,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'bottom',
            gradAlpha: 80,
            dy_marginX: 25,
            dy_marginY: 44,
            shadow: 'medium',
         },
         'Sky TIM': {
            idCh: [],
            font: 'skytext 800',
            dy_size: 60,
            dy_lineHeight: 50,
            allCaps: 1,
            fontSub: 'skytext 400',
            dy_sizeSub: 54,
            dy_lineHeightSub: 50,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'bottom',
            gradAlpha: 75,
            dy_marginX: 25,
            dy_marginY: 44,
            shadow: 'medium',
         },
         'TIM': {
            idCh: [],
            font: 'Futura-Bold',
            dy_size: 55,
            dy_lineHeight: 55,
            allCaps: 1,
            fontSub: 'Futura-Bold',
            dy_sizeSub: 40,
            dy_lineHeightSub: 40,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'bottom',
            gradAlpha: 50,
            dy_marginX: 35,
            dy_marginY: 35,
            shadow: 'mini',
         },
         'Vodafone': {
            idCh: [],
            font: 'skytext 800',
            dy_size: 55,
            dy_lineHeight: 55,
            allCaps: 0,
            fontSub: 'skytext 800',
            dy_sizeSub: 55,
            dy_lineHeightSub: 55,
            allCapsSub: 0,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'top',
            gradAlpha: 30,
            dy_marginX: 40,
            dy_marginY: 58,
            shadow: 'mini',
         },
         'Blaze': {
            idCh: [804],
            font: 'Futura-Extrabold-Condensed-Oblique',
            dy_size: 75,
            dy_lineHeight: 75,
            allCaps: 1,
            fontSub: 'Futura-Medium-Condensed-Oblique',
            dy_sizeSub: 55,
            dy_lineHeightSub: 55,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'top',
            gradAlpha: 30,
            dy_marginX: 35,
            dy_marginY: 35,
            shadow: 'none',
         },
         'C+I': {
            idCh: [727],
            font: 'Flama-Bold',
            dy_size: 60,
            dy_lineHeight: 60,
            allCaps: 1,
            fontSub: 'Flama-Bold',
            dy_sizeSub: 36,
            dy_lineHeightSub: 36,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'top',
            gradAlpha: 30,
            dy_marginX: 35,
            dy_marginY: 35,
            shadow: 'none',
         },
         'History': {
            idCh: [263, 365, 550],
            font: 'Tungsten-Bold',
            dy_size: 75,
            dy_lineHeight: 75,
            allCaps: 1,
            fontSub: 'Tungsten-Bold',
            dy_sizeSub: 36,
            dy_lineHeightSub: 36,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'top',
            gradAlpha: 30,
            dy_marginX: 35,
            dy_marginY: 35,
            shadow: 'none',
         },
         'Discovery': {
            idCh: [84, 268, 403, 270],
            font: 'Gotham-Bold',
            dy_size: 55,
            dy_lineHeight: 55,
            allCaps: 1,
            fontSub: 'Gotham-Bold',
            dy_sizeSub: 32,
            dy_lineHeightSub: 32,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'top',
            gradAlpha: 30,
            dy_marginX: 35,
            dy_marginY: 35,
            shadow: 'none',
         },
         'Discovery Science': {
            idCh: [269],
            font: 'AvenirNext-Bold',
            dy_size: 55,
            dy_lineHeight: 55,
            allCaps: 1,
            fontSub: 'AvenirNext-Bold',
            dy_sizeSub: 32,
            dy_lineHeightSub: 32,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'top',
            gradAlpha: 30,
            dy_marginX: 35,
            dy_marginY: 35,
            shadow: 'none',
         },
         'Nat Geo Channel': {
            idCh: [16, 551],
            font: 'NGC-Din-onAir-Black',
            dy_size: 50,
            dy_lineHeight: 50,
            allCaps: 1,
            fontSub: false,
            dy_sizeSub: false,
            dy_lineHeightSub: false,
            allCapsSub: false,
            color: '#F9DB00',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'top',
            gradAlpha: 30,
            dy_marginX: 35,
            dy_marginY: 35,
            shadow: 'none',
         },
         'Nat Geo Wild': {
            idCh: [460, 872],
            font: 'Futura-Bold',
            dy_size: 55,
            dy_lineHeight: 55,
            allCaps: 1,
            fontSub: 'Futura-Bold',
            dy_sizeSub: 30,
            dy_lineHeightSub: 30,
            allCapsSub: 1,
            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'top',
            gradAlpha: 30,
            dy_marginX: 35,
            dy_marginY: 35,
            shadow: 'none',
         },
         'Caccia AMZ': {
            // idCh: [712, 709],
            idCh: [],

            font: 'Manrope-Extrabold',
            dy_size: 68,
            dy_lineHeight: 75,
            allCaps: 1,

            fontSub: 'Manrope-Medium',
            dy_sizeSub: 40,
            dy_lineHeightSub: 60,
            allCapsSub: 1,

            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'bottom',
            gradAlpha: 100,
            dy_marginX: 35,
            dy_marginY: 40,
            shadow: 'none',
         },
         'Caccia AMZ Left': {
            // idCh: [712, 709],
            idCh: [],

            font: 'Manrope-Extrabold',
            dy_size: 60,
            dy_lineHeight: 65,
            allCaps: 0,

            fontSub: 'Manrope-Medium',
            dy_sizeSub: 40,
            dy_lineHeightSub: 45,
            allCapsSub: 0,

            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'left',
            yAlign: 'bottom',
            gradAlpha: 100,
            dy_marginX: 50,
            dy_marginY: 90,
            shadow: 'none',
         },
         'Caccia AMZ ST': {
            // idCh: [712, 709],
            idCh: [],

            font: 'Manrope-Extrabold',
            dy_size: 68,
            dy_lineHeight: 75,
            allCaps: 1,

            fontSub: 'Manrope-Medium',
            dy_sizeSub: 32,
            dy_lineHeightSub: 64,
            allCapsSub: 1,
            fontSubColor: '#FEA629',

            color: '#FFFFFF',
            alpha: 100,
            xAlign: 'center',
            yAlign: 'bottom',
            gradAlpha: 100,
            dy_marginX: 35,
            dy_marginY: 40,
            shadow: 'none',
         }

      },
      sportTemplates: {
         'Template Partite 2018': {
            'imageE': {
               team1: {
                  x: 70,
                  y: 350,
                  w: 200,
               },
               team2: {
                  x: 330,
                  y: 350,
                  w: 200,
               },
            },
            'imageF': {
               team1: {
                  x: 826,
                  y: 100,
                  w: 250,
               },
               team2: {
                  x: 1376,
                  y: 100,
                  w: 250,
               },
            },
            'imageG': {
               team1: {
                  x: 284,
                  y: 284,
                  w: 488,
               },
               team2: {
                  x: 1150,
                  y: 284,
                  w: 488,
               },
            },
            'imageH': {
               team1: {
                  x: 284,
                  y: 284,
                  w: 488,
               },
               team2: {
                  x: 1150,
                  y: 284,
                  w: 488,
               },
            },
            'imageI': {
               team1: {
                  x: 128,
                  y: 237,
                  w: 256,
               },
               team2: {
                  x: 640,
                  y: 237,
                  w: 256,
               },
            },
            'imageJ': {
               team1: {
                  x: 108,
                  y: 650,
                  w: 360,
               },
               team2: {
                  x: 594,
                  y: 650,
                  w: 360,
               },
            },
            // 'imageK': {
            //    team1: {
            //       x: 188,
            //       y: 205,
            //       w: 327,
            //    },
            //    team2: {
            //       x: 767,
            //       y: 205,
            //       w: 327,
            //    },
            // },
            'imageL': {
               team1: {
                  x: 45,
                  y: 300,
                  w: 150,
               },
               team2: {
                  x: 247,
                  y: 300,
                  w: 150,
               },
            },
         },
         'Template Partite 2019': {
            'imageE': {
               team1: {
                  x: 54,
                  y: 475,
                  w: 218,
               },
               team2: {
                  x: 328,
                  y: 475,
                  w: 218,
               },
               turn: {
                  x: 0,
                  y: 303,
                  w: 600,
                  h: 104,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 42,
               },
            },
            'imageF': {
               team1: {
                  x: 1142,
                  y: 48,
                  w: 300,
               },
               team2: {
                  x: 1456,
                  y: 48,
                  w: 300,
               },
               turn: false,
            },
            'imageG': {
               team1: {
                  x: 992,
                  y: 34,
                  w: 352,
               },
               team2: {
                  x: 1350,
                  y: 34,
                  w: 352,
               },
               turn: {
                  x: 195,
                  y: 488,
                  w: 642,
                  h: 220,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 86,
               },
            },
            'imageH': {
               team1: {
                  x: 992,
                  y: 34,
                  w: 352,
               },
               team2: {
                  x: 1350,
                  y: 34,
                  w: 352,
               },
               turn: {
                  x: 195,
                  y: 488,
                  w: 642,
                  h: 220,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 86,
               },
            },
            'imageI': {
               team1: {
                  x: 533,
                  y: 22,
                  w: 240,
               },
               team2: {
                  x: 773,
                  y: 22,
                  w: 240,
               },
               turn: {
                  x: 2,
                  y: 334,
                  w: 420,
                  h: 136,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 57,
               },
            },
            'imageJ': {
               team1: {
                  x: 96,
                  y: 934,
                  w: 394,
               },
               team2: {
                  x: 590,
                  y: 934,
                  w: 394,
               },
               turn: {
                  x: 258,
                  y: 626,
                  w: 563,
                  h: 192,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 74,
               },
            },
            // 'imageK': {
            //    team1: {
            //       x: 662,
            //       y: 24,
            //       w: 246,
            //    },
            //    team2: {
            //       x: 910,
            //       y: 24,
            //       w: 246,
            //    },
            //    turn: {
            //       x: 108,
            //       y: 340,
            //       w: 444,
            //       h: 160,
            //       xAlign: 'center',
            //       yAlign: 'top',
            //       color: '#FFFFFF',
            //       alpha: 100,
            //       size: 60,
            //    },
            // },
            'imageL': {
               team1: {
                  x: 40,
                  y: 392,
                  w: 164,
               },
               team2: {
                  x: 246,
                  y: 392,
                  w: 164,
               },
               turn: {
                  x: 106,
                  y: 262,
                  w: 238,
                  h: 76,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 31,
               },
            },
            'imageVA': {
               team1: {
                  x: 32,
                  y: 314,
                  w: 131,
               },
               team2: {
                  x: 196,
                  y: 314,
                  w: 131,
               },
               turn: {
                  x: 85,
                  y: 210,
                  w: 190,
                  h: 60,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 25,
               },
            },
            'imageVB': {
               team1: {
                  x: 497,
                  y: 17,
                  w: 176,
               },
               team2: {
                  x: 676,
                  y: 17,
                  w: 176,
               },
               turn: {
                  x: 97,
                  y: 245,
                  w: 322,
                  h: 112,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 43,
               },
            },
            'imageVL': {
               team1: {
                  x: 102,
                  y: 4,
                  w: 36,
               },
               team2: {
                  x: 139,
                  y: 4,
                  w: 36,
               },
               turn: {
                  x: 20,
                  y: 50,
                  w: 66,
                  h: 18,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 9,
               },
            },
         },
         'Template Partite 2021': {
            'imageE': {
               team1: {
                  x: 125,
                  y: 425,
                  w: 146,
               },
               team2: {
                  x: 330,
                  y: 425,
                  w: 146,
               },
               turn: {
                  x: 0,
                  y: 313,
                  w: 600,
                  h: 104,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 34,
               },
            },
            'imageF': {
               team1: {
                  x: 1142,
                  y: 48,
                  w: 300,
               },
               team2: {
                  x: 1456,
                  y: 48,
                  w: 300,
               },
               turn: false,
            },
            'imageG': {
               team1: {
                  x: 528,
                  y: 364,
                  w: 286,
               },
               team2: {
                  x: 1108,
                  y: 364,
                  w: 286,
               },
               turn: {
                  x: 0,
                  y: 315,
                  w: 1920,
                  h: 100,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 42,
               },
            },
            'imageH': {
               team1: {
                  x: 528,
                  y: 364,
                  w: 286,
               },
               team2: {
                  x: 1108,
                  y: 364,
                  w: 286,
               },
               turn: {
                  x: 0,
                  y: 315,
                  w: 1920,
                  h: 100,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 42,
               },
            },
            'imageI': {
               team1: {
                  x: 223,
                  y: 246,
                  w: 192,
               },
               team2: {
                  x: 612,
                  y: 246,
                  w: 192,
               },
               turn: {
                  x: 0,
                  y: 212,
                  w: 1024,
                  h: 60,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 27,
               },
            },
            'imageJ': {
               team1: {
                  x: 223,
                  y: 845,
                  w: 264,
               },
               team2: {
                  x: 594,
                  y: 845,
                  w: 264,
               },
               turn: {
                  x: 258,
                  y: 644,
                  w: 563,
                  h: 160,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 60,
               },
            },
            // 'imageK': {
            //    team1: {
            //       x: 342,
            //       y: 254,
            //       w: 198,
            //    },
            //    team2: {
            //       x: 744,
            //       y: 254,
            //       w: 198,
            //    },
            //    turn: {
            //       x: 0,
            //       y: 218,
            //       w: 1280,
            //       h: 64,
            //       xAlign: 'center',
            //       yAlign: 'top',
            //       color: '#FFFFFF',
            //       alpha: 100,
            //       size: 30,
            //    },
            // },
            'imageL': {
               team1: {
                  x: 93,
                  y: 354,
                  w: 110,
               },
               team2: {
                  x: 248,
                  y: 354,
                  w: 110,
               },
               turn: {
                  x: 0,
                  y: 269,
                  w: 450,
                  h: 75,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 24,
               },
            },
         },
         'Template Partite 2022': {
            'imageE': {
               team1: {
                  x: 48,
                  y: 515,
                  w: 232,
               },
               team2: {
                  x: 316,
                  y: 515,
                  w: 232,
               },
               turn: {
                  x: 0,
                  y: 307,
                  w: 600,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 33,
               },
            },
            // 'imageF': {
            //    team1: {
            //       x: 1142,
            //       y: 48,
            //       w: 300,
            //    },
            //    team2: {
            //       x: 1456,
            //       y: 48,
            //       w: 300,
            //    },
            //    turn: false,
            // },
            'imageG': {
               team1: {
                  x: 232,
                  y: 208,
                  w: 334,
               },
               team2: {
                  x: 615,
                  y: 208,
                  w: 334,
               },
               turn: {
                  x: 1018,
                  y: 554,
                  w: 700,
                  h: 200,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 60,
               },
            },
            'imageH': {
               team1: {
                  x: 232,
                  y: 208,
                  w: 334,
               },
               team2: {
                  x: 615,
                  y: 208,
                  w: 334,
               },
               turn: {
                  x: 1018,
                  y: 554,
                  w: 700,
                  h: 200,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 60,
               },
            },
            'imageI': {
               team1: {
                  x: 20,
                  y: 140,
                  w: 225,
               },
               team2: {
                  x: 288,
                  y: 140,
                  w: 225,
               },
               turn: {
                  x: 563,
                  y: 375,
                  w: 450,
                  h: 200,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 41,
               },
            },
            'imageJ': {
               team1: {
                  x: 88,
                  y: 1006,
                  w: 420,
               },
               team2: {
                  x: 572,
                  y: 1006,
                  w: 420,
               },
               turn: {
                  x: 0,
                  y: 614,
                  w: 1072,
                  h: 170,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 67,
               },
            },
            // 'imageK': {
            //    team1: {
            //       x: 134,
            //       y: 146,
            //       w: 230,
            //    },
            //    team2: {
            //       x: 412,
            //       y: 146,
            //       w: 230,
            //    },
            //    turn: {
            //       x: 645,
            //       y: 386,
            //       w: 560,
            //       h: 140,
            //       xAlign: 'center',
            //       yAlign: 'top',
            //       color: '#FFFFFF',
            //       alpha: 100,
            //       size: 42,
            //    },
            // },
            'imageL': {
               team1: {
                  x: 35,
                  y: 422,
                  w: 176,
               },
               team2: {
                  x: 236,
                  y: 422,
                  w: 176,
               },
               turn: {
                  x: 0,
                  y: 258,
                  w: 447,
                  h: 75,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 28,
               },
            },
         },
         'Template Partite 2023': {
            'imageE': {
               team1: {
                  x: 74,
                  y: 470,
                  w: 208,
               },
               team2: {
                  x: 316,
                  y: 470,
                  w: 208,
               },
               turn: {
                  x: 0,
                  y: 307,
                  w: 600,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 33,
               },
            },
            'imageG': {
               team1: {
                  x: 102,
                  y: 184,
                  w: 400,
               },
               team2: {
                  x: 560,
                  y: 184,
                  w: 400,
               },
               turn: {
                  x: 1018,
                  y: 554,
                  w: 700,
                  h: 200,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 60,
               },
            },
            'imageH': {
               team1: {
                  x: 232,
                  y: 208,
                  w: 334,
               },
               team2: {
                  x: 615,
                  y: 208,
                  w: 334,
               },
               turn: {
                  x: 1018,
                  y: 554,
                  w: 700,
                  h: 200,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 60,
               },
            },
            'imageI': {
               team1: {
                  x: 20,
                  y: 140,
                  w: 225,
               },
               team2: {
                  x: 288,
                  y: 140,
                  w: 225,
               },
               turn: {
                  x: 563,
                  y: 375,
                  w: 450,
                  h: 200,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 41,
               },
            },
            'imageJ': {
               team1: {
                  x: 86,
                  y: 936,
                  w: 420,
               },
               team2: {
                  x: 570,
                  y: 936,
                  w: 420,
               },
               turn: {
                  x: 0,
                  y: 614,
                  w: 1072,
                  h: 170,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 67,
               },
            },
            'imageL': {
               team1: {
                  x: 35,
                  y: 422,
                  w: 176,
               },
               team2: {
                  x: 236,
                  y: 422,
                  w: 176,
               },
               turn: {
                  x: 0,
                  y: 258,
                  w: 447,
                  h: 75,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 28,
               },
            },
         },
         'Champions League Remix': {
            'imageE': {
               team1: false,
               team2: false,
               turn: {
                  x: 50,
                  y: 632,
                  w: 500,
                  h: 65,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 60,
               },
            },
            'imageF': {
               team1: false,
               team2: false,
               turn: false,
            },
            'imageG': {
               team1: false,
               team2: false,
               turn: {
                  x: 1086,
                  y: 336,
                  w: 650,
                  h: 110,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 102,
               },
            },
            'imageH': {
               team1: false,
               team2: false,
               turn: {
                  x: 1086,
                  y: 336,
                  w: 650,
                  h: 110,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 102,
               },
            },
            'imageI': {
               team1: false,
               team2: false,
               turn: {
                  x: 470,
                  y: 226,
                  w: 500,
                  h: 70,
                  xAlign: 'right',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 69,
               },
            },
            'imageJ': {
               team1: false,
               team2: false,
               turn: {
                  x: 140,
                  y: 1215,
                  w: 800,
                  h: 200,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 108,
               },
            },
            // 'imageK': {
            //    team1: false,
            //    team2: false,
            //    turn: {
            //       x: 705,
            //       y: 235,
            //       w: 500,
            //       h: 80,
            //       xAlign: 'center',
            //       yAlign: 'top',
            //       color: '#FFFFFF',
            //       alpha: 100,
            //       size: 71,
            //    },
            // },
            'imageL': {
               team1: false,
               team2: false,
               turn: {
                  x: 25,
                  y: 508,
                  w: 400,
                  h: 50,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 45,
               },
            },
            'imageVA': {
               team1: false,
               team2: false,
               turn: {
                  x: 85,
                  y: 210,
                  w: 190,
                  h: 30,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 25,
               },
            },
            'imageVB': {
               team1: false,
               team2: false,
               turn: {
                  x: 97,
                  y: 245,
                  w: 322,
                  h: 56,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 43,
               },
            },
            'imageVL': {
               team1: false,
               team2: false,
               turn: {
                  x: 20,
                  y: 50,
                  w: 66,
                  h: 9,
                  xAlign: 'center',
                  yAlign: 'top',
                  color: '#FFFFFF',
                  alpha: 100,
                  size: 9,
               },
            },
         },
      },
      defaultFormats: [{
         service: 'All',
         id: 'masterVL',
         name: 'Poster-V-Logo',
         uses: ['Poster-V-Logo'],
         width: 1280,
         height: 1920,
      }, {
         service: 'All',
         id: 'masterV',
         name: 'Poster-V',
         uses: ['Poster-V'],
         width: 1280,
         height: 1920,
      }, {
         service: 'All',
         id: 'masterOL',
         name: 'Poster-O-Logo',
         uses: ['Poster-O-Logo'],
         width: 1920,
         height: 1080,
      }, {
         service: 'All',
         id: 'masterO',
         name: 'Poster-O',
         uses: ['Poster-O'],
         width: 1920,
         height: 1080,
      }, {
         service: 'All',
         id: 'masterH',
         name: 'Background',
         uses: ['Hero'],
         width: 1920,
         height: 1080,
      }, {
         service: 'All',
         id: 'masterS',
         name: 'Scena',
         uses: ['Scena'],
         width: 1920,
         height: 1080,
      }, {
         service: 'All',
         id: 'masterL',
         name: 'Logo',
         uses: ['Logo'],
         width: 2880,
         height: 2880,
      }, {
         service: 'All',
         id: 'customA',
         name: '164x242',
         uses: ['Poster-V-Logo'],
         width: 164,
         height: 242,
      }, {
         service: 'All',
         id: 'Person',
         name: 'Person',
         uses: ['Character'],
         width: 600,
         height: 900,
      }, {
         service: 'All',
         id: 'imageDTT',
         name: 'Foto DTT',
         uses: ['Poster-O-Logo', 'Poster-O', 'Scena'],
         width: 1920,
         height: 1080,
         safeArea: 'safeArea-imageDTT.png',
      }, {
         service: 'All',
         id: 'PunchOut',
         name: 'Punch Out',
         uses: ['Character'],
         width: 400,
         height: 400,
      }, {
         service: 'Timvision',
         id: 'imageA',
         name: 'Locandina',
         uses: ['Poster-V-Logo', 'Poster-V'],
         width: 600,
         height: 900,
      }, {
         service: 'Timvision',
         id: 'imageC',
         name: 'Cover',
         uses: ['Poster-O-Logo', 'Poster-O'],
         width: 800,
         height: 600,
         programLogo: true,
      }, {
         service: 'Timvision',
         id: 'imageD',
         name: 'Background',
         episodic: true,
         uses: ['Poster-O', 'Scena'],
         width: 1920,
         height: 1080,
      }, {
         service: 'Timvision',
         id: 'imageB',
         name: 'Scena Tim',
         episodic: true,
         uses: ['Scena'],
         width: 800,
         height: 600,
      }, {
         service: 'Sky',
         id: 'imageE',
         name: 'Cover',
         uses: ['Poster-V-Logo', 'Poster-V'],
         width: 600,
         height: 800,
         channelLogo: [{
            coverage: 3.5,
            x: 'center',
            y: 24,
            xr: false,
            yr: false,
         }],
      }, {
         service: 'Sky',
         id: 'imageF',
         name: 'Background',
         uses: ['Hero'],
         width: 1920,
         height: 1080,
         safeArea: 'safeArea-imageF.png',
      }, {
         service: 'Sky',
         id: 'imageG',
         name: 'Scene',
         episodic: true,
         uses: ['Poster-O', 'Scena'],
         width: 1920,
         height: 1080,
         safeArea: 'safeArea-imageG.png',
         },
         // {
         // service: 'Sky',
         // id: 'imageH',
         // name: 'Now Land 16:9',
         // episodic: true,
         // flags: 'now,kids',
         // uses: ['Poster-O', 'Scena'],
         // width: 1920,
         // height: 1080,
         // safeArea: 'safeArea-imageH.png',
         // },
         {
            service: 'Sky',
            id: 'imageI',
            name: 'Now Land 4:3',
            episodic: true,
            flags: 'now',
            uses: ['Poster-O', 'Scena'],
            width: 1024,
            height: 730,
            safeArea: 'safeArea-imageI.png',
         },
         {
         service: 'Sky',
         id: 'imageJ',
         name: 'Now Boxart',
         flags: 'now,fusion',
         uses: ['Poster-V-Logo', 'Poster-V'],
         width: 1080,
         height: 1600,
         channelLogo: [{
            coverage: 4,
            x: 'center',
            y: 48,
            xr: false,
            yr: false,
         }],
      }, {
         service: 'Sky',
         id: 'imageK',
         name: 'Vodafone Background',
         // 13/10/2022 tolta episodica
         episodic: false,
         flags: 'now',
         // uses: ['Scena', 'Poster-O', 'Poster-O-Logo'],
         uses: ['Hero'],
         width: 1280,
         height: 752,
         channelLogo: [{
            coverage: 1.45,
            x: 90,
            y: 90,
            xr: true,
            yr: true,
         }],
      }, {
         service: 'Sky',
         id: 'imageL',
         name: 'Vodafone Cover',
         flags: 'now',
         uses: ['Poster-V-Logo', 'Poster-V'],
         width: 450,
         height: 670,
         channelLogo: [{
            coverage: 1.4,
            x: 'center',
            y: 20,
            xr: false,
            yr: true,
         },
         {
            coverage: 1.4,
            x: 'center',
            y: 20,
            xr: false,
            yr: false,
         }],
      }, {
         service: 'Sky',
         id: 'imageM',
         name: 'TIM Hor. Cover',
         flags: 'TimCatchup',
         uses: ['Poster-O-Logo', 'Poster-O'],
         width: 800,
         height: 600,
      }, {
         service: 'Sky',
         id: 'imageN',
         name: 'Cover Clean',
         uses: ['Poster-V'],
         width: 600,
         height: 800,
      }, {
         service: 'Sky',
         id: 'imageO',
         name: 'Scene Key Art',
         uses: ['Poster-O-Logo'],
         width: 1920,
         height: 1080,
      }, {
         service: 'Sky',
         id: 'imageP',
         name: 'Logo',
         uses: ['Logo'],
         width: 2880,
         height: 1620,
      }, {
         service: 'Sky',
         id: 'imageQ',
         name: 'Boxset',
         uses: ['Poster-O-Logo-219', 'Poster-O-Logo'],
         width: 188,
         height: 71,
      }, {
         service: 'Timvision',
         id: 'imageR',
         name: 'C+ Locandina',
         uses: ['Poster-V-Logo'],
         width: 600,
         height: 800,
      }, {
         service: 'Timvision',
         id: 'imageS',
         name: 'C+ Cover',
         uses: ['Poster-O-Logo'],
         width: 1920,
         height: 1080,
         programLogo: true,
      }, {
         service: 'Sky Vod',
         id: 'imageVA',
         name: 'Vod A. Locandina',
         uses: ['Poster-V-Logo'],
         width: 360,
         height: 532,
         channelLogo: [{
            coverage: 1.45,
            x: 'center',
            y: 25,
            xr: false,
            yr: true,
         }],
      }, {
         service: 'Sky Vod',
         id: 'imageVB',
         name: 'Vod B. Landscape 3',
         episodic: true,
         uses: ['Scena', 'Poster-O', 'Poster-O-Logo'],
         width: 962,
         height: 542,
      }, {
         service: 'Sky Vod',
         id: 'imageVG',
         name: 'Vod Tombolino',
         uses: ['Poster-O-Logo'],
         width: 460,
         height: 260,
         episodeNumber: true,
         programLogo: true,
         channelLogo: [{
            coverage: 1.45,
            x: 20,
            y: 20,
            xr: true,
            yr: true,
         }],
      }, {
         service: 'Sky Vod',
         id: 'imageVL',
         name: 'Vod STB',
         episodic: true,
         uses: ['Scena', 'Poster-O', 'Poster-O-Logo'],
         width: 198,
         height: 109,
         episodeNumber: true,
      }, {
         service: 'Sky Primafila',
         id: 'imagePA',
         name: 'Primafila Big',
         uses: ['Poster-O-Logo'],
         width: 720,
         height: 407,
         programLogo: true,
      }, {
         service: 'Sky Primafila',
         id: 'imagePB',
         name: 'Primafila Small',
         uses: ['Poster-O-Logo'],
         width: 198,
         height: 109,
         programLogo: true,
      }, {
         service: 'Sky Primafila',
         id: 'imagePC',
         name: 'Primafila Scena',
         uses: ['Scena', 'Poster-O', 'Poster-O-Logo'],
         width: 1280,
         height: 704,
      }, {
         service: 'Caccia',
         id: 'imageCT',
         name: 'Poster O AMZ TV',
         uses: ['Poster-O-Logo', 'Poster-O', 'Scena'],
         width: 1600,
         height: 1200,
      }, {
         service: 'Caccia',
         id: 'imageCU',
         name: 'Poster V AMZ Movie',
         uses: ['Poster-V-Logo', 'Poster-V', 'Scena', 'Poster-O'],
         width: 1200,
         height: 1600,
      }, {
         service: 'Caccia',
         id: 'imageCV',
         name: 'Scena V AMZ TV',
         uses: ['Scena', 'Poster-O'],
         width: 1600,
         height: 1200,
      }, {
         service: 'Sky',
         flags: 'adult',
         id: 'imageY',
         name: 'Poster STB',
         uses: ['Poster-O', 'Scena'],
         width: 198,
         height: 109,
      }],
   };

   // REQUIRED SCRIPTS
   // if (typeof modalImageChooser == 'undefined') {
   //   const tag = document.createElement('script');
   //   tag.src = _myCanvas.mySite + '/javascripts/datatv/modalImageChooser.js';
   //   window.document.getElementsByTagName('head')[0].appendChild(tag);
   // }

   if (typeof FontFaceObserver == 'undefined') {
      const tag = document.createElement('script');
      tag.src = _myCanvas.mySite + '/module/fontfaceobserver/fontfaceobserver.js';
      window.document.getElementsByTagName('head')[0].appendChild(tag);
   }

   if (typeof Fuse == 'undefined') {
      const tag = document.createElement('script');
      tag.src = _myCanvas.mySite + '/module/fuse.js/dist/fuse.js';
      window.document.getElementsByTagName('head')[0].appendChild(tag);
   }

   // Default settings
   const defaults = {
      selector: '._myCan',
      width: 256,
      height: 256,
   };

    _myCanvas.contentFormatsToPrint = function (rData, imgRichieste) {
      console.log('[contentFormatsToPrint]', rData, 'imgRichieste:', imgRichieste);
      if (rData == 'all') return _myCanvas.defaultFormats;

      // formati
      let formati = _myCanvas.defaultFormats.filter((f) => ['Sky', 'Timvision'].includes(f.service) && f.id != 'imageI');
      const nuoviFormati = [];

      // req
      const req = rData.request ? rData.request : rData;
      const tipo = req._tr ? req._tr : req.TipoRichiesta;

      // ethanMetadatasets
      const flagNow = req.ethanMetadatasets && req.ethanMetadatasets.match(/now/gi);
      const flagFusion = req.ethanMetadatasets && req.ethanMetadatasets.match(/fusion/gi);
      const TimCatchup = req.ethanMetadatasets && req.ethanMetadatasets.match(/TimCatchup/gi);
      const caccia = req.ethanMetadatasets && req.ethanMetadatasets.match(/Caccia/gi);
      const adult = req.ethanMetadatasets && req.ethanMetadatasets.match(/adult/gi);
      const leiDove = req.ethanMetadatasets && req.ethanMetadatasets.match(/LeiDove/gi);

      // Lei e Dove, foto di Amazon Caccia e Pesca + Foto G no stagione e serie forse


      // Se non è now tolgo le immagini now
      if (!TimCatchup) formati = formati.filter((f) => !f.flags || !f.flags.match(/TimCatchup/gi));
      if (!flagNow) formati = formati.filter((f) => !f.flags || !f.flags.match(/now/gi));
      if (flagFusion) formati = formati.concat(_myCanvas.defaultFormats.filter((f) => f.flags && f.flags.match(/fusion/gi)));
      if (!adult) formati = formati.filter((f) => !f.flags || !f.flags.match(/adult/gi));
      if (adult) formati = formati.filter(f => ['imageE', 'imageF', 'imageQ', 'imageY'].includes(f.id));

      // Formati da channelGroupName
      if (req.channelGroupName) {
         const cgNames = req.channelGroupName.split(',');
         cgNames.forEach((cgn) => {
            if (cgn.match(/dyn_img_/gi)) {
               nuoviFormati.push('imageJ_' + cgn);

            } else {
               nuoviFormati.push('imageE_' + cgn);
               nuoviFormati.push('imageF_' + cgn);
               nuoviFormati.push('imageG_' + cgn);
               if (flagFusion) nuoviFormati.push('imageJ_' + cgn);

            }
         });
      }

      // Creo i nuovi formati
      if (nuoviFormati.length > 0) {
         nuoviFormati.forEach((nf) => {
            const formatId = nf.split('_')[0];
            const channelGroup = nf.split('_').splice(1).join('_');
            const baseFormat = _myCanvas.defaultFormats.find((f) => f.id == formatId);
            const newFormat = $.extend({}, baseFormat);

            newFormat.id = nf;
            newFormat.channelGroup = channelGroup;

            if (channelGroup.match(/dyn_img_/gi)) {
               newFormat.name = channelGroup.replace(/dyn_img_/g, 'Alt. ');
               newFormat.service = newFormat.service + ' Alternative Images';
               newFormat.flags = channelGroup.replace(/dyn_img_/g, 'ALT IMG ') + (newFormat.flags ? ',' + newFormat.flags : '');
               newFormat.uses = [channelGroup.replace(/dyn_img_/g, 'Poster-V-Logo-AL')];

            } else {
               newFormat.name = newFormat.name + ' ' + channelGroup.replace(/_/g, ' ').trim().toUpperCase();
               newFormat.service = newFormat.service + ' ' + channelGroup.replace(/[0-9]/g, ' ').toUpperCase();
               newFormat.flags = channelGroup + (newFormat.flags ? ',' + newFormat.flags : '');

            }

            // console.log('nuovo formato', newFormat)
            formati.push(newFormat);
         });
      }

      // Formati peacock
      if (req.extraImageFlag) {
         let formatsToAdd = _myCanvas.defaultFormats.filter(f => ['imageK', 'imageL'].includes(f.id));
         formatsToAdd.forEach(f => {
            let fc = JSON.parse(JSON.stringify(f));
            fc.id = fc.id == 'imageK' ? 'imageT' : 'imageU';
            fc.name = fc.name.replace('Vodafone', 'Peacock');
            fc.service = 'Peacock';
            formati.push(fc);
         })
      }

      // Se episodio filtro solo i formati necessari
      if (tipo == 'EP') {
          if (imgRichieste) {

            if (imgRichieste == 'O') {
               formati = formati.filter((f) => f.episodic);
            }
            else if (imgRichieste == 'H-O') {
               formati = formati.filter((f) => f.episodic || /^imageF/gi.test(f.id));
            }
            else if (imgRichieste == 'V-O') {
               formati = formati.filter((f) => f.episodic || /^(imageE|imageJ|imageA|imageR)/gi.test(f.id));
            }
            else if (imgRichieste == 'V-H-O') {
               formati = formati.filter((f) => f.episodic || /^(imageF|imageE|imageJ|imageA|imageR|imageC|imageS)/gi.test(f.id));
            }
            else if (imgRichieste == 'No Episodiche') {
               formati = [];
            }
         }
         else {
            formati = formati.filter((f) => f.episodic);
         }
      }

      // Extra Formati caccia
      if (caccia) {
         let formatsToAdd = [];

         if (tipo == 'EP') {
            formatsToAdd = _myCanvas.defaultFormats.filter(f => ['imageCV'].includes(f.id));

         } else if (tipo == 'SS') {
            formatsToAdd = _myCanvas.defaultFormats.filter(f => ['imageCT'].includes(f.id));

         } else {
            formatsToAdd = _myCanvas.defaultFormats.filter(f => ['imageCU'].includes(f.id));

         }

         formatsToAdd.forEach(f => {
            let fc = JSON.parse(JSON.stringify(f));
            fc.id = fc.id == 'imageCT' ? 'imageT' : fc.id == 'imageCV' ? 'imageV' : 'imageU';
            formati.push(fc);
         })
      }

      // Extra formati Lei e Dove
      if (leiDove) {
         let formatsToAdd = [];

         if (tipo == 'EP') {
            formatsToAdd = _myCanvas.defaultFormats.filter(f => ['imageCV'].includes(f.id));

         } else if (tipo == 'SS') {
            formatsToAdd = _myCanvas.defaultFormats.filter(f => ['imageCT'].includes(f.id));

         } else {
            formatsToAdd = _myCanvas.defaultFormats.filter(f => ['imageCU'].includes(f.id));

         }

         formatsToAdd.forEach(f => {
            let fc = JSON.parse(JSON.stringify(f));
            fc.id = fc.id == 'imageCT' ? 'imageT' : fc.id == 'imageCV' ? 'imageV' : 'imageU';
            fc.service = 'LEI-DOVE'
            formati.push(fc);
         })
      }

      return formati;
   };

   _myCanvas.contentCreateCanvases = async function (odati, tipo, $dest, init, imgRichieste) {

      // console.log('contentCreateCanvases', odati, tipo, $dest, init)
      if (!$dest) $dest = $('.area-immagini');
      const formatsToPrint = odati ? _myCanvas.contentFormatsToPrint(odati, imgRichieste) : _myCanvas.defaultFormats;

      $dest.html('');
      const $myCanvases = $('<div class="ui segments ' + (tipo == 'episodi' ? 'horizontal ' : '') + 'myCanvases" style="background:white;"></div>').appendTo($dest);
      addMyCanvasesMenu(odati.idProgram);

      await (async () => {
         for (let i = 0; i < formatsToPrint.length; i++) {
            const format = formatsToPrint[i];
            // console.log('format', format)
            const serName = format.service;
            const serClass = serName.replace(/\s/g, '');
            let $group = $dest.find('.img_servizio.' + serClass);
            // Se non c'è il gruppo servizio la creo
            if ($group.length == 0) {
               $group = $(`
            <div class="ui segment left aligned img_servizio ${serClass}" style="padding:8px;">
              <h4 class="ui header">${serName}</h4>
            </div>`).appendTo($myCanvases);
            }
            // se non c'è il canvas lo creo
            if ($dest.find('.id_' + format.id).length == 0) {
               // console.log('contentCreateCanvases ', i, 'di', formatsToPrint.length);
               const uniqueClass = `uc_${format.id}${odati.idRichiesta ? '_r' + odati.idRichiesta : ''}_${new Date().getTime()}`;
               $group.append(`<div class="id_${format.id} ${uniqueClass} canvasProgram"></div>`);
               const f = Object.assign({}, format);
               f.selector = '.' + uniqueClass;
               f.maxHeight = tipo == 'episodi' ? 120 : 200;
               const myCanvas = await _myCanvas.init(f).then((mc) => mc[0]);
               if (odati) myCanvas.addProgramInfo(odati);
               // Add Image
               if (init && odati[f.id]) {
                  // , ir332835_imageE_dazn_se201194_pr242141_ep1299794_st2019_ru0.jpg
                  let turl = URLS.imageFolder + odati[f.id] + '?nocache=true';
                  turl = turl.replace(/\/\, /gi, '/');
                  //console.log('Load Image', turl);

                  await myCanvas.insertImage(turl, 'fit').then(() => {
                     myCanvas.setState('Db');
                  }).catch(e => console.log(e));

               } else if (init && !odati[f.id]) {

                  if (myCanvas.settings.id == 'imageT') {
                     let $source = $(myCanvas.wrapper).closest('.segments.myCanvases').find('.id_imageH');
                     if ($source.length) {
                        let img = $source[0].myCanvas.layers[0].img;
                        if (img) await myCanvas.insertImage(img, 'max');
                     }
                     myCanvas.macroChannelLogo(false, 'OD6');

                  } else if (myCanvas.settings.id == 'imageU') {
                     let $source = $(myCanvas.wrapper).closest('.segments.myCanvases').find('.id_imageE');
                     if ($source.length) {
                        let img = $source[0].myCanvas.layers[0].img;
                        if (img) await myCanvas.insertImage(img, 'max');
                     }
                     myCanvas.macroChannelLogo(false, 'OD6');
                  }
               }
            }
         }
      })();
      // console.log('contentCreateCanvases Fine');
      // addMyCanvasesMenu
      async function addMyCanvasesMenu(idProgram) {
         //
         let chImages;
         if (tipo != 'episodi') {
            chImages = await client.service('channelsimages').find({query:{$limit:1000}})
               .then(r =>
                  _.orderBy(_.uniqBy(r.data, c => c.idChannel), ['channelName'])
               )
         }
         const $menu = $(`
        <div class="ui borderless menu all_imgs_menu ${tipo == 'episodi' ? 'text' : 'basegrey'}" ${(tipo == 'episodi' ? 'style="margin:-16px 0;" ' : '')}>
          ${tipo != 'episodi' ?
               `<div class="vertically fitted item" style="width:200px; padding:0 1px;">
            <div class="ui search selection fluid dropdown chImages">
              <input class="search">
              <input type="hidden" name="chId">
              <div class="text">Logo Canale Immagini</div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div class="item" data-value="">Nessun Logo Canale</div>
                ${chImages.map((ch) =>
                  `<div class="item" data-value="${ch.idChannel}">${ch.channelName}</div>`,
               ).join('')}</div>
            </div>
          </div>
          <div class="vertically fitted item" style="width:200px; padding-right:0;">
            <div class="ui search selection fluid dropdown chImages">
              <input class="search">
              <input type="hidden" name="chPo">
              <div class="text">--</div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div class="item" data-value="">--</div>
                  <a class="item" data-value="ac"><i class="long arrow alternate up icon"></i>Alto Chiaro</a>
                  <a class="item" data-value="as"><i class="long arrow alternate up icon"></i>Alto Scuro</a>
                  <a class="item" data-value="bc"><i class="long arrow alternate down icon"></i>Basso Chiaro</a>
                  <a class="item" data-value="bs"><i class="long arrow alternate down icon"></i>Basso Scuro</a>
                </div>
            </div>
          </div>` : ''}
          <a class="item img_esclusiva"><i class="stamp icon"></i>Esclusiva</a>
          <a class="item img_audio"><i class="stamp icon"></i>Audio Desc.</a>
          <a class="item img_lis"><i class="stamp icon"></i>L.I.S.</a>
            <div class="right menu">
            <a class="item img_wizard" data-content="Auto Immagini"><i class="wizard fitted icon"></i></a>
            <div class="ui floating dropdown item img_singleWizard" data-content="Wizard Cerca Sorgente">
              <i class="fitted cogs icon"></i>
              <div class="ui menu">
                <div class="item" data-wizard="Client"></i>Wizard Cliente</div>
                <div class="item" data-wizard="Archivio"></i>Wizard Archivio</div>
                <div class="item" data-wizard="SportsImages"></i>Wizard SportsImages</div>
                <div class="item" data-wizard="PageCopy"></i>Wizard PageCopy</div>
                <div class="item" data-wizard="Content"></i>Wizard Content</div>
                <div class="item" data-wizard="VOD"></i>Wizard VOD</div>
                <div class="item" data-wizard="Tmdb"></i>Wizard Tmdb</div>
                <div class="item" data-wizard="Fanart"></i>Wizard Fanart</div>
                <div class="item" data-wizard="Google"></i>Wizard Google</div>
              </div>
            </div>
            <a class="item img_archivio ${!idProgram ? 'disabled' : ''}" data-modalarchivio="${idProgram}" data-content="Archivio Immagini"><i class="dropbox fitted icon"></i></a>
            <a class="item img_check" data-content="Immagini Ok"><i class="check fitted icon"></i></a>
            <a class="item img_uncheck" data-content="Immagini Ko"><i class="ban fitted icon"></i></a>
            <a class="item img_download" data-content="Download Immagini"><i class="download fitted icon"></i></a>
            <a class="item img_delete" data-content="Elimina Immagini"><i class="trash alternate fitted icon"></i></a>
          </div>
        </div>`).prependTo($dest);
         $menu.find('[data-content]').popup();
         $menu.find('.img_singleWizard').dropdown({fullTextSearch: true});

         // ////////////////////////////////////////////////////////// CHANNEL IMAGES
         $menu.find('.chImages').dropdown({ fullTextSearch: true });
         $menu.find('.dropdown.chImages').on('change', '[name="chId"], [name="chPo"]', function () {
            const chId = $menu.find('.dropdown.chImages [name="chId"]').val();
            const chPo = $menu.find('.dropdown.chImages [name="chPo"]').val();
            const $area = $menu.closest('.area-immagini');
            // Scrive il logoCanale dentro odati
            const channel = myVars.channels.tutti.find((c) => c.ID == chId);
            if (odati && channel) {
               if (!odati.attributo) odati.attributo = {};
               odati.attributo.logoCanale = channel.Name.replace(/ /gi, '');
            }
            $area.find('.canvasProgram.id_imageK, .canvasProgram.id_imageL').toArray().forEach(async (mc) => {
               const myCan = mc.myCanvas;
               const type = chPo.indexOf('c') > -1 ? 'logoContentChiaro' : 'logoContentScuro';
               let pos = myCan.settings.channelLogo[0];
               if (myCan.settings.id == 'imageL' && chPo.indexOf('a') > -1) pos = myCan.settings.channelLogo[1];
               await myCan.macroChannelLogo(false, chId, type, pos);
            });
         });
         $menu.on('click', '.img_esclusiva', function () {
            const mcs = $dest.find('._myCanvas').toArray();
            mcs[0].myCanvas.addTimOverlay(mcs, 'esclusiva');
         });
         $menu.on('click', '.img_audio', function () {
            const mcs = $dest.find('._myCanvas').toArray();
            mcs[0].myCanvas.addTimOverlay(mcs, 'AUDIO');
         });
         $menu.on('click', '.img_lis', function () {
            const mcs = $dest.find('._myCanvas').toArray();
            mcs[0].myCanvas.addTimOverlay(mcs, 'LIS');
         });
         $menu.on('click', '.img_wizard', function () {
            const mcs = $dest.find('._myCanvas').toArray();
            mcs[0].myCanvas.imageWizard(mcs);
         });
         $menu.on('click', '.img_singleWizard [data-wizard]', function () {
            const mcs = $dest.find('._myCanvas').toArray();
            const wiz = $(this).attr('data-wizard');
            mcs[0].myCanvas.imageWizard(mcs, [wiz]);
         });
         $menu.on('click', '.img_check', function () {
            const mcs = $dest.find('._myCanvas');
            mcs.each((i, mc) => {
               const myCan = mc.myCanvas;
               if (!['No', 'Db', 'Ok'].includes(myCan.state)) {
                  myCan.setState('Ok');
               }
            });
         });
         $menu.on('click', '.img_uncheck', function () {
            const mcs = $dest.find('._myCanvas');
            mcs.each((i, mc) => {
               const myCan = mc.myCanvas;
               if (!['No', 'Db', 'Ko'].includes(myCan.state)) {
                  myCan.setState('kO');
               }
            });
         });
         $menu.on('click', '.img_download', function () {
            const mcs = $dest.find('._myCanvas');
            mcs.each((i, mc) => {
               const myCan = mc.myCanvas;
               if (!['No'].includes(myCan.state)) {
                  myCan.download();
               }
            });
         });
         $menu.on('click', '.img_delete', function () {
            const mcs = $dest.find('._myCanvas');
            mcs.each((i, mc) => {
               const myCan = mc.myCanvas;
               if (!['No'].includes(myCan.state)) {
                  myCan.deleteLayer('all');
               }
            });
         });
      }
   };

   _myCanvas.contentCheckPhotos = function (photos) {
      console.log(photos);
   };

   _myCanvas.loadImage = function (url, nocache, originalUrl) {

      if (!_myCanvas.loadedImages) _myCanvas.loadedImages = {};
      if (url.indexOf('nocache=true') > -1) nocache = true;
      return new Promise((resolve, reject) => {
         url = getImageUrlFromText(url);
         if (!url) reject(new Error('URL immagine non identificabile'));

         // Immagine già caricata
         if (_myCanvas.loadedImages[url]) {
            resolve(_myCanvas.loadedImages[url]);
            return;
         }
         const img = new Image();
         img.crossOrigin = 'anonymous';
         // LOAD
         img.addEventListener('load', () => {

            _myCanvas.loadedImages[url] = img;
            console.log(originalUrl);
            if (originalUrl) _myCanvas.loadedImages[originalUrl] = img;
            resolve(img);
            return;

         });

         img.src = url + (nocache ? '?nocache=' + $.now() : '');
      });
   };

   _myCanvas.readFiles = function (files) {
      if (!Array.isArray(files)) files = [files];
      const promises = [];
      files.forEach((file) => {
         promises.push(
            new Promise((resolve, reject) => {
               const reader = new FileReader();
               // Load
               reader.onload = function () {
                  const dataURL = reader.result;
                  const localImg = new Image();
                  localImg.src = dataURL;

                  localImg.onload = function () {
                     localImg.name = file.name;
                     resolve(localImg);
                  }
               };
               reader.onerror = function () {
                  reject(new Error(`Errore Caricamento Immagine: ${url}`));
               };

               if (file) {
                  reader.readAsDataURL(file);
               }
            }),
         );
      });
      return Promise.all(promises);
   };



   // ////////////////////////////////////////////////////////////////////////////// CANVAS CLASS
   function MyCanvas(el, settings) {
      // console.log('----------', el, el.parentNode)
      // progInfo
      if (settings.progInfo) settings.progInfo = Object.assign({}, settings.progInfo);
      // Classe Base
      this.wrapper = document.createElement('div');
      this.settings = settings;
      this.layers = [];
      this.lastOp = 'created';
      this.state = null;
      // Lo sostituisco in pagina
      this.wrapper.classList.add('_myCanvas', ...el.classList);
      this.wrapper.draggable = true;
      el.parentNode.insertBefore(this.wrapper, el);
      el.remove();
      if (settings.nomenu) this.wrapper.classList.add('nomenu');
      else {
         // Creo l'header
         this.header = document.createElement('div');
         this.header.classList.add('header');
         this.wrapper.appendChild(this.header);
         const title = document.createElement('div');
         title.classList.add('title');
         title.textContent = settings.name ? settings.name : 'Image Editor';
         this.header.appendChild(title);
         const info = document.createElement('div');
         info.classList.add('info');
         info.textContent = (settings.id ? settings.id + '. ' : '') + settings.width + 'x' + settings.height;
         this.header.appendChild(info);
         const tags = document.createElement('div');
         tags.classList.add('tags');
         this.header.appendChild(tags);
         const stato = document.createElement('div');
         stato.classList.add('tag');
         stato.classList.add('state');
         stato.textContent = '?';
         tags.appendChild(stato);
         const source = document.createElement('div');
         source.classList.add('source');
         this.header.appendChild(source);
      }
      // Creo lo Stage
      this.stage = document.createElement('div');
      this.stage.classList.add('stage');
      this.wrapper.appendChild(this.stage);
      // Creo un input file per caricare file locali
      this.input = document.createElement('input');
      this.input.style.display = 'none';
      this.input.type = 'file';
      this.input.accept = 'image/*';
      this.wrapper.appendChild(this.input);
      // Creo lo bottomRightInfo
      this.bottomRightInfo = document.createElement('div');
      this.bottomRightInfo.classList.add('bottomRightInfo');
      this.stage.appendChild(this.bottomRightInfo);
      this.bottomRightInfo.style.display = 'none';
      // Add Base Layer
      const bg = this.addLayer('bg', 'main');
      bg.canvas.style.maxHeight = settings.maxHeight + 'px';
      this.activeLayer = 'bg';
      // Add Helpers Layer
      this.addLayer('helpers', 'helper', false);
      // Add Events
      this.addEvents();
      _myCanvas.windowResizeEvent = 'added';
      // Expose Object
      this.wrapper.myCanvas = this;
      // SafeArea
      if (settings.safeArea) {
         if (settings.safeArea.match(/imageF/gi)) this.renderSafeArea(_myCanvas.mySite + '/datatv/myCanvas/' + settings.safeArea, true, true);
         else this.renderSafeArea(_myCanvas.mySite + '/datatv/myCanvas/' + settings.safeArea);
      }
      this.setState('No', 'created');
   }
   MyCanvas.prototype.addEvents = function () {
      const myCanvas = this;
      const wrapper = this.wrapper;
      const header = this.header;
      const stage = this.stage;
      // Window Resize
      if (!_myCanvas.windowResizeEvent) {
         window.addEventListener('resize', () => {
            const mcs = document.querySelectorAll('._myCanvas.fullScreen');
            mcs.forEach((mc) => {
               mc.myCanvas.setMaxHeight('max');
            });
         });
      }
      // Wrapper
      wrapper.addEventListener('mouseleave', (event) => {
         event.preventDefault();
         event.stopPropagation();
         if (!wrapper.classList.contains('fullScreen')) {
            myCanvas.renderTopMenu('hide');
            myCanvas.renderLayersMenu('hide');
         }
      });
      wrapper.addEventListener('dragstart', (event) => {
         if (wrapper.classList.contains('fullScreen')) {
            event.preventDefault();
            event.stopPropagation();
            return;
         }

         myCanvas.renderTopMenu('hide');
         myCanvas.renderLayersMenu('hide');
         event.dataTransfer.setData('text/plain', 'myCanvas');
         _myCanvas.dragged = myCanvas;
      });
      wrapper.addEventListener('dragover', (event) => {
         event.preventDefault();
         event.stopPropagation();
         wrapper.classList.add('target');
      });
      wrapper.addEventListener('dragleave', (event) => {
         event.preventDefault();
         event.stopPropagation();
         document.querySelectorAll('._myCanvas').forEach((c) => c.classList.remove('target'));
      });
      wrapper.addEventListener('dragend', (event) => {
         event.preventDefault();
         event.stopPropagation();
         document.querySelectorAll('._myCanvas').forEach((c) => c.classList.remove('target'));
         _myCanvas.dragged = false;
      });
      wrapper.addEventListener('drop', (event) => {
         event.preventDefault();
         event.stopPropagation();
         document.querySelectorAll('._myCanvas').forEach((c) => c.classList.remove('target'));
         layerDrop(event);
         _myCanvas.dragged = false;
      });
      // Header
      if (!myCanvas.settings.nomenu) {
         const stateTag = this.header.querySelector('.state');
         stateTag.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (stateTag.classList.contains('Ko')) {
               myCanvas.setState('Ok');
            } else if (stateTag.classList.contains('Ok')) {
               myCanvas.setState('Ko');
            }
         });
         header.addEventListener('dblclick', (event) => {
            event.preventDefault();
            event.stopPropagation();
            myCanvas.fullScreen();
            myCanvas.renderTopMenu('show');
            myCanvas.renderLayersMenu('draw');
         });
         header.addEventListener('contextmenu', (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (!wrapper.classList.contains('withLayersMenu')) {
               myCanvas.renderTopMenu('show');
               myCanvas.renderLayersMenu('draw');
            } else {
               myCanvas.renderTopMenu('hide');
               myCanvas.renderLayersMenu('hide');
            }
         });
         header.addEventListener('mouseenter', (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (!wrapper.classList.contains('fullScreen')) {
               myCanvas.renderTopMenu('show');
               // myCanvas.renderLayersMenu('hide');
            }
         });
      }
      // Stage
      stage.addEventListener('contextmenu', (event) => {
         event.preventDefault();
         event.stopPropagation();
      });
      // stage.addEventListener('wheel', event => {
      //   if (!myCanvas.activeLayer) return;
      //   var lO = myCanvas.layers.find(l => l.name == myCanvas.activeLayer);
      //   myCanvas.viewScale = myCanvas.layers[0].canvas.clientWidth / myCanvas.settings.width;
      //   lO.mouseStart = {
      //     x: Math.round(event.layerX / myCanvas.viewScale),
      //     y: Math.round(event.layerY / myCanvas.viewScale),
      //     lastx: event.screenX,
      //     lasty: event.screenY,
      //     lO: {
      //       scale: lO.scale,
      //       dx: lO.dx,
      //       dy: lO.dy
      //     }
      //   };
      //   layerScaleEvent.bind(lO)(event);
      // });
      stage.addEventListener('mouseleave', (event) => {
         event.preventDefault();
         event.stopPropagation();
         myCanvas.drawHelpers('clear');
         myCanvas.setBottomRightInfo();
      });
      let clicks = 0;
      const delay = 400;
      stage.addEventListener('mousedown', (event) => {
         event.preventDefault();
         event.stopPropagation();
         if (!myCanvas.activeLayer) return;
         clicks++;
         setTimeout(function () {
            clicks = 0;
         }, delay);
         if (clicks === 2) {
            myCanvas.layerCenter();
            clicks = 0;
            return;
         }
         const lO = myCanvas.layers.find((l) => l.name == myCanvas.activeLayer);
         const todo = event.which == 1 ? layerMoveEvent.bind(lO) : layerScaleEvent.bind(lO);
         myCanvas.viewScale = myCanvas.layers[0].canvas.clientWidth / myCanvas.settings.width;
         lO.mouseStart = {
            x: Math.round(event.layerX / myCanvas.viewScale),
            y: Math.round(event.layerY / myCanvas.viewScale),
            lastx: event.screenX,
            lasty: event.screenY,
            lO: {
               scale: lO.scale,
               dx: lO.dx,
               dy: lO.dy,
            },
         };
         window.addEventListener('mousemove', todo);
         window.addEventListener('mouseup', () => {
            window.removeEventListener('mousemove', todo);
            myCanvas.drawHelpers('clear');
            myCanvas.setBottomRightInfo();
            // Ridisegno
            myCanvas.drawContext(lO);
         }, {
            once: true,
         });
      });
      function layerDrop(event) {
         const dt = event.dataTransfer;
         if (dt.getData('text/plain') == 'myCanvas' && _myCanvas.dragged) {
            // MYCANVAS
            myCanvas.cloneAll(_myCanvas.dragged);
            return;
         }
         const lO = myCanvas.layers.find((l) => l.name == myCanvas.activeLayer ? myCanvas.activeLayer : 'bg');
         const mode = lO.type == 'main' ? 'max' : 'min';
         if (lO.type !== 'image' && lO.type !== 'main') return;
         if (dt.files[0]) {
            // FILES
            myCanvas.readFiles([dt.files[0]]).then((imgs) => {
               myCanvas.insertImage(imgs[0], mode, lO.name);
            });
         } else if (dt.getData('text/plain')) {
            // IMAGE URL
            const url = getImageUrlFromText(dt.getData('text/plain'));
            if (url) myCanvas.insertImage(url, mode, lO.name);
         }
      }
      function layerMoveEvent(event) {
         event.preventDefault();
         event.stopPropagation();
         const lO = this;
         let dx = 0;
         let dy = 0;
         if (!lO || !lO.img) return;
         // Event.movement FIX
         let movementX = event.screenX - lO.mouseStart.lastx;
         let movementY = event.screenY - lO.mouseStart.lasty;
         lO.mouseStart.lastx = event.screenX;
         lO.mouseStart.lasty = event.screenY;
         if (!event.shiftKey) {
            movementX = Math.round(movementX / myCanvas.viewScale);
            movementY = Math.round(movementY / myCanvas.viewScale);
         }
         dx = parseInt(lO.dx) + movementX;
         dy = parseInt(lO.dy) + movementY;
         myCanvas.layerMove(lO.name, dx, dy);
         myCanvas.drawHelpers('guides', lO.name);
         myCanvas.drawHelpers('area', lO.name);
      }
      function layerScaleEvent(event) {
         event.preventDefault();
         event.stopPropagation();
         const lO = this;
         let newScale = lO.scale;
         if (!lO || !lO.img) return;
         // Event.movement FIX
         const movementY = event.screenY - lO.mouseStart.lasty;
         lO.mouseStart.lasty = event.screenY;
         // Scale
         const increment = event.shiftKey ? 0.01 : 0.05;
         if (event.type == 'wheel' && event.deltaY != 0) {
            newScale += (event.deltaY > 0 ? increment : -increment);
         } else if (movementY != 0) {
            newScale = lO.scale + movementY / 100;
         } else return;
         myCanvas.layerScale(lO.name, newScale, lO.mouseStart.x, lO.mouseStart.y);
         myCanvas.drawHelpers('guides', lO.name);
         myCanvas.drawHelpers('area', lO.name);
      }
   };
   MyCanvas.prototype.addProgramInfo = function (data, dataep, source) {
      // console.log('addProgramInfo', data, dataep, source);
      if (source == 'program-join') {
         this.settings.progInfo = {
            category: data.program.Category_Name,
            idSerie: data.programserie ? data.programserie.idProgramBase : '',
            idProgram: data.idProgram == -1 ? '' : data.idProgram,
            idEpisode: dataep.ID == -1 ? '' : dataep.ID,
            idTmdb: data.idTmdb,
            title: data.program.Title,
            originalTitle: data.program.originalTitle,
            episodeTitle: dataep ? dataep.Title : '',
            type: data.program.Category_Name == 'Film' ? 'movie' : 'tv',
            season: data.program.SeriesNumber,
            episode: dataep ? dataep.episodioNumerico : '',
            ethanMetadatasets: '',
            turn: dataep.Turn,
            canali: data.content && data.content.length && data.content[0].canali ? data.content[0].canali : false,
            idRequest: data.content && data.content.length ? data.content[0].idRequest : false,
         };
      } else if (!source || source == 'odati') {
         this.settings.progInfo = {
            tipologia: data.tipologia,
            category: data.detailedCategory,
            idSerie: data.idSerie == -1 ? '' : data.idSerie,
            idProgram: data.idProgram == -1 ? '' : data.idProgram,
            idEpisode: data.idEpisode == -1 ? '' : data.idEpisode,
            idTmdb: data.tvDBID,
            title: data.title,
            originalTitle: data.originalTitle,
            season: data.seasonNumber,
            episode: data.episodeNumber,
            ethanMetadatasets: data.ethanMetadatasets,
            type: data.detailedCategory == 'Film' ? 'movie' : 'tv',
            turn: data.turn,
            canali: data.canali,
            idRequest: data.idRichiesta,
            tr: data._tr ? data._tr : '',
         };
      } else if (!source || source == 'DTT') {
         this.settings.progInfo = {
            category: data.categoria,
            idProgram: data.idProgram == -1 ? '' : data.idProgram,
            idEpisode: data.idEpisode == -1 ? '' : data.idEpisode,
            title: data.titolo,
            originalTitle: data.titoloOriginale,
            season: data.stagione,
            episode: data.episodioNumerico,
            type: data.category == 'Film' ? 'movie' : 'tv',
            canali: data.canali,
         };
      }
      // console.log('addProgramInfo:', data);
   };
   // ////////////////////////////////////////////////////////////////////////////// OPERATIONS
   MyCanvas.prototype.addLayer = function (layerName, type, uiDisplay) {
      if (type !== 'safearea') this.setState('KO', 'addLayer');
      const names = this.layers.map((l) => l.name);
      if (!layerName) layerName = 'Nuovo-1';
      layerName = layerName.replace(/[^A-Za-z0-9 -]+/gi, '').replace(/\s/gi, '-');
      if (names.find((n) => n.toLowerCase() == layerName.toLowerCase())) {
         const names = this.layers.map((l) => l.name);
         const match = /\d{1,}/gi.exec(layerName);
         let num = match ? Math.abs(match[0]) : 1;
         let cleanName = match ? layerName.replace(match[0], '') : layerName;
         cleanName = cleanName.replace(/-$/gi, '');
         while (names.find((n) => n.toLowerCase() == layerName.toLowerCase())) {
            layerName = cleanName + '-' + num;
            num++;
         }
      }
      // Creo il canvas
      const canvas = document.createElement('canvas');
      canvas.classList.add(layerName);
      canvas.width = this.settings.width;
      canvas.height = this.settings.height;
      // Lo aggiungo ai Layer
      this.stage.appendChild(canvas);
      const newLayer = {
         name: layerName,
         canvas: canvas,
         type: type ? type : 'image',
         visible: true,
         uiDisplay: uiDisplay === false ? false : true,
         // Canvas
         layerw: this.settings.width,
         layerh: this.settings.height,
         effects: [],
      };
      this.layers.push(newLayer);
      this.orderLayer(); // Rimette bg in fondo e safearea e helpers in cima
      // this.renderLayersMenu('update');
      return newLayer;
   };
   MyCanvas.prototype.orderLayer = function (layerName, newIndex) {
      if (layerName) this.setState('KO', 'orderLayer');
      if (!newIndex && newIndex != 0) newIndex = this.layers.length - 1;
      const destName = this.layers[newIndex].name;
      // Remove not orderable layers
      const fixed = this.layers.filter((fl) => ['main', 'helper', 'safearea'].includes(fl.type));
      fixed.forEach((fl) => {
         const fli = this.layers.findIndex((lO) => lO == fl);
         this.layers.splice(fli, 1);
      });
      // Reorder Array
      if (layerName) {
         const fromI = this.layers.findIndex((l) => l.name == layerName);
         let toI = this.layers.findIndex((l) => l.name == destName);
         if (destName == 'bg') toI = 0; // sposto all'inizio
         else if (toI == -1) toI = this.layers.length - 1; // se non esiste alla fine
         else if (toI < fromI) toI = toI + 1; // lo metto sempre sopra al target
         this.layers.splice(toI, 0, this.layers.splice(fromI, 1)[0]);
         // console.log(fromI, toI, layerName, destName, newIndex, this.layers.map(f => f.name));
      }
      // Restore not orderable layers
      fixed.forEach((fl) => {
         if (fl.type == 'main') this.layers.unshift(fl);
         else this.layers.push(fl);
      });
      // Reorder Layers
      this.layers.forEach((lay) => {
         this.stage.insertBefore(lay.canvas, null);
      });
      // Render Interface
      // if(this.wrapper.classList.contains('withLayersMenu')) this.renderLayersMenu('draw');
   };

   MyCanvas.prototype.insertImage = async function (imgToLoad, mode, layerName, trasp, keep) {
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      if (!lO) return;

      this.message('Disegno immagine...');

      // Carico l'immagine se serve
      let img = false;
      if (imgToLoad instanceof HTMLImageElement || imgToLoad instanceof HTMLCanvasElement) {
         img = imgToLoad;
      } else if (typeof imgToLoad == 'string') {
         img = await this.loadImage(imgToLoad);
      }

      // Rimuovo l'area trasparente
      if (trasp) img = await this.removeTransparency(img);

      // La assegno al livello
      lO.img = img;
      lO.iw = img.width;
      lO.ih = img.height;

      // Calcolo scale e posizione
      if (typeof mode === 'object') {
         // coverage
         if (mode.coverage) {
            mode.area = lO.layerh * lO.layerw * mode.coverage / 100;
            mode.w = Math.sqrt((lO.iw / lO.ih) * mode.area);
            mode.h = Math.sqrt((lO.ih / lO.iw) * mode.area);
            // console.log(mode.area, 'Area:', mode.w * mode.h, mode.w, mode.h);
         }
         // SAME
         if (mode.same) {
            // La stessa area
            mode.area = lO.layerh * lO.layerw * lO.coverage / 100;
            mode.w = Math.sqrt((lO.iw / lO.ih) * mode.area);
            mode.h = Math.sqrt((lO.ih / lO.iw) * mode.area);
            // Nello stesso centro
            mode.x = lO.dx + lO.dw / 2 - mode.w / 2;
            mode.y = lO.dy + lO.dh / 2 - mode.h / 2;
         }
         // Dimensioni
         lO.dw = mode.w ? mode.w : mode.h ? lO.iw * (mode.h / lO.ih) : 0;
         lO.dh = mode.h ? mode.h : mode.w ? lO.ih * (mode.w / lO.iw) : 0;
         // Posizione
         lO.dx = mode.x ? mode.x : lO.layerw / 2 - lO.dw / 2;
         lO.dy = mode.y ? mode.y : lO.layerh / 2 - lO.dh / 2;
         lO.scale = lO.dw / lO.iw;
      } else if (mode != 'same') {
         // Scale
         if (!mode) lO.scale = 1;
         else if (mode == 'max') lO.scale = Math.max(lO.layerw / lO.iw, lO.layerh / lO.ih);
         else if (mode == 'fit') lO.scale = Math.min(lO.layerw / lO.iw, lO.layerh / lO.ih);
         else if (mode == 'min' || mode == 'same') {
            lO.scale = Math.min(lO.layerw / lO.iw, lO.layerh / lO.ih);
            if (lO.scale > 1) lO.scale = 1;
         }
         const scaledw = lO.iw * lO.scale;
         const scaledh = lO.ih * lO.scale;
         // Canvas Destination
         lO.dx = lO.layerw / 2 - scaledw / 2;
         lO.dy = lO.layerh / 2 - scaledh / 2;
         lO.dw = scaledw;
         lO.dh = scaledh;
         // Canvas Shadow
         lO.shadowx = false;
         lO.shadowy = false;
         lO.shadowblur = false;
         lO.shadowcolor = false;
      }

      // Arrotondo posizione e dimensione
      lO.dx = Math.round(lO.dx);
      lO.dy = Math.round(lO.dy);
      lO.dw = Math.round(lO.dw);
      lO.dh = Math.round(lO.dh);

      // Ridisegno il livello con l'immagine
      this.drawContext(lO, keep);
      this.message();
      if (lO.type != 'safearea') this.setState('KO', 'insertImage');

      return this;
   }

   MyCanvas.prototype.clearLayerType = function (layerName) {
      const lO = this.layers.find((l) => l.name == layerName);
      lO.type = '';
      lO.gradient = false;
      lO.text = false;
      lO.channel = false;
      lO.sportImage = false;
      lO.sportMatch = false;
      lO.episodeNumber = false;
      lO.blurTile = false;
      lO.titleTemplate = false;
      lO.titleLogo = false;
      return lO;
   };
   MyCanvas.prototype.copyLayer = function (layerName) {
      const lO = this.layers.find((l) => l.name == layerName);
      const copy = Object.assign({}, lO);
      return copy;
   };
   MyCanvas.prototype.pasteLayer = function (layerName, copied) {
      if (layerName) this.setState('KO', 'pasteLayer');
      let lO = this.layers.find((l) => l.name == layerName);
      // Gli do il nome e il canvas del vecchio
      copied.name = lO.name;
      copied.canvas = lO.canvas;
      lO = Object.assign({}, copied);
      this.drawContext(lO);
      this.activeLayer = lO.name;
      return lO;
   };
   MyCanvas.prototype.cloneLayer = function (layerName, newName) {
      if (layerName) this.setState('KO', 'cloneLayer');
      const lOcopy = this.copyLayer(layerName);
      let lOnew = this.addLayer(newName ? newName : lOcopy.name);
      // Gli do il nome e il canvas del layer creato
      lOcopy.name = lOnew.name;
      lOcopy.canvas = lOnew.canvas;
      lOcopy.type = lOcopy.type == 'main' ? 'image' : lOcopy.type;
      lOnew = Object.assign(lOnew, lOcopy);
      this.drawContext(lOnew);
      this.activeLayer = lOnew.name;
      console.log(this.layers);
      return lOnew;
   };
   MyCanvas.prototype.deleteLayer = function (layerName) {
      let lOtoDel;
      if (layerName == 'all') lOtoDel = this.layers;
      else if (Array.isArray(layerName)) lOtoDel = layerName.map((ln) => this.layers.find((l) => l.name == ln));
      else lOtoDel = [this.layers.find((l) => l.name == layerName)];
      if (layerName == 'all' || layerName == 'bg') this.setSource(false);
      for (let i = lOtoDel.length - 1; i >= 0; i--) {
         var lO = lOtoDel[i];
         if (!lO) continue;
         if (['helper', 'safearea'].includes(lO.type)) continue;
         if (lO.type == 'main') {
            lO.img = null;
            this.drawContext(lO);
         } else {
            lO.canvas.remove();
            this.layers.splice(this.layers.findIndex((tl) => tl.name == lO.name), 1);
         }
      }
      if (this.areLayersBlank() == true) this.setState('No', 'deleteLayer');
      else this.setState('KO', 'deleteLayer');
      if (!this.layers.find((l) => l.name == this.activeLayer)) this.activeLayer = 'bg';
   };
   MyCanvas.prototype.clearLayer = function (layerName) {
      const lO = this.layers.find((l) => l.name == layerName);
      lO.img = false;
      lO.iw = 0;
      lO.ih = 0;
      lO.scale = 0;
      lO.dx = 0;
      lO.dy = 0;
      lO.dw = 0;
      lO.dh = 0;
      lO.coverage = 0;
      const ctx = lO.canvas.getContext('2d');
      ctx.clearRect(0, 0, lO.canvas.width, lO.canvas.height);
      this.updateLayerFormTransform();
      this.setState('KO', 'clearLayer');
      if (layerName == 'bg') this.setSource(false);
   };
   MyCanvas.prototype.renameLayer = function (layerName, newName) {
      if (newName == layerName) return newName;
      this.setState('SAME', 'renameLayer');
      const lO = this.layers.find((l) => l.name == layerName);
      const names = this.layers.map((l) => l.name);
      newName = newName.replace(/[^A-Za-z0-9 -]+/gi, '').replace(/\s/gi, '-');
      if (newName == layerName) return newName;
      if (names.find((n) => n.toLowerCase() == newName.toLowerCase() && n != layerName)) {
         const names = this.layers.map((l) => l.name);
         const match = /\d{1,}/gi.exec(newName);
         let num = match ? Math.abs(match[0]) : 1;
         let cleanName = match ? newName.replace(match[0], '') : newName;
         cleanName = cleanName.replace(/-$/gi, '');
         while (names.find((n) => n.toLowerCase() == newName.toLowerCase() && n != layerName)) {
            newName = cleanName + '-' + num;
            num++;
         }
      }
      if (newName == layerName) return newName;
      lO.name = newName;
      lO.canvas.classList.remove(layerName);
      lO.canvas.classList.add(newName);
      return newName;
   };
   MyCanvas.prototype.showLayer = function (layerName, bool, updateInterface) {
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      const vis = bool ? 'visible' : 'hidden';
      lO.canvas.style.visibility = vis;
      lO.visible = bool;
      if (lO.type == 'safearea') {
         this.layers.filter((l) => l.type == 'safearea').forEach((l) => {
            l.canvas.style.visibility = vis;
            l.visible = bool;
         });
      } else this.setState('KO', 'showLayer');
   };
   // TODO: Clone copy
   MyCanvas.prototype.cloneAll = async function (sourceCanvas, deep) {
      const myCanvas = this;
      if (!sourceCanvas || this == sourceCanvas) return;
      myCanvas.deleteLayer('all');
      // DEEP COPY
      if (deep) {
         sourceCanvas.layers.forEach((slO) => {
            if (slO.type != 'main') myCanvas.addLayer(slO.name, slO.type, slO.uiDisplay);
            const lO = myCanvas.layers.find((l) => l.name == slO.name);
            Object.keys(slO).forEach((k) => {
               if (k == 'canvas') return;
               lO[k] = slO[k];
            });
            myCanvas.drawContext(lO);
            myCanvas.activeLayer = sourceCanvas.activeLayer;
            return myCanvas;

         });
      }

      // STANDARD COPY
      if (!deep) {
         await (async () => {
            for (let i = 0; i < sourceCanvas.layers.length; i++) {
               const slO = sourceCanvas.layers[i];
               if (['helper', 'safearea'].includes(slO.type)) continue;
               console.log(slO.name);
               // Crea Layer
               if (!['main', 'blurTile'].includes(slO.type)) myCanvas.addLayer(slO.name, slO.type, slO.uiDisplay);
               const lO = myCanvas.layers.find((l) => l.name == slO.name);
               console.log(lO);
               const sourceSize = Math.min(slO.layerw, slO.layerh); // per il rapporto di scala

               if (slO.text) {
                  const tO = Object.assign({}, slO.text);
                  tO.x = myCanvas.dynamicSize(tO.x, slO.layerw, 'w');
                  tO.y = myCanvas.dynamicSize(tO.y, slO.layerh, 'h');
                  tO.size = myCanvas.dynamicSize(tO.size, slO.layerh, 'h');
                  tO.lineHeight = myCanvas.dynamicSize(tO.lineHeight, slO.layerh, 'h');
                  tO.w = myCanvas.dynamicSize(tO.w, slO.layerw, 'w');
                  tO.h = myCanvas.dynamicSize(tO.h, slO.layerh, 'h');
                  myCanvas.layerText(lO.name, tO, true);

               } else if (slO.gradient) {
                  await myCanvas.layerGradient(lO.name, slO.gradient);

               } else if (slO.channel) {
                  await myCanvas.macroChannelLogo(false, slO.channel.idChannel, slO.channel.tipo);

               } else if (slO.sportImage) {
                  console.log(slO.sportImage);

               } else if (slO.sportMatch) {
                  console.log(slO.sportMatch);

               } else if (slO.episodeNumber) {
                  await myCanvas.macroEpisodeNumber(slO.episodeNumber.epn, slO.episodeNumber.tipo);

               } else if (slO.blurTile) {
                  await myCanvas.macroBlurTile(slO.blurTile.blur);

               } else if (slO.titleTemplate) {
                  const tOtt = Object.assign({}, slO.titleTemplate);
                  tOtt.marginX = myCanvas.dynamicSize(tOtt.marginX, sourceSize);
                  tOtt.marginY = myCanvas.dynamicSize(tOtt.marginY, sourceSize);
                  tOtt.size = myCanvas.dynamicSize(tOtt.size, sourceSize);
                  tOtt.sizeSub = myCanvas.dynamicSize(tOtt.sizeSub, sourceSize);
                  tOtt.lineHeight = myCanvas.dynamicSize(tOtt.lineHeight, sourceSize);
                  tOtt.lineHeightSub = myCanvas.dynamicSize(tOtt.lineHeightSub, sourceSize);
                  await myCanvas.macroTitle(tOtt, true);

               } else if (slO.titleLogo) {
                  console.log(slO.titleLogo);
                  const tOtl = Object.assign({}, slO.titleLogo);
                  tOtl.pos.x = isNaN(tOtl.pos.x) ? tOtl.pos.x : myCanvas.settings.width / 10;
                  tOtl.pos.y = isNaN(tOtl.pos.y) ? tOtl.pos.y : myCanvas.settings.height / 10;
                  await myCanvas.macroTitleLogo(false, tOtl);

               } else if (slO.img) {
                  const mode = slO.type == 'main' ? 'max' : 'fit';
                  const trasp = slO.type == 'main' ? false : true;
                  await myCanvas.insertImage(slO.img, mode, slO.name, trasp);

               }
            }
            myCanvas.activeLayer = sourceCanvas.activeLayer;
            return myCanvas;
         })();
      }
   };
   MyCanvas.prototype.mergeLayers = function (onStage, layerNamesArray, newName) {
      this.setState('SAME', 'mergeLayers');
      const myCanvas = this;
      const tempCan = createTempCan(myCanvas.settings.width, myCanvas.settings.height);
      const ctx = tempCan.getContext('2d');
      const lOtoMerge = layerNamesArray ? layerNamesArray.map((ln) => myCanvas.layers.find((l) => l.name == ln)) : myCanvas.layers;
      // Ridisegno i livelli
      lOtoMerge.forEach((lO) => {
         if (!lO) return;
         if (['helper', 'safearea'].includes(lO.type)) return;
         ctx.drawImage(lO.canvas, 0, 0, myCanvas.settings.width, myCanvas.settings.height);
      });
      if (!onStage) return tempCan;
      // Cancello i livelli e Disegno il livello unico
      if (layerNamesArray) {
         const lOnew = myCanvas.addLayer(newName ? newName : 'Merged', 'image');
         myCanvas.insertImage(tempCan, false, lOnew.name);
         myCanvas.deleteLayer(layerNamesArray);
         return lOnew;
      } else {
         myCanvas.insertImage(tempCan, false, 'bg');
         myCanvas.deleteLayer('all');
         return this;
      }
      // tempCan.parentNode.removeChild(tempCan);
   };
   MyCanvas.prototype.layerCenter = function (layerName) {
      this.setState('KO', 'layerCenter');
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      if (!lO || !lO.img) return;
      lO.dx = Math.round(lO.layerw / 2 - lO.dw / 2);
      lO.dy = Math.round(lO.layerh / 2 - lO.dh / 2);
      this.drawContext(lO);
      return this;
   };
   MyCanvas.prototype.layerMax = function (layerName) {
      this.setState('KO', 'showLayer');
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      if (!lO || !lO.img) return;
      lO.scale = Math.max(lO.layerw / lO.iw, lO.layerh / lO.ih);
      const scaledw = Math.round(lO.iw * lO.scale);
      const scaledh = Math.round(lO.ih * lO.scale);
      // Canvas Destination
      lO.dx = Math.round(lO.layerw / 2 - scaledw / 2);
      lO.dy = Math.round(lO.layerh / 2 - scaledh / 2);
      lO.dw = scaledw;
      lO.dh = scaledh;
      this.drawContext(lO);
      return this;
   };
   MyCanvas.prototype.layerMin = function (layerName) {
      this.setState('KO', 'layerMin');
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      if (!lO || !lO.img) return;
      lO.scale = Math.min(lO.layerw / lO.iw, lO.layerh / lO.ih);
      const scaledw = Math.round(lO.iw * lO.scale);
      const scaledh = Math.round(lO.ih * lO.scale);
      // Canvas Destination
      lO.dx = Math.round(lO.layerw / 2 - scaledw / 2);
      lO.dy = Math.round(lO.layerh / 2 - scaledh / 2);
      lO.dw = scaledw;
      lO.dh = scaledh;
      this.drawContext(lO);
      return this;
   };
   MyCanvas.prototype.layerMove = function (layerName, x, y, xReverse, yReverse) {
      const lO = this.layers.find((l) => l.name == layerName);
      if (!lO || !lO.img || lO.locked || this.settings.locked) return;
      if (lO.type != 'safearea') this.setState('KO', 'layerMove');
      // SINGOLO
      if (!x && x !== 0) x = lO.dx;
      if (!y && y !== 0) y = lO.dy;
      // CENTER
      if (x == 'center') x = Math.round(lO.layerw / 2 - lO.dw / 2);
      if (y == 'center') y = Math.round(lO.layerh / 2 - lO.dh / 2);
      // REVERSE
      if (xReverse) x = lO.layerw - lO.dw - x;
      if (yReverse) y = lO.layerh - lO.dh - y;
      // LIMITI MAIN
      if (lO.type == 'main') {
         const w = lO.dw;
         const h = lO.dh;
         const cw = lO.layerw;
         const ch = lO.layerh;
         // Limiti Posizione
         if (x > 0) x = 0;
         else if (x + w < cw) x = cw - w;
         if (y > 0) y = 0;
         else if (y + h < ch) y = ch - h;
      }
      if (lO.dx == x && lO.dy == y) return;
      // APPLY
      lO.dx = x;
      lO.dy = y;
      this.setBottomRightInfo('x:' + lO.dx + ' y:' + lO.dy);
      this.drawContext(lO);
   };
   MyCanvas.prototype.layerScale = function (layerName, newScale, ox, oy) {
      const lO = this.layers.find((l) => l.name == layerName);
      if (!lO || !lO.img || lO.locked || this.settings.locked) return;
      if (lO.type != 'safearea') this.setState('KO', 'layerScale');
      // console.log(layerName, newScale, ox, oy);
      if (typeof newScale === 'object') {
         // Scale Coverage
         if (newScale.coverage) {
            const area = lO.layerh * lO.layerw * newScale.coverage / 100;
            newScale.w = Math.sqrt((lO.iw / lO.ih) * area);
         }
         // Scale Proporzionale
         if (newScale.w) newScale = newScale.w / lO.iw;
         else if (newScale.h) newScale = newScale.h / lO.ih;
      }
      // Non posso scalare al contrario
      if (newScale <= 0.01) return;
      // Se non specifico x, y scalo in alto a sx
      if (!ox & ox !== 0) ox = lO.dx;
      if (!oy & oy !== 0) oy = lO.dy;
      const offsetX = (ox - lO.dx) / lO.scale * newScale;
      const offsetY = (oy - lO.dy) / lO.scale * newScale;
      let x = ox - Math.round(offsetX);
      let y = oy - Math.round(offsetY);
      let w = Math.round(lO.iw * newScale);
      let h = Math.round(lO.ih * newScale);
      if (lO.type == 'main') {
         const cw = lO.layerw;
         const ch = lO.layerh;
         // Limiti Dimensione
         if (w < cw || h < ch) {
            newScale = lO.scale;
            w = lO.dw;
            h = lO.dh;
         }
         // Limiti Posizione
         if (newScale == lO.scale) {
            x = lO.dx;
            y = lO.dy;
         } else {
            if (x > 0) x = 0;
            else if (x + w < cw) x = cw - w;
            if (y > 0) y = 0;
            else if (y + h < ch) y = ch - h;
         }
      }
      lO.scale = newScale;
      lO.dx = x;
      lO.dy = y;
      lO.dw = w;
      lO.dh = h;
      this.setBottomRightInfo(`${Math.round(lO.scale * 100)}% (${w}x${h})`);
      this.drawContext(lO);
   };
   MyCanvas.prototype.layerFlip = function (asse, layerName) {
      this.setState('KO', 'layerFlip');
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      if (!lO || !lO.img) return;
      // Temp Canvas
      const tempCan = document.createElement('canvas');
      tempCan.width = lO.img.width;
      tempCan.height = lO.img.height;
      document.body.appendChild(tempCan);
      // Flip Temp Canvas
      const sctx = tempCan.getContext('2d');
      let sx; let sy; let w; let h;
      if (!asse || asse == 'x') {
         sx = -1;
         sy = 1;
         w = lO.img.width * -1;
         h = lO.img.height;
      } else {
         sx = 1;
         sy = -1;
         w = lO.img.width;
         h = lO.img.height * -1;
      }
      sctx.save();
      sctx.scale(sx, sy);
      sctx.drawImage(lO.img, 0, 0, w, h);
      // Original Layer
      lO.img = tempCan;
      this.drawContext(lO);
      tempCan.remove();
   };
   MyCanvas.prototype.layerGradient = function (layerName, gO, w, h) {
      this.setState('KO', 'layerGradient');
      const myCanvas = this;
      const lO = this.layers.find((l) => l.name == layerName);
      const ctx = lO.canvas.getContext('2d');
      let gradient;
      lO.gradient = gO;
      // Disegno un gradiente grande come il layer
      const x = 0;
      const y = 0;
      if (!w) w = lO.layerw;
      if (!h) h = lO.layerh;
      console.log(w, h);
      if (gO.type == 'linearTB') {
         gradient = ctx.createLinearGradient(w / 2, y, w / 2, y + h);
      } else if (gO.type == 'linearLR') {
         gradient = ctx.createLinearGradient(x, h / 2, x + w, h / 2);
      } else if (gO.type == 'linearD') {
         gradient = ctx.createLinearGradient(x, y, w, h);
      } else if (gO.type == 'radial') {
         gradient = ctx.createRadialGradient(w / 2, h / 2, w / 2, w / 2, h / 2, 0);
      }
      const colori = [];
      Object.keys(gO).forEach((k) => {
         const match = /colore(\d{0,})$/gm.exec(k);
         if (!match || !match[1]) return;
         colori.push('rgba(' + this.hex2rgb(gO[k], gO['alpha' + match[1]] / 100).join(',') + ')');
      });
      let cstop = 0;
      colori.forEach((c) => {
         gradient.addColorStop(cstop, c);
         cstop += 1 / (colori.length - 1);
      });
      ctx.fillStyle = gradient;
      ctx.clearRect(0, 0, w, h);
      ctx.fillRect(x, y, w, h);
      // lO IMAGE
      return this.getImgFromLayer(lO.name, 0, 0, w, h).then((cimg) => {
         lO.img = cimg;
         lO.iw = cimg.width;
         lO.ih = cimg.height;
         // COORD
         if (!lO.scale && !lO.dx) {
            lO.scale = 1;
            lO.dx = 0;
            lO.dy = 0;
            lO.dw = w;
            lO.dh = h;
         } else {
            myCanvas.layerScale(lO.name, lO.scale);
            myCanvas.layerMove(lO.name, lO.dx, lO.dy);
         }
      });
   };
   MyCanvas.prototype.layerText = async function (layerName, tO, updateInterface, keep) {

      const myCanvas = this;
      const lO = this.layers.find((l) => l.name == layerName);
      // Stili Canvas
      if (tO.letterSpacing) lO.canvas.style.letterSpacing = tO.letterSpacing + 'px';
      const ctx = lO.canvas.getContext('2d');
      const font = tO.font.split(' ');
      if (lO.type != 'safearea') this.setState('KO', 'layerText');
      lO.text = tO;    // SCRIVO IL TESTO
      ctx.font = (font[1] ? font[1] + ' ' : '') + tO.size + 'px ' + font[0];
      ctx.fillStyle = 'rgba(' + this.hex2rgb(tO.color, tO.alpha / 100) + ')';
      if (!keep) ctx.clearRect(0, 0, lO.layerw, lO.layerh);
      else this.rasterize(lO.name);
      if (tO.borderSize) {
         ctx.lineWidth = tO.borderSize;
         ctx.strokeStyle = 'rgba(' + this.hex2rgb(tO.borderColor, tO.borderColorAlpha / 100) + ')';
         ctx.mlStrokeText(tO.text, tO.x, tO.y, tO.w, tO.h, tO.yAlign, tO.xAlign, tO.lineHeight);
      }
      if (tO.shadowAlpha && (tO.shadowX || tO.shadowY || tO.shadowBlur)) {
         ctx.shadowColor = 'rgba(' + this.hex2rgb(tO.shadowColor, tO.shadowAlpha / 100) + ')';
         ctx.shadowOffsetX = tO.shadowX;
         ctx.shadowOffsetY = tO.shadowY;
         ctx.shadowBlur = tO.shadowBlur;
      }
      const resultSize = ctx.mlFillText(tO.text, tO.x, tO.y, tO.w, tO.h, tO.yAlign, tO.xAlign, tO.lineHeight);
      // lO IMAGE
      const cimg = await this.getImgFromLayer(lO.name, 0, 0, lO.layerw, lO.layerh);
      lO.img = cimg;
      lO.iw = cimg.width;
      lO.ih = cimg.height;
      // COORD
      if (!lO.scale && !lO.dx) {
         lO.scale = 1;
         lO.dx = 0;
         lO.dy = 0;
         lO.dw = lO.layerw;
         lO.dh = lO.layerh;
      } else {
         myCanvas.layerScale(lO.name, lO.scale);
         myCanvas.layerMove(lO.name, lO.dx, lO.dy);
      }
      myCanvas.drawContext(lO);
      return resultSize;
      // if (updateInterface) this.renderLayersMenu('update');
   };
   MyCanvas.prototype.layerMask = async function (layerName, maskUrl) {
      const myCanvas = this;
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      if (!lO || !lO.img) return;
      if (!maskUrl) maskUrl = _myCanvas.mySite + '/datatv/myCanvas/' + 'mask-punchOut.png';

      const ctx = lO.canvas.getContext('2d');

      ctx.save();
      ctx.globalCompositeOperation = 'destination-in';
      await myCanvas.insertImage(maskUrl, 'fit', lO.name, true, true);

      ctx.restore();
      myCanvas.rasterize(lO.name);
   };
   MyCanvas.prototype.renderSafeArea = function (imgUrl, title, text, min) {
      this.setState('SAME', 'renderSafeArea');
      if (imgUrl) {
         if (!this.layers.find((l) => l.name == 'safe-area')) {
            this.addLayer('safe-area', 'safearea');
         }
         this.insertImage(imgUrl, 'max', 'safe-area');
      }
      if (title) {
         const areaTitle = {
            font: 'skytext 200',
            size: 50,
            color: '#ffffff',
            alpha: 100,
            borderSize: 0,
            borderColor: '#000000',
            borderColorAlpha: 0,
            text: 'Omnium rerum principia parva sunt',
            xAlign: 'left',
            yAlign: 'top',
            lineHeight: 50,
            x: 92,
            y: 230,
            w: 1780,
            h: 100,
            shadowColor: '#000000',
            shadowAlpha: 100,
            shadowBlur: 2,
            shadowX: 2,
            shadowY: 2,
         };
         if (!this.layers.find((l) => l.name == 'safe-area-title')) {
            this.addLayer('safe-area-title', 'safearea', false);
         }
         if (title !== true) areaTitle.text = title;
         this.layerText('safe-area-title', areaTitle);
      }
      if (text) {
         const areaText = {
            font: 'skytext 200',
            size: 36,
            color: '#ffffff',
            alpha: 100,
            borderSize: 0,
            borderColor: '#000000',
            borderColorAlpha: 0,
            text: '000m\nDisponibile\nLorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea',
            xAlign: 'left',
            yAlign: 'top',
            lineHeight: 45,
            x: 95,
            y: 285,
            w: 1450,
            h: 450,
         };
         if (!this.layers.find((l) => l.name == 'safe-area-text')) {
            this.addLayer('safe-area-text', 'safearea', false);
         }
         if (text !== true) areaText.text = (min ? min : '000') + 'm\nDisponibile\n' + text;
         this.layerText('safe-area-text', areaText);
      }
   };
   MyCanvas.prototype.changeSize = function (w, h) {
      this.setState('KO', 'changeSize');
      this.settings.width = w;
      this.settings.height = h;
      this.layers.forEach((lO) => {
         lO.layerw = w;
         lO.layerh = h;
         lO.canvas.width = w;
         lO.canvas.height = h;
         if (lO.img) this.layerMax(lO.name); // this.layerCenter(lO.name);
      });
      const lO = this.layers.find((l) => l.name == 'bg');
      this.viewScale = lO.canvas.clientWidth / this.settings.width;
   };
   MyCanvas.prototype.toUrl = async function () {
      const myCanvas = this;

      return new Promise((resolve, reject) => {
         myCanvas.mergeLayers().toBlob(function (blob) {
            const canUrl = URL.createObjectURL(blob);
            resolve(canUrl);

         }, 'image/jpeg', 0.75);

      });
   };
   MyCanvas.prototype.download = async function (type, nome, w, h) {
      this.setState('SAME', 'download');
      const myCanvas = this;
      const settings = myCanvas.settings;

      if (!nome) {
         let pInfo = settings.progInfo;
         nome = pInfo && pInfo.title ? pInfo.title + '_' : '';
         nome += (pInfo && pInfo.idProgram ? 'idp' + pInfo.idProgram + '_' : '');
         nome += (pInfo && pInfo.idEpisode ? 'ide' + pInfo.idEpisode + '_' : '');
         nome += (settings.id ? settings.id + '_' : '');
         nome += settings.width + 'x' + settings.height;
      }

      console.log('Download', settings.name);

      if (w && h) {
         let myTempCan = document.createElement('div');
         const uClass = 'myTempCan_' + nome + '_' + Date.now();
         myTempCan.classList.add(uClass);
         myCanvas.wrapper.parentNode.insertBefore(myTempCan, null);
         myTempCan = await _myCanvas.init({
            selector: '.' + uClass,
            width: w,
            height: h,
            maxHeight: settings.maxHeight,
            progInfo: settings.progInfo,
         }).then((mc) => mc[0]);
         await myTempCan.cloneAll(myCanvas);
         myTempCan.download();
         myTempCan.wrapper.remove();
         return;
      }

      if (!type || type == 'jpg') {
         myCanvas.mergeLayers().toBlob(function (blob) {
            const aimg = document.createElement('a');
            aimg.download = nome + '.jpg';
            aimg.style.display = 'none';
            aimg.href = URL.createObjectURL(blob);
            aimg.click();
         }, 'image/jpeg', 0.9);

      } else if (type == 'png') {
         myCanvas.mergeLayers().toBlob(function (blob) {
            const aimg = document.createElement('a');
            aimg.download = nome + '.png';
            aimg.style.display = 'none';
            aimg.href = URL.createObjectURL(blob);
            aimg.click();
         }, 'image/png');

      } else if (type == 'layers') {
         myCanvas.layers.forEach((lO, i) => {
            if (['helper', 'safearea'].includes(lO.type)) return;
            lO.canvas.toBlob(function (blob) {
               const aimg = document.createElement('a');
               aimg.download = nome + '_layer' + i + '.png';
               aimg.style.display = 'none';
               aimg.href = URL.createObjectURL(blob);
               aimg.click();
            }, 'image/png');

         });
      }
   };
   MyCanvas.prototype.saveImage = function (name, folder, ext) {

      // override logo png
      if (this.settings.id == 'imageP') {
         ext = 'png';
      }

      this.setState('SAME', 'saveImage');
      if (!name && this.settings.fileName) name = this.settings.fileName;
      if (!folder && this.settings.folder) folder = this.settings.folder;
      if (!ext && this.settings.fileType) ext = this.settings.fileType;
      if (!ext || !name || !folder) {
         console.error('Per Salvare Mancano name, folder o ext');
         return;
      }

       // Replace forbidden characters from windows filenames
        name = name.replace(/[<>:"/\\|?*\x00-\x1F]/gi, '');
        folder = folder.replace(/[<>:"\\|?*\x00-\x1F]/gi, ''); // removed slash to save images.content/personaggi

      if (ext == 'png') ext = 'png';

      let quality = 0.75;
      if (!isNaN(ext)) {
         quality = ext;
         ext = 'jpeg';
      } else {
         ext = ext.toLowerCase;
         if (ext == 'jpg') ext = 'jpeg';
      }
      this.message('Salvataggio Immagine...');
      const canvas = this.mergeLayers();
      const base64 = canvas.toDataURL('image/' + ext, quality);
      const formD = new FormData();
      formD.append('base64', JSON.stringify([base64]));
      formD.append('folderName', folder);
      formD.append('filesData', JSON.stringify([{
         name: name,
      }]));


      return $.ajax({
         type: 'POST',
         url: `${mySite}/uploads`,
         beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + myAuth.getCookie('tools.datatv.it'));
         },
         enctype: 'multipart/form-data',
         processData: false,
         contentType: false,
         data: formD,
      }).then((r) => {
         this.message();
         return r;
      });
   };
   MyCanvas.prototype.getChannelLogo = async function (idCh, tipo) {
      if (!_myCanvas.channelLogos) {
         const load = await $.ajax({
            type: 'GET',
            url: mySite + '/channelsimages?$limit=1000',
         });
         let logos = load && load.data ? load.data : [];
         logos = myFunc.orderObjByKey(logos, 'channelName');
         _myCanvas.channelLogos = [];
         logos.forEach((logo) => {
            logo.name = logo.channelName;
            logo.url = mySite + '/' + logo.folder + '/' + logo.image;
            _myCanvas.channelLogos.push(logo);
         });
      }
      // console.log('getChannelLogo:', idCh, tipo);
      if (!tipo) tipo = 'logoContentScuro';
      let cho = _myCanvas.channelLogos.find((c) => c.idChannel == idCh && c.tipo == tipo);
      if (!cho && tipo.indexOf('Content') > -1) cho = _myCanvas.channelLogos.find((c) => c.idChannel == idCh && c.tipo == 'logoContentScuro');
      if (!cho) cho = _myCanvas.channelLogos.find((c) => c.idChannel == idCh && c.tipo == 'logo');
      // console.log('getChannelLogo found:', idCh, tipo, cho);

      return cho ? cho : false;
   };

   // NUOVE FUNZIONI SPORT //
   MyCanvas.prototype.getSportImgs = async function (txt, competition, idProgram, idProgramBase) {
      // Roma, Serie A | idProgram | idProgramBase
      // Se non ho mai caricato le competizioni le carico prima
      if (!_myCanvas.competitions) {
         _myCanvas.competitions = await $.ajax({ type: 'GET', url: `${mySite}/sportsimages?$limit=1000&$sort[competition]=1&tipo=1` }).then((r) => r.data);
      }

      // CERCO LA COMPETIZIONE
      const competitions = _myCanvas.competitions;
      let competizione;

      if (idProgram && idProgram != -1) {
         competizione = competitions.find((c) => c.idProgram == idProgram);

      } else if (idProgramBase && idProgramBase != -1) {
         competizione = competitions.find((c) => c.idProgramBase == idProgramBase);

      } else if (competition) {
         const fuseOpt = {
            includeScore: true,
            includeMatches: true,
            threshold: 0.25,
            minMatchCharLength: 3,
            shouldSort: true,
            keys: ['competition'],
         };

         const fuse = new Fuse(competitions, fuseOpt);
         const fuseSearch = fuse.search(competition);
         competizione = fuseSearch[0] ? fuseSearch[0].item : false;
      }

      // Se non ho trovato la competizione esco
      if (!competizione) {
         console.log('[getSportImg] >>> Competizione NON TROVATA');
         return false;
      }

      console.log('[getSportImg] >>> Competizione:', competizione);

      // Se non ho mai caricato le squadre della competizione le carico
      if (!competizione.teams || !competizione.teams.length) {
         const idFallback = competizione.template && competizione.template.fallback ? competizione.template.fallback.split('=')[1] : null;
         competizione.teams = [];

         // Carico le squadre dal valore fallback in idProgram
         if (!competizione.teams.length && idFallback) {
            competizione.teams = await $.ajax({ type: 'GET', url: `${mySite}/sportsimages?$limit=1000&$sort[teamName]=1&tipo=2&idProgram=${idFallback}` })
            .then((r) => r.data);
         }

         // Carico le squadre dal valore fallback in idProgramBase
         if (!competizione.teams.length && idFallback) {
            competizione.teams = await $.ajax({ type: 'GET', url: `${mySite}/sportsimages?$limit=1000&$sort[teamName]=1&tipo=2&idProgramBase=${idFallback}` })
            .then((r) => r.data);
         }

         // Carico le squadre dal Programma
         if (!competizione.teams.length &&competizione.idProgram && competizione.idProgram != -1) {
            competizione.teams = await $.ajax({ type: 'GET', url: `${mySite}/sportsimages?$limit=1000&$sort[teamName]=1&tipo=2&idProgram=${competizione.idProgram}` })
            .then((r) => r.data);
         }

         // Carico le squadre dalla Base
         if (!competizione.teams.length && competizione.idProgramBase && competizione.idProgramBase != -1) {
            competizione.teams = await $.ajax({ type: 'GET', url: `${mySite}/sportsimages?$limit=1000&$sort[teamName]=1&tipo=2&idProgramBase=${competizione.idProgramBase}` })
            .then((r) => r.data);
         }
      }

      // Se non ho richiesto una squadra restituisco la competizione
      if (!txt) {
         return { competizione: competizione };
      }

      // CERCO LE SQUADRE E IL TURNO
      let team1, team2, turn;

      if (!/\ - /gi.test(txt)) {
         // se il testo non contiene un trattino cerco solo una squadra
         team1 = searchTeam(txt, competizione.teams);
         console.log('[getSportImg] >>> Team1:', team1);

      } else {
         // se il testo contiene un trattino cerco due squadre
         let parts = txt.split(' - ');
         team1 = searchTeam(parts[0], competizione.teams);
         team2 = searchTeam(parts[1], competizione.teams);
         console.log('[getSportImg] >>> Team1:', team1);
         console.log('[getSportImg] >>> Team2:', team2);
      }

      // CERCO IL TURNO
      if (this.settings.progInfo.turn) {
         // Se ho l'informazione
         turn = this.settings.progInfo.turn;

      } else if (team1 && team2 && txt.indexOf('.') > -1) {

         // Se ho trovato le squadre vedo il testo che rimane
         let tempt = txt
            .replace(new RegExp('' + team1.searchName + '', 'gi'), '')
            .replace(new RegExp('' + team2.searchName + '', 'gi'), '')
            .trim()
            .replace(/-/gi, '') // Elimina i trattini
            .replace(/^\./gi, '') // Elimina i punti all'inizio
            .replace(/\.$/gi, '') // Elimina i punti alla fine
            .replace(/ \./gi, '') // Elimina i punti con uno spazio davanti
            .replace(/  /gi, ' ') // elimina doppi spazi
            .trim();

         // se ci sono ancora punti perndo la stringa dopo l'ultimo punto
         if (tempt.indexOf('.') > -1) {
            tempt = tempt.split('.').pop();
         }

         // se il turno che ho ricavato non è parte di una delle due squadre lo uso
         if (team1.searchName.indexOf(tempt.toLowerCase()) < 0 && team1.searchName.indexOf(tempt.toLowerCase())) {
            turn = tempt;
         }

      } else if (!team1 && !team2) {
         // Se non ho trovato le squadre considero tutto turno
         turn = txt;
      }

      if (turn) {
         // Se il turno è 1a g. lo scrivo per esteso
         if (/\d{1,}a g/i.test(turn)) {
            turn = /(\d{1,})a g/i.exec(turn)[1] + 'a Giornata';
         }

         // Se il turno è lungo cambio le dimensioni e lo mando a capo sui punti

         // 24-09-2021 Disabilitato per i nuovi template
         // if (turn.length > 12 && this.settings.id != 'imageE_dazn') {
         //    const lastWord = turn.split(/(\.| )/gi)[turn.split(/(\.| )/gi).length - 1];
         //    turn = turn.replace(lastWord, '\n' + lastWord);
         // }

         competizione.foundTurn = turn;
         console.log('[getSportImg] >>> Turn:', competizione.foundTurn);
      }

      return {
         competizione: competizione,
         team1: team1,
         team2: team2,
         turn: turn,
      };

      function searchTeam(txt, teams) {

         // normalizzo il testo cercato
         const cleanTxt = txt.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // rimuove gli accenti
            .replace(/[^A-Z0-9\s]/gi, '*') // sostituisce quello che non sono lettere numeri spazi con #
            .replace(/  /gi, ' ') // elimina doppi spazi
            .trim()
            .toLowerCase();

         // Normalizzo i nomi delle squadre
         const cleanTeams = [];
         teams.forEach(t => {
            t.searchName = t.teamName.normalize('NFD')
               .replace(/[\u0300-\u036f]/g, '') // rimuove gli accenti
               .replace(/[^A-Z0-9\s]/gi, '*') // sostituisce quello che non sono lettere numeri spazi con #
               .replace(/  /gi, ' ') // elimina doppi spazi
               .trim()
               .toLowerCase();

            cleanTeams.push(t);

            // Aggiungo le squadre con gli altrin nomi
            if (t.teamOtherNames && t.teamOtherNames.length) {
               t.teamOtherNames.forEach(ton => {

                  const oTeam = Object.assign({}, t);
                  oTeam.searchName = ton.normalize('NFD')
                     .replace(/[\u0300-\u036f]/g, '') // rimuove gli accenti
                     .replace(/[^A-Z0-9 ]/gi, '*') // sostituisce quello che non sono lettere numeri spazi con #
                     .replace(/  /gi, ' ') // elimina doppi spazi
                     .trim()
                     .toLowerCase();

                  cleanTeams.push(oTeam);
               });
            }
         });

         // Cerco la squadra
         const cleanTeamsOrdered = _.orderBy(cleanTeams, [(t) => t.searchName.length], ['desc'])
         // 30-08-2023 Modificato con match completo su richiesta di Babbi
         // const matches = cleanTeamsOrdered.filter(t => t.searchName.indexOf(cleanTxt) > -1 || cleanTxt.indexOf(t.searchName) > -1);
         const matches = cleanTeamsOrdered.filter(t => t.searchName == cleanTxt);
         let found = null;

         // Prendo la squadra con il nome più corto
         if (matches && matches.length) {
            found = _.orderBy(matches, [(t) => myFunc.getStringSimilarityScore('pisa', t.searchName).score], ['desc'])[0];
         }

         console.log('cleanTxt', cleanTxt, 'cleanTeamsOrdered', cleanTeamsOrdered, 'found', found);
         return found;
      }
   };
   MyCanvas.prototype.macroSportMatch = async function (sportMatch, multiLayer, progInfo) {
      // 02/10/2019 Babbi Non compongo mai la Hero Sky
      if (this.settings.id == 'imageF') return;
      if (!progInfo) progInfo = this.settings.progInfo;

      if (!sportMatch) {
         const title = progInfo.episodeTitle ? progInfo.episodeTitle : progInfo.title ? progInfo.title : '';
         sportMatch = await this.getSportImgs(title, false, progInfo.idProgram, progInfo.idSerie);
      }

      console.log('[macroSportMatch] >>> sportMatch:', sportMatch);
      if (!sportMatch) return;

      const template = _myCanvas.sportTemplates[sportMatch.competizione.template.template][this.settings.id.split('_')[0]];
      if (!template) return;

      this.setState('Ko', 'macroSportMatch');

      // Elimino i vecchi livelli sport
      const toDel = this.layers.filter((l) => l.type == 'sport');
      this.deleteLayer(toDel.map((l) => l.name));

      // Determino lo sfondo necessario
      const orientamento = this.settings.width > this.settings.height ? 'orizzontale' : 'verticale';
      const isDAZN = this.settings.id.indexOf('dazn') > -1;
      let neededBg = this.settings.id.indexOf('imageF') > -1 ? 'background_H' :
         orientamento == 'orizzontale' ? 'background_O' : 'background_V';
      let canBg = '';
      if (isDAZN) canBg = sportMatch.competizione.images ? sportMatch.competizione.images.find((i) => i.indexOf(neededBg) > -1 && i.indexOf('dazn') > -1) : null;
      else canBg = sportMatch.competizione.images ? sportMatch.competizione.images.find((i) => i.indexOf(neededBg) > -1 && i.indexOf('dazn') < 0) : null;

      if (!canBg) {
         console.log('[macroSportMatch] >>> Sfondo Competizione non trovato:', sportMatch.competizione.images);
         return;
      }

      // DISEGNO
      if (sportMatch.competizione) {
         var lObg = this.addLayer(sportMatch.competizione.competition, 'sport');
         lObg.sportImage = sportMatch.competizione;
         lObg.sportImage.url = `${mySite}/${sportMatch.competizione.folder}/${canBg}`;
         await this.insertImage(lObg.sportImage.url, 'max', lObg.name);
      }

      if (sportMatch.team1) {
         var lOteam1 = this.addLayer(sportMatch.team1.teamName, 'sport');
         lOteam1.sportImage = sportMatch.team1;
         lOteam1.sportImage.url = `${mySite}/${sportMatch.team1.folder}/${sportMatch.team1.images[0]}`;
         await this.insertImage(lOteam1.sportImage.url, template.team1, lOteam1.name);
      }

      if (sportMatch.team2) {
         var lOteam2 = this.addLayer(sportMatch.team2.teamName, 'sport');
         lOteam2.sportImage = sportMatch.team2;
         lOteam2.sportImage.url = `${mySite}/${sportMatch.team2.folder}/${sportMatch.team2.images[0]}`;
         await this.insertImage(lOteam2.sportImage.url, template.team2, lOteam2.name);
      }

      if (sportMatch.turn && template.turn && sportMatch.competizione.template.font) {
         const tt = template.turn;
         const font = sportMatch.competizione.template.font;
         var lOturn = this.addLayer(sportMatch.turn, 'sport');
         let turnScale = 1;
         let turnHeight = 1;
         if (/[\r\n]/.test(sportMatch.turn)) {
            turnScale = 0.75;
            turnHeight = 2;
         }
         await this.layerText(lOturn.name, {
            text: sportMatch.turn.toUpperCase(),
            x: tt.x,
            y: tt.y,
            w: tt.w,
            h: tt.h * turnHeight,
            yAlign: tt.yAlign,
            xAlign: tt.xAlign,
            lineHeight: tt.lineHeight ? tt.lineHeight * turnScale : tt.size * turnScale,
            font: isDAZN ? 'DAZNTrim-SemiBold' : font,
            size: tt.size * turnScale,
            color: isDAZN ? '#02131C' : sportMatch.competizione.template.fontColor ? sportMatch.competizione.template.fontColor : tt.color,
            alpha: tt.alpha,
            borderColor: '#000000',
            borderColorAlpha: 0,
            borderSize: 0,
         }, false);
         this.activeLayer = lOturn.name;
      }
      this.setSource('Macro Sport');

      if (!multiLayer) {
         const toMerge = [];
         if (lObg) toMerge.push(lObg.name);
         if (lOteam1) toMerge.push(lOteam1.name);
         if (lOteam2) toMerge.push(lOteam2.name);
         if (lOturn) toMerge.push(lOturn.name);

         const lOnew = this.mergeLayers(true, toMerge, 'Sport Match');
         lOnew.type = 'sport';
         lOnew.sportMatch = sportMatch;
         this.activeLayer = lOnew.name;
         return lOnew;
      }

      return this;

   };

   // VECCHIE //
   // MyCanvas.prototype.getSportImageOLD = async function (image, comp, idProgramBase) {
   //    // image = background_V, background_O, hero, logo, **teamname**

   //    if (!image) return false;
   //    if (!_myCanvas.sportsImages) {
   //       const load = await $.ajax({
   //          type: 'GET',
   //          url: mySite + '/sportsimages?$limit=1000',
   //       });
   //       _myCanvas.sportsImages = load ? load.data : [];
   //       _myCanvas.competitions = _myCanvas.sportsImages.filter((i) => i.tipo == 1);
   //       // Sort Competitions
   //       _myCanvas.competitions = _myCanvas.competitions.sort(function (a, b) {
   //          if (a.competition < b.competition) {
   //             return -1;
   //          }
   //          if (a.competition > b.competition) {
   //             return 1;
   //          }
   //          return 0;
   //       });
   //       // Nidifico competizioni e immagini
   //       _myCanvas.competitions.forEach((c, ci) => {
   //          if (!c.template) {
   //             const lastTemplate = Object.keys(_myCanvas.sportTemplates)[Object.keys(_myCanvas.sportTemplates).length - 1];
   //             c.template = JSON.stringify({ template: lastTemplate });
   //          }
   //          const template = c.template;
   //          const fallbackIdProgram = template.fallback ? template.fallback.split('=')[1] : false;
   //          // Immagini Competizione
   //          // console.log('Competizioni:', c);
   //          const cImgs = c.images ? c.images : [];
   //          // console.log('---------', c.images)
   //          const bkgs = cImgs.map((i, ind) => {
   //             return {
   //                id: -1 - ci * 1000 - ind,
   //                cid: c.id,
   //                competition: c.competition,
   //                template: c.template,
   //                img: i,
   //                imgName: i.split('_')[2].toUpperCase() + ' ' + i.split('_')[3].toUpperCase(),
   //                url: mySite + '/' + c.folder + '/' + i,
   //             };
   //          });

   //          // Squadre
   //          let teams = _myCanvas.sportsImages.filter((i) => {
   //             if (i.tipo == 2 && ((i.idProgram == c.idProgram && i.idProgramBase == c.idProgramBase) || (i.idProgram == fallbackIdProgram || i.idProgramBase == fallbackIdProgram))) {
   //                const tImgs = i.images;
   //                i.cid = c.id;
   //                i.competition = c.competition;
   //                i.img = tImgs[0];
   //                i.imgName = i.teamName;
   //                i.url = mySite + '/' + i.folder + '/' + i.img;
   //                return i;
   //             }
   //          });
   //          // Sort Squadre
   //          teams = teams.sort(function (a, b) {
   //             if (a.teamName < b.teamName) {
   //                return -1;
   //             }
   //             if (a.teamName > b.teamName) {
   //                return 1;
   //             }
   //             return 0;
   //          });
   //          // console.log('Sfondi e Squadre', bkgs, teams);
   //          if (bkgs.length && teams.length) c.imgs = [...bkgs, ...teams];
   //          else if (bkgs.length) c.imgs = bkgs;
   //          else if (teams.length) c.imgs = teams;

   //       });
   //       // console.log('Loaded competitions:', _myCanvas.competitions);
   //       // console.log('Loaded sportsImages', _myCanvas.sportsImages);
   //    }
   //    const comps = _myCanvas.competitions;
   //    let compRes; let imageRes;

   //    // Fuse Options
   //    const options = {
   //       // shouldSort: true,
   //       // tokenize: true,
   //       // includeScore: true,
   //       // includeMatches: true,
   //       // threshold: 0.2, // Era 0.25 ma aveva trovato Getafe quando non c'era Gent
   //       // location: 0,
   //       // distance: 100,
   //       // maxPatternLength: 32,
   //       // minMatchCharLength: 4,

   //       // isCaseSensitive: false,
   //       includeScore: true,
   //       shouldSort: true,
   //       includeMatches: true,
   //       // findAllMatches: false,
   //       minMatchCharLength: 3,
   //       location: 0,
   //       threshold: 0.25,
   //       distance: 100,
   //       // useExtendedSearch: true,
   //    };

   //    // Cerco la competizione per idProgram
   //    if (comp && !isNaN(comp)) compRes = comps.find((c) => c.idProgram == comp);
   //    // Cerco la competizione per idProgramBase
   //    if (!compRes && idProgramBase) compRes = comps.find((c) => c.idProgramBase == idProgramBase);
   //    // Cerco la competizione per Nome
   //    if (!compRes && comp && isNaN(comp)) {
   //       options.keys = ['competition'];
   //       const fuse = new Fuse(comps, options);
   //       const compSearch = fuse.search(comp);
   //       compRes = compSearch[0] ? compSearch[0].item : false;
   //    }



   //    // IMMAGINE
   //    if (compRes && ['dazn_background_V', 'dazn_background_O', 'dazn_background_H'].includes(image)) {
   //       const rexBgDazn = new RegExp('^(?=.*' + image + ').*', 'i');
   //       imageRes = compRes.imgs.find((i) => rexBgDazn.test(i.img));
   //       if (imageRes) imageRes.searched = image;

   //    } else if (compRes && ['background_V', 'background_O', 'background_H', 'logo'].includes(image)) {
   //       const rexBg = new RegExp('^(?=.*' + image + ')(?!.*dazn).*', 'i');
   //       imageRes = compRes.imgs.find((i) => rexBg.test(i.img));
   //       if (imageRes) imageRes.searched = image;

   //    } else if (compRes) {
   //       options.keys = ['teamName'];

   //       compRes.imgs.forEach((t) => {
   //          if (!t.teamOtherNames) return;
   //          t.teamOtherNames.forEach((o, i) => {
   //             t['t' + i] = o;
   //             if (!options.keys.includes('t' + i)) options.keys.push('t' + i);
   //          });
   //       });
   //       //console.log('Cerco La squadra dentro:', compRes.imgs, options.keys);


   //       const fuse = new Fuse(compRes.imgs, options);
   //       const imageSearch = fuse.search(image);
   //       imageRes = imageSearch[0] ? imageSearch[0].item : false;

   //       if (imageRes) imageRes.searched = image;
   //    };

   //    if (!imageRes) {
   //       this.message(`<i class="warning sign icon"></i>Immagini Sport non trovate <br>
   //    <a href="${mySite}/immaginiSport" target="_blank">Apri Immagini Sport</a>`, true);
   //    }

   //    console.log('getSportImage Immagine Trovata:', imageRes);
   //    return imageRes;
   // };
   // MyCanvas.prototype.macroSportMatchOLD = async function (sportMatch, multiLayer, progInfo) {
   //    if (myVars.io.username == 'Alvise') {
   //       this.macroSportMatchAl();
   //       return;
   //    }

   //    if (!progInfo) progInfo = this.settings.progInfo;
   //    const orientamento = this.settings.width > this.settings.height ? 'orizzontale' : 'verticale';
   //    const isDAZN = this.settings.id.indexOf('dazn') > -1;

   //    // 02/10/2019 Babbi Non compongo mai la Hero Sky
   //    if (this.settings.id == 'imageF') return;

   //    // LOAD BG/COMP
   //    if (!sportMatch) {
   //       let loadBg;
   //       if (this.settings.id.indexOf('imageF') > -1) loadBg = 'background_H';
   //       else if (orientamento == 'orizzontale') loadBg = 'background_O';
   //       else if (orientamento == 'verticale') loadBg = 'background_V';
   //       if (isDAZN) loadBg = 'dazn_' + loadBg;

   //       // IMMAGINE COMPETIZIONE
   //       const comp = await this.getSportImage(loadBg, progInfo.idProgram, progInfo.idSerie);
   //       if (!comp) {
   //          console.log('!!! macroSportMatch: Immagine Competizione non trovata!');
   //          return;
   //       }

   //       console.log(comp);

   //       // TITOLO
   //       const rTitle = progInfo.episodeTitle ? progInfo.episodeTitle : progInfo.title ? progInfo.title : false;
   //       if (!rTitle) {
   //          console.log('!!! macroSportMatch: Titolo non trovato!');
   //          return;
   //       }

   //       // Atalanta - Valencia. Ottavi. Andata
   //       const rexTeamsTurn = /^(.+) - ([^\.]+)(\. ?.*)?$/gi;
   //       let turn = '';
   //       let team1 = '';
   //       let team2 = '';
   //       console.log('TEAMS / TURN', rTitle, rexTeamsTurn.test(rTitle));
   //       rexTeamsTurn.lastIndex = 0;
   //       console.log('TEAMS / TURN', rTitle, rexTeamsTurn.exec(rTitle));
   //       rexTeamsTurn.lastIndex = 0;

   //       if (rexTeamsTurn.test(rTitle)) {
   //          rexTeamsTurn.lastIndex = 0;
   //          const teamsTurn = rexTeamsTurn.exec(rTitle);
   //          team1 = teamsTurn[1];
   //          team2 = teamsTurn[2] ? teamsTurn[2] : '';
   //          turn = teamsTurn[3] ? teamsTurn[3].replace(/(^\. ?|\. ?$)/gi, '') : '';
   //       }

   //       // Se non c'è il turno vedo se c'è il campo
   //       if (!turn && progInfo.turn) {
   //          turn = progInfo.turn;
   //       }

   //       // Se non c'è il turno e non ho trovato le squadre lo considero tutto tutno
   //       if (!turn && !team1) {
   //          turn = rTitle;
   //       }

   //       // Se il turno è 1a g. lo scrivo per esteso
   //       if (turn && /\d{1,}a g/i.test(turn)) {
   //          turn = /(\d{1,})a g/i.exec(turn)[1] + 'a Giornata';
   //       }

   //       // Se il turno è lungo cambio le dimensioni e lo mando a capo sui punti
   //       if (this.settings.id != 'imageE_dazn' && turn.length > 12) {
   //          const lastWord = turn.split(/(\.| )/gi)[turn.split(/(\.| )/gi).length - 1];
   //          turn = turn.replace(lastWord, '\n' + lastWord);
   //       }

   //       // IMMAGINI
   //       sportMatch = {
   //          bg: comp,
   //          team1: await this.getSportImage(team1, progInfo.idProgram, progInfo.idSerie),
   //          team2: await this.getSportImage(team2, progInfo.idProgram, progInfo.idSerie),
   //          turn: turn,
   //       };
   //    }

   //    console.log('sportMatch', sportMatch);
   //    if (!sportMatch) return;

   //    const template = _myCanvas.sportTemplates[sportMatch.bg.template.template][this.settings.id.split('_')[0]];
   //    console.log('Template:', this.settings.id, sportMatch.bg.template.template);
   //    if (!template) return;

   //    this.setState('Ko', 'macroSportMatch');

   //    // Elimino i vecchi livelli sport
   //    const toDel = this.layers.filter((l) => l.type == 'sport');
   //    this.deleteLayer(toDel.map((l) => l.name));

   //    // DISEGNO
   //    if (sportMatch.bg) {
   //       var lObg = this.addLayer(sportMatch.bg.competition, 'sport');
   //       lObg.sportImage = sportMatch.bg;
   //       await this.insertImage(sportMatch.bg.url, 'max', lObg.name);
   //    }
   //    if (sportMatch.team1 && sportMatch.team1.url) {
   //       var lOteam1 = this.addLayer(sportMatch.team1.teamName, 'sport');
   //       lOteam1.sportImage = sportMatch.team1;
   //       await this.insertImage(sportMatch.team1.url, template.team1, lOteam1.name);
   //    }
   //    if (sportMatch.team2 && sportMatch.team2.url) {
   //       var lOteam2 = this.addLayer(sportMatch.team2.teamName, 'sport');
   //       lOteam2.sportImage = sportMatch.team2;
   //       await this.insertImage(sportMatch.team2.url, template.team2, lOteam2.name);
   //    }
   //    if (sportMatch.turn && template.turn && sportMatch.bg.template.font) {
   //       const tt = template.turn;
   //       const font = sportMatch.bg.template.font;
   //       var lOturn = this.addLayer(sportMatch.turn, 'sport');
   //       let turnScale = 1;
   //       let turnHeight = 1;
   //       if (/[\r\n]/.test(sportMatch.turn)) {
   //          turnScale = 0.75;
   //          turnHeight = 2;
   //       }
   //       await this.layerText(lOturn.name, {
   //          text: sportMatch.turn.toUpperCase(),
   //          x: tt.x,
   //          y: tt.y,
   //          w: tt.w,
   //          h: tt.h * turnHeight,
   //          yAlign: tt.yAlign,
   //          xAlign: tt.xAlign,
   //          lineHeight: tt.lineHeight ? tt.lineHeight * turnScale : tt.size * turnScale,
   //          font: isDAZN ? 'DAZNTrim-SemiBold' : font,
   //          size: tt.size * turnScale,
   //          color: isDAZN ? '#02131C' : tt.color,
   //          alpha: tt.alpha,
   //          borderColor: '#000000',
   //          borderColorAlpha: 0,
   //          borderSize: 0,
   //       }, false);
   //       this.activeLayer = lOturn.name;
   //    }
   //    this.setSource('Macro Sport');

   //    if (!multiLayer) {
   //       const toMerge = [];
   //       if (lObg) toMerge.push(lObg.name);
   //       if (lOteam1) toMerge.push(lOteam1.name);
   //       if (lOteam2) toMerge.push(lOteam2.name);
   //       if (lOturn) toMerge.push(lOturn.name);

   //       const lOnew = this.mergeLayers(true, toMerge, 'Sport Match');
   //       lOnew.type = 'sport';
   //       lOnew.sportMatch = sportMatch;
   //       this.activeLayer = lOnew.name;
   //       return lOnew;
   //    }

   //    return this;
   // };

   // INUTILIZZATO
   // MyCanvas.prototype.getSportMatch = async function () {
   //    const sportMatch = {};
   //    const orientamento = this.settings.width > this.settings.height ? 'orizzontale' : 'verticale';
   //    const isDAZN = this.settings.id.indexOf('dazn') > -1;

   //    // Determino lo sfondo necessario
   //    let neededBg = this.settings.id.indexOf('imageF') > -1 ? 'background_H' :
   //       orientamento == 'orizzontale' ? 'background_O' : 'background_V';

   //    if (isDAZN) neededBg = 'dazn_' + neededBg;

   //    // Carico la Competizione
   //    sportMatch.competition = await this.getSportImage(neededBg, progInfo.idProgram, progInfo.idSerie);

   //    // Recupero le Squadre dal Titolo
   //    const title = progInfo.episodeTitle ? progInfo.episodeTitle : progInfo.title ? progInfo.title : false;
   //    const teams = getTeamsFromTitle();

   //    function getTeamsFromTitle(title) {
   //       // Es. Man. United - Valencia. Ottavi. Andata
   //    }


   //    // Atalanta - Valencia. Ottavi. Andata
   //    const rexTeamsTurn = /^(.+) - ([^\.]+)(\. ?.*)?$/gi;
   //    let turn = '';
   //    let team1 = '';
   //    let team2 = '';
   //    console.log('TEAMS / TURN', rTitle, rexTeamsTurn.test(rTitle));
   //    rexTeamsTurn.lastIndex = 0;
   //    console.log('TEAMS / TURN', rTitle, rexTeamsTurn.exec(rTitle));
   //    rexTeamsTurn.lastIndex = 0;

   //    if (rexTeamsTurn.test(rTitle)) {
   //       rexTeamsTurn.lastIndex = 0;
   //       const teamsTurn = rexTeamsTurn.exec(rTitle);
   //       team1 = teamsTurn[1];
   //       team2 = teamsTurn[2] ? teamsTurn[2] : '';
   //       turn = teamsTurn[3] ? teamsTurn[3].replace(/(^\. ?|\. ?$)/gi, '') : '';
   //    }

   //    // Se non c'è il turno vedo se c'è il campo
   //    if (!turn && progInfo.turn) {
   //       turn = progInfo.turn;
   //    }

   //    // Se non c'è il turno e non ho trovato le squadre lo considero tutto tutno
   //    if (!turn && !team1) {
   //       turn = rTitle;
   //    }

   //    // Se il turno è 1a g. lo scrivo per esteso
   //    if (turn && /\d{1,}a g/i.test(turn)) {
   //       turn = /(\d{1,})a g/i.exec(turn)[1] + 'a Giornata';
   //    }

   //    // Se il turno è lungo cambio le dimensioni e lo mando a capo sui punti
   //    if (this.settings.id != 'imageE_dazn' && turn.length > 12) {
   //       const lastWord = turn.split(/(\.| )/gi)[turn.split(/(\.| )/gi).length - 1];
   //       turn = turn.replace(lastWord, '\n' + lastWord);
   //    }

   //    // IMMAGINI
   //    sportMatch = {
   //       bg: comp,
   //       team1: await this.getSportImage(team1, progInfo.idProgram, progInfo.idSerie),
   //       team2: await this.getSportImage(team2, progInfo.idProgram, progInfo.idSerie),
   //       turn: turn,
   //    };
   // };

   MyCanvas.prototype.macroChannelLogo = async function (progInfo, chId, type, logoPos) {
      if (!progInfo) progInfo = this.settings.progInfo;
      if (!this.settings.channelLogo) return;

      // CANALE
      if (!chId && progInfo && progInfo.idProgram) {
         const getprog = await client.service('program-join').get(progInfo.idProgram, { query: { add: 'onAirChannel' } });
         chId = getprog.onAirChannel;
         // console.log('######### ', chId, getprog)
      }
      // POSIZIONE
      if (!logoPos) logoPos = this.settings.channelLogo[0];
      if (!logoPos) logoPos = { coverage: 1.45, x: 90, y: 90, xr: true, yr: true }; // In Basso a Destra

      // LUMINOSITA'
      const awh = Math.round(Math.sqrt(this.settings.width * this.settings.height * logoPos.coverage * 1.1 / 100));
      let ax = logoPos.x == 'center' ? this.settings.width / 2 - awh / 2 : logoPos.x;
      let ay = logoPos.y == 'center' ? this.settings.height / 2 - awh / 2 : logoPos.y;
      if (logoPos.xr) ax = this.settings.width - ax - awh;
      if (logoPos.yr) ay = this.settings.width - ay - awh;
      const brightness = this.getImageBrightness('bg', ax, ay, awh, awh);
      if (!type) type = brightness[1] == 'chiaro' ? 'logoContentScuro' : 'logoContentChiaro';

      // Se nuovi loghi Sky Applica Gradiente
      if ([393, 394, 395, 480, 284, 864, 875, 876, 877, 874, 698, 305, 411, 736, 753, 701, 311, 283, 925].includes(chId)) {
         await this.skyGradient();
      }

      // console.log(progInfo, chId, type, logoPos);
      if (typeof setChannelDropdown != 'undefined') setChannelDropdown(chId, type, logoPos);

      // Get Logo
      let logo = await this.getChannelLogo(chId, type);
      if (!logo) logo = await this.getChannelLogo(chId, 'logoContentScuro');
      if (!logo) logo = await this.getChannelLogo(chId, 'logo');
      // if (!logo) logo = await this.getChannelLogo('OD1', 'logoContentScuro');

      console.log('*** macroChannelLogo:', arguments, this);

      // Layer Logo
      let lO = this.layers.find((l) => l.type == 'channel');
      if (!lO) lO = this.addLayer(logo.name ? logo.name : 'Scegli Canale', 'channel');

      if (logo) {
         console.log('inserisco logo', logo, logoPos);
         // console.log('Resized', `https://tools.datatv.it/returnFile?fileName=${logo.image}&folderName=channelsimages&resizeHeight=100`);
         await this.insertImage(logo.url, { coverage: logoPos.coverage }, lO.name, true);
         await this.layerMove(lO.name, logoPos.x, logoPos.y, logoPos.xr, logoPos.yr);
         this.setState('KO', 'macroChannelLogo');
      }

      this.activeLayer = lO.name;
      lO.channel = logo ? logo : {};
      return lO;
   };
   MyCanvas.prototype.skyGradient = async function (force) {
      let areas = [];
      if (this.settings.id == 'imageG') {
         areas = [{
            name: 'topleft',
            xywh: [1600, 20, 300, 300],
         }, {
            name: 'bottomleft',
            xywh: [20, 760, 300, 300],
         }, {
            name: 'bottomRight',
            xywh: [1600, 760, 300, 300],
         }];

      } else if (this.settings.id == 'imageK') {
         areas = [{
            name: 'topleft',
            xywh: [1070, 10, 200, 200],
         }, {
            name: 'bottomleft',
            xywh: [10, 542, 200, 200],
         }, {
            name: 'bottomRight',
            xywh: [1070, 542, 200, 200],
         }];

      } else if (this.settings.id == 'imageL') {
         areas = [{
            name: 'top',
            xywh: [225, 10, 160, 100],
         }, {
            name: 'bottom',
            xywh: [225, 560, 160, 100],
         }];
      }

      let maxBrightness = 0;
      areas.forEach(async (a) => {
         a.brightness = this.getImageBrightness('bg', a.xywh[0], a.xywh[1], a.xywh[2], a.xywh[3]);
         if (a.brightness[0] > maxBrightness) maxBrightness = a.brightness[0];
      });

      // console.log('skyGradient', maxBrightness, areas)
      if (maxBrightness > 130 || force) {
         let lO = this.layers.find((l) => l.name == 'skyGradient');
         if (!lO) lO = this.addLayer('skyGradient');
         const gradUrl = this.settings.width > this.settings.height ?
            'https://tools.datatv.it/datatv/myCanvas/overlay-skyGradientHorizontal.png' :
            'https://tools.datatv.it/datatv/myCanvas/overlay-skyGradientVertical.png';

         await this.insertImage(gradUrl, 'max', lO.name);
      }
   };

   MyCanvas.prototype.macroTitle = async function (templateO, reDrawLayersMenu) {
      this.setState('KO', 'macroTitle');
      console.log('macroTitle', templateO, reDrawLayersMenu);

      // Elimino altri titoli
      const toDel = this.layers.filter((l) => l.type == 'title');
      this.deleteLayer(toDel.map((l) => l.name));

      // Template Object
      const canSettings = this.settings;
      let finalTemplate = templateO;

      // Forced Templates Amazon
      if (canSettings.id == 'imageT' || (canSettings.id == 'imageO' && canSettings.progInfo && canSettings.progInfo.ethanMetadatasets == 'LeiDove' && canSettings.progInfo.season)) {
         finalTemplate = Object.assign({ name: 'Caccia AMZ ST' }, _myCanvas.titleTemplates['Caccia AMZ ST']);
      }
      else if (canSettings.id == 'imageV') {
         finalTemplate = Object.assign({ name: 'Caccia AMZ Left' }, _myCanvas.titleTemplates['Caccia AMZ Left']);
      }
      else if (canSettings.id == 'imageU') {
         finalTemplate = Object.assign({ name: 'Caccia AMZ' }, _myCanvas.titleTemplates['Caccia AMZ']);
      }

      // Forced Templates Channel
      if (canSettings.progInfo && canSettings.progInfo.idProgram && !['imageU', 'imageV', 'imageT', 'imageM'].includes(canSettings.id) && !canSettings.service == 'Timvision') {
         const getprog = await client.service('program-join').get(canSettings.progInfo.idProgram, { query: { add: 'onAirChannel' } });
         const templateFound = Object.keys(_myCanvas.titleTemplates)
            .find((tt) => _myCanvas.titleTemplates[tt].idCh.includes(getprog.onAirChannel));

         if (templateFound) {
            finalTemplate = Object.assign({ name: templateFound }, _myCanvas.titleTemplates[templateFound]);
         }
      }

      // Base Templates
      if (!finalTemplate){
         if (canSettings.id == 'imageL') {
            finalTemplate = Object.assign({ name: 'Vodafone' }, _myCanvas.titleTemplates['Vodafone']);
         }
         else if (canSettings.id == 'imageM') {
            finalTemplate = Object.assign({ name: 'Sky TIM' }, _myCanvas.titleTemplates['Sky TIM']);
         }
         else if (canSettings.id == 'imageO') {
            finalTemplate = Object.assign({ name: 'Sky Scene Key Art' }, _myCanvas.titleTemplates['Sky Scene Key Art']);
         }
         // Servizi
         else if (canSettings.service == 'Timvision') {
            finalTemplate = Object.assign({ name: 'TIM' }, _myCanvas.titleTemplates['TIM']);
         }
         else {
            finalTemplate = Object.assign({ name: 'Sky Q' }, _myCanvas.titleTemplates['Sky Q']);
         }

      }

      // Aggiungo i parametri inseriti dall'utente
      if (templateO) {
         finalTemplate = Object.assign(finalTemplate, templateO);
      }

      // Dimensioni Dinamiche
      if (!finalTemplate.size)
         finalTemplate.size = this.dynamicSize(finalTemplate.dy_size);

      if (!finalTemplate.sizeSub)
         finalTemplate.sizeSub = this.dynamicSize(finalTemplate.dy_sizeSub ? finalTemplate.dy_sizeSub : finalTemplate.dy_size);

      if (!finalTemplate.marginX)
         finalTemplate.marginX = this.dynamicSize(finalTemplate.dy_marginX);

      if (!finalTemplate.marginY)
         finalTemplate.marginY = this.dynamicSize(finalTemplate.dy_marginY ? finalTemplate.dy_marginY : finalTemplate.dy_marginX);

      if (!finalTemplate.lineHeight)
         finalTemplate.lineHeight = this.dynamicSize(finalTemplate.dy_lineHeight);

      if (!finalTemplate.lineHeightSub)
         finalTemplate.lineHeightSub = this.dynamicSize(finalTemplate.dy_lineHeightSub ? finalTemplate.dy_lineHeightSub : finalTemplate.dy_lineHeight);

      // TITOLO
      if (!finalTemplate.title) {

         // Provo a prenderlo da ProgInfo
         if (canSettings.progInfo && canSettings.progInfo.title) {
            finalTemplate.title = canSettings.progInfo.title;

            // Se è previsto un sottotitolo
            if (finalTemplate.fontSub) {
               const rex = /(.+)(?:\:| - )(.+)/gi.exec(finalTemplate.title);
               if (rex && rex[1] && rex[2]) {
                  finalTemplate.title = rex[1].trim();
                  finalTemplate.subTitle = rex[2] ? rex[2].trim() : '';
               }
            }
         } else {
            finalTemplate.title = '';
            finalTemplate.subTitle = '';
         }

         // Stagione
         if (canSettings.progInfo && canSettings.progInfo.season && finalTemplate.title) {
            const st = canSettings.progInfo.season;

            // Se imageVG appendo il numero della stagione
            if (['imageVA', 'imageVG'].includes(canSettings.id))
               finalTemplate.title = finalTemplate.title + ' ' + st;

            // Se imageT metto il numero della stagione nel sottotitolo
            if (['imageT'].includes(canSettings.id))
               finalTemplate.subTitle = 'Stagione ' + st;

            if (['imageO'].includes(canSettings.id) && canSettings.progInfo.ethanMetadatasets == 'LeiDove')
               finalTemplate.subTitle = 'Stagione ' + st;
         }
      }

      console.log('finalTemplate', finalTemplate)

      // I layer
      const lOt = this.addLayer('Titolo', 'text');
      const lOst = this.addLayer('Sotto-Titolo', 'text');
      const lOg = this.addLayer('TitoloGrad', 'gradient');
      let tO = {};
      let stO = {};
      let titleSize = { width: 0, height: 0 };
      let subTitleSize = { width: 0, height: 0 };

      // Text Titolo
      if (finalTemplate.title) {
         tO = {
            text: finalTemplate.allCaps == 1 ? finalTemplate.title.toString().toUpperCase() : finalTemplate.title,
            x: finalTemplate.marginX,
            y: finalTemplate.marginY,
            w: this.settings.width - finalTemplate.marginX * 2,
            h: this.settings.height - finalTemplate.marginY * 2,
            yAlign: finalTemplate.yAlign,
            xAlign: finalTemplate.xAlign,
            lineHeight: finalTemplate.lineHeight,
            font: finalTemplate.font,
            size: finalTemplate.size,
            color: finalTemplate.color,
            alpha: finalTemplate.alpha,
            borderColor: '#000000',
            borderColorAlpha: 0,
            borderSize: 0,
         };

         // Text Shadow
         if (finalTemplate.shadow && finalTemplate.shadow !== 'none') {
            const shadow = _myCanvas.shadowTemplates[finalTemplate.shadow];
            tO.shadowColor = shadow.shadowColor;
            tO.shadowAlpha = shadow.shadowAlpha;
            tO.shadowX = this.dynamicSize(shadow.shadowX);
            tO.shadowY = this.dynamicSize(shadow.shadowY);
            tO.shadowBlur = this.dynamicSize(shadow.shadowBlur);
         }
         titleSize = await this.layerText(lOt.name, tO, false, true);
      }

      // Text SottoTitolo
      if (finalTemplate.title && finalTemplate.subTitle) {

         stO = Object.assign({}, tO);
         stO.text = finalTemplate.subTitle && finalTemplate.allCapsSub == 1 ? finalTemplate.subTitle.toString().toUpperCase() : finalTemplate.subTitle;
         stO.lineHeight = finalTemplate.lineHeightSub;
         stO.font = finalTemplate.fontSub,
         stO.size = finalTemplate.sizeSub;

         if (finalTemplate.fontSubColor) stO.color = finalTemplate.fontSubColor;

         subTitleSize = await this.layerText(lOst.name, stO, false, true);
         if (finalTemplate.yAlign == 'top') {
            await this.layerMove(lOst.name, false, lOt.dy + titleSize.height);
         } else if (finalTemplate.yAlign == 'bottom') {
            await this.layerMove(lOt.name, false, lOst.dy + subTitleSize.height, false, true);
         }
      }

      // Gradient
      if (finalTemplate.title) {
         const gw = this.settings.width;
         const gh = titleSize.height + subTitleSize.height + finalTemplate.marginY * 2;
         await this.layerGradient(lOg.name, {
            type: 'linearTB',
            colore0: '#000000',
            colore1: '#000000',
            colore2: '#000000',
            alpha0: finalTemplate.yAlign == 'top' ? finalTemplate.gradAlpha : 0,
            alpha1: finalTemplate.gradAlpha * 0.6,
            alpha2: finalTemplate.yAlign == 'bottom' ? finalTemplate.gradAlpha : 0,
         }, gw, gh);

         if (finalTemplate.yAlign == 'bottom') await this.layerMove(lOg.name, false, 0, false, true);
         await this.orderLayer(lOg.name, 0);
      }

      // Merge Layers
      const lOnew = this.mergeLayers(true, [lOg.name, lOt.name, lOst.name], 'Titolo Programma');
      lOnew.titleTemplate = finalTemplate;
      this.activeLayer = lOnew.name;
      lOnew.type = 'title';
      if (reDrawLayersMenu) this.renderLayersMenu('draw');
      return lOnew;
   }

   MyCanvas.prototype.macroTitleOld = async function (titleTemplate, reDrawLayersMenu) {
      this.setState('KO', 'macroTitle');
      console.log('macroTitle', progInfo, titleTemplate, reDrawLayersMenu);
      if (!progInfo) progInfo = this.settings.progInfo;

      // Elimino altri titoli
      const toDel = this.layers.filter((l) => l.type == 'title');
      this.deleteLayer(toDel.map((l) => l.name));

      // Amazon Forced
      if (this.settings.id == 'imageO' && progInfo && progInfo.ethanMetadatasets == 'LeiDove' && progInfo.season) {
         titleTemplate = Object.assign({ name: 'Caccia AMZ ST' }, _myCanvas.titleTemplates['Caccia AMZ ST']);
      }

      else if (this.settings.id == 'imageV') {
         titleTemplate = Object.assign({ name: 'Caccia AMZ Left' }, _myCanvas.titleTemplates['Caccia AMZ Left']);
      }

      else if (this.settings.id == 'imageU') {
         titleTemplate = Object.assign({ name: 'Caccia AMZ' }, _myCanvas.titleTemplates['Caccia AMZ']);
      }

      else if (this.settings.id == 'imageT') {
         titleTemplate = Object.assign({ name: 'Caccia AMZ ST' }, _myCanvas.titleTemplates['Caccia AMZ ST']);
      }

      // Template
      if (!titleTemplate) {

         // Formati
         if (this.settings.id == 'imageL') {
            titleTemplate = Object.assign({ name: 'Vodafone' }, _myCanvas.titleTemplates['Vodafone']);
         }

         else if (this.settings.id == 'imageM') {
            titleTemplate = Object.assign({ name: 'Sky TIM' }, _myCanvas.titleTemplates['Sky TIM']);
         }

         else if (this.settings.id == 'imageO') {
            titleTemplate = Object.assign({ name: 'Sky Scene Key Art' }, _myCanvas.titleTemplates['Sky Scene Key Art']);
         }

         // Servizi
         else if (this.settings.service == 'Timvision') {
            titleTemplate = Object.assign({ name: 'TIM' }, _myCanvas.titleTemplates['TIM']);
         }

         else {
            titleTemplate = Object.assign({ name: 'Sky Q' }, _myCanvas.titleTemplates['Sky Q']);
         }
      }

      // Template Canale
      if (progInfo && progInfo.idProgram && !['imageU', 'imageV', 'imageT', 'imageM'].includes(this.settings.id) && !this.settings.service == 'Timvision') {
         const getprog = await client.service('program-join').get(progInfo.idProgram, { query: { add: 'onAirChannel' } });
         const templateFound = Object.keys(_myCanvas.titleTemplates).find((tt) => _myCanvas.titleTemplates[tt].idCh.includes(getprog.onAirChannel));
         if (templateFound) {
            titleTemplate = Object.assign({ name: templateFound }, _myCanvas.titleTemplates[templateFound]);
         }
      }

      // TESTO
      if (!titleTemplate.title || (!titleTemplate.fontSub && titleTemplate.subTitle)) {
         if (progInfo && progInfo.title) {
            titleTemplate.title = progInfo.title;

            // 23/12/2022 Tolto numero stagione da imageL
            // if (this.settings.id == 'imageL' && progInfo.season && progInfo.season != 1) titleTemplate.title += ' ' + progInfo.season;

            // Sottotitolo
            if (titleTemplate.fontSub) {
               const rex = /(.+)(?:\:| - )(.+)/gi.exec(progInfo.title);
               if (rex && rex[1] && rex[2]) {
                  titleTemplate.title = rex[1].trim();
                  titleTemplate.subTitle = rex[2] ? rex[2].trim() : '';
               }
            } else {
               titleTemplate.subTitle = '';
            }

            // Se imageVG appendo il numero della stagione
            if (progInfo.season && ['imageVA', 'imageVG'].includes(this.settings.id))
               titleTemplate.title = titleTemplate.title + ' ' + progInfo.season;

            // Se imageT metto il numero della stagione nel sottotitolo
            if (progInfo.season && ['imageT'].includes(this.settings.id))
               titleTemplate.subTitle = 'Stagione ' + progInfo.season;

            if(progInfo.season && ['imageO'].includes(this.settings.id) && progInfo.ethanMetadatasets == 'LeiDove')
               titleTemplate.subTitle = 'Stagione ' + progInfo.season;

         } else {
            titleTemplate.title = '';
         }
      }
      // Dimensioni Dinamiche
      if (!titleTemplate.size) titleTemplate.size = this.dynamicSize(titleTemplate.dy_size);
      if (!titleTemplate.sizeSub) titleTemplate.sizeSub = this.dynamicSize(titleTemplate.dy_sizeSub ? titleTemplate.dy_sizeSub : titleTemplate.dy_size);
      if (!titleTemplate.marginX) titleTemplate.marginX = this.dynamicSize(titleTemplate.dy_marginX);
      if (!titleTemplate.marginY) titleTemplate.marginY = this.dynamicSize(titleTemplate.dy_marginY ? titleTemplate.dy_marginY : titleTemplate.dy_marginX);
      if (!titleTemplate.lineHeight) titleTemplate.lineHeight = this.dynamicSize(titleTemplate.dy_lineHeight);
      if (!titleTemplate.lineHeightSub) titleTemplate.lineHeightSub = this.dynamicSize(titleTemplate.dy_lineHeightSub ? titleTemplate.dy_lineHeightSub : titleTemplate.dy_lineHeight);

      // I layer
      const lOt = this.addLayer('Titolo', 'text');
      const lOst = this.addLayer('Sotto-Titolo', 'text');
      const lOg = this.addLayer('TitoloGrad', 'gradient');
      let tO = {};
      let stO = {};
      let titleSize = { width: 0, height: 0 };
      let subTitleSize = { width: 0, height: 0 };

      // Text Titolo
      if (titleTemplate.title) {
         tO = {
            text: titleTemplate.allCaps == 1 ? titleTemplate.title.toString().toUpperCase() : titleTemplate.title,
            x: titleTemplate.marginX,
            y: titleTemplate.marginY,
            w: this.settings.width - titleTemplate.marginX * 2,
            h: this.settings.height - titleTemplate.marginY * 2,
            yAlign: titleTemplate.yAlign,
            xAlign: titleTemplate.xAlign,
            lineHeight: titleTemplate.lineHeight,
            font: titleTemplate.font,
            size: titleTemplate.size,
            color: titleTemplate.color,
            alpha: titleTemplate.alpha,
            borderColor: '#000000',
            borderColorAlpha: 0,
            borderSize: 0,
         };

         // Text Shadow
         if (titleTemplate.shadow && titleTemplate.shadow !== 'none') {
            const shadow = _myCanvas.shadowTemplates[titleTemplate.shadow];
            tO.shadowColor = shadow.shadowColor;
            tO.shadowAlpha = shadow.shadowAlpha;
            tO.shadowX = this.dynamicSize(shadow.shadowX);
            tO.shadowY = this.dynamicSize(shadow.shadowY);
            tO.shadowBlur = this.dynamicSize(shadow.shadowBlur);
         }
         titleSize = await this.layerText(lOt.name, tO, false, true);
      }

      // Text SottoTitolo
      if (titleTemplate.title && titleTemplate.subTitle) {

         stO = Object.assign({}, tO);
         stO.text = titleTemplate.subTitle && titleTemplate.allCapsSub == 1 ? titleTemplate.subTitle.toString().toUpperCase() : titleTemplate.subTitle;
         stO.lineHeight = titleTemplate.lineHeightSub;
         stO.font = titleTemplate.fontSub,
         stO.size = titleTemplate.sizeSub;

         if (titleTemplate.fontSubColor) stO.color = titleTemplate.fontSubColor;

         subTitleSize = await this.layerText(lOst.name, stO, false, true);
         if (titleTemplate.yAlign == 'top') {
            await this.layerMove(lOst.name, false, lOt.dy + titleSize.height);
         } else if (titleTemplate.yAlign == 'bottom') {
            await this.layerMove(lOt.name, false, lOst.dy + subTitleSize.height, false, true);
         }
      }

      // Gradient
      if (titleTemplate.title) {
         const gw = this.settings.width;
         const gh = titleSize.height + subTitleSize.height + titleTemplate.marginY * 2;
         await this.layerGradient(lOg.name, {
            type: 'linearTB',
            colore0: '#000000',
            colore1: '#000000',
            colore2: '#000000',
            alpha0: titleTemplate.yAlign == 'top' ? titleTemplate.gradAlpha : 0,
            alpha1: titleTemplate.gradAlpha * 0.6,
            alpha2: titleTemplate.yAlign == 'bottom' ? titleTemplate.gradAlpha : 0,
         }, gw, gh);

         if (titleTemplate.yAlign == 'bottom') await this.layerMove(lOg.name, false, 0, false, true);
         await this.orderLayer(lOg.name, 0);
      }

      // Merge Layers
      const lOnew = this.mergeLayers(true, [lOg.name, lOt.name, lOst.name], 'Titolo Programma');
      lOnew.titleTemplate = titleTemplate;
      this.activeLayer = lOnew.name;
      lOnew.type = 'title';
      if (reDrawLayersMenu) this.renderLayersMenu('draw');
      return lOnew;
   };
   MyCanvas.prototype.macroTitleLogo = async function (progInfo, titleLogo) {
      if (!progInfo) progInfo = this.settings.progInfo;
      if (!titleLogo) titleLogo = {};
      // IL lAYER
      let lO = this.layers.find((l) => l.type == 'titleLogo');
      if (!lO) lO = this.addLayer('Logo Titolo', 'titleLogo');
      // titleLogo.logo
      if (!titleLogo.logo) {
         const images = await this.getExtImages();
         const logos = images.filter((i) => i.use == 'Logo');
         titleLogo.logo = logos.find((l) => l.service == 'Archivio'); // Se c'è prendo il logo in archivio
         if (!titleLogo.logo) titleLogo.logo = logos[0]; // Altrimenti il primo che trovo
         console.log(images, logos, titleLogo)
         this.message(false);
      }
      if (!titleLogo.logo) return;
      this.setState('KO', 'macroTitleLogo');
      const imgToLoad = titleLogo.logo.url ? titleLogo.logo.url : titleLogo.logo;
      await this.insertImage(imgToLoad, { coverage: 12 }, lO.name, true);

      if (lO.dw > this.settings.width * 0.75) await this.insertImage(imgToLoad, { w: this.settings.width * 0.75 }, lO.name, true);

      // titleLogo.pos
      if (!titleLogo.pos) {
         titleLogo.pos = {
            x: 'center',
            y: this.settings.height / 10,
            xr: 0,
            yr: true,
         };
      }
      await this.layerMove(lO.name, titleLogo.pos.x, titleLogo.pos.y, titleLogo.pos.xr, titleLogo.pos.yr);
      // titleLogo.shadow
      if (!titleLogo.shadow) titleLogo.shadow = 'huge';
      const shadow = _myCanvas.shadowTemplates[titleLogo.shadow];
      lO.shadowColor = shadow.shadowColor;
      lO.shadowAlpha = shadow.shadowAlpha;
      lO.shadowX = this.dynamicSize(shadow.shadowX);
      lO.shadowY = this.dynamicSize(shadow.shadowY);
      lO.shadowBlur = this.dynamicSize(shadow.shadowBlur);
      this.drawContext(lO);
      lO.type = 'titleLogo';
      lO.titleLogo = titleLogo;
      this.activeLayer = lO.name;
      return lO;
   };
   MyCanvas.prototype.macroEpisodeNumber = async function (epn, tipo) {
      if (!this.settings.episodeNumber) return;
      this.setState('KO', 'macroEpisodeNumber');
      const myCanvas = this;
      if (this.settings.progInfo) {
         if (!epn) epn = this.settings.progInfo.episode ? this.settings.progInfo.episode.toString() : '';
         if (!tipo) tipo = /skykids/i.test(this.settings.progInfo.ethanMetadatasets) ? 'kids' : 'norm';
      }
      if (!epn) epn = '0';
      if (!tipo) tipo = 'norm';
      const epLayers = this.layers.filter((l) => l.type == 'episodeNumber');
      if (epLayers && epLayers.length > 0) epLayers.map((l) => myCanvas.deleteLayer(l.name));
      if (tipo == 'norm') {
         var lO = this.addLayer('Ep.', 'episodeNumber');
         this.activeLayer = lO.name;
         await this.layerText(lO.name, {
            text: 'EP. ' + epn,
            x: 135,
            y: 0,
            w: 60,
            h: 22,
            yAlign: 'top',
            xAlign: 'right',
            lineHeight: 22,
            font: 'skytext 400',
            size: 21,
            color: '#FFFFFF',
            alpha: 100,
            borderColor: '#000000',
            borderColorAlpha: 100,
            borderSize: 3,
         }, false);
      }
      if (tipo == 'kids') {
         var lO = this.addLayer('Ep.', 'episodeNumber');
         this.activeLayer = lO.name;
         const ctx = lO.canvas.getContext('2d');
         // SHADOW
         ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
         ctx.shadowBlur = 1;
         ctx.shadowOffsetX = 2;
         ctx.shadowOffsetY = 2;
         // Rettangolo
         ctx.fillStyle = '#FFFFFF';
         ctx.beginPath();
         ctx.moveTo(146, 6);
         ctx.lineTo(194, 3);
         ctx.lineTo(189, 25);
         ctx.lineTo(153, 26);
         ctx.fill();
         // Remove Shadow
         ctx.shadowColor = 'rgba(0, 0, 0, 0)';
         await this.layerText(lO.name, {
            text: epn,
            x: 150,
            y: 8,
            w: 40,
            h: 20,
            yAlign: 'center',
            xAlign: 'center',
            lineHeight: 12,
            letterSpacing: 1,
            font: 'skytext 400',
            size: 21,
            color: '#000000',
            alpha: 100,
            borderColor: '#000000',
            borderColorAlpha: 100,
            borderSize: 0.5,
         }, false, true);
      }
      lO.text = false;
      lO.type = 'episodeNumber';
      lO.episodeNumber = {
         epn: epn,
         tipo: tipo,
      };
      lO.locked = true;
      // console.log(lO.episodeNumber)
      return lO;
   };
   MyCanvas.prototype.macroBlurTile = async function (blur, layerName) {
      this.setState('KO', 'blurTile');
      const olO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      let lO;
      if (olO.originalImg) {
         lO = olO;
         lO.img = olO.originalImg;
         lO.iw = olO.img.width;
         lO.ih = olO.img.height;
         lO.dx = olO.dx + olO.iw * olO.scale;
         lO.dy = olO.dy + olO.ih * olO.scale;
         lO.dw = olO.iw * olO.scale;
         lO.dh = olO.ih * olO.scale;
      } else {
         lO = this.cloneLayer(olO.name, olO.name + ' Tile');
      }
      if (!lO || !lO.img) return;
      const iw = lO.img.width;
      const ih = lO.img.height;
      const tempCan = createTempCan(iw * 3, ih * 3);
      const sctx = tempCan.getContext('2d');
      // COPIE IMMAGINE FLIPPATE
      sctx.save();
      // Immagine
      sctx.scale(1, 1);
      sctx.drawImage(lO.img, iw, ih, iw, ih);
      // horizontal Flip
      sctx.scale(-1, 1);
      sctx.drawImage(lO.img, 0, ih, iw * -1, ih);
      sctx.drawImage(lO.img, -iw * 2, ih, iw * -1, ih);
      // Vertical Flip
      sctx.scale(-1, -1);
      sctx.drawImage(lO.img, iw, 0, iw, ih * -1);
      sctx.drawImage(lO.img, iw, -ih * 2, iw, ih * -1);
      // Doppio Flip
      sctx.scale(-1, 1);
      sctx.drawImage(lO.img, 0, 0, iw * -1, ih * -1);
      sctx.drawImage(lO.img, -iw * 2, 0, iw * -1, ih * -1);
      sctx.drawImage(lO.img, 0, -ih * 2, iw * -1, ih * -1);
      sctx.drawImage(lO.img, -iw * 2, -ih * 2, iw * -1, ih * -1);
      // restore scale
      sctx.restore();
      if (blur) {
         if (blur > 150) blur = 150;
         // Blur l'immagine grande
         sctx.filter = 'blur(' + blur + 'px)';
         sctx.drawImage(tempCan, 0, 0, tempCan.width, tempCan.height);
         sctx.filter = 'none';
         // Buco l'immagine grande
         sctx.globalCompositeOperation = 'destination-out';
         sctx.filter = 'blur(' + blur + 'px)';
         // console.log( lO.canvas.width, lO.canvas.height );
         const minSize = Math.min(lO.canvas.width, lO.canvas.height);
         const radius = minSize / 100 * blur;
         let shrink = blur * 6;
         if (iw - shrink < 0 || ih - shrink < 0) {
            shrink = 0;
         }
         // console.log( radius, shrink );
         sctx.roundRect(iw + shrink / 2, ih + shrink / 2, iw - shrink, ih - shrink, radius);
         sctx.fillStyle = '#FF0000';
         sctx.fill();
         sctx.filter = 'none';
         // diregno sotto l'immagine originale
         sctx.globalCompositeOperation = 'destination-over';
         sctx.drawImage(lO.img, iw, ih, iw, ih);
      }
      // Original Layer
      lO.originalImg = lO.img;
      lO.img = tempCan;
      lO.iw = tempCan.width;
      lO.ih = tempCan.height;
      lO.dx = lO.dx - lO.iw / 3 * lO.scale;
      lO.dy = lO.dy - lO.ih / 3 * lO.scale;
      lO.dw = lO.dw * 3;
      lO.dh = lO.dh * 3;
      this.clearLayerType(lO.name);
      lO.type = 'blurTile';
      lO.blurTile = {
         blur: blur,
      };
      // DRAW
      this.drawContext(lO);
      // this.renderLayersMenu('update');
      return lO;
      // tempCan.parentNode.removeChild(tempCan);
   };
   MyCanvas.prototype.macroCopyRight = async function (text) {

      if (!text) {
         const d = new Date();
         const year = d.getFullYear();
         text = `© ${year}/${year + 1} All Rights Reserved.`;
      }

      // NEW LAYER
      const copyLayers = this.layers.filter((l) => l.type == 'copyRight');
      if (copyLayers && copyLayers.length > 0) copyLayers.map((l) => this.deleteLayer(l.name));
      const lO = this.addLayer('CopyRight', 'text');

      // Verifico in basso a dx che colore mi serve in base alla luminosità
      const txtWidth = lO.canvas.width * 0.02;
      const brightness = this.getImageBrightness('bg', lO.canvas.width - txtWidth, lO.canvas.height / 2, txtWidth, lO.canvas.height / 2);
      const color = brightness && brightness[1] == 'chiaro' ? 'rgba(0,0,0,1)' : 'rgba(255,255,255,1)';

      // Disegno un tempCan a dei valori fissi
      const fonstSize = 24;
      const tempSize = 1080;
      const tempCan = createTempCan(tempSize, tempSize, true);
      const ctx = tempCan.getContext('2d');
      const scaledWidth = lO.canvas.height * 0.018;

      // ROTATE
      ctx.save();
      ctx.rotate(-90 * Math.PI / 180);
      ctx.translate(-tempSize, 0);

      // WRITE
      ctx.font = '200 ' + fonstSize + 'px skytext';
      ctx.fillStyle = color;
      const width = Math.ceil(ctx.mlFillText(text, 0, 0, 1920, fonstSize * 1.2, 'center', 'left', fonstSize).width);
      console.log('WIDTH', width)

      // INSERT
      console.log('Copy', lO)
      const margin = parseInt(Math.min(lO.layerw, lO.layerh)) * 0.005;


      await this.insertImage(tempCan, {w: scaledWidth}, lO.name, true);
      this.layerMove(lO.name, margin, margin * 1.2, true, true);

      lO.type = 'copyRight';
      lO.copyRight = { text: text };
      this.activeLayer = lO.name;

      tempCan.remove();
   };

   MyCanvas.prototype.addTimOverlay = async function (canvases, type) {
      for (const canvas of canvases) {
         const mc = canvas.myCanvas;
         const formatId = mc.settings.id;

         if (!['imageA', 'imageC', 'imageR', 'imageS'].includes(mc.settings.id)) continue;
         let imgUrl = `${mySite}/datatv/myCanvas/${type}-${mc.settings.width}x${mc.settings.height}.png`;

         console.log('ADD TIM Overlay', formatId, imgUrl);
         mc.deleteLayer('Overlay');
         let lOnew = mc.addLayer('Overlay');
         await mc.insertImage(imgUrl, 'max', lOnew.name);


         mc.setState('Ko');
      }
   }

   // ////////////////////////////////////////////////////////////////////////////// WIZARD
   MyCanvas.prototype.imageWizard = async function (canvases, origins, minsize) {
      console.log(`[imageWizard] Wizard Start`, arguments);

      // Sen non ci sono i dati programm esco
      if (!this.settings.progInfo) return;

      // Se non ho passato Canvas lavoro su questo
      if (!canvases || !canvases.length) canvases = [this.wrapper];

      // Se non ho passate origins imposto un minimo di default
      if (!origins) origins = ['Client', 'Archivio', 'SportImages'];

      // Se non ho passato una minsize specifica metto 75
      if (!minsize) minsize = 75;

      // Ciclo i canvas da fare
      for (const canvas of canvases) {
         await doCanvas(canvas);
      }

      console.log(`[imageWizard] Wizard End`, arguments);

      async function doCanvas(canvas) {
         const mc = canvas.myCanvas;
         // Se è KO o Vuoto lo segno come da fare
         if (['Ko', 'No'].includes(mc.state)) {
            mc.oldState = mc.state;
            mc.setState('Wo');
         }

         console.log(`[imageWizard] > Start`, canvas);

         for (const origin of origins) {

            // Se lo stato del canvas è ancora "Wo" non ho ancora modificato il canvas e provo l'origine successiva
            if (mc.state == 'Wo') {
               console.log(`[imageWizard] >> Wizard ${origin}...`);
               mc.message(`Wizard ${origin}...`, true);

               // Operazioni
               if (['SportImages'].includes(origin)) {
                  await mc.macroSportMatch();
                  await addOverlays(mc, ['ChannelLogo']);
                  mc.setSource('Macro Sport');

               } else if (['PageCopy'].includes(origin)) {
                  const foundImg = await getPageImage(mc);
                  if (foundImg) {
                     await mc.insertImage(foundImg.img ? foundImg.img : foundImg.url, 'max', 'bg');
                     await addOverlays(mc, ['Title', 'ChannelLogo'], foundImg);
                     mc.setSource(foundImg.id);
                  }

               } else if (['Client', 'Archivio', 'Content', 'VOD', 'Tmdb', 'Fanart', 'Google'].includes(origin)) {
                  const foundImg = await getExtImage(mc, origin);
                  if (foundImg) {
                     await mc.insertImage(foundImg.img ? foundImg.img : foundImg.url, 'max', 'bg');
                     await addOverlays(mc, ['Title', 'ChannelLogo', 'CopyRight'], foundImg);
                     mc.setSource(foundImg.service);
                  }

               }

               mc.activeLayer = 'bg';
            }

         }

         console.log(`[imageWizard] > End`, canvas);

         // Se non ho fatto niente ripristino lo stato
         if (['Wo'].includes(mc.state)) {
            mc.setState(mc.oldState);
            mc.oldState = '';
            mc.message();
         }

         // getExtImage
         async function getExtImage(mc, service) {
            // nota getExtImages sono già ordinate per dimensioni
            const imgs = await mc.getExtImages();
            console.log('[imageWizard] >>> getExtImages:', imgs);

            let filteredImgs = imgs.filter((i) =>
               i.service == service &&
               i.approved &&
               i.approved != 'ko' &&
               i.tags != 'Da Lavorare' &&
               i.width > mc.settings.width * 0.75 &&
               i.name.indexOf('PLH_[') < 0
            );
            if (!filteredImgs || filteredImgs.length == 0) return;

            if (service != 'Archivio') {
               filteredImgs.forEach((i) => {
                  i.approved = 'all';
               });
            }

            const formatId = mc.settings.id;
            const w = mc.settings.width;
            const h = mc.settings.height;
            const progInfo = mc.settings.progInfo;
            const idEpisode = progInfo.idEpisode && progInfo.idEpisode != -1 ? progInfo.idEpisode : false;
            const isSport = progInfo.category == 'Sport' || progInfo.tipologia == 'Sport' ? true : false;
            const isPrimafila = /prima fila/gi.test(progInfo.canali);


            // DEFINISCO APPROVEDS
            let approveds = mc.settings.flags ? mc.settings.flags.split(',') : [];
            approveds.push(mc.settings.service.toLowerCase());

            // Se è now o kids aggiungo approveds SKY
            if (/\b(now|kids)\b/gi.test(approveds.join())) approveds.push('sky');

            // Se è timvision aggiungo approveds TIM
            if (/\b(timvision)\b/gi.test(approveds.join())) approveds.push('tim');

            // Aggiungo alla fine APPROVEDS ALL
            if (!approveds.includes('all')) approveds.push('all');


            // DEFINISCO USI
            let uses = mc.settings.uses;

            // // USI se è Sport !!! eliminato il 28/03/2023
            // if (isSport || approveds.includes('dazn')) {
            //    if (['imageC', 'imageD', 'imageB', 'imageG', 'imageH', 'imageI'].find((i) => formatId.match(new RegExp(i)))) uses = ['Poster-O-Logo', 'Poster-O', 'Scena'];
            // }

            // USI Primafila eliminato il 28/03/2023
            // if (isPrimafila && formatId == 'imageG') {
            //    // uses = ['Poster-O-Logo', 'Poster-O', 'Scena']; 29/10/2020 tolta poster-o-logo
            //    uses = ['Poster-O', 'Scena'];
            // }

            // USI se è Sport!!! aggiunto il 13/06/2023
            if (isSport || approveds.includes('dazn')) {
               if (['imageG', 'imageI', 'imageO'].find((i) => formatId.match(new RegExp(i))))
                  uses = ['Poster-O-Logo', 'Poster-O', 'Scena'];
               if (['imageN'].find((i) => formatId.match(new RegExp(i))))
                  uses = ['Poster-V-Logo', 'Poster-V'];
            }

            // Se Episodio
            if (idEpisode) {
               if (isSport || approveds.includes('dazn')) {
                  if (['imageG', 'imageI'].find((i) => formatId.match(new RegExp(i)))) uses = ['Poster-O', 'Poster-O-Logo'];
               }
            }

            // TROVO IMMAGINE IN ORDINE DI APPROVED E POI DI USO
            let image = false;

            approveds.forEach((approved) => {

               uses.forEach((use) => {

                  if (image) return;

                  // Immagine delle stesse dimensioni
                  image = filteredImgs.find((i) =>
                     i.approved == approved &&
                     i.use == use &&
                     (idEpisode ? i.idEpisode == idEpisode : (!i.idEpisode || i.idEpisode == -1)) &&
                     i.width == w &&
                     i.height == h
                  );

                  if (image) return;

                  // Immagine di dimensioni diverse
                  image = filteredImgs.find((i) =>
                     i.approved == approved &&
                     i.use == use &&
                     (idEpisode ? i.idEpisode == idEpisode : (!i.idEpisode || i.idEpisode == -1))
                  );

               });
            });

            // Se caccia e pesca e sto facendo imageV va bene anche quella di stagione
            if (!image && formatId == 'imageV') {
               approveds.forEach((approved) => {

                  uses.forEach((use) => {

                     if (image) return;

                     // Immagine delle stesse dimensioni
                     image = filteredImgs.find((i) =>
                        i.approved == approved &&
                        i.use == use &&
                        i.width == w &&
                        i.height == h
                     );

                     if (image) return;

                     // Immagine di dimensioni diverse
                     image = filteredImgs.find((i) =>
                        i.approved == approved &&
                        i.use == use
                     );

                  });
               });
            }


            // Se non ho trovato una immagine e ho una immagine con uso = Unica la uso per comporre le immagini
            // Solo per i programmi
            if (!image && (!idEpisode || formatId == 'imageV')) {
               // Le immagini uniche ordinate per data
               let uniche = filteredImgs
                  .filter(i => i.updated_at && i.use == 'Unica')
                  .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

               if (uniche.length) image = uniche[0];
            }

            console.log('[imageWizard] >>> Ext Image Found:', image ? image : 'NESSUNA IMMAGINE TROVATA!!!');
            return image;


            // // console.log('Cerco Immagine:', formatId, approveds, uses, idEpisode, isSport);
            // // Cerco in ordine di formato e poi di approvazione
            // uses.forEach((use) => {
            //    approveds.forEach((approved) => {
            //       if (image) return;
            //       // console.log(`***\t\t Cerco... ${use} / ${approved} / ep:${idEpisode}`);
            //       if (idEpisode) {
            //          image = filteredImgs.find((i) => i.approved == approved && i.use == use && i.idEpisode == idEpisode && i.width == w && i.height == h);
            //          if (!image) image = filteredImgs.find((i) => i.approved == approved && i.use == use && i.idEpisode == idEpisode);
            //       } else {
            //          image = filteredImgs.find((i) => i.approved == approved && i.use == use && (!i.idEpisode || i.idEpisode == -1) && i.width == w && i.height == h);
            //          if (!image) image = filteredImgs.find((i) => i.approved == approved && i.use == use && (!i.idEpisode || i.idEpisode == -1));
            //       }
            //    });
            // });

            // if (image) {
            //    console.log('[imageWizard] >>> Ext Image Found FA:', image);
            //    return image;
            // }

            // // Cerco in ordine di approvazione e poi di formato
            // approveds.forEach((approved) => {
            //    uses.forEach((use) => {
            //       if (image) return;
            //       if (idEpisode) {
            //          image = filteredImgs.find((i) => i.approved == approved && i.use == use && i.idEpisode == idEpisode && i.width == w && i.height == h);
            //          if (!image) image = filteredImgs.find((i) => i.approved == approved && i.use == use && i.idEpisode == idEpisode);
            //       } else {
            //          image = filteredImgs.find((i) => i.approved == approved && i.use == use && (!i.idEpisode || i.idEpisode == -1) && i.width == w && i.height == h);
            //          if (!image) image = filteredImgs.find((i) => i.approved == approved && i.use == use && (!i.idEpisode || i.idEpisode == -1));
            //       }

            //    });
            // });

            // if (image) console.log('[imageWizard] >>> Ext Image Found AF:', image);
            // return image;
         }

         // getPageImage
         async function getPageImage(mc) {
            const area = getClosest(mc.wrapper, '.area-immagini');
            const otherCans = area.querySelectorAll('._myCanvas');
            const imgs = [];
            otherCans.forEach((j) => {
               const sourceCan = j.myCanvas;
               const bg = sourceCan.layers.find((l) => l.name == 'bg');
               if (sourceCan.state == 'No' || !bg.img) return;

               // Le immagini con _ '_dazn' non possono essere copiate su altri canvas
               if (sourceCan.settings.id.indexOf('_') > -1) return;
               imgs.push({
                  id: sourceCan.settings.id,
                  img: bg.img,
                  width: bg.iw,
                  height: bg.ih,
                  use: sourceCan.settings.uses[0],
                  idEpisode: sourceCan.settings.progInfo.idEpisode,
                  service: 'Page',
               });
            });

            const image = imgs.find((i) => mc.settings.uses.includes(i.use) && i.width > mc.settings.width * 0.75);

            if (image) console.log('[imageWizard] >>> Page Image Found:', image);
            return image;
         }

         // addOverlays
         async function addOverlays(mc, overlays, foundImg) {

            // Channel Logo (imeageE e imageJ le escludo perchè hanno comunque i parametri del logo per love nature)
            if (foundImg && overlays.includes('ChannelLogo') && !/(imageE|imageJ)/gi.test(mc.settings.id)) {
               if (foundImg.service != 'Client') await mc.macroChannelLogo();
            }

            // Inserisco il Titolo
            if (foundImg && overlays.includes('Title') && /Logo/.test(mc.settings.uses[0]) && !/Logo/.test(foundImg.use)) {
               // 28/11/2019 Disabilitato titolo logo su indicazione di Andrea
               // const logoDone = await mc.macroTitleLogo();
               // if (!logoDone && mc.settings.service == 'Sky') await mc.macroTitle();
               await mc.macroTitle();
            }

            // Titolo Episodio Amazon Caccia
            if (mc.settings.id == 'imageV' && mc.settings.progInfo && mc.settings.progInfo.idEpisode) {
               await mc.macroTitle();
            }

            // Inserisco CopyRight
            if (foundImg && foundImg.copyright && overlays.includes('CopyRight')) {
               await mc.macroCopyRight(foundImg.copyright);
            }
         }

      }

   };

   MyCanvas.prototype.getExtImages = async function () {
      const progInfo = this.settings.progInfo;
      // loadImages
      const myUrl = `${mySite}/image-get?${Object.keys(progInfo).map((k) => progInfo[k] ? `&${k}=${progInfo[k]}` : ``).join('')}`;
      console.log('[getExtImages]', myUrl);
      let loadImages;
      if (_myCanvas.loadedUrls[myUrl]) loadImages = _myCanvas.loadedUrls[myUrl];
      else {
         loadImages = await $.ajax({
            type: 'GET',
            url: myUrl,
         });
         _myCanvas.loadedUrls[myUrl] = loadImages;
      }
      // Images
      return loadImages.images;
   };
   // ////////////////////////////////////////////////////////////////////////////// UTILITA'
   MyCanvas.prototype.setState = function (newState, op) {
      if (op) this.lastOp = op;
      else op = 'setState';

      if (!this.log) this.log = [];
      this.log.push(op);
      const oldState = this.state;

      if (newState == 'SAME') return;
      if (!newState || newState == '?') newState = 'No';
      else newState = newState.charAt(0).toUpperCase() + newState.slice(1).toLowerCase();
      this.state = newState;
      if (this.header) {
         const stateTag = this.header.querySelector('.state');
         // Reset
         stateTag.classList.remove('Wo'); // Working
         stateTag.classList.remove('No'); // Vuoto
         stateTag.classList.remove('Db');
         stateTag.classList.remove('Dc');
         stateTag.classList.remove('Ko');
         stateTag.classList.remove('Ok');
         // Set
         stateTag.classList.add(newState);
         stateTag.textContent = newState;
         if (newState == 'No') stateTag.textContent = '?';
      }
      // Se c'è la funzione syncMyCanIcon
      if (typeof syncMyCanIcon == 'function') syncMyCanIcon(this);
      // if(this.settings.id == 'imageF') console.log('State Change', this.settings.id, op, oldState, '=>' ,newState);
      return this;
   };
   MyCanvas.prototype.setSource = function (source) {
      // console.log('Set Source', source);
      if (!source) source = '';
      this.source = source;
      if (!this.header) return;
      const sourceTag = this.header.querySelector('.source');
      sourceTag.textContent = source;
   };
   MyCanvas.prototype.drawContext = function (lO, keep) {
      const ctx = lO.canvas.getContext('2d');
      if (!keep) ctx.clearRect(0, 0, lO.canvas.width, lO.canvas.height);
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      if (lO.shadowAlpha && (lO.shadowX || lO.shadowY || lO.shadowBlur)) {
         ctx.shadowColor = 'rgba(' + this.hex2rgb(lO.shadowColor, lO.shadowAlpha / 100) + ')';
         ctx.shadowOffsetX = lO.shadowX;
         ctx.shadowOffsetY = lO.shadowY;
         ctx.shadowBlur = lO.shadowBlur;
      } else {
         ctx.shadowColor = 'rgba(0,0,0,0)';
         ctx.shadowOffsetX = 0;
         ctx.shadowOffsetY = 0;
         ctx.shadowBlur = 0;
      }
      if (lO.img) ctx.drawImage(lO.img, lO.dx, lO.dy, lO.dw, lO.dh);
      const areal = lO.layerh * lO.layerw;
      const areai = lO.dh * lO.dw;
      lO.coverage = Math.round((areai / areal * 100) * 10) / 10;
      this.updateLayerFormTransform();
   };
   MyCanvas.prototype.loadImage = function (url, safeImg, nocache, originalUrl) {
      const myCanvas = this;
      myCanvas.message('Loading imagine...');

      if (!_myCanvas.loadedImages) _myCanvas.loadedImages = {};
      if (url.indexOf('nocache=true') > -1) nocache = true;

      return new Promise((resolve, reject) => {
         url = getImageUrlFromText(url);
         if (!url) reject(new Error('URL immagine non identificabile'));

         // Immagine già caricata
         if (_myCanvas.loadedImages[url]) {
            resolve(_myCanvas.loadedImages[url]);
            return;
         }

         // Immagine da caricare
         const img = new Image();
         img.crossOrigin = 'anonymous';

         // LOAD
         img.addEventListener('load', () => {

            if (safeImg) {
               if (originalUrl) _myCanvas.loadedImages[originalUrl] = img;

               myCanvas.message();
               _myCanvas.loadedImages[url] = img;
               resolve(img);
               return;

            } else if (!isTanted(img)) {
               myCanvas.message();
               _myCanvas.loadedImages[url] = img;
               resolve(img);
               return;

            } else {
               myCanvas.message('Preload Immagine sul server');
               myCanvas.preloadServer(url)
                  .then((r) => {
                     if (!r || !r.length) return;

                     const newUrl = mySite + '/' + r[0].folder + '/' + r[0].name;
                     return myCanvas.loadImage(newUrl, true, false, url);
                  });
               // .then(newImg => {
               //   myCanvas.message();
               //   _myCanvas.loadedImages[url] = img;
               //   resolve(newImg);
               //   return;
               // });
            }
         });

         // ERROR
         img.addEventListener('error', (e) => {

            if (!safeImg) {
               myCanvas.preloadServer(url)
                  .then((r) => {
                     if (!r || !r.length) return;
                     const newUrl = mySite + '/' + r[0].folder + '/' + r[0].name;
                     return myCanvas.loadImage(newUrl, true, false, false, true);
                  })
                  .then((newImg) => {
                     myCanvas.message();
                     resolve(newImg);
                  })
                  .catch((e) => {
                     myCanvas.message(`
                        <i class="warning sign icon"></i>Errore Caricamento Immagine<br>
                        <a href="${url}" target="_blank">Verifica Path</a>`,
                        true
                     );

                     reject(new Error(`Errore Caricamento Immagine: ${url}`));
                  });

            } else {
               myCanvas.message(`
                  <i class="warning sign icon"></i>Errore Caricamento Immagine<br>
                  <a href="${url}" target="_blank">Verifica Path</a>`,
                  true
               );

               reject(new Error(`Errore Caricamento Immagine: ${url}`));
            }
         });

         // Start
         img.src = url + (nocache ? '?nocache=' + $.now() : '');
      });

      // is tainted
      function isTanted(img) {
         let tainted = false;
         const tempCan = createTempCan(10, 10);
         const ctx = tempCan.getContext('2d');
         ctx.drawImage(img, 0, 0);
         try {
            tempCan.toDataURL('image/jpeg');
         } catch (e) {
            tainted = true;
         }
         return tainted;
      }
   };
   MyCanvas.prototype.readFiles = function (files) {
      if (!Array.isArray(files)) files = [files];
      const promises = [];
      files.forEach((file) => {
         promises.push(
            new Promise((resolve, reject) => {
               const reader = new FileReader();
               // Load
               reader.onload = function () {
                  const dataURL = reader.result;
                  const localImg = new Image();
                  localImg.src = dataURL;

                  localImg.onload = function () {
                     localImg.name = file.name;
                     resolve(localImg);
                  }
               };
               reader.onerror = function () {
                  reject(new Error(`Errore Caricamento Immagine: ${url}`));
               };

               if (file) {
                  reader.readAsDataURL(file);
               }
            }),
         );
      });
      return Promise.all(promises);
   };
   MyCanvas.prototype.getLocalFile = function () {
      const myCanvas = this;
      const myInput = this.input;
      // myCanvas.message('Loading immagine...');
      return new Promise((resolve, reject) => {
         var inputChange = function () {
            const file = myInput.files[0];
            // Reset
            myInput.removeEventListener('change', inputChange);
            myInput.value = '';
            myCanvas.readFiles(file).then((f) => {
               resolve(f[0]);
            });
         };
         console.log('CLICK', myInput);
         myInput.click();
         myInput.addEventListener('change', inputChange);
      });
   };
   MyCanvas.prototype.getImgFromLayer = function (layerName, x, y, w, h) {
      return new Promise((resolve, reject) => {
         const lO = this.layers.find((l) => l.name == layerName);
         const myImg = new Image;
         const tempCan = createTempCan(w, h);
         const tctx = tempCan.getContext('2d');
         tctx.drawImage(lO.canvas, x, y, w, h, 0, 0, w, h);
         myImg.onload = function () {
            resolve(myImg);
         };
         myImg.src = tempCan.toDataURL('image/png');
      });
   };
   MyCanvas.prototype.rasterize = function (layerName) {
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      this.getImgFromLayer(lO.name, 0, 0, lO.layerw, lO.layerh).then((cimg) => {
         lO.img = cimg;
         lO.iw = cimg.width;
         lO.ih = cimg.height;
         lO.scale = 1;
         lO.dx = 0;
         lO.dy = 0;
         lO.dw = lO.layerw;
         lO.dh = lO.layerh;
         // console.log('Rasterize:', lO);
         this.clearLayerType(lO.name);
         this.drawContext(lO);
      });
      return this;
   };
   MyCanvas.prototype.hex2rgb = function (hex, alpha) {
      let h = hex.replace('#', '');
      h = h.match(new RegExp('(.{' + h.length / 3 + '})', 'g'));
      for (let i = 0; i < h.length; i++) {
         h[i] = parseInt(h[i].length == 1 ? h[i] + h[i] : h[i], 16);
      }
      if (typeof alpha != 'undefined') h.push(alpha);
      return h;
   };
   MyCanvas.prototype.rgb2hex = function (red, green, blue, alpha) {
      const rgb = blue | (green << 8) | (red << 16);
      return ['#' + (0x1000000 + rgb).toString(16).slice(1), alpha];
   };
   MyCanvas.prototype.setMaxHeight = function (n) {
      const lO = this.layers.find((l) => l.name == 'bg');
      if (!this.settings.startHeight) this.settings.startHeight = this.settings.maxHeight;
      if (n == 'max') {
         if (this.wrapper.className.indexOf('fullScreen') == -1) {
            n = this.settings.startHeight;
         } else {
            const clientWidth = this.wrapper.clientWidth;
            const clientHeight = this.wrapper.clientHeight;
            const cw = this.settings.width;
            const ch = this.settings.height;
            const maxScale = Math.min(clientWidth / cw, clientHeight / ch);
            n = ch * maxScale - 60;
         }
      }
      lO.canvas.style.maxHeight = n + 'px';
      this.settings.maxHeight = n;
      this.viewScale = lO.canvas.clientWidth / this.settings.width;
   };
   MyCanvas.prototype.drawHelpers = function (hO, layerName, layerToDraw) {
      const helperLayer = this.layers.find((l) => l.name == 'helpers');
      this.stage.insertBefore(helperLayer.canvas, null);
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      const canvas = this.layers.find((l) => l.name == (layerToDraw ? layerToDraw : 'helpers')).canvas;
      const ctx = canvas.getContext('2d');
      // PRESETS
      if (hO == 'clear') {
         hO = {
            clear: true,
         };
      }

      if (!lO) return;

      if (hO == 'guides') {
         hO = {
            clear: true,
            points: [{
               x: lO.mouseStart.x,
               y: lO.mouseStart.y,
               w: 4 / this.viewScale,
            }],
            borders: [{
               border: 1 / this.viewScale,
               x: lO.dx + lO.dw / 2 - 1 / this.viewScale,
               y: lO.dy + lO.dh / 2 - 10 / this.viewScale,
               w: 2 / this.viewScale,
               h: 20 / this.viewScale,
            }, {
               border: 1 / this.viewScale,
               x: lO.dx + lO.dw / 2 - 10 / this.viewScale,
               y: lO.dy + lO.dh / 2 - 1 / this.viewScale,
               w: 20 / this.viewScale,
               h: 2 / this.viewScale,
            }, {
               border: 1 / this.viewScale,
               x: lO.layerw / 2,
               y: 0,
               w: 0,
               h: lO.layerh,
            }, {
               border: 1 / this.viewScale,
               x: 0,
               y: lO.layerh / 2,
               w: lO.layerw,
               h: 0,
            }],
         };
      }
      if (hO == 'area') {
         hO = {
            borders: [{
               border: 1 / this.viewScale,
               x: lO.dx,
               y: lO.dy,
               w: lO.dw,
               h: lO.dh,
            }],
         };
      }
      if (hO == 'areaText') {
         hO = {
            borders: [{
               border: 1 / this.viewScale,
               x: lO.dx + lO.text.x * lO.scale,
               y: lO.dy + lO.text.y * lO.scale,
               w: lO.text.w * lO.scale,
               h: lO.text.h * lO.scale,
            }],
         };
      }
      if (hO.clear) ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (hO.points) {
         hO.points.forEach((p) => {
            drawPoint(ctx, p);
         });
      }
      if (hO.borders) {
         hO.borders.forEach((b) => {
            drawBorder(ctx, b);
         });
      }
      function drawPoint(ctx, pointO) {
         ctx.beginPath();
         ctx.arc(pointO.x, pointO.y, pointO.w, 0, 2 * Math.PI, false);
         ctx.fillStyle = pointO.color ? pointO.color : 'transparent';
         ctx.fill();
         ctx.lineWidth = pointO.border;
         ctx.strokeStyle = 'white';
         ctx.stroke();
      }
      function drawBorder(ctx, borderO) {
         ctx.beginPath();
         ctx.lineWidth = borderO.border;
         ctx.strokeStyle = borderO.color ? borderO.color : 'white';
         // ctx.setLineDash([borderO.border * 4, borderO.border * 2]);
         ctx.rect(borderO.x, borderO.y, borderO.w, borderO.h);
         ctx.stroke();
      }
   };
   MyCanvas.prototype.setBottomRightInfo = function (text) {
      if (!text) this.bottomRightInfo.style.display = 'none';
      else this.bottomRightInfo.style.display = 'block';
      this.bottomRightInfo.innerHTML = text;
   };
   MyCanvas.prototype.message = function (text, closable) {
      const prevMess = this.stage.querySelector('.message');
      if (prevMess) prevMess.remove();
      if (!text) return;
      // Creo il messaggio
      const message = document.createElement('div');
      message.classList.add('message');
      message.innerHTML = `<p>${text}</p>`;
      // Creo il close
      if (closable) {
         const close = document.createElement('i');
         close.classList.add('close');
         close.classList.add('link');
         close.classList.add('icon');
         message.appendChild(close);
         close.addEventListener('mousedown', (event) => {
            const mess = this.stage.querySelector('.message');
            mess.remove();
         });
      }
      // Lo appendo allo stage
      this.stage.appendChild(message);
   };
   MyCanvas.prototype.fullScreen = function (mode) {
      if (this.wrapper.className.indexOf('fullScreen') > -1) {
         this.wrapper.classList.remove('fullScreen');
         this.setMaxHeight('max');
         return this;
      }
      this.wrapper.classList.add('fullScreen');
      this.setMaxHeight('max');
      return this;
   };
   MyCanvas.prototype.preloadServer = function (fileUrl) {
      const formD = new FormData();
      formD.append('urls', JSON.stringify([fileUrl]));
      formD.append('folderName', 'tempUploads');
      return $.ajax({
         type: 'POST',
         url: `${mySite}/uploads`,
         beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + myAuth.getCookie('tools.datatv.it'));
         },
         enctype: 'multipart/form-data',
         processData: false,
         contentType: false,
         data: formD,
      }).catch((e) => {
         console.log(e);
      });
   };
   MyCanvas.prototype.hasTransparency = function (img) {
      const w = img.width;
      const h = img.height;
      const tempCan = createTempCan(w, h);
      const ctx = tempCan.getContext('2d');
      ctx.drawImage(img, 0, 0, w, h);
      const idata = ctx.getImageData(0, 0, w, h);
      let trasp = 0;
      for (let i = 0; i < 1200 && i < idata.data.length; i += 4) {
         // console.log(idata.data[i + 3]);
         if (idata.data[i + 3] == 0) trasp++;
         if (trasp > 50) {
            return true;
         }
      }

      return false;
   };
   MyCanvas.prototype.getTransparency = function (img) {
      const w = img.width;
      const h = img.height;
      // This returns an array with 4 bytes (0-255) per pixel
      // data[0] -> R value of first pixel
      // data[1], [2], and [3] -> G, B, and A values
      // etc.
      const tempCan = createTempCan(w, h);
      const ctx = tempCan.getContext('2d');
      ctx.drawImage(img, 0, 0, w, h);
      const idata = ctx.getImageData(0, 0, w, h).data;
      // The total number of pixels is the length of the
      // data array divided by 4, or width * height
      const nrOfPixels = idata.length / 4; // rgba pixels
      let transparent = 0;
      // Your code removes the alpha, so we check each
      // 4th item in the array (notice the += 4)
      // If it's transparent (A === 0), we count it
      for (let i = 3; i < idata.length; i += 4) {
         transparent += idata[i] ? 0 : 1;
      }
      // The percentage is the number of transparent pixels
      // divided by the total number of pixels
      const percentage = transparent / nrOfPixels * 100;
      // console.log('canvasCoverage', percentage)
      return percentage;
   };
   MyCanvas.prototype.getAverageRGB = function (img) {

      const blockSize = 5; // only visit every 5 pixels
      const defaultRGB = { r: 0, g: 0, b: 0 }; // for non-supporting envs
      const rgb = { r: 0, g: 0, b: 0 };
      const canvas = document.createElement('canvas');
      const context = canvas.getContext && canvas.getContext('2d');
      let data;
      let i = -4;
      let count = 0;

      if (!context) {
         return defaultRGB;
      }

      const height = canvas.height = img.naturalHeight || img.offsetHeight || img.height;
      const width = canvas.width = img.naturalWidth || img.offsetWidth || img.width;

      context.drawImage(img, 0, 0);

      try {
         data = context.getImageData(0, 0, width, height);
      } catch (e) {
      /* security error, img on diff domain */alert('x');
         return defaultRGB;
      }

      const length = data.data.length;

      while ((i += blockSize * 4) < length) {
         ++count;
         rgb.r += data.data[i];
         rgb.g += data.data[i + 1];
         rgb.b += data.data[i + 2];
      }

      // ~~ used to floor values
      rgb.r = ~~(rgb.r / count);
      rgb.g = ~~(rgb.g / count);
      rgb.b = ~~(rgb.b / count);

      return rgb;
   };
   MyCanvas.prototype.reverseRGB = function (rgb) {
      const reverse = { r: 0, g: 0, b: 0 };
      rgb = [rgb.r, rgb.g, rgb.b];

      for (let i = 0; i < rgb.length; i++) rgb[i] = (i === 3 ? 1 : 255) - rgb[i];

      reverse.r = rgb[0];
      reverse.g = rgb[1];
      reverse.b = rgb[2];

      return reverse;
   };

   MyCanvas.prototype.removeTransparency = function (img, alphaThreshold) {
      if (alphaThreshold === undefined) alphaThreshold = 5;

      const cw = img.width;
      const ch = img.height;
      const tempCan = createTempCan(cw, ch, false);
      const ctx = tempCan.getContext('2d');
      ctx.drawImage(img, 0, 0, cw, ch);

      const w = ctx.canvas.width;
      const h = ctx.canvas.height;
      const data = ctx.getImageData(0, 0, w, h).data;

      let minX = w;
      let maxX = 0
      let minY = h
      let maxY = 0
      for (let y = 0; y < h; y++) {
         for (let x = 0; x < w; x++) {
            if (data[y * w * 4 + x * 4 + 3]) {
               minX = Math.min(minX, x);
               maxX = Math.max(maxX, x);
               minY = Math.min(minY, y);
               maxY = y;
               x = maxX
            }
         }
      }

      const tc = {
         x: minX,
         y: minY,
         maxX: maxX,
         maxY: maxY,
         w: maxX - minX,
         h: maxY - minY
      }
      //console.log(tc);

      tempCan.width = tc.w;
      tempCan.height = tc.h;

      // Aggiungo un bordo di 1px al disegno
      ctx.drawImage(img, tc.x - 1, tc.y - 1, tc.w + 2, tc.h + 2, 0, 0, tc.w, tc.h);

      return new Promise(resolve => {
         const image = new Image();
         image.addEventListener('load', () => {
            resolve(image);
         });
         image.src = tempCan.toDataURL('image/png');;
      });
   }

   // MyCanvas.prototype.removeTransparency = function (img) {
   //    const w = img.width;
   //    const h = img.height;
   //    const tempCan = createTempCan(w, h, false);
   //    const ctx = tempCan.getContext('2d');
   //    ctx.drawImage(img, 0, 0, w, h);
   //    const idata = ctx.getImageData(0, 0, w, h);
   //    const buffer = idata.data;
   //    const buffer32 = new Uint32Array(buffer.buffer);
   //    let x; let y;
   //    let x1 = w;
   //    let y1 = h;
   //    let x2 = 0;
   //    let y2 = 0;
   //    // get left edge
   //    for (y = 0; y < h; y++) {
   //       for (x = 0; x < w; x++) {
   //          if (buffer32[x + y * w] > 0) {
   //             if (x < x1) x1 = x;
   //          }
   //       }
   //    }
   //    // get right edge
   //    for (y = 0; y < h; y++) {
   //       for (x = w; x >= 0; x--) {
   //          if (buffer32[x + y * w] > 0) {
   //             if (x > x2) x2 = x;
   //          }
   //       }
   //    }
   //    // get top edge
   //    for (x = 0; x < w; x++) {
   //       for (y = 0; y < h; y++) {
   //          if (buffer32[x + y * w] > 0) {
   //             if (y < y1) y1 = y;
   //          }
   //       }
   //    }
   //    // get bottom edge
   //    for (x = 0; x < w; x++) {
   //       for (y = h; y >= 0; y--) {
   //          if (buffer32[x + y * w] > 0) {
   //             if (y > y2) y2 = y;
   //          }
   //       }
   //    }
   //    ctx.clearRect(0, 0, w, h);
   //    const sx = x1 - 2;
   //    const sy = y1 - 2;
   //    const sw = x2 - x1 + 4;
   //    const sh = y2 - y1 + 4;
   //    tempCan.width = sw;
   //    tempCan.height = sh;
   //    ctx.drawImage(img, sx, sy, w, h, 0, 0, w, h);
   //    return tempCan;
   // };

   MyCanvas.prototype.getImageBrightness = function (layerName, x, y, w, h) {
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : 'bg'));
      const canvas = lO.canvas;
      const ctx = canvas.getContext('2d');
      const imageData = ctx.getImageData(x, y, w, h);
      const data = imageData.data;
      let colorSum = 0;
      let pxs = 0;
      let r; let g; let b; let a; let avg;
      for (let i = 0; i < data.length; i += 4) {
         r = data[i];
         g = data[i + 1];
         b = data[i + 2];
         a = data[i + 3];
         if (a > 128) {
            pxs++;
            avg = Math.floor((r + g + b) / 3);
            colorSum += avg;
         }
      }
      const brightness = pxs ? Math.floor(colorSum / pxs) : 0;
      const brightnessName = brightness ? (brightness > 120 ? 'chiaro' : 'scuro') : false;

      //console.log('Pixel totali: ' + data.length, '\nPixel non trasparenti esaminati: ' + pxs, '\nbrightness: ' + brightness);
      this.drawHelpers({
         borders: [{
            border: 1 / this.viewScale,
            x: lO.dx,
            y: lO.dy,
            w: lO.dw,
            h: lO.dh,
         }],
      });

      return [brightness, brightnessName];
   };
   MyCanvas.prototype.areLayersBlank = function () {
      const blanks = [];

      this.layers.forEach((lO) => {
         if (['helper', 'safearea'].includes(lO.type)) return;
         if (lO.img) blanks.push('pieno');
         else blanks.push('vuoto');
      });
      if (blanks.includes('vuoto') && blanks.includes('pieno')) return 'some';
      else if (blanks.includes('vuoto')) return true;
      else if (blanks.includes('pieno')) return false;
   };
   MyCanvas.prototype.isCanvasBlank = function (canvas) {
      const context = canvas.getContext('2d');
      const pixelBuffer = new Uint32Array(
         context.getImageData(0, 0, canvas.width, canvas.height).data.buffer,
      );
      return !pixelBuffer.some((color) => color !== 0);
   };
   MyCanvas.prototype.dynamicSize = function (size, sourceSize, destSize) {
      if (!sourceSize) sourceSize = 600;
      if (!isNaN(destSize)) destSize = destSize;
      else if (destSize == 'w') destSize = this.settings.width;
      else if (destSize == 'h') destSize = this.settings.height;
      else destSize = Math.min(this.settings.width, this.settings.height);

      const scaledSize = Math.ceil(size * destSize / sourceSize);
      return scaledSize;
   };
   // ////////////////////////////////////////////////////////////////////////////// INTERFACE !!!RICHIEDE SEMANTIC UI E JQUERY
   MyCanvas.prototype.renderTopMenu = function (mode) {
      const isExternal = !myVars || !myVars.io || myVars.io.ruolo == 'External';

      // mode : SHOW HIDE
      const myCanvas = this;
      const settings = myCanvas.settings;
      const progInfo = settings.progInfo;
      const $can = $(this.wrapper);
      const isVisible = $can.hasClass('withTopMenu');
      if (mode == 'show' && isVisible) {
         return;
      } else if (mode == 'update' && !isVisible) {
         return;
      } else if (mode == 'hide') {
         $('._myCanvas .topMenu, ._myCanvas .helpTitle').remove();
         $('._myCanvas').removeClass('withTopMenu');
         myCanvas.setMaxHeight('max');
         return;
      } else if (mode == 'show' || mode == 'update') {
         $('._myCanvas .topMenu, ._myCanvas .helpTitle').remove();
         $('._myCanvas').removeClass('withTopMenu');
         $can.addClass('withTopMenu');
         myCanvas.setMaxHeight('max');
      }
      // ////////////////////////////////////// MENUS
      const $topMenu = $('<div class="topMenu"></div>').prependTo($can);
      const $help = $(`
      <div class="ui basic segment helpTitle">
        <div class="ui grey tiny header">
          <div class="content">
            <i class="hand pointer outline icon"></i>Doppio click per editare.
            <div class="sub header">Drag su un'altra immagine per copiare.</div>
          </div>
        </div>
      </div>`).prependTo($can);
      const $menu = $(`<div class="ui ${isExternal ? 'three' : 'four'} item icon menu"></div>`).appendTo($topMenu);
      const $menuDel = $('<a class="item del"><i class="trash alternate outline red icon"></i></a>')
         .appendTo($menu)
         .on('click', function () {
            myCanvas.deleteLayer('all');
            myCanvas.renderLayersMenu('update');
         });


      const $menuOpen = $(`
         <div class="ui floating dropdown item">
            <i class="folder open icon"></i>
            <div class="ui menu">
               <div class="item" data-value="locale"><i class="hdd icon"></i>Apri Immagine Locale</div>
               ` + (typeof modalImageChooser !== 'undefined' && progInfo && (progInfo.idProgram || progInfo.idTmdb || progInfo.title) ? '<div class="item" data-value="modalimagechooser"><i class="search icon"></i>Cerca Immagine</div>' : '') + `
            </div>
         </div>`)
      .appendTo($menu)
      .dropdown();

      $menuOpen.on('click', '[data-value]', function () {
         const value = $(this).attr('data-value');
         const mic = $(this).attr('data-modalimagechooser');
         if (value == 'locale') {
            myCanvas.getLocalFile().then((img) => {
               myCanvas.insertImage(img, 'max', 'bg');
            });
         }
         if (value == 'modalimagechooser') {
            modalImageChooser(progInfo, 'Scegli un ' + settings.uses[0], ['use=' + settings.uses[0]]).then((img) => {
               myCanvas.insertImage(img.url, img.use == 'Logo' ? 'fit' : 'max', 'bg');
               myCanvas.setSource(img.service);
            });
         }
      });

      const $menuDown = $(`<div class="ui floating dropdown item">
          <i class="download icon"></i>
          <div class="ui menu">
            <div class="item" data-value="downJpg"><i class="download icon"></i>Scarica JPG</div>
            <div class="item" data-value="downPng"><i class="download icon"></i>Scarica PNG (Lossless)</div>
            <div class="item" data-value="downLayers"><i class="download icon"></i>Scarica Livelli PNG (Lossless)</div>
            ${this.settings.id == 'imageJ' ? '<div class="item" data-value="down164x242"><i class="download icon"></i>164x242</div>' : ''}
          </div>
        </div>`)
         .appendTo($menu)
         .dropdown();
      $menuDown.on('click', '[data-value]', function () {
         const value = $(this).attr('data-value');
         if (value == 'downJpg') {
            myCanvas.download('jpg');
         }
         if (value == 'downPng') {
            myCanvas.download('png');
         }
         if (value == 'downLayers') {
            myCanvas.download('layers');
         }
         if (value == 'down164x242') {
            myCanvas.download('downJpg', false, 164, 242);
         }
      });

      if (!isExternal) {
         const $menuWiz = $('<a class="item"><i class="magic icon"></i></a>')
            .appendTo($menu)
            .on('click', function () {
               myCanvas.imageWizard();
            });
      }

      return myCanvas;
   };
   MyCanvas.prototype.renderLayersMenu = function (mode) {
      const isExternal = !myVars || !myVars.io || myVars.io.ruolo == 'External';

      // Mode: draw, hide, update
      const myCanvas = this;
      const settings = myCanvas.settings;
      const progInfo = settings.progInfo;
      const $can = $(this.wrapper);
      const isVisible = $can.hasClass('withLayersMenu');
      if (mode == 'draw') {
         $('._myCanvas .layersMenu').remove();
         $('._myCanvas').removeClass('withLayersMenu');
         $can.addClass('withLayersMenu');
         myCanvas.setMaxHeight('max');
      } else if (mode == 'hide') {
         $('._myCanvas .layersMenu').remove();
         $('._myCanvas').removeClass('withLayersMenu');
         myCanvas.setMaxHeight('max');
         return;
      } else if (mode == 'update') {
         if (!isVisible) return;
      }
      // Menu
      renderMenus(mode);

      // Layers
      const $layersMenu = $can.find('.layersMenu');
      const $layersList = $layersMenu.find('.layersList').length
         ? $layersMenu.find('.layersList')
         : $('<div class="ui bottom attached segment layersList"></div>').appendTo($layersMenu);

      // Creo i layer
      myCanvas.layers.forEach((lO, i) => {
      //for (let ll = myCanvas.layers.length - 1; ll >= 0; ll--) {
         //let lO = myCanvas.layers[ll];
         let $layerItem = $layersList.find('[data-name="' + lO.name + '"]').length ? $layersList.find('[data-name="' + lO.name + '"]') : false;

         if (!$layerItem) {
            $layerItem = newLayerItem(lO.name, i);
         } else {
            updateLayerItem(lO, i);
         }
      //}
      });

      // Elimino quelli che non esistono più
      $layersList.find('.layerItem').each((i, el) => {
         const $el = $(el);
         const name = $el.attr('data-name');
         const lO = myCanvas.layers.find((l) => l.name == name);
         if (!lO) $el.remove();
      });

      // ACTIVE LAYER
      myCanvas.layers.forEach((lO, i) => {
         if (lO.name != myCanvas.activeLayer) return;

         $layersList.find('.menu.inverted.blue.active').removeClass('inverted blue active');
         $layersList.find('.layerItem .layerForms').hide();

         const $layerItem = $layersList.find('[data-name="' + lO.name + '"]');
         $layerItem.find('.menu').eq(0).addClass('inverted blue active');
         $layerItem.find('.layerForms').not('.transform').show();
      });

      // Drag And Drop
      $layersList.find('.layerItem').on({
         'dragstart': function (e) {
            e.stopPropagation();
            e.originalEvent.dataTransfer.setData('lName', $(this).find('.layerName').text());
         },
         'dragend': function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.placeHolder').remove();
         },
         'dragenter': function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.placeHolder').remove();
            $(this).prepend('<div class="placeHolder"></div>');
         },
         'dragover': function (e) {
            e.preventDefault();
            e.stopPropagation();
         },
         'dragleave': function (e) {
            e.preventDefault();
            e.stopPropagation();
         },
         'drop': function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.placeHolder').remove();
            const name = e.originalEvent.dataTransfer.getData('lName');
            const targetName = $(this).find('.layerName').text();
            const lOtarget = myCanvas.layers.find((l) => l.name == targetName);
            const newIndex = myCanvas.layers.findIndex((l) => l.name == targetName);

            if (lOtarget.type == 'safearea') $('.layerItem[data-name="' + name + '"]').insertAfter($(this));
            else $('.layerItem[data-name="' + name + '"]').insertBefore($(this));

            myCanvas.orderLayer(name, newIndex);
         },
      });
      //
      return myCanvas;
      // ////////////////////////////////////// MENUS
      function renderMenus(mode) {
         const $layersMenu = $can.find('.layersMenu').length ? $can.find('.layersMenu') : $('<div class="layersMenu"></div>').prependTo($can);
         const $menu = $layersMenu.find('.lmenu').length ? $layersMenu.find('.lmenu') : $('<div class="ui top attached four item icon menu lmenu"></div>').appendTo($layersMenu);
         const $presets = $menu.find('.presets').length ? $menu.find('.presets') : $(`
        <div class="ui floating dropdown item presets">
          <i class="star icon"></i>
          <div class="ui menu">
            ${!isExternal ? '<div class="item" data-value="macroSportMatch"><i class="futbol outline icon"></i>Macro Sport Match</div>' : ''}
            ${!isExternal ? '<div class="item" data-value="macroSportMatchMulti"><i class="futbol outline icon"></i>Macro Sport Match Layers</div>' : ''}
            ${!isExternal ? '<div class="item" data-value="macroChannelLogo"><i class="tv icon"></i>Macro Logo Canale</div>' : ''}
            ${!isExternal ? '<div class="item" data-value="macroEpisodeNumber"><i class="code icon"></i>Macro N° Ep.</div>' : ''}
            <div class="item" data-value="macroBlurTile"><i class="th large icon"></i>Macro Blur Tile</div>
            ${!isExternal ? '<div class="item" data-value="macroTitle"><i class="font icon"></i>Macro Titolo</div>' : ''}
            ${!isExternal ? '<div class="item" data-value="macroTitleLogo"><i class="trademark icon"></i>Macro Logo Titolo</div>' : ''}
            ${!isExternal ? '<div class="item" data-value="copy"><i class="copyright outline icon"></i>Macro Copyright</div>' : ''}
            ${!isExternal ? '<div class="item" data-value="skyGradient"><i class="tint icon"></i>Macro SKY Gradient</div>' : ''}
            <!--<div class="item disabled" data-value=""><i class="certificate icon"></i>Macro App Kids</div>-->
          </div>
        </div>`)
            .appendTo($menu)
            .dropdown();
         if (myCanvas.settings.channelLogo) $presets.find('[data-value="macroChannelLogo"]').removeClass('disabled');
         else $presets.find('[data-value="macroChannelLogo"]').addClass('disabled');
         if (myCanvas.settings.episodeNumber) $presets.find('[data-value="macroEpisodeNumber"]').removeClass('disabled');
         else $presets.find('[data-value="macroEpisodeNumber"]').addClass('disabled');
         const hasSportTemplate = Object.keys(_myCanvas.sportTemplates).map((st) => Object.keys(_myCanvas.sportTemplates[st])).flat(1).includes(myCanvas.settings.id.split('_')[0]);
         if (hasSportTemplate) $presets.find('[data-value="macroSportMatch"], [data-value="macroSportMatchMulti"]').removeClass('disabled');
         else $presets.find('[data-value="macroSportMatch"], [data-value="macroSportMatchMulti"]').addClass('disabled');
         const $addLayer = $menu.find('.addLayer').length ? $menu.find('.addLayer') : $(`
        <div class="ui floating dropdown item addLayer">
          <i class="clone icon"></i>
          <div class="ui menu">
            ${!isExternal ? '<div class="item" data-value="modalimagechooser"><i class="search icon"></i>Cerca Immagine</div>' : ''}
            <div class="item" data-value="imgLocal"><i class="hdd icon"></i>Immagine Locale</div>
            ${!isExternal ? '<div class="item" data-value="imgChannel"><i class="tv icon"></i>Immagine Canale</div>' : ''}
            ${!isExternal ? '<div class="item" data-value="imgSport"><i class="futbol outline icon"></i>Immagine Sport</div>' : ''}
            <div class="item" data-value="textGen"><i class="font icon"></i>Testo</div>
            <div class="item" data-value="gradient"><i class="tint icon"></i>Gradiente</div>
            ${!isExternal ? '<div class="item" data-value="imageMask"><i class="mask icon"></i>Maschera</div>' : ''}
          </div>
        </div>`)
            .appendTo($menu)
            .dropdown();
         const imageChoose = typeof modalImageChooser !== 'undefined' && progInfo && (progInfo.idProgram || progInfo.idTmdb || progInfo.title);
         if (imageChoose) $presets.find('[data-value="modalimagechooser"]').show();
         else $presets.find('[data-value="modalimagechooser"]').hide();
         const $editLayer = $menu.find('.editLayer').length ? $menu.find('.editLayer') : $(`
        <div class="ui floating small dropdown item editLayer">
          <i class="paint brush icon"></i>
          <div class="ui menu">
            <div class="item" data-value="center"><i class="compress arrows alternate icon"></i>Centra</div>
            <div class="item" data-value="max"><i class="expand icon"></i>Max</div>
            <div class="item" data-value="min"><i class="compress icon"></i>Min</div>
            <div class="item" data-value="mirrorx"><i class="arrows alternate horizontal icon"></i>Rifletti X</div>
            <div class="item" data-value="mirrory"><i class="arrows alternate vertical icon"></i>Rifletti Y</div>
          </div>
        </div>`)
            .appendTo($menu)
            .dropdown();
         const $settings = $menu.find('.settings').length ? $menu.find('.settings') : $(`
        <div class="ui floating dropdown item settings">
          <i class="cog icon"></i>
          <div class="ui menu">
            <div class="item" data-value="merge"><i class="clone outline icon"></i>Appiattisci Livelli</div>
            <div class="item" data-value="clone"><i class="clone icon"></i>Duplica Livello</div>
            ${!isExternal ? '<div class="item" data-value="settings"><i class="cog icon"></i>Settings (Debug)</div>' : ''}
          </div>
        </div>`)
            .appendTo($menu)
            .dropdown();
         if (mode == 'draw') {
            $presets.on('click', '[data-value]', function () {
               const value = $(this).attr('data-value');
               if (value == 'macroSportMatch') {
                  myCanvas.macroSportMatch().then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'macroSportMatchMulti') {
                  myCanvas.macroSportMatch(false, true).then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'macroChannelLogo') {
                  myCanvas.macroChannelLogo().then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'macroBlurTile') {
                  myCanvas.macroBlurTile(30).then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'macroEpisodeNumber') {
                  myCanvas.macroEpisodeNumber().then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'macroTitle') {
                  myCanvas.macroTitle().then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'macroTitleLogo') {
                  myCanvas.macroTitleLogo().then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'copy') {
                  myCanvas.macroCopyRight().then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'skyGradient') {
                  myCanvas.skyGradient(true).then(() => myCanvas.renderLayersMenu('draw'));
               }
               if (value == 'maskkids') {
                  console.log('kids');
               }
            });
            $addLayer.on('click', '[data-value]', async function () {
               const value = $(this).attr('data-value');
               if (value == 'modalimagechooser') {
                  modalImageChooser(progInfo, 'Scegli una immagine da aggiungere').then((img) => {
                     const lName = myCanvas.addLayer('immagine-' + img.service).name;
                     myCanvas.insertImage(img.url, 'min', lName);
                     myCanvas.activeLayer = lName;
                     myCanvas.renderLayersMenu('update');
                  });
               }
               if (value == 'imgLocal') {
                  myCanvas.getLocalFile().then((img) => {
                     const lName = myCanvas.addLayer('Immagine Locale').name;
                     myCanvas.insertImage(img, 'min', lName);
                     myCanvas.activeLayer = lName;
                     myCanvas.renderLayersMenu('update');
                  });
               }
               if (value == 'imgChannel') {
                  myCanvas.getChannelLogo(349).then((ch) => {
                     const logoPos = myCanvas.settings.channelLogo;
                     const lobj = myCanvas.addLayer('Canale', 'channel');
                     myCanvas.insertImage(ch.url, {
                        coverage: logoPos ? logoPos[0].coverage : 1.4,
                     }, lobj.name, true).then((r) => {
                        lobj.channel = ch;
                        myCanvas.activeLayer = lobj.name;
                        myCanvas.renderLayersMenu('update');
                     });
                  });
               }
               if (value == 'imgSport') {
                  myCanvas.getSportImage('A.S. Roma', 'Serie A').then((img) => {
                     const lobj = myCanvas.addLayer('Immagine Sport', 'sport');
                     myCanvas.insertImage(img.url, 'min', lobj.name).then((r) => {
                        lobj.sportImage = img;
                        myCanvas.activeLayer = lobj.name;
                        myCanvas.renderLayersMenu('update');
                     });
                  });
               }
               if (value == 'gradient') {
                  var lName = myCanvas.addLayer('Gradiente', 'gradient').name;
                  await myCanvas.layerGradient(lName, {
                     type: 'radial',
                     colore0: '#000000',
                     colore1: '#000000',
                     colore2: '#000000',
                     alpha0: 100,
                     alpha1: 50,
                     alpha2: 0,
                  });
                  myCanvas.activeLayer = lName;
                  myCanvas.renderLayersMenu('update');
               }
               if (value == 'textGen') {
                  var lName = myCanvas.addLayer('Testo', 'text').name;
                  myCanvas.layerText(lName, {
                     text: 'Modifica questo testo nel layer corrispondente.',
                     x: 0,
                     y: 0,
                     w: myCanvas.settings.width,
                     h: myCanvas.settings.height,
                     yAlign: 'center',
                     xAlign: 'center',
                     lineHeight: 50,
                     font: 'skytext 800',
                     size: 50,
                     color: '#FFFFFF',
                     alpha: 100,
                     borderColor: '#000000',
                     borderColorAlpha: 0,
                     borderSize: 0,
                  }, true);
                  myCanvas.activeLayer = lName;
                  myCanvas.renderLayersMenu('update');
               }
               if (value == 'imageMask') {
                  myCanvas.layerMask();
               }
            });
            $editLayer.on('click', '[data-value]', function () {
               const value = $(this).attr('data-value');
               if (value == 'center') {
                  myCanvas.layerCenter();
               }
               if (value == 'max') {
                  myCanvas.layerMax();
               }
               if (value == 'min') {
                  myCanvas.layerMin();
               }
               if (value == 'mirrorx') {
                  myCanvas.layerFlip('x');
               }
               if (value == 'mirrory') {
                  myCanvas.layerFlip('y');
               }
            });
            $settings.on('click', '[data-value]', function () {
               const value = $(this).attr('data-value');
               if (value == 'clone') {
                  myCanvas.cloneLayer(myCanvas.activeLayer);
                  myCanvas.renderLayersMenu('update');
               }
               if (value == 'merge') {
                  myCanvas.mergeLayers(true);
                  myCanvas.renderLayersMenu('update');
               }
               if (value == 'settings') {
                  myCanvas.editSettings();
               }
            });
         }
      }
      // ////////////////////////////////////// CREATE LAYER MENU
      function newLayerItem(layerName, i) {
         const lO = myCanvas.layers.find((l) => l.name == layerName);
         const isActive = lO.name == myCanvas.activeLayer;
         const draggable = !['main', 'safearea'].includes(lO.type);
         const deletable = !['safearea'].includes(lO.type);
         // console.log('Disegno', layerName, isActive);
         let icon = 'image';
         if (lO.type == 'gradient') icon = 'fill';
         else if (lO.type == 'text') icon = 'font';
         else if (lO.type == 'channel') icon = 'tv';
         else if (lO.type == 'sport') icon = 'futbol outline';
         else if (lO.type == 'episodeNumber') icon = 'code';
         else if (lO.type == 'safearea') icon = 'map';
         else if (lO.type == 'blurTile') icon = 'th';
         else if (lO.type == 'title') icon = 'font';
         else if (lO.type == 'titleLogo') icon = 'trademark';
         else if (lO.type == 'copyRight') icon = 'copyright outline';
         const $layerItem = $(`
            <div class="layerItem ${lO.type} ${!lO.uiDisplay ? 'hidden' : ''}" data-name="${lO.name}">
              <div class="ui mini borderless ${!lO.visible ? 'opacita4' : ''} ${isActive ? 'inverted blue active' : ''} menu"  ${draggable ? 'draggable=true' : ''}>
                <div class="item icon layerView"><i class="${icon} icon"></i></div>
                <div class="layerName">${lO.name}</div>
                <div class="right menu">
                  ${deletable ? '<div class="item icon layerDel"><i class="trash alternate outline red icon"></i></div>' : ''}
                </div>
              </div>
            </div>`);

         // Append al contrario
         const prevLayer = i > 0 ? myCanvas.layers[i - 1] : false;
         if (prevLayer.type == 'safearea') $layerItem.insertAfter($layersList.find('[data-name="' + prevLayer.name + '"]'));
         else if (prevLayer) $layerItem.insertBefore($layersList.find('[data-name="' + prevLayer.name + '"]'));
         else $layerItem.prependTo($layersList);

         // ////////////////////////////////////// Layer Actions
         // Action Select
         $layerItem.on('click', '.layerName', function () {
            // const layerName = $layerItem.attr('data-name');
            // const lO = myCanvas.layers.find(l => l.name == layerName);
            if (['safearea'].includes(lO.type) || lO.name == myCanvas.activeLayer) return;
            // Reset
            $layersList.find('.menu.inverted.blue.active').removeClass('inverted blue active');
            $layersList.find('.layerItem .layerForms').hide();
            myCanvas.activeLayer = null;
            // Apply
            $layerItem.find('.menu').eq(0).addClass('inverted blue active');
            $layerItem.find('.layerForms').not('.transform').show();
            myCanvas.activeLayer = $(this).text();
         });
         // Action contextMenu
         $layerItem.on('contextmenu', '.layerView', function (e) {
            e.preventDefault();
            e.stopPropagation();
            // const layerName = $layerItem.attr('data-name');
            // const lO = myCanvas.layers.find(l => l.name == layerName);
            $layerItem.find('.layerForms.transform').toggle();
            myCanvas.updateLayerFormTransform(lO.name);
            console.log('Layer Settings:', lO);
         });
         // Action Show / Hide
         $layerItem.on('click', '.layerView', function () {
            const layerName = $layerItem.attr('data-name');
            const lO = myCanvas.layers.find((l) => l.name == layerName);
            const isHidden = $(lO.canvas).css('visibility') == 'hidden';
            myCanvas.showLayer(lO.name, isHidden);
            // console.log('isHidden', isHidden, $(this).closest('.layerItem > .menu'))
            if (isHidden) $(this).closest('.layerItem > .menu').removeClass('opacita4');
            else $(this).closest('.layerItem > .menu').addClass('opacita4');
         });
         // Action Delete
         $layerItem.on('click', '.layerDel', function () {
            const lO = myCanvas.layers.find((l) => l.name == layerName);
            myCanvas.deleteLayer(lO.name);
            if (!['main'].includes(lO.type)) $layerItem.remove();
         });
         // Action Rename
         $layerItem.on('dblclick contextmenu focusout', '.layerName', function (e) {
            e.preventDefault();
            e.stopPropagation();
            // const layerName = $layerItem.attr('data-name');
            // const lO = myCanvas.layers.find(l => l.name == layerName);
            // const isActive = lO.name == myCanvas.activeLayer;
            if (['safearea', 'main'].includes(lO.type)) return;
            if (['contextmenu', 'dblclick'].includes(e.type)) {
               $layerItem.removeClass(lO.name);
               $(this)[0].contentEditable = 'true';
               $(this).focus();
            } else if (e.type == 'focusout') {
               $(this)[0].contentEditable = 'false';
               const nName = myCanvas.renameLayer(lO.name, $(this).text());
               $layerItem.attr('data-name', nName);
               myCanvas.renderLayersMenu('draw');
               // if(isActive) myCanvas.activeLayer = nName
            }
         });
         // Action Draw Helpers
         $layerItem.on('focus change', 'input', function () {
            // const layerName = $layerItem.attr('data-name');
            // const lO = myCanvas.layers.find(l => l.name == layerName);
            myCanvas.drawHelpers('clear');
            if (lO.dw) myCanvas.drawHelpers('area', lO.name);
            if (lO.text) myCanvas.drawHelpers('areaText', lO.name);
         });
         $layerItem.on('focusout', 'input', function () {
            myCanvas.drawHelpers('clear');
         });
         $layerItem.on('mouseout', function () {
            myCanvas.drawHelpers('clear');
         });
         // ////////////////////////////////////// Layer Forms
         // formTransform
         if (true /* lO.dw*/) {
            const $formTransform = $(`
              <div class="layerForms transform" style="display:none;">
                <div class="ui mini unstackable form transform">

                  <div class="ui fields">
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" step="10" name="x" value="0">
                        <i class="arrow alternate left icon"></i>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" step="10" name="y" value="0">
                        <i class="arrow alternate up icon"></i>
                      </div>
                    </div>
                  </div>

                  <div class="ui fields">
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" step="10" name="w" value="0">
                        <i class="arrows alternate horizontal icon"></i>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" step="10" name="h" value="0">
                        <i class="arrows alternate vertical icon"></i>
                      </div>
                    </div>
                  </div>

                  <div class="ui fields">
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" step="1" name="s" value="0">
                        <i class="percent icon"></i>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" step="0.1" name="c" value="0">
                        <i class="sticky note icon"></i>
                      </div>
                    </div>
                  </div>

                </div>
              </div>`).appendTo($layerItem);
            $formTransform.on('change', 'input', function () {
               console.log('change');
               const name = $(this).attr('name');
               const val = $(this).val();
               if (name == 'x') {
                  myCanvas.layerMove(lO.name, val);
               } else if (name == 'y') {
                  myCanvas.layerMove(lO.name, false, val);
               } else if (name == 'w') {
                  myCanvas.layerScale(lO.name, {
                     w: val,
                  });
               } else if (name == 'h') {
                  myCanvas.layerScale(lO.name, {
                     h: val,
                  });
               } else if (name == 's') {
                  myCanvas.layerScale(lO.name, val / 100);
               } else if (name == 'c') {
                  myCanvas.layerScale(lO.name, {
                     coverage: val,
                  });
               }
            });
         }
         // Image
         if (lO.type == 'image') {
            const $formImage = $(`
        <div class="layerForms">
          <div class="ui mini unstackable form imageForm">
            <div class="fields">
              <div class="sixteen wide field">
                <label></label>
                <div class="ui fluid mini button local"><i class="folder open icon"></i>Immagine Locale</div>
              </div>
            </div>
            <div>
              <div class="sixteen wide field">
                <label></label>
                <div class="ui fluid mini button search"><i class="search icon"></i>Cerca Immagine</div>
              </div>
            </div>

          </div>
        </div>`).appendTo($layerItem);
            $layerItem.on('click', '.local', function () {
               myCanvas.getLocalFile().then((img) => {
                  myCanvas.insertImage(img, 'min', lO.name);
                  const nName = myCanvas.renameLayer(lO.name, 'Immagine Locale', false);
                  $layerItem.attr('data-name', nName);
                  $layerItem.find('.layerName').text(nName);
                  myCanvas.activeLayer = nName;
               });
            });
            $layerItem.on('click', '.search', function () {
               modalImageChooser(progInfo, 'Scegli una immagine da aggiungere').then((img) => {
                  myCanvas.insertImage(img.url, 'min', lO.name);
                  const nName = myCanvas.renameLayer(lO.name, 'immagine-' + img.service, false);
                  $layerItem.attr('data-name', nName);
                  $layerItem.find('.layerName').text(nName);
                  myCanvas.activeLayer = nName;
               });
            });
         }
         // formGradient
         if (lO.gradient) {
            const $formGradient = $(`
        <div class="layerForms">
          <div class="ui mini unstackable form gradient">
            <div class="sixteen wide field">
              <label></label>
              <div class="ui selection dropdown">
                <input type="hidden" name="type">
                <i class="dropdown icon"></i>
                <div class="default text">Gradient Type</div>
                <div class="menu">
                  <div class="item" data-value="radial">Radiale</div>
                  <div class="item" data-value="linearTB">Verticale</div>
                  <div class="item" data-value="linearLR">Orizzontale</div>
                  <div class="item" data-value="linearD">Diagonale</div>
                </div>
              </div>
            </div>
            ${Object.keys(lO.gradient).map((k) => {
               const match = /colore(\d{0,})$/gm.exec(k);
               if (!match || !match[1]) return '';
               const colore = lO.gradient[k];
               const alpha = lO.gradient['alpha' + match[1]];
               const $el = `
                <div class="ui fields color">
                  <div class="four wide field">
                    <label> </label>
                    <input type="color" name="colore${match[1]}" placeholder="Colore" value="${colore}">
                  </div>
                  <div class="twelve wide field">
                    <label>
                    </label>
                    <div class="ui left icon input">
                      <input type="number" name="alpha${match[1]}" , placeholder="Alpha" type="number" value="${alpha}">
                      <i class="chess board icon">
                      </i> </div>
                  </div>
                </div>`;
               return $el;
            }).join('')}
          </div>
        </div>`).appendTo($layerItem);
            $layerItem.find('.ui.dropdown')
               .dropdown('set selected', lO.gradient.type);
            $layerItem.on('change', '[name]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.gradient')[0]);
               myCanvas.layerGradient(lO.name, formO);
            });
         }
         // formText
         if (lO.text) {
            const $formText = $(`
              <div class="layerForms">
                <div class="ui mini unstackable form testo">
                  <div class="sixteen wide field">
                    <label></label>
                    <div class="ui selection dropdown font" tabindex="0">
                      <input type="hidden" name="font">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu">
                        ${_myCanvas.fonts.map((f) => `
                          <div class="item" data-value="${f.family}${f.weight != 'normal' ? ' ' + f.weight : ''}">
                            <span style="font-family: ${f.family}; font-weight:${f.weight};">${f.family} ${f.weight != 'normal' ? f.weight : ''}</span>
                          </div>`).join('')
               }
                      </div>
                    </div>
                  </div>
                  <div class="ui fields">
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="size" value="${lO.text.size}">
                        <i class="font icon"></i>
                      </div>
                    </div>
                    <div class="four wide field">
                      <label></label>
                      <input type="color" name="color" value="${lO.text.color}">
                    </div>
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="alpha" value="${lO.text.alpha}">
                        <i class="chess board icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="ui fields">
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="borderSize" value="${lO.text.borderSize}">
                        <i class="grip lines icon"></i>
                      </div>
                    </div>
                    <div class="four wide field">
                      <label></label>
                      <input type="color" name="borderColor" value="${lO.text.borderColor}">
                    </div>
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="borderColorAlpha" value="${lO.text.borderColorAlpha}">
                        <i class="chess board icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label></label>
                    <textarea rows="3" placeholder="Testo..." name="text">${lO.text.text}</textarea>
                  </div>
                  <div class="ui fields">
                    <div class="five wide field">
                      <label></label>
                      <div class="ui fluid selection dropdown xAlign" tabindex="0">
                        <input type="hidden" name="xAlign">
                        <i class="dropdown icon"></i>
                        <div class="text"></div>
                        <div class="menu" tabindex="-1">
                          <div class="item" data-value="left"><i class="align left icon"></i></div>
                          <div class="item" data-value="center"><i class="align center icon"></i></div>
                          <div class="item" data-value="right"><i class="align right icon"></i></div>
                        </div>
                      </div>
                    </div>
                    <div class="five wide field">
                      <label></label>
                      <div class="ui fluid selection dropdown yAlign" tabindex="0">
                        <input type="hidden" name="yAlign">
                        <i class="dropdown icon"></i>
                        <div class="text"></div>
                        <div class="menu" tabindex="-1">
                          <div class="item" data-value="top"><i class="arrow alternate up icon"></i></div>
                          <div class="item" data-value="center"><i class="arrows alternate vertical icon"></i></div>
                          <div class="item" data-value="bottom"><i class="arrow alternate down icon"></i></div>
                        </div>
                      </div>
                    </div>
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="lineHeight" value="${lO.text.lineHeight}">
                        <i class="text height icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="ui fields">
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="x" value="${lO.text.x}">
                        <i class="arrow alternate left icon"></i>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="y" value="${lO.text.y}">
                        <i class="arrow alternate up icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="ui fields">
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="w" value="${lO.text.w}">
                        <i class="arrows alternate horizontal icon"></i>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="h" value="${lO.text.h}">
                        <i class="arrows alternate vertical icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`).appendTo($layerItem);
            $layerItem.find('.ui.dropdown.xAlign')
               .dropdown('set selected', lO.text.xAlign);
            $layerItem.find('.ui.dropdown.yAlign')
               .dropdown('set selected', lO.text.yAlign);
            $layerItem.find('.ui.dropdown.font')
               .dropdown('set selected', lO.text.font);
            $layerItem.on('change', '[name]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.testo')[0]);
               myCanvas.layerText(lO.name, formO, false);
            });
         }
         // LIVELLO CANALE
         if (lO.channel) {
            const $formChannel = $(`
              <div class="layerForms">
                <div class="ui mini unstackable form canale">
                  <div class="sixteen wide field">
                    <label></label>
                    <div class="ui search selection dropdown idChannel" tabindex="0">
                      <input type="hidden" name="idChannel">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu">
                        <div class="item" data-value="">--</div>
                        ${_myCanvas.channelLogos.map((logo) => {
               if (logo.tipo != 'logo') return;
               return `<div class="item" data-value="${logo.idChannel}">${logo.name}</div>`;
            }).join('')}
                      </div>
                    </div>
                  </div>
                  <div class="sixteen wide field">
                    <label></label>
                    <div class="ui search selection dropdown tipo" tabindex="0">
                      <input type="hidden" name="tipo">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu"></div>
                    </div>
                  </div>
                  <div class="ui three fields">
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="tl"><i class="chevron up icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="tc"><i class="chevron up icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="tr"><i class="chevron up icon"></i></a>
                    </div>
                  </div>
                  <div class="ui three fields">
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="cl"><i class="chevron left icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="cc"><i class="circle icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="cr"><i class="chevron right icon"></i></a>
                    </div>
                  </div>
                  <div class="ui three fields">
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="bl"><i class="chevron down icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="bc"><i class="chevron down icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="br"><i class="chevron down icon"></i></a>
                    </div>
                  </div>

                  <div class="fields">
                    <div class="sixteen wide field">
                      <label></label>
                      <a href="${mySite}/immaginiCanali" target="_blank" class="ui fluid mini button"><i class="external alternate icon"></i>Apri Immagini Canali</a>
                    </div>
                  </div>
                </div>
              </div>`).appendTo($layerItem);
            // DROP CANALI
            $layerItem.on('change', '[name="idChannel"]', async function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.canale')[0]);
               const logo = await myCanvas.getChannelLogo(formO.idChannel, lO.channel.tipo);
               const logoPos = myCanvas.settings.channelLogo;
               if (!logo) return;
               // creo il dropdown tipo
               const logos = _myCanvas.channelLogos.filter((l) => l.idChannel == formO.idChannel);
               const $tipoDrop = $layerItem.find('.dropdown.tipo');
               $tipoDrop.find('.menu').html('<div class="item" data-value="">--</div>' +
                  logos.map((logo) => `<div class="item" data-value="${logo.tipo}">${logo.tipo}</div>`)
                     .join(''),
               );
               $tipoDrop.dropdown('refresh');
               $tipoDrop.dropdown('set selected', logo.tipo);
               // insert logo
               if (logo == lO.channel) return;
               await myCanvas.insertImage(logo.url, lO.img ? { same: true } : { coverage: logoPos[0].coverage }, lO.name, true);
               // Se esiste una posizione predefinita lo sposto
               if (logoPos) {
                  const x = logoPos[0].x;
                  const y = logoPos[0].y;
                  const xr = logoPos[0].xr;
                  const yr = logoPos[0].yr;
                  await myCanvas.layerMove(lO.name, x, y, xr, yr);
               }
               const nName = myCanvas.renameLayer(lO.name, logo.name, false);
               $layerItem.attr('data-name', nName);
               $layerItem.find('.layerName').text(nName);
               myCanvas.activeLayer = nName;
               lO.channel = logo;
            });
            // DROP TIPO
            $layerItem.on('change', '[name="tipo"]', async function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.canale')[0]);
               const logo = await myCanvas.getChannelLogo(formO.idChannel, formO.tipo);
               const logoPos = myCanvas.settings.channelLogo;
               if (!logo || logo == lO.channel) return;
               // insert logo
               await myCanvas.insertImage(logo.url, {
                  same: true,
               }, lO.name, true);
               if (logoPos) {
                  const x = logoPos[0].x;
                  const y = logoPos[0].y;
                  const xr = logoPos[0].xr;
                  const yr = logoPos[0].yr;
                  await myCanvas.layerMove(lO.name, x, y, xr, yr);
               }
               const nName = myCanvas.renameLayer(lO.name, logo.name, false);
               $layerItem.attr('data-name', nName);
               $layerItem.find('.layerName').text(nName);
               myCanvas.activeLayer = nName;
               lO.channel = logo;
            });
            $layerItem.find('.dropdown.idChannel').dropdown({
               fullTextSearch: true,
            }).dropdown('set selected', lO.channel.idChannel);
            // $layerItem.find('.dropdown.tipo').dropdown( { fullTextSearch:true }).dropdown('set selected', lO.channel.tipo);
            // POSIZIONE
            $layerItem.on('click', '[data-pos]', function () {
               const logoPos = myCanvas.settings.channelLogo;
               const m = !logoPos ? 90 : !isNaN(logoPos[0].x) ? logoPos[0].x : !isNaN(logoPos[0].y) ? logoPos[0].y : 90;
               const p = $(this).attr('data-pos');
               const poso = {
                  tl: {
                     x: m,
                     y: m,
                     xr: false,
                     yr: false,
                  },
                  tc: {
                     x: 'center',
                     y: m,
                     xr: false,
                     yr: false,
                  },
                  tr: {
                     x: m,
                     y: m,
                     xr: true,
                     yr: false,
                  },
                  cl: {
                     x: m,
                     y: 'center',
                     xr: false,
                     yr: false,
                  },
                  cc: {
                     x: 'center',
                     y: 'center',
                     xr: false,
                     yr: false,
                  },
                  cr: {
                     x: m,
                     y: 'center',
                     xr: true,
                     yr: false,
                  },
                  bl: {
                     x: m,
                     y: m,
                     xr: false,
                     yr: true,
                  },
                  bc: {
                     x: 'center',
                     y: m,
                     xr: false,
                     yr: true,
                  },
                  br: {
                     x: m,
                     y: m,
                     xr: true,
                     yr: true,
                  },
               };
               myCanvas.layerMove(lO.name, poso[p].x, poso[p].y, poso[p].xr, poso[p].yr);
            });
         }
         // LIVELLO SPORT
         if (lO.sportImage) {
            const $formSportImage = $(`
              <div class="layerForms">
                <div class="ui mini unstackable form sport">
                  <div class="sixteen wide field">
                    <label></label>
                    <div class="ui serch selection dropdown competitionId" tabindex="0">
                      <input type="hidden" name="competitionId">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu">
                        ${_myCanvas.competitions.map((co) => {
               return `<div class="item" data-value="${co.id}">${co.competition}${co.year ? ' ' + co.year : ''}</div>`;
            }).join('')}
                      </div>
                    </div>
                  </div>
                  <div class="sixteen wide field">
                    <label></label>
                    <div class="ui search selection dropdown imgId" tabindex="0">
                      <input type="hidden" name="imgId">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu"></div>
                    </div>
                  </div>

                  <div class="fields">
                    <div class="sixteen wide field">
                      <label></label>
                      <a href="${mySite}/immaginiSport" target="_blank" class="ui fluid mini button"><i class="external alternate icon"></i>Apri Immagini Sport</a>
                    </div>
                  </div>
                </div>
              </div>`).appendTo($layerItem);
            // DROP COMPETIZIONI
            $layerItem.on('change', '[name="competitionId"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.sport')[0]);
               const imgs = _myCanvas.competitions.find((c) => c.id == formO.competitionId).imgs;
               // creo il dropdown immagini
               const $imgDrop = $layerItem.find('.dropdown.imgId');
               $imgDrop.find('.menu').html('<div class="item" data-value="">--</div>' +
                  imgs.map((i) => `<div class="item" data-value="${i.id}">${i.imgName}</div>`)
                     .join(''),
               );
               $imgDrop.dropdown('refresh');
               // Valore dropdown immagini
               const current = imgs.find((i) => i.id == lO.sportImage.id);
               if (current) $imgDrop.dropdown('set selected', lO.sportImage.id);
               else $imgDrop.dropdown('set selected', '');
            });
            // DROP IMMAGINI
            $layerItem.on('change', '[name="imgId"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.sport')[0]);
               const img = _myCanvas.competitions.find((c) => c.id == formO.competitionId).imgs.find((i) => i.id == formO.imgId);
               console.log('Change img:', formO);
               if (!img) {
                  myCanvas.clearLayer(lO.name);
                  lO.sportImage = {
                     cid: formO.competitionId,
                  };
                  return;
               } else if (img.id == lO.sportImage.id) return;
               const mode = img.id < 0 ? 'max' : img.id > 0 && lO.sportImage.id > 0 ? {
                  same: true,
               } : 'min';
               const nName = myCanvas.renameLayer(lO.name, img.imgName, false);
               $layerItem.attr('data-name', nName);
               $layerItem.find('.layerName').text(nName);
               myCanvas.activeLayer = nName;
               lO.sportImage = img;
               myCanvas.insertImage(img.url, mode, nName);
            });
            $layerItem.find('.dropdown.imgId').dropdown({
               fullTextSearch: true,
            }).dropdown('set selected', lO.sportImage.id);
            $layerItem.find('.dropdown.competitionId').dropdown({
               fullTextSearch: true,
            }).dropdown('set selected', lO.sportImage.cid);
         }
         // LIVELLO SPORT MATCH
         if (lO.sportMatch) {
            const $formSportMatch = $(`
               <div class="layerForms">
                  <div class="ui mini unstackable form sportAll">

                     <div class="sixteen wide field">
                        <label></label>
                        <div class="ui serch selection dropdown competitionId" tabindex="0">
                           <input type="hidden" name="competitionId">
                           <i class="dropdown icon"></i>
                           <div class="text"></div>
                           <div class="menu">
                              ${_myCanvas.competitions.filter((co) => co.images && co.images.length).map((co) => `
                                 <div class="item" data-value="${co.id}">${co.competition}${co.year ? ' ' + co.year : ''}</div>
                              `).join('')}
                           </div>
                        </div>
                     </div>

                     <div class="sixteen wide field">
                        <label></label>
                        <div class="ui search selection dropdown team1" tabindex="0">
                           <input type="hidden" name="team1">
                           <i class="dropdown icon"></i>
                           <div class="text"></div>
                           <div class="menu"></div>
                        </div>
                     </div>

                     <div class="sixteen wide field">
                        <label></label>
                        <div class="ui search selection dropdown team2" tabindex="0">
                           <input type="hidden" name="team2">
                           <i class="dropdown icon"></i>
                           <div class="text"></div>
                           <div class="menu"></div>
                        </div>
                     </div>

                     <div class="sixteen wide field">
                        <label></label>
                        <div class="ui input">
                           <input type="text" name="turn" value="${lO.sportMatch.turn ? lO.sportMatch.turn : ''}">
                        </div>
                     </div>

                     <div class="fields">
                        <div class="sixteen wide field">
                           <label></label>
                           <a href="${mySite}/immaginiSport" target="_blank" class="ui fluid mini button"><i class="external alternate icon"></i>Apri Immagini Sport</a>
                        </div>
                     </div>
                  </div>
               </div>`).appendTo($layerItem);

            $layerItem.on('change', '[name="competitionId"]', async function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.sportAll')[0]);

               // Se è cambiata la competizione richiamo getSportImgs per eventualmente caricare le squadre
               if (lO.sportMatch.competizione.id != formO.competitionId) {
                  const compInForm = _myCanvas.competitions.find((c) => c.id == formO.competitionId);
                  const sportImgs = await myCanvas.getSportImgs(false, false, compInForm.idProgram, compInForm.idProgramBase);
                  lO.sportMatch.competizione = sportImgs.competizione;
                  await myCanvas.macroSportMatch(lO.sportMatch);
               }

               console.log('////////////////////', lO.sportMatch)

               // Genero i dropdown delle squadre
               $layerItem.find('.dropdown.team1, .dropdown.team2').each((ix, drop) => {
                  const $drop = $(drop);
                  $drop.find('.menu').html(`
                     <div class="item" data-value="">--</div>
                     ${lO.sportMatch.competizione.teams.map((t) => `
                        <div class="item" data-value="${t.id}">${t.teamName}</div>
                     `).join('')}
                  `);

                  $drop.dropdown('refresh');

                  // Se la competizione contiene la squadra
                  if (lO.sportMatch['team' + (ix + 1)]
                     && lO.sportMatch.competizione.teams.find((t) => t.id == lO.sportMatch['team' + (ix + 1)].id)) {
                     $drop.dropdown('set selected', lO.sportMatch['team' + (ix + 1)].id);

                  } else {
                     $drop.dropdown('set selected', '');
                     lO.sportMatch['team' + (ix + 1)] = null;
                  }
               });
            });

            $layerItem.on('change', '[name="team1"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.sportAll')[0]);
               const team = _myCanvas.competitions
                  .find((c) => c.id == formO.competitionId).teams
                  .find((i) => i.id == formO.team1);

               if (!team && lO.sportMatch.team1) {
                  lO.sportMatch.team1 = null;

               } else if (team && !lO.sportMatch.team1) {
                  lO.sportMatch.team1 = team;

               } else if (team && lO.sportMatch.team1 && lO.sportMatch.team1.id != team.id) {
                  lO.sportMatch.team1 = team;

               } else {
                  return;

               }

               myCanvas.macroSportMatch(lO.sportMatch);
            });

            $layerItem.on('change', '[name="team2"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.sportAll')[0]);
               const team = _myCanvas.competitions
                  .find((c) => c.id == formO.competitionId).teams
                  .find((i) => i.id == formO.team2);

               if (!team && lO.sportMatch.team2) {
                  lO.sportMatch.team2 = null;

               } else if (team && !lO.sportMatch.team2) {
                  lO.sportMatch.team2 = team;

               } else if (team && lO.sportMatch.team2 && lO.sportMatch.team2.id != team.id) {
                  lO.sportMatch.team2 = team;

               }else {
                  return;

               }

               myCanvas.macroSportMatch(lO.sportMatch);
            });

            $layerItem.on('change', '[name="turn"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.sportAll')[0]);
               console.log('turn:', formO);
               lO.sportMatch.turn = formO.turn;
               myCanvas.macroSportMatch(lO.sportMatch);
            });

            $layerItem.find('.dropdown.team1').dropdown({
               fullTextSearch: true,
            }).dropdown('set selected', lO.sportMatch.team1.id);

            $layerItem.find('.dropdown.team2').dropdown({
               fullTextSearch: true,
            }).dropdown('set selected', lO.sportMatch.team2.id);

            $layerItem.find('.dropdown.competitionId').dropdown({
               fullTextSearch: true,
            }).dropdown('set selected', lO.sportMatch.competizione.id);
         }
         // LIVELLO EPISODENUMBER
         if (lO.episodeNumber) {
            const $formepisodeNumber = $(`
              <div class="layerForms">
                <div class="ui mini unstackable form episodeNumber">
                  <div class="sixteen wide field">
                    <label></label>
                    <div class="ui left icon input">
                      <input type="number" name="epn" value="${lO.episodeNumber.epn}">
                      <i class="code icon"></i>
                    </div>
                  </div>
                  <div class="sixteen wide field">
                    <label></label>
                    <div class="ui search selection dropdown tipo">
                      <input type="hidden" name="tipo">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu">
                        <div class="item" data-value="norm">Normale</div>
                        <div class="item" data-value="kids">Kids</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`).appendTo($layerItem);
            $layerItem.on('change', '[name="epn"], [name="tipo"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.episodeNumber')[0]);
               console.log(lO.episodeNumber, 'formO', formO);
               if (lO.episodeNumber.tipo == formO.tipo && lO.episodeNumber.epn == formO.epn) return;
               else {
                  lO.episodeNumber.tipo = formO.tipo;
                  lO.episodeNumber.epn = formO.epn;
                  myCanvas.macroEpisodeNumber(formO.epn, formO.tipo);
               }
            });
            $layerItem.find('.dropdown.tipo').dropdown({
               fullTextSearch: true,
            }).dropdown('set selected', lO.episodeNumber.tipo);
         }
         // LIVELLO HERO
         if (lO.blurTile) {
            const $formblurTile = $(`
              <div class="layerForms">
                <div class="ui mini unstackable form blurTile">

                  <div class="sixteen wide field">
                    <label>Sfocatura</label>
                    <div class="ui left icon input">
                      <input type="number" name="blur" value="` + lO.blurTile.blur + `">
                      <i class="bullseye icon"></i>
                    </div>
                  </div>
                </div>
              </div>`).appendTo($layerItem);
            $layerItem.on('change', '[name="blur"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.blurTile')[0]);
               if (lO.blurTile.blur == formO.blur) return;
               else myCanvas.macroBlurTile(formO.blur, lO.name);
            });
         }
         // LIVELLO TITOLO
         if (lO.titleTemplate) {
            const $formTitleTemplate = $(`
              <div class="layerForms">
                <div class="ui mini unstackable form titleTemplate">
                  <!--TEMPLATE-->
                  <div class="sixteen wide field">
                    <label>Template</label>
                    <div class="ui serch selection dropdown templateName" tabindex="0">
                      <input type="hidden" name="templateName">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu">
                        ${Object.keys(_myCanvas.titleTemplates).map((tt) => {
               return `<div class="item" data-value="${tt}">${tt}<br>Template</div>`;
            }).join('')}
                      </div>
                    </div>
                  </div>

                  <!--TITOLO-->
                  <div class="field">
                    <label>Titolo</label>
                    <textarea rows="3" placeholder="Titolo..." name="title">${lO.titleTemplate.title}</textarea>
                  </div>

                  <div class="sixteen wide field" style="display:none;">
                    <label></label>
                    <div class="ui selection dropdown font" tabindex="0">
                      <input type="hidden" name="font">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu">
                        ${_myCanvas.fonts.map((f) => `
                          <div class="item" data-value="${f.family}${f.weight != 'normal' ? ' ' + f.weight : ''}">
                            <span style="font-family: ${f.family}; font-weight:${f.weight};">${f.family} ${f.weight != 'normal' ? f.weight : ''}</span>
                          </div>`).join('')
               }
                      </div>
                    </div>
                  </div>
                  <div class="ui fields">
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="size" value="${lO.titleTemplate.size}">
                        <i class="font icon"></i>
                      </div>
                    </div>
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="lineHeight" value="${lO.titleTemplate.lineHeight}">
                        <i class="list icon"></i>
                      </div>
                    </div>
                    <div class="four wide field">
                      <label></label>
                      <input type="color" name="color" value="${lO.titleTemplate.color}">
                    </div>
                  </div>

                  <!--SOTTO TITOLO-->
                  <div class="field">
                    <label>Sotto Titolo</label>
                    <textarea rows="3" placeholder="Sotto Titolo..." name="subTitle">${lO.titleTemplate.subTitle ? lO.titleTemplate.subTitle : ''}</textarea>
                  </div>
                  <div class="sixteen wide field" style="display:none;">
                    <label></label>
                    <div class="ui selection dropdown fontSub" tabindex="0">
                      <input type="hidden" name="fontSub">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu">
                        ${_myCanvas.fonts.map((f) => `
                          <div class="item" data-value="${f.family}${f.weight != 'normal' ? ' ' + f.weight : ''}">
                            <span style="font-family: ${f.family}; font-weight:${f.weight};">${f.family} ${f.weight != 'normal' ? f.weight : ''}</span>
                          </div>`).join('')
               }
                      </div>
                    </div>
                  </div>
                  <div class="ui fields">
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="sizeSub" value="${lO.titleTemplate.sizeSub}">
                        <i class="font icon"></i>
                      </div>
                    </div>
                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="lineHeightSub" value="${lO.titleTemplate.lineHeightSub}">
                        <i class="list icon"></i>
                      </div>
                    </div>
                  </div>

                  <!--OPZIONI-->
                  <div class="ui fields">
                    <div class="eight wide field">
                      <label>Opzioni</label>
                      <div class="ui fluid selection dropdown xAlign" tabindex="0">
                        <input type="hidden" name="xAlign">
                        <i class="dropdown icon"></i>
                        <div class="text"></div>
                        <div class="menu" tabindex="-1">
                          <div class="item" data-value="left"><i class="align left icon"></i></div>
                          <div class="item" data-value="center"><i class="align center icon"></i></div>
                          <div class="item" data-value="right"><i class="align right icon"></i></div>
                        </div>
                      </div>
                    </div>
                    <div class="eight wide field">
                      <label>&nbsp;</label>
                      <div class="ui fluid selection dropdown yAlign" tabindex="0">
                        <input type="hidden" name="yAlign">
                        <i class="dropdown icon"></i>
                        <div class="text"></div>
                        <div class="menu" tabindex="-1">
                          <div class="item" data-value="top"><i class="arrow alternate up icon"></i></div>
                          <div class="item" data-value="bottom"><i class="arrow alternate down icon"></i></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ui fields">

                    <div class="six wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="gradAlpha" value="${lO.titleTemplate.gradAlpha}">
                        <i class="tint icon"></i>
                      </div>
                    </div>
                    <div class="ten wide field">
                      <label></label>
                      <div class="ui fluid selection dropdown shadow" tabindex="0">
                        <input type="hidden" name="shadow">
                        <i class="dropdown icon"></i>
                        <div class="text"></div>
                        <div class="menu">
                          ${Object.keys(_myCanvas.shadowTemplates).map((sk) => `
                            <div class="item" data-value="${sk}">${sk}</div>`).join('')
               }
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--HIDDEN-->
                  <div class="sixteen wide field" style="display:none;">

                    <div class="ten wide field">
                      <label>Altro</label>
                      <div class="ui left icon input">
                        <input type="number" name="alpha" value="${lO.titleTemplate.alpha}">
                        <i class="chess board icon"></i>
                      </div>
                    </div>
                    <div class="ten wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="marginX" value="${lO.titleTemplate.marginX}">
                        <i class="arrows alternate horizontal icon"></i>
                      </div>
                    </div>
                    <div class="ten wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="marginY" value="${lO.titleTemplate.marginY}">
                        <i class="arrows alternate vertical icon"></i>
                      </div>
                    </div>

                    <div class="ten wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="allCaps" value="${lO.titleTemplate.allCaps}">
                        <i class="font icon"></i>
                      </div>
                    </div>
                    <div class="ten wide field">
                      <label></label>
                      <div class="ui left icon input">
                        <input type="number" name="allCapsSub" value="${lO.titleTemplate.allCapsSub}">
                        <i class="font icon"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`).appendTo($layerItem);
            $layerItem.find('.dropdown.templateName').dropdown({
               fullTextSearch: true,
            }).dropdown('set selected', lO.titleTemplate.name);
            $layerItem.find('.dropdown.font').dropdown({ fullTextSearch: true }).dropdown('set selected', lO.titleTemplate.font);
            $layerItem.find('.dropdown.fontSub').dropdown({ fullTextSearch: true }).dropdown('set selected', lO.titleTemplate.fontSub);
            $layerItem.find('.ui.dropdown.xAlign').dropdown('set selected', lO.titleTemplate.xAlign);
            $layerItem.find('.ui.dropdown.yAlign').dropdown('set selected', lO.titleTemplate.yAlign);
            $layerItem.find('.ui.dropdown.shadow').dropdown('set selected', lO.titleTemplate.shadow);
            $layerItem.on('change', '[name]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.titleTemplate')[0]);
               const input = $(this).attr('name');
               console.log(input, formO);
               if (input == 'templateName') { // set template
                  const temp = Object.assign({}, _myCanvas.titleTemplates[formO.templateName]);
                  temp.name = formO.templateName;
                  temp.title = formO.title;
                  temp.subTitle = formO.subTitle;
                  myCanvas.macroTitle(temp, true);
               } else { // Render
                  if (input != 'title' && input != 'subTitle') formO.templateName = ''; // template modificato
                  formO.name = formO.templateName;
                  myCanvas.macroTitle(formO);
               }
            });
         }
         // LIVELLO TITOLO LOGO
         if (lO.titleLogo) {
            const $formTitleTemplate = $(`
              <div class="layerForms">
                <div class="ui mini unstackable form titleLogo">
                  <div class="fields">
                    <div class="sixteen wide field">
                      <label></label>
                      <div class="ui fluid mini button local"><i class="folder open icon"></i>Logo Locale</div>
                    </div>
                  </div>

                  <div class="fields">
                    <div class="sixteen wide field">
                      <label></label>
                      <div class="ui fluid mini button search"><i class="search icon"></i>Cerca Logo</div>
                    </div>
                  </div>
                  <div class="sixteen wide field">
                    <label></label>
                    <div class="ui fluid selection dropdown shadow" tabindex="0">
                      <input type="hidden" name="shadow">
                      <i class="dropdown icon"></i>
                      <div class="text"></div>
                      <div class="menu">
                        <div class="item" data-value="none">Senza Ombra</div>
                        ${Object.keys(_myCanvas.shadowTemplates).map((sk) => `
                          <div class="item" data-value="${sk}">${sk}</div>`).join('')
               }
                      </div>
                    </div>
                  </div>
                  <div class="ui three fields">
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="tl"><i class="chevron up icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="tc"><i class="chevron up icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="tr"><i class="chevron up icon"></i></a>
                    </div>
                  </div>
                  <div class="ui three fields">
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="cl"><i class="chevron left icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="cc"><i class="circle icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="cr"><i class="chevron right icon"></i></a>
                    </div>
                  </div>
                  <div class="ui three fields">
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="bl"><i class="chevron down icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="bc"><i class="chevron down icon"></i></a>
                    </div>
                    <div class="field">
                      <label></label>
                      <a class="ui fluid mini icon toggle button" data-pos="br"><i class="chevron down icon"></i></a>
                    </div>
                  </div>
                </div>
              </div>`).appendTo($layerItem);
            $layerItem.find('.ui.dropdown.shadow').dropdown('set selected', lO.titleLogo.shadow);
            $layerItem.on('click', '.local', function () {
               myCanvas.getLocalFile().then((img) => {
                  lO.titleLogo.logo = img;
                  myCanvas.macroTitleLogo(false, lO.titleLogo);
               });
            });
            $layerItem.on('click', '.search', function () {
               modalImageChooser(progInfo, 'Scegli un Logo', ['use=Logo']).then((img) => {
                  lO.titleLogo.logo = img;
                  myCanvas.macroTitleLogo(false, lO.titleLogo);
               });
            });
            $layerItem.on('change', '[name="shadow"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.titleLogo')[0]);
               lO.titleLogo.shadow = formO.shadow;
               console.log(lO.titleLogo);
               myCanvas.macroTitleLogo(false, lO.titleLogo);
            });
            // POSIZIONE
            $layerItem.on('click', '[data-pos]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.titleLogo')[0]);
               const m = myCanvas.settings.height / 10;
               const p = $(this).attr('data-pos');
               const poso = {
                  tl: {
                     x: m,
                     y: m,
                     xr: false,
                     yr: false,
                  },
                  tc: {
                     x: 'center',
                     y: m,
                     xr: false,
                     yr: false,
                  },
                  tr: {
                     x: m,
                     y: m,
                     xr: true,
                     yr: false,
                  },
                  cl: {
                     x: m,
                     y: 'center',
                     xr: false,
                     yr: false,
                  },
                  cc: {
                     x: 'center',
                     y: 'center',
                     xr: false,
                     yr: false,
                  },
                  cr: {
                     x: m,
                     y: 'center',
                     xr: true,
                     yr: false,
                  },
                  bl: {
                     x: m,
                     y: m,
                     xr: false,
                     yr: true,
                  },
                  bc: {
                     x: 'center',
                     y: m,
                     xr: false,
                     yr: true,
                  },
                  br: {
                     x: m,
                     y: m,
                     xr: true,
                     yr: true,
                  },
               };
               lO.titleLogo.pos = poso[p];
               myCanvas.layerMove(lO.name, poso[p].x, poso[p].y, poso[p].xr, poso[p].yr);
            });
         }
         if (lO.copyRight) {
            const $formcopyRight = $(`
              <div class="layerForms">
                <div class="ui mini unstackable form copyRight">

                  <div class="sixteen wide field">
                    <label>Copyright</label>
                    <textarea rows="3" placeholder="Testo..." name="text">${lO.copyRight.text}</textarea>
                  </div>
                </div>
              </div>`).appendTo($layerItem);
            $layerItem.on('change', '[name="text"]', function () {
               const formO = myCanvas.getObjFromForm($layerItem.find('.ui.form.copyRight')[0]);
               if (lO.copyRight.text == formO.text) return;
               else myCanvas.macroCopyRight(formO.text);
            });
         }
      }
      function updateLayerItem(lO, i) {

      }
   };
   MyCanvas.prototype.getObjFromForm = function (formElement) {
      const inputs = formElement.querySelectorAll('[name]');
      const fO = {};
      inputs.forEach((inp) => {
         const key = inp.getAttribute('name');
         let val = inp.value;
         // console.log(key, val)
         if (val && !isNaN(val)) val = parseFloat(val);
         fO[key] = val;
      });

      console.log('getObjFromForm:', fO);
      return fO;
   };
   MyCanvas.prototype.updateLayerFormTransform = function (layerName) {
      const lO = this.layers.find((l) => l.name == (layerName ? layerName : this.activeLayer));
      if (!lO) return;
      const $form = $('.layerItem[data-name="' + lO.name + '"]').find('.layerForms.transform');
      // console.log('updateLayerFormTransform:', $form, lO);
      if (!lO || !$form.length) return;
      $form.find('[name="x"]').val(lO.dx ? lO.dx : 0);
      $form.find('[name="y"]').val(lO.dy ? lO.dy : 0);
      $form.find('[name="w"]').val(lO.dw ? lO.dw : 0);
      $form.find('[name="h"]').val(lO.dh ? lO.dh : 0);
      $form.find('[name="c"]').val(lO.coverage ? lO.coverage : 0);
      $form.find('[name="s"]').val(Math.round(lO.scale * 100));
   };
   MyCanvas.prototype.editSettings = async function (source) {
      const myCanvas = this;
      const settings = this.settings;
      console.log('Edit Settings:', settings);
      $('#modalEditSettings').remove();
      const $modal = $(`
      <div class="ui small top aligned modal" id="modalEditSettings">
        <div class="header"><i class="cog icon"></i>Modifica Settings</div>
        <div class="content">Settings</div>
      </div>`).appendTo('body');
      const formHtml = `
      <div class="ui grid">
        <div class="eight wide column">
          <div class="ui unstackable small form settings">
            <h4 class="ui dividing header">Canvas Settings</h4>

            ${Object.keys(settings).map((k) => {
         if (['progInfo', 'templates', 'startHeight'].includes(k)) return '';
         return `
                <div class="${!['width', 'height', 'maxHeight', 'episodic', 'uses'].includes(k) ? 'disabled' : ''} field">
                  <label>${k.toUpperCase()}</label>
                  <input type="text" name="${k}" value="${settings[k]}">
                </div>`;
      }).join('')}
          </div>
        </div>

        <div class="eight wide column">
          <div class="ui unstackable small form progInfo">
            <h4 class="ui dividing header">Program Info</h4>
            ${Object.keys(settings.progInfo).map((k) => {
         return `
                <div class="field">
                  <label>${k.toUpperCase()}</label>
                  <input type="text" name="${k}" value="${settings.progInfo[k]}">
                </div>`;
      }).join('')}
          </div>
        </div>
      </div>`;
      $modal.find('.content').html(formHtml);
      const $actions = $(`
      <div class="actions">
        <div class="ui cancel red button">Annulla</div>
        <div class="ui approve green button">Applica</div>
      </div>`).appendTo($modal);
      // ACTIONS CLICK
      $modal.find('.approve.button').on('click', function () {
         // Settings
         var $fields = $modal.find('.settings .field:not(disabled)');
         let changeSize = false;
         let setMaxHeight = false;
         $fields.each((i, el) => {
            const $el = $(el);
            const $input = $el.find('input');
            const k = $input.attr('name');
            const val = $input.val();
            if (k == 'width' && settings[k] != val) changeSize = true;
            if (k == 'height' && settings[k] != val) changeSize = true;
            if (k == 'maxHeight' && settings[k] != val) setMaxHeight = true;
            // console.log(k, val);
            settings[k] = val;
         });
         if (changeSize) myCanvas.changeSize(settings.width, settings.height);
         if (setMaxHeight) myCanvas.setMaxHeight(settings.maxHeight);
         // progInfo
         var $fields = $modal.find('.progInfo .field:not(disabled)');
         $fields.each((i, el) => {
            const $el = $(el);
            const $input = $el.find('input');
            const k = $input.attr('name');
            const val = $input.val();
            // console.log(k, val);
            settings.progInfo[k] = val;
         });
      });
      // Show Modal
      $modal.modal({
         autofocus: false,
         transition: 'vertical flip',
      }).modal('show');
   };
   // ////////////////////////////////////////////////////////////////////////////// PRIVATE FUNCTIONS
   /**
    * Merge two or more objects. Returns a new object.
    * @private
    * @param {Boolean}  deep     If true, do a deep (or recursive) merge [optional]
    * @param {Object}   objects  The objects to merge together
    * @return {Object}          Merged values of defaults and options
    */
   const extend = function () {
      // Variables
      const extended = {};
      let deep = false;
      let i = 0;
      const length = arguments.length;
      // Check if a deep merge
      if (Object.prototype.toString.call(arguments[0]) === '[object Boolean]') {
         deep = arguments[0];
         i++;
      }
      // console.log(deep, arguments);
      // Merge the object into the extended object
      const merge = function (obj) {
         for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
               // If deep merge and property is an object, merge properties
               if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                  extended[prop] = extend(true, extended[prop], obj[prop]);
               } else {
                  extended[prop] = obj[prop];
               }
            }
         }
      };
      // Loop through each object and conduct a merge
      for (; i < length; i++) {
         const obj = arguments[i];
         merge(obj);
      }
      return extended;
   };
   /**
    * Get the closest matching element up the DOM tree.
    * @private
    * @param  {Element} elem     Starting element
    * @param  {String}  selector Selector to match against
    * @return {Boolean|Element}  Returns null if not match found
    */
   const getClosest = function (elem, selector) {
      for (; elem && elem !== document; elem = elem.parentNode) {
         if (elem.matches(selector)) return elem;
      }
      return null;
   };
   const createTempCan = function (w, h, append) {
      const tempCan = document.createElement('canvas');
      tempCan.width = w;
      tempCan.height = h;
      tempCan.style.float = 'left';
      tempCan.style.margin = '5px';
      tempCan.style.border = '1px dotted white';
      // tempCan.style.display = 'none';
      if (append) document.body.appendChild(tempCan);
      return tempCan;
   };
   const getImageUrlFromText = function (url) {
      let imgUrl = false;
      const rexUrl = /(.*)(\.bmp|\.svg|\.png|\.jpeg|\.jpg)/gi.exec(url);
      if (rexUrl && rexUrl[2]) imgUrl = rexUrl[1] + rexUrl[2];
      else imgUrl = url + '.jpg';
      return imgUrl;
   };
   const loadFonts = function (force) {
      const promises = [];
      const timeout = 15000;
      if (!force && _myCanvas.fonts) {
         return Promise.resolve('AAA Fonts già Caricati!');
      }
      // Il Primo Canvas della pagina
      if (!force && document.querySelectorAll('._myCanvas').length > 1) {
         return Promise.resolve('AAA Fonts già Caricati!');
      }
      // LOAD FONTS
      promises.push(new FontFaceObserver('skytext', {
         weight: 200,
      }).load(null, timeout));
      promises.push(new FontFaceObserver('skytext', {
         weight: 400,
      }).load(null, timeout));
      promises.push(new FontFaceObserver('skytext', {
         weight: 800,
      }).load(null, timeout));
      promises.push(new FontFaceObserver('SSportsC').load(null, timeout));
      promises.push(new FontFaceObserver('SSportsPL').load(null, timeout));
      promises.push(new FontFaceObserver('SSportsF').load(null, timeout));
      promises.push(new FontFaceObserver('Champions').load(null, timeout));
      promises.push(new FontFaceObserver('EuropaTitle').load(null, timeout));
      promises.push(new FontFaceObserver('DAZNTrim-SemiBold').load(null, timeout));
      promises.push(new FontFaceObserver('AvenirNext-Bold').load(null, timeout));
      promises.push(new FontFaceObserver('Flama-Bold').load(null, timeout));
      promises.push(new FontFaceObserver('Futura-Bold').load(null, timeout));
      promises.push(new FontFaceObserver('Futura-Extrabold-Condensed-Oblique').load(null, timeout));
      promises.push(new FontFaceObserver('Futura-Medium-Condensed-Oblique').load(null, timeout));
      promises.push(new FontFaceObserver('Gotham-Bold').load(null, timeout));
      promises.push(new FontFaceObserver('NGC-Din-onAir-Black').load(null, timeout));
      promises.push(new FontFaceObserver('Tungsten-Bold').load(null, timeout));
      promises.push(new FontFaceObserver('ESP-AlphaHeadline-Tab-Bold').load(null, timeout));
      promises.push(new FontFaceObserver('PremierLeague-Bold').load(null, timeout));
      promises.push(new FontFaceObserver('SSportNBA-Regular').load(null, timeout));
      promises.push(new FontFaceObserver('PFBeauSansPro-SeBold').load(null, timeout));
      promises.push(new FontFaceObserver('SSportsD-Medium').load(null, timeout));
      promises.push(new FontFaceObserver('BaiJamjuree-Medium').load(null, timeout));
      promises.push(new FontFaceObserver('Manrope-Medium').load(null, timeout));
      promises.push(new FontFaceObserver('Manrope-Extrabold').load(null, timeout));

      return Promise.all(promises).then(function (res) {
         const fonts = _.orderBy(res, ['family'], ['asc']);
         // console.log('Fonts Caricati:', fonts);
         _myCanvas.fonts = fonts;
         return fonts;
      });
   };
   CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
      console.log();
      if (width < 2 * radius) radius = width / 2;
      if (height < 2 * radius) radius = height / 2;
      this.beginPath();
      this.moveTo(x + radius, y);
      this.arcTo(x + width, y, x + width, y + height, radius);
      this.arcTo(x + width, y + height, x, y + height, radius);
      this.arcTo(x, y + height, x, y, radius);
      this.arcTo(x, y, x + width, y, radius);
      this.closePath();
      return this;
   };
   function mlFunction(text, x, y, w, h, xAlign, yAlign, lineheight, fn) {

      // 28/11/2019 Alvise rimuovo gli a capo ad inizio e fine riga
      text = text.replace(/^[\r\n]+|[\r\n]+$/g, '');
      // The objective of this part of the code is to generate an array of words.
      // There will be a special word called '\n' that indicates a separation of paragraphs.
      text = text.toString().replace(/\r/g, '');
      let words = [];
      const inLines = text.split('\n');
      let i;
      for (i = 0; i < inLines.length; i++) {
         if (i) words.push('\n');
         words = words.concat(inLines[i].split(' '));
      }
      // words now contains the array.
      // Next objective is generate an array of lines where each line has a property
      // called Words with all the words that fits in the line. Each word contains 2 fields:
      // .word for the actual word and .l for the length o the word.
      // If the line is the last line of a paragraps, the property EndOfParagraph will be true
      const sp = this.measureText(' ').width;
      const lines = [];
      let actualline = 0;
      let actualsize = 0;
      let wo;
      lines[actualline] = {};
      lines[actualline].Words = [];
      i = 0;
      while (i < words.length) {
         let word = words[i];
         if (word == '\n') {
            lines[actualline].EndParagraph = true;
            actualline++;
            actualsize = 0;
            lines[actualline] = {};
            lines[actualline].Words = [];
            i++;
         } else {
            wo = {};
            wo.l = this.measureText(word).width;
            if (actualsize === 0) {
               // If the word does not fit in one line, we split the word
               while (wo.l > w) {
                  word = word.slice(0, word.length - 1);
                  wo.l = this.measureText(word).width;
               }
               wo.word = word;
               lines[actualline].Words.push(wo);
               actualsize = wo.l;
               if (word != words[i]) {
                  // if a single letter does not fit in one line, just return without painting nothing.
                  if (word === '') return;
                  words[i] = words[i].slice(word.length, words[i].length);
               } else {
                  i++;
               }
            } else {
               if (actualsize + sp + wo.l > w) {
                  lines[actualline].EndParagraph = false;
                  actualline++;
                  actualsize = 0;
                  lines[actualline] = {};
                  lines[actualline].Words = [];
               } else {
                  wo.word = word;
                  lines[actualline].Words.push(wo);
                  actualsize += sp + wo.l;
                  i++;
               }
            }
         }
      }
      if (actualsize === 0) lines.pop(); // We remove the last line if we have not added any thing here.
      // The last line will be allways the last line of a paragraph even if it does not end with a  /n
      lines[actualline].EndParagraph = true;
      // Now we remove any line that does not fit in the heigth.
      let totalH = lineheight * lines.length;
      while (totalH > h) {
         lines.pop();
         totalH = lineheight * lines.length;
      }
      // Now we calculete where we start draw the text.
      let yy;
      const lineheightAlCorrected = lineheight / 1.2;
      if (yAlign == 'bottom') {
         yy = y + h - totalH + lineheightAlCorrected;
      } else if (yAlign == 'center') {
         yy = y + h / 2 - totalH / 2 + lineheightAlCorrected;
      } else {
         yy = y + lineheightAlCorrected;
      }
      const oldTextAlign = this.textAlign;
      this.textAlign = 'left'; // we will draw word by word.
      let maxWidth = 0;
      for (const li in lines) {
         if (!lines.hasOwnProperty(li)) continue;
         let totallen = 0;
         var xx; var usp;
         for (wo in lines[li].Words) {
            if (!lines[li].Words.hasOwnProperty(wo)) continue;
            totallen += lines[li].Words[wo].l;
         }
         // Here we calculate the x position and the distance betwen words in pixels
         if (xAlign == 'center') {
            usp = sp;
            xx = x + w / 2 - (totallen + sp * (lines[li].Words.length - 1)) / 2;
         } else if ((xAlign == 'justify') && (!lines[li].EndParagraph)) {
            xx = x;
            usp = (w - totallen) / (lines[li].Words.length - 1);
         } else if (xAlign == 'right') {
            xx = x + w - (totallen + sp * (lines[li].Words.length - 1));
            usp = sp;
         } else { // left
            xx = x;
            usp = sp;
         }
         for (wo in lines[li].Words) {
            if (!lines[li].Words.hasOwnProperty(wo)) continue;
            if (fn == 'strokeText' || fn == 'fillStrokeText') {
               this.strokeText(lines[li].Words[wo].word, xx, yy);
            }
            if (fn == 'fillText' || fn == 'fillStrokeText') {
               this.fillText(lines[li].Words[wo].word, xx, yy);
            }
            xx += lines[li].Words[wo].l + usp;
         }
         maxWidth = Math.max(maxWidth, xx);
         yy += lineheight;
      }
      this.textAlign = oldTextAlign;
      return {
         width: maxWidth,
         height: totalH,
      };
   }
   (function mlInit() {
      CanvasRenderingContext2D.prototype.mlFunction = mlFunction;
      CanvasRenderingContext2D.prototype.mlFillText = function (text, x, y, w, h, yAlign, xAlign, lineheight) {
         return this.mlFunction(text, x, y, w, h, xAlign, yAlign, lineheight, 'fillText');
      };
      CanvasRenderingContext2D.prototype.mlStrokeText = function (text, x, y, w, h, yAlign, xAlign, lineheight) {
         return this.mlFunction(text, x, y, w, h, xAlign, yAlign, lineheight, 'strokeText');
      };
      CanvasRenderingContext2D.prototype.mlFillStrokeText = function (text, x, y, w, h, yAlign, xAlign, lineheight) {
         return this.mlFunction(text, x, y, w, h, xAlign, yAlign, lineheight, 'fillStrokeText');
      };
   })();
   // //////////////////////////////////////////////////////////////////////////////// INIT
   _myCanvas.init = async function (options) {
      // Merge user options with defaults
      const settings = extend(defaults, options || {});
      // Creo i MyCanvas
      const elems = document.querySelectorAll(settings.selector);
      const newCanvases = [];
      const start = async () => {
         await asyncForEach([1, 2, 3], async (num) => {
            await waitFor(50);
            console.log(num);
         });
         console.log('Done');
      };
      await loadFonts();
      elems.forEach((el) => {
         const mcan = new MyCanvas(el, settings);
         newCanvases.push(mcan);
      });
      // console.log(newCanvases)
      return newCanvases;
   };
   // Public APIs
   return _myCanvas;
});
