module.exports = {
   init: function () {

      $('body').on('contextmenu', '[data-requestmenu]', function (e) {
         e.preventDefault();
         if ($(this).find('.popup').length) return;

         const $el = $(this);
         const $table = $el.closest('.dataTable');
         const $tr = $el.closest('tr');
         const rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;
         myPops.requestMenu($el, rowData);
      });

      if (myVars && myVars.io && (
         ['Admin', 'Supervisor'].includes(myVars.io.ruolo)
         || myVars.io.username == 'Caschetta'
         || (['Redattore'].includes(myVars.io.ruolo) && /Satellite/.test(myVars.io.area))
      )) {


         $('body').on('click', '[data-assegna_dbid]', function (e) {
            if ($(e.target).closest('.ui.popup').length) return;

            const $el = $(this);
            const ids = $el.attr('data-assegna_dbid').split('||');
            const title = ids[0] ? ids[0] : '';
            const idRequest = ids[1] ? ids[1] : '';
            const idResponse = ids[2] ? ids[2] : '';
            myPops.assegnaDbid($el, idRequest, idResponse);
         });

         $('body').on('click', '[data-popassegna_canale]', function () {
            if ($(this).find('.popup').length) return;

            const $el = $(this);
            myPops.assegnaCanale($el);
         });

         $('body').on('click', '[data-popassegna_grafico]', function () {
            if ($(this).find('.popup').length) return;
            if (!['Admin', 'Supervisor'].includes(myVars.io.ruolo)) return;

            const $el = $(this);
            myPops.assegnaGrafico($el);
         });

         $('body').on('click', '[data-popassegna_redattore]', function () {
            if ($(this).find('.popup').length) return;

            const $el = $(this);
            myPops.assegnaRedattore($el);
         });

         $('body').on('click', '[data-popassegna_attributi]', function () {
            if ($(this).find('.popup').length) return;

            const $el = $(this);
            myPops.assegnaAttributi($el);
         });

         $('body').on('click', '[data-ethanMetadatasets]', function () {
            if ($(this).find('.popup').length) return;

            const $el = $(this);
            myPops.setMetadataSets($el);
         });
      }

      $('body').on('mouseenter', '[data-note_richiesta]', function () {
         if ($(this).find('.popup').length) return;

         const $el = $(this);
         if (!$('.filterPop.visible, .myPops.edit.visible').length) myPops.showNoteRichiesta($el);
      });

      $('body').on('mouseenter', '[data-preview_images]', function () {
         if ($(this).find('.popup').length) return;

         const $el = $(this);
         if (!$('.filterPop.visible, .myPops.edit.visible').length) myPops.previewRequestImages($el);
      });

      $('body').on('mouseenter', '[data-date_richiesta]', function () {
         if ($(this).find('.popup').length) return;

         const $el = $(this);
         if (!$('.filterPop.visible, .myPops.edit.visible').length) myPops.showDateRichiesta($el);
      });

      $('body').on('mouseenter', '[data-copyClip]', function () {
         const $el = $(this);
         myPops.copyClipboard($el, 'show');
      });

      $('body').on('click', '[data-copyClip]', function () {
         const $el = $(this);
         myPops.copyClipboard($el);
      });

      $('body').on('mouseenter', '[data-previewResponseSynopsys]', function () {
         const $el = $(this);
         myPops.previewResponseSynopsys($el);
      });

      $('body').on('mouseenter', '[data-previewResponseImages]', function () {
         const $el = $(this);
         myPops.previewResponseImages($el);
      });

      $('body').on('mouseenter', '[data-previewresponse]', function () {
         const $el = $(this);

         const delay = setTimeout(function () { myPops.previewresponse($el); }, 300);
         $('[data-previewresponse]').on('mouseout', function () { clearTimeout(delay); });
      });

   },

   requestMenu: function ($el, row) {
      const $table = $el.closest('.dataTable');
      const selection = $table.DataTable().rows('.selected').data().toArray();

      $('.myPops').remove();
      $('.myPopsActivator').remove();
      const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      const admin = ['Admin', 'Supervisor'].includes(myVars.io.ruolo);
      const redattoreSatellite = ['Redattore'].includes(myVars.io.ruolo) && /satellite/gi.test(myVars.io.area);
      let invia = false;

      if (row.Response_id && row._datiok && row._fotook && row._metaok) {
         if (row._stato == 'In Lavorazione') {
            if (admin) invia = ['Invia Risposta', 'send'];
            else invia = ['Conferma', 'confirm'];
         } else if (row._stato == 'Confermata') {
            if (admin) invia = ['Invia Risposta', 'send'];
            else invia = ['Annulla Conferma', 'noConfirm'];
         } else if (row._stato == 'In Invio') {
            if (admin) invia = ['Annulla Invio', 'noSend'];
         } else if (row._stato == 'Inviata') {
            if (admin) invia = ['Invia Nuovamente', 'reSend'];
         }
      }

      // ${admin && rowData.Request_from_acquisition && ['Da Lavorare'].includes(row._stato) && row._idProgram &&
      // (row.Request_TipoRichiesta == 'EP' ? row._idEpisode && row._idEpisode != -1 : true) ?
      // `<a class="item inviaAcquisition"><i class="door open icon"></i>Invia Acquisition</a>` : ''}

      const $pop = $(`
        <div class="ui popup myPops edit" style="padding:0; width:200px;">
          <div class="ui small fluid vertical menu" style="box-shadow: none; border: none;">
            ${invia ? `<a class="item invia" data-op="${invia[1]}"><i class="plane icon"></i>${invia[0]}</a>` : ''}

            ${row.Request_Priorita == 11 && !row.Request_data_invio_acquisition && row.Request_data_commit_acquisition ? `<a class="item annullaInviaAcquisition"><i class="plane icon"></i>Annulla Invio Acquisition</a>` : ''}

            ${admin && row.Response_id && ['Confermata', 'In Invio', 'Inviata'].includes(row._stato) ? `<a class="item copy"><i class="copy icon"></i>Copia Risposta</a>` : ''}

            ${admin && myVars.Response_copy ? `<a class="item collega"><i class="linkify icon"></i>Collega Risposta <div class="a_blue" style="margin-right: 24px;">${myVars.Response_copy.Response_title}</div></a>` : ''}

            ${(admin || redattoreSatellite) && row.Request_id ? `<a class="item modifica"><i class="write icon"></i>Modifica Richiesta</a>` : ''}


            ${admin && row.Request_id ? `
              ${row.Request_TipoRichiesta == 'EP' ? `<a class="item createSS"><i class="code branch icon"></i>Crea Stagione</a>` : ''}
              ${row.Request_TipoRichiesta == 'SS' ? `<a class="item createSE"><i class="code branch icon"></i>Crea Serie</a>` : ''}
              ${row._idEpisode && row._idEpisode != -1 ? `<a class="item" target="_blank" href="https://tools.datatv.it/userLog?idEpisode=${row.Response_idEpisode}"><i class="user clock icon"></i>Log Episodio</a>` :
               row._idProgram ? `<a class="item" target="_blank" href="https://tools.datatv.it/userLog?idProgram=${row.Response_idProgram}"><i class="user clock icon"></i>Log Programma</a>` : ''}
              ${!row.Request_pending ? `<a class="item nascondi"><i class="eye slash outline icon"></i>Nascondi Richiesta</a>` : `<a class="item mostra"><i class="eye outline icon"></i>Mostra Richiesta</a>`}
               <a class="item elimina"><i class="trash alternate outline icon"></i>Elimina Richiesta</a>
              ` : ''}
          </div>
        </div>
      `).prependTo($el);

      $pop.on('click', '.invia', function (e) {
         const op = $(this).attr('data-op');
         $myPopsActivator.popup('hide');
         myWrites.inviaRisposta(row.Request_id, op, row.Response_idProgram, row.Response_idEpisode, row.Response_title).then((r) => {
            if ($table.length) $table.DataTable().ajax.reload();
         });
      });

      $pop.on('click', '.annullaInviaAcquisition', function(e) {
        $myPopsActivator.popup('hide');
         myWrites.annullaInviaAcquisition(row.Request_id).then( (r) => {
          if ($table.length) $table.DataTable().ajax.reload();
        });
      });

      $pop.on('click', '.copy', (e) => {
         $myPopsActivator.popup('hide');
         myVars.Response_copy = row;
      });

      $pop.on('click', '.collega', (e) => {
         $myPopsActivator.popup('hide');
         const risposta = myVars.Response_copy;
         const send = risposta.Request_data_invio && risposta._datiok && risposta._dbok && risposta._fotook;
         myWrites.collegaRisposta(risposta.Response_id, row.Request_id, row.Response_idProgram, row.Response_idEpisode, row.Response_title).then((r) => {
            if (send) {
               myWrites.inviaRisposta(row.Request_id, 'send', row.Response_idProgram, row.Response_idEpisode, row.Response_title);
            } else {
               if ($table.length) $table.DataTable().ajax.reload();
            }
         });
      });

      $pop.on('click', '.modifica', async (e) => {
         $myPopsActivator.popup('hide');
         const data = await modificaRichiestaModal(row);
         if (!Object.keys(data).length) return;
         myWrites.modificaRichiesta(row.Request_id, data, row.Response_idProgram, row.Response_idEpisode, row.Response_title).then((r) => {
            if ($table.length) $table.DataTable().ajax.reload();
         });
      });

      $pop.on('click', '.createSS', async (e) => {
         $myPopsActivator.popup('hide');
         const data = await myWrites.creaRichiestaModal('SS', row);
         myWrites.creaRichiesta(data).then((r) => {
            if ($table.length) $table.DataTable().ajax.reload();
         });
      });

      $pop.on('click', '.createSE', async (e) => {
         $myPopsActivator.popup('hide');
         const data = await myWrites.creaRichiestaModal('SE', row);
         myWrites.creaRichiesta(data).then((r) => {
            console.log('input', data);
            console.log('output', r);
            if ($table.length) $table.DataTable().ajax.reload();
         });
      });

      $pop.on('click', '.nascondi', async (e) => {
         $myPopsActivator.popup('hide');
         let data = [];
         let date = moment().format('YYYY-MM-DD HH:mm:ss');

         if (selection && selection.length) {
            const confirm = await myFunc.confirmModal('Nascondi Richieste Selezionate', `Vuoi Nascondere le <span class="a_blue">${selection.length}</span> Richieste Selezionate?`)
            if (confirm == 'ok') {
               data = selection.map(r => ({
                  id: r.Request_id,
                  pending: date
               }));
            }
         } else {
            data = [{
               id: row.Request_id,
               pending: date
            }];
         }

         if (data.length) {
            for (const el of data) {
               await myWrites.nascondiRichiesta(el);
            }
            if ($table.length)
               $table.DataTable().ajax.reload();
         }
      });

      $pop.on('click', '.mostra', async (e) => {
         $myPopsActivator.popup('hide');
         let data = [];
         let date = moment().format('YYYY-MM-DD HH:mm:ss');

         if (selection && selection.length) {
            const confirm = await myFunc.confirmModal('Mostra Richieste Selezionate', `Vuoi Rendere Visibili le <span class="a_blue">${selection.length}</span> Richieste Selezionate?`)
            if (confirm == 'ok') {
               data = selection.map(r => ({
                  id: r.Request_id,
                  pending: 'mostra'
               }));
            }
         } else {
            data = [{
               id: row.Request_id,
               pending: 'mostra'
            }];
         }

         if (data.length) {
            for (const el of data) {
               await myWrites.nascondiRichiesta(el);
            }
            if ($table.length)
               $table.DataTable().ajax.reload();
         }
      });

      $pop.on('click', '.elimina', (e) => {
         $myPopsActivator.popup('hide');
         myFunc.confirmModal('Elimina Richiesta', `Vuoi Eliminare questa richiesta?<div class="a_red">${row.Request_titolo}</div>`).then((r) => {
            if (r == 'ok') {
               myWrites.eliminaRichiesta(row.Request_id, row.Response_idProgram, row.Response_idEpisode, row.Response_title).then((r) => {
                  if ($table.length) $table.DataTable().ajax.reload();
               });
            }
         });
      });

      // popup
      $myPopsActivator.popup({
         popup: $pop,
         boundary: $('body'),
         exclusive: true,
         position: 'bottom center',
         lastResort: 'top center',
         distanceAway: -20,
         on: 'manual',
         onHidden: function () {
            $myPopsActivator.remove();
            $pop.remove();
         },
      }).popup('show');

      function modificaRichiestaModal(row) {
         return new Promise((resolve, reject) => {
            $('.ui.modal').remove();
            const $modal = $(`
          <div class="ui mini modal" id="modRichiModalesta">
            <div class="header"><i class="write icon"></i>Modifica Richiesta</div>

            <div class="content">
              <div class="ui small form">

              <div class="field">
                <label>Titolo</label>
                <input type="text" placeholder="Titolo" name="Request_titolo" value="">
              </div>

              <div class="fields">
                <div class="four wide field">
                  <label>Priorità</label>
                  <input type="text" placeholder="00" name="Request_Priorita" value="">
                </div>
                <div class="twelve wide field">
                  <label>Tipo</label>
                  <div class="ui selection dropdown">
                    <input type="hidden" class=""  name="Request_TipoRichiesta" value="">
                    <i class="dropdown icon"></i>
                    <div class="text">--</div>
                    <div class="menu">
                      <div class="item" data-value="TT">Programma</div>
                      <div class="item" data-value="SE">Serie</div>
                      <div class="item" data-value="SS">Stagione</div>
                      <div class="item" data-value="EP">Episodio</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="fields">
                <div class="eight wide field">
                  <label>Stagione</label>
                  <input type="text" placeholder="Stagione" name="Request_num_stagione" value="">
                </div>
                <div class="eight wide field">
                  <label>Episodio</label>
                  <input type="text" placeholder="Episodio" name="Request_episodio" value="">
                </div>
              </div>

              ${row.Request_ethanFlag ? `<div class="field">
                <label>Flags - Meta Data Sets</label>
                <div class="ui multiple selection dropdown">
                  <input type="hidden" class=""  name="Request_ethanMetadatasets" value="">
                  <i class="dropdown icon"></i>
                  <div class="text">--</div>
                  <div class="menu">
                    ${myVars.metadataSets.map((m) => `<div class="item" data-value="${m.value}"><div class="ui ${m.color} mini label" style="margin: 0 2px 0 -6px;">${m.name}</div></div>`).join('')}
                  </div>
                </div>
              </div>` : ``}

            </div>
          </div>

          <div class="actions">
            <div class="ui red button cancel">Annulla</div>
            <div class="ui green button ok">Modifica</i>
          </div>

        </div>`).appendTo('body');

            $modal.on('change', '[name="Request_TipoRichiesta"]', function (e) {
               const tipo = $(this).val();
               const $st = $modal.find('[name="Request_num_stagione"]').closest('.field');
               const $ep = $modal.find('[name="Request_episodio"]').closest('.field');
               const $creaSE = $modal.find('.creaSE').closest('.field');
               const $creaSS = $modal.find('.creaSS').closest('.field');

               if (tipo == 'TT') {
                  $st.addClass('disabled');
                  $ep.addClass('disabled');
                  $creaSE.addClass('disabled');
                  $creaSS.addClass('disabled');
               } else if (tipo == 'SE') {
                  $st.addClass('disabled');
                  $ep.addClass('disabled');
                  $creaSE.addClass('disabled');
                  $creaSS.addClass('disabled');
               } else if (tipo == 'SS') {
                  $st.removeClass('disabled');
                  $ep.addClass('disabled');
                  $creaSE.removeClass('disabled');
                  $creaSS.addClass('disabled');
               } else if (tipo == 'EP') {
                  $st.removeClass('disabled');
                  $ep.removeClass('disabled');
                  $creaSE.addClass('disabled');
                  $creaSS.removeClass('disabled');
               }
            });

            $modal.on('click', '.button.ok', (e) => {
               const tor = {};
               $modal.find('[name]').toArray().map((i) => {
                  const disabled = $(i).closest('.field').hasClass('disabled');
                  const k = $(i).attr('name');
                  const v = $(i).val();

                  if (v != row[k] || k == 'Request_TipoRichiesta') tor[k.replace('Request_', '')] = disabled ? null : v;
               });

               resolve(tor);
            });

            // Riempio i campi
            Object.keys(row).forEach((k) => {
               $modal.find('[name="' + k + '"]').val(row[k]);
            });

            $modal.find('[name="Request_TipoRichiesta"]').trigger('change');
            $modal.find('.ui.dropdown').dropdown();

            $modal.modal({
               autofocus: false,
               allowMultiple: true,
               transition: 'scale',
            }).modal('show');
         });
      }
   },

   assegnaDbid: async function ($el, idRequest, idResponse) {
      const dt = $el.closest('.dataTable').length ? $el.closest('.dataTable').DataTable() : null;
      const clickedRowData = dt && $el.closest('tr').length ? dt.row($el.closest('tr')).data() : null;
      let selectionData = dt && dt.rows({ selected: true }).count() ? dt.rows({ selected: true }).data().toArray()
         : clickedRowData ? [clickedRowData]
            : idRequest ? [{ Request_id: idRequest, Response_id: idResponse }]
               : null;

      if (!selectionData.length) return;

      // Se non trovo la riga cliccata nelle righe selezionate lavoro solo quella cliccata
      if (!selectionData.find(rd => rd.Request_id == clickedRowData.Request_id))
         selectionData = [clickedRowData];

      const parsedData = parseRowData(clickedRowData);

      // Popup Ricerca
      $('.myPops').remove();
      $('.myPopsActivator').remove();
      const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      const $pop = $(`
         <div class="ui flowing popup myPops edit">
            <div class="center aligned header">Associa Programma</div>
            <div class="ui form">
               <div class="field">
                  <label class="label"></label>

                  <div class="ui mini inverted blue menu" style="margin-bottom: 2px; max-width: 450px; overflow: auto;">
                     ${parsedData.searches.map(sa => `<a class="item" data-defSearch="${sa[1]}">${sa[0]}</a>`).join('')}
                  </div>

                  <div class="ui search programSearch" id="associaProg" data-program_search="programEpisode" data-dispatch="true">
                  <div class="ui left icon input" style="min-width: 28em;">
                     <input type="text" name="programSearch" placeholder="Cerca..." class="prompt">
                     <i class="database icon"></i>
                  </div>
               </div>
            </div>
         </div>
      `).appendTo($el);

      $pop.find('#associaProg').data('searchSource', clickedRowData);
      $pop.find('[data-defSearch]').on('click', function (e) {
         e.stopPropagation();
         e.preventDefault();
         let ts = $(this).attr('data-defSearch');
         $pop.find('input').val(ts);
         $pop.find('#associaProg').search('query');
      });

      // Cosa Cercare
      let toSearch = '';

      // Ricerca per title id se esiste
      if (!toSearch && parsedData.titleId) {
         const foundByTitleId = await client.service('program-join').find({ query: { title: `titleId=${parsedData.titleId}` } })
            .then(r => r.results && r.results.length ? r.results[0].item : null);

         if (foundByTitleId) toSearch = `titleId=${parsedData.titleId}`;
      }

      // Ricerca predefinita
      if (!toSearch && parsedData && parsedData.searches && parsedData.searches.length) {
         toSearch = parsedData.searches[0][1];
      }

      // Aggiungo Comcast se priorità 4
      if (clickedRowData && clickedRowData.Request_Priorita && clickedRowData.Request_Priorita == 4) {
         toSearch += '-- Comcast';
      }

      $pop.find('input').val(toSearch);
      $pop.find('#associaProg').search(myProgramSearch.getOpt('programEpisode', true, true)).search('query');

      $pop.find('#associaProg').on('choosed', async function (e) {
         $('.myPops').remove();
         $('.myPopsActivator').remove();

         let choosed = e.detail;
         for(const rowData of selectionData) {
            let proceed = await checkTitleId(rowData, choosed);
            if (!proceed) continue;

            const isAcquisition = rowData.Request_from_acquisition && !rowData.Request_data_invio_acquisition;

            if (isAcquisition)
               await sendAcquisition(rowData, choosed);
            else
               await associaId(rowData, choosed);
         }

         // if (dt) dt.rows(selectionData.map(rd => '#' + rd.id)).invalidate().draw();
         console.log('[Reload DataTable]', dt, $el)
         if (dt) dt.ajax.reload();
      });

      // popup
      $myPopsActivator.popup({
         popup: $pop,
         boundary: $('body'),
         exclusive: true,
         position: 'bottom center',
         lastResort: 'top center',
         distanceAway: -20,
         on: 'manual',
         onHidden: function () {
            $myPopsActivator.remove();
         },
         onHide: function () {
            const $prompt = $(this).find('.prompt');
            if ($prompt.is(':focus')) return false;
         }
      }).popup('show');

      $pop.find('input').focus();


      async function checkTitleId(rowData, el) {
         const rexTitleId = /TitleID richiesta: ?([^<\s]+)/gi.exec(rowData.Request_nota);
         const titleId = rexTitleId ? rexTitleId[1] : null;

         // Se non c'è un title id o è discovery lo ignoro
         if (!titleId || /DIS|DIA|DIV/gi.test(titleId))
            return 1;

         const foundByTitleId = await client.service('program-join').find({ query: { title: `titleId=${titleId}` } })
            .then(r => r.results && r.results.length ? r.results[0].item : null);

         // Se è lo stesso
         if (
            !foundByTitleId ||
            (foundByTitleId.idEpisode && foundByTitleId.idEpisode == el.idEpisode) ||
            (foundByTitleId.idProgram && foundByTitleId.idProgram == el.idProgram)
         )
            return 1;

         let confirmOverWrite = await myFunc.confirmModal('Sky TitleId già presente', `
            <div class="ui error message">
               <div class="header">La richiesta ha un TitleId associato a un altro ${el.idEpisode ? 'Episodio' : 'Programma'}</div>
               Se confermi Il <b>TitleId</b> verrà RIMOSSO dall'elemento attualmente associato:
            </div>
            <table class="ui basic celled table"><tbody><tr>
               <td class="collapsing"><div class="ui big purple label">TI ${foundByTitleId.titleId}</div></td>
               <td class="pad0">${myProgramSearch.getHtmlForSat(el)}</td>
            </tr></tbody></table>
         `);

         if (confirmOverWrite == 'ok') {
            // Rimuovo il title Id dal programma precendente
            let tor = { titleId: '-2' };

            if (foundByTitleId.idEpisode) {
               await client.service('episode').update(foundByTitleId.idEpisode, tor);
            } else {
               await client.service('program').update(foundByTitleId.idProgram, tor);
            }

            return 1;

         } else {
            return 0;
         }
      }

      async function sendAcquisition(rowData, el) {
         const requestItem = await client.service('program-join').find({ query: { what: 'request', idReq: rowData.Request_id } })
            .then(r => r.results && r.results.length ? r.results[0].item : null);

         if(!requestItem) return;

         let confirm = await myFunc.confirmModal(
            `Invia Risposta <b class="a_orange">Acquisition</b>`,
            `<div class="ui horizontal divider" style="margin-top: 0;">Rispondi  alla Richiesta Acquisition</div>

                  <table class="ui basic celled table"><tbody><tr>
                     <td class="collapsing" style="min-width: 155px;">${requestItem.titleId ? `<div class="ui big purple label">TI ${requestItem.titleId}</div>` : ''}</td>
                     <td class="pad0">${myProgramSearch.getHtmlForRequest(requestItem)}</td>
                  </tr></tbody></table>

                  <div class="ui horizontal divider" style="margin-top: 0;">${el.restype == 'episode' ? `Con l'Episodio` : `Con il Programma`}</div>

                  <table class="ui basic celled table"><tbody><tr>
                     <td class="collapsing" style="min-width: 155px;">${el.titleId ? `<div class="ui big purple label">TI ${el.titleId}</div>` : ''}</td>
                     <td class="pad0">${myProgramSearch.getHtmlForSat(el)}</td>
                     </td>
                  </tr></tbody></table>`
         );

         console.log('sendAcquisition', requestItem.id, el);
         // return;

         if (confirm == 'ok') {
            await myWrites.inviaAcquisition(requestItem.id, el);
         }

         return 1;
      }

      async function associaId(rowData, el) {
         const requestItem = await client.service('program-join').find({ query: { what: 'request', idReq: rowData.Request_id } })
            .then(r => r.results && r.results.length ? r.results[0].item : null);

         if (!requestItem) return;

         let confirm = await myFunc.confirmModal(
            `Associa Richiesta / Risposta`,
            `<div class="ui horizontal divider" style="margin-top: 0;">Associa la Richiesta / Risposta</div>

               <table class="ui basic celled table"><tbody><tr>
                  <td class="collapsing" style="min-width: 155px;">${requestItem.titleId ? `<div class="ui big purple label">TI ${requestItem.titleId}</div>` : ''}</td>
                  <td class="pad0">${myProgramSearch.getHtmlForRequest(requestItem)}</td>
               </tr></tbody></table>

               <div class="ui horizontal divider" style="margin-top: 0;">${el.restype == 'episode' ? `All'Episodio` : `Al Programma`}</div>

               <table class="ui basic celled table"><tbody><tr>
                  <td class="collapsing" style="min-width: 155px;">${el.titleId ? `<div class="ui big purple label">TI ${el.titleId}</div>` : ''}</td>
                  <td class="pad0">${myProgramSearch.getHtmlForSat(el)}</td>
               </tr></tbody></table>`
         );

         if (confirm == 'ok') {
            console.log('associaId', rowData, el);
            await myWrites.writeDbId(el.idBase, el.idProgram, el.idEpisode, rowData.Request_id, rowData.Response_id);

            // se richiesta è programma e associo a un episodio esistente cambiare tipo richiesta in episodio e completare informazioni richiesta
            if (rowData.Request_TipoRichiesta == 'TT' && el.idEpisode) {
               await modificaRichiesta(Request_id, {
                  TipoRichiesta: 'EP',
                  Request_num_stagione: el.SeriesNumber,
                  Request_episodio: el.episodioNumerico
               }, el.idProgram, el.idEpisode, el.Title);
            }
         } else {
            console.log('associaId operazione annullata', rowData, el);
         }

         return 1;
      }

      function parseRowData(rd) {
         let pData = {
            titolo: rd.Request_titolo,
            searches: []
         };

         console.log('//////////', rd)

         // Data From Note
         if (rd.Request_nota) {
            let regExes = {
               titleId: /TitleID richiesta: ?([^<\s]+)/gi,
               step: /\b(?:seasons\.? ?|season\.? ?|stagione\.? ?|stag\.? ?|Year\.? ?|yr\.? ?|st\.? ?|s\.? ?)(\d{1,})(?:episode\.? ?|episodio\.? ?|epis\.? ?|ep\.? ?|e\.? ?|\#)(\d{1,})\b/gi,
               st: /\b(?:seasons\.? ?|season\.? ?|stagione\.? ?|stag\.? ?|Year\.? ?|yr\.? ?|st\.? ?|s\.? ?)(\d{1,4}\-\d{1,4}|\d{1,4})\b/gi,
               ep: /\b(?:episode\.? ?|episodio\.? ?|epis\.? ?|ep\.? ?|e\.? ?)(\d{1,4})(?:\/?\d{1,}|r\b|\b)/gi,
            }

            Object.keys(regExes).forEach((key) => {
               let rex = regExes[key];
               let match = rex.exec(rd.Request_nota);
               rex.lastIndex = 0;

               if (match && match.length) {
                  pData.titolo = pData.titolo.replace(rex, '').trim();


                  if (key == 'titleId') {
                     pData.titleId = match[1];

                  } else if (key == 'step') {
                     pData.st = match[1].replace(/^0+/, '');
                     pData.ep = match[2].replace(/^0+/, '');

                  } else if (key == 'st') {
                     pData.st = match[1].replace(/^0+/, '');

                  } else if (key == 'ep') {
                     pData.ep = match[1].replace(/^0+/, '');

                  }
               }
            });
         }

         if (rd.Request_num_stagione) pData.st = rd.Request_num_stagione;
         if (rd.Request_episodio) pData.ep = rd.Request_episodio;

         if (rd._idEpisode) pData.idEp = rd._idEpisode;
         if (rd._idProgram) pData.idPr = rd._idProgram;

         if (rd.Request_anno_prod) pData.year = rd.Request_anno_prod;

         // Searches
         if (pData.st && pData.st != -1 && pData.ep)
            pData.searches.push(['Prog--S.E.', `${pData.titolo} -- s${pData.st} e${pData.ep}`]);
         if (pData.ep)
            pData.searches.push(['Prog--E.', `${pData.titolo} -- e${pData.ep}`]);
         if (pData.year)
            pData.searches.push(['Prog--Anno.', `${pData.titolo} -- ${pData.year}`]);
         if (pData.titolo)
            pData.searches.push(['Prog', `${pData.titolo}`]);
         if (pData.titleId)
            pData.searches.push(['Title Id', `titleId=${pData.titleId}`]);
         if (pData.idEp && pData.idEp != -1)
            pData.searches.push(['Id Ep.', `idEp=${pData.idEp}`]);
         if (pData.idPr && pData.idPr != -1)
            pData.searches.push(['Id Prog.', `idPr=${pData.idPr}`]);

         return pData;
      }




      // const $table = $el.closest('.dataTable');
      // const $tr = $el.closest('tr');


      // const rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;
      // const isAcquisition = rowData.Request_from_acquisition && !rowData.Request_data_invio_acquisition;
      // let parsedData = parseRowData(rowData);
      // let titleId = parsedData.titleId;

      // if (!idRequest) idRequest = rowData.Request_id;
      // if (!idResponse) idResponse = rowData.Response_id;

      // $('.myPops').remove();
      // $('.myPopsActivator').remove();
      // const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      // const $pop = $(`
      //    <div class="ui flowing popup myPops edit">
      //       <div class="center aligned header">Associa Programma</div>
      //       <div class="ui form">
      //          <div class="field">
      //             <label class="label"></label>

      //             <div class="ui mini inverted blue menu" style="margin-bottom: 2px; max-width: 450px; overflow: auto;">
      //                ${parsedData.searches.map(sa => `<a class="item" data-defSearch="${sa[1]}">${sa[0]}</a>`).join('')}
      //             </div>

      //             <div class="ui search programSearch" id="associaProg" data-program_search="programEpisode" data-dispatch="true">
      //             <div class="ui left icon input" style="min-width: 28em;">
      //                <input type="text" name="programSearch" placeholder="Cerca..." class="prompt">
      //                <i class="database icon"></i>
      //             </div>
      //          </div>
      //       </div>
      //    </div>
      // `).appendTo($el);

      // $pop.find('#associaProg').data('searchSource', rowData);
      // $pop.find('[data-defSearch]').on('click', function (e) {
      //    e.stopPropagation();
      //    e.preventDefault();
      //    let ts = $(this).attr('data-defSearch');
      //    $pop.find('input').val(ts);
      //    $pop.find('#associaProg').search('query');
      // });

      // // Cosa Cercare
      // let toSearch = '';
      // let preSearchedTitleId = null;

      // // provo prima a cercare il title id
      // if (titleId) {
      //    preSearchedTitleId = await client.service('program-join').find({ query: { title: `titleId=${titleId}` } })
      //       .then(r => r.results && r.results.length ? r.results[0].item : null);
      // }

      // if (preSearchedTitleId)
      //    toSearch = `titleId=${titleId}`;
      // else
      //    toSearch = parsedData.searches[0][1];

      // if (rowData && rowData.Request_Priorita && rowData.Request_Priorita == 4) {
      //    toSearch += '-- Comcast';
      // }

      // $pop.find('input').val(toSearch);
      // $pop.find('#associaProg').search(myProgramSearch.getOpt('programEpisode', true, true)).search('query');

      // // Associa
      // $pop.find('#associaProg').on('choosed', async function (e) {
      //    $('.myPops').remove();
      //    $('.myPopsActivator').remove();

      //    //////////////////////////
      //    // l'elemento selezionato
      //    let choosed = e.detail;

      //    //////////////////////////
      //    // La richiesta
      //    let requestItem = await client.service('program-join').find({ query: { what: 'request', idReq: idRequest } })
      //       .then(r => r.results && r.results.length ? r.results[0].item : null);

      //    //////////////////////////
      //    // Controllo TitleId se è di sky
      //    if (preSearchedTitleId && !/DIS|DIA|DIV/gi.test(preSearchedTitleId.titleId)) {
      //       if ((preSearchedTitleId.idProgram != choosed.idProgram)
      //          || (preSearchedTitleId.idEpisode && preSearchedTitleId.idEpisode != choosed.idEpisode)) {

      //          // è già presente nel db un programma diverso con il titleId richiesto
      //          let overwrite = await myFunc.confirmModal('Sky TitleId già presente', `
      //             <div class="ui error message">
      //                <div class="header">La richiesta ha un TitleId associato a un altro ${choosed.idEpisode ? 'Episodio' : 'Programma'}</div>
      //                Se confermi Il <b>TitleId</b> verrà RIMOSSO dall'elemento attualmente associato:
      //             </div>
      //             <table class="ui basic celled table"><tbody><tr>
      //                <td class="collapsing"><div class="ui big purple label">TI ${preSearchedTitleId.titleId}</div></td>
      //                <td class="pad0">${myProgramSearch.getHtmlForSat(choosed)}</td>
      //             </tr></tbody></table>
      //          `);

      //          if (overwrite == 'ok') {
      //             // Rimuovo il title Id dal programma precendente
      //             let tor = { titleId: '-2' };

      //             if (preSearchedTitleId.idEpisode) {
      //                await client.service('episode').update(preSearchedTitleId.idEpisode, tor);
      //             } else {
      //                await client.service('program').update(preSearchedTitleId.idProgram, tor);
      //             }

      //          } else {
      //             return;
      //          }
      //       }
      //    }

      //    if (isAcquisition) { // INVIA ACQUISITION

      //       let confirm = await myFunc.confirmModal(
      //             `Invia Risposta <b class="a_orange">Acquisition</b>`,
      //             `<div class="ui horizontal divider" style="margin-top: 0;">Rispondi  alla Richiesta Acquisition</div>

      //             <table class="ui basic celled table"><tbody><tr>
      //                <td class="collapsing" style="min-width: 155px;">${requestItem.titleId ? `<div class="ui big purple label">TI ${requestItem.titleId}</div>` : ''}</td>
      //                <td class="pad0">${myProgramSearch.getHtmlForRequest(requestItem)}</td>
      //             </tr></tbody></table>

      //             <div class="ui horizontal divider" style="margin-top: 0;">${choosed.restype == 'episode' ? `Con l'Episodio` : `Con il Programma`}</div>

      //             <table class="ui basic celled table"><tbody><tr>
      //                <td class="collapsing" style="min-width: 155px;">${choosed.titleId ? `<div class="ui big purple label">TI ${choosed.titleId}</div>` : ''}</td>
      //                <td class="pad0">${myProgramSearch.getHtmlForSat(choosed)}</td>
      //                </td>
      //             </tr></tbody></table>`
      //          );


      //       if (confirm == 'ok') {
      //          await myWrites.inviaAcquisition(idRequest, choosed);
      //          if ($table.length) $table.DataTable().row($tr).invalidate().draw();
      //       }

      //    } else if (!isAcquisition) { // ASSOCIA RICHIESTA

      //       let confirm = await myFunc.confirmModal(
      //          `Associa Richiesta / Risposta`,
      //          `<div class="ui horizontal divider" style="margin-top: 0;">Associa la Richiesta / Risposta</div>

      //          <table class="ui basic celled table"><tbody><tr>
      //             <td class="collapsing" style="min-width: 155px;">${requestItem.titleId ? `<div class="ui big purple label">TI ${requestItem.titleId}</div>` : ''}</td>
      //             <td class="pad0">${myProgramSearch.getHtmlForRequest(requestItem)}</td>
      //          </tr></tbody></table>

      //          <div class="ui horizontal divider" style="margin-top: 0;">${choosed.restype == 'episode' ? `All'Episodio` : `Al Programma`}</div>

      //          <table class="ui basic celled table"><tbody><tr>
      //             <td class="collapsing" style="min-width: 155px;">${choosed.titleId ? `<div class="ui big purple label">TI ${choosed.titleId}</div>` : ''}</td>
      //             <td class="pad0">${myProgramSearch.getHtmlForSat(choosed)}</td>
      //          </tr></tbody></table>`
      //       );

      //       if (confirm == 'ok') {
      //          await myWrites.writeDbId(choosed.idBase, choosed.idProgram, choosed.idEpisode, idRequest, idResponse);

      //          // se richiesta è programma e associo a un episodio esistente cambiare tipo richiesta in episodio e completare informazioni richiesta
      //          if (rowData.Request_TipoRichiesta == 'TT' && choosed.idEpisode) {
      //             await modificaRichiesta(Request_id, {
      //                TipoRichiesta: 'EP',
      //                Request_num_stagione: choosed.SeriesNumber,
      //                Request_episodio: choosed.episodioNumerico
      //             }, choosed.idProgram, choosed.idEpisode, choosed.Title);
      //          }

      //          if ($table.length) $table.DataTable().row($tr).invalidate().draw();
      //       }
      //    }
      // });

      // // popup
      // $myPopsActivator.popup({
      //    popup: $pop,
      //    boundary: $('body'),
      //    exclusive: true,
      //    position: 'bottom center',
      //    lastResort: 'top center',
      //    distanceAway: -20,
      //    on: 'manual',
      //    onHidden: function () {
      //       $myPopsActivator.remove();
      //    },
      //    onHide: function () {
      //       const $prompt = $(this).find('.prompt');
      //       if ($prompt.is(':focus')) return false;
      //    }
      // }).popup('show');

      // $pop.find('input').focus();


   },

   assegnaCanale: function ($el, data) {
      const $table = $el.closest('.dataTable');
      const $tr = $el.closest('tr');
      const rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;

      if (!data && rowData) {
         data = {
            id: rowData.Request_id,
            canali: rowData.Request_canali ? rowData.Request_canali.split(',')[0] : '',
         };
      }

      $('.myPops').remove();
      $('.myPopsActivator').remove();
      const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      const $pop = $(`
      <div class="ui flowing popup myPops edit">
        <form class="ui small form">
          <div class="field">
            <label>Cambia Canale</label>
            <div class="ui search selection dropdown modChannels" style="width:250px;">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">Seleziona Canale</div>
              <div class="menu">
                <div class="item" data-value="remove">Nessuno</div>
                ${[...new Set(
         myVars.channels.ibmsActive
            .map(c => c.clean)
            .concat([
               'Vh1',
               'Super',
               'Paramount',
               'DMax',
               'Real Time',
               'Nove',
               'Motor Trend'
            ])
      )]
            .sort()
            .map((c) => `<div class="item" data-value="${c}">${c}</div>`).join('')}
              </div>
            </div>
          </div>
        </form>
      </div>
    `).appendTo($('body'));

      $pop.find('.modChannels').dropdown('set selected', data.canali);
      $pop.find('.modChannels').dropdown('setting', {
         fullTextSearch: true,
         onChange: function (value, text, $choice) {
            $('.ui.popup').popup('hide all');
            if (!value) return;

            console.log(data.canali, value)
            if (value == 'remove' && !data.canali) return;
            if (data.canali == value) return;

            data.canali = value;
            myWrites.writeCanale(data).then((r) => {
               if ($table.length) $table.DataTable().row($tr).invalidate().draw();
            });
         },
      });

      // popup
      $myPopsActivator.popup({
         popup: $pop,
         movePopup: false,
         scrollContext: $tr.closest('.stickyHead'),
         boundary: $('body'),
         exclusive: true,
         position: 'bottom center',
         lastResort: 'top center',
         distanceAway: -20,
         on: 'manual',
         onHidden: function () {
            $myPopsActivator.remove();
            $pop.remove();
         },
      }).popup('show');
   },

   assegnaGrafico: async function ($el, data) {
      const users = myVars.users.tutti.filter((u) => u.attivo == true && ['Admin', 'Supervisor', 'Grafico'].includes(u.ruolo));
      const $table = $el.closest('.dataTable');
      const $tr = $el.closest('tr');
      const rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;

      if (!data && rowData) {
         data = {
            tipo: 'Grafico',
            idProgram: rowData._idProgram,
            idEpisode: rowData._idEpisode ? rowData._idEpisode : -1,
            codiceF: rowData.Request_externalID,
            programTitle: rowData.Satellite_Program_Title,
            created_by: myVars.io.id,
            assigned_to: null,
            created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            confirm_by: null,
            confirmed_at: null,
            note: null,
            sent: 0,
         };
      }

      if (!data) {
         let values = $el.attr('data-popassegna_grafico');
         if (values) values = values.split(',');

         if (!values[0]) return;
         data = {
            tipo: 'Grafico',
            idProgram: parseInt(values[0]),
            idEpisode: values[1] ? parseInt(values[1]) : -1,
            codiceF: values[2],
            programTitle: values[3],
            created_by: myVars.io.id,
            assigned_to: null,
            created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            confirm_by: null,
            confirmed_at: null,
            note: null,
            sent: 0,
         };
      }

      // Aggiunta assegnazioni del mese
      const currMonth = moment().startOf('month').format('MM');
      const prevMonth = moment().startOf('month').subtract(1, 'months').format('MM');
      const monthStart = moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD hh:mm');
      const assegnazioni = await client.service('archivioassegnazioni').find({
         query: {
            created_at: { $gte: monthStart },
            tipo: 'Grafico',
            $limit: 10000
         }
      });

      $('.myPops').remove();
      $('.myPopsActivator').remove();
      const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      const $pop = $(`
      <div class="ui flowing popup myPops edit" style="padding:0; max-height: 290px; overflow: hidden; overflow-y: auto;">
        <div class="ui vertical compact small menu" style="border: 0; box-shadow: none; text-align: left; width:240px !important;">
          <a class="item" style="padding:12px 16px; text-align: center;" data-value="remove"><b>Rimuovi Grafico</b></a>
          ${users.map((u) => `
            <a class="item"  data-value="${u.id}" style="padding: 5px 70px 5px 14px;">
              <img class="ui avatar image" src="${mySite}/avatars/${u.avatar ? u.avatar : 'user.png'}">
              <span style="line-height: 30px;">${u.nome} ${u.cognome}</span>
               <span class="ui small label" title="Assegnazioni nel mese corrente (mese precedente)" style="position: absolute; right: 10px; top: 14px;">
                  ${assegnazioni.data.filter(a => a.assigned_to == u.id && a.created_at.split('-')[1] == currMonth).length} a/m (${assegnazioni.data.filter(a => a.assigned_to == u.id && a.created_at.split('-')[1] == prevMonth).length})
               </span>
            </a>`).join('')}
        </div>
      </div>
    `).appendTo($el);

      $pop.find('[data-value]').on('click', function (e) {
         const userId = $(this).attr('data-value');
         $('.ui.popup').popup('hide all');

         data.assigned_to = userId;
         myWrites.writeGrafico(data).then((r) => {
            if ($table.length) {
               $table.DataTable().row($tr).invalidate().draw();

            } else {
               if ($el.find('.avatar.image').length) {
                  let userSel = users.find(u => u.id == userId);
                  $el.find('.avatar.image').attr('src', userSel && userSel.avatar ? './avatars/' + userSel.avatar : './avatars/user.png');

                  if ($el.find('.content .header').length) {
                     $el.find('.content .header').text(`${userSel && userSel.nome ? userSel.nome.substring(0, 1) + '.' : ''} ${userSel ? userSel.cognome : '--'}`);
                  }

                  if ($el.find('.content .description').length) {
                     $el.find('.content .description').text(`${data.created_at ? moment(data.created_at.replace(/T|Z$/gi, ' '), 'YYYY-MM-DD HH:mm').format('DD/MM/YY') : '???' }`);
                  }

               }

            }
         });
      });

      // popup
      $myPopsActivator.popup({
         popup: $pop,
         boundary: $('body'),
         exclusive: true,
         position: 'bottom center',
         lastResort: 'top center',
         distanceAway: -20,
         on: 'manual',
         onHidden: function () {
            $myPopsActivator.remove();
            $pop.remove();
         },
      }).popup('show');
   },

   assegnaRedattore: async function ($el, data, dataOld) {
      // const admins = myVars.users.tutti.filter((u) => ['Admin', 'Supervisor'].includes(u.ruolo) && /Content/.test(u.area));
      const users = myVars.users.tutti.filter((u) => u.attivo == true && ['Admin', 'Supervisor', 'Redattore'].includes(u.ruolo));
      const $table = $el.closest('.dataTable');
      const $tr = $el.closest('tr');
      const rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;

      if (!data && rowData) {

         data = {
            tipo: 'Redattore',
            idProgram: rowData._idProgram ? rowData._idProgram : -1,
            idEpisode: rowData._idEpisode ? rowData._idEpisode : -1,
            codiceF: rowData.Request_externalID,
            programTitle: rowData.Satellite_Program_Title,
            created_by: myVars.io.id,
            assigned_to: null,
            created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
            confirm_by: null,
            confirmed_at: null,
            note: null,
            sent: 0,
         };

         // Per la vecchia tabella di assegnazioni
         dataOld = {
            idUser: null,
            username: null,
            codiceF: rowData.Request_externalID,
            dataAssegnazione: moment().format('YYYY-MM-DD HH:mm:ss'),
         };
      }

      // Aggiunta assegnazioni del mese
      const currMonth = moment().startOf('month').format('MM');
      const prevMonth = moment().startOf('month').subtract(1, 'months').format('MM');
      const monthStart = moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD hh:mm');
      const assegnazioni = await client.service('archivioassegnazioni').find({
         query: {
            created_at: { $gte: monthStart },
            tipo: 'Redattore',
            $limit: 10000
         }
      });

      $('.myPops').remove();
      $('.myPopsActivator').remove();
      const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      const $pop = $(`
      <div class="ui flowing popup myPops edit" style="padding:0; max-height: 290px; overflow: hidden; overflow-y: auto;">
        <div class="ui vertical compact small menu" style="border: 0; box-shadow: none; text-align: left;">
          <a class="item" style="padding:12px 16px; text-align: center;" data-value="remove"><b>Rimuovi Redattore</b></a>
          ${users.map((u) => `
            <a class="item"  data-value="${u.id}" style="padding: 5px 70px 5px 14px;">
               <img class="ui avatar image" src="${mySite}/avatars/${u.avatar ? u.avatar : 'user.png'}">
               <span style="line-height: 30px;">${u.nome} ${u.cognome}</span>
               <span class="ui small label" title="Assegnazioni nel mese corrente (mese precedente)" style="position: absolute; right: 10px; top: 14px;">
                  ${assegnazioni.data.filter(a => a.assigned_to == u.id && a.created_at.split('-')[1] == currMonth).length} a/m (${assegnazioni.data.filter(a => a.assigned_to == u.id && a.created_at.split('-')[1] == prevMonth).length})
               </span>
            </a>`).join('')}
        </div>
      </div>
    `).appendTo($el);

      $pop.find('[data-value]').on('click', function (e) {
         const userId = $(this).attr('data-value');
         if (userId == 'remove') {
            data.assigned_to = 'remove';
            dataOld.idUser = 'remove';
         } else {
            const user = users.find((u) => u.id == userId);
            if (user) {
               data.assigned_to = user.id;

               // Per la vecchia tabella di assegnazioni
               dataOld.idUser = user.old_id;
               dataOld.username = user.username;
            }
         }

         myWrites.writeRedattore(data, dataOld).then((r) => {
            if ($table.length) $table.DataTable().row($tr).invalidate().draw();
         });
      });

      // popup
      $myPopsActivator.popup({
         popup: $pop,
         boundary: $('body'),
         exclusive: true,
         position: 'bottom center',
         lastResort: 'top center',
         distanceAway: -20,
         on: 'manual',
         onHidden: function () {
            $myPopsActivator.remove();
            $pop.remove();
         },
      }).popup('show');
   },

   assegnaAttributi: function ($el, data) {
      const $table = $el.closest('.dataTable');
      const $tr = $el.closest('tr');
      const rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;

      if (!data && rowData) {
         data = {
            id: rowData.Request_id,
            noteL: rowData.Request_attributo ? rowData.Request_attributo.noteL : '',
            imgRichieste: rowData.Request_attributo ? rowData.Request_attributo.imgRichieste : '',
            imgCadenza: rowData.Request_attributo ? rowData.Request_attributo.imgCadenza : '',
            controllare: rowData.Request_controllare ? rowData.Request_controllare : '',
         };
      }

      $('.myPops').remove();
      $('.myPopsActivator').remove();
      const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      const $pop = $(`
      <div class="ui flowing popup myPops edit">
        <form class="ui small form">

          <div class="field">
            <label>Note Lavorazione</label>
            <div class="ui search selection dropdown noteL" style="width:250px;">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">Seleziona Note Lavorazione</div>
              <div class="menu">
                <div class="item" data-value="remove">...</div>
                ${myVars.noteLavorazione.map((n) => `<div class="item" data-value="${n.name}"><i class="${n.icon} icon"></i>${n.name}</div>`).join('')}
              </div>
            </div>
          </div>

          <div class="field">
            <label>Immagini Richieste</label>
            <div class="ui search selection dropdown imgRichieste" style="width:250px;">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">Seleziona Immagini Richieste</div>
              <div class="menu">
                <div class="item" data-value="remove">...</div>
                ${myVars.immaginiRichieste.map((i) => `<div class="item" data-value="${i.name}"><i class="${i.icon} icon"></i>${i.name}</div>`).join('')}
              </div>
            </div>
          </div>

          <div class="field">
            <label>Cadenza Immagini</label>
            <div class="ui search selection dropdown imgCadenza" style="width:250px;">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">Cadenza Immagini</div>
              <div class="menu">
                <div class="item" data-value="remove">...</div>
                <div class="item" data-value="standard">Standard</div>
                <div class="item" data-value="settimanale">Settimanale</div>
              </div>
            </div>
          </div>

         <div class="field">
            <div class="ui checkbox controllare">
               <input type="checkbox" name="controllare">
               <label><i class="orange exclamation triangle link icon"></i>Controllare dal ${data.controllare ? moment(data.controllare).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY') }</label>
            </div>
         </div>

          <div class="field">
            <div class="ui right labeled icon small button salvaNote">Salva<i class="save icon"></i></div>
          </div>

        </form>
      </div>
    `).appendTo($('body'));

      $pop.find('.ui.dropdown').dropdown();
      $pop.find('.ui.checkbox').checkbox();

      if (data.controllare) $pop.find('.controllare').checkbox('check');

      if (data.noteL && data.noteL.value) $pop.find('.noteL').dropdown('set selected', data.noteL.value);
      if (data.imgRichieste) $pop.find('.imgRichieste').dropdown('set selected', data.imgRichieste);
      if (data.imgCadenza) $pop.find('.imgCadenza').dropdown('set selected', data.imgCadenza);

      $pop.find('.salvaNote').on('click', function (e) {
         data.noteL = $pop.find('.noteL').dropdown('get value');
         data.imgRichieste = $pop.find('.imgRichieste').dropdown('get value');
         data.imgCadenza = $pop.find('.imgCadenza').dropdown('get value');

         // Se data.controllare non è cambiato
         const controllareChecked = $pop.find('.controllare input')[0].checked;

         if (controllareChecked && !data.controllare) {
            data.controllare = moment().utc().format('YYYY-MM-DD HH:mm:ss');
         } else if (!controllareChecked) {
            data.controllare = null;
         }

         console.log('[assegnaAttributi]', data);

         myWrites.writeAttributi(data).then((r) => {
            if ($table.length) $table.DataTable().row($tr).invalidate().draw();
         });

         $myPopsActivator.popup('hide');
      });

      // popup
      $myPopsActivator.popup({
         popup: $pop,
         movePopup: false,
         scrollContext: $tr.closest('.stickyHead'),
         boundary: $('body'),
         exclusive: true,
         position: 'right center',
         lastResort: 'top left',
         distanceAway: -8,
         offset: 0,
         on: 'manual',
         onHidden: function () {
            $myPopsActivator.remove();
            $pop.remove();
         },
      }).popup('show');
   },

   showNoteRichiesta: function ($el) {
      let $table = $el.closest('.dataTable');
      let $tr = $el.closest('tr');
      let rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;
      let nota = rowData && rowData.Request_nota ? rowData.Request_nota : 'Nota: non presente.';
      let notaTxt = nota;


      // let notaObj = nota
      //    .replace('Generato in automatico da', 'Generato da:')
      //    .split('Missing').join('[#]Missing')
      //    .split(/\<br\>|\[#\]/gi).map((t) => {
      //       let a = t.split(':');
      //       return {
      //          t: a[0] ? a[0].trim() : '',
      //          v: a[1] ? a[1].split(',').join(', ').trim() : '',
      //       };
      //    });

      $('.myPops').remove();
      $('.myPopsActivator').remove();
      let $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      let $pop = $(`
      <div class="ui small popup myPops" style="white-space:normal; min-width:260px;">
        ${notaTxt.split('<br>')
         .map(t => `<span class="opacita6">${t.replace(/\,/gi, ', ').replace(/\s+/gi, ' ').replace(/\:/, ':</span>').trim()}`).join('<br>')
         }
      </div>
    `).appendTo('body');

      // popup
      $myPopsActivator.popup({
         popup: $pop,
         movePopup: false,
         scrollContext: $tr.closest('.stickyHead'),
         boundary: $('body'),
         exclusive: true,
         position: 'right center',
         lastResort: 'right center',
         distanceAway: -20,
         maxSearchDepth: 0,
         on: 'manual',
         onHidden: function () {
            $myPopsActivator.remove();
            $pop.remove();
         },
         delay: {
            show: 50,
            hide: 0,
         },
      }).popup('show');
   },

   showDateRichiesta: function ($el) {
      const $table = $el.closest('.dataTable');
      const $tr = $el.closest('tr');
      const rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;
      const date = [
         { n: 'Fine', icon: 'tiny circle', color: 'grey', v: rowData.Request_Dfine },
         { n: 'Inizio', icon: 'tiny circle', color: 'green', v: rowData.Request_Dinizio },
         { n: 'Agg. Richiesta', icon: 'tiny circle', color: 'grey', v: rowData.Request_data_aggiornamento },
         { n: 'In Invio', icon: 'plane', color: 'grey', v: rowData.Request_data_commit },
         { n: 'Salv. Risposta', icon: 'write', color: 'grey', v: rowData.Request_data_inLavorazione },
         { n: 'Inviata', icon: 'flag', color: 'blue', v: rowData.Request_data_invio },
         { n: 'Da Lavorare', icon: 'inbox', color: 'red', v: rowData.Request_data_ricevimento },
         { n: 'Conferma', icon: 'check circle', color: 'grey', v: rowData.Request_data_salvataggio },
         { n: 'Agg. Risposta', icon: 'tiny circle', color: 'grey', v: rowData.Response_data_aggiornamento },
         { n: 'Assegnazione', icon: 'user', color: 'grey', v: rowData.utenti_request_dataAssegnazione },
         { n: 'Acquis. In Invio', icon: 'user', color: 'grey', v: rowData.Request_data_commit_acquisition },
         { n: 'Acquis. Inviata', icon: 'user', color: 'grey', v: rowData.Request_data_invio_acquisition },
      ].filter((d) => d.v);

      date.forEach((d, i) => {
         d.m = moment(d.v, 'YYYY-MM-DD HH:mm:ss');
         d.d = d.m.format('ddd <b>DD/MM/YY</b>');
         d.ts = d.m.valueOf();
      });

      const ordered = myFunc.orderObjByKey(date, 'ts', 'asc');

      $('.myPops').remove();
      $('.myPopsActivator').remove();
      const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
      const $pop = $(`
      <div class="ui tiny flowing popup myPops">
        ${ordered.map((o) => o.d ? `
          <div class="timeline">
            <div class="tData a_grey">${o.d}</div>
            <div class="tIcon"><div class="line"></div><i class="fitted ${o.icon} ${o.color} icon tIcon"></i></div>
            <div class="tName">${o.n}</div>
          </div>` : '').join('')}
      </div>
    `).appendTo($el);

      // popup
      $myPopsActivator.popup({
         popup: $pop,
         boundary: $('body'),
         exclusive: true,
         position: 'right center',
         lastResort: 'right center',
         distanceAway: -20,
         maxSearchDepth: 0,
         on: 'manual',
         onHidden: function () {
            $myPopsActivator.remove();
            $pop.remove();
         },
         delay: {
            show: 50,
            hide: 0,
         },
      }).popup('show');
   },

   setMetadataSets: function ($el) {
      return new Promise((resolve, reject) => {
         let Request_id = false;
         let ethanMetadatasets = false;
         let $tr = $el.closest('tr');
         let isDataTable = $el.closest('.dataTable').length;
         console.log('Modifica ethanMetaDataSets');

         if ($el.attr('data-idRichiesta')) {
            // Se ci sono i dati nel tag html
            Request_id = $el.attr('data-idRichiesta');
            ethanMetadatasets = $el.attr('data-ethanMetadatasets');


         } else if (isDataTable) {
            // Se siamo in una dataTable
            const $table = $el.closest('.dataTable');
            const $tr = $el.closest('tr');
            const rowData = $tr.length ? $table.DataTable().row($tr).data() : false;

            if (rowData.Request_id) {
               Request_id = rowData.Request_id;
               ethanMetadatasets = rowData.Request_ethanMetadatasets;
            }
         }

         console.log('ethanMetaDataSets Modifica', Request_id, ethanMetadatasets);
         if (!Request_id) return;
         else {
            const emds = [];
            myVars.metadataSets.forEach((m) => {
               let toCheck = m.value;
               if (m.value == 'nowtv') toCheck = 'now';
               if (m.value == 'stbfusion') toCheck = 'fusion';

               if (ethanMetadatasets.toLowerCase().indexOf(toCheck.toLowerCase()) > -1) {
                  emds.push(m.value);
               }
            });

            ethanMetadatasets = emds;
         }

         // Rimuovo gli altri popup
         $('.myPops').remove();
         $('.myPopsActivator').remove();

         // Creo il popup
         $el.css('position: relative;');
         const $myPopsActivator = isDataTable ? $('<div class="myPopsActivator"></div>').prependTo($el) : $('<div class="myPopsActivatorClean"></div>').prependTo($el);
         const $pop = $(`
        <div class="ui flowing popup myPops edit">
          <form class="ui small form">
            <div class="field">
              <label>Cambia Meta Data Sets</label>
              <div class="ui multiple clearable search selection dropdown modEMDS" style="width:250px;">
                <input type="hidden">
                <i class="dropdown icon"></i>
                <div class="default text">Cambia Meta Data Sets</div>
                <div class="menu">
                  ${myVars.metadataSets.map((m) => `<div class="item" data-value="${m.value}"><div class="ui ${m.color} mini label" style="margin: 0 2px 0 -6px;">${m.name}</div></div>`).join('')}
                </div>
              </div>
            </div>
            <div class="field">
              <div class="ui blue tiny fluid button changeemds">Applica</div>
            </div>
          </form>
        </div>
      `).appendTo($('body'));

         console.log('init', ethanMetadatasets)
         $pop.find('.modEMDS').dropdown('set selected', ethanMetadatasets);

         // change
         $pop.find('.changeemds').on('click', function (e) {
            let newEmds = $pop.find('.modEMDS').dropdown('get value');
            if (newEmds) newEmds = 'skyq,' + newEmds;

            console.log('ethanMetaDataSets Nuovi', newEmds);

            $('.ui.popup').popup('hide all');
            myWrites.modificaRichiesta(Request_id, { ethanMetadatasets: newEmds }).then((r) => {
               console.log('ethanMetaDataSets cambiati correttamente:', newEmds);

               if (isDataTable) {
                  const $table = $el.closest('.dataTable');
                  //$table.DataTable().ajax.reload();
                  const $tr = $el.closest('tr');
                  $table.DataTable().row($tr).invalidate().draw();

               }

               resolve(newEmds);
            });
         });

         // popup
         $myPopsActivator.popup({
            popup: $pop,
            movePopup: false,
            scrollContext: $tr.closest('.stickyHead'),
            boundary: $('body'),
            exclusive: true,
            position: 'right center',
            lastResort: 'bottom center',
            distanceAway: -20,
            on: 'manual',
            onHidden: function () {
               $myPopsActivator.remove();
               $pop.remove();
            },
         }).popup('show');
      });
   },

   copyClipboard: function ($el, show) {
      const toCopy = $el.attr('data-copyClip');
      if (show) {

         $('.myPops').remove();
         $('.myPopsActivator').remove();
         const $pop = $(`
        <div class="ui tiny flowing popup myPops">
          ${toCopy}
        </div>
      `).appendTo($el);

         // popup
         $el.popup({
            popup: $pop,
            boundary: $('body'),
            exclusive: true,
            position: 'right center',
            lastResort: 'right center',
            distanceAway: -20,
            maxSearchDepth: 0,
            on: 'manual',
            onHidden: function () {
               $pop.remove();
            },
            delay: {
               show: 50,
               hide: 0,
            },
         }).popup('show');

      } else {
         myFunc.copyToClipboard(toCopy);
         $el.transition('flash');

      }
   },

   async previewresponse($el, imgH) {

      const attr = $el.attr('data-previewresponse').split(',');
      const errMsg = $el.attr('data-errorMsg');
      const tipo = attr[0];
      const idRequest = attr[1];
      const idResponse = attr[2];
      if (!idRequest || !idResponse || !tipo) return;

      const dataScheda = await client.service('content-scheda').get(idRequest);
      if (!dataScheda || !dataScheda.response) return;
      console.log(dataScheda);

      const req = dataScheda.request;
      const res = dataScheda.response;
      const meta = dataScheda.meta;

      $('.ui.popup').popup('hide all');
      $('.tableSideFixedArea').remove();
      const $td = $el.closest('td');
      const $tr = $el.closest('tr');
      const $dtdiv = $el.closest('.dt-div');
      const width = parseInt($td.offset().left) -20;
      const $sideArea = $(`
         <div class="tableSideFixedArea" style="width: ${width}px">
            <div class="ui header">
               ${myProgramSearch.getHtmlForRequestHeader(req, dataScheda.response)}
            </div>
            ${errMsg ? `
               <div class="ui negative message">
                  <div class="header">FOTO KO...</div>
                  <p>${errMsg}</p>
               </div>
            ` : ''}
         </div>`).insertBefore($dtdiv);

      $(document)
         .off('.tableSideFixedArea') // remove all events in namespace tableSideFixedArea
         .on({
            'mousemove.tableSideFixedArea': function (ev) {
               let inside = $(ev.target).closest('.tableSideFixedArea').length
                  || $(ev.target).closest('tr#' + $tr.attr('id')).length;

               if (!inside) {
                  $('.tableSideFixedArea').remove();
                  $(document).off('.tableSideFixedArea')
               }
            }
         });

      if (tipo == 'foto') {
         if (!imgH) imgH = 120;

          let imgRichieste = false;
          if (dataScheda.request.attributo.imgRichieste) {
              imgRichieste = dataScheda.request.attributo.imgRichieste;

          } else if (dataScheda.idPadre && dataScheda.idPadre != -1) {
              let dataPadre = await client.service('content-scheda').get(dataScheda.idPadre);
              if (dataPadre.request.attributo.imgRichieste) {
                  imgRichieste = dataPadre.request.attributo.imgRichieste;
              }

          }


         const formats = _myCanvas.contentFormatsToPrint(dataScheda, imgRichieste);

         formats.forEach((f) => {
            // Servizio
            if (!$sideArea.find(`.servizio_${f.service}`).length) {
               $sideArea.append(`<div class="servizio_${f.service}" style="margin-bottom: 14px;">
               <div class="ui dividing header">
                  <div class="content">${f.service}</div>
               </div>
            </div>`);
            }

            let url = '';
            if (dataScheda.response[f.id]) {
               url = `${mySite}/returnFile?func=processImage&height=${imgH}&uri=${mySite}/content-imgs/${dataScheda.response[f.id]}&destUri=/mnt/tools.datatv.it/thumbnails/response_${dataScheda.response.id}_${f.id}.jpg`;
            } else {
               url = `${mySite}/datatv/myCanvas/checkered.jpg`;
            }

            const ih = imgH;
            const iw = Math.floor(f.width * (imgH / f.height));

            $sideArea.find(`.servizio_${f.service}`).append(`
            <div class="imgContainer" style="width: ${iw}px; height: ${ih+24}px;">
                  <div style="padding:0 3px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"><b>${f.id.replace('image', '')}. ${f.name}</b></div>

               <div title="${f.id} ${f.name}" style="
                  background: url('${url}');
                  background-size: ${dataScheda.response[f.id] ? 'cover' : 'auto'};
                  width: ${iw}px;
                  height: ${ih}px;
               ">
               </div>
            </div>`);
         });

      } else if (tipo == 'dati') {
         $sideArea.append(`
            <div class="ui dividing header">
               <div class="content">Trame</div>
            </div>
            <div class="trame">
               <div class="trama"><div class="ui small header">Trama Lunga</div>${res.longDescription ? res.longDescription : 'Vuota...'}</div>
               <div class="trama"><div class="ui small header">Trama Corta</div>${res.shortDescription ? res.shortDescription : 'Vuota...'}</div>
               <div class="trama"><div class="ui small header">Trama SKY</div>${res.ethanSynopsis ? res.ethanSynopsis : 'Vuota...'}</div>
               <div class="trama"><div class="ui small header">Trama SKY VOD</div>${res.ethanMediumSynopsisVod ? res.ethanMediumSynopsisVod : 'Vuota...'}</div>
               <div class="trama"><div class="ui small header">Trama Primafila</div>${res.ppvSynopsis ? res.ppvSynopsis : 'Vuota...'}</div>
               <div class="trama"><div class="ui small header">Trama SKY Marketing Message</div>${res.briefSynopsis ? res.briefSynopsis : 'Vuota...'}</div>
            </div>`);

      } else if (tipo == 'meta') {
         $sideArea.append(`
            <div class="ui dividing header">
               <div class="content">Metadati</div>
            </div>
            <div class="metas">
               ${ Object.keys(meta).map(k => `
                  <div class="meta">
                     <div class="ui small header">${k}</div>
                     ${meta[k].length ? meta[k].map(m => `<div class="ui label">${m}</div>`).join('') : '<div class="ui basic label">Vuoto...</div>'}
                  </div>
               `).join('')}
            </div>`);
      }

   },

   async previewResponseImages($el) {
      const idRequest = $el.attr('data-previewResponseImages');
      if (!idRequest) return;

      const dataScheda = await client.service('content-scheda').get(idRequest);
      if (!dataScheda) return;

      const formats = _myCanvas.contentFormatsToPrint(dataScheda);

      $('.myPops').remove();
      const $pop = $(`<div class="ui small popup myPops" style="white-space:normal; min-width:960px; text-align: left;"></div>`).appendTo('body');

      formats.forEach((f) => {
         // Servizio
         if (!$pop.find(`.servizio_${f.service}`).length) {
            $pop.append(`<div class="servizio_${f.service}">
               <div class="ui small header" style="margin: 0;">
                  <div class="content">${f.service}</div>
               </div>
            </div>`);
         }

         let url = '';
         if (dataScheda.response[f.id]) {
            //url = `${mySite}/content-imgs/${dataScheda.response[f.id]}`;
            url = `https://tools.datatv.it/returnFile?func=processImage&height=120&uri=${mySite}/content-imgs/${dataScheda.response[f.id]}&destUri=/mnt/tools.datatv.it/thumbnails/response_${dataScheda.response.id}_${f.id}.jpg`;
         } else {
            url = `${mySite}/datatv/myCanvas/checkered.jpg`;
         }

         const ih = 120;
         const iw = Math.floor(f.width * (120 / f.height));

         $pop.find(`.servizio_${f.service}`).append(`
            <div title="${f.name}, ${f.id}" style="
               background: url('${url}');
               background-size: ${dataScheda.response[f.id] ? 'cover' : 'auto'};
               width: ${iw}px;
               height: ${ih}px;
               display: inline-block;
               overflow: hidden;
               padding: 1px;
               border: 1px solid #e8e9e9;">
            </div>
            `);
      })

      //console.log('preview idRequest', idRequest, dataScheda, formats);

      // popup
      $el.popup({
         popup: $pop,
         boundary: $('body'),
         exclusive: true,
         position: 'left center',
         lastResort: 'left center',
         distanceAway: -20,
         maxSearchDepth: 0,
         on: 'manual',
         onHidden: function () {
            $pop.remove();
         },
         delay: {
            show: 50,
            hide: 0,
         },
      }).popup('show');
   }
};
