module.exports = {
   init: function () {
      $('body').on('click', '.clearCache', function () {
         myRemoteCommands.clearCache(true);
      });

      client.service('messages').on('created', (m) => {
         // esegue il comando sul browser del destinatario
         if (m.user.ruolo != 'Admin' && m.to && m.to != myVars.io.id) return;
         console.log(`[myRemote commands] sent: ${m.text} to userId: ${m.to}`);

         if (m.text == 'clear cache') {
            myRemoteCommands.clearCache();

         } else if(m.text == 'clear cache reload') {
            myRemoteCommands.clearCache(true);

         } else if (m.text == 'reload page') {
            window.location = `${window.location}?refresh=${new Date().getTime()}`;

         } else if (m.text == 'logout page') {
            myAuth.logout();

         }
      });
   },

   clearCache: async function (reload) {
      console.log('Clear javascript cache...');
      let scripts = document.getElementsByTagName('script');
      let scriptUrls = [];

      // Salvo gli script attuali
      for (let script of scripts) {
         if (!script.src) continue;
         scriptUrls.push(`${script.src.split('?')[0]}?refresh=${new Date().getTime()}`);
      }

      // Rimuovo gli script presenti
      for (let script of scripts) {
         if (!script.src) continue;
         script.remove();
      }

      // Ricarico gli script con un timestamp
      for (let url of scriptUrls) {
         await affixScriptToHead(`${url}?refresh=${new Date().getTime()}`);
      }

      if (reload) {
         window.location.reload(true);
         // if (window.location.toString().indexOf('?') > -1) {
         //    window.location = `${window.location.toString()}&refresh=${new Date().getTime()}`;
         // } else {
         //    window.location = `${window.location.toString()}?refresh=${new Date().getTime()}`;
         // }
      }

      // funzione ricarica script
      function affixScriptToHead(url, onloadFunction) {
         return new Promise(function (resolve, reject) {
            var newScript = document.createElement("script");
            newScript.onerror = function (message, source, lineno, colno, error) {
               reject(`Errore reperimento script ${message}. (${url})`);
            }
            newScript.onload = resolve(`Caricato script (${url})`);
            document.head.appendChild(newScript);
            newScript.src = url;
         });
      }
   }
};