module.exports = {
  knownFormats: [{
    name: 'Auto',
  }, {
    name: 'Generico',
  }, {
    name: 'File EPG',
  }, {
    name: 'Listing SkyLife',
  }, {
    name: 'Listing Mediaset',
    fieldHeads: [
      [false, 'Rete'],
      [false, 'Data'],
      [false, 'Ora inizio'],
      ['Titolo', 'Tit. Programma'],
      ['Genere', 'Genere'],
      ['Credits', 'Descrizione EPG'],
      ['Prima', '1TV'],
      [false, 'Livello Divieto'],
      [false, 'Nocivo'],
      [false, 'Parental Rating'],
      ['Parental', 'Bollino'],
      ['Durata', 'Durata'],
    ],
  }, {
    name: 'Locandina SKY',
    fieldHeads: [
      [false, 'tx_date'],
      [false, 'tx_time'],
      ['Durata', 'tx_duration'],
      [false, 'tx_type'],
      ['Titolo', 'program_title'],
      ['Serie', 'series title'],
      ['Versione', 'version_title'],
      ['Parental', 'certification'],
      ['Audio', 'audio_fmt'],
      ['Video', 'video_fmt'],
      ['Sottotitoli', 'subtitles'],
      ['Live', 'live'],
    ],
  }, {
    name: 'Locandina NICK',
    fieldHeads: [
      [false, 'date'],
      [false, 'time'],
      ['Titolo', 'title'],
      ['St', 'season #'],
      ['Ep', 'episode #'],
      ['Sinossi', 'synopsis'],
      ['Video', 'aspect ratio'],
      ['Parental', 'parental rating'],
      [false, 'epg series link id'],
    ],
  }, {
    name: 'Locandina PESCA',
    fieldHeads: [
      [false, 'data'],
      ['Durata', 'durata prev'],
      ['Serie', 'titolo serie'],
      ['Titolo', 'titolo'],
      ['Nazione', 'paese'],
      ['Genere', 'genere'],
      [false, 'genere interno'],
      ['Anno', 'anno produzione'],
      ['Parental', 'certificazione'],
    ],
  }, {
    name: 'Locandina GIALLO',
    fieldHeads: [
      [false, 'channel'],
      [false, 'tx date'],
      [false, 'start time'],
      [false, 'tx type'],
      ['Durata', 'duration'],
      ['Titolo', 'title.'],
      ['Versione', 'version'],
      [false, 'media item number'],
      [false, 'comments'],
    ],
  }, {
    name: 'Locandina GAMBERO',
    fieldHeads: [
      [false, 'data'],
      [false, 'ora'],
      ['St', 'codice'],
      ['Titolo', 'descrizione'],
      ['Durata', 'durata'],
    ],
  }, {
    name: 'Locandina HISTORY',
    fieldHeads: [
      [false, 'start time planned time'],
      [false, 'material type'],
      ['Titolo', 'event title'],
      [false, 'upn'],
      [false, 'tx id/ status'],
      ['Durata', 'duration'],
      [false, 'tx source'],
      ['Sottotitoli', 'subt file no'],
      [false, 'bc/cn'],
    ],
  }, {
    name: 'Locandina AE',
    fieldHeads: [
      [false, 'Start time'],
      [false, 'End time'],
      [false, 'Series title'],
      ['Titolo', 'Title'],
      ['Durata', 'Duration'],
      [false, 'Media label'],
      [false, 'TC in'],
      [false, 'TC out'],
    ],
  }],

  RaiChNames: [
    // DB, Rayplay, RaiWeb
    ['Rai 1', 'rai-1', 'RaiUno'],
    ['Rai 2', 'rai-2', 'RaiDue'],
    ['Rai 3', 'rai-3', 'RaiTre'],
    ['Rai 4', 'rai-4', 'Rai4'],
    ['Rai 5', 'rai-5', 'Extra'],
    ['Rai Movie', 'rai-movie', 'RaiMovie'],
    ['Rai Premium', 'rai-premium', 'Premium'],
    ['Rai Gulp', 'rai-gulp', 'RaiGulp'],
    ['Rai YoYo', 'rai-yoyo', 'Yoyo'],
    ['Rai Storia', 'rai-storia', 'RaiEDU2'],
    ['Rai Scuola', 'rai-scuola'],
    ['Rai News 24', 'rai-news-24', 'RaiNews'],
    ['Rai Sport 1', 'rai-sport', 'RaiSport1'],
    ['Rai Sport 2', 'rai-sport-piu-hd', 'RaiSport2'],
    // Canali sconosciuti RaiWeb
    // EuroNews
    // RaiEducational
    // RaiMed
    // RaiWorld
  ],

  regexes: {
    st: /\b(?:seasons\.? ?|season\.? ?|stagione\.? ?|stag\.? ?|Year\.? ?|yr\.? ?|st\.? ?|s\.? ?)(\d{1,4}\-\d{1,4}|\d{1,4})\b/gi,
    ep: /\b(?:episode\.? ?|episodio\.? ?|epis\.? ?|ep\.? ?|e\.? ?)(\d{1,4})(?:\/?\d{1,}|r\b|\b)/gi,
    step: /\b(?:seasons\.? ?|season\.? ?|stagione\.? ?|stag\.? ?|Year\.? ?|yr\.? ?|st\.? ?|s\.? ?)(\d{1,})(?:episode\.? ?|episodio\.? ?|epis\.? ?|ep\.? ?|e\.? ?)(\d{1,})\b/gi,
  },

  newPassaggio: function() {
    const obj = {
      Category_ID: null,
      Category_Name: null,
      Channel_ID: null,
      ProgramChannel_AttributeMask: null,
      ProgramChannel_EndDate: null,
      ProgramChannel_ID: null,
      ProgramChannel_StartDate: null,
      ProgramChannel_StartTime: null,
      ProgramChannel_dataRedazione: null,
      ProgramChannel_dataUltimaModifica: null,
      ProgramChannel_utenteModifica: null,
      Program_ID: null,
      Program_OriginalTitle: null,
      Program_SeriesNumber: null,
      Program_Title: null,
      Program_Year: null,
      Program_completeFlag: null,
      Serie_ID: null,
      attributes: null,
      ciclo: null,
      Program_Nota: null,
      Program_Description: null,
      ProgramGenre: null,
      programCountry: null,
      Program_BlackAndWhite: null,
      episode_EpisodeNumber: null,
      episode_ID: null,
      episode_Location: null,
      episode_OriginalTitle: null,
      episode_Title: null,
      episode_Turn: null,
      episode_completeFlag: null,
      episode_episodioNumerico: null,
      episode_Description: null,
      myId: null,
      parental: null,
      original: null,
      workNotes: {},
    };

    return obj;

  },

  // Get Listings
  getFromFile: async function(file) {

    // Read
    const workbook = await getWorkbook(file);
    const myWorkbook = {
      name: file.name,
      sheets: [],
    };

    // Ciclo I fogli
    workbook.SheetNames.forEach((sheetName) => {
      if (/\+\+\+|>>>|<>|<confronto>|preparazione|elaborato non ordinato|-- Programmi --|--- Elaborato ---/gi.test(sheetName)) return;
      const sheet = workbook.Sheets[sheetName];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      const mySheet = {
        name: sheetName,
        nRows: range.e.r - range.s.r,
        nCols: range.e.c - range.s.c,
      };

      mySheet.rows = getRows(sheet);
      mySheet.format = getFormat(mySheet);
      mySheet.channel = myListings.getChannel([file.name, sheetName, mySheet.rows.slice().splice(0, 3).flat().map((c) => c.txt).filter((t) => t).join(' ')]);
      eccezioni(mySheet);

      mySheet.analisiGiorni = getAnalisiGiorni(mySheet);
      mySheet.analisiOre = getAnalisiOre(mySheet);
      myWorkbook.sheets.push(mySheet);
    });

    if (!myWorkbook.sheets.length) throw new Error('Non è stato Nessun foglio da elaborare.');
    const confirmed = await confirmData(myWorkbook);
    if (confirmed == 'ko') return;

    myWorkbook.sheets.forEach((mySheet) => {
      mySheet.progs = getProgs(mySheet);
      parseProgs(mySheet);

      mySheet.list = getListing(mySheet);
    });

    console.log('File Originale:', workbook);
    console.log('File Parsed:', myWorkbook);
    return myListings.parseListing(myWorkbook.sheets[0].list);

    // FUNZIONI
    function eccezioni(mySheet) {

      if (mySheet.format.name == 'Locandina HISTORY') {
        const giorni = mySheet.rows.flat().filter((c) => c.tip == 'giorno');
        if (giorni.length == 1) {
          // Sposto la data all'inizio
          const giorno = _.cloneDeep(giorni[0]);
          giorno.a = 'A1';
          giorno.c = 0;
          giorno.r = 0;
          mySheet.rows[0][0] = giorno;

          giorni[0].tip = 'text';
          giorni[0].txt = '';
          giorni[0].val = '';
          giorni[0].par = '';

          // Elimino la colonna con la falsa ora e le righe che non servono
          for (let y = mySheet.rows.length - 1; y >= 0; y--) {
            const row = mySheet.rows[y];

            if (y > 0 && row[2].txt != 'Programme Part') mySheet.rows.splice(y, 1);
          }

          // Comprimo i programmi uguali
          for (let y = mySheet.rows.length - 1; y >= 0; y--) {
            const row = mySheet.rows[y];
            const prev = y > 0 ? mySheet.rows[y - 1] : false;

            if (prev && prev[5].txt == row[5].txt) {
              mySheet.rows.splice(y, 1);
            }
          }

          // Sposto le ore in avanti + 1
          for (let y = 0; y < mySheet.rows.length; y++) {
            const row = mySheet.rows[y];
            const ora = row.find((c) => c.tip == 'ora');

            if (ora) {
              const h = ora.par.getHours() + 1;
              const m = ora.par.getMinutes();
              ora.par = new Date(1975, 0, 1, h, m);

            }
          }
        }
      }

      if (mySheet.format.name == 'Locandina AE') {
        // Cancello i programmi sotto al minuto
        for (let y = mySheet.rows.length - 1; y >= 0; y--) {

          const row = mySheet.rows[y];
          const dur = row[4];

          if (dur && dur.par) {
            const h = dur.par.getHours();
            const m = dur.par.getMinutes();
            if (h / 60 + m < 4) mySheet.rows.splice(y, 1);
          }
        }

        // Comprimo i programmi uguali e rimuovo le ore dalla colonna B
        for (let y = mySheet.rows.length - 1; y >= 0; y--) {
          const row = mySheet.rows[y];
          const prev = y > 0 ? mySheet.rows[y - 1] : false;

          if (row[3].txt && prev[3].txt) {
            row[3].txt = row[3].txt.replace(/ \- \d{1,3}\/\d{1,3}$/gi, '').replace(/¿/gi, '\'');
            prev[3].txt = prev[3].txt.replace(/ \- \d{1,3}\/\d{1,3}$/gi, '');

            if (row[3].txt == prev[3].txt) mySheet.rows.splice(y, 1);

          }

          if (row[1]) {
            console.log(row[1]);
            row[1].tip = 'text';
            row[1].txt = '';
            row[1].val = '';
            row[1].par = '';
          }

        }
      }

    }

    async function getWorkbook(file, opt) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function(e) {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, {
            type: 'array',
            raw: true,
            cellDates: true,
            cellNF: true,
            cellStyles: true,
            blankrows: false,
          });

          resolve(workbook);
        };
        reader.readAsArrayBuffer(file);
      });
    }

    function getRows(sheet) {
      const rows = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);

      for (let R = range.s.r; R <= range.e.r; ++R) {
        const row = [];
        rows.push(row);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell = getCell(sheet, C, R);
          row.push(cell);

        }
      }

      return rows;

      // FUNZIONI
      function getCell(sheet, C, R) {
        const address = XLSX.utils.encode_cell({
          c: C,
          r: R,
        });
        const cell = {
          a: address,
          c: C,
          r: R,
          val: null,
          txt: null,
          fmt: null,
          sty: null,
          tip: null,
          par: null,
          ori: null,
        };

        const obj = sheet[address];
        if (!obj) return cell;

        cell.ori = obj;
        cell.val = obj.v;
        cell.txt = obj.w ? obj.w : obj.v;
        cell.fmt = obj.z;
        cell.sty = obj.s;
        cell.tip = 'text';
        cell.par = null;

        if (/^(?!.*[A-Za-z])(\d{1,2})[\.:](\d{1,2})/gi.test(cell.txt)) { // verifico se è una stringa con l'ora
          const rexOra = /^(?!.*[A-Za-z])(\d{1,2})[\.:](\d{1,2})/gi;
          const rexOraSerial = /^(?!.*[A-Za-z])(\d{1,2})\.(\d{1,})$/gi;
          const matOra = rexOra.exec(cell.txt);
          const matOraSerial = rexOraSerial.exec(cell.txt);

          if (matOraSerial && matOraSerial.length > 1) {
            cell.tip = 'ora';
            cell.par = ExcelDateToJSDate(cell.txt);

          } else {
            cell.tip = 'ora';
            cell.par = new Date(1975, 0, 1, matOra[1], matOra[2]);

          }

        } else { // verifico se è una stringa con la data
          const rexData = [
            /^(\d{4})[\/-](\d{1,2})[\/-](\d{1,2})$/,
            /\b(january|february|march|april|may|june|july|august|september|october|november|december),? ?(\d{2,4})[a-z]{1,3}$/i,
            /^(\d{1,2})[\/-](\d{1,2})[\/-](\d{1,4})$/,
            /^(\d{1,2})[\/\s-](gennaio|gen|febbraio|feb|marzo|mar|aprile|apr|maggio|mag|giugno|giu|luglio|lug|agosto|ago|settembre|set|ottobre|ott|novembre|nov|dicembre|dic)[\/\s-](\d{1,4})$/i,
            /^(?:luned.|marted.|mercoled.|gioved.|venerd.|sabat.|domenic.)\s(\d{1,2})\s(gennaio|gen|febbraio|feb|marzo|mar|aprile|apr|maggio|mag|giugno|giu|luglio|lug|agosto|ago|settembre|set|ottobre|ott|novembre|nov|dicembre|dic)$/i,
            /^[a-z]+,? ?(\d{1,2})[a-z]{2} ?of ?(january|february|march|april|may|june|july|august|september|october|november|december),? ?(\d{2,4})$/i,
          ];

          rexData.forEach((rex, i) => {
            if (cell.par) return;

            const matData = rex.exec(cell.txt);
            let day; let month; let year;
            if (!matData || !matData.length || !matData[1] || !matData[2]) return;

            if (cell.val instanceof Date) { // è una patch perchè xlsx.js stampa mm/dd/yyyy nel testo a volte
              day = cell.val.getDate();
              month = cell.val.getMonth();
              year = cell.val.getFullYear();

            } else if (i == 0) {
              day = Math.abs(matData[3]);
              month = toMonth(matData[2]);
              year = matData[1] ? Math.abs(matData[1]) : 0;

            } else if (i == 1) {
              day = Math.abs(matData[2]);
              month = toMonth(matData[1]);
              year = null;

            } else {
              day = Math.abs(matData[1]);
              month = toMonth(matData[2]);
              year = matData[3] ? Math.abs(matData[3]) : 0;

            }

            if (!year) {
              const oggi = new Date();
              const oAnno = oggi.getFullYear();
              const oMese = oggi.getMonth();
              differenzaMesi = month - oMese;

              if (differenzaMesi > 10) year = oAnno - 1;
              else if (month > 8 && month < oMese) year = oAnno + 1;
              else year = oAnno;
            }

            if (year < 2000) year = year + 2000;

            const d = new Date(year, month, day);

            cell.tip = 'giorno';
            cell.par = d;
            // console.log(i, rex, matData, year, month, day, cell);
          });
        }

        return cell;
      }

      function toMonth(el) {
        // console.log('toMonth', el)
        const mesi = [
          ['1', '01', 'gennaio', 'january', 'gen'],
          ['2', '02', 'febbraio', 'february', 'feb'],
          ['3', '03', 'marzo', 'march', 'mar'],
          ['4', '04', 'aprile', 'april', 'apr'],
          ['5', '05', 'maggio', 'may', 'mag'],
          ['6', '06', 'giugno', 'june', 'giu'],
          ['7', '07', 'luglio', 'july', 'lug'],
          ['8', '08', 'agosto', 'august', 'ago'],
          ['9', '09', 'settembre', 'september', 'set'],
          ['10', '010', 'ottobre', 'october', 'ott'],
          ['11', '011', 'novembre', 'november', 'nov'],
          ['12', '012', 'dicembre', 'december', 'dic'],
        ];

        for (let i = 0; i < mesi.length; i++) {
          const mr = mesi[i];
          for (let y = 0; y < mr.length; y++) {
            const mt = mr[y];
            if (mt == el.toString().toLowerCase()) return i;
          }
        }

        return false;
      }

      function ExcelDateToJSDate(serial) {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const fractional_day = serial - Math.floor(serial) + 0.0000001;

        let total_seconds = Math.floor(86400 * fractional_day);

        const seconds = total_seconds % 60;

        total_seconds -= seconds;

        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;

        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
      }
    }

    function getAnalisiOre(mySheet) {
      const analisiOre = {
        celle: mySheet.rows.flat().filter((c) => c.tip == 'ora'),
        ore: [],
        oreCols: [],
        durateCols: [],
      };

      const uniqCols = [...new Set(analisiOre.celle.map((cell) => cell.c))];

      uniqCols.forEach((col) => {
        const ore = analisiOre.celle.filter((cell) => cell.c == col);
        const consecutivi = [];
        let minutiInCol = 0;

        ore.forEach((o, i) => {
          const hour = o.par.getTime();
          const prev = ore[i - 1] ? ore[i - 1].par.getTime() : false;
          const next = ore[i + 1] ? ore[i + 1].par.getTime() : false;
          // console.log(moment(o.par).format('HH:mm'), o)

          // Consecutivi
          if (next && prev && hour < next && hour > prev) consecutivi.push(o);
          else if (prev && hour > prev) consecutivi.push(o);
          else if (next && hour < next) consecutivi.push(o);

          // minuti
          minutiInCol += o.par.getHours() * 60 + o.par.getMinutes();
        });

        // console.log('ore.length:', ore.length, 'Colonna:', col, 'Orari consecutivi:', consecutivi.length, 'Minuti Medi Cella:', minutiInCol / ore.length, 'minuti Tot', minutiInCol);
        if (minutiInCol / ore.length > 360 && consecutivi.length > ore.length * 0.8) analisiOre.oreCols.push(col);
        else analisiOre.durateCols.push(col);

      });

      analisiOre.celle.map((c) => {
        if (analisiOre.durateCols.includes(c.c)) c.tip == 'durata';
      });

      analisiOre.ore = analisiOre.celle.filter((c) => !analisiOre.durateCols.includes(c.c));
      return analisiOre;
    }

    function getAnalisiGiorni(mySheet) {

      const analisiGiorni = {
        n: false,
        tipo: false,
        celle: mySheet.rows.flat().filter((c) => c.tip == 'giorno'),
        singoloGiorno: false,
        uguali: [],
        consecutivi: [],
        equidistanti: [],
        giorni: [],
      };

      if (analisiGiorni.celle.length == 1) {
        analisiGiorni.n = analisiGiorni.celle[0].c;
        analisiGiorni.tipo = 'col';
        analisiGiorni.singoloGiorno = true;
        analisiGiorni.giorni = analisiGiorni.celle;

        return analisiGiorni;

      } else if (!analisiGiorni.celle.length) {
        analisiGiorni.n = 0;
        analisiGiorni.tipo = 'col';
        analisiGiorni.singoloGiorno = true;
        analisiGiorni.giorniNonTrovati = true;
        analisiGiorni.giorni = [{
          tip: 'giorno',
          par: new Date(),
          c: 0,
          r: 0,
        }];

        return analisiGiorni;
      }

      const giorniRows = [...new Set(analisiGiorni.celle.map((cell) => cell.r))].map((r) => analisiGiorni.celle.filter((cell) => cell.r == r));
      const giorniCols = [...new Set(analisiGiorni.celle.map((cell) => cell.c))].map((c) => analisiGiorni.celle.filter((cell) => cell.c == c));
      const analisi = [];

      // Controllo Le righe
      giorniRows.forEach((cells) => {
        const el = {
          tipo: 'row',
          n: cells[0].r,
          consecutivi: [],
          equidistanti: [],
        };
        analisi.push(el);

        cells.forEach((cell, i) => {
          const prev = cells[i - 1];
          const next = cells[i + 1];

          if (prev && next) {
            if (cell.par.getTime() >= prev.par.getTime() && cell.par.getTime() <= next.par.getTime()) el.consecutivi.push(cell);
            if (cell.c - prev.c == next.c - cell.c) el.equidistanti.push(cell);

          } else if (prev) {
            if (cell.par.getTime() >= prev.par.getTime()) el.consecutivi.push(cell);
            if (cell.c - prev.c == cells[1].c - cells[0].c) el.equidistanti.push(cell);

          } else if (next) {
            if (cell.par.getTime() <= next.par.getTime()) el.consecutivi.push(cell);
            if (next.c - cell.c == cells[1].c - cells[0].c) el.equidistanti.push(cell);

          }
        });
      });

      // Controllo le colonne
      giorniCols.forEach((cells) => {
        const el = {
          tipo: 'col',
          n: cells[0].c,
          consecutivi: [],
          equidistanti: [],
        };
        analisi.push(el);

        cells.forEach((cell, i) => {
          const prev = cells[i - 1];
          const next = cells[i + 1];

          if (prev && next) {
            if (cell.par.getTime() >= prev.par.getTime() && cell.par.getTime() <= next.par.getTime()) el.consecutivi.push(cell);
            if (cell.r - prev.r == next.r - cell.r) el.equidistanti.push(cell);

          } else if (prev) {
            if (cell.par.getTime() >= prev.par.getTime()) el.consecutivi.push(cell);
            if (cell.r - prev.r == cells[1].r - cells[0].r) el.equidistanti.push(cell);

          } else if (next) {
            if (cell.par.getTime() <= next.par.getTime()) el.consecutivi.push(cell);
            if (next.r - cell.r == cells[1].r - cells[0].r) el.equidistanti.push(cell);

          }
        });
      });

      // console.log('Analisi:', analisi, sheet);
      const max = analisi.reduce((max, curr) => curr.consecutivi.length + curr.equidistanti.length > max ? curr.consecutivi.length + curr.equidistanti.length : max, []);
      const best = analisi.find((e) => e.equidistanti.length + e.consecutivi.length == max);

      // Imposto a stringa i giorni non identificati
      analisiGiorni.celle.forEach((cell) => {
        if (best.tipo == 'col' && best.n != cell.c) {
          cell.tip = 'txt';
          cell.ori = moment(cell.par).format('DD/MM/YYYY');

        } else if (best.tipo == 'row' && best.n != cell.r) {
          cell.tip = 'txt';
          cell.ori = moment(cell.par).format('DD/MM/YYYY');

        }
      });

      analisiGiorni.n = best.n;
      analisiGiorni.tipo = best.tipo;
      analisiGiorni.consecutivi = best.consecutivi;
      analisiGiorni.equidistanti = best.equidistanti;
      analisiGiorni.giorni = analisiGiorni.celle.filter((g) => best.tipo == 'row' ? g.r == best.n : g.c == best.n);

      // Giorno e Mese invertiti?
      analisiGiorni.giorni.forEach((g, i) => {
        const date = g.par;
        const prev = i > 0 ? analisiGiorni.giorni[i - 1].par : false;

        // Cambio Giorno
        if (prev && prev.getTime() != date.getTime()) {
          const d = date.getUTCDate();
          const m = date.getUTCMonth();
          const prevd = prev.getUTCDate();
          const prevm = prev.getUTCMonth();

          if (d == prevd && m == prevm + 1) {
            // console.log('Giorno e Mese invertiti!', prevd + '/' + prevm, d + '/' + m);
            analisiGiorni.giornoMeseInvertiti = true;
          }
        }
      });

      if (analisiGiorni.giornoMeseInvertiti) {
        analisiGiorni.giorni.forEach((g, i) => {
          const d = g.par.getUTCMonth() + 1;
          const m = g.par.getUTCDate();
          const y = g.par.getUTCFullYear();

          g.par = new Date(y, m, d); // moment(`${d}/${m}/${y}`, "DD/MM/YYYY").toDate();
        });
      }

      return analisiGiorni;
    }

    function getFormat(mySheet) {
      const celle = mySheet.rows.slice().splice(0, 9).flat();
      let bestFormat = false;
      const best = [];

      // Per ogni formato
      myListings.knownFormats.forEach((format) => {
        if (!format.fieldHeads || !format.fieldHeads.length) return;
        const fields = format.fieldHeads;
        const cols = [];

        fields.forEach((field) => {
          const cella = celle.find((c) => c.txt && c.txt.toLowerCase().trim() == field[1].toLowerCase().trim());
          if (cella) cols.push(cella);
        });

        if (cols.length > 4 && cols.length > best.length) {
          bestFormat = format;
          format.cols = cols;
        }

      });

      return bestFormat;
    }

    function getProgs(mySheet) {
      const progs = [];

      mySheet.rows.forEach((row) => {
        row.forEach((cell) => {
          if (!['giorno', 'ora'].includes(cell.tip)) {
            const giorno = getGiorno(cell);
            const ora = getOra(cell);

            if (giorno && ora && cell.txt && cell.tip != 'durata') {
              let prog = progs.find((p) => p.day.par.getTime() == giorno.par.getTime() && p.hour.par.getTime() == ora.par.getTime());

              if (!prog) {
                const md = moment(giorno.par).format('DD/MM/YYYY');
                const mh = moment(ora.par).format('HH:mm');

                prog = {
                  day: giorno,
                  hour: ora,
                  mome: moment(`${md} ${mh}`, 'DD/MM/YYYY HH:mm'),
                  cells: [],
                };
                progs.push(prog);
              }

              prog.cells.push(cell);

            }
          }
        });
      });

      console.log('---------', progs);
      if (!progs.length) throw new Error('Non è stato possibile identificare i Programmi!');

      // Cambio Giorno se le ore sono dalle 6 alle 6
      let last = progs[0].mome;
      progs.forEach((prog, i) => {
        const mome = prog.mome;
        const prev = i > 0 ? progs[i - 1].mome : false;
        const ora = parseInt(mome.format('HH'));

        if (i > 0 && !mome.isSame(prev, 'day')) last = prev;

        if (last && mome.isSameOrBefore(last, 'day') && ora >= 0 && ora < 6) {
          prog.mome = mome.clone().add(1, 'day');
          // console.log('Aggiungo un giorno:', mome.format('DD/MM HH:mm'), prog.mome.format('DD/MM HH:mm'));
        }
      });

      // RETURN;
      return progs;

      // FUNZIONI
      function getGiorno(cell) {
        const analisiGiorni = mySheet.analisiGiorni;

        let dataCell = false;
        const y = cell.r;
        const x = cell.c;

        if (analisiGiorni.tipo == 'row') {
          // Vado a cercare il giorno sulla verticale
          dataCell = analisiGiorni.giorni.find((g) => g.c == x);
        }

        if (analisiGiorni.tipo == 'col') {
          // Vado a cercare il giorno all'indietro a partire dal programma
          dataCell = [...analisiGiorni.giorni].reverse().find((g) => g.c <= x && g.r <= y);
        }

        return dataCell;
      }

      function getOra(cell) {

        const colsCount = mySheet.nCols;
        const analisiOre = mySheet.analisiOre;
        const oreCols = analisiOre.oreCols;

        let oraCell = false;
        const y = cell.r;
        const x = cell.c;

        // Copio e inverto l'array per trovare la prima colonna ora a sx
        let oraCol = [...oreCols].reverse().find((c) => c <= x);

        // Se sto facendo la penultima colonna
        if (x == colsCount - 1) {
          // Se la colonna ora precedente non è un'ora
          if (!oreCols.includes(x - 1)) {
            // Se l'ultima colonna è unora
            if (oreCols[oreCols.length - 1] == colsCount) {
              oraCol = colsCount;
            }
          }
        }

        // Prendo l'ora che ha una riga minore della cella ed è nella colonna definita sopra
        oraCell = [...analisiOre.ore].reverse().find((ocell) => ocell.c == oraCol && ocell.r <= y);
        // console.log(cell._address, cell.val+'\n', oraCell ? 'Ora in: ' + oraCell._address : 'Ora non trovata.');
        return oraCell;
      }
    }

    function parseProgs(mySheet) {
      mySheet.progs.forEach((prog) => {
        prog.parsed = {};

        if (mySheet.format) {
          parseFormat(prog, mySheet.format);

        } else {
          parseGeneric(prog);
        }

      });

      // FUNZIONI
      function parseFormat(prog, format) {
        format.cols.forEach((col) => {
          const field = format.fieldHeads.find((t) => t[1] && col.txt && t[1].toLowerCase().trim() == col.txt.toLowerCase().trim());
          const cell = prog.cells.find((cell) => cell.c == col.c);

          if (field && field[0] && cell) {
            prog.parsed[field[0]] = cell.txt;
            cell.parsed = true;
          }
        });

        // Metto il resto dentro altro
        prog.parsed.Altro = Object.keys(prog.parsed).map((k) => {
          if (['Titolo', 'St', 'Ep'].includes(k)) return null;
          else return prog.parsed[k];
        }).filter((e) => e).join(', ');
      }

      function parseGeneric(prog) {
        prog.parsed.Titolo = prog.cells[0].txt;
        prog.parsed.Altro = prog.cells.filter((c, i) => i != 0).map((c) => c.txt).join(', ');
      }
    }

    function getListing(mySheet) {
      const listing = [];

      mySheet.progs.forEach((prog, i) => {

        prog.parsed.date = prog.mome.format('DD/MM/YYYY');
        prog.parsed.hour = prog.mome.format('HH:mm');

        const newp = myListings.newPassaggio();
        newp.myId = 'File_' + i;
        newp.original = prog.parsed;
        newp.Channel_ID = mySheet.channel.ID;
        newp.Channel_Name = mySheet.channel.Name;
        newp.Program_Title = prog.parsed.Titolo ? prog.parsed.Titolo : null;
        newp.Program_SeriesNumber = prog.parsed.St ? prog.parsed.St : null;
        newp.episode_Title = prog.parsed.Altro ? prog.parsed.Altro : null;
        newp.episode_episodioNumerico = prog.parsed.Ep ? prog.parsed.Ep : null;

        listing.push(newp);

      });

      myListings.generateMoment(listing);


      return listing;
    }

    async function confirmData(myWorkbook) {
      $('.ui.popup').popup('hide all');
      await myFunc.elLoader(false);
      console.log('hidden');
      return new Promise((resolve, reject) => {

        $('#confirmData').remove();

        const $cmodal = $(`
          <div class="ui small modal confirmData" id="confirmData">
            <div class="header"><i class="check circle icon"></i>Conferma / Modifica Dati: <span class="a_blue">${myWorkbook.name}</span></div>
            <div class="content">
              ${myWorkbook.sheets.map((s) => {
                const days = _.orderBy(s.analisiGiorni.giorni, (o) => o.par.getTime(), ['asc']);
                const firstDay = moment(days[0].par);
                const lastDay = moment(days[days.length - 1].par);
                const daysTot = parseInt(lastDay.diff(firstDay, 'days')) + 1;

                return `
                <table class="ui small compact celled table">
                  <thead>
                    <tr><th colspan="2">${s.name} <span class="a_grey">(${s.nCols} Colonne, ${s.nRows} Righe)</span>.</th></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="collapsing">Canale</td>
                      <td>
                        ${!s.channel.ID ? '<b><i class="ui red warning sign icon"></i>Canale non Trovato!</b> Scegli un canale:' : ''}
                        <div class="ui fluid search selection dropdown channels">
                          <input type="hidden" name="canale" value="${s.channel.ID}">
                          <i class="dropdown icon"></i>
                          <div class="default text">Seleziona Canale</div>
                          <div class="menu">
                            ${myVars.channels.tutti.map((c) => `
                              <div class="item" data-value="${c.ID}">${c.Name}</div>
                            `).join('')}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td class="collapsing">Posizione Giorni / Ore</td>
                      <td>
                        Giorni ${s.analisiGiorni.tipo == 'col' ? `in Colonna <b class="a_blue">${XLSX.utils.encode_col(s.analisiGiorni.n)}</b>` : `in Riga <b class="a_blue">${s.analisiGiorni.n + 1}</b>`},
                        Ore in Colonn${s.analisiOre.oreCols.length > 1 ? 'e' : 'a'} <b class="a_blue">${s.analisiOre.oreCols.map((o) => XLSX.utils.encode_col(o)).join(', ')}</b>
                      </td>
                    </tr>
                    <tr>
                      <td class="collapsing">Giorni Totali</td>
                      <td>
                        ${s.analisiGiorni.giorniNonTrovati? '<b><i class="ui red warning sign icon"></i>Nessun Giorno Trovato nel File!</b><br>Provo con:' : ''}
                        <b class="a_blue">${daysTot} g.</b> dal: <b class="a_blue">${firstDay.format('DD/MM/YYYY')}</b> al: <b class="a_blue">${lastDay.format('DD/MM/YYYY')}</b>
                      </td>
                    </tr>
                    <tr>
                      <td class="collapsing">Formato</td>
                      <td><b class="a_blue">${s.format ? s.format.name : 'Generico'}</b></td>
                    </tr>
                  </tbody>
                </table>

              `;
}).join('')}
            </div>
            <div class="actions">
              <div class="ui cancel red small button"><i class="remove icon"></i>Annulla</div>
              <div class="ui prosegui green small button"><i class="check icon"></i>Conferma</div>
            </div>
          </div>
          `).appendTo('body');

        $cmodal.find('.ui.dropdown').dropdown({fullTextSearch: true});

        $cmodal.find('.prosegui').on('click', function() {
          const errori = [];
          const $chDrops = $cmodal.find('.dropdown.channels');

          $chDrops.each((i, drop) => {
            const $drop = $(drop);
            const val = $drop.find('[name]').val();
            const canale = myVars.channels.tutti.find((c) => c.ID == val);

            if (!canale) {
              errori.push('Canale Mancante');
              $drop.addClass('error');

            } else {
              myWorkbook.sheets[i].channel = canale;

            }
          });

          // Con errori non proseguo
          if (errori.length) return;

          // Prosegue
          setTimeout(function() {
            resolve('ok');
            $cmodal.modal('hide');
          }, 500);
        });

        // Show Modal
        $cmodal.modal({
          autofocus: false,
          allowMultiple: true,
          transition: 'zoom',
          closable: false,
          duration: 250,
          onDeny: function() {
            setTimeout(function() {
              resolve('ko');
            }, 500);
          },
        }).modal('show');
      });
    }
   },

  getFromDatatv: async function(dStart, dEnd, channel) {
    if (!dStart || !channel) return [];
    const dbChannel = myVars.channels.tutti.find((c) => c.Name == channel);
    dEnd = moment(dEnd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');

    let listing = await client.service('listing').find({
      query: {
        Channel_ID: dbChannel.ID,
        ProgramChannel_StartDate: {
          $gte: dStart,
          $lte: dEnd,
        },
      },
    });
    // console.log('----------', listing)

   listing.forEach((r, i) => {
      delete r.ProgramChannel_dateTime;

      r.myId = 'DataTv_' + i;
      r.original = JSON.parse(JSON.stringify(r));
      r.Channel_ID = dbChannel.ID;
      r.Channel_Name = dbChannel.Name;
      r.attributes = myFunc.getAttributesFromMask(r.original.ProgramChannel_AttributeMask);
      r.workNotes = {};
      r.original.date = moment(r.ProgramChannel_StartDate, 'YYYY/MM/DD HH:mm:ss').format('DD/MM/YYYY');
      r.original.hour = ('0' + parseInt(r.ProgramChannel_StartTime / 60)).slice(-2) + ':' + ('0' + r.ProgramChannel_StartTime % 60).slice(-2);
    });

    myListings.generateMoment(listing);

    // Rimuovo primo e ultimo giorno
    const toDel = [moment(dStart, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD'), dEnd];
    listing = listing.filter((p) => !toDel.includes(p.mome6.format('YYYY-MM-DD')));

    console.log('Listing Datatv:', dStart, dEnd, listing);
    return listing;
  },

  getFromRaiplay: async function(dStart, dEnd, channel) {
    const dbChannel = myVars.channels.tutti.find((c) => c.Name == channel);
    let raiChannel = myListings.RaiChNames.find((c) => c[0] == channel);
    if (!raiChannel) return [];
    else raiChannel = raiChannel[1];

    const Start = moment(dStart, 'YYYY-MM-DD');
    const End = moment(dEnd, 'YYYY-MM-DD');
    const giorni = End.diff(Start, 'days') + 1;
    const promises = [];

    for (let g = 0; g <= giorni; g++) {
      const giorno = Start.clone().add(g, 'days');
      const url = `https://www.raiplay.it/palinsesto/app/${raiChannel}/${giorno.format('DD-MM-YYYY')}.json`;
      promises.push($.get(url).catch((e) => []));
    }

    const result = await Promise.all(promises).catch((e) => console.log(e));
    const allResult = [].concat.apply([], result.map((r) => r.events ? r.events : []));
    let listing = [];

    allResult.forEach((r, i) => {
      const newp = myListings.newPassaggio();
      newp.myId = 'RaiPlay_' + i;
      newp.original = r;
      newp.Channel_ID = dbChannel.ID;
      newp.Channel_Name = dbChannel.Name;
       newp.Program_Title = r.episode_title ? r.program.name : r.program.name ? r.program.name : r.name;
      newp.Program_SeriesNumber = r.season.split('/')[0];
      newp.episode_Title = r.episode_title;
      newp.episode_episodioNumerico = r.episode;
      newp.Program_Description = r.description;

      listing.push(newp);
    });

    myListings.generateMoment(listing);

    // Rimuovo primo e ultimo giorno
    const toDel = [moment(dStart, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD'), moment(dEnd, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')];
    listing = listing.filter((p) => !toDel.includes(p.mome6.format('YYYY-MM-DD')));

    console.log('Listing RaiPlay:', listing);
    return myListings.parseListing(listing);
  },

   getFromRaiweb: async function (dStart, dEnd, channel) {
      // 2023/01/30 DISABILITATI perchè inaffidabili
      return [];
    const dbChannel = myVars.channels.tutti.find((c) => c.Name == channel);
    let raiChannel = myListings.RaiChNames.find((c) => c[0] == channel);
    if (!raiChannel) return [];
    else raiChannel = raiChannel[2];

    const Start = moment(dStart, 'YYYY-MM-DD');
    const End = moment(dEnd, 'YYYY-MM-DD');
    const giorni = End.diff(Start, 'days');
    const promises = [];

    for (let g = 0; g <= giorni; g++) {
      const giorno = Start.clone().add(g, 'days');
      const url = `https://www.rai.it/dl/portale/html/palinsesti/guidatv/static/iphone/${raiChannel}_${giorno.format('YYYY_MM_DD')}`;
      promises.push($.get(url).then((r) => {
        return {
          giorno: giorno.clone().format('DD/MM/YYYY'),
          xml: r,
        };
      }).catch((e) => []));

    }

    const result = await Promise.all(promises).catch((e) => console.log(e));

    const listing = [];
    result.forEach((r) => {
      if (!r.giorno) return;
      const giorno = r.giorno;
      const res = r.xml.replace('<?xml version="1.0" encoding="UTF-8"?>', '').replace(/http:\/\//gi, 'https://');
      const $res = $(res);
      const $progs = $res.find('.intG');

      $progs.each((i, p) => {
        const $p = $(p);

        // Salto l'ultimo programma (è nel giorno successivo)
        if (i == $progs.length -1) return;

        const original = {
          id: $p.find('[id_evento]').attr('id_evento'),
          date: giorno,
          hour: $p.find('.ora').text(),
          title: $p.find('[id_evento]').text(),
          description: $p.find('.eventDescription').text(),
          image: $p.find('.programImage img').attr('src'),
        };

        const newp = myListings.newPassaggio();
        newp.myId = 'RaiWeb_' + i,
        newp.original = original;
        newp.Channel_ID = dbChannel.ID;
        newp.Channel_Name = dbChannel.Name;
        newp.Program_Title = original.title ? original.title : '';
        newp.Program_Description = original.description ? original.description : '';
        listing.push(newp);
      });

    });

    if (!listing.length) return [];
    myListings.generateMoment(listing, 'base6');

    console.log('Listing RaiWeb:', listing);
    return myListings.parseListing(listing);

  },

  getCompared: function(listings) {

    listings = listings.map((list, i) => {
      const clear = list
        .filter((r) => r.original != 'placeholder')
        .map((prog) => {
          delete prog.workNotes.match; return prog;
        });

      return clear;
    });

    console.log(listings);

    // const original = JSON.parse(JSON.stringify(listings))

    if (listings.length < 2) return listings;
    // console.log('getListingsCompared', original);

    listings.forEach((list, i) => {
      const other = listings.filter((l, y) => y != i);
      other.forEach((o) => {
        list = compareWith(list, o);
      });

    });

    function compareWith(listA, listB) {
      const fuseOpt = {
        shouldSort: true,
        includeScore: true,
        includeMatches: true,
        tokenize: true,
        matchAllTokens: true,
        findAllMatches: true,
        // tokenSeparator: /[\ \.\:\;\!\&\?\-\#\@\(\)\$\&]/gi,
        threshold: 1,
        location: 0,
        distance: 64,
        maxPatternLength: 64,
        minMatchCharLength: 4,
      };

      listB.forEach((prog, i) => {
        const name = !listA[0] ? 'DataTv' : listA[0].myId.split('_')[0];
        const matchOra = listA.find((pa) => pa.mome.format('x') == prog.mome.format('x'));

        if (!matchOra) {
          const newp = myListings.newPassaggio();
          newp.myId = name + '_' + listA.length + '_' + i;
          newp.original = 'placeholder';
          newp.Channel_ID = prog.Channel_ID;
          newp.Channel_Name = prog.Channel_Name;
          newp.mome = prog.mome.clone();
          newp.mome6 = prog.mome6.clone();
          newp.ProgramChannel_StartDate = prog.ProgramChannel_StartDate;
          newp.ProgramChannel_EndDate = prog.ProgramChannel_EndDate;
          newp.ProgramChannel_StartTime = prog.ProgramChannel_StartTime;
          newp.Program_Title = '';

          listA.push(newp);

        } else if (name == 'DataTv') {
          const tit = prog.Program_Title.replace(/[^a-z0-9]|\s/gi, '').toLowerCase();
          const titDb = matchOra.Program_Title.replace(/[^a-z0-9]|\s/gi, '').toLowerCase();
          if (!tit || !titDb) return;

          if (tit == titDb) {
            matchOra.workNotes.match = 100;

          } else {
            const contained = tit.indexOf(titDb) > -1 || titDb.indexOf(tit) > -1;
            const fuseTitle = new Fuse([titDb], fuseOpt);
            const fuseMatch = fuseTitle.search(tit);
            // console.log(fuseMatch, tit, titDb);
            if (fuseMatch && fuseMatch[0]) {
              matchOra.workNotes.match = parseInt(100 - fuseMatch[0].score * 100) + (contained ? 10 : 0);
            }
          }

          // console.log(matchOra.Program_Title)
        }

      });
      return listA;
    }

    // console.log('getListingsCompared END', listings);
    return listings;
  },

  // Funzioni di Supporto
  parseListing: function(listing) {

    listing.forEach((prog) => {
      if (!prog.Program_Title) return;
      let title = prog.Program_Title;
      let epTitle = prog.episode_Title;

      // Titolo
      Object.keys(myListings.regexes).forEach((key) => {
        const rex = myListings.regexes[key];
        const match = rex.exec(title);
        rex.lastIndex = 0;

        if (match && match.length) {
          // const torep = new RegExp('\\' + match[0].split('').join('\\'), 'gi');
          const torep = match[0];
          console.log(title, match);
          if (key == 'ep') {
            title = title.replace(torep, '');
            if (!prog.episode_episodioNumerico) prog.episode_episodioNumerico = match[1].replace(/^0+/, '');

          } else if (key == 'st') {
            title = title.replace(torep, '');
            if (!prog.Program_SeriesNumber) prog.Program_SeriesNumber = match[1].replace(/^0+/, '');

          } else if (key == 'step') {
            title = title.replace(torep, '');
            if (!prog.Program_SeriesNumber) prog.Program_SeriesNumber = match[1].replace(/^0+/, '');
            if (!prog.episode_episodioNumerico) prog.episode_episodioNumerico = match[2].replace(/^0+/, '');

          }
        }
      });

      title = title
        .replace(/\b(telefilm|film)\b/gi, '')
        .replace(/ \:/gi, ':')
        .replace(/\|\|/gi, '')
        .replace(/\(\)/, '')
        .replace(/\- ?\-/gi, '')
        .replace(/^ ?,/gi, '')
        .replace(/^ ?\-/gi, '')
        .replace(/\- ?$/gi, '')
        .replace(/  /gi, ' ')
        .trim();

      // ROMAN NUMBERS
      if (prog.episode_episodioNumerico) {
        const matchRoman = /\bM{0,4}(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/.exec(title);
        if (matchRoman && matchRoman[0]) {
          title = title.replace(matchRoman[0], '');
          if (!prog.Program_SeriesNumber) prog.Program_SeriesNumber = romanToArabic(matchRoman[0]);
        }
      }


      prog.Program_Title = title.trim();

      // Titolo Episodio
      if (epTitle) {
        Object.keys(myListings.regexes).forEach((key) => {
          const rex = myListings.regexes[key];
          const match = rex.exec(epTitle);
          rex.lastIndex = 0;

          if (match && match.length) {
            // const torep = new RegExp('\\' + match[0].split('').join('\\'), 'gi');
            const torep = match[0];

            if (key == 'ep') {
              epTitle = epTitle.replace(torep, '');
              if (!prog.episode_episodioNumerico) prog.episode_episodioNumerico = match[1].replace(/^0+/, '');

            } else if (key == 'st') {
              epTitle = epTitle.replace(torep, '');
              if (!prog.Program_SeriesNumber) prog.Program_SeriesNumber = match[1].replace(/^0+/, '');

            } else if (key == 'step') {
              epTitle = epTitle.replace(torep, '');
              if (!prog.Program_SeriesNumber) prog.Program_SeriesNumber = match[1].replace(/^0+/, '');
              if (!prog.episode_episodioNumerico) prog.episode_episodioNumerico = match[2].replace(/^0+/, '');

            }
          }
        });

        prog.episode_Title = epTitle
          .replace(/\b(telefilm|film)\b/gi, '')
          .replace(/ \:/gi, ':')
          .replace(/\|\|/gi, '')
          .replace(/\(\)/, '')
          .replace(/\- ?\-/gi, '')
          .replace(/^ ?,/gi, '')
          .replace(/^ ?\-/gi, '')
          .replace(/\- ?$/gi, '')
          .replace(/  /gi, ' ')
          .trim();
      }


    });

    return listing;

    function romanToArabic(romanNumber) {
      romanNumber = romanNumber.toUpperCase();
      const romanNumList = ['CM', 'M', 'CD', 'D', 'XC', 'C', 'XL', 'L', 'IX', 'X', 'IV', 'V', 'I'];
      const corresp = [900, 1000, 400, 500, 90, 100, 40, 50, 9, 10, 4, 5, 1];
      let index = 0; let num = 0;
      for (const rn in romanNumList) {
        index = romanNumber.indexOf(romanNumList[rn]);
        while (index != -1) {
          num += parseInt(corresp[rn]);
          romanNumber = romanNumber.replace(romanNumList[rn], '-');
          index = romanNumber.indexOf(romanNumList[rn]);
        }
      }
      return num;
    }
  },

  getChannel: function(names) {
    if (!Array.isArray(names)) names = [names];
    let matches = [];
    let match = false;

    names.forEach((n) => {
      const cleanName = n
        .replace(/\.[A-Za-z]{3,4}$/gi, '')
        .replace(/\-|_/gi, ' ')
        .replace(/\bHD\b/gi, '')
        .replace(/channel/gi, '');

      // console.log('getChannel\tSearch:', cleanName, '('+n+')');
      matches = matches.concat(
        myVars.channels.tutti.filter((c) => c.Name != '_prova').filter((c) => {
          const cleanc = c.Name
            .replace(/\-|_/gi, ' ')
            .replace(/\bHD\b/gi, ' ')
            .replace(/channel/gi, ' ')
            .replace(/ /gi, ' ?')
            .replace(/\+/gi, '\\+');

          const rex = new RegExp('\\b' + cleanc + '\\b', 'gi');
          const match = rex.test(cleanName);
          // console.log('getChannel\t\t', match, cleanc);
          return match;
        }),
      );
    });

    if (matches.length >= 1) {
      match = matches[0];
    }

    // console.log('getChannel\t\t\t\tmatch:', match);
    return match;
  },

   download: function (listing, type) {
      console.log('Download', type, listing);
      // Listing
      listing = listing.filter((p) => p.original != 'placeholder');

      if (!type) type = 'preparazione';
      const dStart = listing[0].mome6.format('ddd DD-MM');
      const dEnd = listing[listing.length - 1].mome6.format('ddd DD-MM');
      const channel = listing[0].Channel_Name;

      let palArray = [[
         'Giorno',
         'Ora',
         'Titolo Programma',
         'Titolo Episodio',
         'Stagione',
         'Episodio',
         'Sinossi',
         'Varie TXT',
         channel
      ]];

      listing.forEach(d => {
         palArray.push([
            d.mome.format('DD/MM/YYYY'),
            ('0' + parseInt(d.ProgramChannel_StartTime / 60)).slice(-2) + ':' + ('0' + d.ProgramChannel_StartTime % 60).slice(-2),
            d.Program_Title,
            d.episode_Title ? d.episode_Title : '',
            d.Program_SeriesNumber ? d.Program_SeriesNumber : '',
            d.episode_episodioNumerico ? d.episode_episodioNumerico : '',
            d.Program_Description ? d.Program_Description : '',
            '',
            '',
         ]);
      });

      const wBook = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet(palArray);
      XLSX.utils.book_append_sheet(wBook, ws, `Export ${channel}`);
      myFunc.downloadXLSX(wBook, `Export ${channel} dal ${listing[0].mome.format('DD/MM/YYYY')}`);



      // // Originale
      // const ws = XLSX.utils.json_to_sheet(listing.map(l => l.original));
      // XLSX.utils.book_append_sheet(wBook, ws, '>>>' + listing[0].Channel_Name + ' Originale');
      // XLSX.utils.sheet_set_range_style(ws, `A1:BA1`, style);

      // // Preparazione
      // const wsp = XLSX.utils.json_to_sheet(listing.flatMap((d, i) => {
      //    const prev = i > 0 ? listing[i - 1].mome6 : false;
      //    let arr = [];

      //    if (i == 0 || !d.mome6.isSame(prev, 'day')) {
      //       arr.push({
      //          data: d.mome6.format('DD/MM/YYYY'),
      //          titolo: '',
      //          ep: '',
      //          trama: '',
      //       })
      //    }

      //    arr.push({
      //       data: ('0' + parseInt(d.ProgramChannel_StartTime / 60)).slice(-2) + ':' + ('0' + d.ProgramChannel_StartTime % 60).slice(-2),
      //       titolo: `${d.Program_Title} ${d.Program_SeriesNumber && d.Program_SeriesNumber < 1000 ? `Stag. ${d.Program_SeriesNumber}` : ''}`,
      //       ep: [(d.episode_episodioNumerico ? 'Ep. ' + d.episode_episodioNumerico : ''), (d.episode_Title ? d.episode_Title : '')].filter((e) => e).join(', '),
      //       trama: d.Program_Description ? d.Program_Description : '',
      //    })

      //    return arr;
      // }), { skipHeader: 1 });

      // XLSX.utils.book_append_sheet(wBook, wsp, 'Preparazione');

      // Generico






      // Download
      // let filename = `${listing[0].Channel_Name}_${dStart} ${dEnd}.xlsx`;
      // // XLSX.utils.book_append_sheet(wBook, ws, '>>> Data'); NON CREDO SERVA
      // XLSX.writeFile(wBook, filename, { bookType: 'xlsx', cellStyles: true });
   },

  generateMoment: function(listing, base6) {

    // Aggiungo i moment
    listing.forEach((prog, i) => {
      prog.mome = base6 ? addDayTo06(roundMinutes(prog)) : roundMinutes(prog);
      prog.mome6 = subtractDayTo06(prog.mome);
      prog.ProgramChannel_StartTime = prog.mome.hour() * 60 + prog.mome.minutes();
      prog.ProgramChannel_StartDate = prog.mome.format('YYYY-MM-DD 00:00:00');
      prog.ProgramChannel_EndDate = prog.mome.format('YYYY-MM-DD 00:00:00');
    });

    // Controllo orari sovrapposti
    listing.forEach((prog, i) => {
      const prev = listing[i - 1];
      const next = listing[i + 1];

      const plus5 = prog.mome.clone().add(5, 'minute');
      const minu5 = prog.mome.clone().subtract(5, 'minute');

      if (prev && prev.mome.isSame(prog.mome)) {
        // console.log('Orario sovrapposto:', prog.mome.format('HH:mm'), prog.Program_Title);
        // console.log('prev:', prev.mome.format('HH:mm'), 'next:', next.mome.format('HH:mm'))

        if (next && !next.mome.isSameOrBefore(plus5)) {
          // console.log('\tDiventa +5:', plus5.format('HH:mm'));
          prog.mome = plus5;

        } else if (prev && !prev.mome.isSameOrAfter(minu5)) {
          // console.log('\tDiventa -5:', minu5.format('HH:mm'));
          prog.mome = minu5;

        } else {
          // console.log('\tDiventa -3');
          prog.mome = prog.mome.subtract(3, 'minutes');
        }

        prog.mome6 = subtractDayTo06(prog.mome);
        prog.ProgramChannel_StartTime = prog.mome.hour() * 60 + prog.mome.minutes();
        prog.ProgramChannel_StartDate = prog.mome.format('YYYY-MM-DD 00:00:00');
        prog.ProgramChannel_EndDate = prog.mome.format('YYYY-MM-DD 00:00:00');

      }
    });

    function roundMinutes(prog) {
      const m = moment(`${prog.original.date} ${prog.original.hour}`, 'DD/MM/YYYY HH:mm');
      if (prog.Program_Title == 'TG3 L.I.S.') console.log('in', m.format('HH:mm'));
      let mins = m.minute();
      const resto = mins % 5;

      if (resto && resto != 0 && resto < 3) {
        mins = Math.floor(mins / 5) * 5;

      } else if (resto) {
        mins = Math.ceil(mins / 5) * 5;

      }

      m.minute(mins).second(0);
      if (prog.Program_Title == 'TG3 L.I.S.') console.log('out', m.format('HH:mm'));
      return m;
    }

    function addDayTo06(mome) {
      let newMome = mome.clone();
      const ora = newMome.hour();

      if (ora >= 0 && ora < 6) {
        newMome = newMome.add(1, 'day');
      }

      return newMome;
    }

    function subtractDayTo06(mome) {
      let newMome = mome.clone();
      const ora = newMome.hour();

      if (ora >= 0 && ora < 6) {
        newMome = newMome.subtract(1, 'day');
      }

      return newMome;
    }
  },

  print: async function($dest, dStart, dEnd, channel, file, redraw) {
    myFunc.elLoader(true, false, 'Loading Listings');
    const listingToComare = [];

    if (redraw) {
      $dest.find('.dataTable').each((i, table) => {
        const data = $(table).DataTable().data().toArray().filter((r) => r.original != 'placeholder');
        listingToComare.push(data);
      });

    } else if (file) {
      const listingFile = await myListings.getFromFile(file).catch((err) => {
        myError.show(`Errore lettura File`, err);
        return;
      });

      if (!listingFile) {
        // $loader.remove();
        return;
      }

      dStart = listingFile[0].mome6.format('YYYY-MM-DD');
      dEnd = listingFile[listingFile.length - 1].mome6.format('YYYY-MM-DD');
      channel = listingFile[0].Channel_Name;

      const listingDb = await myListings.getFromDatatv(dStart, dEnd, channel);

      listingToComare.push(listingDb);
      if (listingFile && listingFile.length) listingToComare.push(listingFile);


    } else if (dStart && dEnd && channel) {
      const listingDb = await myListings.getFromDatatv(dStart, dEnd, channel);
      const listingRaiWeb = await myListings.getFromRaiweb(dStart, dEnd, channel);
      const listingRaiPlay = await myListings.getFromRaiplay(dStart, dEnd, channel);

      listingToComare.push(listingDb);
      if (listingRaiWeb && listingRaiWeb.length) listingToComare.push(listingRaiWeb);
      if (listingRaiPlay && listingRaiPlay.length) listingToComare.push(listingRaiPlay);

    }

    myFunc.elLoader(false);
    printComparison(myListings.getCompared(listingToComare));

    function printComparison(listings) {
      if ($dest.find('.dataTable').length) $dest.find('.dataTable').DataTable().destroy();
      $dest.empty();
      const $grid = $(`<div class="ui equal width padded grid listings" style="margin-top:0;"></div>`).appendTo($dest);

      listings.forEach((listing, i) => {
        if (!listing.length) return;
        const name = listing[0].myId.split('_')[0];

        $grid.append(`
          <div class="column" style="padding:0;">
            <div class="ui borderless menu" style="overflow: hidden; margin: 0 14px 14px 14px;${name == 'DataTv' ? 'background: #f9fafb;' : ''}">
              <a class="item scarica" data-index="${i}"><i class="download icon"></i><b>Palinsesto&nbsp;<span class="a_blue">${name}</span></b></a>
              <div class="right menu">
                <div class="item header">${listing[0].Channel_Name} dal:${listing[0].mome6.format('DD/MM')} al:${listing[listing.length -1].mome6.format('DD/MM')}</div>
              </div>
            </div>
            <table class="ui small unstackable compact celled single line table" id="listing${name}" style="width:100%; margin:0 !important;${name == 'DataTv' ? 'background: #f9fafb;' : ''}"></table>
          </div>`);

        // Datatable
        const $table = $(`#listing${name}`);
        const len = $('.dtLength').dropdown('get value');
        const toSearch = $('[name="datatableSearch"]').val();
        const columns = listingColumns(Object.keys(listing[0]), name == 'DataTv');

        const dt = myDt.createTable($table, listing, columns, 'myId', len, false, false, false, false);
        if (toSearch) $table.DataTable().search(toSearch);
        myDt.orderCols($table, true, [
          ['mome', 'asc'],
        ]);

        // Add tableFuncButton
        if (name != 'DataTv') {
          $(`<div class="ui mini basic icon button tableFuncButton" data-act="escludi"><i class="eye slash icon"></i></div>`)
            .prependTo($table.closest('.dataTables_wrapper'))
            .on('click', function(e) {
              const act = $(this).attr('data-act');

              if (act == 'includi') {
                $(this).attr('data-act', 'escludi');
                $(this).html('<i class="eye slash icon"></i>');
                dt.rows.add($(this).data('rows'));

              } else if (act == 'escludi') {
                $(this).attr('data-act', 'includi');
                $(this).html('<i class="eye icon"></i>');
                $(this).data('rows', dt.data().toArray());
                dt.clear();
              }


              myListings.dataTablesRefresh($dest);
            // console.log(dt.data())
            });
        }

        // Funzioni DataTable
        $table.on('click', 'td.giorno', function(e) {
          const $td = $(this);
          const row = dt.row($td.closest('tr'));
          console.log($(this).closest('tr').attr('id'), row.data());
        });

        $table.on('click', 'td.ora', function(e) {
          const $td = $(this);
          const row = dt.row($td.closest('tr'));
          console.log($(this).closest('tr').attr('id'), row.data().mome.format('dd DD/MM/YYYY HH:mm'));
        });

        // //popup
        // $grid.on('mouseenter', 'td.titolo', function(e){
        //   if($(this).find('.popup').length) return;
        //   const $el = $(this);
        //   myListings.showPop($el);
        // });

        // Remove Program
        $table.on('contextmenu', 'td.titolo', function(e) {
          e.preventDefault();
          // if ($(this).find('.popup').length) return;
          myListings.contextMenu($(this), $dest);
        });

        // Add / Rename Program
        $table.on('click', 'td.titolo', function(e) {
          if ($(this).find('.popup').length) return;
          if (name == 'DataTv') myListings.addProgram($(this), $dest);
          else myListings.renameProgram($(this), $dest);
        });

        if (name == 'DataTv') {
          // Reorder Table per ora non utilizzato
          // dt.on('row-reorder', function (e, diff, edit) {
          //   if (!diff.length) return;
          //   const dragged = edit.triggerRow.data();
          //   const ended = dt.row('#' + edit.values[dragged.myId]).data();
          //   //console.log('Reorder dragged on row:', dragged.myId, dragged.Program_Title);
          //   //console.log('Reorder ended on row after:', ended.myId, ended.Program_Title);
          //   myDt.orderCols($table, true, [
          //     ['mome', 'asc']
          //   ]);
          // });

          // Drag Orari sbagliati
          $table
            .on('dragstart', 'td.ora', function(e) {
              // e.preventDefault();
              // e.stopPropagation();
              const $td = $(this);
              const $tr = $td.closest('tr');
              e.originalEvent.dataTransfer.setData('text/plain', $tr.attr('id'));

            })
            .on('dragover', 'td.ora', function(e) {
              e.preventDefault();
              e.stopPropagation();
              const $td = $(this);
              if ($td.hasClass('myError')) {
                $td.css('background', 'orange');
              }
            })
            .on('dragleave', 'td.ora', function(e) {
              e.preventDefault();
              e.stopPropagation();
              const $td = $(this);
              $td.css('background', '');
            })
            .on('drop', 'td.ora', function(e) {
              e.preventDefault();
              e.stopPropagation();
              const $td = $(this);
              if ($td.hasClass('myError')) {
                const destRow = dt.row($td.closest('tr'));
                const sourceId = e.originalEvent.dataTransfer.getData('text/plain');
                const sourceRow = dt.row($('#' + sourceId));

                // console.log('destRow', $td.closest('tr').attr('id'), destRow.data().mome.format('dd DD/MM/YYYY HH:mm'));
                // console.log('sourceRow', sourceId, sourceRow.data().mome.format('dd DD/MM/YYYY HH:mm'));
                const newData = JSON.parse(JSON.stringify(sourceRow.data()));
                newData.mome = destRow.data().mome.clone();
                newData.mome6 = destRow.data().mome6.clone();
                newData.ProgramChannel_StartDate = destRow.data().ProgramChannel_StartDate;
                newData.ProgramChannel_EndDate = destRow.data().ProgramChannel_EndDate;
                newData.ProgramChannel_StartTime = destRow.data().ProgramChannel_StartTime;

                destRow.data(newData);
                sourceRow.remove();
                myListings.dataTablesRefresh($dest);
              }
            });
        }
      });

      // Over TR
      $grid.on('mouseover', 'tr', function() {
        $grid.find('tr').removeClass('highlight');
        const index = $(this).index() + 1;
        $grid.find('.dataTable').each((i, dt) => {
          $(dt).children('tbody').children('tr').eq(index - 1).addClass('highlight');
        });
      });

      // scarica
      $grid.on('click', '.scarica', function() {
        myListings.dataTablesRefresh($dest);
        const $table = $(this).closest('.column').find('.dataTable');
        const listing = $table.DataTable().data().toArray().filter((r) => r.original != 'placeholder');
        const sorted = _.orderBy(listing, (o) => o.mome.format('X'), ['asc']);

        if ($table.attr('id') == 'listingDataTv') {
          myListings.download(sorted, 'toImport');
        } else {
          myListings.download(sorted, 'preparazione');
        }
      });

    }

    function listingColumns(colNames, editable) {
      const cols = [];
      const order = [];

      const myOrder = [
        'mome',
        'ProgramChannel_StartTime',
        'Program_Title',
        'Program_SeriesNumber',
        'episode_episodioNumerico',
      ];

      colNames.forEach((c, i) => {
        if (!myOrder.includes(c)) {
          myOrder.push(c);
        }
      });

      // Opzioni Colonne
      myOrder.forEach((n) => {
        let col = {
          visible: false,
          searchable: false,
        };

        if (n == 'mome') {
          col = {
            title: 'Data',
            className: 'collapsing center aligned giorno warning',
            createdCell: function(td, cellData, rowData, row, col) {
              if (editable) $(td).css('cursor', 'pointer');
            },
            render: function(data, type, row, meta) {
              const attr = row.attributes && row.attributes.length ? row.attributes.map((a) => a.Name) : false;
              let attrHtml = '';
              if (attr) {
                if (attr.includes('Prima Visione Assoluta')) {
                  attrHtml = `<span class="ui mini red circular label" style="position: absolute; left: -16px;" title="${attr.join(', ')}">A</span>`;

                } else if (attr.includes('Prima Visione')) {
                  attrHtml = `<span class="ui mini red circular label" style="position: absolute; left: -16px;" title="${attr.join(', ')}">P</span>`;

                } else if (attr.includes('Diretta')) {
                  attrHtml = `<span class="ui mini green circular label" style="position: absolute; left: -16px;" title="${attr.join(', ')}">D</span>`;

                } else if (attr.length) {
                  attrHtml = `<span class="ui mini grey circular label" style="position: absolute; left: -16px;" title="${attr.join(', ')}">#</span>`;

                }
              }

              const html = `
                <div style="display:none;">${parseInt(data.format('x'))}</div>
                ${attrHtml ? attrHtml : ''}
                ${editable ? '' : ''}
                ${row.mome6 ? row.mome6.format('DD/MM') : ''}`;
              return html;
            },
          };
        }

        if (n == 'ProgramChannel_StartTime') {
          col = {
            title: 'Ora',
            className: 'ora collapsing center aligned warning',
            createdCell: function(td, cellData, rowData, row, col) {
              if (editable) {
                $(td).attr('draggable', true);
                if (rowData.original == 'placeholder') {
                  $(td).addClass('myError');
                  $(td).attr('title', 'Trascina qui un orario esistente.');
                }
              }

            },
            render: function(data, type, row, meta) {
              let html = /* row.original.hour ? row.original.hour + '<br>' : */'';
              html += ('0' + parseInt(row.ProgramChannel_StartTime / 60)).slice(-2) + ':' + ('0' + row.ProgramChannel_StartTime % 60).slice(-2);
              return html;
            },
          };
        }

        if (n == 'Program_Title') {
          col = {
            title: 'Titolo',
            searchable: true,
            className: 'titolo',
            render: function(data, type, row, meta) {
              let color = 'a_red';

              const match = row.workNotes.match;
              if (match) {
                if (match >= 95) {
                  color = 'a_green';

                } else if (match >= 75) {
                  color = 'a_olive';

                } else if (match >= 45) {
                  color = 'a_orange';

                } else {
                  color = 'a_red';
                }
              }

              const html = `
                  <div style="overflow: hidden; position: absolute; top: 6px; left: 8px; right: 0; bottom: 0; text-overflow: ellipsis;">
                    <b ${match ? ` class="${color}"` : ``}${match ? ` title="Match:${match}%"` : ``}>${data}</b><span class="a_grey">${row.episode_Title ? ' / ' + row.episode_Title : ''}</span>
                  </div>`;
              return html;
            },
          };
        }

        if (n == 'Program_SeriesNumber') {
          col = {
            title: 'St',
            className: 'collapsing',
            width: '30px',
            render: function(data, type, row, meta) {
              const html = data;
              return html;
            },
          };
        }

        if (n == 'episode_episodioNumerico') {
          col = {
            title: 'Ep',
            className: 'collapsing',
            width: '30px',
            render: function(data, type, row, meta) {
              const html = data;
              return html;
            },
          };
        }

        // if (n == 'attributes') {
        //   col = {
        //     title: 'A',
        //     className: 'collapsing',
        //     render: function (data, type, row, meta) {
        //       let html = '';
        //       console.log(data)

        //       // somma 2 per ogni attr elevato bitposition

        //       if(!data) return '';
        //       const attr = data.map(a => a.Name);
        //       if(attr.includes('Prima Visione Assoluta')){
        //         html = `<span class="ui mini red label" style="width: 6px; text-align: center; " title="${attr.join(', ')}">A</span>`;

        //       } else if(attr.includes('Prima Visione')){
        //         html = `<span class="ui mini red label" style="width: 6px; text-align: center; " title="${attr.join(', ')}">1</span>`;

        //       } else if(attr.includes('Diretta')){
        //         html = `<span class="ui mini green label" style="width: 6px; text-align: center; " title="${attr.join(', ')}">D</span>`;

        //       } else if(attr.length){
        //         html = `<span class="ui mini label" style="width: 6px; text-align: center; " title="${attr.join(', ')}">#</span>`;

        //       }

        //       return html;
        //     }
        //   }
        // }

        col.data = n;
        col.name = n;
        cols.push(col);

      });

      return {
        // order: order,
        cols: cols,
      };

    }

  },

  dataTablesRefresh: function($dest) {
    const listingToCompare = [];
    $dest.find('.dataTable').each((i, table) => {
      const data = $(table).DataTable().data().toArray();
      listingToCompare.push(data);
    });

    const newListings = myListings.getCompared(listingToCompare);

    $dest.find('.dataTable').each((i, table) => {
      $(table).DataTable().clear();
      $(table).DataTable().rows.add(newListings[i]);
      myDt.orderCols($(table), true, [
        ['mome', 'asc'],
      ]);
    });
  },

  addProgram: async function($el, $dest) {
    const $table = $el.closest('.dataTable');
    const $tr = $el.closest('tr');
    const row = $table.length && $tr.length ? $table.DataTable().row($tr) : false;

    $('.myPops').remove();
    $('.myPopsActivator').remove();
    const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
    const $pop = $(`
      <div class="ui flowing popup myPops edit">
        <div class="ui vertical compact small menu" style="border: 0; box-shadow: none;">
        <div class="ui form">
          <div class="field">
            <label class="label">Associa Programma</label>
            <div class="ui search programSearch" id="associaProg" data-program_search="programEpisode" data-dispatch="true">
              <div class="ui left icon input" style="min-width: 32em;">
                <input type="text" name="programSearch" placeholder="Cerca..." class="prompt">
                <i class="database icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    `).appendTo($el);

    // Attiva Search
    $pop.find('#associaProg').search(myProgramSearch.getOpt('programEpisode', true, true));

    // Titolo della tabella accanto
    let tosearch = false;
    $dest.find('table').not($table).each((i, table) => {
      if (tosearch) return;
      const $thisTr = $(table).find('tr').eq($tr.index() + 1);
      const thisData = $(table).DataTable().row($thisTr).data();
      if (thisData && thisData.Program_Title && thisData.Program_Title != ' ') tosearch = thisData.Program_Title;
    });

    if (tosearch) {
      $pop.find('input').val(tosearch);
      $pop.find('#associaProg').search('query');
    }

    // Associa
    $pop.find('#associaProg').on('choosed', async function(e) {
      const r = e.detail;
      const ide = r.idEpisode && r.idEpisode != -1 ? r.idEpisode : false;
      console.log(r);

      const newData = Object.assign({}, row.data(), {
        original: 'choosed',
        Serie_ID: r.idBase ? r.idBase : null,
        Program_ID: r.idProgram ? r.idProgram : null,
        episode_ID: r.idEpisode ? r.idEpisode : null,
        Program_Title: r.Title ? r.Title : null,
        Program_OriginalTitle: r.OriginalTitle ? r.OriginalTitle : null,
        Program_SeriesNumber: r.SeriesNumber ? r.SeriesNumber : null,
        Program_Year: r.Year ? r.Year : null,
        Program_completeFlag: null,
        episode_Title: r.epTitle ? r.epTitle : null,
        episode_OriginalTitle: r.epOriginalTitle ? r.epOriginalTitle : null,
        episode_EpisodeNumber: null,
        episode_episodioNumerico: r.episodioNumerico ? r.episodioNumerico : null,
        episode_Location: null,
        episode_Turn: r.Turn ? r.Turn : null,
        episode_completeFlag: null,
        Program_Nota: r.Nota ? r.Nota : null,
        ProgramChannel_AttributeMask: 0,
        partental: null,
        attributes: null,
      });

      console.log(r, newData);
      row.data(newData);
      myListings.dataTablesRefresh($dest);

    });

    // popup
    $myPopsActivator.popup({
      popup: $pop,
      boundary: $('body'),
      exclusive: true,
      position: 'bottom center',
      lastResort: 'top center',
      distanceAway: -20,
      on: 'manual',
      onHidden: function() {
        $myPopsActivator.remove();
        $pop.remove();
      },
      onHide: function () {
        const $prompt = $(this).find('.prompt');
        if ($prompt.is(':focus')) return false;
      }
    }).popup('show');

    $pop.find('input').focus();
  },

  renameProgram: async function($el, $dest) {
    const $table = $el.closest('.dataTable');
    const $tr = $el.closest('tr');
    const row = $table.length && $tr.length ? $table.DataTable().row($tr) : false;

    $('.myPops').remove();
    $('.myPopsActivator').remove();
    const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
    const $pop = $(`
      <div class="ui flowing popup myPops edit" style="width:400px;">
        <div class="ui small form">
          <div class="field">
            <label class="label">Rinomina Programma</label>
            <input type="text" name="Program_Title" placeholder="Titolo" value="${row.data().Program_Title}">
          </div>
          <div class="field">
            <label class="label">Nuovo Nome</label>
            <input type="text" name="Program_Title_new" placeholder="Nuovo Titolo" value="${row.data().Program_Title}">
          </div>
          <div class="field">
            <div class="ui small fluid buttons">
              <button class="ui button all">Rinomina Tutti</button>
              <button class="ui green button">Rinomina Passaggio</button>
            </div>
          </div>
        </div>
      </div>
    `).appendTo($el);

    $pop.find('.button').on('click', function(e) {
      const tutti = $(this).hasClass('all');
      const oldName = $pop.find('[name="Program_Title"]').val();
      const newName = $pop.find('[name="Program_Title_new"]').val();

      if (oldName == newName) return;

      if (!tutti) {
        row.data().Program_Title = newName;
      } else {
        $dest.find('.dataTable:not(#listingDataTv)').each((i, table) => {
          const $ttable = $(table);
          const tdt = $ttable.DataTable();

          tdt.rows().every( function( rowIdx, tableLoop, rowLoop ) {
            const data = this.data();

            if (data.Program_Title == oldName) {
              console.log($ttable, data, oldName);
              this.data().Program_Title = newName;
            }
          });
        });
      }

      myListings.dataTablesRefresh($dest);
    });


    // Associa
    $pop.find('#associaProg').on('choosed', async function(e) {
      const r = e.detail;
      const ide = r.idEpisode && r.idEpisode != -1 ? r.idEpisode : false;
      const newData = Object.assign({}, row.data(), {
        original: 'choosed',
        Serie_ID: r.idBase ? r.idBase : null,
        Program_ID: r.idProgram ? r.idProgram : null,
        episode_ID: r.idEpisode ? r.idEpisode : null,
        Program_Title: r.Title ? r.Title : null,
        Program_OriginalTitle: r.OriginalTitle ? r.OriginalTitle : null,
        Program_SeriesNumber: r.SeriesNumber ? r.SeriesNumber : null,
        Program_Year: r.Year ? r.Year : null,
        Program_completeFlag: null,
        episode_Title: r.epTitle ? r.epTitle : null,
        episode_OriginalTitle: r.epOriginalTitle ? r.epOriginalTitle : null,
        episode_EpisodeNumber: null,
        episode_episodioNumerico: r.episodioNumerico ? r.episodioNumerico : null,
        episode_Location: null,
        episode_Turn: r.Turn ? r.Turn : null,
        episode_completeFlag: null,
      });

      console.log(r, newData);
      row.data(newData);
      myListings.dataTablesRefresh($dest);

    });

    // popup
    $myPopsActivator.popup({
      popup: $pop,
      boundary: $('body'),
      exclusive: true,
      position: 'bottom center',
      lastResort: 'top center',
      distanceAway: -20,
      on: 'manual',
      onHidden: function() {
        $myPopsActivator.remove();
        $pop.remove();
      },
    }).popup('show');

    $pop.find('input').focus();
  },

  contextMenu: function($el, $dest) {
    const $table = $el.closest('.dataTable');
    const $tr = $el.closest('tr');
    const row = $table.length && $tr.length ? $table.DataTable().row($tr) : false;
    const rowData = $table.length && $tr.length ? $table.DataTable().row($tr).data() : false;

    $('.myPops').remove();
    $('.myPopsActivator').remove();
    const $myPopsActivator = $('<div class="myPopsActivator"></div>').prependTo($el);
    console.log('rowData', rowData);
    const $pop = $(`
      <div class="ui wide popup myPops edit" style="padding:0; min-width:200px;">
        <div class="ui small fluid vertical menu" style="box-shadow: none; border: none;">
          <a class="item elimina"><i class="trash alternate outline icon"></i>Elimina Passaggio</a>
          <div class="active item">
            <table class="ui mini very basic table">
              <thead>
                <tr>
                  <th colspan="2">Informazioni</th>
                </tr>
              </thead>
              <tbody>
                ${rowData.original.name ? `
                  <tr>
                    <td class="collapsing">Originale</td>
                    <td>${rowData.original.name}</td>
                  </tr>` : ``}
                ${rowData.original.title ? `
                  <tr>
                    <td class="collapsing">Originale</td>
                    <td>${rowData.original.title}</td>
                  </tr>` : ``}
                ${rowData.Program_Nota ? `
                  <tr>
                    <td class="collapsing">Nota</td>
                    <td>${rowData.Program_Nota}</td>
                  </tr>` : ``}
                ${rowData.Program_Year ? `
                  <tr>
                    <td class="collapsing">Anno</td>
                    <td>${rowData.Program_Year}</td>
                  </tr>` : ``}
                ${rowData.ciclo ? `
                <tr>
                  <td class="collapsing">Ciclo</td>
                  <td>${rowData.ciclo}</td>
                </tr>` : ``}
                ${rowData.parental && rowData.parental.length ? `
                  <tr>
                    <td class="collapsing">Parental</td>
                    <td>${rowData.parental.map((p) => p.Name).join(', ')}</td>
                  </tr>` : ``}
              </tbody>
            </table>


          </div>
        </div>
      </div>
    `).prependTo($el);

    $pop.on('click', '.elimina', (e) => {
      row.remove();
      myListings.dataTablesRefresh($dest);
    });

    // popup
    $myPopsActivator.popup({
      popup: $pop,
      boundary: $('body'),
      exclusive: true,
      position: 'bottom center',
      lastResort: 'top center',
      distanceAway: -20,
      on: 'manual',
      onHidden: function() {
        $myPopsActivator.remove();
        $pop.remove();
      },
    }).popup('show');

  },
};
